//import { width } from "@atlaskit/modal-dialog/dist/types/internal/constants";
import { bottom } from "@popperjs/core";
import React, { useState } from "react";
// import {headerlogo,lnEnglish,lnFrench,lnSpanish,lnGerman,Avatar_02,Avatar_03,Avatar_17,Avatar_06,
//   Avatar_13,Avatar_09,Avatar_05,Avatar_08,Avatar_21,Img_01} from '../../Entryfile/imagepath';
import ReactPlayer from "react-player";
const Cards = (props) => {
  const { value, setSource } = props;
  // console.log(value);
  return (
    <>
      {/* Cards */}
      <section className="comp-section comp-cards" id="comp_cards">
        <div className="row">
          {value?.map((ele, i) => (
            <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
              <div
                className="card flex-fill"
                style={{
                  border: "none",
                  borderRadius: "30px !important",
                  background: "none",
                  position: "relative",
                }}
              >
                {/* <img
                  alt=""
                  src={ele.img_url}
                  className="card-img-top"
                  style={{
                    height: "150px",
                    width: "100%",
                    borderRadius: "16px 16px 0px 0px ",
                  }}
                /> */}
                <ReactPlayer
                  url={ele.video_url}
                  light={true}
                  className="card-img-top1"
                  playing={false}
                  style={{ pointerEvents: "none" }}
                />
                {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                  <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                </div> */}
                <div
                  className="card-body"
                  style={{
                    background: "#535353",
                    color: "white",
                    borderRadius: "0px 0px 16px 16px ",
                  }}
                >
                  <h5
                    className="card-title mb-0"
                    style={{
                      color: "white",
                      whiteSpace: "nowrap",
                      maxWidth: "250px",
                      height: "30px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {ele.name}
                  </h5>
                  <p className="card-text" style={{ height: "40px" }}>
                    {ele.description?.slice(0, 30)}
                  </p>
                  <div
                    className="flex"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <button
                      type="button"
                      className="btn btn-sm"
                      style={{
                        minWidth: "70px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "0px 10px 0px 10px",
                        alignItems: "center",
                        height: "30px",
                        background: "#3EA6FF",
                        color: "white!important",
                        borderRadius: "20px",
                      }}
                      onClick={() => setSource(ele.video_url)}
                    >
                      <a
                        style={{ color: "white" }}
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#play_videos"
                      >
                        Play Now
                      </a>
                    </button>
                  </div>
                  {/* <a
                    className="btn btn-primary"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#play_videos"
                    style={{
                      color: "white",
                      border: "none",
                      background: "#3EA6FF",
                      padding: "0px 20px 0px 20px",
                      borderRadius: "30px",
                      width: "100px",
                      height: "28px",
                      fontSize: "14px",
                      justifyContent: "center",
                      textAlign: "center",
                      position: "absolute",
                      bottom: "10px",
                      right: "76px",
                    }}
                    onClick={() => setSource(ele.video_url)}
                  >
                    Play Now
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/* /Cards */}
    </>
  );
};

export default Cards;
