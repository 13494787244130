import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';

export default function CustomizedTimeline({getTime,timelineName}) {

  return (
    <Timeline position="alternate">
        {
            getTime.map((ele)=>(
                <TimelineItem>
                  
                <TimelineOppositeContent
                  sx={{ m: 'auto 0' }}
                  align="right"
                  variant="body2"
                  color="text.primary"
                ><b style={{color:ele.status==="Applied"?'#2196f3':ele.status==="Approve"? '#4caf50':ele.status==="Approved"? '#4caf50':'#b71c1c'}}>{ele.status}</b>
                <br/>
                  {ele.date}
                  <br/>
                  {ele.time}
                </TimelineOppositeContent>
                <TimelineSeparator> 
                  {/* <TimelineConnector /> */}
                  
                  <TimelineDot  sx={{ 
            backgroundColor:ele.status==="Applied"?'#2196f3':ele.status==="Approve"? '#4caf50':ele.status==="Approved"? '#4caf50':'#b71c1c'
          }}>
                    {/* <FastfoodIcon /> */}
                   {ele.status==="Applied"? <PendingActionsOutlinedIcon/>:ele.status==="Approve" ?<CheckCircleOutlineOutlinedIcon/>:ele.status==="Approved" ?<CheckCircleOutlineOutlinedIcon/>:   <CancelOutlinedIcon/>}
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <Typography >
                    <b>{ele.action_by}</b>
                  </Typography>
                  <Typography sx={{fontSize:"12px"}}>{ele.reason}</Typography>
                </TimelineContent>
              </TimelineItem>   
            ))
        }
     
      {/* <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.primary"
        >
          10:00 am
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ 
    backgroundColor: '#b71c1c'
  }} >
            <CancelOutlinedIcon/>
          </TimelineDot >
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Code
          </Typography>
          <Typography>Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem> */}
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot sx={{ 
    backgroundColor: '#2196f3'
  }} >
   
        <PendingActionsOutlinedIcon/>
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Sleep
          </Typography>
          <Typography>Because you need rest</Typography>
        </TimelineContent>
      </TimelineItem> */}
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Repeat
          </Typography>
          <Typography>Because this is the life you love!</Typography>
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}