import React, { useState } from "react";
import Tile from "./Tile";
import Cell from "./Cell";
import { Board } from "../helper";
import useEvent from "../hooks/useEvent";
import GameOverlay from "./GameOverlay";
import "../mainone.scss";
import "../styles.scss";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Back from "../../../../../assets/img/profiles/arrowleft.svg";

const BoardView = () => {
  const [board, setBoard] = useState(new Board());

  const handleKeyDown = (event) => {
    if (board.hasWon()) {
      return;
    }

    if (event.keyCode >= 37 && event.keyCode <= 40) {
      let direction = event.keyCode - 37;
      let boardClone = Object.assign(
        Object.create(Object.getPrototypeOf(board)),
        board
      );
      let newBoard = boardClone.move(direction);
      setBoard(newBoard);
    }
  };

  useEvent("keydown", handleKeyDown);

  const cells = board.cells.map((row, rowIndex) => {
    return (
      <div key={rowIndex}>
        {row.map((col, colIndex) => {
          return <Cell key={rowIndex * board.size + colIndex} />;
        })}
      </div>
    );
  });

  const tiles = board.tiles
    .filter((tile) => tile.value !== 0)
    .map((tile, index) => {
      return <Tile tile={tile} key={index} />;
    });

  const resetGame = () => {
    setBoard(new Board());
  };

  return (
    <div className=" twozerogame">
      <Helmet>
        <title>2048 game</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>
      {/* Page Content */}

      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h1
              className="page-title"
              style={{ fontSize: "45px", fontWeight: "900" }}
            >
              2048
            </h1>
          </div>
          <div className="col-auto float-end ml-auto">
            <Link to="/happyzone-two" style={{ color: "white" }}>
              <button className="back-happyzone">
                <span>
                  <img src={Back} />
                </span>{" "}
                Back To HappyZone
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* /Page Header */}
      {/* Content Starts */}
      <div className="twozero-box">
        <div className="details-box">
          <div className="resetButton" onClick={resetGame}>
            New Game
          </div>
          <div className="score-box">
            <div className="score-header">PUNTOS</div>
            <div style={{ fontSize: "20px" }}>{board.score}</div>
          </div>
        </div>
        <div className="board-one">
          {cells}
          {tiles}
          <GameOverlay onRestart={resetGame} board={board} />
        </div>
      </div>
      {/* /Content End */}
    </div>
  );
};

// const Wrapper = styled.section`
// @font-face {
//   font-family: "Clear Sans";
//   // src: url("../src/clear-sans.ttf") format("truetype");
// }

// body {
//   display: flex;
//   background: rgb(2,0,36);
//   background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(87,64,124,1) 47%, rgba(0,212,255,1) 100%);
//   color: white;
//   flex-direction: column;
//   justify-content: center;
//   align-content: center;
//   align-items: center;
//   font-family: "Clear Sans", sans-serif;
//   font-size: 21px;
// }

// .text {
//   order: 2;
//   padding-top: 40px;
//   width: 440px;
//   font-weight: bold;
// }

// .board {
//   order: 1;
//   width: 440px;
//   height: 440px;
//   padding: 5px;
//   background-color: #57407c;
//   border-radius: 7px;
//   outline: none;
//   position: relative;
// }

// .board .cell,
// .tile {
//   user-select: none;
//   cursor: default;
// }

// .details-box {
//   display: flex;
//   align-items: center;
//   padding: 10px;
//   justify-content: space-between;
// }

// .resetButton {
//   background-color: #3d2963;
//   color: rgba(255, 255, 255, 0.5);
//   cursor: pointer;
//   font-size: 25px;
//   padding: 10px 20px;
//   border-radius: 7px;
//   transition: color 0.3s ease, background-color 0.3s ease;
//   &:hover {
//     background-color: #d3386a;
//     color: rgba(255, 255, 255, 1);
//   }
// }

// .score-header {
//   font-size: 50%;
// }

// .score-box {
//   background-color: #3d2963;
//   color: rgba(255, 255, 255, 0.5);
//   min-width: 50px;
//   width: 70px;
//   padding: 5px 15px;
//   border-radius: 7px;
// }

// .cell,
// .tile {
//   width: 100px;
//   height: 100px;
//   margin: 5px;
//   line-height: 90px;
//   display: inline-block;
//   font-size: 55px;
//   font-weight: bold;
//   text-align: center;
//   vertical-align: middle;
//   border-radius: 7px;
//   font-family: "Clear Sans";
//   color: #766;
//   background-color: #3d2963;
// }

// .tile0 {
//   background-color: #dcb;
// }

// .tile2 {
//   background-color: #eee;
//   background-image: url(../assets/img/2.gif);
// }

// .tile4 {
//   background-color: #eec;
//   background-image: url(../assets/img/4.gif);
// }

// .tile8 {
//   color: #ffe;
//   background-image: url(../assets/img/8.gif);
// }

// .tile16 {
//   color: #ffe;
//   background-image: url(../assets/img/16.gif);
// }

// .tile32 {
//   color: #ffe;
//   background-image: url(../assets/img/32.gif);
// }

// .tile64 {
//   color: #ffe;
//   background-image: url(../assets/img/64.gif);
// }

// .tile128 {
//   color: #ffe;
//   background-image: url(../assets/img/128.gif);
// }

// .tile256 {
//   color: #ffe;
//   background-image: url(./assets/img/256.gif);
//   font-size: 45px;
// }

// .tile512 {
//   color: #ffe;
//   background-image: url(./assets/img/512.gif);
//   font-size: 45px;
// }

// .tile1024 {
//   color: #fff;
//   background-image: url(./assets/img/1024.gif);
//   font-size: 35px;
// }

// .tile2048 {
//   position: absolute;
//   background-size: contain;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   padding: 0px;
//   color: #fff;
//   background-image: url(./assets/img/2048.gif);
//   font-size: 35px;
// }

// .try-again {
//   z-index: 10;
//   height: 100px;
//   width: 100px;
//   background-image: url(./assets/img/try-again.gif);
// }

// .tile {
//   position: absolute;
// }

// .tile.merged {
//   display: none;
// }

// .tile.merged.isMoving {
//   display: inline;
// }

// .tile.new,
// .overlay {
//   animation-duration: 0.2s;
//   animation-name: newTile;
//   animation-fill-mode: forwards;
//   animation-delay: 0.15s;
//   transform: scale(0);
// }

// @keyframes newTile {
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1);
//   }
// }

// .overlay {
//   position: absolute;
//   top: 0px;
//   bottom: 0px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   left: 0px;
//   right: 0px;
//   font-size: 55px;
//   font-weight: bolder;
//   border-radius: 7px;
// }

// .tryAgain {
//   background-color: #876;
//   color: #fff;
//   height: 40px;
//   width: 200px;
//   font-size: 20px;
//   font-weight: bold;
//   cursor: pointer;
//   border: none;
//   border-radius: 5px;
//   border: 2px solid white;
// }

// .gameOver {
//   background-size: contain;
//   background-image: url(./assets/img/game-over.gif);
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 100;
//   text-align: center;
// }

// .overlay .message {
//   color: #666;
// }

// `;

export default BoardView;
