import React, { useMemo, useState, useEffect } from "react";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";

const PFDeduction = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status1, setStatus1] = useState("");
  const [name1, setName1] = useState("");
  const [location, setLocation] = useState("");
  const [empId, setEmpId] = useState("");
  const [outdoor, setOutdoor] = useState({});
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");

  const handlePFDeduction = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/pf_deduction_in_payroll/`,
        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            comp_id: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handlePFDeduction();
  }, [monthValue]);

  const filteredData = useMemo(() => {
    return data
      .filter((x) => x.empname.toLowerCase().includes(name1.toLowerCase()))
      .filter((x) => x.empcode.toLowerCase().includes(empId.toLowerCase()))
      .filter((a) =>
        a.work_location.toLowerCase().includes(location.toLowerCase())
      );
  }, [name1, location, empId, data]);

  const columns = [
    {
      title: "Employee Code",
      dataIndex: "empcode",
    },
    {
      title: "Employee Name",
      dataIndex: "empname",
    },
    {
      title: "Basic Salary",
      dataIndex: "basic_salary",
    },
    {
      title: "PF Amount",
      dataIndex: "pf_amount",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      render: (text, record) => (
        <>{text?.slice(0, 10).split("-").reverse().join("-")}</>
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      render: (text, record) => (
        <>{text?.slice(0, 10).split("-").reverse().join("-")}</>
      ),
    },

    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text, record) => (
    //     <button
    //       style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //       data-bs-toggle="modal"
    //       data-bs-target="#short_leave"
    //       className="btn-primary submit-btn1"
    //       onClick={(e) => {
    //         setOutdoor(text);
    //       }}
    //     >
    //       view
    //     </button>
    //   ),
    // },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">PF Deduction</h3>
              </div>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee Name"
                  options={[
                    ...new Set(filteredData?.map((item) => item.empname)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee code"
                  options={[
                    ...new Set(filteredData?.map((item) => item.empcode)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    empId !== ""
                      ? {
                          label: empId,
                          value: empId,
                        }
                      : null
                  }
                  onChange={(e) => setEmpId(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Work Location"
                  isClearable
                  options={[
                    ...new Set(filteredData?.map((item) => item.work_location)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    location !== ""
                      ? {
                          label: location,
                          value: location,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setLocation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group  ">
                    <input
                      className="form-control input-height"
                      type="date"
                      placeholder="To"
                      value={to1}
                      onChange={(e) => setTo1(e.target.value)}
                    />
                  </div>
                </div> */}
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (setName1(""), setLocation(""), setEmpId(""))}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <div className="view-icons">
                    <input
                      style={{
                        width: "150px",
                        borderRadius: "5px",
                        height: "37px",
                        padding: "10px",
                      }}
                      type="month"
                      value={monthValue}
                      onChange={(e) => setMonthValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: filteredData?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={filteredData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};
export default PFDeduction;
