import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import deletimg from "../../../assets/img/profiles/delete.svg";
import { Table } from "antd";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import Delete from "../../../modelbox/Delete";
import axios from "axios";

const Contractor = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [contractor, setContractor] = useState({
    name: "",
    conct_no: "",
    spoc_name: "",
    email: "",
    gst: "",
    pan: "",
  });
  const [contractors, setContractors] = useState([]);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const token = sessionStorage.getItem("access_token");

  const getContractors = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contractors/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setContractors(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddContractor = (e) => {
    e.preventDefault();
    if (
      !contractor.name ||
      !contractor.conct_no ||
      !contractor.spoc_name ||
      !contractor.gst ||
      !contractor.pan ||
      !contractor.email
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/contractors/`,
          {
            coregid: sessionStorage.getItem("coid"),
            name: contractor.name,
            mobile: contractor.conct_no,
            spoc_name: contractor.spoc_name,
            email: contractor.email,
            gst: contractor.gst,
            pan: contractor.pan,
          },
          {
            // params: {
            //   empid: sessionStorage.getItem("emp_id"),
            // },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Contractor added successfully",
              // timer: 1500,
            });
          }
          setContractor({
            name: "",
            conct_no: "",
            spoc_name: "",
            email: "",
            gst: "",
            pan: "",
          });
          getContractors();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            //title: "oops..",
            text: err.response?.data?.error
              ? `${
                  err.response.data.error[0].toUpperCase() +
                  err.response.data.error.slice(1)
                }!`
              : "Something Went Wrong",
            // timer: 1500,
          });
        });
    }
  };

  useEffect(() => {
    getContractors();
  }, []);

  const columns = [
    {
      title: "Contractor Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      //   sorter: {
      //     compare: (a, b) =>
      //       moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
      //   },
    },
    {
      title: "SPOC Name",
      dataIndex: "spoc_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "PAN Number",
      dataIndex: "pan",
    },
    {
      title: "GST Number",
      dataIndex: "gst",
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Contractors</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_contractor"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Add Contractor
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: contractors.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={contractors}
                      rowKey={(record) => record.id}
                      // onChange={console.log("change")}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        <div
          id="add_contractor"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Contractor</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Contractor Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.name}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          pattern="[0-9]{10}"
                          minlength="10"
                          title="please Enter Valid Phone Number"
                          maxlength="10"
                          type="tel"
                          value={contractor.conct_no}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              conct_no: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        SPOC Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.spoc_name}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              spoc_name: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Email <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.email}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              email: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.pan}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              pan: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        GST <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          value={contractor.gst}
                          onChange={(e) =>
                            setContractor({
                              ...contractor,
                              gst: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn"
                    style={{
                      background: "#3298DB",
                      color: "white",
                      width: "70px",
                      height: "24px",
                      textAlign: "center",
                      borderRadius: "14px",
                      fontSize: "14px",
                      padding: "2px 7px",
                    }}
                    data-bs-dismiss={
                      !contractor.name ||
                      !contractor.conct_no ||
                      !contractor.spoc_name ||
                      !contractor.gst ||
                      !contractor.pan ||
                      !contractor.email
                        ? ""
                        : "modal"
                    }
                    onClick={(event) => handleAddContractor(event)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contractor;
