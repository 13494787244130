import React from "react";
import ReactDom from "react-dom";
import { Link } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";
import store from "./store";
import Back from "../../../../assets/img/profiles/arrowleft.svg";
import {
  SettingsContainer,
  StatusContainer,
  BoardContainer,
} from "./containers";
import { Wrapper, Title } from "./AppStyle";

const MineSweeper = () => {
  return (
	<div className=" twozerogame">
      <Helmet>
        <title>mines sweeper game</title>
        <meta name="description" content="Reactify Blank Page" />
      </Helmet>
      {/* Page Content */}

      {/* Page Header */}
      <div className="page-header">
        <div className="row align-items-center">
          <div className="col">
            <h1
              className="page-title"
              style={{ fontSize: "45px", fontWeight: "900" }}
            >
              Mine Sweeper
            </h1>
          </div>
          <div className="col-auto float-end ml-auto">
            <Link to="/happyzone-two" style={{ color: "white" }}>
              <button className="back-happyzone">
                <span>
                  <img src={Back} />
                </span>{" "}
                Back To HappyZone
              </button>
            </Link>
          </div>
        </div>
      </div>
    <Wrapper>
      <Provider store={store}>
        <Title>Minesweeper Game</Title>
        <SettingsContainer />
        <StatusContainer />
        <BoardContainer />
      </Provider>
    </Wrapper>
	</div>
  );
};

export default MineSweeper;
