import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import { Table } from "antd";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import { RotatingLines } from "react-loader-spinner";
import Button from "@mui/material/Button";
import axios from "axios";
import {
  itemRender,
  onShowSizeChange,
} from "../../../../components/paginationfunction";
//import "./antdstyle.css";
import Select from "react-select";
import eye from "../../../../assets/img/profiles/Group813.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import Nav from "../../../../containers/Navbar/navbar";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
import FilterOff from "../../../../assets/img/profiles/filter-off-fill1.svg";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Back from "../../../../assets/img/profiles/arrow-left.svg";
import ViewPayslip from "./ViewPayroll";
import RevisePayroll from "./RevisePayroll";
import { empdet } from "../admconst";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { FiUpload } from "react-icons/fi";
import Swal from "sweetalert2";
import { read, utils } from "xlsx";

const AdminPayroll = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [toggle, setToggle] = useState(0);
  const [menu, setMenu] = useState(false);
  const [name1, setName1] = useState("");
  const [location, setLocation] = useState("");
  const [empCode, setEmpCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [designation1, setDesignation1] = useState("");
  const [empPayrollDetails, setEmpPayrollDetails] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [empID, setEmpID] = useState("");
  const [empName, setEmpName] = useState("");
  const [col, setCol] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [excelData, setExcelData] = useState([]);
  const [exData, setExData] = useState([]);
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const hiddenFileInput = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const handleClick1 = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleCurrPage = (value) => {
    //setToggle(page)
    setCurrentTab(value);
  };
  console.log(col);

  const token = sessionStorage.getItem("access_token");

  const handleGetPayroll = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          toggle: "t",
          comp_id: sessionStorage.getItem("coid"),
        },
      })
      .then((res) => {
        //console.log(res.data);
        setCol(
          res.data.columns.map((ele) => {
            if (ele.key === "employee_name") {
              return {
                title: ele.name.replace(".", ""),
                width: 200,
                dataIndex: ele.key.replace(".", ""),
                key: ele.key.replace(".", ""),
                align: "left",
                fixed: "left",
              };
            } else if (ele.key.replace(".", "") === "employee_id") {
              return {
                title: ele.name.replace(".", ""),
                width: 100,
                dataIndex: ele.key.replace(".", ""),
                key: ele.key.replace(".", ""),
                align: "left",
                fixed: "left",
              };
            } else {
              return {
                title: ele.name.replace(".", ""),
                width: 180,
                dataIndex: ele.key.replace(".", ""),
                key: ele.key.replace(".", ""),
                // align: "right",
              };
            }
            // title: ele.name.replace(".", ""),
            // dataIndex: ele.key.replace(".", ""),

            // align: "right",
          })
        );
        setEmpPayrollDetails(res.data.data);
        setSearchData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetPayroll();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/directory/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setEmpPayrollDetails(res.data);
        // setSearchData(res.data);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUploadBulk = () => {
    let formData = new FormData();
    formData.append("file", exData);
    formData.append("coregid", sessionStorage.getItem("coid"));
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/bulk_salary_update_template/`,
        formData
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Payroll updated successfully",
        });
        HandleDelete();
        handleGetPayroll();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        setExcelData(json);
        setToggle(3);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setExData(e.target.files[0]);
    }
  };

  const HandleDelete = () => {
    setToggle(0);
    setExData({});
    setExcelData([]);
  };

  const handleSearch1 = () => {
    const newData = empPayrollDetails
      .filter((x) => x.employee_name == (name1 == "" ? x.employee_name : name1))
      .filter((y) => y.department == (address1 == "" ? y.department : address1))
      .filter((y) => y.emp_code == (empCode == "" ? y.emp_code : empCode))
      .filter(
        (y) =>
          y.office_location == (location == "" ? y.office_location : location)
      )
      .filter(
        (z) =>
          z.employement_type ==
          (designation1 == "" ? z.employement_type : designation1)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch1();
  }, [name1, address1, designation1, location, empCode]);

  const columns = [
    // {
    //   title: "Employee ID",
    //   dataIndex: "emp_id",
    //   sorter: (a, b) => a.emp_id.length - b.emp_id.length,
    // },
    // {
    //   title: "Employee Name",
    //   dataIndex: "emp_name",
    //   sorter: (a, b) => a.joindate.length - b.joindate.length,
    // },
    // {
    //   title: "Department",
    //   dataIndex: "emp_dept",
    //   sorter: (a, b) => a.mobile.length - b.mobile.length,
    // },
    // {
    //   title: "Designation",
    //   dataIndex: "emp_desg",
    //   sorter: (a, b) => a.mobile.length - b.mobile.length,
    // },
    // {
    //   title: "Employment Type",
    //   dataIndex: "emp_type",
    // },
    // {
    //   title: "Email",
    //   dataIndex: "emp_email",
    //   sorter: (a, b) => a.email.length - b.email.length,
    // },
    // {
    //   title: "CTC",
    //   dataIndex: "ctc",
    //   sorter: (a, b) => a.email.length - b.email.length,
    //   render: (text, record) => Math.round(text),
    // },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <a
            href="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={
              rolePermissions["Overview Payroll"] === "Edit/Create/Approve"
                ? false
                : true
            }
          >
            <Button variant="contained">
              <MoreHorizIcon />
            </Button>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{ inset: "-100px auto auto 0px" }}
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                setToggle(1);
                setEmpID(text.employee_id);
                setEmpName(text.employee_name);
              }}
            >
              <img
                src={eye}
                style={{
                  height: "10px",
                  width: "15px",
                }}
              />
              <b> View Salary Breakup</b>
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                setToggle(2);
                setEmpID(text.employee_id);
                setEmpName(text.employee_name);
              }}
            >
              <i className="fa fa-pencil m-r-5" />
              <b> Revise Salary</b>
            </a>
            {/* <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
            >
              <i className="fa fa-trash-o m-r-5" />
              <b> Skip Payroll</b>
            </a> */}
          </div>
        </div>
      ),
    },
  ];

  const column1 = [
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id.length - b.emp_id.length,
    },
    {
      title: "Employee Name",
      dataIndex: "emp_name",
      sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "Variable Type",
      dataIndex: "emp_type",
    },
    {
      title: "Variable Amount",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <Button variant="contained">
            <CreateOutlinedIcon />
          </Button>
        </div>
      ),
    },
  ];

  const column2 = [
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id.length - b.emp_id.length,
    },
    {
      title: "Employee Name",
      dataIndex: "emp_name",
      sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "Employment Type",
      dataIndex: "emp_type",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "CTC",
      dataIndex: "ctc",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <Button variant="contained">
            <CreateOutlinedIcon />
          </Button>
        </div>
      ),
    },
  ];

  const column3 = [
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id.length - b.emp_id.length,
    },
    {
      title: "Employee Name",
      dataIndex: "emp_name",
      sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "Bonus Type",
      dataIndex: "emp_type",
    },

    {
      title: "Bonus Amount",
      dataIndex: "ctc",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <Button variant="contained">
            <CreateOutlinedIcon />
          </Button>
        </div>
      ),
    },
  ];

  const column4 = [
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id.length - b.emp_id.length,
    },
    {
      title: "Employee Name",
      dataIndex: "emp_name",
      sorter: (a, b) => a.joindate.length - b.joindate.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.mobile.length - b.mobile.length,
    },
    {
      title: "From",
      dataIndex: "emp_type",
    },
    {
      title: "To",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Arrear Amount",
      dataIndex: "ctc",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <Button variant="contained">
            <CreateOutlinedIcon />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  {currentTab !== 0 && (
                    <>
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "3px 3px",
                            fontSize: "15px",
                            background: " #3298DB",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setCurrentTab(0)}
                        >
                          <img src={Back} />
                        </button>
                      </span>
                      &nbsp;{" "}
                    </>
                  )}
                  {/* {toggle !== 0 && (
                    <>
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "3px 3px",
                            fontSize: "15px",
                            background: " #3298DB",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setToggle(0)}
                        >
                          <img src={Back} />
                        </button>
                      </span>
                      &nbsp;{" "}
                    </>
                  )} */}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setToggle(0)}
                  >
                    Payroll
                  </span>
                  {toggle === 1 && (
                    <span style={{ color: "#6c757d" }}> / payslip</span>
                  )}
                  {toggle === 2 && (
                    <span style={{ color: "#6c757d" }}> / reviseSalary</span>
                  )}
                </h3>
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Employee</li>
                </ul> */}
              </div>
              {toggle === 0 ? (
                <div className="col-auto float-end">
                  <button
                    className=""
                    classname="edit-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "19px 23px",
                      height: "30px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <Box>
                      <Tooltip title="Add Bulk File">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          color="white"
                          // sx={{ ml: -3 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // className={classes.button}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            <FiUpload />
                            &nbsp;&nbsp; Bulk salary revision
                          </p>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem>
                      <a
                        // onClick={() => handleExcelDownload()}
                        href={`${
                          process.env.REACT_APP_BACKEND_URL
                        }/bulk_salary_update_template/?coregid=${sessionStorage.getItem(
                          "coid"
                        )}&dev=dev`}
                        download
                        target="_blank"
                      >
                        Download Template
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        onClick={(e) => handleClick1(e)}
                        // data-bs-toggle="modal"
                        // data-bs-target="#experience_info"
                        // href="#"
                        // appearance="primary"
                        // onClick={openModal}
                      >
                        Upload File
                      </a>
                    </MenuItem>
                  </Menu>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => readUploadFile(e)}
                    style={{ display: "none" }}
                  />
                  {/* <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange1}
                    style={{ display: "none" }}
                  /> */}
                </div>
              ) : (
                // <div className="dropdown dropdown-action col-auto float-end ml-auto">
                //   <div className="view-icons">
                //     <a
                //       href="#"
                //       className="action-icon dropdown-toggle"
                //       data-bs-toggle="dropdown"
                //       aria-expanded="false"
                //     >
                //       <Button variant="contained">
                //         <MoreHorizIcon />
                //       </Button>
                //     </a>
                //     <div className="dropdown-menu dropdown-menu-right">
                //       <label
                //         className="dropdown-item"
                //         href="#"
                //         data-bs-toggle="modal"
                //         data-bs-target="#edit_employee"
                //       >
                //         <b> import .csv, xslx</b>
                //       </label>

                //       <input
                //         type="file"
                //         id="upload-csv-xlsx"
                //         style={{ display: "none" }}
                //       />
                //       <label
                //         className="dropdown-item"
                //         href="#"
                //         htmlFor="upload-csv-xlsx"
                //         //data-bs-toggle="modal"
                //         //data-bs-target="#delete_employee"
                //       >
                //         <b> Upload .csv, xlsx</b>
                //       </label>
                //     </div>
                //   </div>
                // </div>
                <div
                  className="col-auto float-end"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="content container-fluid">
                    <Button variant="contained" onClick={() => setToggle(0)}>
                      <CloseIcon fontSize="small" />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}

          {isLoading ? (
            <div style={{ marginLeft: "45%", marginTop: "15%" }}>
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.50"
                width="50"
                marginRight="700"
                visible={true}
              />
            </div>
          ) : (
            <>
              {" "}
              {toggle === 0 && (
                <>
                  <div className="row filter-row">
                    <div className="col-sm-6 col-md-2">
                      <div className="form-group form-focus select-focus">
                        <Select
                          inputId="single-select-example"
                          isClearable
                          className="single-select"
                          classNamePrefix="react-select"
                          placeholder="Employee Name"
                          options={[
                            ...new Set(
                              empPayrollDetails?.map(
                                (item) => item.employee_name
                              )
                            ),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={
                            name1 !== ""
                              ? {
                                  label: name1,
                                  value: name1,
                                }
                              : null
                          }
                          onChange={(e) => setName1(e ? e.value : "")}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          style={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="form-group form-focus select-focus">
                        <Select
                          inputId="single-select-example"
                          isClearable
                          className="single-select"
                          classNamePrefix="react-select"
                          placeholder="Employee Code"
                          options={[
                            ...new Set(
                              empPayrollDetails?.map((item) => item.emp_code)
                            ),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={
                            empCode !== ""
                              ? {
                                  label: empCode,
                                  value: empCode,
                                }
                              : null
                          }
                          onChange={(e) => setEmpCode(e ? e.value : "")}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          style={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="form-group form-focus select-focus">
                        <Select
                          placeholder="Employment Type"
                          isClearable
                          options={[
                            ...new Set(
                              empPayrollDetails?.map(
                                (item) => item.employement_type
                              )
                            ),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={
                            designation1 !== ""
                              ? {
                                  label: designation1,
                                  value: designation1,
                                }
                              : null
                          }
                          onChange={(e) => {
                            setDesignation1(e ? e.value : "");
                          }}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          //className="select floating"
                          style={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                        />
                        {/* <label className="focus-label">Designation</label> */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="form-group">
                        <Select
                          // type="text"
                          // className="form-control input-height"
                          isClearable
                          options={[
                            ...new Set(
                              empPayrollDetails?.map((item) => item.department)
                            ),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={
                            address1 !== ""
                              ? {
                                  label: address1,
                                  value: address1,
                                }
                              : null
                          }
                          onChange={(e) => {
                            setAddress1(e ? e.value : "");
                          }}
                          placeholder="Department"
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          style={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                          //   onChange={(e) =>
                          //     setAddress1(e.target.value.toLocaleLowerCase())
                          //   }
                        />
                        {/* <label className="focus-label">Location</label> */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-2">
                      <div className="form-group">
                        <Select
                          // type="text"
                          // className="form-control input-height"
                          isClearable
                          options={[
                            ...new Set(
                              empPayrollDetails?.map(
                                (item) => item.office_location
                              )
                            ),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={
                            location !== ""
                              ? {
                                  label: location,
                                  value: location,
                                }
                              : null
                          }
                          onChange={(e) => {
                            setLocation(e ? e.value : "");
                          }}
                          placeholder="Location"
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          style={{
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                          //   onChange={(e) =>
                          //     setAddress1(e.target.value.toLocaleLowerCase())
                          //   }
                        />
                        {/* <label className="focus-label">Location</label> */}
                      </div>
                    </div>
                    <div className="row col-sm-6 col-md-2">
                      {/*   <div className="col-md-9">
                        <div className="form-group">
                          <input
                            type="month"
                            className="form-control input-height"
                            placeholder="Month"
                            value={month}
                            style={{
                              height: "50px",
                              width: "100%",
                              border: "1px solid lightgrey",
                              borderRadius: "3px",
                            }}
                            onChange={(e) => setMonth(e.target.value)}
                          />
                          <label className="focus-label">Location</label>
                        </div>
                      </div>*/}

                      <div className="col-md-3">
                        <a
                          className="btn-block "
                          onClick={() => (
                            setAddress1(""),
                            setDesignation1(""),
                            setName1(""),
                            setEmpCode(""),
                            setLocation("")
                          )}
                        >
                          <img
                            tabindex="0"
                            data-bs-toggle="tooltip"
                            title="clear all filter"
                            style={{
                              height: "38px",
                              backgroundColor: "#3298DB",
                              padding: "7px 5px",
                              borderRadius: "5px",
                            }}
                            src={FilterOff}
                          />
                        </a>
                      </div>
                    </div>
                    {/* <div className="col-auto float-start ml-auto mb-4">
                      <div
                        className="view-icons"
                        style={{ gap: "20px !important" }}
                      >
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            marginRight: "20px",
                            fontSize: "15px",
                            background: currentTab === 1 ? "#2DB8C5" : "white",
                            border: "none",
                            color: currentTab === 1 ? "white" : "#000000",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#Leave_info_modal"
                          href="#"
                          classname="edit-icon"
                          onClick={() => handleCurrPage(1)}
                        >
                          Variable
                        </button>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            fontSize: "15px",
                            marginRight: "20px",
                            background: currentTab === 2 ? "#2DB8C5" : "white",
                            border: "none",
                            color: currentTab === 2 ? "white" : "#000000",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#Leave_info_modal"
                          onClick={() => {
                            handleCurrPage(2);
                          }}
                        >
                          Reimbursements
                        </button>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            fontSize: "15px",
                            marginRight: "20px",
                            background: currentTab === 3 ? "#2DB8C5" : "white",
                            border: "none",
                            color: currentTab === 3 ? "white" : "#000000",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#Leave_info_modal"
                          onClick={() => handleCurrPage(3)}
                        >
                          Bonus
                        </button>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            fontSize: "15px",
                            background: currentTab === 4 ? "#2DB8C5" : "white",
                            border: "none",
                            color: currentTab === 4 ? "white" : "#000000",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#Leave_info_modal"
                          onClick={() => {
                            handleCurrPage(4);
                          }}
                        >
                          Arrears
                        </button>
                      </div>
                    </div> */}
                  </div>

                  {currentTab === 0 && (
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table
                            searchable
                            exportableProps={{ showColumnPicker: true }}
                            className="table-striped"
                            style={{ overflowX: "auto" }}
                            columns={col.concat(columns)}
                            // bordered
                            dataSource={searchData}
                            rowKey={(record) => record.id}
                            pagination={{
                              total: searchData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              position: ["bottomRight"],
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            scroll={{ x: 1300 }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 1 && (
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table
                            searchable
                            exportableProps={{ showColumnPicker: true }}
                            className="table-striped"
                            pagination={{
                              total: searchData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            style={{ overflowX: "auto" }}
                            columns={column1}
                            // bordered
                            dataSource={searchData}
                            rowKey={(record) => record.id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 2 && (
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table
                            searchable
                            exportableProps={{ showColumnPicker: true }}
                            className="table-striped"
                            pagination={{
                              total: searchData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            style={{ overflowX: "auto" }}
                            columns={column2}
                            // bordered
                            dataSource={searchData}
                            rowKey={(record) => record.id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 3 && (
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table
                            searchable
                            exportableProps={{ showColumnPicker: true }}
                            className="table-striped"
                            pagination={{
                              total: searchData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            style={{ overflowX: "auto" }}
                            columns={column3}
                            // bordered
                            dataSource={searchData}
                            rowKey={(record) => record.id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentTab === 4 && (
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="table-responsive">
                          <Table
                            searchable
                            exportableProps={{ showColumnPicker: true }}
                            className="table-striped"
                            pagination={{
                              total: searchData.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            style={{ overflowX: "auto" }}
                            columns={column4}
                            // bordered
                            dataSource={searchData}
                            rowKey={(record) => record.id}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {toggle === 1 && <ViewPayslip empID={empID} empName={empName} />}
              {toggle === 2 && (
                <RevisePayroll empID={empID} setToggle={setToggle} />
              )}
              {toggle === 3 && (
                <>
                  {" "}
                  <div className="card  pre-table">
                    <div className="table-responsive">
                      <p>
                        To edit the details, please correct it in the excel file
                        and reupload the same !
                      </p>
                      <Table
                        scroll={{ x: 1300 }}
                        dataSource={excelData}
                        pagination={{
                          total: excelData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={
                          excelData.length !== 0
                            ? Object.keys(excelData[0]).map((ele) => ({
                                title: ele,
                                dataIndex: ele,
                                key: ele,
                              }))
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="btn-sub"
                      style={{ background: "white", color: "#3298DB" }}
                      onClick={() => HandleDelete()}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-sub"
                      style={{ background: "#3298DB", color: "white" }}
                      onClick={() => handleUploadBulk()}
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {/* /Page Content */}
        {/* Add Employee Modal */}
        {/* <Addemployee /> */}
        {/* /Add Employee Modal */}
        {/* Edit Employee Modal */}
        {/* <Editemployee /> */}
        {/* /Edit Employee Modal */}
        {/* Delete Employee Modal */}
        <div
          className="modal custom-modal fade"
          id="delete_employee"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Employee</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Employee Modal */}
      </div>
    </div>
  );
};

export default AdminPayroll;
