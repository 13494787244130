import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Select from "react-select";
import moment from "moment";
import Swal from "sweetalert2";
import empty from "../../assets/img/profiles/browser.png";
// import {
//   avatar_02,
//   Avatar_05,
//   Avatar_09,
//   Avatar_10,
//   Avatar_03,
//   Avatar_08,
//   Avatar_15,
//   Avatar_20,
//   Avatar_25,
//   Avatar_24,
// } from "../../../src/assets/img/profiles/";
import CircularStatic from "../../components/ProgressBar";
import { SmileOutlined } from "@ant-design/icons";

import { ConfigProvider, Table } from "antd";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import Delete from "../../modelbox/Delete";
import axios from "axios";
const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "100px", marginTop: "15px" }} />
    <p>No Transfer Data to Show</p>
  </div>
);

const Transfer = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [offices, setOffices] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [applyTransfer, setApplyTransfer] = useState({
    name: sessionStorage.getItem("name"),
    emp_id: JSON.parse(sessionStorage.getItem("emp_id")),
    curr_location: { label: null, value: null },
    location_to: { label: null, value: null },
    permanent: { label: null, value: null },
    date_from: "",
    date_to: "",
    reason: "",
  });

  const transferLoc = offices.filter(
    (number) => number !== applyTransfer.curr_location
  );

  const [showTransfer, setShowTransfer] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [data, setData] = useState([]);
  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // }

  const token = sessionStorage.getItem("access_token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leavetype",
      sorter: (a, b) => a.leavetype.localeCompare(b.leavetype),
    },

    {
      title: "From",
      dataIndex: "from",
      //sorter: (a, b) => a.from.length - b.from.length,
      sorter: {
        compare: (a, b) =>
          moment(a.from, "YYYY-MM-DD") - moment(b.from, "YYYY-MM-DD"),
      },
    },
    {
      title: "To",
      dataIndex: "to",
      render: (text, record) => (text === null ? "Permanent" : text),
      //sorter: (a, b) => a.to.length - b.to.length,
      sorter: {
        compare: (a, b) =>
          moment(a.to, "YYYY-MM-DD") - moment(b.to, "YYYY-MM-DD"),
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      sorter: (a, b) => a.location.length - b.location.length,
    },

    {
      title: "No. of Days",
      dataIndex: "noofdays",
      render: (text, record) => (text === null || text === "" ? "-" : text),

      sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "Reason",
      dataIndex: "reasons",
      sorter: (a, b) => a.reasons.length - b.reasons.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Manager",
      dataIndex: "name",
      //   render: (text, record) => (
      //     <h2 className="table-avatar">
      //       <Link to="/app/profile/employee-profile" className="avatar">
      //         <img alt="" src={record.image} />
      //       </Link>
      //       <Link to="/app/profile/employee-profile">{text} </Link>
      //     </h2>
      //   ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];

  const handleApplyTransfer = () => {
    if (
      !applyTransfer.name &&
      !applyTransfer.emp_id &&
      !applyTransfer.curr_location.label &&
      !applyTransfer.location_to.value &&
      !applyTransfer.date_from &&
      !applyTransfer.date_to &&
      !applyTransfer.reason
    ) {
      Swal.fire({
        icon: "error",
        title: "oops..",
        text: "Something Went Wrong",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/transfer/`,
          {
            name: applyTransfer.name,
            empid: applyTransfer.emp_id,
            curr_location: applyTransfer.curr_location.label,
            office_to: applyTransfer.location_to.value,
            permanent:
              applyTransfer.permanent.value === "Permanent" ? true : false,
            date_from: applyTransfer.date_from,
            date_to: applyTransfer.date_to,
            reason: applyTransfer.reason,
          },
          {
            // params: { empid: sessionStorage.getItem("emp_id") },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("transfer apply successfully");
          setShowTransfer(!showTransfer);
          handleApply();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const handleApply = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/transfer/`,

        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleApply();
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/transfer/`,

  //       {
  //         params: { empid: sessionStorage.getItem("emp_id") },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setData(res.data);
  //       setIsLoading(false)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/department/`,

  //       {
  //         params: { empid: sessionStorage.getItem("emp_id") },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setDepartment(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_BACKEND_URL}/designation/`,

  //       {
  //         params: { empid: sessionStorage.getItem("emp_id") },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setDesignation(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_co_office/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setOfficeDetails(res.data);
        setOffices(
          res.data.map((ele) => ({
            label: `${ele.name}-${ele.city}`,
            value: ele.off_id,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const type_transfer = [
    { label: "Permanent", value: "Permanent" },
    { label: "Temporary", value: "Temporary" },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin Template</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Transfer</h3>
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Leaves</li>
              </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                {!showTransfer && (
                  <a
                    href="#"
                    className="btn"
                    // data-bs-toggle="modal"
                    // data-bs-target="#add_leave"
                    onClick={() => setShowTransfer(!showTransfer)}
                    style={{
                      marginLeft: "20px",
                      background: "#1f8ded",
                      color: "white",
                    }}
                  >
                    Apply for Transfer
                  </a>
                )}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}

          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    {!showTransfer ? (
                      <ConfigProvider
                        renderEmpty={
                          // customize ?
                          customizeRenderEmpty
                          // : undefined
                        }
                      >
                        <Table
                          className="table-striped"
                          pagination={{
                            total: data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          // bordered
                          dataSource={data}
                          rowKey={(record) => record.id}
                          onChange={console.log("change")}
                          loading={isLoading}
                        />
                      </ConfigProvider>
                    ) : (
                      <div>
                        <div className=" row col-md-12">
                          <div className="col-md-6">
                            <h4
                              className="card-title"
                              // style={{ color: "white" }}
                            >
                              Details
                            </h4>
                            <div
                              className="col-md-12 transfer-div"
                              // style={{ background: "white" }}
                            >
                              <br />

                              <div className="col-md-12">
                                <div className="card-body">
                                  <form action="#">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Name{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <input
                                            // disabled={
                                            //   offices.length === 0
                                            //     ? true
                                            //     : false
                                            // }
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={applyTransfer.name}
                                            // onChange={(e) =>
                                            //   setApplyTransfer({
                                            //     ...applyTransfer,
                                            //     name: e.target.value,
                                            //   })
                                            // }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Employee ID{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <input
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value={applyTransfer.emp_id}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                emp_id: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Department</label>
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                          /> */}
                                    {/* <Select options={department } />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Designation</label> */}
                                    {/* <input
                                            type="text"
                                            className="form-control"
                                          /> */}
                                    {/* <Select options={designation } />
                                        </div>
                                      </div>
                                    </div>  */}
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Current Location{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                          /> */}
                                          <Select
                                            isDisabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            options={offices}
                                            value={applyTransfer.curr_location}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                curr_location: e,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Manager{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <input
                                            disabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            type="text"
                                            className="form-control"
                                          />
                                        </div>
                                      </div>
                                      {offices?.length === 0 && (
                                        <div className="col-md-12">
                                          <div className="form-group">
                                            <label style={{ color: "red" }}>
                                              <b>{`!Only ${
                                                offices[0]
                                                  ? offices[0].label
                                                  : "one office"
                                              } is available for your company`}</b>
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <h4
                              className="card-title"
                              // style={{ color: "white" }}
                            >
                              Transfer To
                            </h4>

                            <div
                              className="col-md-12 transfer-div"
                              // style={{ background: "white" }}
                            >
                              {/* <div
                                style={{
                                  display: "flex",
                                  marginLeft: "80%",
                                  paddingTop: "5px",
                                }}
                              >
                                {" "}
                                <input type="checkbox" />
                                <label>Permanent</label>
                              </div> */}
                              <div className="col-md-12">
                                <div className="card-body">
                                  <form action="#">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Location{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                          /> */}
                                          <Select
                                            isDisabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            options={transferLoc}
                                            value={applyTransfer.location_to}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                location_to: e,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Transfer Type{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          {/* <input
                                            type="text"
                                            className="form-control"
                                          /> */}
                                          <Select
                                            isDisabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            options={type_transfer}
                                            value={applyTransfer.permanent}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                permanent: e,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Date(from){" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <input
                                            disabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            type="date"
                                            className="form-control"
                                            min={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                            value={applyTransfer.date_from}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                date_from: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Date(to){" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <input
                                            disabled={
                                              applyTransfer.permanent.value ===
                                                "Permanent" ||
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            type="date"
                                            min={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                            className="form-control"
                                            value={applyTransfer.date_to}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                date_to: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <label>
                                            Reason for Transfer{" "}
                                            <sup style={{ color: "red" }}>
                                              *
                                            </sup>
                                          </label>
                                          <textarea
                                            disabled={
                                              offices.length === 0
                                                ? true
                                                : false
                                            }
                                            rows="10"
                                            maxlength="100"
                                            cols="50"
                                            max-height="0"
                                            type="text"
                                            className="form-control"
                                            value={applyTransfer.reason}
                                            onChange={(e) =>
                                              setApplyTransfer({
                                                ...applyTransfer,
                                                reason: e.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              textAlign: "center",
                              justifyContent: "center",
                              gap: "100px",
                              marginTop: "30px",
                            }}
                          >
                            <div>
                              <button
                                className="add-transfer-btn"
                                style={{}}
                                disabled={offices.length === 0 ? true : false}
                                onClick={() => handleApplyTransfer()}
                              >
                                Submit
                              </button>
                            </div>
                            <div>
                              <button
                                className="add-transfer-btn"
                                onClick={() => setShowTransfer(!showTransfer)}
                              >
                                Discard
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
