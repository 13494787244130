import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import temp from "../assets/img/profiles/maintenance.jpeg";
const UnderMaintenance = () => {
  return (
    <div className="main-wrapper">
      <div className="error-box">
        <img src={temp} style={{ height: "500px", width: "500px" }} />
        {/* <h3>
          <i className="fa fa-warning" /> Oops! Page not found!
        </h3> */}
        <h3 style={{ color: "white" }}>
          The page you requested is Under Maintenance.
        </h3>
        {/* <Link
          onClick={() => localStorage.setItem("firstload", "true")}
          to="/dashboard"
          className="btn btn-custom"
        >
          Back to Home
        </Link> */}
      </div>
    </div>
  );
};

export default UnderMaintenance;
