import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import axios from "axios";
import smily from "../../assets/img/smiley-icon.svg";
import YouTube from "react-youtube";
import closeIcon from "../../assets/img/profiles/whiteclose.svg";
import { Hidden } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

const Training = () => {
  const [menu, setMenu] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [currentPlay, setCurrentPlay] = useState("");
  const [profileArea, setProfileArea] = useState(true);
  const [empDetails, setEmpDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const urls = [
    "V4kLU-pTwNQ",
    "4FwXqOT4-FM",
    "b3_THpKM4EU",
    "http://www.youtube.com/embed/nas1rJpm7wY?rel=0",
    "http://www.youtube.com/embed/nas1rJpm7wY?rel=0",
    "http://www.youtube.com/embed/nas1rJpm7wY?rel=0",
    "http://www.youtube.com/embed/nas1rJpm7wY?rel=0",
    "http://www.youtube.com/embed/nas1rJpm7wY?rel=0",
  ];

  const opts = {
    height: "500",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const handleVideo = (ele) => {
    setCurrentPlay(ele);
    setProfileArea(false);
  };
  const close = () => {
    setCurrentPlay("");
    setProfileArea(true);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setEmpDetails(res.data);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/youtubeRec/`,

        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            limit: 10,
            desgid: "2",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setVideoList(res.data);
        setIsLoading(false);
        //  setLeaves(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(videoList);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid" id="video">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Training and Development</h3>
              </div>
            </div>
          </div>
          {profileArea ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <a href="#">
                          <img
                            alt=""
                            src={
                              empDetails.profile_pic
                                ? empDetails.profile_pic.links
                                : smily
                            }
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="profile-basic"
                    style={{ color: "white", paddingTop: "30px" }}
                  >
                    <div className="row">
                      <div className="col-md-7">
                        <div className="row">
                          <h4
                            className=""
                            style={{
                              textTransform: "capitalize",
                              color: "white",
                            }}
                          >
                            {empDetails.name ? empDetails.name : "-"}
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-2">
                            <small>
                              points
                              {/* {empDetails.designation
                              ? empDetails.designation
                              : "-"} */}
                            </small>
                            {"    "}
                          </div>
                          <div className="col-3">
                            <small>
                              completed classes
                              {/* {empDetails.designation
                                  ? empDetails.designation
                                  : "-"} */}
                            </small>
                          </div>
                          <div className="col-3">
                            <small>
                              completed lessons
                              {/* {empDetails.designation
                                  ? empDetails.designation
                                  : "-"} */}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </>
          ) : (
            <>
              <div
                className="card mb-0"
                style={{ background: "rgba(255,255,255, 0.3)" }}
              >
                <div className="card-body">
                  <button
                    style={{
                      position: "relative",
                      marginLeft: "97%",
                      marginBottom: "1%",
                    }}
                    type="button"
                    className="close-btn"
                    onClick={() => close()}
                  >
                    <img src={closeIcon} />
                  </button>
                  <YouTube
                    videoId={currentPlay}
                    opts={opts}
                    onReady={onReady}
                  />
                </div>
              </div>

              <br />
            </>
          )}

          <div className="row">
            <div className="card mb-0">
              <div className="card-body" style={{ minHeight: "320px" }}>
                <h4>
                  <b>Recommended Videos for you</b>
                </h4>
                <div className="row">
                  {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "250px",
                      }}
                    >
                      <RotatingLines
                        strokeColor="#2e86c1"
                        strokeWidth="5"
                        animationDuration="0.50"
                        width="50"
                        marginRight="700"
                        visible={true}
                      />
                    </div>
                  )}
                  {!isLoading &&
                    videoList.map((ele) => (
                      <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                        <div className="cardone">
                          <iframe
                            style={{ borderRadius: "15px 15px 0px 0px" }}
                            src={ele.thumbnails[0].url}
                            frameborder="0"
                            Scrolling="no"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                            title={ele.type}
                          />
                          <p
                            className="video-desc"
                            class="d-inline-block"
                            tabindex="0"
                            data-bs-toggle="tooltip"
                            title={ele.title}
                            style={{
                              paddingLeft: "10px",
                              fontWeight: 500,
                              marginTop: "2%",
                              height: "25px",
                            }}
                          >
                            {ele.title.substr(0, 30) + "..."}
                          </p>
                          <div
                            className="flex"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                              height: "20px",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-sm"
                              style={{
                                minWidth: "70px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                backgroundColor: "#0d8dbf",
                                color: "white!important",
                                borderRadius: "20px",
                              }}
                              onClick={() => handleVideo(ele.id)}
                            >
                              <a style={{ color: "white" }} href="#video">
                                Start
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div className="row">
            <div className="card mb-0">
              <div className="card-body" style={{ minHeight: "320px" }}>
                <h4>
                  <b>New Videos For You</b>
                </h4>
                <div className="row">
                  {isLoading && (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "250px",
                      }}
                    >
                      <RotatingLines
                        strokeColor="#2e86c1"
                        strokeWidth="5"
                        animationDuration="0.50"
                        width="50"
                        marginRight="700"
                        visible={true}
                      />
                    </div>
                  )}
                  {!isLoading &&
                    videoList.map((ele) => (
                      <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                        <div className="cardone">
                          <iframe
                            style={{ borderRadius: "15px 15px 0px 0px" }}
                            src={ele.thumbnails[0].url}
                            frameborder="0"
                            Scrolling="no"
                            allow="autoplay; encrypted-media"
                            allowfullscreen
                            title={ele.type}
                          />
                          <p
                            className="video-desc"
                            class="d-inline-block"
                            tabindex="0"
                            data-bs-toggle="tooltip"
                            title={ele.title}
                            style={{
                              paddingLeft: "10px",
                              fontWeight: 500,
                              marginTop: "2%",
                              height: "25px",
                            }}
                          >
                            {ele.title.substr(0, 30) + "..."}
                          </p>
                          <div
                            className="flex"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textAlign: "center",
                              alignItems: "center",
                              // position: "relative",
                              // marginLeft: "40%",
                              height: "20px",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-sm"
                              style={{
                                minWidth: "70px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                backgroundColor: "#0d8dbf",
                                color: "white!important",
                                borderRadius: "20px",
                              }}
                              onClick={() => handleVideo(ele.id)}
                            >
                              <a style={{ color: "white" }} href="#video">
                                Start
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Training;
