import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../assets/OZHRMS.svg";
import "./navbar.css";

function Nav(props) {
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const onMenuClik = () => {
    props.onMenuClick();
  };
  return (
    <>
      <Navbar bg="blue" variant="dark" fixed="top" className="navbar">
        <Container className="hero">
          <a id="toggle_btn" href="#" onClick={handlesidebar}>
            <span className="bar-icon">
              <span />
              <span />
              <span />
            </span>
          </a>
          <Navbar.Brand href="#home">
            <img
              // src={logo}
              width="100"
              height="100"
              className=" align-top"
              alt="OZ HRMS"
            />
          </Navbar.Brand>
          <a
            style={{ marginLeft: "90%" }}
            id="mobile_btn"
            className="mobile_btn"
            href="#"
            onClick={() => onMenuClik()}
          >
            <i className="fa fa-bars" />
          </a>
        </Container>
      </Navbar>
    </>
  );
}

export default Nav;
