import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";

const CompOffLeave = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [empId, setEmpId] = useState("");
  const [location, setLocation] = useState("");
  const [searchData, setSearchData] = useState([]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const token = sessionStorage.getItem("access_token");

  const handleCompOffLeave = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/compoff_calc/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: {
            admid: JSON.parse(sessionStorage.getItem("admid")),
            year: month.split("-")[0],
            month: month.split("-")[1],
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele) => ({
            ...ele,
            all: ele.empid.length === 0 ? true : false,
          }))
        );
        setSearchData(
          res.data.map((ele) => ({
            ...ele,
            all: ele.empid.length === 0 ? true : false,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleCompOffLeave();
  }, [month]);

  const generateCompOff = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/compoff_calc/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Comp Off Leave generated successfully.",
        });
        handleCompOffLeave();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleSearch = () => {
    const newData = data
      .filter((x) => x.emp_name == (name == "" ? x.emp_name : name))
      .filter((x) => x.emp_code == (empId == "" ? x.emp_code : empId))
      .filter(
        (a) =>
          a.office_location == (location == "" ? a.office_location : location)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [name, location, empId]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "emp_name",
      //   sorter: (a, b) => a.leaveleavetype.length - b.type.length,
    },
    {
      title: "Employee Code",
      dataIndex: "emp_code",
      render: (text, record) => <>{text ? text : "--"}</>,
      //   sorter: (a, b) => a.leaveleavetype.length - b.type.length,
    },
    {
      title: "Comp Off Leave  ",
      dataIndex: "comp_off_val",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "Date",
      dataIndex: "date",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Status",
      dataIndex: "approved",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded" href="#">
            <i
              className={
                text === 1
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
                // ? "fa fa-dot-circle-o text-info"
                // : text === "Approved"
                // ? "fa fa-dot-circle-o text-success"
                // : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text === 1 ? "Approved" : "Pending"}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{
            height: "25px",
            fontSize: "14px",
            paddingTop: "2px",
            background: "#3278DB",
            borderRadius: "12px",
            border: "none",
            color: "#ffffff",
            width: "100px",
          }}
          // data-bs-toggle="modal"
          // data-bs-target="#edit_leave"
          disabled={text.approved === 1 ? true : false}
          onClick={(e) => generateCompOff(text.id)}
        >
          {text.approved === 1 ? "Generated" : "Generate"}
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Generate Comp Off Leaves</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_leave"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp; Add Leaves
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee Name"
                  options={[...new Set(data?.map((item) => item.emp_name))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    name !== ""
                      ? {
                          label: name,
                          value: name,
                        }
                      : null
                  }
                  onChange={(e) => setName(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee code"
                  options={[...new Set(data?.map((item) => item.emp_code))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    empId !== ""
                      ? {
                          label: empId,
                          value: empId,
                        }
                      : null
                  }
                  onChange={(e) => setEmpId(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Work Location"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.office_location)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    location !== ""
                      ? {
                          label: location,
                          value: location,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setLocation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (setName(""), setLocation(""), setEmpId(""))}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <div className="view-icons">
                    <input
                      type="month"
                      value={month}
                      min="2024-05"
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={searchData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};
export default CompOffLeave;
