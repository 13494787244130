import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Applogo from "../../../../assets/img/profiles/image23.png";
import Printer from "../../../../assets/img/profiles/printer-line.svg";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect } from "react";
// import Select from "react-select";
import {
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Select,
} from "antd";

const RevisePayroll = (props) => {
  const { empID, setToggle } = props;
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");

  const [form] = Form.useForm();
  const [date, setDate] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const [totalCtc, setTotalCtc] = useState(0);
  const [fixedPer, setFixedPer] = useState(0);
  const [fixed, setFixed] = useState(0);
  const [dummy, setDummy] = useState(true);
  const [showPre, setShowPre] = useState(false);
  const [showPreCTC, setShowPreCTC] = useState(false);
  const [earn, setEarn] = useState([]);
  const [Ded, setDed] = useState([]);
  const [varia, setVaria] = useState([]);
  const [allData, setAllData] = useState({});
  const [salData, setSalData] = useState({
    monthly_ctc: 0,
    annual_ctc: 0,
    monthly_gross: 0,
    annual_gross: 0,
    monthly_net: 0,
    annual_net: 0,
  });

  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      value: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const [enrg, setEnrg] = useState([
    {
      key: 1,
      name: "Basic",
      type: "percent",
      value: 50,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "HRA",
      type: "percent",
      value: 20,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 5,
      name: "Special Alowances",
      type: "percent",
      value: 30,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 3,
      name: "WiFi Allowance",
      type: "value",
      value: 1200,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);

  const [otheV, setOtheV] = useState([
    {
      name: "Other Variables",
      type: "-",
      value: "-",
      monthly: 0,
      annually: 0,
    },
  ]);

  const [deduct, setDeduct] = useState([
    {
      key: 1,
      name: "Professional Tax",
      type: "value",
      value: 500,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "Other Tax",
      type: "percent",
      value: 5,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);

  const [variables, setVariables] = useState([
    {
      key: 1,
      name: "Performance Variable",
      type: "value",
      value: 500,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
    {
      key: 2,
      name: "Annual Retaintion Variable",
      type: "percent",
      value: 5,
      monthly: 0,
      annually: 0,
      enabled: true,
    },
  ]);

  const handleSalaryFix = (fix) => {
    setFixed(fix);
    setFixedPer((fix * 100) / totalCtc);
    // const fix = (totalCtc * per) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
  };

  const handleSalaryPer = (per) => {
    setFixed((totalCtc * per) / 100);
    setFixedPer(per);
    const fix = (totalCtc * per) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
  };

  const handleSalary = (handleOther) => {
    setFixed((totalCtc * fixedPer) / 100);
    const fix = (totalCtc * fixedPer) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
    // handleOther();
  };

  const handleOther = () => {
    // const rem = p.filter((ele) => ele.name !== "Other Allowance");
    const sum = enrg.reduce((accumulator, object) => {
      console.log(accumulator);
      return accumulator + object.annually;
    }, 0);
    const oth = fixed - sum;
    console.log(sum);
    console.log(oth);
    setEnrg(
      enrg.map((ele) => {
        return {
          name: ele.name,
          type: ele.type,
          value: ele.value,
          monthly: ele.name === "Other Allowance" ? oth / 12 : ele.monthly,
          annually: ele.name === "Other Allowance" ? oth : ele.annually,
        };
      })
    );
  };

  useEffect(() => {
    handleSalary(handleOther);
  }, [totalCtc, dummy]);

  const option = [
    {
      label: "Percent",
      value: "Percent",
    },
    {
      label: "Value",
      value: "Value",
    },
  ];

  const option1 = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const [menu, setMenu] = useState(false);
  const [ctcType, setCtcType] = useState(0);

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...enrg];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };

  const save1 = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...variables];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setVariables(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };

  const save2 = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...deduct];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDeduct(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setDeduct(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : inputType === "select" ? (
        <Input />
      ) : dataIndex === "type" ? (
        <Select options={option} />
      ) : dataIndex === "visible_payslip" ? (
        <Select options={option1} />
      ) : (
        <Select options={option1} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          // <div className="table-action">
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
          //  <Popconfirm
          //         title="Sure to delete?"
          //         onConfirm={() => handleDelete(record.key)}
          //       >
          //         <a>Delete</a>
          //       </Popconfirm>
          //     </div>
        );
      },
    },
  ];

  const columns1 = [
    {
      title: "Name",
      dataIndex: "name",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save1(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          record.name !== "Other Variables" &&
            record.name !== "Total Variables" && (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            )
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    // {
    //   title: "Taxable",
    // },
    // {
    //   title: "Annually",
    // },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save2(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const columns4 = [
    { title: "Name of Component", dataIndex: "name" },
    { title: "Monthly", dataIndex: "monthly", align: "right" },
    { title: "Annually", dataIndex: "annually", align: "right" },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedColumns1 = columns1.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          comp_id: sessionStorage.getItem("coid"),
          toggle: "t3",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setEnrg(
          res.data.earning.map((ele, i) => ({
            key: `E+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            amount: 0,
            annually: 0,
            enabled: true,
            toggle: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "A",
          }))
        );
        setVariables(
          res.data.variables.map((ele, i) => ({
            key: `V+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
            toggle: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "V",
          }))
        );
        setDeduct(
          res.data.deduction.map((ele, i) => ({
            key: `D+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
            toggle: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "D",
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index][event.target.name] = event.target.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChangeToggle = (index, event, state, setState) => {
    let data = [...state];
    data[index]["toggle"] = !data[index]["toggle"];
    // data[index][event] = event.value;
    setState(data);
  };
  console.log(enrg);
  console.log(deduct);

  const calculateSalary = async (e) => {
    e.preventDefault();
    const payload = enrg
      .concat(deduct)
      .concat(variables)
      .map((ele) => ({
        id: ele.id,
        toggle: ele.toggle,
        amt: parseInt(ele.amount) * 12,
      }));
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calc_salary/`, payload)
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setEarn(
          res.data.component
            .filter((item) => item.type === "A")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
              toggle: ele.toggle,
            }))
        );
        setDed(
          res.data.component
            .filter((item) => item.type === "D")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
              toggle: ele.toggle,
            }))
        );
        setVaria(
          res.data.component
            .filter((item) => item.type === "V")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
              toggle: ele.toggle,
            }))
        );
        setShowPre(true);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const calculateCTCSalary = async (e) => {
    e.preventDefault();
    const payload = enrg
      .concat(deduct)
      .concat(variables)
      .map((ele) => ({
        id: ele.id,
        name: ele.name,
        amt: ele.value == null ? 0 : ele.value,
        payslip_visible: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: ele.comp_type,
        type: ele.type === "Value" ? "val" : "per",
        comp_id: [0],
      }));
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calculate_salary_sad/`, {
        monthly_ctc: parseInt(salData.monthly_ctc),
        annual_ctc: parseInt(totalCtc),
        monthly_gross: "",
        annual_gross: "",
        fixed_ctc: parseInt(fixed) / 12,
        monthly_net: "",
        annual_net: "",
        component: payload,
      })
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setEarn(
          res.data.component
            .filter((item) => item.comp_type === "A")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setDed(
          res.data.component
            .filter((item) => item.comp_type === "D")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setVaria(
          res.data.component
            .filter((item) => item.comp_type === "V")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setShowPreCTC(true);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleValue = () => {
    const totalErn = enrg.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);
    const totalErnM = enrg.reduce((accumulator, object) => {
      return accumulator + object.monthly;
    }, 0);

    const totalGross = enrg
      .filter((ele) => ele.visible_payslip !== 0)
      .reduce((accumulator, object) => {
        return accumulator + object.annually;
      }, 0);
    const totalGrossM = enrg
      .filter((ele) => ele.visible_payslip !== 0)
      .reduce((accumulator, object) => {
        return accumulator + object.monthly;
      }, 0);
    const totalDeductM = deduct.reduce((accumulator, object) => {
      return accumulator + object.monthly;
    }, 0);

    const totalDeduct = deduct.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);

    setSalData({
      monthly_ctc: totalErnM.toFixed(0),

      monthly_gross: totalGrossM.toFixed(0),
      annual_gross: totalGross.toFixed(0),
      monthly_net: (totalGrossM - totalDeductM).toFixed(0),
      annual_net: (totalGross - totalDeduct).toFixed(0),
    });
  };

  useEffect(() => {
    handleValue();
  }, [enrg, deduct, variables]);

  const handleUpdateSalary = (e) => {
    e.preventDefault();
    if (
      !date
      // !applyLeave.from_date ||
      // !applyLeave.to_date ||
      // !applyLeave.type.value
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all mandatory fields",
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/update_salary/`, {
          empid: empID,
          effective_date: date,
          CTC:
            // ctcType === 0
            //   ?
            {
              // Anual_ctc: totalCtc,
              monthly_ctc: allData.monthly_ctc,
              annual_ctc: allData.annual_ctc,
              monthly_gross: allData.monthly_gross,
              annual_gross: allData.annual_gross,
              monthly_net: allData.monthly_net,
              annual_net: allData.annual_net,

              earning:
                // fixedEarning.map((ele) => ({
                //   name: ele.name,
                //   annual_amt: ele.monthly * 12,
                // })),
                earn
                  // .concat(othea)
                  .map((ele) => {
                    return {
                      name: ele.name,
                      annual_amt: ele.annually,
                      visible_payslip: ele.visible_payslip,
                      taxable: ele.taxable,
                    };
                  })
                  .filter((item) => item.name !== "Total Earnings"),

              deduction:
                // otherEarning.map((ele) => ({
                //   name: ele.name,
                //   annual_amt: ele.monthly * 12,
                // })),
                Ded.map((ele) => ({
                  name: ele.name,
                  annual_amt: ele.annually,
                  visible_payslip: ele.visible_payslip,
                  taxable: ele.taxable,
                })),
              variables: varia
                // .concat(otheV)
                .map((ele) => {
                  return {
                    name: ele.name,
                    annual_amt: ele.annually,
                    visible_payslip: ele.visible_payslip,
                    taxable: ele.taxable,
                  };
                })
                .filter((item) => item.name !== "Total Variables"),
              //   }
              // : {
              //     // Anual_ctc: totalCtc,
              //     monthly_ctc: salData.monthly_ctc,
              //     annual_ctc: totalCtc,
              //     monthly_gross: salData.monthly_gross,
              //     annual_gross: salData.annual_gross,
              //     monthly_net: salData.monthly_net,
              //     annual_net: salData.annual_net,
              //     earning:
              //       // fixedEarning.map((ele) => ({
              //       //   name: ele.name,
              //       //   annual_amt: ele.monthly * 12,
              //       // })),
              //       enrg
              //         // .concat(othea)
              //         .map((ele) => {
              //           return {
              //             name: ele.name,
              //             annual_amt: ele.annually,
              //             visible_payslip: ele.visible_payslip,
              //             taxable: ele.taxable,
              //           };
              //         })
              //         .filter((item) => item.name !== "Total Earnings"),

              //     deduction:
              //       // otherEarning.map((ele) => ({
              //       //   name: ele.name,
              //       //   annual_amt: ele.monthly * 12,
              //       // })),
              //       deduct.map((ele) => ({
              //         name: ele.name,
              //         annual_amt: ele.annually,
              //         visible_payslip: ele.visible_payslip,
              //         taxable: ele.taxable,
              //       })),
              //     variables: variables
              //       // .concat(otheV)
              //       .map((ele) => {
              //         return {
              //           name: ele.name,
              //           annual_amt: ele.annually,
              //           visible_payslip: ele.visible_payslip,
              //           taxable: ele.taxable,
              //         };
              //       })
              //       .filter((item) => item.name !== "Total Variables"),
            },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Salary updated successfully",
            timer: 2000,
          });

          setToggle(0);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  return (
    <>
      <h3
        style={{ color: "white", textDecoration: "bold", marginBottom: "20px" }}
      >
        Salary Revision
      </h3>
      <div
        className="col-md-12 "
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "12px",
        }}
      >
        <input
          type="radio"
          id="basic"
          name="fav_language"
          style={{ width: "20px", cursor: "pointer" }}
          checked={ctcType === 0 ? true : false}
          onClick={() => {
            setCtcType(0);
          }}
        />{" "}
        &nbsp; &nbsp; &nbsp;
        <label htmlFor="basic">
          <span style={{ color: "white" }}>Based on Basic</span>
        </label>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <input
          type="radio"
          id="ctc"
          name="fav_language"
          style={{ width: "20px", cursor: "pointer" }}
          checked={ctcType === 1 ? true : false}
          onClick={() => {
            setCtcType(1);
          }}
        />{" "}
        &nbsp; &nbsp; &nbsp;
        <label htmlFor="ctc">
          <span style={{ color: "white" }}>Based on CTC</span>
        </label>
        <br />
      </div>
      {ctcType === 1 && (
        <div className="col-md-12 row">
          <div className="col-md-2"></div>
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
            className="row col-md-8"
          >
            {/* <div className="">
        <input
          type="radio"
          id="html"
          name="fav_language"
          value="HTML"
          checked
        />
        <label for="html">Total CTC</label>
        &nbsp; &nbsp;
        <input type="radio" id="css" name="fav_language" value="CSS" />
        <label for="css">Basic Salary</label>
      </div> */}
            {!showPreCTC ? (
              <>
                <div className="col-md-6">
                  <h4 style={{ fontWeight: "600" }}>Salary Details</h4>
                </div>
                <div className="col-md-6">
                  {" "}
                  <div style={{ display: "flex" }}>
                    <span>
                      salary revision to be effective from
                      <span className="text-danger">*</span>{" "}
                    </span>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>
                      <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        style={{ borderRadius: "5px", height: "30px" }}
                      />
                    </span>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <div className="row col-md-12">
                  <div className=" col-md-4">
                    <label>Total CTC :</label> &nbsp;
                    <input
                      placeholder="Total CTC"
                      className="salary_input"
                      value={totalCtc}
                      onChange={(e) => setTotalCtc(e.target.value)}
                    />
                  </div>

                  <div className=" col-md-4">
                    <label>
                      Fixed CTC % <sup className="sup">*</sup>:
                    </label>
                    &nbsp;
                    <input
                      placeholder="fixed CTC %"
                      className="salary_input"
                      value={fixedPer}
                      onChange={(e) => handleSalaryPer(e.target.value)}
                      // onChange={(e) => setFixedPer(e.target.value)}
                    />
                  </div>
                  <div className=" col-md-4">
                    <label>Fixed CTC :</label> &nbsp;
                    <input
                      placeholder="fixed CTC"
                      className="salary_input"
                      value={fixed}
                      onChange={(e) => handleSalaryFix(e.target.value)}
                      // onChange={(e) => setFixed(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                {/* <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>{`Monthly CTC`}:</label> &nbsp;&nbsp;&nbsp;
                      {salData.monthly_ctc}
                    </div>
                    <div>
                      {" "}
                      <label>{`Monthly Gross`}:</label> &nbsp;&nbsp;&nbsp;
                      {salData.monthly_gross}
                    </div>
                    <div>
                      {" "}
                      <label>Monthly Gross before TDS :</label>
                      &nbsp;&nbsp;&nbsp;
                      {salData.monthly_net}
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>Annual Gross:</label>&nbsp;&nbsp;&nbsp;
                      {salData.annual_gross}
                    </div>
                    <div>
                      <label>Annual Gross before TDS:</label>&nbsp;&nbsp;&nbsp;
                      {salData.annual_net}
                    </div>
                  </div>
                </div> */}

                <br />

                <div>
                  <p className="salary_head">Earnings:</p>
                  {/* <button
          onClick={(e) => handleAdd(e)}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a row
        </button> */}
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      dataSource={enrg}
                      columns={mergedColumns}
                      // rowClassName="editable-row"
                      pagination={false}
                      // rowClassName={(record) =>
                      //   !record.enabled ? "disabled-row" : "editable-row"
                      // }
                      // rowSelection={rowSelection}
                    />
                  </Form>
                  {/* <SalaryTable data={enrg} /> */}
                </div>
                {/* <div className="other">
        <p>Other Allowances</p>
        <p>{`-`}</p>
        <p>{`-`}</p>
        <p className="other-r">{othea[0].monthly}</p>
        <p className="other-r">{othea[0].annually}</p>
      </div> */}
                <br />
                <div>
                  <p className="salary_head"> Standard Deductions:</p>
                  <Form form={form} component={false}>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      dataSource={deduct}
                      pagination={false}
                      columns={mergedColumns2}
                      // rowClassName="editable-row"
                      // pagination={false}
                      rowClassName={(record) =>
                        !record.enabled ? "disabled-row" : "editable-row"
                      }
                    />
                  </Form>
                </div>
                <br />
                <div>
                  <p className="salary_head">Variables:</p>
                  <Form form={form} component={false}>
                    <Table
                      // columns={columns1}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      dataSource={variables.concat(otheV)}
                      pagination={false}
                      columns={mergedColumns1}
                      // rowClassName="editable-row"
                      // pagination={false}
                      rowClassName={(record) =>
                        !record.enabled ? "disabled-row" : "editable-row"
                      }
                    />
                  </Form>

                  {/* <div className="other">
          <p>Other Allowances</p>
          <p>{`-`}</p>
          <p>{`-`}</p>
          <p className="other-r">{otherAllow / 12}</p>
          <p className="other-r">{otherAllow}</p>
        </div> */}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn-sub-ctcbased"
                    style={{ background: "#3298DB", color: "white" }}
                    onClick={(e) => calculateCTCSalary(e)}
                  >
                    Calculate
                  </button>
                </div>
              </>
            ) : (
              <>
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button
                      onClick={() => setShowPreCTC(false)}
                      style={{
                        width: "fit-content",
                        border: "none",
                        background: "#004693",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      Recalculate
                    </button>
                  </div>

                  <br />
                  {earn.length !== 0 && (
                    <>
                      <label>Earnings</label>
                      <br />
                      <Table
                        columns={columns4}
                        dataSource={earn}
                        pagination={false}
                      />
                      <br />
                    </>
                  )}
                  <br />
                  {Ded.length !== 0 && (
                    <>
                      <label>Deductions</label>
                      <br />
                      <Table
                        columns={columns4}
                        dataSource={Ded}
                        pagination={false}
                      />
                      <br />
                    </>
                  )}
                  <br />
                  {varia.length !== 0 && (
                    <>
                      <label>Variables</label>
                      <br />
                      <Table
                        columns={columns4}
                        dataSource={varia}
                        pagination={false}
                      />
                    </>
                  )}

                  <br />
                  <br />

                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <p className="col-md-4">
                      <b>Monthly CTC :</b>&nbsp;
                      {allData.monthly_ctc}
                    </p>
                    <p className="col-md-4">
                      <b>Monthly Gross :</b>&nbsp;
                      {allData.monthly_gross}
                    </p>
                    <p className="col-md-4">
                      <b>Monthly Net :</b>&nbsp;
                      {allData.monthly_net}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className="col-md-4">
                      <b>Annual CTC :</b>&nbsp;
                      {allData.annual_ctc}
                    </p>
                    <p className="col-md-4">
                      <b>Annual Gross :</b>&nbsp;
                      {allData.annual_gross}
                    </p>
                    <p className="col-md-4">
                      <b>Annual Net :</b>&nbsp;
                      {allData.annual_net}
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className={
                        ctcType === 1
                          ? "btn-sub-ctcbased"
                          : "btn-sub-basicbased"
                      }
                      style={{ background: "#3298DB", color: "white" }}
                      onClick={(e) => handleUpdateSalary(e)}
                    >
                      Save
                    </button>
                  </div>
                </>
              </>
            )}
          </div>
        </div>
      )}

      {ctcType === 0 && (
        <div className="page-wrapper">
          {/* <div className="content container-fluid"> */}
          <div
            className="content container-fluid"
            style={{ marginTop: "-70px" }}
          >
            <div className="card col-md-10" style={{ padding: "20px" }}>
              {!showPre ? (
                <>
                  <div className="col-md-12 row">
                    <div className="col-md-6">
                      <h4 style={{ fontWeight: "600" }}>Earnings</h4>
                    </div>
                    <div className="col-md-6">
                      {" "}
                      <div style={{ display: "flex" }}>
                        <span>
                          salary revision to be effective from{" "}
                          <span className="text-danger">*</span>{" "}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            style={{ borderRadius: "5px", height: "30px" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  {enrg.map((ele, i) => (
                    <div
                      style={{ display: "flex", padding: "10px 10px 5px 10px" }}
                      className="col-md-12 row"
                    >
                      <div
                        className="form-group col-md-12"
                        style={{ display: "flex" }}
                      >
                        <p className="col-md-5">{ele.name}</p>
                        <div
                          className="col-md-7"
                          style={{ display: "flex", gap: "10px" }}
                        >
                          <input
                            name="amount"
                            type="number"
                            // disabled={ele.type === "Value" ? false : true}
                            value={ele.amount}
                            className="form-control"
                            placeholder="Enter Amount"
                            onChange={(e) =>
                              handleFormChange(i, e, enrg, setEnrg)
                            }
                          />{" "}
                          <input
                            type="checkbox"
                            id={ele.id}
                            className="check"
                            name={ele.name}
                            checked={ele.toggle}
                            onChange={(e) =>
                              handleFormChangeToggle(i, e, enrg, setEnrg)
                            }
                          />
                          <label htmlFor={ele.id} className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  <h4>Deductions</h4>
                  {deduct.map((ele, i) => (
                    <div
                      style={{ display: "flex", padding: "10px 10px 5px 10px" }}
                      className="col-md-12 row"
                    >
                      <div
                        className="form-group col-md-12 "
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <p className=" col-md-5">{ele.name}</p>
                        <div className="col-md-7" style={{ display: "flex" }}>
                          <input
                            type="number"
                            name="amount"
                            disabled={ele.type === "Value" ? false : true}
                            value={ele.amount}
                            onChange={(e) =>
                              handleFormChange(i, e, deduct, setDeduct)
                            }
                            className="form-control "
                            placeholder="Enter Amount"
                          />{" "}
                          <input
                            type="checkbox"
                            id={ele.id}
                            className="check"
                            name={ele.name}
                            checked={ele.toggle}
                            onChange={(e) =>
                              handleFormChangeToggle(i, e, deduct, setDeduct)
                            }
                          />
                          <label htmlFor={ele.id} className="checktoggle">
                            checkbox
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                  {variables.length !== 0 && (
                    <>
                      <h4>Variables</h4>
                      {variables.map((ele, i) => (
                        <div
                          style={{
                            display: "flex",
                            padding: "10px 10px 5px 10px",
                          }}
                          className="col-md-12 row"
                        >
                          <div
                            className="form-group col-md-12 "
                            style={{ display: "flex" }}
                          >
                            <p className=" col-md-5">{ele.name}</p>
                            <div
                              className="col-md-7"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <input
                                type="number"
                                name="amount"
                                disabled={ele.type === "Value" ? false : true}
                                value={ele.amount}
                                onChange={(e) =>
                                  handleFormChange(
                                    i,
                                    e,
                                    variables,
                                    setVariables
                                  )
                                }
                                className="form-control "
                                placeholder="Enter Amount"
                              />{" "}
                              <input
                                type="checkbox"
                                id={ele.id}
                                className="check"
                                name={ele.name}
                                checked={ele.toggle}
                                onChange={(e) =>
                                  handleFormChangeToggle(
                                    i,
                                    e,
                                    variables,
                                    setVariables
                                  )
                                }
                              />
                              <label htmlFor={ele.id} className="checktoggle">
                                checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button
                      onClick={(e) => calculateSalary(e)}
                      style={{
                        width: "fit-content",
                        border: "none",
                        background: "#004693",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      Calculate CTC
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <button
                      onClick={() => setShowPre(false)}
                      style={{
                        width: "fit-content",
                        border: "none",
                        background: "#004693",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      Recalculate
                    </button>
                  </div>

                  <br />
                  {earn.length !== 0 && (
                    <>
                      <label>Earnings</label>
                      <br />
                      <Table
                        columns={columns4}
                        dataSource={earn}
                        pagination={false}
                      />
                      <br />
                    </>
                  )}

                  {Ded.length !== 0 && (
                    <>
                      <label>Deductions</label>
                      <br />
                      <Table
                        columns={columns}
                        dataSource={Ded}
                        pagination={false}
                      />
                      <br />
                    </>
                  )}

                  {varia.length !== 0 && (
                    <>
                      <label>Variables</label>
                      <br />
                      <Table
                        columns={columns}
                        dataSource={varia}
                        pagination={false}
                      />
                    </>
                  )}

                  <br />
                  <div style={{ display: "flex" }}>
                    <p className="col-md-4">
                      <b>Monthly CTC :</b>&nbsp;
                      {allData.monthly_ctc}
                    </p>
                    <p className="col-md-4">
                      <b>Monthly Gross :</b>&nbsp;
                      {allData.monthly_gross}
                    </p>
                    <p className="col-md-4">
                      <b>Monthly Net :</b>&nbsp;
                      {allData.monthly_net}
                    </p>
                  </div>
                  <div style={{ display: "flex" }}>
                    <p className="col-md-4">
                      <b>Annual CTC :</b>&nbsp;
                      {allData.annual_ctc}
                    </p>
                    <p className="col-md-4">
                      <b>Annual Gross :</b>&nbsp;
                      {allData.annual_gross}
                    </p>
                    <p className="col-md-4">
                      <b>Annual Net :</b>&nbsp;
                      {allData.annual_net}
                    </p>
                  </div>
                </>
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className={
                    ctcType === 1 ? "btn-sub-ctcbased" : "btn-sub-basicbased"
                  }
                  style={{ background: "#3298DB", color: "white" }}
                  onClick={(e) => handleUpdateSalary(e)}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          className={ctcType === 1 ? "btn-sub-ctcbased" : "btn-sub-basicbased"}
          style={{ background: "#3298DB", color: "white" }}
          onClick={() => handleUpdateSalary()}
        >
          Save
        </button>
      </div> */}
      {/* <div className="row">
        <div className="col-md-8">
          <div
            className="card"
            style={{
              background: " #FFFFFF",
              borderRadius: "10px",
              paddingTop: "8px",
            }}
          >
            <ul className="list-unstyled mb-4 mt-3 mx-4">
              <li>
                {" "}
                <h5>
                  <b>Select One: </b>
                </h5>
              </li>
              <li style={{ margin: "25px 45px " }}>
                <b>CTC Revision by amount</b>
                {""} &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;
                <input type="number" style={{ borderRadius: "5px" }} />
              </li>
              <li style={{ margin: "25px 45px " }}>
                <b>CTC Revision by percentage</b> &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;
                <input type="number" style={{ borderRadius: "5px" }} />
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <div
            className="card"
            style={{
              background: " #FFFFFF",
              borderRadius: "10px",
              paddingTop: "8px",
            }}
          >
            <ul className="list-unstyled mb-4 mt-3 mx-4">
              <li>
                {" "}
                <h4>
                  <b>Payout Details</b>
                </h4>
              </li>
              <li style={{ margin: "13px 15px ", display: "flex" }}>
                <span>salary revision to be effective from </span>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <input
                    type="date"
                    style={{ borderRadius: "5px", height: "30px" }}
                  />
                </span>
              </li>
              <li style={{ margin: "10px 15px ", display: "flex" }}>
                <span>salary revision to be Paid out from </span>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  <input
                    type="date"
                    style={{ borderRadius: "5px", height: "30px" }}
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <div
            className="card"
            style={{
              background: " #FFFFFF",
              borderRadius: "10px",
            }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 m-b-0 m-t-10">
                  <ul className="list-unstyled mb-0">
                    <li>
                      {" "}
                      <h4>
                        <b>Mahesh Suthar</b>
                      </h4>
                    </li>
                    <li>
                      <b>Emp ID: </b> <span>MI009</span>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 m-b-0 m-t-10">
                  <div className="invoice-details">
                    <h5>
                      <b>Monthly CTC: </b> 40000.00
                    </h5>
                    <ul className="list-unstyled">
                      <li>
                        <b> Annual CTC: </b>
                        <span>800000.00</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <h4 className="m-b-10">
                      <strong style={{ color: "green" }}>Earnings</strong>
                      <strong style={{ marginLeft: "60%" }}>Amount</strong>
                    </h4>
                    <table className=" table table-borderless ">
                      <tbody className="">
                        <tr>
                          <th>
                            <strong
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Components
                            </strong>{" "}
                          </th>
                          <th style={{ textAlign: "end" }}>
                            <strong>Monthly</strong>{" "}
                          </th>
                          <th>
                            <strong
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              Annually
                            </strong>{" "}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Basic
                            </span>{" "}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <span>21,667.00</span>{" "}
                          </td>
                          <td>
                            <span
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              2,60,000.00
                            </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              HRA
                            </span>{" "}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <span>10,834.00</span>{" "}
                          </td>
                          <td>
                            <span
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              1,30,000.00
                            </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Telephone Allowance
                            </span>{" "}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <span>2500.00</span>{" "}
                          </td>
                          <td>
                            <span
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              30,000.00
                            </span>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Professional Allowance
                            </span>{" "}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <span>15,000.00</span>{" "}
                          </td>
                          <td>
                            <span
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              18,000.00
                            </span>{" "}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Leave Travel Allowance
                            </span>{" "}
                          </td>
                          <td style={{ textAlign: "end" }}>
                            <span>6520.00</span>{" "}
                          </td>
                          <td>
                            <span
                              className="float-end"
                              style={{ marginRight: "30px" }}
                            >
                              78,240.00
                            </span>{" "}
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <th>
                            <strong
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Add Ons
                            </strong>{" "}
                          </th>
                          <td>
                            <strong> </strong>{" "}
                          </td>
                          <th>
                            <strong>Amount</strong>{" "}
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <span
                              className="float-start"
                              style={{ marginLeft: "30px" }}
                            >
                              Variables
                            </span>{" "}
                          </td>
                          <td>
                            <strong> </strong>{" "}
                          </td>

                          <td>
                            <span>15,000.00</span>{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h5 className="m-b-10">
                      <strong style={{ marginLeft: "5%", fontWeight: "800" }}>
                        Add Ons
                      </strong>
                      <strong style={{ marginLeft: "58%", fontWeight: "800" }}>
                        Amount
                      </strong>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="submit-section1"
        style={{ maxWidth: "819px", marginTop: "-20px", borderRadius: "10px" }}
      >
        <button
          className="btn-sub"
          style={{ background: "#3298DB", color: "white" }}
          //onClick={() => handleEmployeeRegistration()}
        >
          Save
        </button>
        <button
          className="btn-sub"
          style={{ background: "white", color: "#3298DB" }}
          // onClick={() => handleProfileEdit()}
          // onClick={() => setStep(0)}
          onClick={() => setToggle(0)}
        >
          Cancel
        </button>
      </div> */}
    </>
  );
};

export default RevisePayroll;
