import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../pages/Sidebar/header";
import calm from "../../assets/img/profiles/calm1.svg";
import stress from "../../assets/img/profiles/stress1.svg";
import busy from "../../assets/img/profiles/work1.svg";
import angry from "../../assets/img/profiles/angry1.svg";
import happy from "../../assets/img/profiles/happy1.svg";
import Pantry from "../../assets/img/profiles/pantry.svg";
import Emergency from "../../assets/img/profiles/emergency.svg";
import Tea from "../../assets/img/profiles/tea-cup.svg";
import GreenTea from "../../assets/img/profiles/green-tea.svg";
import Coffee from "../../assets/img/profiles/coffee-cup.svg";
import Nachos from "../../assets/img/profiles/nachos.svg";
import Biscuit from "../../assets/img/profiles/cookies.svg";
import SmileEmoji from "../../assets/img/profiles/smilie-emoji.gif";
import { FaMinus, FaPlus } from "react-icons/fa";
import Cards from "../../pages/cards/cards";
import { data, trending } from "../../Constants/happyZoneConstant";
import scratch from "../../assets/img/profiles/Mask group.png";
import TwoZero from "../../assets/img/profiles/2048edited.png";
import TicTac from "../../assets/img/profiles/tactoe.png";
import CandyCrushh from "../../assets/img/profiles/candycrushh.png";
import Sketchboard from "../../assets/img/profiles/sketchboard.png";
import FindRing from "../../assets/img/profiles/findring.png";
import MineSweeper from "../../assets/img/profiles/sweeper.png";
import Swal from "sweetalert2";
import axios from "axios";
import ReactPlayer from "react-player";
import closeIcon from "../../assets/img/profiles/whiteclose.svg";
import sendIcon from "../../assets/img/profiles/sendIcon.svg";

const HappyZoneTwo = () => {
  const [clickVideos, setClickVideos] = useState(false);
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState({
    expanded: false,
    itemsToShow: 4,
    STRESS: [
      {
        id: 21,
        name: "Never Give Up",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/X_sI-qjUIY4",
        img_url: "",
        description:
          "A motivational video that talks about the importance of not giving up, being in the mindset of self-discipline and staying motivated/",
      },
      {
        id: 22,
        name: "One of the Greatest Speeches Ever by Steve Jobs",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/Tuw8hxrFBH8",
        img_url: "",
        description:
          "A TED talk by Steve Jobs, where he talks about the general sense of motivation and never giving up. If you are feeling low and stressed, try watching this video, and it might change the way you feel.",
      },
      {
        id: 23,
        name: "Sundar Pichai's Eye Opening Speech",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/8Aux7nqu6w8",
        img_url: "",
        description:
          "Hear Sundar Pichai talk about his experiences, and why changes are important.",
      },
      {
        id: 24,
        name: "Control Your Life",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/3gKYFWVIHus",
        img_url: "",
        description:
          "Why is taking control of your life so important? A speech that teaches the importance of self-control and how you can be the best version of yourself.",
      },
    ],
    ANGRY: [
      {
        id: 25,
        name: "Powerful Motivational Speech by Sandeep Maheshwari",
        content_type: "VIDEO",
        emotion_type: "ANGRY",
        video_url: "https://youtu.be/kBo3ipDEH6k",
        img_url: "",
        description:
          "In case you are feeling angry at the moment, try listening to what Sandesh Maheshwari has to say about what life really is about and what challenges one faces. It is important to know everything is temporary. Do not hold on to ill feelings.",
      },
      {
        id: 26,
        name: "The Power of Positivity",
        content_type: "VIDEO",
        emotion_type: "ANGRY",
        video_url: "https://youtu.be/HwLK9dBQn0g",
        img_url: "",
        description:
          "The importance of positive thinking when negative thinking takes over. Anger can make you do things that you do not mean to, instead try to channel positive feelings.",
      },
      {
        id: 27,
        name: "14 Sign That You Have a Stronger Mental Capacity Than Most People",
        content_type: "VIDEO",
        emotion_type: "ANGRY",
        video_url: "https://youtu.be/ZKo5aRaSxG4",
        img_url: "",
        description:
          "Try to know where you stand mentally and your mental capacity to handle challenges. Try to learn what you can do to improve.",
      },
      {
        id: 28,
        name: "Steve Harvey",
        content_type: "VIDEO",
        emotion_type: "ANGRY",
        video_url: "https://youtu.be/J5L8GrMV03w",
        img_url: "",
        description:
          "Hear Steve Harvey talk about why is it important to not let negative emotions impact you.",
      },
    ],
    CALM: [
      {
        id: 29,
        name: "10 Things That Shows You are Smart, According to Experts",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/J4xQec5I_sw",
        img_url: "",
        description:
          "It talks about Topics Ranging From Health & welness To Human Relationships, Self Development, Motivation & Fun",
      },
      {
        id: 30,
        name: "Jack Ma - Learn From The Mistakes",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/K99sC9e_psY",
        img_url: "",
        description:
          "Learn the attitude you should have to be success in building your own business",
      },
      {
        id: 31,
        name: "Game of Life by Sandeep Maheshwari",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/3TfRSGrEE6I",
        img_url: "",
        description:
          "Teaching us how to play the game of life with both success & failures",
      },
      {
        id: 32,
        name: "4 Minutes To Start Your Day Right",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/HgiiY9TLtX8",
        img_url: "",
        description: "Morning Motivation and Positivity",
      },
    ],
    BUSY: [
      {
        id: 33,
        name: "Break Your Negative Thinking",
        content_type: "VIDEO",
        emotion_type: "BUSY",
        video_url: "https://youtu.be/u53NWioihto",
        img_url: "",
        description: "Wake Up Postive Motivational Video",
      },
      {
        id: 34,
        name: "This Song Gave Me Strength To Fight One More Day",
        content_type: "VIDEO",
        emotion_type: "BUSY",
        video_url: "https://youtu.be/ooOak5FVkpM",
        img_url: "",
        description:
          "Song about motivation and not to give up as the sun will shine again",
      },
      {
        id: 35,
        name: "Don’t Forget To Smile in Life by Gaur Gopal Prabhu",
        content_type: "VIDEO",
        emotion_type: "BUSY",
        video_url: "https://youtu.be/_OOQP7QNca8",
        img_url: "",
        description:
          "Talks about how to keep on smiling in everyday life even tho there are tons of problem",
      },
      {
        id: 36,
        name: "R. Madhavan Tells How to be Exceptional",
        content_type: "VIDEO",
        emotion_type: "BUSY",
        video_url: "https://youtu.be/aoR607khyEQ",
        img_url: "",
        description: "Talks about importance of Soft Skills and motivation",
      },
    ],
    HAPPY: [
      {
        id: 37,
        name: "Learn To Love & Value Yourself",
        content_type: "VIDEO",
        emotion_type: "HAPPY",
        video_url: "https://youtu.be/hfRPT9a0Ics",
        img_url: "",
        description:
          "Talks about freedom of mind and to release the baggage of the past and liberate ourselves from the pain",
      },
      {
        id: 38,
        name: "4 Simple Habits For A Happy Life",
        content_type: "VIDEO",
        emotion_type: "HAPPY",
        video_url: "https://youtu.be/aDIrnR9y-j8",
        img_url: "",
        description:
          "Talks about daily aspects of life which will make you happy internally and will keep you motivated",
      },
      {
        id: 39,
        name: "Destiny Is Your Choice - BK Shivani",
        content_type: "VIDEO",
        emotion_type: "HAPPY",
        video_url: "https://youtu.be/fSN0E_p4abs",
        img_url: "",
        description:
          "Talks about the destiny and that destiny is not a chance but your choice",
      },
      {
        id: 40,
        name: "How to Control Your Subconscious Mind - Sandeep Maheshwari",
        content_type: "VIDEO",
        emotion_type: "HAPPY",
        video_url: "https://youtu.be/dIlmtkRZK8g",
        img_url: "",
        description:
          "When your conscious mind and subconscious mind are properly aligned, YOU become unstoppable",
      },
    ],
  });
  const [mood, setMood] = useState("");
  const [tea, setTea] = useState(0);
  const [green, setGreen] = useState(0);
  const [amount, setAmount] = useState(0);
  const [coffee, setCoffee] = useState(0);
  const [biscuit, setBiscuit] = useState(0);
  const [source, setSource] = useState("");
  const [list, setList] = useState(false);
  const [trendVideo, setTrendVideo] = useState(trending.video);
  const [changeTheme, setChangeTheme] = useState({
    background: "black",
    color: "white",
  });
  const messageEl = useRef(null);
  const [show, setShow] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([
    { text: "what is your name? ", isUserMessage: false },
    { text: "Tushar Verma", isUserMessage: true },
    { text: "How u r feeling now?", isUserMessage: false },
    { text: "kind of Happy", isUserMessage: true },
    { text: "kind of Happy", isUserMessage: false },
    { text: "kind of Happy", isUserMessage: true },
    { text: "kind of happy", isUserMessage: false },
  ]);

  const question = [
    " How are you feeling today? I will try to bring in some happiness to increase your productivity while working",
    "Let me recommend something for you to do while you are in this zone. What’s your state of mind now?",
    "Games, videos, songs - based on your state of mind, I recommend the ideal thing for you that can help in your productivity. How are you feeling?",
  ];

  const stock = 5;

  const handleNumberOperation = (e, value, item) => {
    e.preventDefault();
    if (item == "tea") {
      if (value == -1 && tea == 0) {
        setTea(tea);
      } else {
        setTea((prev) => prev + value);
      }
    } else if (item == "coffee") {
      if (value == -1 && coffee == 0) {
        setCoffee(coffee);
      } else {
        setCoffee((prev) => prev + value);
      }
    } else if (item == "biscuit") {
      if (value == -1 && biscuit == 0) {
        setBiscuit(biscuit);
      } else {
        setBiscuit((prev) => prev + value);
      }
    } else if (item == "green") {
      if (value == -1 && green == 0) {
        setGreen(green);
      } else {
        setGreen((prev) => prev + value);
      }
    }
  };
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADD_URL}/happyZone/hzExploreMore/`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   title: "oops..",
        //   text: "Something Went Wrong",
        // });
      });
  }, []);
  const showMore = () => {
    data?.itemsToShow === 4
      ? setData({
          ...data,
          itemsToShow: data?.MOTIVATION.length,
          expanded: true,
        })
      : setData({
          ...data,
          itemsToShow: 4,
          expanded: false,
        });
  };

  const handleOrderSubmit = (e) => {
    e.preventDefault();
    setBiscuit(0);
    setTea(0);
    setCoffee(0);
    setGreen(0);
    Swal.fire({
      icon: "success",
      text: "Your Order Placed successfully",
      timer: 1500,
    });
  };

  const handleMessageSubmit = (e) => {
    e.preventDefault();
    // Send message to chat-bot backend
    if (messageText.trim() !== "") {
      axios
        .post("/api/chatbot", { messageText })
        .then((response) => {
          // Update state with chat-bot response
          setMessages([
            ...messages,
            { text: messageText, isUserMessage: true },
          ]);
          setMessages([
            ...messages,
            { text: response.data, isUserMessage: false },
          ]);
        })
        .catch((error) => {
          console.error(error);
        });
      setMessageText("");
    }
  };

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      style={{ background: "#262339" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <div className="happyzone-page">
        <Helmet>
          <title>Happy Zone</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-9 col-md-9 ">
              <section className="dash-section">
                <div className="dash-sec-content">
                  <div className="dash-info-list">
                    <a href="#" className="dash-card">
                      <div className="dash-card-cb" ref={messageEl}>
                        <div style={{ display: "flex", marginTop: "-1px" }}>
                          <img
                            style={{ width: "58px", height: "58px" }}
                            src={SmileEmoji}
                          />
                          <b
                            style={{
                              margin: "20px",
                              maxWidth: "450px",
                              background: "#E4E6EB",
                              color: "#000000",
                              padding: "5px 10px",
                              borderRadius: "7px 7px 7px 0px ",
                            }}
                          >
                            {
                              question[
                                Math.floor(Math.random() * question.length)
                              ]
                            }
                            {/* How are you feeling today? I will try to bring in
                            some happiness to increase your productivity while
                            working. */}
                          </b>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "-5px",
                            marginLeft: "80px",
                            gap: "30px",
                            alignItems: "end",
                            textAlign: "left",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={happy}
                              style={{
                                width: "47px",
                                height: "43px",
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setMood("happy"),
                                setTimeout(() => {
                                  setShow(true);
                                  setClickVideos(true);
                                }, 2000)
                              )}
                              className=""
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                width: "43px",
                                height: "43px",
                                cursor: "pointer",
                              }}
                              src={calm}
                              onClick={() => (
                                setMood("calm"),
                                setTimeout(() => {
                                  setShow(true);
                                  setClickVideos(true);
                                }, 2000)
                              )}
                              className=""
                            />
                          </div>
                          <div>
                            <img
                              src={busy}
                              style={{
                                width: "57px",
                                height: "46px",
                                marginTop: "0px",
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setMood("busy"),
                                setTimeout(() => {
                                  setShow(true);
                                  setClickVideos(true);
                                }, 2000)
                              )}
                              className=""
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={stress}
                              style={{
                                width: "57px",
                                height: "47px",
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setMood("stress"),
                                setTimeout(() => {
                                  setShow(true);
                                  setClickVideos(true);
                                }, 2000)
                              )}
                              className=""
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={angry}
                              style={{
                                width: "47px",
                                height: "43px",
                                cursor: "pointer",
                              }}
                              onClick={() => (
                                setMood("angry"),
                                setTimeout(() => {
                                  setShow(true);
                                  setClickVideos(true);
                                }, 2000)
                              )}
                              className=""
                            />
                          </div>
                        </div>
                        {mood === "happy" && (
                          <div className="user-response">
                            <img
                              src={happy}
                              style={{
                                width: "47px",
                                height: "43px",
                              }}
                            />
                          </div>
                        )}
                        {mood === "stress" && (
                          <div className="user-response">
                            <img
                              src={stress}
                              style={{
                                width: "57px",
                                height: "47px",
                              }}
                            />
                          </div>
                        )}
                        {mood === "angry" && (
                          <div className="user-response">
                            <img
                              src={angry}
                              style={{
                                width: "47px",
                                height: "43px",
                              }}
                            />
                          </div>
                        )}
                        {mood === "calm" && (
                          <div className="user-response">
                            <img
                              src={calm}
                              style={{
                                width: "47px",
                                height: "43px",
                              }}
                            />
                          </div>
                        )}
                        {mood === "busy" && (
                          <div className="user-response">
                            <img
                              src={busy}
                              style={{
                                width: "57px",
                                height: "47px",
                              }}
                            />
                          </div>
                        )}

                        {mood === "happy" && show && (
                          <div className="bot-response">
                            <b>
                              That’s great to know! Let me make you happier.
                              Here’s something for you!
                            </b>
                          </div>
                        )}
                        {mood === "stress" && show && (
                          <div className="bot-response">
                            <b>
                              Take a deep breath and take a few minutes out of
                              your work. Here’s something for you!
                            </b>
                          </div>
                        )}
                        {mood === "angry" && show && (
                          <div className="bot-response">
                            <b>
                              Let me help you calm yourself down. Here’s
                              something for you!
                            </b>
                          </div>
                        )}
                        {mood === "calm" && show && (
                          <div className="bot-response">
                            <b>
                              That’s good to know. To increase your productivity
                              further, here’s something for you!
                            </b>
                          </div>
                        )}
                        {mood === "busy" && show && (
                          <div className="bot-response">
                            <b>
                              When you are busy with work, you need to calm your
                              mind and this helps increase productivity. Here’s
                              something for you!
                            </b>
                          </div>
                        )}

                        {/* <div className="message-list">
                          {messages.map((message, index) => (
                            <span
                              className={
                                message.isUserMessage
                                  ? "user-message"
                                  : "bot-message"
                              }
                              key={index}
                            >
                              {message.text}
                            </span>
                          ))}
                        </div>
                        <div className="chat-bot-div">
                          <input
                            className="chat-bot-input"
                            type="text"
                            value={messageText}
                            onChange={(e)=>setMessageText(e.target.value)}
                            placeholder="Want to ask something ?"
                          />
                          <button className="chat-bot-btn" onClick={(e)=>handleMessageSubmit(e) }>
                            <img src={sendIcon} />
                          </button>
                        </div> */}
                        {/* <div
                            style={{ marginLeft: "20px", marginTop: "30px" }}
                          >
                            <h1 className="welcome-bold">
                              Welcome to Happy Zone!
                            </h1>
                            <h5
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                marginBottom: "10px",
                                maxWidth: "500px",
                                fontWeight: "600",
                                fontFamily: "Kodchasan",
                              }}
                            >
                              Hey, User! How are you feeling today? I will try
                              to bring in some happiness to increase your
                              productivity while working.
                            </h5>
                          </div>
                        </div>
                        <div
                          style={{ marginTop: "20px", gap: "50px", alignItems:"end" }}
                          className="smile"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={happy}
                              style={{
                                width: mood === "happy" ? "85px" : "47px",
                                height: mood === "happy" ? "85px" : "43px",
                                cursor: "pointer",
                              }}
                              onClick={() => setMood("happy")}
                              className=""
                            />
                            <p
                              style={{
                                marginTop: "10px",
                                fontWeight: mood === "happy" ? 900 : 600,
                              }}
                            >
                              Happy
                            </p>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                width: mood === "calm" ? "85px" : "43px",
                                height: mood === "calm" ? "85px" : "43px",
                                cursor: "pointer",
                              }}
                              src={calm}
                              onClick={() => setMood("calm")}
                              className=""
                            />
                            <p
                              style={{
                                marginTop: "10px",
                                fontWeight: mood === "calm" ? 900 : 600,
                              }}
                            >
                              Calm
                            </p>
                          </div>
                          <div>
                            <img
                              src={busy}
                              style={{
                                width: mood === "busy" ? "95px" : "57px",
                                height: mood === "busy" ? "85px" : "46px",
                                marginTop:
                                  mood === ""
                                    ? "0px"
                                    : mood !== "busy"
                                    ? "20px"
                                    : "0px",
                                cursor: "pointer",
                              }}
                              onClick={() => setMood("busy")}
                              className=""
                            />
                            <p
                              style={{
                                marginTop: mood === "busy" ? "0px" : "10px",
                                marginLeft: mood === "busy" ? "20px" : "10px",
                                fontWeight: mood === "busy" ? 900 : 600,
                              }}
                            >
                              Busy
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={stress}
                              style={{
                                width: mood === "stress" ? "105px" : "57px",
                                height: mood === "stress" ? "85px" : "47px",
                                cursor: "pointer",
                              }}
                              onClick={() => setMood("stress")}
                              className=""
                            />
                            <p
                              style={{
                                marginTop: "10px",
                                fontWeight: mood === "stress" ? 900 : 600,
                              }}
                            >
                              Stressed
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={angry}
                              style={{
                                width: mood === "angry" ? "85px" : "47px",
                                height: mood === "angry" ? "85px" : "43px",
                                cursor: "pointer",
                              }}
                              onClick={() => setMood("angry")}
                              className=""
                            />
                            <p
                              style={{
                                marginTop: "10px",
                                fontWeight: mood === "angry" ? 900 : 600,
                              }}
                            >
                              Angry
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </a>
                  </div>
                  <div className="scroll-div2">
                    {clickVideos && mood === "happy" && (
                      <div className="dash-info-list">
                        <div className="video-head-container">
                          <h5
                            className="video-heading"
                            style={{ color: changeTheme.color }}
                          >
                            Recommended Contents Based on your mood
                          </h5>
                          <button
                            className="show-more"
                            onClick={() => showMore()}
                          >
                            {data.expanded ? (
                              <span>Show less</span>
                            ) : (
                              <span>Show more</span>
                            )}
                          </button>
                        </div>
                        <Cards
                          value={data.HAPPY}
                          // value={trendVideo}
                          setSource={setSource}
                        />
                      </div>
                    )}
                    {clickVideos && mood === "stress" && (
                      <div className="dash-info-list">
                        <div className="video-head-container">
                          <h5
                            className="video-heading"
                            style={{ color: changeTheme.color }}
                          >
                            Recommended Contents Based on your mood
                          </h5>
                          <button
                            className="show-more"
                            onClick={() => showMore()}
                          >
                            {data.expanded ? (
                              <span>Show less</span>
                            ) : (
                              <span>Show more</span>
                            )}
                          </button>
                        </div>
                        <Cards value={data.STRESS} setSource={setSource} />
                      </div>
                    )}
                    {clickVideos && mood === "angry" && (
                      <div className="dash-info-list">
                        <div className="video-head-container">
                          <h5
                            className="video-heading"
                            style={{ color: changeTheme.color }}
                          >
                            Recommended Contents Based on your mood
                          </h5>
                          <button
                            className="show-more"
                            onClick={() => showMore()}
                          >
                            {data.expanded ? (
                              <span>Show less</span>
                            ) : (
                              <span>Show more</span>
                            )}
                          </button>
                        </div>
                        <Cards value={data.ANGRY} setSource={setSource} />
                      </div>
                    )}
                    {clickVideos && mood === "calm" && (
                      <div className="dash-info-list">
                        <div className="video-head-container">
                          <h5
                            className="video-heading"
                            style={{ color: changeTheme.color }}
                          >
                            Recommended Contents Based on your mood
                          </h5>
                          <button
                            className="show-more"
                            onClick={() => showMore()}
                          >
                            {data.expanded ? (
                              <span>Show less</span>
                            ) : (
                              <span>Show more</span>
                            )}
                          </button>
                        </div>
                        <Cards value={data.CALM} setSource={setSource} />
                      </div>
                    )}
                    {clickVideos && mood === "busy" && (
                      <div className="dash-info-list">
                        <div className="video-head-container">
                          <h5
                            className="video-heading"
                            style={{ color: changeTheme.color }}
                          >
                            Recommended Contents Based on your mood
                          </h5>
                          <button
                            className="show-more"
                            onClick={() => showMore()}
                          >
                            {data.expanded ? (
                              <span>Show less</span>
                            ) : (
                              <span>Show more</span>
                            )}
                          </button>
                        </div>
                        <Cards value={data.BUSY} setSource={setSource} />
                      </div>
                    )}
                    <div className="dash-info-list ">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Popular games amongst employees
                        </h5>
                      </div>

                      <section
                        className="comp-section comp-cards"
                        id="comp_cards"
                      >
                        <div className="row">
                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div className="card1 flex-fill">
                              <img
                                alt=""
                                src={TwoZero}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  width: "100%",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  2048
                                </h5>
                                <p className="card-text">
                                  Join the tiles, get to 2048! swipe left or
                                  right, up or down to move the tiles. When two
                                  tiles with the same number touch, they merge
                                  into one.
                                </p>
                                <Link to="/twozerogame">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>
                                {/* <a className="card-link" href="#">
                                      Another link
                                     </a> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div className="card1 flex-fill">
                              <img
                                alt=""
                                src={FindRing}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  width: "100%",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  Find The Ring
                                </h5>
                                <p className="card-text">
                                  Join the tiles, get to 2048! swipe left or
                                  right, up or down to move the tiles. When two
                                  tiles with the same number touch, they merge
                                  into one.
                                </p>
                                <Link to="/ring-game">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>

                                {/* <a className="card-link" href="#">
                                      Another link
                                     </a> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div className="card1 flex-fill">
                              <img
                                alt=""
                                src={MineSweeper}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  width: "100%",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  Mine Sweeper
                                </h5>
                                <p className="card-text">
                                  Join the tiles, get to 2048! swipe left or
                                  right, up or down to move the tiles. When two
                                  tiles with the same number touch, they merge
                                  into one.
                                </p>
                                <Link to="/minesweeper-game">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div className="card1 flex-fill">
                              <img
                                alt=""
                                src={TicTac}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  width: "100%",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  Tic Tac Toe
                                </h5>
                                <p className="card-text">
                                  Tik Tak Toe is one of the most popular games
                                  of all time. Daily challenges to sharpen your
                                  mind. Most popular game...
                                </p>
                                <Link to="/tictac-game">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div
                              className="card flex-fill"
                              style={{
                                border: "none",
                                borderRadius: "30px !important",
                                background: "none",
                              }}
                            >
                              <img
                                alt=""
                                src={CandyCrushh}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  Candy Crushh
                                </h5>
                                <p className="card-text">
                                  Candy Crushh is one of the most enjoyable game
                                  of all time. Daily challenges to sharpen your
                                  mind. Most popular game for quick...
                                </p>
                                <Link to="/candycrushh-game">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                            <div
                              className="card flex-fill"
                              style={{
                                border: "none",
                                borderRadius: "30px !important",
                                background: "none",
                              }}
                            >
                              <img
                                alt=""
                                src={Sketchboard}
                                className="card-img-top"
                                style={{
                                  height: "150px",
                                  borderRadius: "16px 16px 0px 0px ",
                                }}
                              />
                              {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                                <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                                </div> */}
                              <div
                                className="card-body"
                                style={{
                                  background: "#535353",
                                  color: "white",
                                  borderRadius: "0px 0px 16px 16px ",
                                }}
                              >
                                <h5
                                  className="card-title mb-0"
                                  style={{ color: "white", marginTop: "-10px" }}
                                >
                                  Sketch Board
                                </h5>
                                <p className="card-text">
                                  Sketch Board is an excellent app for
                                  sketching. Daily challenges to sharpen your
                                  mind. Most popular game for quick playing.
                                </p>
                                <Link to="/sketchboard-game">
                                  <div
                                    className="flex"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      textAlign: "center",
                                      alignItems: "center",
                                      height: "20px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-sm"
                                      style={{
                                        minWidth: "70px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "30px",
                                        padding: "0px 10px 0px 10px",
                                        background: "#3EA6FF",
                                        color: "white!important",
                                        borderRadius: "20px",
                                      }}
                                      // onClick={() => handleVideo(ele.id)}
                                    >
                                      <a style={{ color: "white" }} href="#">
                                        Play Now
                                      </a>
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    {/* <div className="dash-info-list ">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Boost your Energy
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {data.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>

                      <Cards value={data.CALM} setSource={setSource} />
                    </div> */}
                    {/* <div className="dash-info-list ">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Boost your Energy
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {data.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>

                      <Cards value={data.MOTIVATION} setSource={setSource} />
                    </div> */}

                    {/* <div className="dash-info-list ">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Boost your Energy
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {data.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>

                      <Cards value={data.ANGRY} setSource={setSource} />
                    </div> */}

                    {/* <div className="dash-info-list">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Popular games amongst employees
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {data.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>
                      <Cards value={data.SAD} setSource={setSource} />
                    </div> */}
                    <div className="dash-info-list">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Boost up your energy with below Content
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {data.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>
                      <Cards value={data.STRESS} setSource={setSource} />
                    </div>
                    {/* <div className="dash-info-list">
                      <div className="video-head-container">
                        <h5
                          className="video-heading"
                          style={{ color: changeTheme.color }}
                        >
                          Skill Up with some SKILL COURSES
                        </h5>
                        <button
                          className="show-more"
                          onClick={() => showMore()}
                        >
                          {trendVideo.expanded ? (
                            <span>Show less</span>
                          ) : (
                            <span>Show more</span>
                          )}
                        </button>
                      </div>
                      <Cards value={trendVideo} />
                    </div> */}
                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="dash-sidebar">
                <section>
                  <div className="card-body">
                    <div className="time-list">
                      <Link to="/pantry-order">
                        <div className="dash-stats-list">
                          <img src={Pantry} />
                        </div>
                      </Link>
                      <div className="dash-stats-list">
                        <img src={Emergency} />
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  {/* <div className="card">
                    <div className="card-body text-center">
                      <h4 className="holiday-title mb-0">No orders yet</h4>
                    </div>
                  </div> */}
                </section>
                <section>
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="flex"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          // height: "20px",
                        }}
                      >
                        <iframe
                          src="https://www.manotr.com/"
                          height="500px"
                          width="100%"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}

        <div id="play_videos" className="modal custom-modal fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              {/* <div className="modal-header">
                <h4 className="pantry-heading">Video Player</h4>
              </div> 
              <div className="modal-body">*/}
              {/* <div
                  className="card mb-0"
                  style={{
                    background: "rgba(255,255,255, 0.3)",
                    width: "80%",
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "20px",
                  }}
                > */}
              <div className="card-body">
                <button
                  style={{
                    position: "relative",
                    marginLeft: "97%",
                    marginBottom: "1%",
                  }}
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                  onClick={() => setSource("")}
                >
                  <b>X</b>
                  {/* <img
                      style={{color:"black"}} 
                      src={closeIcon} /> */}
                </button>
                <ReactPlayer
                  // width="1108px"
                  // height="640px"
                  width="100%"
                  // height="auto"
                  url={source}
                  playing={false}
                  light={true}
                  controls
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="add_task_board"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="pantry-heading">Quick Order</h4>
              </div>
              <div className="modal-body">
                <form>
                  {list === false ? (
                    <>
                      <div className="form-group task-board-color">
                        <div className="menu-row1">
                          <div className="menu-row2">
                            <div>
                              <img src={Tea} />
                              <p className="pantry-item">
                                <b>Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  value={Tea}
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "tea")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{tea}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "tea")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={GreenTea} />
                              <p className="pantry-item">
                                <b> Green Tea</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "green")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{green}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "green")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Coffee} />
                              <p className="pantry-item">
                                <b>Coffee</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "coffee")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{coffee}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "coffee")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="menu-row2">
                            <div>
                              <img src={Biscuit} />
                              <p className="pantry-item">
                                <b> Biscuit</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px 0px 0px 15px" }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, -1, "biscuit")
                                  }
                                >
                                  <FaMinus />
                                </button>
                                <div className="amount-style">{biscuit}</div>
                                <button
                                  style={{ borderRadius: "0px 15px 15px 0px " }}
                                  className="amount-button"
                                  onClick={(e) =>
                                    handleNumberOperation(e, 1, "biscuit")
                                  }
                                >
                                  <FaPlus />
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* <div className="menu-row2">
                            <div>
                              <img src={Nachos} />
                              <p className="pantry-item">
                                <b> Snacks</b>
                              </p>
                              <div className="amount-toggle">
                                <button
                                  style={{ borderRadius: "15px" }}
                                  className="amount-button"
                                  onClick={() => setList(true)}
                                >
                                  view List
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Any instructions?"
                          style={{ maxWidth: "350px", marginLeft: "4%" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <p style={{ marginLeft: "20px" }}>
                        <img src={Nachos} />{" "}
                        <b>Select your favorite snacks from list</b>
                      </p>
                      <ul
                        style={{
                          listStyleType: "none",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Chips</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Samosa</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Vada Pav</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Ice Cream</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                        <li
                          style={{
                            display: "flex",
                            alignItems: "baseline",
                            marginTop: "10px",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ accentColor: "#ed9d44", color: "white" }}
                          />{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Bhel Puri</b>{" "}
                          <div
                            className="amount-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexGrow: "1",
                            }}
                          >
                            <button
                              style={{ borderRadius: "15px 0px 0px 15px" }}
                              className="amount-button"
                              // onClick={() => setDecrease()}
                            >
                              <FaMinus />
                            </button>
                            <div className="amount-style">{amount}</div>
                            <button
                              style={{ borderRadius: "0px 15px 15px 0px " }}
                              className="amount-button"
                              // onClick={() => setIncrease()}
                            >
                              <FaPlus />
                            </button>
                          </div>
                        </li>
                      </ul>
                    </>
                  )}
                  <div className="m-t-20 text-center">
                    <button
                      className="btn"
                      data-bs-dismiss="modal"
                      style={{ background: "#ed9d44", color: "white" }}
                      onClick={(e) => handleOrderSubmit(e)}
                    >
                      Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HappyZoneTwo;
