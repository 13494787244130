import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import closeIcon from "../../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../../components/paginationfunction";
import "../../../../antdstyle.css";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
import FilterOff from "../../../../assets/img/profiles/filter-off-fill1.svg";
//import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import moment from "moment";
import { Menu, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStateManager } from "react-select";

const Arrears = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const [empList, setEmpList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [arrears, setArrears] = useState({
    emp_id: "",
    month: "",
    amt: "",
  });
  const [editArrear, setEditArrear] = useState({
    id: "",
    toggle: "",
    status: "",
    date: "",
    month: "",
  });
  const [data, setData] = useState([]);

  const getArrearData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/arrears/`, {
        // params: {
        //   coregid: sessionStorage.getItem("coid"),
        // },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        // setData(
        //   res.data.map((ele, index) => ({
        //     ...ele,
        //     joining_date: ele.joining_date
        //       .substr(0, 10)
        //       .split("-")
        //       .reverse()
        //       .join("-"),
        //   }))
        // );
        setSearchData(res.data);
        // setSearchData(
        //   res.data.map((ele, index) => ({
        //     ...ele,
        //     joining_date: ele.joining_date
        //       .substr(0, 10)
        //       .split("-")
        //       .reverse()
        //       .join("-"),
        //   }))
        // );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getArrearData();
  }, []);

  const handleSearch = () => {
    const newData = data
      .filter(
        (y) => y.department == (department == "" ? y.department : department)
      )
      .filter(
        (z) =>
          z.designation == (designation == "" ? z.designation : designation)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [department, designation]);

  const handleAddArrears = async (event) => {
    event.preventDefault();
    if (!arrears.emp_id || !arrears.month || !arrears.amt) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/arrears/`,
          {
            empid: arrears.emp_id.value,
            month: arrears.month.split("-")[1],
            year: arrears.month.split("-")[0],
            amount: arrears.amt,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Arrears added Successfully",
            });
            setArrears({
              emp_id: "",
              month: "",
              amt: "",
            });
            getArrearData();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went Wrong",
            });
          }
        });
    }
  };

  const handleReleaseArrears = async (event) => {
    event.preventDefault();
    if (
      !editArrear.toggle ||
      !editArrear.status ||
      (!editArrear.date && !editArrear.month)
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/arrears/`,

          editArrear.toggle?.label === "With Payslip"
            ? {
                id: editArrear.id,
                toggle: editArrear.toggle.value,
                released_month: editArrear.month.split("-")[1],
                released_year: editArrear.month.split("-")[0],
                status: editArrear.status.value,
              }
            : {
                id: editArrear.id,
                toggle: editArrear.toggle.value,
                released_date: editArrear.date,
                status: editArrear.status.value,
              },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Arrears added Successfully",
            });
            setEditArrear({
              id: "",
              toggle: "",
              status: "",
              date: "",
              month: "",
            });
            getArrearData();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went Wrong",
            });
          }
        });
    }
  };

  console.log(editArrear);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(
          res.data.map((ele, index) => ({
            value: ele.empid,
            label: ele.name,
          }))
        );
        setDepartmentList(
          res.data.map((ele, index) => ({
            ...ele,
            key: ele.empid,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "empid",
      sorter: (a, b) => a.empid.localeCompare(b.empid),
    },
    {
      title: "Month",
      dataIndex: "month",
      // sorter: (a, b) => a.month.localeCompare(b.month),
    },

    {
      title: "Year",
      dataIndex: "year",
      // sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      title: "Arrears in Payslip",
      dataIndex: "arrears_in_payslip",
      // sorter: (a, b) =>
      //   a.arrears_in_payslip.localeCompare(b.arrears_in_payslip),
    },
    {
      title: "Arrears without Payslip",
      dataIndex: "arrears_without_payslip",
      // sorter: (a, b) =>
      //   a.arrears_without_payslip.localeCompare(b.arrears_without_payslip),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.localeCompare(b.amount),
    },
    {
      title: "Released Date",
      dataIndex: "released_date",
      sorter: {
        compare: (a, b) =>
          moment(a.released_date, "DD-MM-YYYY") -
          moment(b.released_date, "DD-MM_YYYY"),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#edit_arrears"
          className=" btn-primary submit-btn1"
          onClick={(e) => setEditArrear({ ...editArrear, id: text.id })}
        >
          Release
        </button>
      ),
    },
  ];
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Arrears</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                      marginLeft: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_arrears"
                    href="#"
                  >
                    <i className="fa fa-plus" /> &nbsp;Add Arrears
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Search Filter */}
          {/* <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Department"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.department)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department !== ""
                      ? {
                          label: department,
                          value: department,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setDepartment(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Designation"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.designation)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation !== ""
                      ? {
                          label: designation,
                          value: designation,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDesignation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (setDepartment(""), setDesignation(""))}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div> */}
          {/* /Search Filter */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                ></div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      bordered
                      dataSource={searchData}
                      // rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* /Add Arrears Modal */}
        <div
          id="add_arrears"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Arrears Details</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className=" col-sm-12">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="form-group col-sm-6">
                          <label>Employee Name</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            options={empList}
                            value={arrears.empid}
                            onChange={(e) =>
                              setArrears({
                                ...arrears,
                                emp_id: e,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label>Department</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            // options={genderData}
                            isDisabled
                            value={departmentList
                              .filter(
                                (ele) => arrears?.emp_id?.value === ele.empid
                              )
                              .map((ele) => ({
                                label: ele.department,
                                value: ele.department,
                              }))}
                          />
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        <div className="form-group col-sm-6">
                          <label>Amount</label>
                          <input
                            type="number"
                            className="form-control"
                            value={arrears.amt}
                            onChange={(e) =>
                              setArrears({
                                ...arrears,
                                amt: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label>Month & Year</label>
                          <input
                            type="month"
                            className="form-control"
                            value={arrears.month}
                            onChange={(e) =>
                              setArrears({
                                ...arrears,
                                month: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="submit-section">
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            !arrears.emp_id || !arrears.month || !arrears.amt
                              ? ""
                              : "modal"
                          }
                          style={{
                            background: "#3298DB",
                            color: "white",
                            width: "94px",
                            height: "38px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleAddArrears(event)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Arrears Modal */}

        <div
          id="edit_arrears"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Release Arrear</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className=" col-sm-12">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="form-group col-sm-6">
                          <label>Release With</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            options={[
                              { label: "With Payslip", value: "with_payslip" },
                              {
                                label: "Without Payslip",
                                value: "without_payslip",
                              },
                            ]}
                            value={editArrear.toggle}
                            onChange={(e) =>
                              setEditArrear({
                                ...editArrear,
                                toggle: e,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-6">
                          <label>Status</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            options={[
                              { label: "Pending", value: "Pending" },
                              {
                                label: "Rejected",
                                value: "Rejected",
                              },
                              {
                                label: "Approved",
                                value: "Approved",
                              },
                            ]}
                            value={editArrear.status}
                            onChange={(e) =>
                              setEditArrear({
                                ...editArrear,
                                status: e,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        {editArrear.toggle?.label === "Without Payslip" && (
                          <div className="form-group col-sm-6">
                            <label>Release Date</label>
                            <input
                              type="date"
                              className="form-control"
                              value={editArrear.date}
                              onChange={(e) =>
                                setEditArrear({
                                  ...editArrear,
                                  date: e.target.value,
                                })
                              }
                            />
                          </div>
                        )}
                        {editArrear.toggle?.label === "With Payslip" && (
                          <div className="form-group col-sm-6">
                            <label> Release Month & Year</label>
                            <input
                              type="month"
                              className="form-control"
                              value={editArrear.month}
                              onChange={(e) =>
                                setEditArrear({
                                  ...editArrear,
                                  month: e.target.value,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="submit-section">
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            !editArrear.status ||
                            !editArrear.toggle ||
                            (!editArrear.date && !editArrear.month)
                              ? ""
                              : "modal"
                          }
                          style={{
                            background: "#3298DB",
                            color: "white",
                            width: "94px",
                            height: "38px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleReleaseArrears(event)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Arrears;
