import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Header from "../../Sidebar/header";
import Sidebar from "../../Sidebar/sidebarAdmin";
import axios from "axios";
import Select from "@atlaskit/select";
import { Box } from "@mui/material";
import { FiUpload } from "react-icons/fi";

const LeaveReport = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const [data, setData] = useState([]);
  const [name1, setName1] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [leaver, setLeaver] = useState([]);
  const [leavec, setLeavec] = useState([]);
  const [leaveReport, setLeaveReport] = useState([]);
  const [leaveColumn, setLeaveColumn] = useState([]);
  const [finance, setFinance] = useState([
    {
      label: "2020-2021",
      value: "2020-2021",
    },
    {
      label: "2021-2022",
      value: "2021-2022",
    },
    {
      label: "2022-2023",
      value: "2022-2023",
    },
    {
      label: "2023-2024",
      value: "2023-20214",
    },
  ]);

  const getLeave = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/mas_leave/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //setData(res.data);
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            from: ele.from.split("-").reverse().join("-"),
            to: ele.to.split("-").reverse().join("-"),
          }))
        );
        //setSearchData(res.data)
        setSearchData(
          res.data.map((ele, index) => ({
            ...ele,
            from: ele.from.split("-").reverse().join("-"),
            to: ele.to.split("-").reverse().join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLeave();
  }, []);

  const handleLeaveReport = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/leave_report/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        setLeavec(res.data.columns);
        setLeaver(res.data.data);
        setLeaveReport(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleLeaveReport();
  }, []);

  useEffect(() => {
    if (leaveReport.length === 0) {
      setLeaveColumn([]);
    } else {
      const column_names = [leaveReport.map((v) => v)[0]].map((v) =>
        Object.keys(v)
      )[0];
      const Column = [];
      for (let i = 0; i < column_names.length; i++) {
        Column.push({
          title:
            column_names[i].split("_").join(" ")[0].toLocaleUpperCase() +
            column_names[i].split("_").join(" ").substring(1),
          dataIndex: column_names[i],
          fixed:
            column_names[i] === "employee_name"
              ? "left"
              : column_names[i] === "employee_id"
              ? "left"
              : "none",
          width: column_names[i] === "employee_name" ? "220px" : "150px",
        });
      }
      setLeaveColumn(Column);
    }
  });

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Leave Report</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <button
                  className="btn btn-success btn-block "
                  style={{
                    padding: "8px 23px",
                    fontSize: "15px",
                    background: " #973FCF",
                    border: "none",
                    color: "white",
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#Leave_info_modal"
                  href="#"
                  classname="edit-icon"
                >
                  <CSVLink
                    data={leaver}
                    headers={leavec.map((ele) => ({
                      label: ele.name,
                      key: ele.key,
                    }))}
                  >
                    <span style={{ color: "white" }}>
                      {" "}
                      <FiUpload /> &nbsp; Export Leave Report
                    </span>
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>

          <div className="row filter-row">
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <input
                className="form-group"
                type="search"
                style={{
                  height: "38px",
                  marginTop: "1px",
                  borderRadius: "5px",
                  width: "200px",
                  padding: "8px",
                }}
                placeholder="Search by name and id"
                onChange={(e) => setName1(e.target.value)}
              />

              <Select
                isClearable
                placeholder="Fianancial year"
                options={finance}
                /*
              value={
                name1 !== ""
                  ? {
                    label: name1,
                    value: name1,
                  }
                  : null
              }
              */
              />
            </Box>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: leaveReport.filter((ele) => {
                          return name1.toLocaleLowerCase() === ""
                            ? ele
                            : ele.employee_name
                                .toLocaleLowerCase()
                                .includes(name1.toLocaleLowerCase()) ||
                              name1.toLocaleLowerCase() === ""
                            ? ele
                            : String(ele.employee_id)
                                .toLocaleLowerCase()
                                .includes(name1.toLocaleLowerCase());
                        }).length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      scroll={{ x: 1300 }}
                      columns={leaveColumn}
                      bordered
                      dataSource={leaveReport.filter((ele) => {
                        return name1.toLocaleLowerCase() === ""
                          ? ele
                          : ele.employee_name
                              .toLocaleLowerCase()
                              .includes(name1.toLocaleLowerCase()) ||
                            name1.toLocaleLowerCase() === ""
                          ? ele
                          : String(ele.employee_id)
                              .toLocaleLowerCase()
                              .includes(name1.toLocaleLowerCase());
                      })}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveReport;
