import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { ConfigProvider } from "antd";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import { Tooltip as TooltipOne } from "antd";

import empty from "../../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import axios from "axios";
import Lozenge from "@atlaskit/lozenge";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { addDays } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "150px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No data to show</p>
  </div>
);

const ContractAttendance = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [menu, setMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [contractor, setContractor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const matchsmexact = useMediaQuery("(max-width:630px)");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [contractors, setContractors] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  //   const [monthValue, setMonthValue] = useState(
  //     `${new Date().getFullYear()}-${
  //       new Date().getMonth() + 1 > 9
  //         ? new Date().getMonth() + 1
  //         : `0${new Date().getMonth() + 1}`
  //     }`
  //   );

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/attendance/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          // year: monthValue.split("-")[0],
          // month: monthValue.split("-")[1],
          contractor: contractor?.value ? contractor.value : 0,
          toggle:
            moment(state[0].startDate).format("YYYY-MM-DD") ===
            moment(state[0].endDate).format("YYYY-MM-DD")
              ? 0
              : "ym",
          range_from: moment(state[0].startDate).format("YYYY-MM-DD"),
          range_to: moment(state[0].endDate).format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            //punchin:ele.punchin.substr(11, 8),
            //punchout:ele.punchout.substr(11, 8),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state, contractor]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contractors/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setContractors(
          res.data.map((ele, index) => ({
            value: ele.id,
            label: ele.name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const column1 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      fixed: "left",
      width: 80,
    },
    {
      title: "Employee name",
      dataIndex: "name",
      fixed: "left",
      // width: "fit-content",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Contractor Name",
      dataIndex: "contractor_name",
      sorter: (a, b) => a.contractor_name?.localeCompare(b?.contractor_name),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department?.localeCompare(b?.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation?.localeCompare(b?.designation),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      sorter: (a, b) => a.status?.localeCompare(b?.status),
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Present"
                  ? "fa fa-dot-circle-o text-success"
                  : text === "present"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Punch In",
      dataIndex: "punchin",
      align: "center",
      render: (text, record) => <>{text?.substr(11, 8) || "-"}</>,
      //   sorter: (a, b) => a.punchin.length - b.punchin.length,
    },
    {
      title: "Punch Out",
      dataIndex: "punchout",
      align: "center",
      render: (text, record) => <>{text?.substr(11, 8) || "-"}</>,
      //   sorter: (a, b) => a.punchout.length - b.punchout.length,
    },
    {
      title: "Late In",
      dataIndex: "late_in",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    // {
    //   title: "Location",
    //   dataIndex: "location",
    // //   sorter: (a, b) => a.location.length - b.location.length,
    // },
    {
      title: "Location",
      dataIndex: "location",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      render: (text, record) => <>{text === null ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      render: (text, record) => <>{text === null ? "-" : text}</>,
      align: "center",
      //   sorter: (a, b) => a.break.length - b.break.length,
    },
    {
      title: "Break",
      dataIndex: "break",
      align: "center",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      //   sorter: (a, b) => a.break.length - b.break.length,
    },

    {
      title: "Productive Hours",
      dataIndex: "prohrs",
      align: "center",
      render: (text, record) => (
        <>
          {parseInt(text) <= 4 ? (
            <Lozenge appearance="removed">{text === "" ? "-" : text}</Lozenge>
          ) : parseInt(text) > 4 && parseInt(text) < 6 ? (
            <Lozenge appearance="moved">{text === "" ? "-" : text}</Lozenge>
          ) : (
            <Lozenge appearance="success">{text === "" ? "-" : text}</Lozenge>
          )}
        </>
      ),
    },
    {
      title: "Overtime",
      dataIndex: "overtime",
      align: "center",
      render: (text, record) => <>{text === "" ? "-" : text}</>,
      //   sorter: (a, b) => a.overtime.length - b.overtime.length,
    },
  ];

  const column2 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
      fixed: "left",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   fixed: "left",
    //   sorter: (a, b) => a.name?.localeCompare(b?.name),
    // },
    {
      title: "Employee name",
      dataIndex: "name",
      fixed: "left",
      // width: "fit-content",
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <TooltipOne placement="topLeft" title={name}>
          {name}
        </TooltipOne>
      ),
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Department",
      dataIndex: "dept",
      sorter: (a, b) => a.dept?.localeCompare(b?.dept),
    },
    {
      title: "Designation",
      dataIndex: "desg",
      sorter: (a, b) => a.desg?.localeCompare(b?.desg),
    },
    {
      title: "Working Days",
      dataIndex: "days",
      align: "center",
      sorter: (a, b) => a.days - b.days,
    },

    {
      title: "In Office",
      dataIndex: "office",
      align: "center",
      sorter: (a, b) => a.office - b.office,
    },
    {
      title: "Outdoor",
      dataIndex: "outdoor",
      align: "center",
      sorter: (a, b) => a.outdoor - b.outdoor,
    },
    // {
    //   title: "Location",
    //   dataIndex: "location",
    // //   sorter: (a, b) => a.location.length - b.location.length,
    // },
    {
      title: "Working From Home",
      dataIndex: "wfh",
      align: "center",
      sorter: (a, b) => a.wfh - b.wfh,
    },
    {
      title: "Break",
      dataIndex: "break",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.break, "h:mm:ss") - moment(b.break, "h:mm:ss"),
      },
    },
    {
      title: "Late In",
      dataIndex: "late_in",
      align: "center",
      sorter: (a, b) => a.late_in - b.late_in,
    },
    {
      title: "Early Out",
      dataIndex: "early_out",
      align: "center",
      sorter: (a, b) => a.early_out - b.early_out,
    },
    {
      title: "Productive Hours",
      dataIndex: "prohrs",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.prohrs, "h:mm:ss") - moment(b.prohrs, "h:mm:ss"),
      },
    },
    {
      title: "Overtime",
      dataIndex: "overtime",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.overtime, "h:mm:ss") - moment(b.overtime, "h:mm:ss"),
      },
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/reports");
                    }}
                  >
                    Reports
                  </span>{" "}
                  <span style={{ color: "#6c757d", cursor: "not-allowed" }}>
                    {" "}
                    / Contract Based Attendance
                  </span>
                </h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons"></div>
              </div>
            </div>
          </div>

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="col-md-12">
                <input
                  type="search"
                  placeholder="Employee Name..."
                  className="project-search col-md-12"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Contractor"
                  options={contractors}
                  value={contractor}
                  //isClearable={true}
                  isClearable
                  onChange={(e) => {
                    setContractor(e ? e : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Department"
                  isClearable
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={[
                    ...new Set(
                      data?.map((item) => item.department || item.dept)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department !== ""
                      ? {
                          label: department,
                          value: department,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDepartment(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Designation"
                  options={[
                    ...new Set(
                      data?.map((item) => item.designation || item.desg)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation !== ""
                      ? {
                          label: designation,
                          value: designation,
                        }
                      : null
                  }
                  //isClearable={true}
                  isClearable
                  onChange={(e) => {
                    setDesignation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Status"
                  options={[...new Set(data?.map((item) => item.status))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    status !== ""
                      ? {
                          label: status,
                          value: status,
                        }
                      : null
                  }
                  //isClearable={true}
                  isClearable
                  onChange={(e) => {
                    setStatus(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="card mb-0">
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box
                  sx={
                    matchsmexact
                      ? { width: "250px", overflow: "auto" }
                      : { minWidth: "200px", overflow: "auto" }
                  }
                >
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={state}
                    direction="horizontal"
                  />
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    bottom: "-15px",
                    left: "28%",
                    // width: "100px",
                    // padding: "3px 5px",
                    // background: "#3d91ff",
                    // color: "white",
                    // borderRadius: "8px",
                    // cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",

                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      width: "100px",
                      padding: "3px 5px",
                      background: "#3d91ff",
                      color: "white",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      width: "100px",
                      padding: "3px 5px",
                      background: "#3d91ff",
                      color: "white",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClose()}
                  >
                    Apply
                  </Box>
                </Box>
              </Box>
            </Modal>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginRight: "10px",
                justifyContent: "end",
              }}
            >
              <h4 style={{ paddingTop: "7px" }}>
                <b>{`Login Report From
                    ${moment(state[0].startDate).format(
                      "DD-MM-YYYY"
                    )} To ${moment(state[0].endDate).format("DD-MM-YYYY")}`}</b>
              </h4>{" "}
              &nbsp; &nbsp; &nbsp;
              <div className="view-icons">
                <button
                  className="btn btn-success btn-block "
                  style={{
                    padding: "5px 15px",
                    fontSize: "15px",
                    background: "#3298DB",
                    border: "none",
                    color: "white",
                  }}
                  onClick={handleOpen}
                >
                  Select Date(s)
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <ConfigProvider
                  renderEmpty={
                    // customize ?
                    customizeRenderEmpty
                    // : undefined
                  }
                >
                  <Table
                    pagination={{
                      total: data
                        .filter((a) => {
                          return name.toLocaleLowerCase() === ""
                            ? a
                            : a.name
                                .toLocaleLowerCase()
                                .includes(name.toLocaleLowerCase());
                        })
                        .filter(
                          (a) =>
                            a.department ==
                            (department == "" ? a.department : department)
                        )
                        .filter(
                          (a) =>
                            a.designation ==
                            (designation == "" ? a.designation : designation)
                        )
                        .filter(
                          (a) => a.status == (status == "" ? a.status : status)
                        ).length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    // style={{ overflowX: "1300" }}
                    columns={
                      moment(state[0].startDate).format("YYYY-MM-DD") ===
                      moment(state[0].endDate).format("YYYY-MM-DD")
                        ? column1
                        : column2
                    }
                    bordered
                    dataSource={data
                      .filter((a) => {
                        return name.toLocaleLowerCase() === ""
                          ? a
                          : a.name
                              .toLocaleLowerCase()
                              .includes(name.toLocaleLowerCase());
                      })
                      .filter(
                        (a) =>
                          a.department ==
                          (department == "" ? a.department : department)
                      )
                      .filter(
                        (a) =>
                          a.designation ==
                          (designation == "" ? a.designation : designation)
                      )
                      .filter(
                        (a) => a.status == (status == "" ? a.status : status)
                      )}
                    rowKey={(record) => record.id}
                    exportable
                    // searchable
                    exportableProps={{ showColumnPicker: true }}
                    // searchableProps={{ fuzzySearch: true }}
                    loading={isLoading}
                    scroll={{ x: 2100 }}
                    // onChange={this.handleTableChange}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractAttendance;
