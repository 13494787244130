import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import CrossIcon from "../../../assets/img/profiles/closeline.svg";
import CheckIcon from "../../../assets/img/profiles/checkfill.svg";
import smiley from "../../../assets/img/profiles/OZHRMS.svg";
import { ArrowBack } from "@mui/icons-material";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";

const TrainingContent = () => {
  const [menu, setMenu] = useState(false);
  const [trainingContents, setTrainingContents] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [videoName, setVideoName] = useState("");
  const [deptList, setDeptList] = useState([]);
  const [desiList, setDesiList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [trainingContent, setTrainingContent] = useState({
    name: "",
    content_name: "",
    language: "",
    video_url: "",
    department: "",
    desg: "",
    desc: "",
  });

  const inputRef = useRef();

  const [source, setSource] = useState();

  const languages = [
    { value: "HINDI", label: "HINDI" },
    { value: "ENGLISH", label: "ENGLISH" },
    { value: "MARATHI", label: "MARATHI" },
    { value: "TAMIL", label: "TAMIL" },
    { value: "GUJRATI", label: "GUJRATI" },
  ];

  const types = [{ label: "video", value: "video" }];

  const handleGetDes = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_designation/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDesiList(
          res.data.map((ele) => ({
            label: ele.desg.name,
            value: ele.desg.desg_id,
          }))
        );
        // setExdes(res.data);
        // setGetDes(res.data);
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetDept = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_department/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setGetDept(res.data);
        setDeptList(
          res.data.map((ele) => ({
            label: ele.dept.name,
            value: ele.dept.dept_id,
          }))
        );
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetContent = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/training_logs_content/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
      })
      .then((res) => {
        setData(
          res.data?.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   title: "oops..",
        //   text: "Something Went Wrong",
        //   // timer: 3000,
        // });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetContent();
    handleGetDept();
    handleGetDes();
  }, []);

  const newData = data.filter((Data) => {
    if (videoName === "") {
      return Data;
    } else if (Data?.vid_name.toLowerCase().includes(videoName.toLowerCase())) {
      return Data;
    }
  });

  const handleContent = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/training_logs_content/`, {
        //admid: JSON.parse(sessionStorage.getItem("admid")),
        empid: parseInt(sessionStorage.getItem("emp_id")),
        vid_name: trainingContent.name,
        language: trainingContent.language.value,
        content_name: trainingContent.content_name,
        // description: trainingContent.desc,
        dept_id: trainingContent.department.value,
        desg_id: trainingContent.desg.value,
        vid_url: trainingContent.video_url,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Content uploaded Successfully",
          // timer: 2000,
        });
        handleGetContent();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
  };

  const column1 = [
    {
      title: "Sno.",
      dataIndex: "sno",
    },
    {
      title: "Name of Video",
      dataIndex: "vid_name",
    },
    {
      title: "Content Name",
      align: "center",
      dataIndex: "content_name",
    },

    {
      title: "Department",
      align: "center",
      dataIndex: "dept",
    },
    {
      title: "Designation",
      align: "center",
      dataIndex: "desg",
    },
    {
      title: "Language",
      align: "center",
      dataIndex: "language",
    },

    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text, record) => (
    //     <>
    //       <button
    //         style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //         className="submit-btn1"
    //         data-bs-toggle="modal"
    //         data-bs-target="#edit_content"
    //         onClick={(e) => setTrainingContents(text)}
    //       >
    //         Edit
    //       </button>{" "}
    //       <button
    //         style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //         className="submit-btn1"
    //         //onClick={(e) => getDocsInfo(text.key, e)}
    //       >
    //         Delete
    //       </button>
    //     </>
    //   ),
    // },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Training & Development Contents</h3>
              </div>

              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    data-bs-toggle="modal"
                    data-bs-target="#add_content_modal"
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "8px",
                    }}
                    classname="edit-icon"
                    //onClick={() => setToggel(true)}
                  >
                    <i className="fa fa-plus" /> Add Contents
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}

          {/* Search Filter */}

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
              <div className="form-group">
                <Select
                  placeholder="Video name"
                  options={[...new Set(data.map((item) => item.vid_name))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  isClearable={true}
                  value={
                    videoName !== ""
                      ? {
                          label: videoName,
                          value: videoName,
                        }
                      : null
                  }
                  //defaultValue={options[0]}
                  onChange={(e) => {
                    setVideoName(e ? e.value : "");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={column1}
                      dataSource={newData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add happy zone contents */}
      <div
        id="add_content_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <br />
              <br />
              <br />
              <h5 className="modal-title">
                <b>Add Video content</b>
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Video URL<span className="text-danger">*</span>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={trainingContent.video_url}
                    onChange={(e) => {
                      setTrainingContent({
                        ...trainingContent,
                        video_url: e.target.value,
                      });
                    }}
                  />
                  {/* {!source && <button onClick={handleChoose}>Choose</button>} */}
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Video Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          border="none"
                          value={trainingContent.name}
                          onChange={(e) => {
                            setTrainingContent({
                              ...trainingContent,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12" style={{ marginLeft: "25px" }}>
                      {" "}
                      <label>
                        Department <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={deptList}
                          value={trainingContent.department}
                          onChange={(e) =>
                            setTrainingContent({
                              ...trainingContent,
                              department: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Content Name <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          border="none"
                          value={trainingContent.content_name}
                          onChange={(e) => {
                            setTrainingContent({
                              ...trainingContent,
                              content_name: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12" style={{ marginLeft: "25px" }}>
                      {" "}
                      <label>
                        Language <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={languages}
                          value={trainingContent.language}
                          onChange={(e) =>
                            setTrainingContent({
                              ...trainingContent,
                              language: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Designation <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={desiList}
                          value={trainingContent.desg}
                          onChange={(e) =>
                            setTrainingContent({
                              ...trainingContent,
                              desg: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    placeholder="Video description"
                    //rows={4}
                    className="form-control"
                    value={trainingContent.desc}
                    onChange={(e) => {
                      setTrainingContent({
                        ...trainingContent,
                        desc: e.target.value,
                      });
                    }}
                    //defaultValue={""}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className=" btn"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#3298DB",
                      color: "white",
                      width: "94px",
                      height: "38px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    onClick={(e) => handleContent(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* document part */}
      <div id="edit_content" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <br />
              <br />
              <br />
              <h5 className="modal-title">
                <b>Edit Content</b>
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Upload Video<span className="text-danger">*</span>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </label>
                  <input
                    ref={inputRef}
                    className="VideoInput_input"
                    type="file"
                    onChange={handleFileChange}
                    accept=".mov,.mp4,.gif"
                  />
                  {/* {!source && <button onClick={handleChoose}>Choose</button>} */}
                </div>
                <div className="row col-md-12">
                  <div className="form-group col-md-6">
                    <div className="col-md-12">
                      {" "}
                      <label>
                        Content Type <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={types}
                          value={trainingContents.content_name}
                          // onChange={(e) =>
                          //   setApplyLeave({ ...applyLeave, type: e })
                          // }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="col-md-12" style={{ marginLeft: "25px" }}>
                      {" "}
                      <label>
                        Content Emotion <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={languages}
                          value={trainingContents.language}
                          // onChange={(e) =>
                          //   setApplyLeave({ ...applyLeave, type: e })
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    placeholder="Video description"
                    //rows={4}
                    //value={applyLeave.reason}
                    className="form-control"
                    //defaultValue={""}
                    // onChange={(e) =>
                    //   setApplyLeave({
                    //     ...applyLeave,
                    //     reason: e.target.value,
                    //   })
                    // }
                  />
                </div>
                <div className="submit-section">
                  <button
                    className=" btn"
                    data-bs-dismiss="modal"
                    style={{
                      background: "#3298DB",
                      color: "white",
                      width: "94px",
                      height: "38px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    //onClick={(event) => handleApplyLeave(event)}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ borderBottom: " 2px solid #cccccc" }}
            >
              <h4
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                {" "}
                {currDocs.name}{" "}
              </h4>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div
              className="modal-body"
              style={{ height: "100%", width: "800px", overflowY: "auto" }}
            >
              <iframe
                id="iframepdf"
                src={currDocs.link}
                style={{ width: "750px", height: "500px" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default TrainingContent;
