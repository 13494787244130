const themes = {
  default: {
    "background-color": "#fff",
    color: "#000",
    "font-family":
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  },
  red: {
    "font-family": "sans-serif, Open Sans",
    "font-size": "0.9375rem",
    color: "#1f1f1f",

    // "backdrop-filter": "blur(5px)",

    overflow: "hidden",
    "background-image":
      "url(https://media.istockphoto.com/photos/beautiful-artistic-image-of-environment-nature-in-spring-or-summer-picture-id1314315360?b=1&k=20&m=1314315360&s=170667a&w=0&h=vZ7ZKu_qL1b0MSNQyC7ru9u9jwY4JcMPvdZP1Imh3K8=)",
    // "background-repeat": "no-repeat",
    "background-position": "center",

    "background-size": "cover",
    height: "100vh",
  },
  black: {
    "background-color": "#000",
    color: "#fff",
    // "background-image":
    //   "url(https://preview.redd.it/tqayctfeykq61.jpg?auto=webp&s=29505eecd0a421c886b3b3656df24af184b03250)",
    "background-repeat": "no-repeat",
    "background-position": "center",

    "background-size": "cover",
    height: "100%",
  },
  white: {
    "background-color": "#fff",
    color: "red",
    "font-family": "Roboto, sans-serif",
    "background-image":
      "url( https://cdn.pixabay.com/photo/2016/01/08/11/57/butterflies-1127666_960_720.jpg)",
    "background-repeat": "no-repeat",
    "background-position": "center",

    "background-size": "cover",
    overflow: "hidden",
  },
};

export default themes;
