import React, { useState, useRef } from "react";
// import { Table } from "antd";
import { Table } from "ant-table-extensions";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import { ArrowBack } from "@mui/icons-material";
import { appendErrors } from "react-hook-form";
import { FiUpload } from "react-icons/fi";
import Lozenge from "@atlaskit/lozenge";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { UploadFile } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { css, jsx } from "@emotion/react";
import attendanceSheet from "../../../assets/css/Sample Attendance Sheet.xlsx";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import moment from "moment";
import tableExport from "antd-table-export";
import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { Box, Modal, useMediaQuery } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "150px",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const AdminTaskReport = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [name1, setName1] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [designation1, setDesignation1] = useState("");
  const [department1, setDepartment1] = useState("");
  const [status1, setStatus1] = useState("");
  const [menu, setMenu] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [filter, setFilter] = useState([]);
  const matchsmexact = useMediaQuery("(max-width:630px)");

  const open1 = Boolean(anchorEl);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  /* filter*/
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const hiddenFileInput = useRef(null);
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    console.log(true);
    setFilteredInfo({});
    setSortedInfo({});
  };
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [taskData, setTaskData] = useState([]);
  const token = sessionStorage.getItem("access_token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/task_report/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          // year: monthValue.split("-")[0],
          // month: monthValue.split("-")[1],
          from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
          to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTaskData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            //punchin:ele.punchin.substr(11, 8),
            //punchout:ele.punchout.substr(11, 8),
          }))
        );
        // setFilter(
        //   res.data.map((ele) => ({
        //     text: ele.name,
        //     value: ele.name,
        //   }))
        // );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  const column2 = [
    {
      title: "SNo.",
      dataIndex: "sno",
      align: "center",
      fixed: "left",
      width: 60,
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      sorter: (a, b) => a.name?.localeCompare(b?.name),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department?.localeCompare(b?.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation?.localeCompare(b?.designation),
    },
    {
      title: "Task Assigned",
      dataIndex: "task_assigned",
      align: "center",
      sorter: (a, b) => a.task_assigned - b.task_assigned,
    },
    {
      title: "Task Completed",
      dataIndex: "task_completed",
      align: "center",
      sorter: (a, b) => a.task_completed - b.task_completed,
    },
    {
      title: "Task Pending",
      dataIndex: "task_pending",
      align: "center",
      sorter: (a, b) => a.task_pending - b.task_pending,
    },
    // {
    //   title: "Late Completed",
    //   dataIndex: "wfh",
    //   align: "center",
    //   sorter: (a, b) => a.wfh - b.wfh,
    // },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={
                matchsmexact
                  ? { width: "250px", overflow: "auto" }
                  : { minWidth: "200px", overflow: "auto" }
              }
            >
              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
              />
            </Box>
            <Box
              sx={{
                position: "relative",
                bottom: "-15px",
                left: "28%",
                // width: "100px",
                // padding: "3px 5px",
                // background: "#3d91ff",
                // color: "white",
                // borderRadius: "8px",
                // cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  width: "100px",
                  padding: "3px 5px",
                  background: "#3d91ff",
                  color: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              >
                Cancel
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  width: "100px",
                  padding: "3px 5px",
                  background: "#3d91ff",
                  color: "white",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => handleClose()}
              >
                Apply
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/reports");
                    }}
                  >
                    Reports
                  </span>{" "}
                  <span style={{ color: "#6c757d", cursor: "not-allowed" }}>
                    {" "}
                    / Task Report
                  </span>
                </h3>
              </div>
            </div>
          </div>

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="col-md-12">
                <input
                  type="search"
                  placeholder="Employee Name..."
                  className="project-search col-md-12"
                  onChange={(e) => setName1(e.target.value)}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Department"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  isClearable
                  options={[
                    ...new Set(
                      taskData?.map((item) => item.department || item.dept)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department1 !== ""
                      ? {
                          label: department1,
                          value: department1,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDepartment1(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Designation"
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  options={[
                    ...new Set(
                      taskData?.map((item) => item.designation || item.desg)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation1 !== ""
                      ? {
                          label: designation1,
                          value: designation1,
                        }
                      : null
                  }
                  //isClearable={true}
                  isClearable
                  onChange={(e) => {
                    setDesignation1(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                marginRight: "10px",
                justifyContent: "end",
              }}
            >
              <h4 style={{ paddingTop: "7px" }}>
                {state[0].startDate === state[0].endDate ? (
                  <b>{`Tasks For
                    ${moment(state[0].startDate).format("DD-MM-YYYY")}`}</b>
                ) : (
                  <b>{`Tasks From
                    ${moment(state[0].startDate).format(
                      "DD-MM-YYYY"
                    )} To ${moment(state[0].endDate).format("DD-MM-YYYY")}`}</b>
                )}
              </h4>{" "}
              &nbsp; &nbsp; &nbsp;
              <div className="view-icons">
                <button
                  className="btn btn-success btn-block "
                  style={{
                    padding: "5px 15px",
                    fontSize: "15px",
                    background: "#3298DB",
                    border: "none",
                    color: "white",
                  }}
                  onClick={handleOpen}
                >
                  Select Date(s)
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  pagination={{
                    total: taskData
                      .filter((a) => {
                        return name1.toLocaleLowerCase() === ""
                          ? a
                          : a.name
                              .toLocaleLowerCase()
                              .includes(name1.toLocaleLowerCase());
                      })
                      .filter(
                        (a) =>
                          a.department ==
                          (department1 == "" ? a.department : department1)
                      )
                      .filter(
                        (a) =>
                          a.designation ==
                          (designation1 == "" ? a.designation : designation1)
                      ).length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  // style={{ overflowX: "1300" }}
                  columns={column2}
                  bordered
                  dataSource={taskData
                    .filter((a) => {
                      return name1.toLocaleLowerCase() === ""
                        ? a
                        : a.name
                            .toLocaleLowerCase()
                            .includes(name1.toLocaleLowerCase());
                    })
                    .filter(
                      (a) =>
                        a.department ==
                        (department1 == "" ? a.department : department1)
                    )
                    .filter(
                      (a) =>
                        a.designation ==
                        (designation1 == "" ? a.designation : designation1)
                    )}
                  rowKey={(record) => record.id}
                  onChange={handleChange}
                  exportable
                  // searchable
                  exportableProps={{ showColumnPicker: true }}
                  // searchableProps={{ fuzzySearch: true }}
                  loading={isLoading}
                  scroll={{ x: 1200 }}
                  // onChange={this.handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTaskReport;
