import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SettingsSidebar from "../Sidebar/settingsidebar";
import Header from "../Sidebar/header";
import Approval from "../../assets/img/profiles/approval.svg";
import Edit from "../../assets/img/profiles/edit.svg";
import Select from "react-select";
import { FaMinus, FaPlus } from "react-icons/fa";
import axios from "axios";

const ApprovalPermission = () => {
  const [menu, setMenu] = useState(false);
  const [displayPage, setDisplayPage] = useState("leave");
  const [getallGrp, setGetallGrp] = useState([]);
  const [groupname, setGroupname] = useState("");
  const [modulename, setModuleName] = useState(null);
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [getEmpl, setGetEmpl] = useState([]);
  const [member, setMemebr] = useState(null);
  const [groupset, setGroupset] = useState(null);
  const [moduleset, setmoduleset] = useState(null);
  const [getgrpname, setgetgrpname] = useState([]);
  const [showmem, setShowmem] = useState([]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");

  /**************Function to get All Group Info************* */
  const GetGroup = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/approval_matrixs/`,
        {
          params: {
            coregid: sessionStorage.getItem("coid"),
            // coregid:"Sapi141335",
            module_type: displayPage,
          },
        }
      );
      const data = await res.data;

      console.log(data);
      setGetallGrp(res.data);

      // setGetEmpl(res.data.map((ele)=>({label:ele.name, value:ele.empid})))
    } catch (err) {
      console.log(err);
    }
  };

  const GetGroupName = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/approval_members/`,
        {
          params: {
            coregid: sessionStorage.getItem("coid"),
            // coregid:"Sapi141335",
            module_type: displayPage,
          },
        }
      );
      const data = await res.data;

      console.log(data);
      // setGetallGrp(res.data)
      setgetgrpname(res.data);

      // setGetEmpl(res.data)
    } catch (err) {
      console.log(err);
    }
  };

  const GetEmp = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/master_employees/`,
        {
          params: {
            coregid: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.data;

      console.log(data);

      setGetEmpl(
        res.data.map((ele) => ({ label: ele.name, value: ele.empid }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddMember = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/approval_members/`,
        {
          coregid: sessionStorage.getItem("coid"),
          // coregid:"Sapi141335",
          module_type: displayPage,
          group_name: groupset.value,

          members: member.map((ele) => ele.value),
        }
      );
      const data = await res.data;

      alert("Member Added Successfully");

      // setGetEmpl(res.data.map((ele)=>({label:ele.name, value:ele.empid})))
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetEmp();
  }, []);

  const PostGroup = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/approval_matrixs/`,
        // {
        //   params: {
        //     coregid:sessionStorage.getItem("coid"),
        //     coregid:"Sapi141335",
        //     module_type:"leave"
        //   },

        // }
        {
          group_name: groupname,
          module_type: modulename.value,
          coregid: sessionStorage.getItem("coid"),
          // "coregid": "Sapi141335",
          approval_level: [
            {
              level: 1,
              ids: level1.map((ele) => ele.value),
            },
            {
              level: 2,
              ids: level2.map((ele) => ele.value),
            },
            {
              level: 3,
              ids: level3.map((ele) => ele.value),
            },
          ].filter((item) => item.ids.length !== 0),
        }
      );
      const data = await res.data;

      // console.log(data)
      alert("added Successfully");
      GetGroup();

      // setGetEmpl(res.data.map((ele)=>({label:ele.name, value:ele.empid})))
    } catch (err) {
      console.log(err);
    }
  };
  /******************************************************** */

  useEffect(() => {
    GetGroup();
    GetGroupName();
  }, [displayPage]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <SettingsSidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        {/* <button onClick={()=>PostGroup()}>add</button> */}
        <div className="content container-fluid">
          {/* /Page Header */}
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  {/* <div className="page-header">
                    <h4>
                      <img src={Approval} /> <span>Approval</span>
                    </h4>
                  </div> */}
                  <div className="row align-items-center">
                    <div className="col">
                      <h4>
                        <img src={Approval} /> <span>Approval</span>
                      </h4>
                    </div>
                    <div className="col-auto float-end">
                      <div className="view-icons">
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "5px 20px",
                            fontSize: "15px",
                            background: "#477BFF",
                            border: "none",
                            color: "white",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#add_group"
                          href="#"
                          // onClick={() =>}
                        >
                          + Add Members
                        </button>
                      </div>
                      &nbsp;
                      <div className="view-icons">
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "5px 20px",
                            fontSize: "15px",
                            background: "#477BFF",
                            border: "none",
                            color: "white",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#edit_group"
                          href="#"
                          // onClick={() =>}
                        >
                          + Add Group
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="col-auto float-start ml-auto mt-4">
                        <div>
                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 15px",
                              fontSize: "15px",
                              borderRadius: "8px 0px 0px 8px",
                              background:
                                displayPage === "leave" ? "#477BFF" : "#FFFFFF",
                              border: "none",
                              color:
                                displayPage === "leave" ? "#FFFFFF" : "#344767",
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#personal_info_modal"
                            onClick={() => {
                              setDisplayPage("leave");
                            }}
                          >
                            {" "}
                            Leave
                          </button>

                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 15px",
                              fontSize: "15px",
                              borderRadius: "0px",
                              background:
                                displayPage === "attendance"
                                  ? "#477BFF"
                                  : "#FFFFFF",
                              border: "none",
                              color:
                                displayPage === "attendance"
                                  ? "#FFFFFF"
                                  : "#344767",
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#personal_info_modal"
                            onClick={() => {
                              setDisplayPage("attendance");
                            }}
                          >
                            Attendance
                          </button>

                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "6px 15px",
                              fontSize: "15px",
                              borderRadius: "0px 8px 8px 0px",
                              background:
                                displayPage === "reimbursement"
                                  ? "#477BFF"
                                  : "#FFFFFF",
                              border: "none",
                              color:
                                displayPage === "reimbursement"
                                  ? "#FFFFFF"
                                  : "#344767",
                            }}
                            onClick={() => {
                              setDisplayPage("reimbursement");
                            }}
                          >
                            Reimbursement
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="row col-md-12">
                      {getallGrp.map((ele) => (
                        <div className="col-lg-4 col-sm-6 col-md-4 col-xl-4">
                          <div
                            className="card"
                            style={{
                              height: "380px",
                              borderRadius: "16px",
                              border: "1px solid #D9D9D9",
                            }}
                          >
                            <div className="card-body">
                              {/* <div style={{display:"flex", justifyContent:"space-between", flexDirection:"row"}}>

                            </div> */}
                              <div style={{ float: "right" }}>
                                <button
                                  style={{
                                    background: "none",
                                    border: "none",
                                  }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_group"
                                  href="#"
                                >
                                  {/* <img src={Edit} /> */}
                                </button>
                              </div>
                              <p
                                style={{
                                  color: "#666666",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                }}
                              >
                                Group Name :{" "}
                                <span style={{ color: "#0C41FF" }}>
                                  {ele.group_name}
                                </span>
                              </p>
                              <p
                                style={{
                                  color: "#666666",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                }}
                              >
                                Number Of Members In Group :{" "}
                                <span
                                  style={{ color: "#0C41FF" }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#show_mem"
                                  href="#"
                                  onClick={() => setShowmem(ele.members)}
                                >
                                  {ele.members.length}
                                </span>
                              </p>
                              <hr />
                              <p
                                style={{
                                  color: "#666666",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                }}
                              >
                                Number Of Approval Levels :{" "}
                                <span style={{ color: "#0C41FF" }}>
                                  {Object.keys(ele.approval_level).length}
                                </span>
                              </p>
                              <hr />
                              <p
                                style={{
                                  color: "#666666",
                                  fontWeight: "400",
                                  fontSize: "16px",
                                  lineHeight: "25px",
                                }}
                              >
                                Approval Leavel Authority :
                              </p>
                              {Object.entries(ele.approval_level)
                                .reverse()
                                .map((item, i) => (
                                  <p
                                    style={{
                                      color: "#666666",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      lineHeight: "25px",
                                    }}
                                  >
                                    {console.log(item)}
                                    {`Level ${i + 1}`} :{" "}
                                    <span style={{ color: "#0C41FF" }}>
                                      {item[1].map((el) => el.name).toString()}
                                    </span>
                                    {console.log(item)}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <div
          id="add_group"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: "615px" }}>
              {/* <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Group</b>
                </h5>
                 </div> */}
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                {/* <img src={closeIcon} /> */}X
              </button>

              <div className="modal-body">
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  Group Name :{" "}
                  <span style={{ color: "#0C41FF" }}>
                    <Select
                      options={getgrpname}
                      value={groupset}
                      onChange={(e) => setGroupset(e)}
                    />
                  </span>
                </p>
                <hr />
                <p
                  style={{
                    color: "#000000",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "25px",
                  }}
                >
                  Add Member
                </p>
                <div className="row col-sm-12">
                  <div className="col-sm-10">
                    <div style={{ width: "100%" }}>
                      <Select
                        isMulti
                        // options={managerList?.filter(
                        //   (item) =>
                        //     !roleList
                        //       ?.filter((ele) => roleID == ele.id)[0]
                        //       ?.members_details?.map((ele) => ele.admid)
                        //       ?.includes(item.value)
                        // )}
                        // value={member}
                        // onChange={(e) => {
                        //   if (e) {
                        //     setMember(e.map((item) => item));
                        //   } else {
                        //     setMember([]);
                        //   }
                        // }}

                        options={getEmpl}
                        onChange={(e) => setMemebr(e)}
                      />
                    </div>
                  </div>
                  <br />
                  <div style={{ marginBottom: "15px" }} className="col-sm-10">
                    <label>Module Name</label>
                    <Select
                      options={[
                        { label: "Attendance", value: "attendance" },
                        { label: "reimbursement", value: "reimbursement" },
                        { label: "Leave", value: "leave" },
                      ]}
                      value={moduleset}
                      onChange={(e) => setmoduleset(e)}
                    />
                  </div>

                  <div className="col-sm-4">
                    <button
                      style={{
                        background: "#0C41FF",
                        color: "#ffffff",
                        padding: "8px 20px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                      onClick={() => handleAddMember()}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*** show members*/}
        <div
          id="show_mem"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={{ width: "450px", padding: "10px" }}
            >
              <div className="modal-header">
                <br />

                <h6 className="modal-title" style={{ marginBottom: "-10px" }}>
                  Members
                </h6>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                {showmem.map((ele) => (
                  <p>{ele.name}</p>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Edit group */}
        <div
          id="edit_group"
          className="modal custom-modal fade"
          role="dialog"
          //data-bs-backdrop="static"
          //data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div
              className="modal-content"
              style={{ width: "450px", padding: "10px" }}
            >
              <div className="modal-header">
                <br />

                <h6 className="modal-title" style={{ marginBottom: "-10px" }}>
                  Add Group
                </h6>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  {/* <img src={closeIcon} /> */}X
                </button>
              </div>
              <hr />
              <div className="modal-body">
                <div style={{ marginTop: "-30px" }}>
                  <p style={{ fontWeight: "550" }}>Group Name :</p>
                  <input
                    type="text"
                    value={groupname}
                    style={{
                      border: "1px solid #3298DB",
                      borderRadius: "6px",
                      width: "100%",
                      padding: "7px",
                      background: "#f6f6f6",
                      marginTop: "-10px",
                    }}
                    onChange={(e) => setGroupname(e.target.value)}
                  />
                </div>
                <hr />
                <div style={{ marginBottom: "15px" }}>
                  <label>Module Name</label>
                  <Select
                    options={[
                      { label: "Attendance", value: "attendance" },
                      { label: "reimbursement", value: "reimbursement" },
                      { label: "Leave", value: "leave" },
                    ]}
                    value={modulename}
                    onChange={(e) => setModuleName(e)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    marginTop: "30px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p style={{ fontSize: "16px", fontWeight: "500" }}>
                      Approval Level :{" "}
                    </p>
                  </div>
                  {/* <div style={{ marginTop: "-15px" }}>
                    <button
                      style={{
                        border: "1px solid #666666",
                        borderRadius: "50%",
                        fontSize: "15px",
                        color: "#FF0000",
                        background: "#ffffff",
                      }}
                    >
                      <FaMinus />
                    </button>
                  </div> */}
                  {/* <div>
                    <p style={{ fontSize: "16px" }}>6</p>
                  </div> */}
                  {/* <div style={{ marginTop: "-15px" }}>
                    <button
                      style={{
                        border: "1px solid #666666",
                        borderRadius: "50%",
                        fontSize: "15px",
                        color: "#0C41FF",
                        background: "#ffffff",
                      }}
                    >
                      <FaPlus />
                    </button>
                  </div> */}
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <label>Level 1</label>
                  <Select
                    style={{ border: "1px solid #3298DB" }}
                    onChange={(e) => setLevel1(e)}
                    options={getEmpl}
                    isMulti
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <label>Level 2</label>
                  <Select
                    onChange={(e) => setLevel2(e)}
                    options={getEmpl}
                    isMulti
                  />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <label>Level 3</label>
                  <Select
                    onChange={(e) => setLevel3(e)}
                    options={getEmpl}
                    isMulti
                  />
                </div>
                <div className="col-sm-2">
                  <button
                    style={{
                      background: "#0C41FF",
                      color: "#ffffff",
                      padding: "8px 20px",
                      border: "none",
                      borderRadius: "8px",
                    }}
                    onClick={() => PostGroup()}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApprovalPermission;
