import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Tooltip, Table } from "antd";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import styled from "@emotion/styled";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
// import "../../../../src/antdstyle.css";
import "../../../antdstyle.css";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../../pages/Sidebar/header";
import deletimg from "../../../assets/img/profiles/delete.svg";
import Swal from "sweetalert2";
import Rating from "@mui/material/Rating";
import ques from "../../../assets/img/profiles/ques.svg";
import half from "../../../assets/img/profiles/half.svg";
import comment from "../../../assets/img/profiles/comment.svg";

//import ReviewForm from "./ReviewForm";

// Datatable JS
// import "../../../assets/js/jquery.dataTables.min.js";
// import "../../../assets/js/dataTables.bootstrap4.min.js";

const AdminReview = () => {
  const [menu, setMenu] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [reviewName, setReviewName] = useState("");
  const [SelectReview, setSelectReview] = useState("general");
  const [reviewData, setReviewData] = useState();
  const [loading, setLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const [inputList, setInputList] = useState([{ question: "" }]);
  const removeFields = (e, index) => {
    e.preventDefault();
    let data = [...inputList];
    data.splice(index, 1);
    setInputList(data);
  };
  const addFields = (e) => {
    e.preventDefault();
    let newfield = { question: "" };

    setInputList([...inputList, newfield]);
  };
  const handleFormChange = (index, event) => {
    let data = [...inputList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setInputList(data);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          toggle: "d",
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEmpReview = (e, reviewid, by_empid, to_empid) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        params: {
          review_id: reviewid,
          by_empid: by_empid,
          to_empid: to_empid,
          toggle: "e",
        },
      })
      .then((res) => {
        console.log(res.data, "Hello");
        setReviewData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCreateReview = (e) => {
    e.preventDefault();
    if (
      reviewName === "" ||
      inputList
        .map((v) => String(Object.values(v).includes("")))
        .some((v) => v === "true")
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          form_name: reviewName,
          toggle: "p",
          questions: inputList.map((ele) => {
            return ele.question;
          }),
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Review created successfully",
            // timer: 1500,
          });
          setShowForm(false);
          setReviewName("");
          setInputList([{ question: "" }]);
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      width: 70,
    },
    {
      title: "Review Form Name",
      dataIndex: "review_form_name",
      width: 260,
      sorter: (a, b) => a.review_form_name.localeCompare(b.review_form_name),
    },
    // {
    //   title: "Reviewer Name",
    //   dataIndex: "by_emp_name",
    //   sorter: (a, b) => a.by_emp_name.localeCompare(b.by_emp_name),
    // },
    {
      title: "Reviewed Employee",
      dataIndex: "to_emp_name",
      sorter: (a, b) => a.to_emp_name.localeCompare(b.to_emp_name),
    },
    {
      title: "Rating",
      align: "center",
      dataIndex: "avg",
      render: (text, record) => (
        <>
          <Rating
            name="half-rating-read"
            defaultValue={text}
            precision={0.5}
            readOnly
          />
        </>
      ),
    },
    // {
    //   title: "Rating",
    //   align: "center",
    //   dataIndex: "avg",
    //   //sorter: (a, b) => a.avg.localeCompare(b.avg),
    //   render: (text, record) => (
    //     <button
    //       className="choose-file-btn"
    //       style={{
    //         background:
    //           text === 5
    //             ? "#FF7676"
    //             : text >= 4
    //             ? "#68B266"
    //             : text >= 3
    //             ? "#FFA500"
    //             : "#3298DB",
    //         width:
    //           text === 5
    //             ? "110px"
    //             : text >= 4
    //             ? "80px"
    //             : text >= 3
    //             ? "110px"
    //             : text >= 2
    //             ? "175px"
    //             : "300px",
    //         fontSize: "14px",
    //       }}
    //     >
    //       {text === 5
    //         ? "Exellence"
    //         : text >= 4
    //         ? "Good"
    //         : text >= 3
    //         ? "Satisfactory"
    //         : text >= 2
    //         ? "Need Improvement"
    //         : "UnSatisfactory"}
    //     </button>
    //   ),
    // },

    {
      title: "Comments",
      dataIndex: "comment",
      // render: (text, record) => <span>$ {text}</span>,
      sorter: (a, b) => a.comment.length - b.comment.length,
      ellipsis: {
        showTitle: false,
      },
      render: (comment) => (
        <Tooltip placement="topLeft" title={comment}>
          {comment}
        </Tooltip>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          className="choose-file-btn"
          style={{
            background: "#68B266",
            width: "80px",

            fontSize: "14px",
          }}
          onClick={(e) =>
            handleEmpReview(e, text.review_id, text.by_emp, text.to_emp)
          }
          data-bs-toggle="modal"
          data-bs-target="#show_review"
        >
          View
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Performance Review</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  {rolePermissions["Review Form"] === "Edit/Create/Approve" && (
                    <>
                      {!showForm && (
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            fontSize: "15px",
                            background: "#3298db",
                            border: "none",
                            color: "white",
                            borderRadius: "10px",
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#personal_info_modal"
                          // onClick={() => setApplyLeave("regular")}
                          onClick={() => {
                            setShowForm(true);
                          }}
                        >
                          <i className="fa fa-plus" />
                          &nbsp; Create Review
                        </button>
                      )}
                    </>
                  )}
                  {showForm && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#personal_info_modal"
                      // onClick={() => setApplyLeave("regular")}
                      onClick={() => {
                        setShowForm(false);
                      }}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {!showForm && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        ellipsis={true}
                        className="table-striped"
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        loading={loading}
                        // onChange={this.handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showForm && (
            <div className="card" style={{ padding: "20px" }}>
              <h3>Review form</h3>
              <p style={{ fontSize: "12px" }}>
                <span className="text-danger">*</span>
                Please ensure that your questions are based on a 1-5 scale where
                1 is the lowest and 5 is the highest. This will provide
                consistent and meaningful data analysis. Contact our support
                team with any questions. Thank you.
              </p>
              <div className="col-xl-12">
                <form>
                  <div className="card col-md-12">
                    <div className="card-body ">
                      <div className="row">
                        <label className="col-lg-3 col-form-label">
                          Review Form Name:
                        </label>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              value={reviewName}
                              onChange={(e) => setReviewName(e.target.value)}
                              placeholder="Review Name"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      {inputList.map((input, index) => {
                        return (
                          <div className="row col-md-12 ">
                            <div className="remove-div">
                              <button
                                className="add-btn-remove"
                                onClick={(e) => removeFields(e, index)}
                              >
                                <img src={deletimg} />
                              </button>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Question</label>
                                <input
                                  className=" form-control"
                                  name="question"
                                  placeholder="Review Question"
                                  value={input.education}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="add-div">
                      <button
                        className="add-btn-add"
                        onClick={(e) => addFields(e)}
                      >
                        Add More &nbsp;+
                      </button>
                    </div>
                  </div>
                </form>
                <div style={{ textAlign: "center", margin: "auto" }}>
                  <button
                    className="modal-submit-btn"
                    onClick={(e) => handleCreateReview(e)}
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>
          )}
          <div
            id="show_review"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <br />
                  <br />
                  <br />
                  <h5 className="modal-title">
                    <b>Review</b>
                  </h5>

                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>
                <hr
                  style={{
                    boxSizing: "border-box",
                    height: "4px",
                    marginTop: "-8px",
                    paddingX: "5px",
                  }}
                />
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-sm-12">
                        <div
                          className="form-group"
                          style={{ marginTop: "-10px" }}
                        >
                          {reviewData?.map((ele) => {
                            return (
                              <div
                                className="col-sm-12"
                                style={{
                                  border: ".3px solid #5F6368",
                                  borderRadius: "5px",
                                  marginBottom: "10px",
                                  padding: "15px",
                                }}
                              >
                                <div className="col-sm-12">
                                  <p
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      <img src={ques} />
                                    </span>{" "}
                                    &nbsp;&nbsp;
                                    <span style={{ fontWeight: "600" }}>
                                      {ele.question}
                                    </span>
                                  </p>
                                  <hr
                                    style={{
                                      boxSizing: "border-box",
                                      height: "2px",
                                      marginTop: "-8px",
                                      paddingX: "5px",
                                    }}
                                  />
                                </div>

                                <div className="row col-sm-12">
                                  <div className="col-sm-3">
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>
                                        <img src={half} />
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginTop: "4px",
                                        }}
                                      >
                                        Rating :
                                      </span>
                                    </p>
                                  </div>

                                  <div className="col-sm-9">
                                    <Rating
                                      name="half-rating-read"
                                      defaultValue={ele.rating}
                                      precision={0.5}
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div className="row col-sm-12">
                                  <div className="col-sm-3">
                                    <p
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>
                                        <img src={comment} />
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          marginTop: "4px",
                                        }}
                                      >
                                        Comment :
                                      </span>
                                    </p>
                                  </div>

                                  <div className="col-sm-9">
                                    <p>
                                      {ele.comment?.length > 0 ? (
                                        ele.comment
                                      ) : (
                                        <i>No Comment</i>
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminReview;
