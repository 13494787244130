import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import { ConfigProvider } from "antd";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import download from "../../../assets/img/profiles/downloadicon.svg";
import empty from "../../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import { FiUpload } from "react-icons/fi";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
//import Select from "react-select";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Box,
  TableContainer,
  Table as TableOne,
  TableRow,
  TableCell,
  Typography,
  Button,
  Toolbar,
  Grid,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
// import {  group } from "./constant";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No reimbursement data to show</p>
  </div>
);

const STableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:nth-of-type(even)": {
    backgroundColor: "#e6e6fa",
  },
}));

const STableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  fontSize: "13px",
  color: "#808080",
});

const SNCTableCell = styled(TableCell)({
  borderColor: "#FFFFFF",
  fontSize: "13px",
  color: "#808080",
});

const SCTableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  // background: "#a2d8fb",
  // color:"#808080"
});
const LATableCell = styled(TableCell)({
  textAlign: "right",
});

const SButton = styled(Button)({
  background: "#2DB8C5",
  borderRadius: "4px",
  textTransform: "none",
  boxShadow: "none",
  padding: "8px",
  ":hover": {
    background: "#2DB8C5",
    boxShadow: "none",
  },
});

const ReimbursementAdmin = () => {
  const [menu, setMenu] = useState(false);
  const [reimburseDetails, setReimburseDetails] = useState({});
  const [appReim, setAppReim] = useState(0);
  const [appDesc, setAppDesc] = useState("");
  const [loading, setLoading] = useState(true);
  const [declineReason, setDeclineReason] = useState("");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [showT, setShowT] = useState(0);
  const hiddenFileInput = useRef(null);
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [reimReport, setReimReport] = useState({
    empid: "",
    from: "",
    to: "",
  });
  const [claimId, setClaimId] = useState("");
  const [reportShow, setReportShow] = useState(false);
  const [name1, setName1] = useState("");
  const [status1, setStatus1] = useState("");
  const [reimburseType, setReimburseType] = useState("");
  const [date, setDate] = useState("");
  const [dept, setDept] = useState("");
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [reportData, setReportData] = useState({});
  const [claim, setClaim] = useState([]);
  const [group, setGroup] = useState([]);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClickOne = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const inputRef = useRef(null);
  const printPage = useReactToPrint({
    content: () => inputRef.current,
    documentTitle: "Reimburseement Report",
  });
  // const getReimbursement = () => {
  //   setLoading(true);
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
  //       params: {
  //         admid: sessionStorage.getItem("admid"),
  //         year: month?.split("-")[0] ? month?.split("-")[0] : "",
  //         month: month?.split("-")[1] ? month?.split("-")[1] : "",
  //       },
  //       // headers: {
  //       //   Authorization: `Bearer ${token}`,
  //       // },
  //     })
  //     .then((res) => {
  //       //setData(res.data);
  //       setData(
  //         res.data.map((ele, index) => ({
  //           ...ele,
  //           key: ele.approval_id,
  //           date_time: ele.date_time.substr(0, 10),
  //         }))
  //       );
  //       //setSearchData(res.data);
  //       setSearchData(
  //         res.data.map((ele, index) => ({
  //           ...ele,
  //           key: ele.approval_id,
  //           date_time: ele.date_time.substr(0, 10),
  //           //to: ele.to.split("-").reverse().join("-"),
  //         }))
  //       );
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getReimbursementRep = () => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          toggle: "rep",
          reim_ids: claim.map((ele) => ele.id).toString(),
        },
        responseType: "blob", // important
      })

      .then((response) => {
        console.log(response);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        console.log(url);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Reimbursement_report.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.log(err);
      });
    // axios.get(
    //   `${process.env.REACT_APP_BACKEND_URL}/reimbursement/`,

    //     {reim_ids:searchData.map((ele)=>ele.id), admid: sessionStorage.getItem("admid"),
    //   },

    //   {
    //     params:{
    //     admid: sessionStorage.getItem("admid"),
    //               year: month?.split("-")[0] ? month?.split("-")[0] : "",
    //               month: month?.split("-")[1] ? month?.split("-")[1] : "",
    //              toggle:"rep",

    //   },
    //   responseType: "blob" // important
    // }).then(response => {
    //   const url = window.URL.createObjectURL(new Blob([response.data]));
    //   console.log(url)
    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.setAttribute(
    //       "download",
    //       `Reimbursement_report.xlsx`
    //   );
    //   document.body.appendChild(link);
    //   link.click();
    // });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleUpload = (event) => {
    // const fileUploaded = event.target.files[0];
    // setBioExcel(fileUploaded);
    // uploadAttendance();
    let formData = new FormData();
    formData.append("datafile", event.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Paid status file uploaded successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleApproveReimburse = async (e, value) => {
    e.preventDefault();
    if (
      appReim === 0 ||
      appReim === "0" ||
      Number(appReim) > Number(reimburseDetails.claim_allowed)
    ) {
      Swal.fire({
        text: "Claim approved must be greater than 0 and lesser then or equal to allowed reimbursement amount.",
      });
    } else if (value === true && declineReason === "") {
      Swal.fire({
        text: "Please enter decline reason",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/reimbursement_approval/`,
          {
            admid: JSON.parse(sessionStorage.getItem("admid")),
            approval_id: reimburseDetails.approval_id,
            total_approved: appReim,
            approval_reason: value === false ? appDesc : declineReason,
            decline: value,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: res.data.status === false ? "error" : "success",
            // text: res.data.status === true ? "Document Verified" : "",

            // icon:
            //   res.data.error === "Cannot approved by same person"
            //     ? "error"
            //     : "success",
            text:
              res.data.error === "Already Approved"
                ? "Reimbursement is already approved"
                : res.data.error === "Cannot approved by same person"
                ? "Cannot approved or declined by same person"
                : res.data.error ===
                  "Cannot approved by someone of lower hierchy"
                ? "You have no authority to approve or decline request"
                : res.data.error ===
                  "Cannot approved by someone of different departmenr"
                ? "You have no authority to approve or decline request"
                : res.data.status === true
                ? "Reimbursement approved successfully"
                : "Reimbursement Rejected",
            // timer: 3000,
          });
          // getReimbursement();
          setDeclineReason("");
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(
          res.data.map((ele, index) => ({
            value: ele.empid,
            label: ele.name,
          }))
        );
        setEmpData(
          res.data.map((ele, index) => ({
            ...ele,
            key: ele.empid,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const generateReport = (e) => {
    e.preventDefault();
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/reimbursement_report_bgauss/`,
        {
          params: {
            empid: reimReport.empid?.value,
            // year: month1?.split("-")[0],
            // month: month1?.split("-")[1],
            from_date: reimReport.from,
            to_date: reimReport.to,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setReportData(res.data);
        setReportShow(true);
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          timer: 3000,
          text: "Report Generated",
        });
        setReimReport({
          empid: "",
          from: "",
          to: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBulkAction = (e, value) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/reimbursement_approval/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          approval_id: selectedRowKeys,
          decline: value,
          bulk: "T",
          // reason: declineReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: res.data.status === false ? "error" : "success",
          text: value ? "Request Rejected" : "Request Approved Successfully",

          // icon:
          //   res.data.error === "Cannot approved by same person"
          //     ? "error"
          //     : "success",
          // text:
          //   res.data.error === "Already Approved"
          //     ? "Request is Already Approved"
          //     : res.data.error === "Cannot approved by same person"
          //     ? "You have no authority to approve or decline request"
          //     : res.data.error === "Cannot approved by someone of lower hierchy"
          //     ? "You have no authority to approve or decline request"
          //     : res.data.error ===
          //       "Cannot approved by someone of different departmenr"
          //     ? "You have no authority to approve or decline request"
          //     : res.data.status === true
          //     ? "Request Approved Successfully"
          //     : "Request Rejected",
        });
        // getReimbursement();
        setSelectedRowKeys([]);
        getReimonClaimid();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  useEffect(() => {
    handleSearch();
  }, [name1, status1, dept, date, reimburseType]);

  const handleSearch = () => {
    const newData = data
      .filter((x) => x.emp_name == (name1 == "" ? x.emp_name : name1))
      .filter(
        (a) => a.application_date == (date == "" ? a.application_date : date)
      )
      .filter((y) => y.status == (status1 == "" ? y.status : status1))
      .filter((b) => b.emp_dept == (dept == "" ? b.emp_dept : dept))
      .filter((z) => z.name == (reimburseType == "" ? z.name : reimburseType));
    setClaim(newData);
  };

  {
    /******************************** */
  }
  const columns1 = [
    {
      title: "Claim Id",
      dataIndex: "claim_id",
      fixed: "left",
      width: "120px",
      sorter: (a, b) => a.claim_id.localeCompare(b.claim_id),
    },
    // {
    //   title: "Type of Reimbursement",
    //   dataIndex: "type",
    //   key: "key",
    //   fixed: "left",
    //   sorter: (a, b) => a.type.localeCompare(b.type),
    // },
    {
      title: "Name of Employee",
      dataIndex: "emp_name",
      fixed: "left",
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Department",
      dataIndex: "emp_dept",
      sorter: (a, b) => a.emp_dept.localeCompare(b.emp_dept),
    },
    {
      title: "Designation",
      dataIndex: "emp_desg",
      sorter: (a, b) => a.emp_desg.localeCompare(b.emp_desg),
    },
    // {
    //   title: "Grade",
    //   dataIndex: "emp_grade",
    //   align: "center",
    //
    //   sorter: (a, b) => a.emp_grade.localeCompare(b.emp_grade),
    // },

    {
      title: "Claim Applied(₹)",
      dataIndex: "total_applied",
      align: "right",
      sorter: (a, b) => a.total_applied - b.total_applied,
    },
    {
      title: "Claim Allowed(₹)",
      dataIndex: "total_allowed",
      align: "right",
      sorter: (a, b) => a.total_allowed - b.total_allowed,
    },
    {
      title: "Claim Approved(₹)",
      dataIndex: "total_approved",
      align: "right",
      sorter: (a, b) => a.total_approved - b.total_approved,
    },
    // {
    //   title: "Claim Rejected(₹)",
    //   dataIndex: "claim_reject",
    //   align: "right",
    //
    //   sorter: (a, b) => a.claim_reject - b.claim_reject,
    // },
    {
      title: "Application Date",
      dataIndex: "application_date",
      align: "center",
      render: (text, record) => (
        <>{text?.substr(0, 10).split("-").reverse().join("-")}</>
      ),
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Bill",
      dataIndex: "bill",
      key: "key",
      align: "center",
      render: (text, record) =>
        text !== "-" ? (
          <a
            href={text !== "-" ? text[0].links : ""}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={download}
              style={{
                height: "18px",
                width: "18px",

                cursor: "pointer",
              }}
            />
          </a>
        ) : (
          <> Not Available</>
        ),
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "key",
    //   align: "center",
    //   render: (text, record) => (
    //     <div className="dropdown action-label text-center">
    //       <a
    //         className="btn btn-white btn-sm btn-rounded "
    //         href="#"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i
    //           className={
    //             text === "Declined"
    //               ? "fa fa-dot-circle-o text-danger"
    //               : text === "Pending"
    //               ? "fa fa-dot-circle-o text-info"
    //               : text === "Approved"
    //               ? "fa fa-dot-circle-o text-success"
    //               : "fa fa-dot-circle-o text-purple"
    //           }
    //         />{" "}
    //         {text}
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a className="dropdown-item" href="#">
    //           <i className="fa fa-dot-circle-o text-purple" /> New
    //         </a>
    //         <a className="dropdown-item" href="#">
    //           <i className="fa fa-dot-circle-o text-info" /> Pending
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#approve_leave"
    //         >
    //           <i className="fa fa-dot-circle-o text-success" /> Approved
    //         </a>
    //         <a className="dropdown-item" href="#">
    //           <i className="fa fa-dot-circle-o text-danger" /> Declined
    //         </a>
    //       </div>
    //     </div>
    //   ),
    //   sorter: (a, b) => a.status.localeCompare(b.status),
    // },
    // {
    //   title: "Paid Status",
    //   dataIndex: "paid",
    //   key: "key",
    //   align: "center",
    //   render: (text, record) => (
    //     <div className="dropdown action-label text-center">
    //       <a
    //         className="btn btn-white btn-sm btn-rounded "
    //         href="#"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i
    //           className={
    //             text
    //               ? "fa fa-dot-circle-o text-success"
    //               : "fa fa-dot-circle-o text-danger"
    //           }
    //         />{" "}
    //         {text ? "Paid" : "Unpaid"}
    //       </a>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            // data-bs-toggle="modal"
            // data-bs-target="#view_reimbursement"
            className=" btn-primary submit-btn1"
            onClick={(e) => {
              // setReimburseDetails(text);
              // setAppReim(
              //   text.claim_approved === 0
              //     ? text.claim_applied
              //     : text.claim_approved
              // );
              setShowT(1);
              setClaimId(text.claim_id);
              setSelectedRowKeys([]);
              getReimonClaimid(text.claim_id);
            }}
            disabled={
              rolePermissions["Employee Reimbursement"] ===
              "Edit/Create/Approve"
                ? false
                : true
            }
          >
            View
          </button>{" "}
          {/* <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            data-bs-toggle="modal"
            data-bs-target="#view_reimbursement"
            className=" btn-primary submit-btn1"
            onClick={(e) => {
              //  setReimburseDetails(text);
              //  setAppReim(
              //    text.total_approved === 0
              //      ? text.total_applied
              //      : text.total_approved
              //  );
            }}
            //  disabled={
            //    rolePermissions["Employee Reimbursement"] === "Edit/Create/Approve"
            //      ? false
            //      : true
            //  }
          >
            Approve
          </button> */}
        </>
      ),
    },
  ];

  {
    /********************************* */
  }

  const columns = [
    {
      title: "Name of Employee",
      dataIndex: "emp_name",
      key: "key",
      fixed: "left",
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Department",
      dataIndex: "emp_dept",
      key: "key",
      sorter: (a, b) => a.emp_dept.localeCompare(b.emp_dept),
    },
    {
      title: "Designation",
      dataIndex: "emp_desg",
      key: "key",
      sorter: (a, b) => a.emp_desg.localeCompare(b.emp_desg),
    },
    // {
    //   title: "Grade",
    //   dataIndex: "emp_grade",
    //   align: "center",
    //   key: "key",
    //   sorter: (a, b) => a.emp_grade.localeCompare(b.emp_grade),
    // },
    {
      title: "Type of Reimbursement",
      dataIndex: "reim_name",
      key: "key",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Claim Applied(₹)",
      dataIndex: "claim_applied",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_applied - b.claim_applied,
    },
    {
      title: "Claim Allowed(₹)",
      dataIndex: "claim_allowed",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_allowed - b.claim_allowed,
    },
    {
      title: "Claim Approved(₹)",
      dataIndex: "claim_approved",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_approved - b.claim_approved,
    },
    {
      title: "Application Date",
      dataIndex: "date_time",
      align: "center",
      key: "key",
      render: (text, record) => (
        <>{text?.substr(0, 10).split("-").reverse().join("-")}</>
      ),
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    // {
    //   title: "Bill",
    //   dataIndex: "bill",
    //   key: "key",
    //   align: "center",
    //   render: (text, record) =>
    //     text !== null ? (
    //       <a
    //         href={text !== null ? text[0]?.links : ""}
    //         target="_blank"

    //         rel="noopener noreferrer"
    //       >
    //         <img
    //           src={download}
    //           style={{
    //             height: "18px",
    //             width: "18px",

    //             cursor: "pointer",
    //           }}
    //         />
    //       </a>
    //     ) : (
    //       <> Not Available</>
    //     ),
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "key",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Paid Status",
      dataIndex: "paid",
      key: "key",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text ? "Paid" : "Unpaid"}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            data-bs-toggle="modal"
            data-bs-target="#view_reimbursement"
            className=" btn-primary submit-btn1"
            onClick={(e) => {
              setReimburseDetails(text);
              setAppReim(
                text.total_approved === 0
                  ? text.total_applied
                  : text.total_approved
              );
            }}
            disabled={
              rolePermissions["Employee Reimbursement"] ===
              "Edit/Create/Approve"
                ? false
                : true
            }
          >
            View
          </button>
        </>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Claim Id",
      dataIndex: "claim_id",
      key: "key",
      fixed: "left",
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Group Id",
      dataIndex: "group_id",
      key: "key",
      fixed: "left",
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Department",
      dataIndex: "emp_dept",
      key: "key",
      sorter: (a, b) => a.emp_dept.localeCompare(b.emp_dept),
    },
    {
      title: "Designation",
      dataIndex: "emp_desg",
      key: "key",
      sorter: (a, b) => a.emp_desg.localeCompare(b.emp_desg),
    },
    {
      title: "Grade",
      dataIndex: "emp_grade",
      align: "center",
      key: "key",
      sorter: (a, b) => a.emp_grade.localeCompare(b.emp_grade),
    },
    {
      title: "Type of Reimbursement",
      dataIndex: "name",
      key: "key",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Claim Applied(₹)",
      dataIndex: "claim_applied",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_applied - b.claim_applied,
    },
    {
      title: "Claim Allowed(₹)",
      dataIndex: "claim_allowed",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_allowed - b.claim_allowed,
    },
    {
      title: "Claim Approved(₹)",
      dataIndex: "claim_approved",
      align: "right",
      key: "key",
      sorter: (a, b) => a.claim_approved - b.claim_approved,
    },
    {
      title: "Application Date",
      dataIndex: "date_time",
      align: "center",
      key: "key",
      render: (text, record) => (
        <>{text?.substr(0, 10).split("-").reverse().join("-")}</>
      ),
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    // {
    //   title: "Bill",
    //   dataIndex: "bill",
    //   key: "key",
    //   align: "center",
    //   render: (text, record) =>
    //     text !== null ? (
    //       <a
    //         href={text !== null ? text[0]?.links : ""}
    //         target="_blank"
    //         // added rel for view docs
    //         rel="noopener noreferrer"
    //       >
    //         <img
    //           src={download}
    //           style={{
    //             height: "18px",
    //             width: "18px",
    //             fontWeight:"300",

    //             cursor: "pointer",
    //           }}
    //         />
    //       </a>
    //     ) : (
    //       <> Not Available</>
    //     ),
    // },

    {
      title: "Status",
      dataIndex: "status",
      key: "key",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Paid Status",
      dataIndex: "paid",
      key: "key",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text ? "Paid" : "Unpaid"}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#view_reimbursement"
          className=" btn-primary submit-btn1"
          onClick={(e) => {
            // setReimburseDetails(text);
            // setAppReim(
            //   text.claim_approved === 0
            //     ? text.claim_applied
            //     : text.claim_approved
            // );
            setShowT(2);
          }}
          disabled={
            rolePermissions["Employee Reimbursement"] === "Edit/Create/Approve"
              ? false
              : true
          }
        >
          View
        </button>
      ),
    },
  ];

  const getReimonClaim = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          year: month?.split("-")[0] ? month?.split("-")[0] : "",
          month: month?.split("-")[1] ? month?.split("-")[1] : "",
          toggle: "admin",
        },
      })
      .then((res) => {
        // setReportData(res.data);
        // setReportShow(true);
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            application_date: ele.application_date.substr(0, 10),
          }))
        );
        setClaim(
          res.data.map((ele, index) => ({
            ...ele,
            application_date: ele.application_date.substr(0, 10),
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReimonClaimid = (value) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reim_claim_group_cal/`, {
        params: {
          claim_id: value ? value : claimId,
          // year: month?.split("-")[0] ? month?.split("-")[0] : "",
          // month: month?.split("-")[1] ? month?.split("-")[1] : "",
        },
      })
      .then((res) => {
        // setReportData(res.data);
        // setReportShow(true);
        // setClaim(res.data)
        setGroup(res.data.map((ele) => ({ ...ele, key: ele.approval_id })));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReimonClaim();
  }, [month]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Reimbursement</h3>
              </div>
              {!reportShow ? (
                <div className="col-auto float-end ml-auto">
                  <button
                    className=""
                    classname="edit-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "19px 23px",
                      height: "30px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                      borderRadius: "6px",
                    }}
                  >
                    <Box>
                      <Tooltip title="Add Bulk File">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          color="white"
                          // sx={{ ml: -3 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // className={classes.button}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            <FiUpload />
                            &nbsp;&nbsp; Upload Paid status
                          </p>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem>
                      <a
                        // onClick={() => handleExcelDownload()}
                        href={`${
                          process.env.REACT_APP_BACKEND_URL
                        }/reimbursement/?coregid=${sessionStorage.getItem(
                          "coid"
                        )}`}
                        download
                        target="_blank"
                      >
                        Download Template
                      </a>
                    </MenuItem>
                    <MenuItem onClick={(e) => handleClickOne(e)}>
                      <a>Upload File</a>
                    </MenuItem>
                  </Menu>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e) => handleUpload(e)}
                    style={{ display: "none" }}
                  />
                </div>
              ) : (
                <div className="col-auto float-end ml-auto">
                  <button
                    className=""
                    classname="edit-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "19px 23px",
                      height: "30px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                      borderRadius: "6px",
                    }}
                    onClick={() => setReportShow(false)}
                  >
                    Back to Reimbursement
                  </button>
                </div>
              )}
            </div>
          </div>
          {!reportShow ? (
            <>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className=""
                    classname="edit-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "19px 23px",
                      height: "30px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                      borderRadius: "6px",
                    }}
                  >
                    <Box>
                      <Tooltip title="Add Bulk File">
                        <IconButton
                          onClick={handleClick1}
                          size="small"
                          color="white"
                          // sx={{ ml: -3 }}
                          aria-controls={open1 ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open1 ? "true" : undefined}
                          // className={classes.button}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            <FiUpload />
                            &nbsp;&nbsp; Report
                          </p>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </button>
                  <Menu
                    anchorEl={anchorEl1}
                    id="account-menu"
                    open={open1}
                    onClose={handleClose1}
                    onClick={handleClose1}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem
                      data-bs-toggle="modal"
                      data-bs-target="#employee_report"
                    >
                      <a>For specific Employee</a>
                    </MenuItem>
                    <MenuItem onClick={() => getReimbursementRep()}>
                      <a>Export Report</a>
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              {/* Search Filter */}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      //isClearable={()=>name1.clearValue()}
                      placeholder="Employee name"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.emp_name)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        name1 !== ""
                          ? {
                              label: name1,
                              value: name1,
                            }
                          : null
                      }
                      onChange={(e) => setName1(e ? e.value : "")}
                      //className="select floating"
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Status"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.status)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        status1 !== ""
                          ? {
                              label: status1,
                              value: status1,
                            }
                          : null
                      }
                      //isClearable={true}
                      isSearchable
                      onChange={(e) => {
                        setStatus1(e ? e.value : "");
                      }}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Reimbursement Type"
                      isClearable
                      options={[...new Set(data?.map((item) => item.name))].map(
                        (ele) => ({
                          label: ele,
                          value: ele,
                        })
                      )}
                      value={
                        reimburseType !== ""
                          ? {
                              label: reimburseType,
                              value: reimburseType,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setReimburseType(e ? e.value : "");
                      }}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div> */}
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Department"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.emp_dept)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        dept !== ""
                          ? {
                              label: dept,
                              value: dept,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setDept(e ? e.value : "");
                      }}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group">
                    <input
                      className="form-control input-height"
                      type="date"
                      placeholder="Applied Date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a
                    href="#"
                    className="btn btn-block w-100"
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      background: "#18838d",
                      color: "white",
                    }}
                    onClick={() => (
                      setName1(""),
                      setDate(""),
                      setDept(""),
                      setStatus1(""),
                      setReimburseType("")
                    )}
                  >
                    {" "}
                    Clear All{" "}
                  </a>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div
                      style={{
                        display: "flex",
                        margin: "10px",
                        marginBottom: "-10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {showT === 1 && (
                        <div>
                          <button
                            style={{
                              padding: "4px 14px",
                              fontSize: "15px",
                              borderRadius: "8px",
                              background: "#477BFF",
                              border: "none",
                              color: "#FFFFFF",
                            }}
                            onClick={() => setShowT(0)}
                          >
                            {" "}
                            Back
                          </button>
                        </div>
                      )}
                      {selectedRowKeys.length > 0 && showT === 1 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "14px",
                          }}
                        >
                          <div className="regularize-part">
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                padding: "4px 20px",
                                border: "1.5px solid #7F7F7F",
                                borderRadius: "6px",
                                color: "#7F7F7F",
                              }}
                              onClick={(e) => handleBulkAction(e, true)}
                            >
                              Reject
                            </button>
                          </div>
                          <div className="regularize-part">
                            <button
                              style={{
                                border: "none",
                                outline: "none",
                                padding: "4px 20px",
                                background: "#393F5C",

                                color: "#fff",
                                borderRadius: "6px",
                              }}
                              onClick={(e) => handleBulkAction(e, false)}
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      )}
                      {showT === 0 && (
                        <div className="view-icons">
                          <input
                            style={{
                              width: "150px",
                              borderRadius: "5px",
                              height: "29px",
                              padding: "10px",
                            }}
                            type="month"
                            defaultValue={month}
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    {showT === 0 && (
                      <div className="card-body">
                        <div className="table-responsive">
                          <ConfigProvider
                            renderEmpty={
                              // customize ?
                              customizeRenderEmpty
                              // : undefined
                            }
                          >
                            <Table
                              className="table-striped"
                              pagination={{
                                total: claim.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              // rowSelection={rowSelection}
                              // style={{ overflowX: "auto" }}
                              scroll={{ x: 2500 }}
                              columns={columns1}
                              bordered
                              dataSource={claim}
                              // rowKey={(record) => record.id}
                              // exportable
                              // searchable
                              // exportableProps={{ showColumnPicker: true }}
                              loading={loading}
                            />
                          </ConfigProvider>
                        </div>
                      </div>
                    )}

                    {/*********************************** */}
                    {showT === 1 && (
                      <div className="card-body">
                        <div className="table-responsive">
                          <ConfigProvider
                            renderEmpty={
                              // customize ?
                              customizeRenderEmpty
                              // : undefined
                            }
                          >
                            <Table
                              className="table-striped"
                              pagination={{
                                total: group.length,
                                showTotal: (total, range) =>
                                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                itemRender: itemRender,
                              }}
                              rowSelection={rowSelection}
                              // style={{ overflowX: "auto" }}
                              scroll={{ x: 2000 }}
                              columns={columns}
                              bordered
                              dataSource={group}
                              // rowKey={(record) => record.id}
                              // exportable
                              // searchable
                              // exportableProps={{ showColumnPicker: true }}
                              loading={loading}
                            />
                          </ConfigProvider>
                        </div>
                      </div>
                    )}

                    {/**************************************** */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Box sx={{ width: "95%", m: 1, ml: 1.5 }}>
                  {/* <div
                    className="content container-fluid"
                    style={{
                      marginTop: "23px",
                      padding: "8px",
                      paddingLeft: "18px",
                    }}
                  >
                    <div className="page-header">
                      <h3 className="page-title">Payslip</h3>
                    </div>
                  </div> */}

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                    }}
                  >
                    <div className="col-md-3"></div>

                    <SButton onClick={printPage}>
                      <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
                        Download
                      </Typography>
                    </SButton>
                  </Box>

                  <Box sx={{ background: "#FFFFFF", borderRadius: "4px" }}>
                    <Box
                      ref={inputRef}
                      sx={{
                        textAlign: "left",
                        p: 1.5,
                        ml: "auto",
                        mr: "auto",
                      }}
                    >
                      <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                        <br />
                        <br />
                        <span>
                          <b>Employee Name : </b> {reportData.emp_name}
                        </span>
                        <br />
                        <br />
                        <span>
                          <b>Designation : </b>
                          {reportData.desg}
                        </span>
                        <br />
                        <br />
                        <span>
                          <b>Grade : </b>
                          {reportData.grade}
                        </span>
                        <br />
                        <br />
                        <span>
                          <b>Department : </b>
                          {reportData.dept}
                        </span>
                        <br />
                        <br />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            <b>Duration : </b>
                            {reportData.claim_duration}
                          </span>{" "}
                          <span>
                            <b>Claim id : </b>
                            {reportData.claim_id}
                          </span>
                        </div>
                      </Box>
                      <Box sx={{ height: "100%", p: 1 }}>
                        <table className="reimbursement-report">
                          <tr>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Classification of Travel</th>
                            <th>Travel Mode Share Taxi/Auto</th>
                            <th>Bus/Train/Flight/Hired Taxi</th>
                            <th>Own Car/Bike</th>
                            <th>Food Expense</th>
                            <th>Lodging/Self Stay Accommodation</th>
                            <th>Toll/Tax/Parking</th>
                            <th>Expense Reimbursement</th>
                          </tr>
                          {reportData.data.map((ele) => (
                            <tr>
                              <td style={{ textAlign: "left" }}>
                                {ele.from_date
                                  ?.slice(0, 10)
                                  ?.split("-")
                                  ?.reverse()
                                  ?.join("/")}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {ele.to_date
                                  ?.slice(0, 10)
                                  ?.split("-")
                                  ?.reverse()
                                  ?.join("/")}
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {ele.classification}
                              </td>
                              <td>{ele.shared_taxi}</td>
                              <td>{ele.bus_train_flight}</td>
                              <td>{ele.own_car_bike}</td>
                              <td>{ele.food_expense}</td>
                              <td>{ele.stay_accomodation}</td>
                              <td>{ele.toll_parking}</td>
                              <td>{ele.expense}</td>
                            </tr>
                          ))}
                        </table>
                      </Box>
                      <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                        <br />
                        <span>
                          <b>Total Claim Amount(₹) : </b>
                          {reportData.total_claim}
                        </span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          p: 1,
                          pt: 0,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <div>Employee Name</div>
                        <div>HOD Name</div>
                        <div>Finance Approval</div>
                      </Box>
                      <br />
                      <br />
                      <br />
                      <br />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </div>

        {/* /Page Content */}
        {/* Add Leave Modal */}
        <div
          id="view_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  View Reimbursement
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Employee Name</label>
                        <input
                          disabled
                          className="form-control datetimepicker"
                          type="text"
                          value={reimburseDetails.emp_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Reimbursement Type </label>
                        <input
                          disabled
                          className="form-control datetimepicker"
                          type="text"
                          value={reimburseDetails.reim_name}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          disabled
                          className="form-control datetimepicker"
                          type="text"
                          value={reimburseDetails.additional_note}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Date</label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            //type="date"
                            // value={reimburseDetails.from
                            //   ?.split("-")
                            //   .reverse()
                            //   .join("-")}
                            value={reimburseDetails.date_time
                              ?.slice(0, 10)
                              ?.split("-")
                              ?.reverse()
                              ?.join("-")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Claim Applied</label>
                        <input
                          className="form-control"
                          readOnly
                          type="text"
                          disabled
                          value={reimburseDetails.claim_applied}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Claim Allowed</label>
                        <input
                          className="form-control"
                          readOnly
                          type="text"
                          disabled
                          value={reimburseDetails.claim_allowed}
                        />
                      </div>
                    </div>
                    {reimburseDetails.components?.map((ele, index) => (
                      <div className="col-sm-6" key={index}>
                        <div className="form-group">
                          <label>{ele.name}</label>
                          <div>
                            <input
                              disabled
                              className="form-control"
                              value={ele.value == null ? "-" : ele.value}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-sm-6">
                      {/* <div className="form-group">
                        <label> Reimbursement Bill</label>
                        <br />
                        {reimburseDetails?.bill !== null ? (
                          <>
                            <a
                              href={reimburseDetails?.bill[0].links}
                              target="_blank"
                             
                              rel="noopener noreferrer"
                            >
                              View Bill
                              <img
                                src={download}
                                style={{
                                  height: "18px",
                                  width: "18px",
                             

                                  cursor: "pointer",
                                }}
                              />
                            </a>
                          </>
                        ) : (
                          <p>Not Available</p>
                        )}
                      </div> */}
                    </div>

                    {/* <div className="form-group">
                      <label>
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        disabled
                        rows={4}
                        className="form-control"
                        value={reimburseDetails.reason}
                      />
                    </div> */}
                    {reimburseDetails?.can_take_action ? (
                      <div
                        className="submit-section"
                        style={{ justifyContent: "space-between" }}
                      >
                        <button
                          type="button"
                          className="btn btn-success btn-block w-100 "
                          //data-bs-dismiss="modal"
                          style={{
                            background: "transparent",
                            color: "#18838D",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          // onClick={(e) => handleApproveReimburse(e, true)}
                        >
                          Decline
                        </button>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          data-bs-toggle="modal"
                          data-bs-target="#view_reim"
                          // onClick={(e) => handleApproveReimburse(e, false)}
                          onClick={(e) => e.preventDefault()}
                        >
                          Accept
                        </button>
                      </div>
                    ) : (
                      <div className="submit-section">
                        <button
                          type="button"
                          className="btn btn-success btn-block w-100 "
                          //data-bs-dismiss="modal"
                          style={{
                            background: "transparent",
                            color: "#18838D",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          // onClick={(e) => handleApproveReimburse(e, true)}
                        >
                          Decline
                        </button>
                      </div>
                      // <div className="submit-section">
                      //   {" "}
                      //   <button
                      //     type="button"
                      //     className="btn btn-success btn-block w-100 "
                      //     //data-bs-dismiss="modal"
                      //     style={{
                      //       background: "transparent",
                      //       color: "#18838D",
                      //       cursor: "default",
                      //     }}
                      //     // onClick={(e) => handleApproveReimburse(e, true)}
                      //   >
                      //     Approved
                      //   </button>{" "}
                      // </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Leave Modal */}
        <div
          id="employee_report"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  Generate Reimbursement Report
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Employee Name</label>
                        <Select
                          // styles={colourStyles}
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          className="add-emp-field"
                          options={empList}
                          value={reimReport.empid}
                          onChange={(e) =>
                            setReimReport({
                              ...reimReport,
                              empid: e,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Department</label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          // options={genderData}
                          isDisabled
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={empData
                            .filter(
                              (ele) => reimReport?.empid?.value === ele.empid
                            )
                            .map((ele) => ({
                              label: ele.department,
                              value: ele.department,
                            }))}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>From Date</label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            value={reimReport.from}
                            onChange={(e) =>
                              setReimReport({
                                ...reimReport,
                                from: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>To Date</label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            value={reimReport.to}
                            onChange={(e) =>
                              setReimReport({
                                ...reimReport,
                                to: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Month</label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="month"
                            value={month1}
                            onChange={(e) => setMonth1(e.target.value)}
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      style={{
                        border: "none",
                        padding: "4px 10px",
                        background: "#3298DB",
                        color: "white",
                        borderRadius: "15px",
                        marginTop: "20px",
                      }}
                      data-bs-dismiss="modal"
                      onClick={(e) => generateReport(e)}
                    >
                      Generate
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Edit Leave Modal */}

        {/* Decline Reason Modal */}

        <div
          id="exampleModal"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ marginTop: "15px" }}>
                {/* <h5 className="modal-title">Edit Leave</h5> */}
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>
                    Reason<span className="text-danger">*</span>:
                  </label>
                  <input
                    className="form-control"
                    value={declineReason}
                    onChange={(e) => setDeclineReason(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        style={{ background: "transparent", color: "#18838D" }}
                        // onClick={(e) => handleApproveReimburse(e, true)}
                      >
                        Close
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        onClick={(e) => handleApproveReimburse(e, true)}
                      >
                        Decline
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="edit_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Leave Modal */}
        {/* Approve Leave Modal */}
        <div
          className="modal custom-modal fade"
          id="approve_leave"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Leave Approve</h3>
                  <p>Are you sure want to approve for this leave?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Approve
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Decline
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="view_reim" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header" style={{ marginTop: "15px" }}>
                {/* <h5 className="modal-title">Edit Leave</h5> */}
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label>
                    Claim Approved <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    disabled={
                      reimburseDetails.status === "Approved by HOD"
                        ? true
                        : false
                    }
                    value={appReim}
                    onChange={(e) => setAppReim(e.target.value)}
                    type="number"
                    min="0"
                    onInput={(e) => {
                      e.target.value =
                        Math.abs(e.target.value) >= 0
                          ? Math.abs(e.target.value)
                          : null;
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Note</label>
                  <input
                    className="form-control"
                    onChange={(e) => setAppDesc(e.target.value)}
                    value={appDesc}
                    type="text"
                  />
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        style={{ background: "transparent", color: "#18838D" }}
                        // onClick={(e) => handleApproveReimburse(e, true)}
                      >
                        Close
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-success btn-block w-100"
                        onClick={(e) => handleApproveReimburse(e, false)}
                      >
                        Approve
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementAdmin;
