import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { RotatingLines } from "react-loader-spinner";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import empty from "../../../assets/img/profiles/browser.png";
// import empty from "../../../assets/img/profiles/emptystate.svg";
import aadhar from "../../../assets/img/profiles/Aadhaar_Logo.svg.png";
import Pan from "../../../assets/img/profiles/pan-card.jpg";
import pdflogo from "../../../assets/img/profiles/Adobe-PDF-File-Icon-01.svg";
import Photo from "../../../assets/img/profiles/photo.svg";
import cv from "../../../assets/img/profiles/cva.png";
import offerl from "../../../assets/img/profiles/offerl.png";
import EduEx from "../../../assets/img/profiles/eduEx.png";
import sign from "../../../assets/img/profiles/sign.png";
import CrossIcon from "../../../assets/img/profiles/closeline.svg";
import CheckIcon from "../../../assets/img/profiles/checkfill.svg";
import { ArrowBack } from "@mui/icons-material";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import CancelIcon from "@mui/icons-material/Cancel";

const AdminDocument = () => {
  const [pdfFile, setPdfFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [declineReason, setDeclineReason] = useState("");
  const [menu, setMenu] = useState(false);
  const [viewLeaveDetails, setViewLeaveDetails] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [loading, setLoading] = useState(true);
  const [toggel, setToggel] = useState(true);
  const [empDetails, setEmpDetails] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [currDocs, setCurrDocs] = useState({
    link: "",
    name: "",
  });
  const [empId, setEmpId] = useState(null);
  const [docColumn, setDocColumn] = useState([]);

  const options = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];

  const [docsStatus, setDocsStatus] = useState({
    empl_id: sessionStorage.getItem("emp_id"),
    status: { label: null, value: null },
  });

  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const getDocs = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          view: "b",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //setData(res.data);
        setEmployeeDetails(res.data);
        setDocColumn(res.data.columns);
        setData(
          res.data["data"].map((ele, index) => ({
            ...ele,
            sno: index + 1,
            closeicon: CrossIcon,
            checkicon: CheckIcon,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDocs();
  }, []);

  const newData = data?.filter((Data) => {
    if (employeeName === "") {
      return Data;
    } else if (Data.name.toLowerCase().includes(employeeName.toLowerCase())) {
      return Data;
    }
  });

  const handleDocs = (e, value, approval_id) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/approve/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          approval_id: approval_id,
          decline: value,
          reason: declineReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleUpdate();
        Swal.fire({
          icon: res.data.status === false ? "error" : "success",
          // text: res.data.status === true ? "Document Verified" : "",

          // icon:
          //   res.data.error === "Cannot approved by same person"
          //     ? "error"
          //     : "success",
          text:
            res.data.error === "Already Approved"
              ? "Document Verified"
              : res.data.error === "Cannot approved by same person"
              ? "Cannot approved or rejected by same person"
              : res.data.error === "Cannot approved by someone of lower hierchy"
              ? "You have no authority to approve or decline request"
              : res.data.error ===
                "Cannot approved by someone of different departmenr"
              ? "You have no authority to approve or decline request"
              : res.data.status === true
              ? "Document Verified"
              : "Document Rejected",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/`, {
        params: {
          empid: empId,
          admid: sessionStorage.getItem("admid"),
          view: "d",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleUpdate();
  }, [empId]);

  const getDocsInfo = (id, e) => {
    //e.preventDefault;
    //setIsLoading(true);
    setToggel(false);
    setEmpId(id);
  };

  const col = docColumn.map((ele) => ({
    title: ele,
    align: "center",
    //dataIndex: {closei},
    render: (text, record) =>
      text.documents[ele] ? (
        <img src={record.checkicon} />
      ) : (
        <img src={record.closeicon} />
      ),
    //console.log(text.documents["My CV"])
  }));

  const column1 = [
    {
      title: "Sno.",
      dataIndex: "sno",
      fixed: "left",
    },
    {
      title: "Name of Employee",
      dataIndex: "name",
      fixed: "left",
    },
  ];

  const ActB = [
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          className=" btn-primary submit-btn1"
          onClick={(e) => getDocsInfo(text.key, e)}
        >
          View
        </button>
      ),
    },
  ];

  console.log(data);

  const column2 = [
    {
      title: "Document Name",
      dataIndex: "doc_name",
      //sorter: (a, b) => a.doc_name.length - b.doc_name.length,
    },
    {
      title: "View File",
      //dataIndex: "upload_file",
      render: (text, record) => (
        <button
          className="btn btn-info "
          style={{
            padding: "2px 23px",
            fontSize: "14px",
            borderRadius: "15px",
            //height:"25px",
            //background: " #DF4E2F",
            border: "none",
            color: "white",
          }}
          //data-bs-toggle="modal"
          //data-bs-target="#view_documents"
          //href="#"
          classname="edit-icon"
          onClick={() => {
            //setCurrDocs({ link: text.link[0].link.links, name: text.doc_name });
            setPdfFile(text.link[0].link.links);
            setViewPdf(true);
          }}
        >
          View
        </button>
      ),

      // <button
      //   className="choose-file-btn"
      //   style={{ background: "#3298DB" }}
      //   onClick={() => console.log(text.doc_id)}
      // >
      //   <a
      //   href={text}
      //   // target="_blank"
      //   rel="noopener noreferrer"
      //   style={{ color: "white" }}
      //   >
      //     View
      //   </a>
      // </button>
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <>
          <div className="dropdown action-label text-center">
            <a
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i
                className={
                  text === "Pending"
                    ? "fa fa-dot-circle-o text-primary"
                    : text === "Approved"
                    ? "fa fa-dot-circle-o text-success"
                    : "fa fa-dot-circle-o text-danger"
                }
              />{" "}
              {text === "Approved"
                ? "Verified"
                : text === "Declined"
                ? "Rejected"
                : "Pending"}
            </a>
            <div className="dropdown-menu dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                //type="button"
                //className="dropdown-item btn btn-success btn-block w-100 "
                //data-bs-dismiss="modal"
                //style={{ background: "transparent", color: "#18838D" }}
                //data-bs-toggle="modal"
                //data-bs-target="#exampleModal"
                onClick={(e) => handleDocs(e, true, record.approval_id)}
              >
                <i className="fa fa-dot-circle-o text-danger" />
                Reject
              </a>
              <a
                className="dropdown-item"
                href="#"
                //onClick={(e)=>handleDocs(e, record.approval_id)}
              >
                <i className="fa fa-dot-circle-o text-primary" />
                Pending
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleDocs(e, false, record.approval_id)}
              >
                <i className="fa fa-dot-circle-o text-success" />
                Verify
              </a>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Documents</h3>
              </div>
              {toggel === false && (
                <div className="col-auto float-end ml-auto">
                  <div className="view-icons">
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "3px 13px",
                        fontSize: "15px",
                        background: "#2e86c1",
                        border: "1px solid white",
                        color: "white",
                        borderRadius: "20px",
                      }}
                      classname="edit-icon"
                      onClick={() => (setToggel(true), setViewPdf(false))}
                    >
                      <ArrowBack /> Go Back
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}

          {/* Search Filter */}
          {toggel ? (
            <>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                  <div className="form-group">
                    <Select
                      placeholder="Employee Name"
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      options={[...new Set(data?.map((item) => item.name))].map(
                        (ele) => ({
                          label: ele,
                          value: ele,
                        })
                      )}
                      isClearable={true}
                      value={
                        employeeName !== ""
                          ? {
                              label: employeeName,
                              value: employeeName,
                            }
                          : null
                      }
                      defaultValue={options[0]}
                      onChange={(e) => {
                        setEmployeeName(e ? e.value : "");
                      }}
                    />
                  </div>
                </div>

                {/*
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                  <div className="form-group form-focus select-focus">
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                  <div className="form-group form-focus select-focus">
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                  <a
                    href="#"
                    className="btn btn-block w-100"
                    style={{ fontSize: "14px", marginBottom: "16px", background: "#1f8ded", color: "white" }}
                  >
                    {" "}
                    Search{" "}
                  </a>
                </div>
                */}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          pagination={{
                            total: newData.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={column1.concat(col).concat(ActB)}
                          dataSource={newData}
                          rowKey={(record) => record.id}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <a href="#">
                          <img
                            alt=""
                            src={
                              empDetails.profile_pic
                                ? empDetails.profile_pic.links
                                : smiley
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div
                            className="profile-info-left"
                            style={{ lineHeight: "300%" }}
                          >
                            <div className="text-muted">
                              {`ID:${empDetails.empl_id ? empDetails.empl_id : "-"}`}
                            </div>

                            <h3
                              className="user-name m-t-0 mb-0"
                              style={{ textTransform: "capitalize" }}
                            >
                              {empDetails.name ? empDetails.name : "-"}
                            </h3>

                            <small className="text-muted">
                              {empDetails.designation
                                ? empDetails.designation
                                : "-"}
                            </small>

                            <div
                              className="small doj text-muted"
                              style={{ marginTop: "-10px" }}
                            >
                              {`Date of Join :${
                                empDetails.date_joined
                                  ? empDetails.date_joined
                                  : "-"
                              }`}
                            </div>

                            <h6 className="text-muted">
                              {empDetails.office_name
                                ? empDetails.office_name
                                : "-"}{" "}
                            </h6>
                            
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Phone:</div>
                              <div className="text">
                                <a href="">
                                  {empDetails.phone ? empDetails.phone : "-"}{" "}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <a href="">
                                  {empDetails.email ? empDetails.email : "-"}
                                </a>
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">
                                {empDetails.bday ? empDetails.bday : "-"}
                              </div>
                            </li>
                            <li>
                              <div className="title"> Address:</div>
                              <div className="text">
                                {empDetails.address ? empDetails.address : "-"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div
                                className="text"
                                style={{ textTransform: "capitalize" }}
                              >
                                {empDetails.gender ? empDetails.gender : "-"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Reports to:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    
                                  </div>
                                </div>
                                <Link to="/app/profile/employee-profile">
                                  {empDetails.report_to
                                    ? empDetails.report_to
                                    : "-"}
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
          <br />*/}
              <br />

              {viewPdf ? (
                <div
                  style={{
                    background: "#FFFFFF",
                    padding: "16px",
                    textAlign: "right",
                    borderRadius: "10px",
                  }}
                >
                  <CancelIcon
                    sx={{ color: "#2E86C1", cursor: "pointer" }}
                    onClick={() => setViewPdf(false)}
                  />
                  <br />

                  <div style={{ height: "100%" }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={
                          pdfFile ? pdfFile : "/assets/pdf-open-parameters.pdf"
                        }
                      />
                    </Worker>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-sm-12">
                      <div
                        className="card mb-0"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="row col-sm-12">
                            {loading === false ? (
                              <>
                                {empDetails?.length > 0 &&
                                  empDetails?.map((ele, index) => {
                                    return (
                                      <div
                                        className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                                        style={{
                                          marginTop: "10px",
                                          marginBottom: "10px",
                                          textAlign: "center",
                                        }}
                                      >
                                        <img
                                          //src={Pan}
                                          src={
                                            ele.doc_name === "Aadhaar Card"
                                              ? aadhar
                                              : ele.doc_name === "PAN Card"
                                              ? Pan
                                              : ele.doc_name === "Offer Letter"
                                              ? offerl
                                              : ele.doc_name ===
                                                "Education Certificate"
                                              ? EduEx
                                              : ele.doc_name ===
                                                ("Passport Size Photograph" ||
                                                  "Profile pic")
                                              ? Photo
                                              : ele.doc_name ===
                                                "Experience Certificate"
                                              ? EduEx
                                              : ele.doc_name === "Resume"
                                              ? cv
                                              : ele.doc_name === "Signature"
                                              ? sign
                                              : pdflogo
                                          }
                                          style={{
                                            height: "60px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (ele.uploaded) {
                                              setPdfFile(
                                                ele.link[0].link.links
                                              );
                                              setViewPdf(true);
                                            }
                                          }}
                                        />
                                        <p
                                          style={{
                                            marginBottom: "-2px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <b>{ele.doc_name}</b>
                                        </p>
                                        <div
                                          className="document-status"
                                          style={{ marginTop: "10px" }}
                                        >
                                          <div className="dropdown action-label text-center">
                                            {ele.uploaded === true ? (
                                              <a
                                                className=" btn btn-white btn-sm btn-rounded"
                                                href="#"
                                              >
                                                <i className="fa fa-dot-circle-o text-success" />{" "}
                                                Uploaded
                                              </a>
                                            ) : (
                                              <a className=" btn btn-white btn-sm btn-rounded">
                                                <i
                                                  className="fa fa-dot-circle-o text-danger"
                                                  style={{ marginLeft: "8px" }}
                                                />{" "}
                                                Not Uploaded &nbsp;
                                              </a>
                                            )}
                                          </div>

                                          {ele.uploaded === true ? (
                                            <div className="dropdown action-label text-center">
                                              <a
                                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                                href="#"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                disabled={
                                                  rolePermissions[
                                                    "Employee Documents"
                                                  ] === "Edit/Create/Approve"
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <i
                                                  className={
                                                    ele.status === "Pending"
                                                      ? "fa fa-dot-circle-o text-primary"
                                                      : ele.status ===
                                                        "Approved"
                                                      ? "fa fa-dot-circle-o text-success"
                                                      : "fa fa-dot-circle-o text-danger"
                                                  }
                                                />{" "}
                                                {ele.status === "Approved"
                                                  ? "Verified"
                                                  : ele.status === "Declined"
                                                  ? "Rejected"
                                                  : "Pending"}
                                              </a>
                                              <div className="dropdown-menu dropdown-menu">
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  //type="button"
                                                  //className="dropdown-item btn btn-success btn-block w-100 "
                                                  //data-bs-dismiss="modal"
                                                  //style={{ background: "transparent", color: "#18838D" }}
                                                  //data-bs-toggle="modal"
                                                  //data-bs-target="#exampleModal"
                                                  onClick={(e) => {
                                                    handleDocs(
                                                      e,
                                                      true,
                                                      ele.approval_id
                                                    );
                                                    console.log(
                                                      ele.approval_id
                                                    );
                                                  }}
                                                >
                                                  <i className="fa fa-dot-circle-o text-danger" />
                                                  Reject
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  //onClick={(e)=>handleDocs(e, record.approval_id)}
                                                >
                                                  <i className="fa fa-dot-circle-o text-primary" />
                                                  Pending
                                                </a>
                                                <a
                                                  className="dropdown-item"
                                                  href="#"
                                                  onClick={(e) =>
                                                    handleDocs(
                                                      e,
                                                      false,
                                                      ele.approval_id
                                                    )
                                                  }
                                                >
                                                  <i className="fa fa-dot-circle-o text-success" />
                                                  Verify
                                                </a>
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    );
                                  })}
                                {empDetails?.length === 0 && (
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      style={{ height: "100px" }}
                                      src={empty}
                                    />

                                    <p>
                                      <b>No Documents Uploaded</b>
                                    </p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <div
                                style={{
                                  textAlign: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <RotatingLines
                                  strokeColor="#2e86c1"
                                  strokeWidth="5"
                                  animationDuration="0.50"
                                  width="50"
                                  marginRight="700"
                                  visible={true}
                                />
                              </div>
                            )}
                          </div>
                          {/* <div className="table-responsive">
                        <Table
                          // pagination={{
                          //   total: data.length,
                          //   showTotal: (total, range) =>
                          //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          //   showSizeChanger: true,
                          //   onShowSizeChange: onShowSizeChange,
                          //   itemRender: itemRender,
                          // }}
                          pagination={false}
                          style={{ overflowX: "auto", fontSize: "14px" }}
                          columns={columns}
                          bordered
                          dataSource={data}
                          rowKey={(record) => record.id}
                          loading={isLoading}
                        // onChange={this.handleTableChange}
                        />
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* decline document */}
      <div
        className="modal custom-modal fade"
        role="dialog"
        id="exampleModal"
        // tabindex="-1"
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">
                    <b>Reason:</b>
                  </label>
                  <textarea
                    class="form-control"
                    id="message-text"
                    value={declineReason}
                    onChange={(e) => setDeclineReason(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                className="submit-btn2"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={(e) => handleDocs(e, true)}
                data-bs-dismiss="modal"
                className="submit-btn2"
              >
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* document part */}
      <div
        id="view_documents"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ borderBottom: " 2px solid #cccccc" }}
            >
              <h4
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                {" "}
                {currDocs.name}{" "}
              </h4>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div
              className="modal-body"
              style={{ height: "100%", width: "800px", overflowY: "auto" }}
            >
              <iframe
                id="iframepdf"
                src={currDocs.link}
                style={{ width: "750px", height: "500px" }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDocument;
