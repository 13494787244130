import React, { Component, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Select from "react-select";
import up from "../../assets/img/profiles/arrow-up-s-line.svg";
import down from "../../assets/img/profiles/arrow-down-s-line.svg";
import rupee from "../../assets/img/profiles/Rupee-Symbol-Black.svg";
import axios from "axios";
import { eightyC, eightyD } from "./taxConst";
import TaxPreview from "./TaxPreview";
const Taxform = () => {
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [eightyc, setEightc] = useState(eightyC);
  const [eightyd, setEightd] = useState(eightyD);
  const [rent, setRent] = useState(true);
  const [deduct, setDeduct] = useState({});
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(true);
  const [showp, setShowp] = useState(false);
  const [eightyG, setEightyG] = useState(0);
  const [eightyE, setEightyE] = useState(0);
  const [eightyEe, setEightyEe] = useState(0);
  const [eightyDd, setEightDd] = useState({
    selfDisabilit: 0,
    amountSD: 0,
    dependentDisability: 0,
    amountDD: 0,
  });
  const [eightyCCG, setEightyCCG] = useState(0);
  const [eightyDDB, setEightyDDB] = useState(0);
  const [section, setSection] = useState({
    age: 0,
    isMetroCity: 0,
    rentORpropoerty: 0,
  });
  useEffect(() => {
    setEightc([
      {
        name: "Life Insurance Premium Paid for yourself, spouse, or children",
        field_name: "life_insure",
        value: 0,
      },
      {
        name: "Repayment of Housing Loan (Principal Component)",
        field_name: "house_repo",
        value: 0,
      },
      {
        name: "Deposit in PF/Superannuation fund",
        field_name: "pf",
        value: 0,
      },
      {
        name: "Investment in 5 yr fixed deposit/bonds",
        field_name: "fd",
        value: 0,
      },
      {
        name: "Investment in NSC",
        field_name: "nsc",
        value: 0,
      },
      {
        name: "Tuition Fee of 2 children",
        field_name: "tution",
        value: 0,
      },
      {
        name: "Stamp duty/fee for purchase of residential house",
        field_name: "stamp_duty",
        value: 0,
      },
      {
        name: "Mutual Fund/UTI and other investments eligible for 80C",
        field_name: "mutual_fund",
        value: 0,
      },
      {
        name: "Your Individual Contribution to NPS (maximum possible 10% of salary- basic+DA)",
        field_name: "nps",
        value: 0,
      },
    ]);
    setEightd([
      {
        name: "Payment for Medical Insurance Premium/CGHS (for non-senior citizens) *mode other than cash (Self, Dependent Children and Spouse)",
        field_name: "cghs_family",
        value: 0,
      },
      {
        name: "Payment for Medical Insurance Premium/CGHS (for non-senior citizens) *mode other than cash (Parents)",
        field_name: "cghs_parents",
        value: 0,
      },
      {
        name: "Payment for Medical Insurance Premium (for senior citizens) (Self, Dependent Children and Spouse)",
        field_name: "medical_insurance_family",
        value: 0,
      },
      {
        name: "Payment for Medical Insurance Premium (for senior citizens) (Parents)",
        field_name: "medical_insurance_parents",
        value: 0,
      },
      {
        name: "Payments for preventive health check-ups (Self, Dependent Children and Spouse)",
        field_name: "preventive_health_family",
        value: 0,
      },
      {
        name: "Payments for preventive health check-ups (Parents)",
        field_name: "preventive_health_parents",
        value: 0,
      },
      {
        name: "Medical expenditure on the health of resident senior citizen and very senior citizen for whom no amount is paid to effect / keep in force an insurance on the health (mode of payment other than cash) (Self, Dependent Children and Spouse)",
        field_name: "medical_expenditure_family",
        value: 0,
      },
      {
        name: "Medical expenditure on the health of resident senior citizen and very senior citizen for whom no amount is paid to effect / keep in force an insurance on the health (mode of payment other than cash) (Parents)",
        field_name: "medical_expenditure_parents",
        value: 0,
      },
    ]);
    setEightyG(0);
    setEightyE(0);
    setEightyEe(0);
    setEightDd({
      selfDisabilit: 0,
      amountSD: 0,
      dependentDisability: 0,
      amountDD: 0,
    });
    setEightyCCG(0);
    setEightyDDB(0);
    setSection({
      age: 0,
      isMetroCity: 0,
      rentORpropoerty: 0,
    });
  }, [show]);
  const handleFormChange1 = (index, event, state, setState, value) => {
    let data = [...state];
    // console.log(index);
    // console.log(event);
    data[index][value] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index]["value"] = parseInt(event.target.value);
    // data[index][event] = event.value;
    setState(data);
    console.log(data);
  };
  const HandleGetDeclaration = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tds/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        // tds: tds.annual_tds,
      })
      .then((res) => {
        console.log(res.data);
        // Swal.fire({
        //   icon: "success",
        //   text: "Your IDF Submitted Successfully",
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    HandleGetDeclaration();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [showp]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title" id="scrollTop">
                  Investment Declaration Form
                </h3>
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Blank Page</li>
                </ul> */}
              </div>
            </div>
          </div>
          {!showp ? (
            <div
              className="col-md-12 main-page"
              // style={{ background: "white" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-20px",
                }}
              >
                <h3>Investment Declaration Form</h3>
              </div>
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                <div className="regime-btn">
                  <button
                    className={!show ? "old-btn" : "old1-btn"}
                    onClick={() => setShow(false)}
                  >
                    Old Tax Regime
                  </button>
                  <button
                    className={show ? "old-btn" : "old1-btn"}
                    onClick={() => setShow(true)}
                  >
                    New Tax Regime
                  </button>
                </div>
                <br />
                <div>
                  <label>Age:</label> &nbsp;
                  <input
                    style={{ borderRadius: "5px", height: "33px" }}
                    className="form-input"
                    type="number"
                    value={section.age}
                    onChange={(e) =>
                      setSection({ ...section, age: e.target.value })
                    }
                  />
                </div>
                <br />
                {!show ? (
                  <p>
                    * As a result of the recent tax changes, some existing form
                    fields may no longer be applicable *
                  </p>
                ) : (
                  ""
                )}
              </div>

              <form>
                {!show && (
                  <>
                    <div
                      // onClick={() => setShow(!show)}
                      className="col-md-12  "
                    >
                      <h4>
                        <b>
                          <u>
                            80C Deductions
                            {/* <img src={show ? up : down} /> */}
                          </u>
                        </b>
                      </h4>
                    </div>
                    <br />

                    <div className="contain">
                      {eightyc.map((input, index) => (
                        <>
                          <div className="col-md-12  ">
                            <label className="col-md-6">{input.name}</label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                name={input.field_name}
                                type="number"
                                className="tax-i col-md-8"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                // placeholder="Enter Amount"
                                value={input.value}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    eightyc,
                                    setEightc
                                  )
                                }
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                        </>
                      ))}
                    </div>

                    {/* <br />
                <br /> */}
                    <div>
                      <div
                        onClick={() => setShow1(!show1)}
                        className="col-md-12  "
                      >
                        <h4>
                          <b>
                            <u>
                              80D Deductions
                              {/* <img src={show1 ? up : down} /> */}
                            </u>
                          </b>
                        </h4>
                      </div>

                      <br />
                      <div className="contain">
                        {eightyd.map((input, index) => (
                          <>
                            <div className="col-md-12  ">
                              <label className="col-md-6">{input.name}</label>
                              <div
                                className=" col-md-3  tax-input"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <img src={rupee} className="rupee col-md-4" />
                                <input
                                  disabled={show}
                                  name={input.field_name}
                                  type="number"
                                  className="tax-i col-md-8"
                                  style={{
                                    cursor: show ? "not-allowed" : "auto",
                                  }}
                                  // placeholder="Enter Amount"
                                  value={input.value}
                                  onChange={(event) =>
                                    handleFormChange(
                                      index,
                                      event,
                                      eightyd,
                                      setEightd
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <br />
                            <br />
                          </>
                        ))}
                      </div>
                    </div>
                    <br />
                    <br />
                    <div>
                      <div
                        onClick={() => setShow2(!show2)}
                        className="col-md-12  "
                      >
                        <h4>
                          <b>
                            <u>
                              Other Deductions <img src={show2 ? up : down} />
                            </u>
                          </b>
                        </h4>
                      </div>
                      <br />
                      {show2 && !show && (
                        <div className="col-md-12 row contain">
                          <div>
                            <label className="col-md-6">80G- Donations</label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                type="number"
                                value={eightyG}
                                onChange={(e) => setEightyG(e.target.value)}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <label className="col-md-6">
                              80E- Interest on Loans for Higher Education
                            </label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                type="number"
                                value={eightyE}
                                onChange={(e) => setEightyE(e.target.value)}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <label className="col-md-6">
                              80EE- Interest on residential property less than
                              Rs 50 lakhs in value
                            </label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                disabled={show}
                                type="number"
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                value={eightyEe}
                                onChange={(e) => setEightyEe(e.target.value)}
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <div>
                              <label className="col-md-6">
                                80U or 80 DD- Disability of Self or Dependent
                              </label>
                              {/*
                          <div className=" col-md-3  tax-input">
                            <img src={rupee} className="rupee col-md-4" />
                            <input
                              className="tax-i col-md-8"
                            // placeholder="Enter Amount"
                            />
                          </div>
                          */}
                            </div>
                            <br />
                            <br />
                            <div style={{ marginLeft: "40px" }}>
                              <div>
                                <label className="col-md-6">
                                  80U- Self Disability- Non-Severe (40-80%)
                                </label>
                                <div
                                  className=" col-md-3  tax-input"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={rupee} className="rupee col-md-4" />
                                  <input
                                    className="tax-i col-md-8"
                                    type="number"
                                    disabled={show}
                                    style={{
                                      cursor: show ? "not-allowed" : "auto",
                                    }}
                                    value={eightyDd.selfDisabilit}
                                    onChange={(e) =>
                                      setEightDd({
                                        ...eightyDd,
                                        selfDisabilit: parseInt(e.target.value),
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <br />
                              <br />
                              <div>
                                <label className="col-md-6">
                                  80U- Self Disability- Severe({">"}=80%)
                                </label>
                                <div
                                  className=" col-md-3  tax-input"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={rupee} className="rupee col-md-4" />
                                  <input
                                    className="tax-i col-md-8"
                                    type="number"
                                    disabled={show}
                                    style={{
                                      cursor: show ? "not-allowed" : "auto",
                                    }}
                                    value={eightyDd.amountSD}
                                    onChange={(e) =>
                                      setEightDd({
                                        ...eightyDd,
                                        amountSD: parseInt(e.target.value),
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <br />
                              <br />
                              <div>
                                <label className="col-md-6">
                                  80DD- Dependent Disability- Non-Severe
                                  (40-80%)
                                </label>
                                <div
                                  className=" col-md-3  tax-input"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={rupee} className="rupee col-md-4" />
                                  <input
                                    className="tax-i col-md-8"
                                    type="number"
                                    disabled={show}
                                    style={{
                                      cursor: show ? "not-allowed" : "auto",
                                    }}
                                    value={eightyDd.dependentDisability}
                                    onChange={(e) =>
                                      setEightDd({
                                        ...eightyDd,
                                        dependentDisability: parseInt(
                                          e.target.value
                                        ),
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <br />
                              <br />
                              <div>
                                <label className="col-md-5">
                                  80DD- Dependent Disability- Severe ({">"}=80%)
                                </label>
                                <div
                                  className=" col-md-3  tax-input"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={rupee} className="rupee col-md-4" />
                                  <input
                                    className="tax-i col-md-8"
                                    type="number"
                                    disabled={show}
                                    style={{
                                      cursor: show ? "not-allowed" : "auto",
                                    }}
                                    value={eightyDd.amountDD}
                                    onChange={(e) =>
                                      setEightDd({
                                        ...eightyDd,
                                        amountDD: parseInt(e.target.value),
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <br />
                              <br />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <label className="col-md-6 ">
                              80CCG- First time Equity Investor with gross
                              salary less than <br /> Rs 12 lacks p.a. (50% of
                              amount invested in selected schemes)
                            </label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                type="number"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                value={eightyCCG}
                                onChange={(e) => setEightyCCG(e.target.value)}
                                // placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <label
                              className="col-md-6 "
                              style={{ marginTop: "20px" }}
                            >
                              80DDB- Expenses on specified diseases for
                              self/dependent
                            </label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                type="number"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                value={eightyDDB}
                                onChange={(e) => setEightyDDB(e.target.value)}
                                // placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <br />
                    <br />
                  </>
                )}
                <div>
                  {!show && (
                    <>
                      <h4>
                        <b>
                          <u>Rent/Self-Occupied Property</u>
                        </b>
                      </h4>
                      <br />

                      <div className="contain">
                        <div className="col-md-12 row">
                          <label className="col-md-2">Place Of Resident?</label>
                          <div className="col-md-2">
                            <input
                              type="radio"
                              id="ismetro"
                              disabled={show}
                              name="ismetro"
                              value="ismetro"
                              checked={section.isMetroCity === 1 ? true : false}
                              onChange={() =>
                                setSection({ ...section, isMetroCity: 1 })
                              }
                            />
                            &nbsp; &nbsp;
                            <label for="ismetro">Metro City</label>
                          </div>
                          <div className="col-md-2">
                            <input
                              type="radio"
                              id="nmetro"
                              disabled={show}
                              name="ismetro"
                              value="nmetro"
                              checked={section.isMetroCity === 0 ? true : false}
                              onChange={() =>
                                setSection({ ...section, isMetroCity: 0 })
                              }
                            />
                            &nbsp; &nbsp;
                            <label for="nmetro">Non Metro City</label>
                          </div>
                        </div>
                        <br />
                        <div className="col-md-12 row">
                          <label className="col-md-2">Are you on rent?</label>
                          <div className="col-md-1">
                            <input
                              type="radio"
                              id="html"
                              disabled={show}
                              name="fav_language"
                              value="HTML"
                              checked={rent}
                              onChange={() => setRent(true)}
                            />
                            &nbsp; &nbsp;
                            <label for="html">Yes</label>
                          </div>
                          <div className="col-md-1">
                            <input
                              type="radio"
                              id="css"
                              disabled={show}
                              name="fav_language"
                              value="CSS"
                              checked={!rent}
                              onChange={() => setRent(false)}
                            />
                            &nbsp; &nbsp;
                            <label for="css">No</label>
                          </div>
                        </div>
                        <br />
                        {rent ? (
                          <div className="col-md-12 row">
                            <label className="col-md-3">
                              Please enter monthly rental value
                            </label>
                            <div
                              className=" col-md-3  tax-input"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img src={rupee} className="rupee col-md-4" />
                              <input
                                className="tax-i col-md-8"
                                disabled={show}
                                style={{
                                  cursor: show ? "not-allowed" : "auto",
                                }}
                                value={section.rentORpropoerty}
                                onChange={(e) =>
                                  setSection({
                                    ...section,
                                    rentORpropoerty: e.target.value,
                                  })
                                }
                                // placeholder="Enter Amount"
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="col-md-12 row">
                              <label
                                className="col-md-6"
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                **Claim this if you have a self-occupied
                                property on whose loan you are paying interest.
                              </label>
                            </div>
                            <br />
                            <div
                              className="col-md-12 row"
                              style={{ alignItems: "self-start" }}
                            >
                              <label className="col-md-3">
                                Please enter interest on housing loan on
                                self-occupied property
                              </label>
                              <div className=" col-md-3  tax-input">
                                <img src={rupee} className="rupee col-md-4" />
                                <input
                                  className="tax-i col-md-8"
                                  disabled={show}
                                  style={{
                                    cursor: show ? "not-allowed" : "auto",
                                  }}
                                  // placeholder="Enter Amount"
                                  value={section.rentORpropoerty}
                                  onChange={(e) =>
                                    setSection({
                                      ...section,
                                      rentORpropoerty: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <br />
                    </>
                  )}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <a href="#scrollTop">
                      <button
                        className="tax-btn"
                        onClick={() => setShowp(true)}
                      >
                        Proceed
                      </button>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <TaxPreview
              eightyc={eightyc}
              setEightc={setEightc}
              eightyd={eightyd}
              setEightd={setEightd}
              eightyG={eightyG}
              eightyE={eightyE}
              eightyEe={eightyEe}
              eightyDd={eightyDd}
              eightyCCG={eightyCCG}
              eightyDDB={eightyDDB}
              showp={showp}
              setShowp={setShowp}
              section={section}
              setSection={section}
              show={show}
              rent={rent}
            />
          )}
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};
export default Taxform;
