import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";

const AddLeave = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allEmp, setAllEmp] = useState([]);

  const maritalStatus = [
    { label: "Married", value: "Married" },
    { label: "Single", value: "Single" },
    { label: "Anyone", value: "Anyone" },
  ];

  const Status = [
    { label: "Active", value: "Active" },
    { label: "InActive", value: "InActive" },
  ];
  const Duration = [
    { label: "Financial Year", value: "Financial Year" },
    { label: "Regular Year", value: "Regular Year" },
    { label: "Other", value: "Other" },
  ];

  const Gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Anyone", value: "Anyone" },
  ];
  const [addLeave, setAddLeave] = useState({
    leave_name: "",
    leave_duration: null,
    from_date: "",
    to_date: "",
    noofdays: null,
    code: "",
    gender: "Anyone",
    marital_status: "Anyone",
    status: "",
    description: "",
    all: true,
  });
  const [updateLeave, setUpdateLeave] = useState({});
  const [emList, setEmList] = useState([]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");

  const handleLeave = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/admin_leave/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: {
            admid: JSON.parse(sessionStorage.getItem("admid")),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele) => ({
            ...ele,
            all: ele.empid.length === 0 ? true : false,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEmpList = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/master_employees/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: {
            // empid: JSON.parse(sessionStorage.getItem("emp_id")),
            coregid: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // setData(res.data);
        console.log(res.data);
        // setIsLoading(false);
        setEmList(
          res.data.map((ele) => ({ label: ele.name, value: ele.empid }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log("adafsgdfhjdskjdfghknfjh", allEmp);

  useEffect(() => {
    handleLeave();
    handleEmpList();
  }, []);

  const handleAddLeave = (e) => {
    e.preventDefault();
    if (
      !addLeave.leave_name ||
      !addLeave.leave_duration ||
      !addLeave.from_date ||
      !addLeave.to_date ||
      !addLeave.description ||
      !addLeave.code ||
      !addLeave.status ||
      !addLeave.noofdays
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all Mandatory fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/admin_leave/`,
          {
            company_id: sessionStorage.getItem("coid"),
            leave_name: addLeave.leave_name,
            leave_duration: addLeave.leave_duration,
            from_date: addLeave.from_date,
            to_date: addLeave.to_date,
            noofdays: addLeave.noofdays,
            code: addLeave.code,
            gender: addLeave.gender,
            marital_status: addLeave.marital_status,
            status: addLeave.status,
            description: addLeave.description,
            empid: allEmp.map((ele) => ele.value),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Leave added successfully.",
          });
          handleLeave();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  const handleUpdatedLeave = (e) => {
    e.preventDefault();
    if (
      !updateLeave.leave_name ||
      !updateLeave.leave_duration ||
      !updateLeave.from_date ||
      !updateLeave.to_date ||
      !updateLeave.noofdays ||
      !updateLeave.code ||
      /*
      !updateLeave.gender ||
      !updateLeave.marital_status ||
      */
      !updateLeave.status ||
      !updateLeave.description
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all Mandatory fields.",
        // timer: 1500,
      });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/admin_leave/`,
          {
            id: updateLeave.id,
            leave_name: updateLeave.leave_name,
            leave_duration: updateLeave.leave_duration,
            from_date: updateLeave.from_date,
            to_date: updateLeave.to_date,
            noofdays: updateLeave.noofdays,
            code: updateLeave.code,
            gender: updateLeave.gender,
            marital_status: updateLeave.marital_status,
            status: updateLeave.status,
            description: updateLeave.description,
            empid: allEmp.map((ele) => ele.value),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Leave updated successfully.",
          });
          handleLeave();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leave_name",
      //   sorter: (a, b) => a.leaveleavetype.length - b.type.length,
    },
    {
      title: "Code",
      dataIndex: "code",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "No Of Days",
      dataIndex: "noofdays",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },

    {
      title: "Select Leave",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded" href="#">
            <i
              className={
                text === "Active"
                  ? "fa fa-dot-circle-o text-info"
                  : "fa fa-dot-circle-o text-danger"
                // ? "fa fa-dot-circle-o text-info"
                // : text === "Approved"
                // ? "fa fa-dot-circle-o text-success"
                // : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#edit_leave"
          className=" btn-primary submit-btn1"
          onClick={(e) => {
            setUpdateLeave(text);
            setAllEmp(emList.filter((ele) => text.empid.includes(ele.value)));
          }}
        >
          Edit
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Leaves</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_leave"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp; Add Leaves
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={data}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Leave</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-8">
                      <label>
                        Name of the Leave<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control datetimepicker"
                        type="text"
                        value={addLeave.leave_name}
                        onChange={(e) =>
                          setAddLeave({
                            ...addLeave,
                            leave_name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label>
                        Leave Cycle <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={Duration}
                          value={
                            addLeave.leave_duration !== ""
                              ? {
                                  label: addLeave.leave_duration,
                                  value: addLeave.leave_duration,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setAddLeave({
                              ...addLeave,
                              leave_duration: e.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>
                          Validity From <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            value={addLeave.from_date}
                            onChange={(e) =>
                              setAddLeave({
                                ...addLeave,
                                from_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>
                          Validity To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            value={addLeave.to_date}
                            className="form-control datetimepicker"
                            type="date"
                            onChange={(e) =>
                              setAddLeave({
                                ...addLeave,
                                to_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" col-md-4">
                      <div className="form-group">
                        <label>
                          Status <span className="text-danger">*</span>
                        </label>
                        <div>
                          <Select
                            options={Status}
                            value={
                              addLeave.status !== ""
                                ? {
                                    label: addLeave.status,
                                    value: addLeave.status,
                                  }
                                : null
                            }
                            onChange={(e) =>
                              setAddLeave({
                                ...addLeave,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" col-md-4">
                      <div className="form-group">
                        <label>
                          No. of days <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={addLeave.noofdays}
                          onChange={(e) =>
                            setAddLeave({
                              ...addLeave,
                              noofdays: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          Code <span className="text-danger">*</span>
                        </label>

                        <input
                          className="form-control"
                          type="text"
                          value={addLeave.code}
                          onChange={(e) =>
                            setAddLeave({
                              ...addLeave,
                              code: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="row col-md-12">
                    <p>
                      <b>Applicable to</b>
                    </p>
                    <br />
                    <br />
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>Employees</label>
                        <div>
                          <Select
                            options={emList}
                            isMulti
                            isDisabled={addLeave.all}
                            // value={
                            //   addLeave.gender !== ""
                            //     ? {
                            //         label: addLeave.gender,
                            //         value: addLeave.gender,
                            //       }
                            //     : null
                            // }
                            value={allEmp}
                            onChange={(e) => setAllEmp(e)}
                          />
                          <input
                            type="checkbox"
                            checked={addLeave.all}
                            onChange={() => {
                              setAddLeave({ ...addLeave, all: !addLeave.all });
                              setAllEmp([]);
                            }}
                          />{" "}
                          &nbsp;All Employees
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>Gender</label>
                        <div>
                          <Select
                            options={Gender}
                            value={
                              addLeave.gender !== ""
                                ? {
                                    label: addLeave.gender,
                                    value: addLeave.gender,
                                  }
                                : null
                            }
                            onChange={(e) =>
                              setAddLeave({
                                ...addLeave,
                                gender: e.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>Marital Status</label>
                        <div>
                          <Select
                            options={maritalStatus}
                            value={
                              addLeave.marital_status !== ""
                                ? {
                                    label: addLeave.marital_status,
                                    value: addLeave.marital_status,
                                  }
                                : null
                            }
                            onChange={(e) =>
                              setAddLeave({
                                ...addLeave,
                                marital_status: e.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      value={addLeave.description}
                      className="form-control"
                      defaultValue={""}
                      onChange={(e) =>
                        setAddLeave({
                          ...addLeave,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="submit-section">
                    <button
                      className="btn modal-submit-btn"
                      data-bs-dismiss={
                        addLeave.leave_name === "" ||
                        addLeave.leave_duration === null ||
                        addLeave.from_date === "" ||
                        addLeave.to_date === "" ||
                        addLeave.status === "" ||
                        addLeave.noofdays === null ||
                        addLeave.code === "" ||
                        addLeave.description === ""
                          ? ""
                          : "modal"
                      }
                      onClick={(event) => handleAddLeave(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  Edit Leave
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-8">
                      <label>
                        Name of the Leave<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control datetimepicker"
                        type="text"
                        value={updateLeave.leave_name}
                        onChange={(e) =>
                          setUpdateLeave({
                            ...updateLeave,
                            leave_name: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label>
                        Duration <span className="text-danger">*</span>
                      </label>
                      <div>
                        <Select
                          options={Duration}
                          value={
                            updateLeave.leave_duration !== ""
                              ? {
                                  label: updateLeave.leave_duration,
                                  value: updateLeave.leave_duration,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setUpdateLeave({
                              ...updateLeave,
                              leave_duration: e.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Validity From <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            value={updateLeave.from_date}
                            onChange={(e) =>
                              setUpdateLeave({
                                ...updateLeave,
                                from_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Validity To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            value={updateLeave.to_date}
                            onChange={(e) =>
                              setUpdateLeave({
                                ...updateLeave,
                                to_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" col-md-4">
                      <div className="form-group">
                        <label>
                          Status <span className="text-danger">*</span>
                        </label>
                        <div>
                          <Select
                            options={Status}
                            value={
                              updateLeave.status !== ""
                                ? {
                                    label: updateLeave.status,
                                    value: updateLeave.status,
                                  }
                                : null
                            }
                            //value={updateLeave.status}
                            onChange={(e) =>
                              setUpdateLeave({
                                ...updateLeave,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          Number of days <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={updateLeave.noofdays}
                          onChange={(e) =>
                            setUpdateLeave({
                              ...updateLeave,
                              noofdays: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>
                          Code <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          value={updateLeave.code}
                          onChange={(e) =>
                            setUpdateLeave({
                              ...updateLeave,
                              code: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row col-md-12">
                      <p>
                        <b>Applicable For</b>
                      </p>
                      <br />
                      <br />
                      <div className="form-group col-md-6">
                        <div className="col-md-12">
                          {" "}
                          <label>Employees</label>
                          <div>
                            <Select
                              options={emList}
                              isMulti
                              isDisabled={updateLeave.all}
                              // value={
                              //   addLeave.gender !== ""
                              //     ? {
                              //         label: addLeave.gender,
                              //         value: addLeave.gender,
                              //       }
                              //     : null
                              // }
                              value={allEmp}
                              onChange={(e) => setAllEmp(e)}
                            />
                            <input
                              type="checkbox"
                              checked={updateLeave.all}
                              onChange={() => {
                                setUpdateLeave({
                                  ...updateLeave,
                                  all: !updateLeave.all,
                                });
                                setAllEmp([]);
                              }}
                            />{" "}
                            &nbsp;All Employees
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="col-md-12">
                          {" "}
                          <label>Gender</label>
                          <div>
                            <Select
                              options={Gender}
                              value={
                                updateLeave.gender !== ""
                                  ? {
                                      label: updateLeave.gender,
                                      value: updateLeave.gender,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setUpdateLeave({
                                  ...updateLeave,
                                  gender: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div className="col-md-12">
                          {" "}
                          <label>Marital Status</label>
                          <div>
                            <Select
                              options={maritalStatus}
                              value={
                                updateLeave.marital_status !== ""
                                  ? {
                                      label: updateLeave.marital_status,
                                      value: updateLeave.marital_status,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setUpdateLeave({
                                  ...updateLeave,
                                  marital_status: e.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                    <div className="form-group">
                      <label>
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={4}
                        className="form-control"
                        value={updateLeave.description}
                        onChange={(e) =>
                          setUpdateLeave({
                            ...updateLeave,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="submit-section">
                      <button
                        className=" btn"
                        data-bs-dismiss={
                          !updateLeave.leave_name ||
                          !updateLeave.leave_duration ||
                          !updateLeave.from_date ||
                          !updateLeave.to_date ||
                          !updateLeave.noofdays ||
                          !updateLeave.code ||
                          /*
                            !updateLeave.gender ||
                            !updateLeave.marital_status ||
                            */
                          !updateLeave.status ||
                          !updateLeave.description
                            ? ""
                            : "modal"
                        }
                        style={{
                          background: "#329fd1",
                          width: "94px",
                          height: "38px",
                          borderRadius: "5px",
                          fontSize: "16px",
                          color: "white",
                        }}
                        onClick={(event) => handleUpdatedLeave(event)}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddLeave;
