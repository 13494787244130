import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Swal from "sweetalert2";

const BasicSalary = (props) => {
  const {
    totalCtc,
    setTotalCtc,
    fixed,
    setFixed,
    otheV,
    othea,
    setOtheV,
    setOthea,
    totalSum,
    setTotalSum,
    otherAllow,
    otherDeduct,
    enrg,
    setEnrg,
    dummy,
    setDummy,
    variables,
    setVariables,
    deduct,
    setDeduct,
    fixedPer,
    setFixedPer,
    setOtherAllow,
    allData,
    setAllData,
    showPre,
    setShowPre,
    earn,
    setEarn,
    Ded,
    setDed,
    setVaria,
    varia,
  } = props;

  // const [allData, setAllData] = useState({});

  const columns = [
    { title: "Name of Component", dataIndex: "name" },
    { title: "Monthly", dataIndex: "monthly", align: "right" },
    { title: "Annually", dataIndex: "annually", align: "right" },
    {
      title: "Visible in Payslip",
      dataIndex: "payslip_visible",
      align: "center",
    },
  ];
  /*------------GET API for prefilled earnings,deductions, and variables---------------*/
  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          comp_id: sessionStorage.getItem("coid"),
          toggle: "t3",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setEnrg(
          res.data.earning.map((ele, i) => ({
            key: `E+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            amount: 0,
            annually: 0,
            enabled: true,
          }))
        );
        setVariables(
          res.data.variables.map((ele, i) => ({
            key: `V+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
          }))
        );
        setDeduct(
          res.data.deduction.map((ele, i) => ({
            key: `D+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index][event.target.name] = event.target.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const calculateSalary = async (e) => {
    e.preventDefault();
    const payload = enrg
      .concat(deduct)
      .concat(variables)
      .map((ele) => ({ id: ele.id, amt: parseInt(ele.amount) * 12 }));
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calc_salary/`, payload)
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setEarn(
          res.data.component
            .filter((item) => item.type === "A")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              payslip_visible: ele.payslip_visible === 1 ? "Yes" : "No",
            }))
        );
        setDed(
          res.data.component
            .filter((item) => item.type === "D")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              payslip_visible: ele.payslip_visible === 1 ? "Yes" : "No",
            }))
        );
        setVaria(
          res.data.component
            .filter((item) => item.type === "V")
            .map((ele, i) => ({
              key: i + 1,
              name: ele.name,
              annually: ele.value,
              monthly: ele.value / 12,
              payslip_visible: ele.payslip_visible === 1 ? "Yes" : "No",
            }))
        );
        setShowPre(true);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };
  console.log(enrg);
  console.log(allData);
  return (
    <div className="page-wrapper">
      {/* <div className="content container-fluid"> */}
      <div className="content container-fluid" style={{ marginTop: "-70px" }}>
        <div className="card col-md-9" style={{ padding: "20px" }}>
          {!showPre ? (
            <>
              <h4>Earnings</h4>
              {enrg.map((ele, i) => (
                <div
                  style={{ display: "flex", padding: "10px 10px 5px 10px" }}
                  className="col-md-12 row"
                >
                  <div
                    className="form-group col-md-12 "
                    style={{ display: "flex" }}
                  >
                    <p className=" col-md-6">{ele.name}</p>
                    <input
                      name="amount"
                      type="number"
                      disabled={ele.type === "Value" ? false : true}
                      value={ele.amount}
                      className="form-control "
                      placeholder="Enter Amount"
                      onChange={(e) => handleFormChange(i, e, enrg, setEnrg)}
                    />{" "}
                  </div>
                </div>
              ))}
              <h4>Deductions</h4>
              {deduct.map((ele, i) => (
                <div
                  style={{ display: "flex", padding: "10px 10px 5px 10px" }}
                  className="col-md-12 row"
                >
                  <div
                    className="form-group col-md-12 "
                    style={{ display: "flex" }}
                  >
                    <p className=" col-md-6">{ele.name}</p>
                    <input
                      type="number"
                      name="amount"
                      disabled={ele.type === "Value" ? false : true}
                      value={ele.amount}
                      onChange={(e) =>
                        handleFormChange(i, e, deduct, setDeduct)
                      }
                      className="form-control "
                      placeholder="Enter Amount"
                    />{" "}
                  </div>
                </div>
              ))}
              {variables.length !== 0 && (
                <>
                  <h4>Variables</h4>
                  {variables.map((ele, i) => (
                    <div
                      style={{ display: "flex", padding: "10px 10px 5px 10px" }}
                      className="col-md-12 row"
                    >
                      <div
                        className="form-group col-md-12 "
                        style={{ display: "flex" }}
                      >
                        <p className=" col-md-6">{ele.name}</p>
                        <input
                          type="number"
                          name="amount"
                          disabled={ele.type === "Value" ? false : true}
                          value={ele.amount}
                          onChange={(e) =>
                            handleFormChange(i, e, variables, setVariables)
                          }
                          className="form-control "
                          placeholder="Enter Amount"
                        />{" "}
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div style={{ display: "flex", justifyContent: "right" }}>
                <button
                  onClick={(e) => calculateSalary(e)}
                  style={{
                    width: "fit-content",
                    border: "none",
                    background: "#004693",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Calculate CTC
                </button>
              </div>
            </>
          ) : (
            <>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button
                  onClick={() => setShowPre(false)}
                  style={{
                    width: "fit-content",
                    border: "none",
                    background: "#004693",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Recalculate
                </button>
              </div>

              <br />
              {earn.length !== 0 && (
                <>
                  <label>Earnings</label>
                  <br />
                  <Table
                    columns={columns}
                    dataSource={earn}
                    pagination={false}
                  />
                  <br />
                </>
              )}

              {Ded.length !== 0 && (
                <>
                  <label>Deductions</label>
                  <br />
                  <Table
                    columns={columns}
                    dataSource={Ded}
                    pagination={false}
                  />
                  <br />
                </>
              )}

              {varia.length !== 0 && (
                <>
                  <label>Variables</label>
                  <br />
                  <Table
                    columns={columns}
                    dataSource={varia}
                    pagination={false}
                  />
                </>
              )}

              <br />
              <div style={{ display: "flex" }}>
                <p className="col-md-4">
                  <b>Monthly CTC :</b>&nbsp;
                  {allData.monthly_ctc}
                </p>
                <p className="col-md-4">
                  <b>Monthly Gross :</b>&nbsp;
                  {allData.monthly_gross}
                </p>
                <p className="col-md-4">
                  <b>Monthly Net :</b>&nbsp;
                  {allData.monthly_net}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="col-md-4">
                  <b>Annual CTC :</b>&nbsp;
                  {allData.annual_ctc}
                </p>
                <p className="col-md-4">
                  <b>Annual Gross :</b>&nbsp;
                  {allData.annual_gross}
                </p>
                <p className="col-md-4">
                  <b>Annual Net :</b>&nbsp;
                  {allData.annual_net}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>

    // </div>
  );
};
export default BasicSalary;
