import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
// import { Avatar_16, Avatar_02, Avatar_05, Avatar_09, Avatar_10, Avatar_11, Avatar_12, Avatar_01 } from "../../../Entryfile/imagepath"
import Avatar_16 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_02 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_05 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_09 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_10 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_11 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_12 from "../../assets/img/profiles/avatar-01.jpg";
import Avatar_01 from "../../assets/img/profiles/avatar-01.jpg";
import report from "../../assets/img/profiles/file-chart-2-line.png";
import DateTimePicker from "react-datetime-picker";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DefaultEditor } from "react-simple-wysiwyg";
import { Space, Spin } from "antd";
// import { RotatingLines } from "react-loader-spinner";
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Tooltip from "@mui/material/Tooltip";
import Swal from "sweetalert2";
import Select from "react-select";
import { taskData } from "./taskConstant";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Tasklist from "./Tasklist";
import axios from "axios";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import Spinner from "../spinners/Spinner";
import { Box } from "@mui/material";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";
import taskvis from "../../assets/img/profiles/task-visualization.svg";
import empty from "../../assets/img/profiles/browser.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import { useSearchParams, useLocation } from "react-router-dom";
import Avatar from "@atlaskit/avatar";
import Comment, {
  CommentAuthor,
  CommentTime,
  CommentAction,
} from "@atlaskit/comment";

const TaskBoard = () => {
  const [menu, setMenu] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showTasks, setShowTasks] = useState([]);
  const [empSendList, setEmpSendList] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [taskKey, setTaskKey] = useState(null);
  const [value, onChange] = useState(new Date());
  const [subTaskKey, setSubTaskKey] = useState(null);
  const [editTask, setEditTask] = useState({});
  const [proName, setProName] = useState("");
  const [taskSearch, setTaskSearch] = useState([]);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState({ content: "", file: null });
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(
    searchParams.get("id") === null ? "" : searchParams.get("id")
  );
  const [projectDetails, setProjectDetails] = useState([]);
  const [projectName, setProjectName] = useState(null);
  const [dateLimit, setDateLimit] = useState({});
  const [html, setHtml] = React.useState("my <b>HTML</b>");
  const [allPro, setAllPro] = useState([]);

  //console.log(Math.round(window.devicePixelRatio * 100));
  const [times, setTimes] = useState(true);
  function onChange1(e) {
    setHtml(e.target.value);
  }
  const navigate = useNavigate();

  const projectId = searchParams.get("id");
  console.log(projectId);

  const [addTask, setAddTask] = useState({
    empid: sessionStorage.getItem("emp_id"),
    Name: "",
    Description: "",
    Project: "",
    Subtask: "",

    Deadline: moment().add(1, "days").format("YYYY-MM-DD"),
    // Deadline: "",
    // Starting_date: new Date().toISOString().substring(0, 10),
    Starting_date: moment().format("YYYY-MM-DD"),
    // start_time: "",
    start_time: new Date().toLocaleTimeString([], {
      hourCycle: "h23",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }),
    end_time: new Date().toLocaleTimeString([], {
      hourCycle: "h23",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }),
    Priority: "Low",
    status: "No Progress",
  });
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const priorityDrop = [
    { label: "High", value: "High" },
    { label: "Medium", value: "Medium" },
    { label: "Low", value: "Low" },
  ];
  console.log(addTask);

  const handleGetProject = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/projects/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProjectDetails(
          res.data.map((ele) => ({ label: ele.name, value: ele.id }))
        );
        // setEmpList(res.data);
        setAllPro(res.data);
      });
  };
  useEffect(() => {
    handleGetProject();
  }, []);

  useEffect(() => {
    if (allPro.length > 0) {
      // console.log(projectName?.value, "BBB");
      const list = allPro.find((ele) => ele.id === projectName?.value); //=== projectName.value
      //console.log(list.map((ele) => ele.members.name));
      //console.log(empList.find((ele) => ele.id === projectName.value));
      const list1 = list?.members.concat(list.teamleads);
      const list2 = list1?.concat([list?.creator]);
      const list3 = [
        ...new Map(list2?.map((item) => [item["key"], item]))
          .values()
          ?.map((ele) => ({
            label: ele.name,
            value: ele.key,
          })),
      ];
      setEmpList(list3);
    }
  }, [projectName]);

  useEffect(() => {
    if (projectDetails?.filter((v) => v.label === editTask?.project_name)) {
      const data = projectDetails?.filter(
        (v) => v.label === editTask?.project_name
      );
      setProjectName(data ? data[0] : {});
    }
  }, [editTask]);

  useEffect(() => {
    if (allPro.length > 0) {
      const list = allPro.find((ele) => ele.name === dateLimit?.projectName1); //=== projectName.value
      //console.log(list.map((ele) => ele.members.name));

      //console.log(empList.find((ele) => ele.id === projectName.value));
      const list1 = list?.members.concat(list.teamleads);
      const list2 = list1?.concat([list?.creator]);
      const list3 = [
        ...new Map(list2?.map((item) => [item["key"], item]))
          .values()
          ?.map((ele) => ({
            label: ele.name,
            value: ele.key,
          })),
      ];
      setEmpList(list3);
    }
  }, [dateLimit.projectName1]);
  const token = sessionStorage.getItem("access_token");

  const handleGetTasks = async () => {
    // setIsLoading(true);
    if (proName === "") {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/tasks/`, {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // setShowTasks({
          //   ...res.data,
          //   subtask:res.data.subtask.map((ele,index)=>(
          //   {
          //     ...ele,
          //     max:res.data.due_date,
          //   }
          // ))})
          setShowTasks(res.data);
          setTaskSearch(res.data);
          // console.log(res.data);
          setIsLoading(false);
        });
    }
  };
  useEffect(() => {
    handleGetTasks();
  }, [proName]);

  {
    /*
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
      });
  }, []);
*/
  }

  const handleAddTask = (e) => {
    e.preventDefault();
    if (
      addTask.Name === "" ||
      addTask.Description === "" ||
      //projectName !== null ||
      empSendList.length === 0 ||
      addTask.Priority === "" ||
      times
    ) {
      if (times) {
        Swal.fire({
          icon: "error",
          text: "Due time should be greater than start time if start date and deadline is same!",
          // timer: 2500,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please fill all Mandatory fields!",
          // timer: 1500,
        });
      }
    } else {
      {
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/tasks/`,
            {
              empid: parseInt(addTask.empid),
              name: addTask.Name,
              description: addTask.Description,
              project:
                dateLimit.tag === "sub"
                  ? projectDetails?.find(
                      (ele) => ele.label === dateLimit.projectName1
                    ).value
                  : projectName.value,
              subtask: taskKey,
              assigned_to: Array.prototype.map.call(
                empSendList,
                function (item) {
                  return item.value;
                }
              ),
              // deadline: `${
              //   `${addTask.Deadline.toISOString()
              //     .substring(0, 10)
              //     .split("/")
              //     .join("-")}`.split(" ")[0] +
              //   " " +
              //   `${addTask.Deadline.toLocaleTimeString([], {
              //     hourCycle: "h23",
              //     hour: "2-digit",
              //     minute: "2-digit",
              //     second: "2-digit",
              //   })}`.split(" ")[0]
              // }`,
              // starting_date: `${
              //   `${addTask.Starting_date.toISOString()
              //     .substring(0, 10)
              //     .split("/")
              //     .join("-")}`.split(" ")[0] +
              //   " " +
              //   `${addTask.Starting_date.toLocaleTimeString([], {
              //     hourCycle: "h23",
              //     hour: "2-digit",
              //     minute: "2-digit",
              //     second: "2-digit",
              //   })}`.split(" ")[0]
              // }`,
              deadline: `${addTask.Deadline}` + " " + `${addTask.end_time}`,
              starting_date:
                `${addTask.Starting_date}` + " " + `${addTask.start_time}`,
              priority: addTask.Priority,
              status: addTask.status,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            handleGetTasks();
            Swal.fire({
              icon: "success",
              text: "Task Added Successfully",
              // timer: 2000,
            });
            setProjectName(null);
            setEmpSendList([]);
            setAddTask({
              empid: sessionStorage.getItem("emp_id"),
              Name: "",
              Description: "",
              Project: "",
              Subtask: "",
              Deadline: moment().add(1, "days").format("YYYY-MM-DD"),
              Starting_date: moment().format("YYYY-MM-DD"),
              start_time: new Date().toLocaleTimeString([], {
                hourCycle: "h23",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }),
              end_time: new Date().toLocaleTimeString([], {
                hourCycle: "h23",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }),
              Priority: "Low",
              status: "No Progress",
            });
          })
          .catch((err) => {
            if (err.response.status === 400) {
              Swal.fire({
                icon: "error",
                text: `${err.response.data.error}!`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong",
              });
            }
          });
      }
    }
  };

  const handleUpdateTask = (e) => {
    e.preventDefault();
    if (
      editTask.task_name === "" ||
      editTask.description === "" ||
      editTask.due_date === "" ||
      editTask.assign_to.length === 0
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields!",
        // timer: 1500,
      });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/tasks/`,
          {
            toggle_status: "F",

            empid: parseInt(sessionStorage.getItem("emp_id")),
            taskid: editTask.key,
            name: editTask.task_name,
            description: editTask.description,
            // project: addTask.Project,
            subtask: subTaskKey,
            assigned_to: Array.prototype.map.call(
              editTask.assign_to,
              function (item) {
                return item.value || item.key;
              }
            ),
            deadline: `${editTask.due_date}` + " " + `${editTask.due_time}`,
            // starting_date: addTask.Starting_date,
            priority: editTask.priority,
            status: editTask.status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          handleGetTasks();
          Swal.fire({
            icon: "success",
            text: "Task Edited Successfully",
            // timer: 2000,
          });
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  const handleDeleteTask = (e) => {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/tasks/?key=${taskKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleGetTasks();
        Swal.fire({
          icon: "success",
          text: "Task Deleted",
          // timer: 2000,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleStatus = (e, taskid, status) => {
    e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/tasks/`,
        {
          toggle_status: "T",
          empid: parseInt(sessionStorage.getItem("emp_id")),
          taskid: taskid,
          note: "Hii",
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        handleGetTasks();
        Swal.fire({
          icon: "success",
          text: "Task Edited Successfully",
          // timer: 2000,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleGetComments = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/comments/`, {
        params: {
          task_id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddComment = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("task_id", taskKey);
    formData.append("empid", sessionStorage.getItem("emp_id"));
    formData.append("content", comment.content);
    formData.append("file", comment.file);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/comments/`, formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 2000,
          text: "Comment added successfully",
        });
        handleGetComments(taskKey);
        setComment({ content: "", file: null });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleSearch = () => {
    const newData = taskSearch?.filter(
      (x) =>
        x.project_name?.toLocaleLowerCase() ==
        (proName == "" ? x.project_name?.toLocaleLowerCase() : proName)
    );
    setShowTasks(newData);
    // console.log(newData);
  };

  const restrictCon = () => {
    if (
      addTask.Deadline === addTask.Starting_date &&
      addTask.end_time <= addTask.start_time
    ) {
      setTimes(true);
    } else {
      setTimes(false);
    }
  };

  useEffect(() => {
    restrictCon();
  }, [
    addTask.end_time,
    addTask.Deadline,
    addTask.Starting_date,
    addTask.start_time,
  ]);

  const statusDrop = [
    { label: "No Progress", value: "No Progress" },
    { label: "In Progress", value: "In Progress" },
    { label: "Completed", value: "Completed" },
  ];
  // console.log(addTask);
  // console.log(editTask);
  // console.log(value);

  const clearAddTask = () => {
    setAddTask({
      empid: sessionStorage.getItem("emp_id"),
      Name: "",
      Description: "",
      Project: "",
      Subtask: "",

      // Deadline: "",
      // Starting_date: "",
      Deadline: moment().add(1, "days").format("YYYY-MM-DD"),
      Starting_date: moment().format("YYYY-MM-DD"),
      start_time: new Date().toLocaleTimeString([], {
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      end_time: new Date().toLocaleTimeString([], {
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      Priority: "Low",
      status: "No Progress",
    });
  };

  console.log(dateLimit);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <RotatingLines
              strokeColor="#2e86c1"
              strokeWidth="5"
              animationDuration="0.50"
              width="50"
              marginRight="700"
              visible={true}
            />
          </div>
        ) : (
          <>
            <div className="content container-fluid">
              {/* Page Header */}

              <div className="page-header">
                <div className="row">
                  <div className="col">
                    <h3 className="page-title" style={{ color: "white" }}>
                      Task Dashboard
                    </h3>
                  </div>
                  <div className="col-auto float-end ml-auto">
                    <div className="view-icons">
                      <Tooltip title="Task Visualization">
                        <div
                          // to="/attendance-grid"
                          className={
                            showTable
                              ? "grid-view btn btn-link "
                              : "grid-view btn btn-link active"
                          }
                          // onClick={() => setShowTable(false)}
                        >
                          {/* <i className="fa fa-th" /> */}
                          <img
                            src={taskvis}
                            onClick={() => navigate("/task-chart")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Tooltip>
                      &nbsp;&nbsp;
                      <Tooltip title="Task Report">
                        <div
                          // to="/attendance-grid"
                          className={
                            showTable
                              ? "grid-view btn btn-link "
                              : "grid-view btn btn-link active"
                          }
                          // onClick={() => setShowTable(false)}
                        >
                          {/* <i className="fa fa-th" /> */}
                          <img
                            src={report}
                            onClick={() => navigate("/task-report")}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </Tooltip>
                      &nbsp;&nbsp;
                      <button
                        disabled={
                          projectDetails.length === 0 && statusDrop.length === 0
                        }
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_task_board"
                        href="#"
                        classname="edit-icon"
                        onClick={() =>
                          setDateLimit({
                            max: "",
                            min: new Date().toISOString().split("T")[0],
                            tag: "",
                          })
                        }
                      >
                        <i className="fa fa-plus" /> &nbsp; Add Task
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Page Content */}
            {/* {isLoading ? (
          <div style={{ marginLeft: "45%", marginTop: "15%" }}>
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.50"
              width="50"
              marginRight="700"
              visible={true}
            />
          </div>
        ) : (
          <> */}
            {!showTable ? (
              <>
                <div className="content container-fluid">
                  {/* Page Header */}
                  <div className="page-header">
                    <div className="row">
                      <div className="col-sm-12">
                        <h3 className="page-title" style={{ color: "white" }}>
                          Task Dashboard
                        </h3>
                      </div>
                    </div>
                  </div>
                  {/* /Page Header */}

                  <div className=" row col-md-12">
                    <div className="card-body col-md-12">
                      <div className="row  col-md-12">
                        <div className="  col-md-3">
                          <div
                            className="kanban-header col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              className="page-title"
                              style={{ fontSize: "14px" }}
                            >{`Self Assigned (02)`}</span>
                            <div className="dropdown kanban-action">
                              <a href="" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_task_board"
                                >
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="kanban-wrap">
                            {taskData.self_assigned.map((ele) => (
                              <div className="card panel">
                                <div className="kanban-box">
                                  <div className="task-board-header">
                                    <span className="status-title">
                                      <a href="task-view.html">
                                        Website redesign
                                      </a>
                                    </span>
                                    <div className="dropdown kanban-task-action">
                                      <a href="" data-bs-toggle="dropdown">
                                        <i className="fa fa-angle-down" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit_task_modal"
                                        >
                                          Edit
                                        </a>
                                        <a className="dropdown-item" href="#">
                                          Delete
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="task-board-body">
                                    <div className="kanban-info">
                                      <div className="progress progress-xs">
                                        <div
                                          className="progress-bar"
                                          role="progressbar"
                                          style={{ width: "20%" }}
                                          aria-valuenow={20}
                                          aria-valuemin={0}
                                          aria-valuemax={100}
                                        />
                                      </div>
                                      <span>70%</span>
                                    </div>
                                    <div className="kanban-footer">
                                      <span className="task-info-cont">
                                        <span className="task-date">
                                          <i className="fa fa-clock-o" /> Sep 26
                                        </span>
                                        <span className="task-priority badge bg-inverse-danger">
                                          High
                                        </span>
                                      </span>
                                      {/* <span className="task-users">
                                <img
                                  src={Avatar_12}
                                  className="task-avatar"
                                  width={24}
                                  height={24}
                                />
                              </span> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="kanban-header col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              className="page-title"
                              style={{ fontSize: "14px" }}
                            >{`To Do (04)`}</span>
                            <div className="dropdown kanban-action">
                              <a href="" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_task_board"
                                >
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="kanban-wrap">
                            <div className="card panel">
                              <div className="kanban-box">
                                <div className="task-board-header">
                                  <span className="status-title">
                                    <a href="task-view.html">
                                      Website redesign
                                    </a>
                                  </span>
                                  <div className="dropdown kanban-task-action">
                                    <a href="" data-bs-toggle="dropdown">
                                      <i className="fa fa-angle-down" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit_task_modal"
                                      >
                                        Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="task-board-body">
                                  <div className="kanban-info">
                                    <div className="progress progress-xs">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: "20%" }}
                                        aria-valuenow={20}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <span>70%</span>
                                  </div>
                                  <div className="kanban-footer">
                                    <span className="task-info-cont">
                                      <span className="task-date">
                                        <i className="fa fa-clock-o" /> Sep 26
                                      </span>
                                      <span className="task-priority badge bg-inverse-warning">
                                        Normal
                                      </span>
                                    </span>
                                    <span className="task-users">
                                      <img
                                        src={Avatar_12}
                                        className="task-avatar"
                                        width={24}
                                        height={24}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card panel">
                              <div className="kanban-box">
                                <div className="task-board-header">
                                  <span className="status-title">
                                    <a href="task-view.html">
                                      Website redesign
                                    </a>
                                  </span>
                                  <div className="dropdown kanban-task-action">
                                    <a href="" data-bs-toggle="dropdown">
                                      <i className="fa fa-angle-down" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit_task_modal"
                                      >
                                        Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="task-board-body">
                                  <div className="kanban-info">
                                    <div className="progress progress-xs">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: "20%" }}
                                        aria-valuenow={20}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <span>70%</span>
                                  </div>
                                  <div className="kanban-footer">
                                    <span className="task-info-cont">
                                      <span className="task-date">
                                        <i className="fa fa-clock-o" /> Sep 26
                                      </span>
                                      <span className="task-priority badge bg-inverse-danger">
                                        High
                                      </span>
                                    </span>
                                    <span className="task-users">
                                      <img
                                        src={Avatar_12}
                                        className="task-avatar"
                                        width={24}
                                        height={24}
                                      />
                                      <span className="task-user-count">
                                        +2
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="add-new-task">
                            <a
                              href=""
                              data-bs-toggle="modal"
                              data-bs-target="#add_task_modal"
                            >
                              Add New Task
                            </a>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="kanban-header col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              className="page-title"
                              style={{ fontSize: "14px" }}
                            >
                              {` In Progress (04)`}
                            </span>
                            <div className="dropdown kanban-action">
                              <a href="" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_task_board"
                                >
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="kanban-wrap ks-empty"></div>
                          <div className="add-new-task">
                            <a
                              href=""
                              data-bs-toggle="modal"
                              data-bs-target="#add_task_modal"
                            >
                              Add New Task
                            </a>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div
                            className="kanban-header col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              className="page-title"
                              style={{ fontSize: "14px" }}
                            >
                              {`Completed (02)`}
                            </span>
                            <div className="dropdown kanban-action">
                              <a href="" data-bs-toggle="dropdown">
                                <i className="fa fa-ellipsis-v" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="kanban-wrap">
                            <div className="card panel">
                              <div className="kanban-box">
                                <div className="task-board-header">
                                  <span className="status-title">
                                    <a href="task-view.html">
                                      Website redesign
                                    </a>
                                  </span>
                                  <div className="dropdown kanban-task-action">
                                    <a href="" data-bs-toggle="dropdown">
                                      <i className="fa fa-angle-down" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#edit_task_modal"
                                      >
                                        Edit
                                      </a>
                                      <a className="dropdown-item" href="#">
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="task-board-body">
                                  <div className="kanban-info">
                                    <div className="progress progress-xs">
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: "20%" }}
                                        aria-valuenow={20}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                    <span>70%</span>
                                  </div>
                                  <div className="kanban-footer">
                                    <span className="task-info-cont">
                                      <span className="task-date">
                                        <i className="fa fa-clock-o" /> Sep 26
                                      </span>
                                      <span className="task-priority badge bg-inverse-success">
                                        Low
                                      </span>
                                    </span>
                                    <span className="task-users">
                                      <img
                                        src={Avatar_12}
                                        className="task-avatar"
                                        width={24}
                                        height={24}
                                      />
                                      <span className="task-user-count">
                                        +2
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="add-new-task">
                            <a
                              href=""
                              data-bs-toggle="modal"
                              data-bs-target="#add_task_modal"
                            >
                              Add New Task
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {showTasks.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      className="card "
                      style={{
                        height: "300px",
                        width: "350px",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                        src={empty}
                      />
                      <br />
                      <h4>There is no task for you</h4>
                      <h6>Click on the above button to create a new task.</h6>
                      <br />
                      <IconButton
                        sx={{ color: "#3298DB" }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_task_board"
                        onClick={() =>
                          setDateLimit({
                            max: "",
                            min: new Date().toISOString().split("T")[0],
                            tag: "",
                          })
                        }
                      >
                        <AddCircleOutlineIcon size="medium" />
                      </IconButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <Box sx={{ maxWidth: "250px", ml: 3 }}>
                      <Select
                        isClearable
                        placeholder="Project Name"
                        options={[
                          ...new Set(
                            showTasks?.map((item) => item.project_name)
                          ),
                        ].map((ele) => ({
                          label: ele,
                          value: ele,
                        }))}
                        value={
                          search !== ""
                            ? {
                                label: search,
                                value: search,
                              }
                            : null
                        }
                        onChange={(e) => setSearch(e ? e.value : "")}
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        }}
                      />
                    </Box>

                    {/*
            <div className="row filter-row col-md-12" style={{ marginTop: "-30px" }}>
              <div
                className="col-md-8"
                style={{
                  display: "flex",
                  marginLeft: "24px",
                }}
              >
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      isClearable
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      //isClearable={()=>name1.clearValue()}
                      placeholder="Project Name"
                      options={[
                        ...new Set(showTasks?.map((item) => item.project_name)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        search !== ""
                          ? {
                            label: search,
                            value: search,
                          }
                          : null
                      }
                      onChange={(e) => setSearch(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className=" col-md-2">
                      <button
                        className=" search-btn"
                        onClick={() => {
                          handleSearch();
                        }}
                      >
                        {" "}
                        Search{" "}
                      </button>
                    </div> 
              </div>
            </div>
           */}

                    <br />
                    <Tasklist
                      showTasks={showTasks?.filter((ele) => {
                        return search.toLocaleLowerCase() === ""
                          ? ele
                          : ele.project_name
                              .toLocaleLowerCase()
                              .includes(search.toLocaleLowerCase());
                      })}
                      setEditTask={setEditTask}
                      editTask={editTask}
                      taskKey={taskKey}
                      subTaskKey={subTaskKey}
                      setTaskKey={setTaskKey}
                      setSubTaskKey={setSubTaskKey}
                      setDateLimit={setDateLimit}
                      handleStatus={handleStatus}
                      isLoading={isLoading}
                      addTask={addTask}
                      setAddTask={setAddTask}
                      projectName={projectName}
                      setProjectName={setProjectName}
                      handleGetComments={handleGetComments}
                    />
                  </>
                )}
              </>
            )}

            {/* Comment section */}

            <div
              id="comment_task_board"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4
                      className="modal-title"
                      style={{ marginTop: "10px", fontWeight: "600" }}
                    >
                      Add comment
                    </h4>
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="modal"
                    >
                      <img src={closeIcon} />
                    </button>
                  </div>
                  <hr />
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div
                          className="col-sm-12"
                          style={{
                            height: "300px",
                            marginTop: "-26px",
                            overflowY: "auto",
                          }}
                        >
                          {comments.length > 0 ? (
                            <div className="">
                              {comments.map((ele, i) => (
                                <>
                                  <Comment
                                    headingLevel="5"
                                    avatar={
                                      <Avatar
                                        name="Scott Farquhar"
                                        src={ele.link}
                                      />
                                    }
                                    author={
                                      <CommentAuthor>{ele.name}</CommentAuthor>
                                    }
                                    time={
                                      <CommentTime>
                                        {`${ele.createddate?.slice(
                                          0,
                                          10
                                        )}  ${ele.createddate?.slice(11, 16)}`}
                                      </CommentTime>
                                    }
                                    content={<>{ele.comment}</>}
                                    actions={
                                      ele.link !== ""
                                        ? [
                                            <CommentAction>
                                              <a
                                                href={ele?.link[0]?.links}
                                                target="_blank"
                                              >
                                                Attached file
                                              </a>
                                            </CommentAction>,
                                          ]
                                        : []
                                    }
                                  />
                                  <div
                                    style={{
                                      borderBottom: "1px dotted black",
                                      margin: "10px 0",
                                      marginTop: "0px",
                                      marginLeft: "40px",
                                      width: "calc(100%-40px)",
                                    }}
                                  ></div>
                                </>
                              ))}
                            </div>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              No comments
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6 form-group">
                          <label style={{ marginBottom: "5px" }}>
                            Comment
                            <span className="text-danger">*</span>
                          </label>
                          {/* <input type="text" className="form-control" /> */}
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              setComment({
                                ...comment,
                                content: e.target.value,
                              })
                            }
                            value={comment.content}
                            style={{ height: "39px" }}
                          />
                        </div>

                        <div className="col-sm-5 form-group">
                          <label style={{ marginBottom: "5px" }}>
                            Add attachment
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) =>
                              setComment({
                                ...comment,
                                file: e.target.files[0],
                              })
                            }
                            style={{ height: "39px" }}
                          />
                        </div>
                        <div className="col-sm-1 form-group">
                          <label style={{ marginBottom: "5px" }}> </label>
                          <button
                            style={{
                              marginTop: "25px",
                              marginLeft: "-25px",
                              background: "#3298db",
                              color: "white",
                              border: "none",
                              borderRadius: "6px",
                              padding: "8px 18px",
                            }}
                            disabled={comment?.content === "" ? true : false}
                            onClick={(e) => handleAddComment(e)}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* delete specific task */}
            <div
              className="modal custom-modal fade"
              id="delete_task_board"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Task</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            className="continue-btn"
                            onClick={(e) => handleDeleteTask(e)}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            className="cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="add_task_board"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <br />

                  <div
                    className="modal-header"
                    style={{ borderBottom: " 2px solid #cccccc" }}
                  >
                    <h4
                      className="modal-title"
                      style={{ marginTop: "10px", fontWeight: "600" }}
                    >
                      {" "}
                      {dateLimit.tag === "sub"
                        ? "Add Sub Task"
                        : "Add Task"}{" "}
                    </h4>
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="modal"
                      onClick={clearAddTask}
                    >
                      <img src={closeIcon} />
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          {dateLimit.tag !== "sub" ? (
                            <div className="form-group">
                              <label style={{ marginBottom: "5px" }}>
                                Project Name
                                <span className="text-danger">*</span>
                              </label>
                              {/* <input type="text" className="form-control" /> */}
                              <Select
                                options={projectDetails}
                                value={projectName}
                                onChange={(e) => setProjectName(e)}
                              />
                            </div>
                          ) : (
                            <div className="form-group">
                              <label style={{ marginBottom: "5px" }}>
                                Project Name
                                <span className="text-danger">*</span>
                              </label>
                              {/* <input type="text" className="form-control" /> */}
                              <input
                                type="text"
                                disabled
                                className="form-control"
                                value={dateLimit.projectName1}
                                style={{ height: "39px" }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "4.5px" }}>
                              {dateLimit.tag === "sub"
                                ? "Sub Task Name"
                                : "Task Name"}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={addTask.Name}
                              style={{ height: "39px" }}
                              onChange={(e) =>
                                setAddTask({ ...addTask, Name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>
                              {dateLimit.tag === "sub"
                                ? "Sub Task Description"
                                : "Task Description"}
                              <span className="text-danger">*</span>
                            </label>
                            <textarea
                              type="text"
                              style={{ height: "3px", paddingBottom: "0px" }}
                              className="form-control"
                              value={addTask.Description}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Description: e.target.value,
                                })
                              }
                            />
                            {/* <Editor
                          editorState={ }
                          value={addTask.Description}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        /> */}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              Start Date<span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              min={
                                /*dateLimit.tag === "sub" ? moment(dateLimit.min).add(1, 'days').format('YYYY-MM-DD') : */ dateLimit.min
                              }
                              // max={moment(dateLimit.max)
                              //   .format("YYYY-MM-DD")}
                              max="9999-12-31"
                              value={addTask.Starting_date}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Starting_date: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          onChange={(e) =>
                            setAddTask({
                              ...addTask,
                              Starting_date: e,
                            })
                          }
                          value={addTask.Starting_date}
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Start Time</label>
                            <input
                              type="time"
                              className="form-control"
                              value={addTask.start_time}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  start_time: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          onChange={(e) =>
                            setAddTask({
                              ...addTask,
                              Starting_date: e,
                            })
                          }
                          value={addTask.Starting_date}
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              End Date<span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              min={moment(addTask.Starting_date).format(
                                "YYYY-MM-DD"
                              )}
                              // max={moment(dateLimit.max)
                              //   .format("YYYY-MM-DD")}
                              max="9999-12-31"
                              disabled={!addTask.Starting_date}
                              value={addTask.Deadline}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Deadline: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          value={addTask.Deadline}
                          onChange={(e) =>
                            setAddTask({
                              ...addTask,
                              Deadline: e,
                            })
                          }
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>End Time</label>
                            <input
                              type="time"
                              className="form-control"
                              min={
                                addTask.Deadline === addTask.Starting_date
                                  ? addTask.start_time
                                  : addTask.end_time
                              }
                              value={addTask.end_time}
                              //value={addTask.Deadline===addTask.Starting_date ? addTask.end_time > addTask.start_time : addTask.end_time }
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  end_time: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          onChange={(e) =>
                            setAddTask({
                              ...addTask,
                              Starting_date: e,
                            })
                          }
                          value={addTask.Starting_date}
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Assigned To<span className="text-danger">*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isMulti
                              options={empList}
                              value={empSendList}
                              onChange={(e) => {
                                if (e) {
                                  setEmpSendList(e.map((item) => item));
                                } else {
                                  setEmpSendList([]);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Priority<span className="text-danger">*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}{" "}
                            <Select
                              options={priorityDrop}
                              value={
                                addTask.Priority !== ""
                                  ? {
                                      label: addTask.Priority,
                                      value: addTask.Priority,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setAddTask({ ...addTask, Priority: e.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Status
                            </label>
                            {/* <input type="text" className="form-control" /> */}{" "}
                            <Select
                              options={statusDrop}
                              defaultValue={{
                                label: "No Progress",
                                value: "No Progress",
                              }}
                              //selectedValue={{label:"No Progress", value:"No Progress"}}
                              value={
                                addTask.status !== ""
                                  ? {
                                      label: addTask.status,
                                      value: addTask.status,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setAddTask({ ...addTask, status: e.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="submit-section">
                          <button
                            style={{ background: "#3298db", color: "white" }}
                            data-bs-dismiss={
                              addTask.Name !== "" &&
                              addTask.Description !== "" &&
                              //projectName !== null &&
                              empSendList.length !== 0 &&
                              addTask.Priority !== "" &&
                              !times
                                ? "modal"
                                : ""
                            }
                            className="btn"
                            onClick={(e) => handleAddTask(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="add_subtask_board"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Add Sub-Task </h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      <img src={closeIcon} />
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Project Name</label>
                            {/* <input type="text" className="form-control" /> */}
                            {/* <Select
                          options={projectDetails}
                          value={
                            addTask.Project !== ""
                              ? {
                                  label: addTask.Project,
                                  value: addTask.Project,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setAddTask({ ...addTask, Project: e.value })
                          }
                        /> */}
                            <h3>{addTask.Project}</h3>
                            {/* <input type="text" className="form-control" value={addTask.Project} /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Task Name</label>
                            <input
                              type="text"
                              className="form-control"
                              value={addTask.Name}
                              onChange={(e) =>
                                setAddTask({ ...addTask, Name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>Task Desription</label>
                            <textarea
                              type="text"
                              className="form-control"
                              value={addTask.Description}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Start Date</label>
                            <input
                              type="date"
                              className="form-control"
                              max="9999-12-31"
                              value={addTask.Starting_date}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Starting_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>End Date</label>
                            <input
                              type="date"
                              min={addTask.Starting_date}
                              max="9999-12-31"
                              className="form-control"
                              value={addTask.Deadline}
                              onChange={(e) =>
                                setAddTask({
                                  ...addTask,
                                  Deadline: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Assigned To</label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isMulti
                              options={empList}
                              value={empSendList}
                              onChange={(e) => {
                                if (e) {
                                  setEmpSendList(e.map((item) => item));
                                } else {
                                  setEmpSendList([]);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Priority</label>
                            {/* <input type="text" className="form-control" /> */}{" "}
                            <Select
                              options={priorityDrop}
                              value={
                                addTask.Priority !== ""
                                  ? {
                                      label: addTask.Priority,
                                      value: addTask.Priority,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setAddTask({ ...addTask, Priority: e.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="m-t-20 text-center">
                          <button
                            data-bs-dismiss="modal"
                            className="submit-btn"
                            onClick={(e) => handleAddTask(e)}
                            style={{ fontSize: "16px" }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="edit_task_board"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4
                      className="modal-title"
                      style={{ marginTop: "10px", fontWeight: "600" }}
                    >
                      {dateLimit.tag === "sub" ? "Edit Sub Task" : "Edit Task"}
                    </h4>
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="modal"
                    >
                      <img src={closeIcon} />
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>
                              {dateLimit.tag === "sub"
                                ? "Sub Task Name"
                                : "Task Name"}
                              <sup style={{ color: "red" }}>*</sup>
                            </label>

                            <input
                              type="text"
                              className="form-control"
                              value={
                                editTask.task_name ? editTask.task_name : ""
                              }
                              onChange={(e) =>
                                setEditTask({
                                  ...editTask,
                                  task_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Task Id</label>
                        <input
                          type="text"
                          className="form-control"
                          value={editTask.key ? editTask.key : ""}
                        /> */}
                        {/* <Select
                          options={projectDetails}
                          value={
                            addTask.Project !== ""
                              ? {
                                  label: addTask.Project,
                                  value: addTask.Project,
                                }
                              : null
                          }
                          onChange={(e) =>
                            setAddTask({ ...addTask, Project: e.value })
                          }
                        /> */}
                        {/* </div>
                    </div> */}
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label>
                              {dateLimit.tag === "sub"
                                ? "Sub Task Desription"
                                : "Task Desription"}
                              <sup style={{ color: "red" }}>*</sup>
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              value={editTask.description}
                              onChange={(e) =>
                                setEditTask({
                                  ...editTask,
                                  description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          value={addTask.Starting_date}
                          onChange={(e) =>
                            setAddTask({
                              ...addTask,
                              Starting_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div> */}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              End Date<sup style={{ color: "red" }}>*</sup>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              min={
                                moment().format("YYYY-MM-DD") >
                                editTask.assigned_date
                                  ? moment().format("YYYY-MM-DD")
                                  : editTask.assigned_date
                              }
                              max="9999-12-31"
                              //min={dateLimit.min}
                              //max={dateLimit.max}
                              value={editTask.due_date}
                              onChange={(e) =>
                                setEditTask({
                                  ...editTask,
                                  due_date: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          value={editTask.due_date}
                          onChange={(e) =>
                            setEditTask({
                              ...editTask,
                              due_date: e,
                            })
                          }
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>Due Time</label>
                            <input
                              type="time"
                              className="form-control"
                              value={editTask.due_time}
                              onChange={(e) =>
                                setEditTask({
                                  ...editTask,
                                  due_time: e.target.value,
                                })
                              }
                            />
                            {/* <DateTimePicker
                          className="form-control"
                          value={editTask.due_date}
                          onChange={(e) =>
                            setEditTask({
                              ...editTask,
                              due_date: e,
                            })
                          }
                        /> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Priority<sup style={{ color: "red" }}>*</sup>
                            </label>
                            {/* <input type="text" className="form-control" /> */}{" "}
                            <Select
                              options={priorityDrop}
                              value={
                                editTask.priority
                                  ? {
                                      label: editTask.priority,
                                      value: editTask.Priority,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setEditTask({ ...editTask, priority: e.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Status
                            </label>
                            {/* <input type="text" className="form-control" /> */}{" "}
                            <Select
                              defaultInputValue={editTask.status}
                              options={statusDrop}
                              disabled={true}
                              value={
                                editTask.status
                                  ? {
                                      label: editTask.status,
                                      value: editTask.status,
                                    }
                                  : null
                              }
                              onChange={(e) =>
                                setEditTask({ ...editTask, status: e.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label style={{ marginBottom: "5px" }}>
                              Assigned To<sup style={{ color: "red" }}>*</sup>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isMulti
                              options={empList}
                              value={
                                editTask.assign_to
                                  ? editTask.assign_to.map((ele) => ({
                                      label: ele.name || ele.label,
                                      value: ele.key || ele.value,
                                    }))
                                  : []
                              }
                              onChange={(e) => {
                                if (e) {
                                  setEditTask({
                                    ...editTask,
                                    assign_to: e.map((item) => item),
                                  });
                                } else {
                                  setEditTask({
                                    ...editTask,
                                    assign_to: [],
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="m-t-20 text-center">
                          <button
                            data-bs-dismiss="modal"
                            className=" btn-primary submit-btn1"
                            onClick={(e) => handleUpdateTask(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="new_project"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Create New Project</h4>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>Project Name</label>
                        <input className="form-control" type="text" />
                      </div>
                      <div className="submit-section">
                        <button className="btn btn-primary submit-btn">
                          Create Project
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="assign_leader"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Assign Leader to this project
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group m-b-30">
                      <input
                        placeholder="Search to add a leader"
                        className="form-control search-input"
                        type="text"
                      />
                      <span className="input-group-append">
                        <button className="btn btn-primary">Search</button>
                      </span>
                    </div>
                    <div>
                      <ul className="chat-user-list">
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_09} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">Richard Miles</div>
                                <span className="designation">
                                  Web Developer
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_10} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">John Smith</div>
                                <span className="designation">
                                  Android Developer
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_16} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">Jeffery Lalor</div>
                                <span className="designation">Team Leader</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="assign_user"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      Assign the user to this project
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group m-b-30">
                      <input
                        placeholder="Search a user to assign"
                        className="form-control search-input"
                        type="text"
                      />
                      <span className="input-group-append">
                        <button className="btn btn-primary">Search</button>
                      </span>
                    </div>
                    <div>
                      <ul className="chat-user-list">
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_09} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">Richard Miles</div>
                                <span className="designation">
                                  Web Developer
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_10} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">John Smith</div>
                                <span className="designation">
                                  Android Developer
                                </span>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <span className="avatar">
                                <img alt="" src={Avatar_16} />
                              </span>
                              <div className="media-body align-self-center text-nowrap">
                                <div className="user-name">Jeffery Lalor</div>
                                <span className="designation">Team Leader</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="submit-section">
                      <button className="btn btn-primary submit-btn">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TaskBoard;
