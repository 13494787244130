import React from "react";

const Documentsview = (props) => {
  const { file }=props;
  return (
    <div
      id="view_doc"
      className="modal fade  come-from-modal left"
      role="dialog"
      style={{ opacity: "1" }}
    >
      <div className="modal-dialog modal-dialog-right modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {/* <h5 className="modal-title">Edit Employee</h5> */}
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <iframe
              src={file}
              height="100%"
              width="100%"
              title="description"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documentsview;
