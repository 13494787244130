import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { Table } from "antd";
import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import Select from "react-select";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../antdstyle.css";
import Attendancegrid from "./Attendancegrid";
import axios from "axios";
import moment from "moment";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { Box, Modal, useMediaQuery } from "@mui/material";
// Datatable JS
// import "../../../assets/js/jquery.dataTables.min.js";
// import "../../../assets/js/dataTables.bootstrap4.min.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "150px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Attendancetable = (props) => {
  const { monthValue, setMonthValue } = props;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [isLoading, setIsLoading] = useState(true);
  const monthval = new Date().getMonth() + 1;
  const matchsmexact = useMediaQuery("(max-width:630px)");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  // const [monthValue, setMonthValue] = useState(
  //   `${new Date().getFullYear()}-${
  //     new Date().getMonth() + 1 > 9
  //       ? new Date().getMonth() + 1
  //       : `0${new Date().getMonth() + 1}`
  //   }`
  // );
  const token = sessionStorage.getItem("access_token");
  // console.log(monthValue);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/attendance/`,

        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            // range_from: moment(state[0].startDate).format("YYYY-MM-DD"),
            // range_to: moment(state[0].endDate).format("YYYY-MM-DD"),
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            overtime: "00:00:00",
            date: ele.date.substr(0, 10).split("-").reverse().join("-"),
            punchin: ele.punchin.substr(11, 8),
            punchout: ele.punchout.substr(11, 8),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [monthValue]);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      title: "Punch In",
      dataIndex: "punchin",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.punchin, "h:mm:ss") - moment(b.punchin, "h:mm:ss"),
      },
      //   sorter: (a, b) => a.punchin.length - b.punchin.length,
    },
    {
      title: "Punch Out",
      dataIndex: "punchout",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.punchout, "h:mm:ss") - moment(b.punchout, "h:mm:ss"),
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "center",
      sorter: (a, b) => a.location.localeCompare(b.break.location),
    },
    {
      title: "Break",
      dataIndex: "break",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.break, "h:mm:ss") - moment(b.break, "h:mm:ss"),
      },
    },

    {
      title: "Productive Hours",
      dataIndex: "prohrs",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.prohrs, "h:mm:ss") - moment(b.prohrs, "h:mm:ss"),
      },
    },
    {
      title: "Mood",
      dataIndex: "mood",
      align: "center",
      render: (text, record) => (
        <>{text === "" ? "----" : text[0].toUpperCase() + text.substring(1)}</>
      ),
      sorter: (a, b) => a.mood.localeCompare(b.mood),
    },
  ];
  return (
    <>
      <div className="card mb-0">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={
                matchsmexact
                  ? { width: "250px", overflow: "auto" }
                  : { minWidth: "200px", overflow: "auto" }
              }
            >
              <DateRangePicker
                onChange={(item) => setState([item.selection])}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
              />
            </Box>
          </Box>
        </Modal>
        <div
          style={{
            display: "flex",
            marginTop: "10px",
            marginRight: "10px",
            justifyContent: "end",
          }}
        >
          {/* <button
            className="btn btn-success btn-block "
            style={{
              padding: "5px 15px",
              fontSize: "15px",
              background: "#3298DB",
              border: "none",
              color: "white",
            }}
            onClick={handleOpen}
          >
            Select Date(s)
          </button> */}
          <input
            style={{
              width: "150px",
              borderRadius: "5px",
              height: "30px",
              padding: "10px",
            }}
            type="month"
            value={monthValue}
            onChange={(e) => setMonthValue(e.target.value)}
          />
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <Table
              pagination={{
                total: data.length,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                showSizeChanger: true,
                onShowSizeChange: onShowSizeChange,
                itemRender: itemRender,
              }}
              style={{ overflowX: "auto" }}
              columns={columns}
              bordered
              dataSource={data}
              rowKey={(record) => record.id}
              // onChange={this.handleTableChange}
              // exportable
              // searchable
              // exportableProps={{ showColumnPicker: true }}
              // searchableProps={{ fuzzySearch: true }}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Attendancetable;
