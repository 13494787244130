import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Table } from "antd";
import "antd/dist/antd.css";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import "./antdstyle.css";
import Select from "react-select";
import moment from "moment";

// import { Avatar_02,Avatar_05,Avatar_11, Avatar_12,Avatar_09,Avatar_10, Avatar_13 } from "../../../Entryfile/imagepath"
import Editemployee from "../../modelbox/Editemployee";
import Addemployee from "../../modelbox/Addemployee";
import Nav from "../../containers/Navbar/navbar";
import Sidebar from "../../pages/Sidebar/sidebar";
import Header from "../../pages/Sidebar/header";
import FilterOff from "../../assets/img/profiles/filter-off-fill1.svg";

const Employeeslist = () => {
  const [menu, setMenu] = useState(false);
  const [name1, setName1] = useState("");
  const [address1, setAddress1] = useState("");
  const [designation1, setDesignation1] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [serachData, setSerachData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [data, setData] = useState([
    {
      id: 1,
      name: "John Doe",
      role: "Web Designer",
      employee_id: "FT-0001",
      email: "johndoe@example.com",
      address: "Kandiwali",
      mobile: "9876543210",
      joindate: "1 Jan 2013",
    },
  ]);

  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/directory/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmployeeDetails(res.data);
        setSerachData(res.data);
        setIsLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    handleSearch1();
  }, [name1, address1, designation1]);

  const handleSearch1 = () => {
    const newData = employeeDetails
      .filter((x) => x.emp_name == (name1 == "" ? x.emp_name : name1))
      .filter((y) => {
        return address1.toLocaleLowerCase() === ""
          ? y
          : y.emp_adress?.toLocaleLowerCase().includes(address1);
      })
      .filter(
        (z) => z.emp_role == (designation1 == "" ? z.emp_role : designation1)
      );
    setSerachData(newData);
  };

  // useEffect( ()=>{
  //   if(('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });

  const columns = [
    {
      title: "Name",
      dataIndex: "emp_name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.emp_profile.links} className="image1" />
          </Link>
          <Link to="#">
            {text} <span>{record.role}</span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id - b.emp_id,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile - b.mobile,
    },

    {
      title: "Join Date",
      dataIndex: "emp_joined",
      sorter: {
        compare: (a, b) =>
          moment(a.emp_joined, "YYYY-MM-DD") -
          moment(b.emp_joined, "YYYY-MM-DD"),
      },
    },
    {
      title: "Role",
      dataIndex: "emp_role",
      sorter: (a, b) => a.emp_role.localeCompare(b.emp_role),
    },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_employee"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_employee"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <Helmet>
          <title>Employeeslist - HRMS</title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee Directory</h3>
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Employee</li>
                </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                {/* <a
                  href="#"
                  className="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_employee"
                >
                  <i className="fa fa-plus" /> ADD EMPLOYEE
                </a> */}
                <div className="view-icons">
                  <Link to="/allEmployee" className="grid-view btn btn-link">
                    <i className="fa fa-th" />
                  </Link>
                  <Link
                    to="/employee-list"
                    className="list-view btn btn-link active"
                  >
                    <i className="fa fa-bars" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Search Filter */}

          <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  //isClearable={()=>name1.clearValue()}
                  placeholder="Employee Name"
                  options={[
                    ...new Set(employeeDetails?.map((item) => item.emp_name)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e.value)}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Select Designation..."
                  options={[
                    ...new Set(employeeDetails?.map((item) => item.emp_role)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation1 !== ""
                      ? {
                          label: designation1,
                          value: designation1,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDesignation1(e.value);
                  }}
                  //className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
                {/* <label className="focus-label">Designation</label> */}
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-height"
                  placeholder="Location"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                  onChange={(e) =>
                    setAddress1(e.target.value.toLocaleLowerCase())
                  }
                />
                {/* <label className="focus-label">Location</label> */}
              </div>
            </div>

            <div className="col-sm-6 col-md-3">
              <a
                className="btn-block "
                onClick={() => (
                  setAddress1(""), setDesignation1(""), setName1("")
                )}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 7px ",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          {/* /Search Filter */}
          {/* <div className="row">
            <div className="col-md-12">
              <div className="table-responsive"> */}
          <div className="card mb-0">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  pagination={{
                    total: serachData.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  // bordered
                  dataSource={serachData}
                  rowKey={(record) => record.id}
                  onChange={console.log("change")}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Employee Modal */}
        {/* <Addemployee /> */}
        {/* /Add Employee Modal */}
        {/* Edit Employee Modal */}
        {/* <Editemployee /> */}
        {/* /Edit Employee Modal */}
        {/* Delete Employee Modal */}
        <div
          className="modal custom-modal fade"
          id="delete_employee"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Employee</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Employee Modal */}
      </div>
    </div>
  );
};

export default Employeeslist;
