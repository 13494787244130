/**
 * App Header
 */
import React, { useEffect, useState } from "react";
import { withRouter, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import dashboardlogo from "../../assets/navbaricons/overview.svg";
import taskslogo from "../../assets/navbaricons/task dashboard.svg";
import Interpretation from "../../assets/navbaricons/interpretation.svg";
import tasksboardlogo from "../../assets/navbaricons/task dashboard.svg";
import projectlogo from "../../assets/navbaricons/projects.svg";
import employeelogo from "../../assets/navbaricons/profile.svg";
import documents from "../../assets/navbaricons/documents.svg";
import transfer from "../../assets/navbaricons/transfer.svg";
import attendance from "../../assets/navbaricons/attendance.svg";
import leaves from "../../assets/navbaricons/leaves.svg";
import slideimg from "../../assets/img/profiles/Sidecollapse.svg";
import review from "../../assets/navbaricons/reviews.svg";
import tnd from "../../assets/navbaricons/tnd.svg";
import ctc from "../../assets/navbaricons/ctc breakup.svg";
import payslip from "../../assets/navbaricons/payslip.svg";
import tax from "../../assets/navbaricons/tax declaration.svg";
import reim from "../../assets/navbaricons/reimbursements.svg";
import guide from "../../assets/navbaricons/guide.svg";
import dict from "../../assets/navbaricons/directory.svg";
import hzone from "../../assets/navbaricons/hzone.svg";
import report from "../../assets/navbaricons/report1.svg";
import shift from "../../assets/navbaricons/shift.svg";
import pantry from "../../assets/navbaricons/pantry.svg";
import holiday from "../../assets/navbaricons/holiday.svg";
import feed from "../../assets/navbaricons/feed.svg";
import survey from "../../assets/navbaricons/survey.svg";
import facerecog from "../../assets/navbaricons/face-recog.svg";
import location1 from "../../assets/navbaricons/location.svg";
import issue from "../../assets/navbaricons/issue.svg";
import duty from "../../assets/navbaricons/duty.svg";
import content from "../../assets/navbaricons/content.svg";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { makeStyles } from "@material-ui/core/styles";
import {
  useMediaQuery,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@material-ui/core";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import axios from "axios";
import Swal from "sweetalert2";
import { Toolbar } from "@mui/material";
import { useLocation } from "react-router-dom";
import { right } from "@popperjs/core";
import recruit from "../../assets/img/profiles/recruitment.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "500px",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  maxWidth: 500,
  minWidth: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const Sidebar = (props) => {
  const [data, setData] = useState([]);
  const [openmodal, setOpenmodal] = useState(false);
  const handleModalOpen = () => setOpenmodal(true);
  const handleModalClose = () => setOpenmodal(false);
  const [isSideMenu, setSideMenu] = useState("");
  const [level2Menu, setLevel2Menu] = useState("");
  const [level3Menu, setLevel3Menu] = useState("");
  const [rotateside, setRotateSide] = useState(false);
  const role = JSON.parse(sessionStorage.getItem("role"));
  const adminActive = JSON.parse(sessionStorage.getItem("adminActive"));
  const [rights, setRights] = useState({});
  // Access DOM element object
  const rotated = document.getElementById("rotated");
  // Rotate element by 180 degrees clockwise
  const matchesxlexact = useMediaQuery("(max-width:991.9px)");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const token = sessionStorage.getItem("access_token");
  const happyZone = JSON.parse(sessionStorage.getItem("happyZone"));
  const location = useLocation();

  const GetHolidays = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/holidays_list/`,
        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            comp_id: sessionStorage.getItem("coid"),
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetHolidays();
  }, []);

  const handleGetRights = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/register_admin/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        setRights(res.data);
        // setLeaver(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetRights();
  }, []);

  const handleSwitch = () => {
    sessionStorage.setItem("adminActive", !adminActive);
    if (adminActive) {
      navigate("/dashboard");
    } else {
      navigate("/admin-overview");
    }
  };

  //console.log(anchorEl);
  // console.log(clockin_status);

  const useStyles = makeStyles((theme) => ({
    customHoverFocus: {
      "&:hover, &.Mui-focusVisible": { backgroundColor: "yellow" },
    },
    button: {
      backgroundColor: "green",
      color: theme.palette.common.white,
    },
    buttonIcon: {
      color: theme.palette.common.white,
    },
  }));
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.removeItem("access_token");
    // sessionStorage.removeItem("refresh_token");
    // sessionStorage.removeItem("user");
    // sessionStorage.removeItem("emp_id");
    // sessionStorage.removeItem("clockin_status");
    sessionStorage.clear();
    navigate("/");
  };
  const handlesidebar = () => {
    // rotated.style.transform = "rotate(180deg)";
    setRotateSide(!rotateside);
    document.body.classList.toggle("mini-sidebar");
  };

  const toggleSidebar = (value) => {
    //console.log(value);
    setSideMenu(value);
  };

  const toggleLvelTwo = (value) => {
    setLevel2Menu(value);
  };
  const toggleLevelThree = (value) => {
    setLevel3Menu(value);
  };

  let pathname = window.location.pathname;
  return (
    <div className="sidebar" id="sidebar">
      <img
        id="rotated"
        src={slideimg}
        style={{
          position: "absolute",
          marginLeft: rotateside === true ? "80%" : "95%",
          marginTop: "5px",
          cursor: "pointer",
          transform: rotateside === true && "rotate(180deg)",
          zIndex: "100",
        }}
        onClick={handlesidebar}
      />
      <Scrollbars
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax="95vh"
        thumbMinSize={30}
        universal={false}
        hideTracksWhenNotNeeded={true}
      >
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <button className=" logout-btn-mob" style={{ marginLeft: "20px" }}>
              <Box>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    color="white"
                    sx={{ ml: -2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    // className={classes.button}
                  >
                    <Avatar sx={{ width: 40, height: 40 }}>
                      <img
                        style={{ width: 40, height: 40 }}
                        src={
                          "https://i.pinimg.com/236x/07/33/ba/0733ba760b29378474dea0fdbcb97107.jpg"
                        }
                      />
                    </Avatar>
                    &nbsp;&nbsp;
                    <p style={{ color: "white", marginTop: "15px" }}>
                      {sessionStorage.getItem("name").split(" ")[0]}
                    </p>
                  </IconButton>
                </Tooltip>
              </Box>
            </button>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
              {/*
              <MenuItem>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              */}
              {role !== 0 && !adminActive && (
                <MenuItem onClick={() => handleSwitch()}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Switch to Admin
                </MenuItem>
              )}
              {role !== 0 && adminActive && (
                <MenuItem onClick={() => handleSwitch()}>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Switch to Employee
                </MenuItem>
              )}
              {JSON.parse(sessionStorage.getItem("permissions")) &&
                rolePermissions?.Admin !== "NoAccess" && (
                  <MenuItem onClick={() => navigate("/roles-permissions")}>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                  </MenuItem>
                )}

              <MenuItem onClick={handleModalOpen}>
                <ListItemIcon>
                  <CalendarMonthIcon fontSize="small" />
                </ListItemIcon>
                Holiday List
              </MenuItem>

              {location.pathname === "/dashboard" ? (
                <MenuItem data-bs-toggle="modal" data-bs-target="#change_theme">
                  <ListItemIcon>
                    <i className="fa fa-pencil m-r-5" />
                  </ListItemIcon>
                  Change Theme
                </MenuItem>
              ) : null}

              {/* <MenuItem onClick={() => navigate("/companysettings")}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
              <MenuItem onClick={() => handleLogout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
            <ul>
              <br />
              {!happyZone &&
                (matchesxlexact ? (
                  <>
                    <Link
                      to="/welcomezone"
                      onClick={() => sessionStorage.setItem("happyZone", true)}
                      style={{ color: "white" }}
                    >
                      <button
                        className="happy-zone"
                        style={{ height: "25px", marginRight: "30px" }}
                      >
                        <span>HAPPY ZONE</span>
                      </button>
                    </Link>
                    <br />
                    <br />
                  </>
                ) : null)}
              {/* {matchesxlexact ? (
                <>
                  <button
                    className="happy-zone"
                    style={{ height: "25px", marginRight: "30px" }}
                    onClick={() => navigate("/welcomezone")}
                  >
                    <span>HAPPY ZONE</span>
                  </button>
                  <br />
                  <br />
                </>
              ) : null} */}
              {/* <li className="menu-title">
                <span>DASHBOARD</span>
              </li> */}
              {/* <li className="submenu" style={{ marginBottom: "5px" }}>
                <Link
                  to="/admin-overview"
                  className={isSideMenu == "dashboard" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "dashboard" ? "" : "dashboard")
                  }
                >
                  <i className="la la-dashcube" />
                  <img src={dashboardlogo} />
                  <span>Overview </span>
                </Link>
              </li> */}
              {/* <li
                className={pathname.includes("admin-overview") ? "active" : ""}
                style={{ marginBottom: "5px" }}
              >
                <Link to="/admin-overview">
                  <i className="la la-users" />
                  <img src={dashboardlogo} />
                  <span>Overview</span>
                </Link>
              </li> */}
              {/* <li
                className={
                  pathname.includes("admin-interpretation") ? "active" : ""
                }
                style={{ marginBottom: "5px" }}
              >
                <Link to="/admin-interpretation">
                  <i className="la la-users" />
                  <img src={Interpretation} />
                  <span>Interpretation</span>
                </Link>
              </li> */}
              <li className="menu-title">
                <span>EMPLOYEES</span>
              </li>
              {(rolePermissions["Employee List"] !== "NoAccess" ||
                rolePermissions["Add Employee"] !== "NoAccess") && (
                <li className="submenu">
                  <a
                    href="#"
                    className={isSideMenu == "employees" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu == "employees" ? "" : "employees"
                      )
                    }
                  >
                    {" "}
                    <img src={employeelogo} /> <span>Employees</span>{" "}
                    <span className="menu-arrow" />
                  </a>
                  {isSideMenu == "employees" ? (
                    <ul>
                      {rolePermissions["Employee List"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("manageEmployee")
                                ? "active"
                                : ""
                            }
                            to="/manageEmployee"
                          >
                            Manage Employees
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Add Employee"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("add-employee") ? "active" : ""
                            }
                            to="/add-employee"
                          >
                            Add Employee
                          </Link>
                        </li>
                      )}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {rolePermissions["Employee Documents"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("admin-documents") ? "active" : ""
                  }
                >
                  <Link to="/admin-documents">
                    <img src={documents} />
                    <span> Documents</span>
                  </Link>
                </li>
              )}
              {rolePermissions["Attendance List"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("admin-attendance") ? "active" : ""
                  }
                >
                  <Link to="/admin-attendance">
                    <img src={attendance} />
                    <span>Manage Attendance</span>
                  </Link>
                </li>
              )}
              {/* {rolePermissions["Attendance List"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("geo-fencing-reg") ? "active" : ""
                  }
                >
                  <Link to="/geo-fencing-reg">
                    <img src={attendance} />
                    <span>Manage Geo Fencing</span>
                  </Link>
                </li>
              )} */}
              {/* <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "admin-attendace" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "admin-attendace" ? "" : "admin-attendace"
                    )
                  }
                >
                  {" "}
                  <img src={leaves} /> <span>Attendance</span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "admin-attendace" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("admin-attendance") ? "active" : ""
                        }
                        to="/admin-attendance"
                      >
                        Manage Attendance
                      </Link>
                    </li>

                    
                    <li>
                      <Link
                        className={
                          pathname.includes("invoices") ? "active" : ""
                        }
                        to="/app/sales/invoices"
                      >
                        Regularise Attendance
                      </Link>
                    </li>
                    
                  </ul>
                ) : (
                  ""
                )}
              </li> */}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("leaves") ? "active" : ""}
              >
                <Link to="/leaves">
                  <img src={leaves} />
                  <span>Leaves</span>
                </Link>
              </li> */}
              {(rolePermissions["Employee Leave"] !== "NoAccess" ||
                rolePermissions["Add Leave"] !== "NoAccess" ||
                rolePermissions["Leave Report"] !== "NoAccess") && (
                <li className="submenu">
                  <a
                    href="#"
                    className={isSideMenu == "leave" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(isSideMenu == "leave" ? "" : "leave")
                    }
                  >
                    {" "}
                    <img src={leaves} /> <span> Leaves</span>{" "}
                    <span className="menu-arrow" />
                  </a>
                  {isSideMenu == "leave" ? (
                    <ul>
                      {rolePermissions["Employee Leave"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("leaves-admin") ? "active" : ""
                            }
                            to="/leaves-admin"
                          >
                            Manage Leaves
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Employee Leave"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("short-leave") ? "active" : ""
                            }
                            to="/short-leave"
                          >
                            Manage Short Leave
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Employee Leave"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("outdoor") ? "active" : ""
                            }
                            to="/outdoor"
                          >
                            Manage Outdoor
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Add Leave"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("addleave-admin")
                                ? "active"
                                : ""
                            }
                            to="/addleave-admin"
                          >
                            Add Leaves
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Leave Report"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("leave-admin-report")
                                ? "active"
                                : ""
                            }
                            to="/leave-admin-report"
                          >
                            Leave Report
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Leave Report"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("comp-off-leave")
                                ? "active"
                                : ""
                            }
                            to="/comp-off-leave"
                          >
                            Generate Comp Off Leave
                          </Link>
                        </li>
                      )}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("transfer-admin") ? "active" : ""}
              >
                <Link to="/transfer-admin">
                  <img src={transfer} />
                  <span>Transfer</span>
                </Link>
              </li> */}
              {rolePermissions["Review Form"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={pathname.includes("review") ? "active" : ""}
                >
                  <Link to="/review-admin">
                    <img src={review} />
                    <span>Review</span>
                  </Link>
                </li>
              )}
              {rolePermissions["Employee Shift"] !== "NoAccess" && (
                <li className="submenu">
                  <a
                    href="#"
                    className={isSideMenu == "shift" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(isSideMenu == "shift" ? "" : "shift")
                    }
                  >
                    {" "}
                    <img style={{ width: "21px" }} src={shift} />{" "}
                    <span> Shift & Scheduling</span>{" "}
                    <span className="menu-arrow" />
                  </a>
                  {isSideMenu == "shift" ? (
                    <ul>
                      {/* <li>
                        <Link
                          className={
                            pathname.includes("admin-shiftscheduling")
                              ? "active"
                              : ""
                          }
                          to="/admin-shiftscheduling"
                        >
                          Shift Scheduling
                        </Link>
                      </li> */}

                      <li>
                        <Link
                          className={
                            pathname.includes("shift-manage") ? "active" : ""
                          }
                          to="/shift-manage"
                        >
                          Manage Shifts
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("admin-shiftscheduling") ? "active" : ""}
              >
                <Link to="/admin-shiftscheduling">
                  <img src={shift} style={{fontWeight:"300"}}/>
                  <span>Shift & Scheduling</span>
                </Link>
              </li> */}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("training") ? "active" : ""}
              >
                <Link to="/training-admin">
                  <img src={tnd} />
                  <span>
                    Training and <br /> Developemnt
                  </span>
                </Link>
              </li> */}

              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "admin-happyzone" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "admin-happyzone" ? "" : "admin-happyzone"
                    )
                  }
                >
                  {" "}
                  <img style={{ width: "22px" }} src={content} />{" "}
                  <span>Contents</span> <span className="menu-arrow" />
                </a>
                {isSideMenu == "admin-happyzone" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("hz-content") ? "active" : ""
                        }
                        to="/hz-content"
                      >
                        HappyZone Contents
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          pathname.includes("training-content") ? "active" : ""
                        }
                        to="/training-content"
                      >
                        Training Contents
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>

              {/* Geo Location */}

              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "geo-location" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "geo-location" ? "" : "geo-location"
                    )
                  }
                >
                  {" "}
                  <img style={{ width: "22px" }} src={location1} />{" "}
                  <span>Geo Location</span> <span className="menu-arrow" />
                </a>
                {isSideMenu == "geo-location" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("geo-tracking") ? "active" : ""
                        }
                        to="/geo-tracking"
                      >
                        Geo Tracking
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={
                          pathname.includes("geo-fencing-reg") ? "active" : ""
                        }
                        to="/geo-fencing-reg"
                      >
                        Geo Fencing
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("geo/tagging") ? "active" : ""
                        }
                        to="/geo/tagging"
                      >
                        Geo Tagging
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>

              <li className="menu-title">
                <span>FINANCE</span>
              </li>
              {(rolePermissions["Overview Payroll"] !== "NoAccess" ||
                rolePermissions["Run Payroll"] !== "NoAccess") && (
                <li className="submenu">
                  <a
                    href="#"
                    className={isSideMenu == "payroll" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(isSideMenu == "payroll" ? "" : "payroll")
                    }
                  >
                    {" "}
                    <img src={payslip} /> <span>Payroll</span>{" "}
                    <span className="menu-arrow" />
                  </a>
                  {isSideMenu == "payroll" ? (
                    <ul>
                      {rolePermissions["Overview Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("admin-payroll") ? "active" : ""
                            }
                            to="/admin-payroll"
                          >
                            Overview Payroll
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Run Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("add-payroll") ? "active" : ""
                            }
                            to="/add-payroll"
                          >
                            Run Payroll
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Run Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("hold-payroll") ? "active" : ""
                            }
                            to="/hold-payroll"
                          >
                            Hold Payroll
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Run Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("deductions") ? "active" : ""
                            }
                            to="/deductions"
                          >
                            Deduction
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Run Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("pf/deduction") ? "active" : ""
                            }
                            to="/pf/deduction"
                          >
                            PF Deduction
                          </Link>
                        </li>
                      )}
                      {rolePermissions["Run Payroll"] !== "NoAccess" && (
                        <li>
                          <Link
                            className={
                              pathname.includes("arrears") ? "active" : ""
                            }
                            to="/arrears"
                          >
                            Arrears
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          className={
                            pathname.includes("pf-report") ? "active" : ""
                          }
                          to="/pf-report"
                        >
                          PF Report
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("Ctcbreakupgrid") ? "active" : ""}
              >
                <Link to="/Ctcbreakupgrid-admin">
                  <img src={ctc} />
                  <span>CTC Breakup</span>
                </Link>
              </li> */}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("payslip") ? "active" : ""}
              >
                <Link to="/payslip-admin">
                  <img src={payslip} />
                  <span>Payslip</span>
                </Link>
              </li> */}
              {/* <li style={{ marginBottom: "5px" }}>
                <Link
                  className={pathname.includes("designations") ? "active" : ""}
                  to="/app/employee/designations"
                >
                  <img src={payroll} />
                  <span>Payroll</span>
                </Link>
              </li> */}
              {/* <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("samarth") ? "active" : ""}
              >
                <Link to="/samarth">
                  <img src={tax} />
                  <span>Samarth</span>
                </Link>
              </li> */}
              {rolePermissions["Employee Reimbursement"] !== "NoAccess" && (
                <li className="submenu">
                  <a
                    href="#"
                    className={isSideMenu == "reimbursement" ? "subdrop" : ""}
                    onClick={() =>
                      toggleSidebar(
                        isSideMenu == "reimbursement" ? "" : "reimbursement"
                      )
                    }
                  >
                    {" "}
                    <img src={reim} /> <span>Reimbursements</span>{" "}
                    <span className="menu-arrow" />
                  </a>
                  {isSideMenu == "reimbursement" ? (
                    <ul>
                      <li>
                        <Link
                          className={
                            pathname.includes("reimbursement-admin")
                              ? "active"
                              : ""
                          }
                          to="/reimbursement-admin"
                        >
                          Manage Reimbursement
                        </Link>
                      </li>

                      {/* <li>
                      <Link
                        className={
                          pathname.includes("add-reimbursements")
                            ? "active"
                            : ""
                        }
                        to="/add-reimbursements"
                      >
                        Manage Reimbursement Limit
                      </Link>
                    </li> */}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              )}
              <li className="menu-title">
                <span>COMPANY</span>
              </li>
              {rolePermissions["Report view and down load"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("reports") ||
                    pathname.includes("newjoiner-report") ||
                    pathname.includes("birthday-report") ||
                    pathname.includes("admin-task")
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/reports">
                    <img src={report} />
                    <span>Reports</span>
                  </Link>
                </li>
              )}
              {rolePermissions["Survey"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("face-recog-admin") ? "active" : ""
                  }
                >
                  <Link to="/face-recog-admin">
                    <img src={facerecog} />
                    <span>Face Recognition</span>
                  </Link>
                </li>
              )}
              {rolePermissions["Survey"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={pathname.includes("DailyFeed") ? "active" : ""}
                >
                  <Link to="/DailyFeed">
                    <img src={feed} />
                    <span>Daily Feed</span>
                  </Link>
                </li>
              )}
              {rolePermissions["Guidelines"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={pathname.includes("holidays") ? "active" : ""}
                >
                  <Link to="/holidays">
                    <img src={holiday} />
                    <span>Holidays</span>
                  </Link>
                </li>
              )}
              <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("admin-pantry") ? "active" : ""}
              >
                <Link to="/admin-pantry">
                  <img src={pantry} />
                  <span>Pantry </span>
                </Link>
              </li>
              {rolePermissions["Survey"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={pathname.includes("survey-admin") ? "active" : ""}
                >
                  <Link to="/survey-admin">
                    <img style={{ width: "22px" }} src={survey} />
                    <span>Survey</span>
                  </Link>
                </li>
              )}

              <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("issue-letter") ? "active" : ""}
              >
                <Link to="/issue-letter">
                  <img style={{ width: "22px" }} src={issue} />
                  <span>Issue Letter</span>
                </Link>
              </li>
              <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("duty-hours") ? "active" : ""}
              >
                <Link to="/duty-hours">
                  <img style={{ width: "22px" }} src={duty} />
                  <span>Duty Hours</span>
                </Link>
              </li>
              <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("duty-hours") ? "active" : ""}
              >
                <Link to="/contractor">
                  <img style={{ width: "22px" }} src={duty} />
                  <span>Contractors</span>
                </Link>
              </li>

              {/* 
              <li
                style={{ marginBottom: "5px" }}
                className={pathname.includes("AllEmployee") ? "active" : ""}
              >
                <Link to="/AllEmployee-admin">
                  <img src={dict} />
                  <span>Directory</span>
                </Link>
              </li> */}
              {rolePermissions["Guidelines"] !== "NoAccess" && (
                <li
                  style={{ marginBottom: "5px" }}
                  className={
                    pathname.includes("admin-guidelines") ? "active" : ""
                  }
                >
                  <Link to="/admin-guidelines">
                    <img style={{ width: "21px" }} src={guide} />
                    <span>Guidelines</span>
                  </Link>
                </li>
              )}
              {sessionStorage.getItem("token") !== "" && (
                <>
                  <li className="menu-title">
                    <span>RECRUITMENT ENGINE</span>
                  </li>
                  <li
                    style={{ marginBottom: "5px" }}
                    className={pathname.includes("samarth") ? "active" : ""}
                  >
                    <Link to="/samarth">
                      <img style={{ width: "21px" }} src={recruit} />
                      <span>Recruitment</span>
                    </Link>
                  </li>
                </>
              )}
              {/* {rolePermissions["Guidelines"] !== "NoAccess" && ( */}

              <br />
              {/* )} */}
              {/* <li
                className={pathname.includes("clients") ? "active" : ""}
                style={{ marginBottom: "15px" }}
              >
                <Link to="/app/employees/clients">
                  <i className="la la-gear" />
                  {/* <i className="fa-regular fa-gear"></i> */}
              {/* <span>Settings</span>
                </Link>
              </li>  */}
              {/* <li className="submenu"> */}
              {/* <a
                  href="#"
                  className={isSideMenu == "projects" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "projects" ? "" : "projects")
                  }
                >
                  <i className="la la-rocket" /> <span> Projects</span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "projects" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("t_dashboard")
                            ? "active"
                            : pathname.includes("projects-list")
                            ? "active"
                            : pathname.includes("cts-view")
                            ? "active"
                            : ""
                        }
                        to="/app/projects/project_dashboard"
                      >
                        Projects
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() =>
                          localStorage.setItem("minheight", "true")
                        }
                        to="/tasks/tasks"
                      >
                        Tasks
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("task-board") ? "active" : ""
                        }
                        to="/app/projects/task-board"
                      >
                        Task Board
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className={pathname.includes("leads") ? "active" : ""}>
                <Link to="/app/employees/leads">
                  <i className="la la-user-secret" /> <span>Leads</span>
                </Link>
              </li>
              <li
                className={
                  pathname.includes("tickets")
                    ? "active"
                    : pathname.includes("ticket-view")
                    ? "active"
                    : ""
                }
              >
                <Link to="/app/employees/tickets">
                  <i className="la la-ticket" /> <span>Tickets</span>
                </Link>
              </li> */}
              {/* <li className="menu-title">
                <span>HR</span>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "sales" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "sales" ? "" : "sales")
                  }
                >
                  <i className="la la-files-o" /> <span> Sales </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "sales" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("estimates") ? "active" : ""
                        }
                        to="/app/sales/estimates"
                      >
                        Estimates
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("invoices") ? "active" : ""
                        }
                        to="/app/sales/invoices"
                      >
                        Invoices
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payments") ? "active" : ""
                        }
                        to="/app/sales/payments"
                      >
                        Payments
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("expenses") ? "active" : ""
                        }
                        to="/app/sales/expenses"
                      >
                        Expenses
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("provident-fund") ? "active" : ""
                        }
                        to="/app/sales/provident-fund"
                      >
                        Provident Fund
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("taxes") ? "active" : ""}
                        to="/app/sales/taxes"
                      >
                        Taxes
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "accounting" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "accounting" ? "" : "accounting"
                    )
                  }
                >
                  <i className="la la-files-o" /> <span> Accounting </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "accounting" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("categories") ||
                          pathname.includes("sub-category")
                            ? "active"
                            : ""
                        }
                        to="/app/accounts/categories"
                      >
                        Categories
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("budgets") ? "active" : ""}
                        to="/app/accounts/budgets"
                      >
                        Budgets
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("budget-expenses") ? "active" : ""
                        }
                        to="/app/accounts/budget-expenses"
                      >
                        Budget Expenses
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("budget-revenues") ? "active" : ""
                        }
                        to="/app/accounts/budget-revenues"
                      >
                        Budget Revenues
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "payroll" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "payroll" ? "" : "payroll")
                  }
                >
                  <i className="la la-money" /> <span> Payroll </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "payroll" ? (
                  <ul>
                    <li>
                      <Link
                        className={pathname.includes("_salary") ? "active" : ""}
                        to="/app/payroll/_salary"
                      >
                        {" "}
                        Employee Salary{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("y-view") ? "active" : ""}
                        to="/app/payroll/salary-view"
                      >
                        {" "}
                        Payslip{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payroll-items") ? "active" : ""
                        }
                        to="/app/payroll/payroll-items"
                      >
                        {" "}
                        Payroll Items{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className={pathname.includes("policies") ? "active" : ""}>
                <Link to="/app/hr/policies">
                  <i className="la la-file-pdf-o" /> <span>Policies</span>
                </Link>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "reports" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "reports" ? "" : "reports")
                  }
                >
                  <i className="la la-pie-chart" /> <span> Reports </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "reports" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("expense-") ? "active" : ""
                        }
                        to="/app/reports/expense-reports"
                      >
                        {" "}
                        Expense Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("invoice-") ? "active" : ""
                        }
                        to="/app/reports/invoice-reports"
                      >
                        {" "}
                        Invoice Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payments-") ? "active" : ""
                        }
                        to="/app/reports/payments-reports"
                      >
                        {" "}
                        Payments Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("project-") ? "active" : ""
                        }
                        to="/app/reports/project-reports"
                      >
                        {" "}
                        Project Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("task-") ? "active" : ""}
                        to="/app/reports/task-reports"
                      >
                        {" "}
                        Task Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("user-") ? "active" : ""}
                        to="/app/reports/user-reports"
                      >
                        {" "}
                        User Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("employee-") ? "active" : ""
                        }
                        to="/app/reports/employee-reports"
                      >
                        {" "}
                        Employee Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("payslip-") ? "active" : ""
                        }
                        to="/app/reports/payslip-reports"
                      >
                        {" "}
                        Payslip Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("attendance-") ? "active" : ""
                        }
                        to="/app/reports/attendance-reports"
                      >
                        {" "}
                        Attendance Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("leave-") ? "active" : ""}
                        to="/app/reports/leave-reports"
                      >
                        {" "}
                        Leave Report{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("daily-") ? "active" : ""}
                        to="/app/reports/daily-reports"
                      >
                        {" "}
                        Daily Report{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="menu-title">
                <span>Performance</span>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "performance" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "performance" ? "" : "performance"
                    )
                  }
                >
                  <i className="la la-graduation-cap" />{" "}
                  <span> Performance </span> <span className="menu-arrow" />
                </a>
                {isSideMenu == "performance" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("-indicator") ? "active" : ""
                        }
                        to="/app/performances/performance-indicator"
                      >
                        {" "}
                        Performance Indicator{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("-review") ? "active" : ""}
                        to="/app/performances/performance-review"
                      >
                        {" "}
                        Performance Review{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("-appraisal") ? "active" : ""
                        }
                        to="/app/performances/performance-appraisal"
                      >
                        {" "}
                        Performance Appraisal{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "goals" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "goals" ? "" : "goals")
                  }
                >
                  <i className="la la-crosshairs" /> <span> Goals </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "goals" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("-tracking") ? "active" : ""
                        }
                        to="/app/goals/goal-tracking"
                      >
                        {" "}
                        Goal List{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("l-type") ? "active" : ""}
                        to="/app/goals/goal-type"
                      >
                        {" "}
                        Goal Type{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "training" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "training" ? "" : "training")
                  }
                >
                  <i className="la la-edit" /> <span> Training </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "training" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("training-list") ? "active" : ""
                        }
                        to="/app/training/training-list"
                      >
                        {" "}
                        Training List{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("trainer") ? "active" : ""}
                        to="/app/training/trainer"
                      >
                        {" "}
                        Trainers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("training-type") ? "active" : ""
                        }
                        to="/app/training/training-type"
                      >
                        {" "}
                        Training Type{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className={pathname.includes("promotion") ? "active" : ""}>
                <Link to="/app/performance/promotion">
                  <i className="la la-bullhorn" /> <span>Promotion</span>
                </Link>
              </li>
              <li className={pathname.includes("resignation") ? "active" : ""}>
                <Link to="/app/performance/resignation">
                  <i className="la la-external-link-square" />{" "}
                  <span>Resignation</span>
                </Link>
              </li>
              <li className={pathname.includes("termination") ? "active" : ""}>
                <Link to="/app/performance/termination">
                  <i className="la la-times-circle" /> <span>Termination</span>
                </Link>
              </li>
              <li className="menu-title">
                <span>Administration</span>
              </li>
              <li className={pathname.includes("assets") ? "active" : ""}>
                <Link to="/app/administrator/assets">
                  <i className="la la-object-ungroup" /> <span>Assets</span>
                </Link>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "jobs" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "jobs" ? "" : "jobs")
                  }
                >
                  <i className="la la-briefcase" /> <span> Jobs </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "jobs" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("user-dashboard") ||
                          pathname.includes("user-all-jobs") ||
                          pathname.includes("saved-jobs") ||
                          pathname.includes("applied-jobs") ||
                          pathname.includes("interviewing") ||
                          pathname.includes("offered-jobs") ||
                          pathname.includes("visited-jobs") ||
                          pathname.includes("archived-jobs") ||
                          pathname.includes("job-aptitude") ||
                          pathname.includes("questions")
                            ? "active"
                            : ""
                        }
                        to="/app/administrator/user-dashboard"
                      >
                        {" "}
                        User Dasboard{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("jobs-dashboard") ? "active" : ""
                        }
                        to="/app/administrator/jobs-dashboard"
                      >
                        {" "}
                        Jobs Dasboard{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname === "/app/administrator/jobs" ? "active" : ""
                        }
                        to="/app/administrator/jobs"
                      >
                        {" "}
                        Manage Jobs{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("manage-resumes") ? "active" : ""
                        }
                        to="/app/administrator/manage-resumes"
                      >
                        {" "}
                        Manage Resumes{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("shortlist-candidates")
                            ? "active"
                            : ""
                        }
                        to="/app/administrator/shortlist-candidates"
                      >
                        {" "}
                        Shortlist Candidates{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname === "/app/administrator/interview-questions"
                            ? "active"
                            : ""
                        }
                        to="/app/administrator/interview-questions"
                      >
                        {" "}
                        Interview Questions{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("offer_approvals") ? "active" : ""
                        }
                        to="/app/administrator/offer_approvals"
                      >
                        {" "}
                        Offer Approvals{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("experiance-level") ? "active" : ""
                        }
                        to="/app/administrator/experiance-level"
                      >
                        {" "}
                        Experience Level{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname === "/app/administrator/candidates"
                            ? "active"
                            : ""
                        }
                        to="/app/administrator/candidates"
                      >
                        {" "}
                        Candidates List{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("schedule-timing") ? "active" : ""
                        }
                        to="/app/administrator/schedule-timing"
                      >
                        {" "}
                        Schedule timing{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("apptitude-result") ? "active" : ""
                        }
                        to="/app/administrator/apptitude-result"
                      >
                        {" "}
                        Aptitude Results{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li
                className={pathname.includes("knowledgebase") ? "active" : ""}
              >
                <Link to="/app/administrator/knowledgebase">
                  <i className="la la-question" /> <span>Knowledgebase</span>
                </Link>
              </li>
              <li className={pathname.includes("activities") ? "active" : ""}>
                <Link to="/app/administrator/activities">
                  <i className="la la-bell" /> <span>Activities</span>
                </Link>
              </li>
              <li
                className={
                  pathname.includes("administrator/users") ? "active" : ""
                }
              >
                <Link to="/app/administrator/users">
                  <i className="la la-user-plus" /> <span>Users</span>
                </Link>
              </li>
              <li>
                <Link to="/settings/companysetting">
                  <i className="la la-cog" /> <span>Settings</span>
                </Link>
              </li>
              <li className="menu-title">
                <span>Pages</span>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "profile" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "profile" ? "" : "profile")
                  }
                >
                  <i className="la la-user" /> <span> Profile </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "profile" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("profile/employee-") ? "active" : ""
                        }
                        to="/app/profile/employee-profile"
                      >
                        {" "}
                        Employee Profile{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={pathname.includes("client-") ? "active" : ""}
                        to="/app/profile/client-profile"
                      >
                        {" "}
                        Client Profile{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "authentication" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "authentication" ? "" : "authentication"
                    )
                  }
                >
                  <i className="la la-key" /> <span> Authentication </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "authentication" ? (
                  <ul>
                    <li>
                      <Link to="/login"> Login </Link>
                    </li>
                    <li>
                      <Link to="/register"> Register </Link>
                    </li>
                    <li>
                      <Link to="/forgotpassword"> Forgot Password </Link>
                    </li>
                    <li>
                      <Link to="/otp"> OTP </Link>
                    </li>
                    <li>
                      <Link to="/lockscreen"> Lock Screen </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "error pages" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "error pages" ? "" : "error pages"
                    )
                  }
                >
                  <i className="la la-exclamation-triangle" />{" "}
                  <span> Error Pages </span> <span className="menu-arrow" />
                </a>
                {isSideMenu == "error pages" ? (
                  <ul>
                    <li>
                      <Link to="/error-404">404 Error </Link>
                    </li>
                    <li>
                      <Link to="/error-500">500 Error </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "subscriptions" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "subscriptions" ? "" : "subscriptions"
                    )
                  }
                >
                  <i className="la la-hand-o-up" /> <span> Subscriptions </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "subscriptions" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("subscriptionadmin") ? "active" : ""
                        }
                        to="/app/subscription/subscriptionadmin"
                      >
                        Subscriptions (Admin){" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("subscriptioncompany")
                            ? "active"
                            : ""
                        }
                        to="/app/subscription/subscriptioncompany"
                      >
                        Subscriptions (Company){" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("subscribedcompanies")
                            ? "active"
                            : ""
                        }
                        to="/app/subscription/subscribedcompanies"
                      >
                        Subscribed Companies
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "pages" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "pages" ? "" : "pages")
                  }
                >
                  <i className="la la-columns" /> <span> Pages </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "pages" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("pages/search") ? "active" : ""
                        }
                        to="/app/pages/search"
                      >
                        {" "}
                        Search{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("pages/faq") ? "active" : ""
                        }
                        to="/app/pages/faq"
                      >
                        {" "}
                        FAQ{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("pages/terms") ? "active" : ""
                        }
                        to="/app/pages/terms"
                      >
                        {" "}
                        Terms{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("privacypolicy") ? "active" : ""
                        }
                        to="/app/pages/privacypolicy"
                      >
                        {" "}
                        Privacy Policy{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("pages/blank") ? "active" : ""
                        }
                        to="/app/pages/blank"
                      >
                        {" "}
                        Blank Page{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="menu-title">
                <span>UI Interface</span>
              </li>
              <li>
                <Link to="/ui-components">
                  <i className="la la-puzzle-piece" /> <span>Components</span>
                </Link>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "forms" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "forms" ? "" : "forms")
                  }
                >
                  <i className="la la-object-group" /> <span> Forms </span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "forms" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("basicinputs") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/basicinputs"
                      >
                        Basic Inputs{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("inputgroups") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/inputgroups"
                      >
                        Input Groups{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("horizontalform") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/horizontalform"
                      >
                        Horizontal Form{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("verticalform") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/verticalform"
                      >
                        {" "}
                        Vertical Form{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("formmask") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/formmask"
                      >
                        {" "}
                        Form Mask{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("formvalidation") ? "active" : ""
                        }
                        to="/app/ui-interface/forms/formvalidation"
                      >
                        {" "}
                        Form Validation{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="submenu">
                <Link
                  to="/app/ui-interface/tables/basic"
                  className={isSideMenu == "tables" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(isSideMenu == "tables" ? "" : "tables")
                  }
                >
                  <i className="la la-table" /> <span> Tables </span>{" "}
                  <span className="menu-arrow" />
                </Link>
                {isSideMenu == "tables" ? (
                  <ul>
                    <li>
                      <Link
                        className={
                          pathname.includes("tables/basic") ? "active" : ""
                        }
                        to="/app/ui-interface/tables/basic"
                      >
                        Basic Tables{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          pathname.includes("tables/data-table") ? "active" : ""
                        }
                        to="/app/ui-interface/tables/data-table"
                      >
                        Data Table{" "}
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>
              <li className="menu-title">
                <span>Extras</span>
              </li>
              <li>
                <a href="#">
                  <i className="la la-file-text" /> <span>Documentation</span>
                </a>
              </li>
              <li>
                <a href="">
                  <i className="la la-info" /> <span>Change Log</span>{" "}
                  <span className="badge badge-primary ml-auto">v3.4</span>
                </a>
              </li>
              <li className="submenu">
                <a
                  href="#"
                  className={isSideMenu == "multi Level" ? "subdrop" : ""}
                  onClick={() =>
                    toggleSidebar(
                      isSideMenu == "multi Level" ? "" : "multi Level"
                    )
                  }
                >
                  <i className="la la-share-alt" /> <span>Multi Level</span>{" "}
                  <span className="menu-arrow" />
                </a>
                {isSideMenu == "multi Level" ? (
                  <ul>
                    <li className="submenu">
                      <a
                        href="#"
                        className={level2Menu == "level 1" ? "subdrop" : ""}
                        onClick={() =>
                          toggleLvelTwo(
                            level2Menu == "level 1" ? "" : "level 1"
                          )
                        }
                      >
                        {" "}
                        <span>Level 1</span> <span className="menu-arrow" />
                      </a>
                      {level2Menu == "level 1" ? (
                        <ul>
                          <li>
                            <a href="#">
                              <span>Level 2</span>
                            </a>
                          </li>
                          <li className="submenu">
                            <a
                              href="#"
                              className={
                                level3Menu == "level 2" ? "subdrop" : ""
                              }
                              onClick={() =>
                                toggleLevelThree(
                                  level3Menu == "level 2" ? "" : "level 2"
                                )
                              }
                            >
                              {" "}
                              <span> Level 2</span>{" "}
                              <span className="menu-arrow" />
                            </a>
                            {level3Menu == "level 2" ? (
                              <ul>
                                <li>
                                  <a href="">Level 3</a>
                                </li>
                                <li>
                                  <a href="">Level 3</a>
                                </li>
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                          <li>
                            <a href="">
                              {" "}
                              <span>Level 2</span>
                            </a>
                          </li>
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                    <li>
                      <a href="">
                        {" "}
                        <span>Level 1</span>
                      </a>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </li>*/}
            </ul>
          </div>
        </div>
      </Scrollbars>

      <Modal
        open={openmodal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button>
          <br />

          <TableContainer>
            <Table
              sx={{ minWidth: 200 }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ background: "#FFFFFF" }}
                    >
                      <Typography variant="body1">{row.name}</Typography>
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{ background: "#FFFFFF", textAlign: "left" }}
                    >
                      <Typography variant="body1">{row.date}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
};

export default Sidebar;
