import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../../pages/Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import download from "../../../assets/img/profiles/downloadicon.svg";
import { Table } from "antd";
import AdminAttendance from "../AdminAttendance/AdminAttendance";

const Reports = () => {
  const [menu, setMenu] = useState(false);
  const [rights, setRights] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/register_admin/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setRights(res.data);
        // setLeaver(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          {/* Page Content */}
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">Reports</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <section className="dash-section">
                  <div className="dash-sec-content">
                    {rolePermissions["Overview Payroll"] !== "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/admin-payroll">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Payroll Register</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Access details of previous and forthcoming payroll
                              cycles.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    {rolePermissions["Employee List"] !== "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/manageEmployee">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>
                                  <img />
                                  Human Resources Report
                                </p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Access information on past and current employees
                              and contractors.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    {rolePermissions["Employee Documents"] !== "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/admin-documents">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Document Repository</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Access all relevant documents for the current
                              employee.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    {rights?.manage_attendance && (
                      <div className="dash-info-list">
                        <Link to="/admin-attendance">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Attendance Overview</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Monitor attendance records for current employees.{" "}
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    <div className="dash-info-list">
                      <Link to="/overtime-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Overtime Report </p>
                            </div>
                          </div>
                          <p className="reports-para">
                            view your current employees' overtime report
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/contract-attendance">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Contract based Attendance</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            view your current employees' contract based
                            attendance
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/manpower-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Average Manpower head count</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            view your average manpower head count
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/arrear-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Salary Arrears Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Generate detailed information on salary arrears for
                            the specified period.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/bonus-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Bonus Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Generate information on employee bonuses for the
                            specified period
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/gratuity-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Annual Gratuity Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Generate pertinent information regarding employee
                            gratuity for the year.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/incentive-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Incentives Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Generate pertinent information regarding employee
                            Incentives for the year.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/inclusive-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p> Inclusive Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Generate pertinent information regarding employee
                            Inclusive for the year.
                          </p>
                        </a>
                      </Link>
                    </div>
                    {rolePermissions["Employee Leave"] !== "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/leave-admin-report">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Leave Management</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Access the current employees' leave report.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    {/* <div className="dash-info-list">
                      <a href="#" className="dash-card">
                        <div className="dash-card-container">
                          <div className="dash-card-content">
                            <p>Tax Deductions</p>
                          </div>
                        </div>
                        <p className="reports-para">
                          view all the tax deductions that your employees have
                          declared
                        </p>
                      </a>
                    </div> */}
                    {rolePermissions["Employee Reimbursement"] !==
                      "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/reimbursement-admin">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Reimbursement Status</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Review pending or approved reimbursements for the
                              current payroll period.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    {rolePermissions["Task Report"] !== "NoAccess" && (
                      <div className="dash-info-list">
                        <Link to="/admin-task">
                          <a href="#" className="dash-card">
                            <div className="dash-card-container">
                              <div className="dash-card-content">
                                <p>Task Report</p>
                              </div>
                            </div>
                            <p className="reports-para">
                              Access information on pending and completed tasks.
                            </p>
                          </a>
                        </Link>
                      </div>
                    )}
                    <div className="dash-info-list">
                      <Link to="/newjoiner-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>New Joiners Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Obtain information about newly joined employees.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/bank-details">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>Employee Bank Details</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Access details of previous and forthcoming payroll
                            cycles.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/birthday-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>Birthday Celebration Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Access details about employees celebrating birthdays
                            this month.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/mood-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>Employee Happiness Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Access the overall happiness and satisfaction levels
                            of all employees.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/login-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>Daily Login Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Access information about employees who logged in
                            today.
                          </p>
                        </a>
                      </Link>
                    </div>
                    <div className="dash-info-list">
                      <Link to="/annual-work-report">
                        <a href="#" className="dash-card">
                          <div className="dash-card-container">
                            <div className="dash-card-content">
                              <p>Annual Working Report</p>
                            </div>
                          </div>
                          <p className="reports-para">
                            Access information about  employees annual Working.
                          </p>
                        </a>
                      </Link>
                    </div>
                    {/* <div className="dash-info-list">
                      <a href="#" className="dash-card">
                        <div className="dash-card-container">
                          <div className="dash-card-content">
                            <p>TDS</p>
                          </div>
                        </div>
                        <p className="reports-para">view TDS challans</p>
                      </a>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
