import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Button, Col, Container, Stack } from "react-bootstrap";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import { Table } from "antd";
import axios from "axios";
import Header from "../Sidebar/header";
import Sidebar from "../Sidebar/settingsidebar";

const Department = () => {
  const [menu, setMenu] = useState(false);
  const [allDept, setAllDept] = useState([]);
  const [getdept, setGetDept] = useState([]);

  const [department, setDepartment] = useState([
    {
      name: "",
      description: "",
    },
  ]);
  const token = sessionStorage.getItem("access_token");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const handleGetDept = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_department/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setGetDept(res.data);
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetDept();
  }, []);

  const handleDepartment = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/tran_department/`,
        {
          coregid: sessionStorage.getItem("coid"),
          create_toggle: 1,
          name: department[0].name,
          description: department[0].description,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        handleGetDept();
        // setCurrentTab(currentTab + 1);
        setDepartment([
          {
            name: "",
            description: "",
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteDept = (value) => {
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/tran_department/`,
        // {
        //   off_id: 78,
        // },
        // {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // }
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: value.id,
          },
        }
      )
      .then((res) => {
        alert("Delete Succesfully");
        handleGetDept();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index][event.target.name] = event.target.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const columns = [
    {
      title: "Name of Department",
      dataIndex: "dept",
      render: (text) => <>{text?.name}</>,
    },
    {
      title: "Description",
      dataIndex: "dept",
      render: (text) => <>{text?.desc}</>,
    },
    {
      title: "Action",
      // dataIndex: "city",
      render: (text) => (
        <div>
          {/* <button
            className="noborder"
            onClick={() => setEditOffice(text)}
            data-bs-toggle="modal"
            data-bs-target="#edit_office_board"
            href="#"
          >
            Edit
          </button> */}
          <button
            className="noborder"
            style={{
              background: "#3298DB",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "2px 12px",
              // fontSize: "13px",
            }}
            onClick={() => handleDeleteDept(text)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin Template</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Department</h3>
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Leaves</li>
              </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#168e9e",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_dept_board"
                    href="#"
                  >
                    Add New
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}

          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={getdept}
                      pagination={{
                        total: getdept.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="add_dept_board"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered  modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add New Department </h4>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                {department.map((input, index) => (
                  <form action="#">
                    <div className="row">
                      <div className="form-group col-md-12">
                        <label> Name of Department</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          value={input.name}
                          onChange={(event) =>
                            handleFormChange(
                              index,
                              event,
                              department,
                              setDepartment
                            )
                          }
                        />
                      </div>
                      <div className="form-group col-md-12">
                        <label> Department Description</label>
                        <input
                          type="text"
                          name="description"
                          className="form-control"
                          value={input.description}
                          onChange={(event) =>
                            handleFormChange(
                              index,
                              event,
                              department,
                              setDepartment
                            )
                          }
                        />
                      </div>
                    </div>
                  </form>
                ))}
                <div className="m-t-20 text-center">
                  <button
                    data-bs-dismiss="modal"
                    className="submit-btn"
                    onClick={(e) => handleDepartment()}
                    style={{ fontSize: "16px" }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Department;
