import React from 'react';
import Button from '../UI/Button/Button';
import './Menu.css';

const Menu = (props) => {
  const {toggle, setToggle} = props;
  return (
    <>
      <div className="Menu__ring" />
      
        <Button onClick={()=>setToggle(true)}>Start Game</Button>
    </>
  );
};

export default Menu;
