import { Box, Button, Typography } from "@mui/material"
import styled from '@emotion/styled'

export const SBox1 = styled(Box)({
    color: '#000000',
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    backdropFilter: 'blur(16px) saturate(180%)',
    WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    borderRadius: '4px',
    padding: '16px',
    display: 'flex',
})

export const SBox2 = styled(Box)({
    color: '#000000',
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    backdropFilter: 'blur(16px) saturate(180%)',
    WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    height: '100%',
    width: '100%',
    borderRadius: '8px',
})

export const SBox3 = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '330px',
    marginBottom: '16px'
})

export const SBox4 = styled(Box)({
    display: 'flex',
    marginBottom: '8px'
})

export const SBox5 = styled(Box)({
    width: '50%'
})

export const SBox6 = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '16px',
    marginTop: '16px'
})

export const SBox7 = styled(Box)({
    width: '100%',
    height: '10px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
})

export const SButton = styled(Button)({
    background: '#2E86C1',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    marginBottom: '16px',
    ":hover": {
        background: '#2E86C1',
        boxShadow: 'none'
    }
})

export const STypography = styled(Typography)({
    fontFamily: 'Roboto'
})
