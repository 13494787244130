/**
 * Blank Page
 */
import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Avatar,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  useMediaQuery,
  Toolbar,
} from "@mui/material";
import {
  SBox1,
  SBox2,
  SBox3,
  SButton,
  SButton2,
  STypography,
} from "../../../components/Dashboard/DashboardCSS";
import empty from "../../../assets/img/profiles/browser.png";
import absent from "../../../assets/img/profiles/absent.png";
import half1 from "../../../assets/img/profiles/half.png";
import late from "../../../assets/img/profiles/late.png";
import tw from "../../../assets/img/profiles/total.png";
import wd from "../../../assets/img/profiles/worked.png";
import aw from "../../../assets/img/profiles/average.png";
import on_leaves from "../../../assets/img/profiles/leave.png";
import early from "../../../assets/img/profiles/early.png";
import nob from "../../../assets/img/profiles/no-birthday.svg";
import noa from "../../../assets/img/profiles/no-work-anniversary.svg";
import noe from "../../../assets/img/profiles/no-new-employee.svg";
import back from "../../../assets/img/profiles/animation_500_lfw79k6c.gif";

const AdminOverview = () => {
  const [menu, setMenu] = useState(false);
  const [birthdays, setBirthdays] = useState([]);
  const [work, setWork] = useState([]);
  const [newmember, setNewmember] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1 > 9
      ? new Date().getMonth() + 1
      : `0${new Date().getMonth() + 1}`
    }`
  );
  // console.log(currentMonth);
  const token = sessionStorage.getItem("access_token");
  const [workingDays, setWorkingDays] = useState([]);

  const [workinghours, setWorkingHours] = useState([]);
  const [salaryData, setSalaryData] = useState([
    {
      year: "2020",
      total_salary: 25000,
    },

    {
      year: "2021",
      total_salary: 30000,
    },
  ]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [data, setData] = useState([]);

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;

    if (value === 0) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="red"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    if (value === 1) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="blue"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
    if (value === 2) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="yellow"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
    if (value === 3) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="pink"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    if (value === 4) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="gray"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="green"
        viewBox="0 0 1024 1024"
      >
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
      </svg>
    );
  };

  const barColors = [
    "#5932EA",
    "#FFD3A3",
    "#E0AFFF",
    "#B84200",
    "#9DCF00",
    "#FF7676",
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/graph/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data.graph1);
        setWorkingHours(
          res.data.graph2.map((ele) => ({
            name: ele.name,
            working_hours: Math.round((ele.score + Number.EPSILON) * 100) / 100,
          }))
        );
      });
  }, []);

  console.log(data)

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/graph2/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          year: currentMonth.split("-")[0],
          month: currentMonth.split("-")[1],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.graph3);
        setWorkingDays(
          res.data.map((ele) => ({ name: ele.name, No_of_Days: ele.score }))
        );
      });
  }, [currentMonth]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Overview</h3>
              </div>
            </div>
          </div>

          <div className="row col-md-12">
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info  "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">projects </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">7</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.absent?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.absent?.improved
                        ? `+${kpi.absent?.percent}`
                        : `-${kpi.absent?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={absent} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9">
                  <h4 className="kpi-head">In Progress Task Today</h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">12</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.leave?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.leave?.improved
                        ? `+${kpi.leave?.percent}`
                        : `-${kpi.leave?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={on_leaves} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Active Employees </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">1200</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.half?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.half?.improved
                        ? `+${kpi.half?.percent}`
                        : `-${kpi.half?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={half1} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">InActive Employees </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">42</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.late?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.late?.improved
                        ? `+${kpi.late?.percent}`
                        : `-${kpi.late?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={late} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Employee Present Today </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">1123</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.early?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.early?.improved
                        ? `+${kpi.early?.percent}`
                        : `-${kpi.early?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={early} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Employee Absent Today </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">12</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.total_wh?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.total_wh?.improved
                        ? `+${kpi.total_wh?.percent}`
                        : `-${kpi.total_wh?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={tw} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Emp Joined Last 12 Month </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">120</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.worked?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.worked?.improved
                        ? `+${kpi.worked?.percent}`
                        : `-${kpi.worked?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={wd} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
            // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Employee Separated </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">
                      {/* {kpi.average_wh ? Math.round(kpi.average_wh.total) : 0} */}
                      50
                    </h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.average_wh?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.average_wh?.improved
                        ? `+${kpi.average_wh?.percent}`
                        : `-${kpi.average_wh?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={aw} />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
          </div>

          <div className=" row col-sm-12">
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Happiness Index
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#8884d8"
                      dot={<CustomizedDot />}
                    />
                  </LineChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Gender Distribution
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div>
          </div>
          <br />
          <div className=" row col-sm-12">
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Social Index
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div>
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Working Location
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <div style={{ padding: "10px" }}>
                  <input
                    type="month"
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "10px",
                      float: "inline-end",
                      position: "absolute",
                    }}
                    value={currentMonth}
                    onChange={(e) => setCurrentMonth(e.target.value)}
                  />
                </div>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={workingDays}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="No_of_Days" fill="#00a0fc">
                      {workingDays?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
          </div>
          <br />

          <div className="row col-sm-12">
            <div className="col-md-12 col-lg-6 col-xl-6 col-sm-6">
              <div className="card flex-fill">
                <div className="card-body">
                  <STypography variant="h6" sx={{ fontSize: "18px", mb: 1 }}>
                    <b>Task Statistics</b>
                  </STypography>
                  <div className="statistics">
                    <div className="row">
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box mb-4" style={{ background: "#4483F7", borderRadius: "7px", color: "white" }}>
                          <p>Total Tasks</p>
                          <h3 style={{ color: "white" }}>385</h3>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 text-center" >
                        <div className="stats-box mb-4" style={{ background: "#FF5252", borderRadius: "7px", color: "white" }}>
                          <p>Overdue Tasks</p>
                          <h3 style={{ color: "white" }}>19</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="progress mb-4">
                    <div
                      className="progress-bar bg-purple"
                      role="progressbar"
                      style={{ width: "30%" }}
                      aria-valuenow={30}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      30%
                    </div>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "22%" }}
                      aria-valuenow={18}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      22%
                    </div>
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "24%" }}
                      aria-valuenow={12}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      24%
                    </div>
                    <div
                      className="progress-bar bg-danger"
                      role="progressbar"
                      style={{ width: "26%" }}
                      aria-valuenow={14}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      21%
                    </div>
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "10%" }}
                      aria-valuenow={14}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      10%
                    </div>
                  </div>
                  <div>
                    <p>
                      <i className="fa fa-dot-circle-o text-purple me-2" />
                      Completed Tasks <span className="float-end">166</span>
                    </p>
                    <p>
                      <i className="fa fa-dot-circle-o text-warning me-2" />
                      Inprogress Tasks <span className="float-end">115</span>
                    </p>
                    <p>
                      <i className="fa fa-dot-circle-o text-success me-2" />
                      On Hold Tasks <span className="float-end">31</span>
                    </p>
                    <p>
                      <i className="fa fa-dot-circle-o text-danger me-2" />
                      Pending Tasks <span className="float-end">47</span>
                    </p>
                    <p className="mb-0">
                      <i className="fa fa-dot-circle-o text-info me-2" />
                      Review Tasks <span className="float-end">5</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-6 col-sm-6">
              <SBox1
                sx={{
                  boxShadow: 3,
                  height: "370px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <STypography variant="h6" sx={{ fontSize: "18px", mb: 1 }}>
                  <b> Absent Employees</b>
                </STypography>
                <Box
                  textAlign="left"
                  sx={{ maxHeight: 230, overflow: "auto", width: "100%" }}
                >
                  {newmember?.length !== 0 ? (
                    <>
                      {newmember.map((ele) => {
                        return (
                          <Stack
                            direction="row "
                            style={{
                              justifyContent: "space-between",
                              paddingRight: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Avatar
                                src={ele.profile_img.links}
                                sx={{ width: 30, height: 30 }}
                              />
                              &nbsp;
                              <STypography
                                variant="subtitle1"
                                sx={{ ml: 0.5, mb: 0.2 }}
                              >
                                {ele.name.split(" ")[0]}{" "}
                                {ele.name.split(" ").length > 2
                                  ? ele.name.split(" ")[2]
                                  : null}
                                <br />
                                <STypography
                                  variant="caption"
                                  sx={{
                                    color: "#000000",
                                    background: "#FFFFFF",
                                    pl: 0.2,
                                    pr: 0.2,
                                    borderRadius: 1,
                                  }}
                                >
                                  {ele.role}
                                </STypography>
                              </STypography>
                            </div>
                            <SButton2
                              data-bs-target="#wish"
                              data-bs-toggle="modal"
                            >
                              <STypography
                                variant="caption"
                                sx={{ color: "#FFFFFF" }}
                              //   onClick={() =>
                              //     setWishe({
                              //       type: "work_annivesary",

                              //       name: ele.name,
                              //       emp_id_wish: ele.id,
                              //       emp_id_wisher:
                              //         sessionStorage.getItem("emp_id"),
                              //       message: "",
                              //       wish_name: "Welcome! new team member",
                              //     })
                              //   }
                              >
                                Wish Them
                              </STypography>
                            </SButton2>
                          </Stack>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={noe} style={{ height: "80px" }} />
                    </div>
                  )}
                </Box>
              </SBox1>

            </div>
          </div>
          <br />
          <div className="row col-sm-12">
            <div className=" col-sm-6">
              <SBox2 sx={{ boxShadow: 3 }}>
                <STypography variant="h6" sx={{ fontSize: "18px", mb: 1 }}>
                  <b>Upcoming Birthday(s)</b>
                </STypography>
                <Box
                  textAlign="left"
                  sx={{
                    padding: "10px",
                    maxHeight: 180,
                    overflow: "auto",
                    backgroundImage: { back },
                  }}
                  className="birt-div"
                >
                  {birthdays?.length !== 0 ? (
                    <>
                      {birthdays.map((ele) => {
                        return (
                          <Stack direction="row" sx={{ marginBottom: "5px" }}>
                            <Avatar
                              src={ele.profile_img.links}
                              sx={{ width: 30, height: 30 }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <SBox3>
                              <STypography variant="subtitle1" sx={{ ml: 0.5 }}>
                                {ele.name.split(" ")[0]}{" "}
                                {ele.name.split(" ").length > 2
                                  ? ele.name.split(" ")[2]
                                  : null}
                                &nbsp;
                                <br />
                                <STypography
                                  variant="caption"
                                  sx={{
                                    color: "#000000",
                                    background: "#c4c4c4",
                                    // pl: 0.2,
                                    // pr: 0.2,
                                    borderRadius: 1,
                                    padding: "2px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {ele.role}
                                </STypography>
                              </STypography>

                              <SButton2
                                data-bs-target="#wish"
                                data-bs-toggle="modal"
                              >
                                <STypography
                                  variant="caption"
                                  sx={{ color: "#FFFFFF" }}
                                // onClick={() =>
                                //   setWishe({
                                //     type: "bithday",

                                //     name: ele.name,
                                //     emp_id_wish: ele.id,
                                //     emp_id_wisher:
                                //       sessionStorage.getItem("emp_id"),
                                //     message: "",
                                //     wish_name: `Wish ${ele.name} Happy Birthday`,
                                //   })
                                // }
                                >
                                  Wish them
                                </STypography>
                              </SButton2>
                            </SBox3>
                          </Stack>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={nob} style={{ height: "80px" }} />
                    </div>
                  )}
                </Box>
              </SBox2>
            </div>
            <div className="col-sm-6">
              <SBox2 sx={{ boxShadow: 3 }}>
                <STypography variant="h6" sx={{ fontSize: "18px", mb: 1 }}>
                  <b>Upcoming Work Anniversaries</b>
                </STypography>
                <Box
                  sx={{
                    maxHeight: 180,
                    overflow: "auto",
                    textAlign: "left",
                    paddingTop: "10px",
                  }}
                >
                  {work?.length !== 0 ? (
                    <>
                      {work.map((ele) => {
                        return (
                          <Stack direction="row">
                            <Avatar
                              src={ele.profile_img.links}
                              sx={{ width: 30, height: 30 }}
                            />
                            <SBox3>
                              <STypography variant="subtitle1" sx={{ ml: 0.5 }}>
                                {ele.name.split(" ")[0]}{" "}
                                {ele.name.split(" ").length > 2
                                  ? ele.name.split(" ")[2]
                                  : null}
                                &nbsp;
                                <br />
                                <STypography
                                  variant="caption"
                                  sx={{
                                    color: "#000000",
                                    background: "#FFFFFF",
                                    pl: 0.2,
                                    pr: 0.2,
                                    borderRadius: 1,
                                  }}
                                >
                                  {ele.role}
                                </STypography>
                              </STypography>

                              <SButton2
                                data-bs-target="#wish"
                                data-bs-toggle="modal"
                              >
                                <STypography
                                  variant="caption"
                                  sx={{ color: "#FFFFFF" }}
                                // onClick={() =>
                                //   setWishe({
                                //     type: "work_annivesary",

                                //     name: ele.name,
                                //     emp_id_wish: ele.id,
                                //     emp_id_wisher:
                                //       sessionStorage.getItem("emp_id"),
                                //     message: "",
                                //     wish_name: `Wish ${ele.name} on Work Anniversary`,
                                //   })
                                // }
                                >
                                  Wish Them
                                </STypography>
                              </SButton2>
                            </SBox3>
                          </Stack>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={noa} style={{ height: "80px" }} />
                    </div>
                  )}
                </Box>
              </SBox2>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};
export default AdminOverview;
