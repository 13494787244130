import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Applogo from "../../assets/img/profiles/image23.png";
import { RotatingLines } from "react-loader-spinner";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import { useState } from "react";
import { useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { pay } from "./payConst";
import Select from "react-select";
import axios from "axios";
import Rupee from "../../assets/img/profiles/Rupee-Symbol-Black.svg";
import SapioLogo from "../../assets/img/profiles/sapio-logo.svg";
import { ArrowBack } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  Button,
  Toolbar,
  Grid,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { borderRadius } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { typeOf } from "react-summernote";
//import { yearlyPayslip } from "./payConst";

// const STableRow = styled(TableRow)({
//   color: "#000000",
//   background: "#FFFFFF",
//   fontSize:"13px"
// });
const STableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:nth-of-type(even)": {
    backgroundColor: "#e6e6fa",
  },
}));

const STableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  fontSize: "13px",
  color: "#808080",
});

const SNCTableCell = styled(TableCell)({
  borderColor: "#FFFFFF",
  fontSize: "13px",
  color: "#808080",
});

const SCTableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  // background: "#a2d8fb",
  // color:"#808080"
});
const LATableCell = styled(TableCell)({
  textAlign: "right",
});

const SButton = styled(Button)({
  background: "#2DB8C5",
  borderRadius: "4px",
  textTransform: "none",
  boxShadow: "none",
  padding: "8px",
  ":hover": {
    background: "#2DB8C5",
    boxShadow: "none",
  },
});

const Payslip = () => {
  const [menu, setMenu] = useState(false);
  const [showPayslip, setShowPayslip] = useState(false);
  const [showSlip, setShowSlip] = useState([]);
  const [totalEr, setTotalEr] = useState(0);
  const [totalDe, setTotalDe] = useState(0);
  const [totalRe, setTotalRe] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [yearlyPayslip, setYearlyPayslip] = useState({});

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [months, setMonths] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [month1, setMonth1] = useState(parseInt(new Date().getMonth() + 1));
  const [month, setmonth] = useState("");
  const [year, setYear] = useState(parseInt(months.split("-")[0]));
  const [year1, setYear1] = useState("");
  const [newYear, setNewYear] = useState(parseInt(new Date().getFullYear()));
  const [year2, setYear2] = useState(null);
  const [viewPayslip, setViewPayslip] = useState({});
  console.log(month1);
  console.log(year);

  const inputRef = useRef(null);
  const inputRefOne = useRef(null);

  //const netEarning = payslipdata[step]?.monthData.map((v) => v.earning)
  //const netDeduction = payslipdata[step]?.monthData.map((v) => v.deduction)

  console.log(showSlip);
  // console.log(months.split("-")[1]);

  // const options = yearlyPayslip.map((v, index) => {
  //   return {
  //     value: index,
  //     label: v.year,
  //   };
  // });

  /*
  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF();
      //   {
      //   orientation: "landscape",
      // }
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };
  */

  const printPage = useReactToPrint({
    content: () => inputRef.current,
    documentTitle: "downloadpayslip",
  });

  const printPageOne = useReactToPrint({
    content: () => inputRefOne.current,
    documentTitle: "downloadAnnualPayslip",
  });

  /* PaySlip Get Api */
  const handlePayslip = (month, year) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payslip/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          month: month,
          year: year,
          toggle: "s",
        },
      })
      .then((res) => {
        console.log(res.data, "Hello");
        setShowSlip(res.data);
        //setYearlyPayslip(res.data);
        const sum = res.data.earning.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalEr(sum);
        const sum1 = res.data.deduction.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalDe(sum1);
        const sum2 = res.data.reimbursment.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalRe(sum2);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYearlyPayslip = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payslip/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          year: year,
          toggle: "n",
        },
      })
      .then((res) => {
        setYearlyPayslip({
          year: res.data.year,
          monthData: res.data.monthData.map((ele) => ({
            ...ele,
            month_int: parseInt(new Date(`${ele.Month}` + 1).getMonth() + 1),
            year:
              parseInt(new Date(`${ele.Month}` + 1).getMonth() + 1) > 3
                ? parseInt(res.data.year)
                : parseInt(res.data.year) + 1,
          })),
        });
        setYear2(parseInt(res.data.year));
        setViewPayslip({});
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(yearlyPayslip);
  useEffect(() => {
    handleYearlyPayslip();
  }, [year]);

  const handlePays = (month, year) => {
    let y;
    let m;
    if (month === "April") {
      m = "4";
      y = year;
    } else if (month === "May") {
      m = "5";
      y = year;
    } else if (month === "June") {
      m = "6";
      y = year;
    } else if (month === "July") {
      m = "7";
      y = year;
    } else if (month === "August") {
      m = "8";
      y = year;
    } else if (month === "September") {
      m = "9";
      y = year;
    } else if (month === "October") {
      m = "10";
      y = year;
    } else if (month === "November") {
      m = "11";
      y = year;
    } else if (month === "December") {
      m = "12";
      y = year;
    } else if (month === "January") {
      m = "1";
      y = parseInt(year) + 1;
    } else if (month === "February") {
      m = "2";
      y = parseInt(year) + 1;
    } else if (month === "March") {
      m = "3";
      y = parseInt(year) + 1;
    }
    handlePayslip(m, y);
    setYear1(y);
  };

  let currentYear = new Date().getFullYear();
  let earliestYear = 2017;
  let option = [];

  while (currentYear >= earliestYear) {
    let op = { label: "", value: "" };
    op.label = currentYear + "-" + (currentYear + 1);
    op.value = currentYear;

    option.push(op);
    currentYear -= 1;
  }

  const th = ["", "thousand", "million", "billion", "trillion"];
  const dg = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const tn = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tw = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function getDaysInMonth(year) {
    return [
      { label: "January", value: 31 },
      {
        label: "February",
        value:
          year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28,
      },
      { label: "March", value: 31 },
      { label: "April", value: 30 },
      { label: "May", value: 31 },
      { label: "June", value: 30 },
      { label: "July", value: 31 },
      { label: "August", value: 31 },
      { label: "September", value: 30 },
      { label: "October", value: 31 },
      { label: "November", value: 30 },
      { label: "December", value: 31 },
    ];
  }

  const monthDays = getDaysInMonth(year1);

  function getInWords(s) {
    s = s?.toString();
    s = s?.replace(/[\, ]/g, "");
    if (s != parseFloat(s)) return " ";
    let x = s?.indexOf(".");
    if (x == -1) x = s?.length;
    if (x > 15) return "too big";
    let n = s?.split("");
    let str = "";
    let sk = 0;
    for (let i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == "1") {
          str += tn[Number(n[i + 1])] + " ";
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + " ";
          sk = 1;
        }
      } else if (n[i] != 0) {
        // 0235
        str += dg[n[i]] + " ";
        if ((x - i) % 3 == 0) str += "hundred ";
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk) str += th[(x - i - 1) / 3] + " ";
        sk = 0;
      }
    }

    if (x != s.length) {
      let y = s.length;
      str += "point ";
      for (let i = x + 1; i < y; i++) str += dg[n[i]] + " ";
    }
    return str?.replace(/\s+/g, " ");
  }

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <Grid container>
          {!isLoading && (
            <>
              {!showPayslip && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{ height: "100%", p: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                        }}
                      >
                        <div
                          className="content container-fluid"
                          style={{
                            marginTop: "15px",
                            padding: "8px",
                            paddingLeft: "22px",
                          }}
                        >
                          <div className="page-header">
                            <h3 className="page-title">Payslip</h3>
                          </div>
                        </div>
                        <Box sx={{ width: "120px", mt: 4 }}>
                          <SButton onClick={printPageOne}>
                            <Typography
                              variant="body2"
                              sx={{ color: "#FFFFFF" }}
                            >
                              Download
                            </Typography>
                          </SButton>
                        </Box>
                        <Box sx={{ width: "200px", mt: 4 }}>
                          <Select
                            //isClearable
                            defaultValue={option[0]}
                            placeholder="Financial Year"
                            options={option}
                            // value={ }
                            value={
                              year !== ""
                                ? option.filter((ele) => ele.value === year)
                                : null
                            }
                            onChange={(e) => setYear(e.value)}
                          />
                        </Box>
                      </Box>

                      <TableContainer
                        sx={{ borderRadius: "10px" }}
                        ref={inputRefOne}
                      >
                        {yearlyPayslip?.monthData?.length > 0 && (
                          <Table
                            size="small"
                            aria-label="a dense table"
                            sx={{ width: "100%", fontSize: 10 }}
                          >
                            <STableRow>
                              {/* <SCTableCell /> */}
                              <TableCell
                                align="left"
                                style={{ borderBottomColor: "#000000" }}
                              >
                                <Typography variant="body2">
                                  <b>Payslip</b>
                                </Typography>
                              </TableCell>

                              {yearlyPayslip?.monthData?.map((v) => {
                                return (
                                  <SCTableCell align="right">
                                    <Typography
                                      variant="body2"
                                      textAlign="center"
                                    >
                                      <b>
                                        {`${v.Month.split("")
                                          .join("")[0]
                                          .toLocaleUpperCase()}` +
                                          "" +
                                          `${v.Month.split("")
                                            .join("")
                                            .substring(1)}`}
                                      </b>
                                      <br />

                                      {v.year < newYear &&
                                      v.Month !== "total" ? (
                                        <IconButton
                                          onClick={() => (
                                            setShowPayslip(true),
                                            handlePays(
                                              v.Month,
                                              yearlyPayslip.year
                                            ),
                                            setmonth(v.Month)
                                          )}
                                        >
                                          <DownloadIcon fontSize="small" />
                                        </IconButton>
                                      ) : (
                                        <>
                                          {v.month_int < month1 &&
                                            v.year === newYear && (
                                              <IconButton
                                                onClick={() => (
                                                  setShowPayslip(true),
                                                  handlePays(
                                                    v.Month,
                                                    yearlyPayslip.year
                                                  ),
                                                  setmonth(v.Month)
                                                )}
                                              >
                                                <DownloadIcon fontSize="small" />
                                              </IconButton>
                                            )}
                                        </>
                                      )}
                                    </Typography>
                                  </SCTableCell>
                                );
                              })}
                            </STableRow>

                            <STableRow style={{ background: "#b4c5d5" }}>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>Attendance</b>
                                </Typography>
                              </TableCell>

                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </STableRow>

                            {yearlyPayslip?.monthData
                              ?.map((v) => v.attendance)[0]
                              ?.map((ele, index) => {
                                return (
                                  <STableRow>
                                    <TableCell style={{ fontWeight: "500" }}>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[0][index]?.component_name
                                      }
                                    </TableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[0][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[1][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[2][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[3][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[4][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[5][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[6][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[7][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[8][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[9][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[10][index]?.data
                                      }
                                      {console.log(index)}
                                    </LATableCell>

                                    <LATableCell>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[11][index]?.data
                                      }
                                    </LATableCell>

                                    <LATableCell style={{ fontWeight: "500" }}>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.attendance
                                        )[12][index]?.data
                                      }
                                    </LATableCell>
                                  </STableRow>
                                );
                              })}

                            <STableRow style={{ background: "#b4c5d5" }}>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>
                                    Earnings(
                                    <img
                                      style={{ width: "10px", height: "10px" }}
                                      src={Rupee}
                                    />
                                    )
                                  </b>
                                </Typography>
                              </TableCell>

                              {Array.from(Array(13).keys()).map((num) => {
                                return <TableCell />;
                              })}
                            </STableRow>

                            {yearlyPayslip?.monthData
                              ?.map((v) => v.earning)[0]
                              ?.map((ele, index) => {
                                return (
                                  <STableRow>
                                    <TableCell style={{ fontWeight: "500" }}>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.earning
                                        )[0][index]?.component_name
                                      }
                                    </TableCell>

                                    {Array.from(Array(13).keys()).map((num) => {
                                      return (
                                        <LATableCell>
                                          {
                                            yearlyPayslip?.monthData.map(
                                              (v) => v.earning
                                            )[num][index]?.total
                                          }
                                        </LATableCell>
                                      );
                                    })}
                                  </STableRow>
                                );
                              })}

                            <STableRow style={{ background: "#a2d8fb" }}>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>Gross Salary</b>
                                </Typography>
                              </TableCell>

                              {yearlyPayslip?.monthData?.map((v) => {
                                return (
                                  <LATableCell>
                                    <Typography variant="body2">
                                      {v.total_gross}
                                    </Typography>
                                  </LATableCell>
                                );
                              })}
                            </STableRow>

                            <STableRow style={{ background: "#b4c5d5" }}>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>
                                    Deductions (
                                    <img
                                      style={{ width: "10px", height: "10px" }}
                                      src={Rupee}
                                    />
                                    )
                                  </b>
                                </Typography>
                              </TableCell>

                              {Array.from(Array(13).keys()).map((num) => {
                                return <TableCell />;
                              })}
                            </STableRow>

                            {yearlyPayslip?.monthData
                              ?.map((v) => v.deduction)[0]
                              ?.map((ele, index) => {
                                return (
                                  <STableRow>
                                    <TableCell style={{ fontWeight: "500" }}>
                                      {
                                        yearlyPayslip?.monthData.map(
                                          (v) => v.deduction
                                        )[0][index]?.component_name
                                      }
                                    </TableCell>

                                    {Array.from(Array(13).keys()).map((num) => {
                                      return (
                                        <LATableCell>
                                          {
                                            yearlyPayslip?.monthData.map(
                                              (v) => v.deduction
                                            )[num][index]?.total
                                          }
                                        </LATableCell>
                                      );
                                    })}
                                  </STableRow>
                                );
                              })}

                            <STableRow style={{ background: "#a2d8fb" }}>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>
                                    Net Salary (
                                    <img
                                      style={{ width: "10px", height: "10px" }}
                                      src={Rupee}
                                    />
                                    )
                                  </b>
                                </Typography>
                              </TableCell>

                              {yearlyPayslip?.monthData?.map((v) => {
                                return (
                                  <LATableCell>
                                    <Typography variant="body2">
                                      <b>{v.total_net}</b>
                                    </Typography>
                                  </LATableCell>
                                );
                              })}
                            </STableRow>
                          </Table>
                        )}
                      </TableContainer>
                    </Box>
                  </Grid>

                  {/* <Grid item lg={2} md={2} sm={12} xs={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    color: "#000000",
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                  }}
                >
                  <Toolbar />
                  <a>
                    <Box
                      sx={{
                        width: "90%",
                        background: "#FFFFFF",
                        ml: "auto",
                        mr: "auto",
                        mt: 2,
                        mb: 2,
                        p: 2,
                        borderRadius: "4px",
                        boxShadow: 1,
                        "&:hover": {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <Typography variant="h6">Form 16</Typography>
                      <br />

                      <Typography variant="body2">
                        View/download your form 16.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid> */}
                </>
              )}
            </>
          )}
          {isLoading && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                p: 1,
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <RotatingLines
                  strokeColor="#2e86c1"
                  strokeWidth="5"
                  animationDuration="0.50"
                  width="50"
                  marginRight="700"
                  visible={true}
                />
              </div>
            </Box>
          )}
          <br />
          {showPayslip && sessionStorage.getItem("coid") === "Sapi141335" && (
            <>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Box sx={{ width: "95%", m: 1, ml: 1.5 }}>
                  <div
                    className="content container-fluid"
                    style={{
                      marginTop: "23px",
                      padding: "8px",
                      paddingLeft: "18px",
                    }}
                  >
                    <div className="page-header">
                      <h3 className="page-title">Payslip</h3>
                    </div>
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                    }}
                  >
                    <div className="col-md-3">
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "4px 4px",
                            fontSize: "15px",
                            background: "#2db8c50",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setShowPayslip(false)}
                        >
                          <ArrowBack />
                        </button>
                      </span>
                      {/* <input
                        type="month"
                        className="col-md-12"
                        style={{ height: "40px", borderRadius: "4px" }}
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      /> */}
                    </div>

                    <SButton onClick={printPage}>
                      <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
                        Download
                      </Typography>
                    </SButton>
                  </Box>

                  <Box sx={{ background: "#FFFFFF", borderRadius: "4px" }}>
                    <Box ref={inputRef}>
                      <Box sx={{ height: "5px", background: "#3d3d3d" }}></Box>
                      <Box sx={{ height: "10px", background: "#1d7e54" }}></Box>
                      <Box
                        sx={{
                          textAlign: "left",
                          p: 1.5,
                          ml: "auto",
                          mr: "auto",
                        }}
                      >
                        <Grid container sx={{ mb: 1 }}>
                          <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignContent: "center",
                                textAlign: "center",
                                p: 1,
                              }}
                            >
                              <img
                                src={SapioLogo}
                                alt=""
                                style={{
                                  height: "auto",
                                  marginBottom: "8px",
                                  height: "50px",
                                  width: "auto",
                                  // marginLeft: "10px",
                                  marginRight: "auto",
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignContent: "center",
                                textAlign: "center",
                                p: 1,
                              }}
                            >
                              <Typography variant="body2">
                                <b style={{ fontSize: "16px" }}>
                                  {showSlip.user_details?.company_name}
                                </b>
                                <br />
                                <b>
                                  {/* OZ HRMS, Oasis Complex, Ram Ratna House,
                                Pandurang Budhkar Marg, Worli, Mumbai,
                                Maharashtra 400013 */}
                                  {showSlip.user_details?.company_address}
                                </b>
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid item lg={3} md={3} sm={3} xs={12}>
                            <Box
                              sx={{
                                display: "flex",
                                alignContent: "center",
                                textAlign: "center",
                                p: 1,
                              }}
                            >
                              <img
                                src={sessionStorage.getItem("company_logo")}
                                alt=""
                                style={{
                                  height: "auto",
                                  marginBottom: "8px",
                                  height: "40px",
                                  width: "auto",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <hr style={{ marginTop: "-10px" }} />
                        <Box sx={{ textAlign: "center", p: 1 }}>
                          <Typography variant="body2">
                            <b>
                              <u>
                                Payslip for the Month of {`${month}-${year1}`}
                              </u>
                            </b>
                          </Typography>
                        </Box>

                        <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                          <TableContainer>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ width: "100%", borderLeftColor: "red" }}
                            >
                              {/* <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Employee ID</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.emp_id}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Bank Name:</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.bank_name}
                                </Typography>
                              </SNCTableCell>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>PAN</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.pan}
                                </Typography>
                              </SNCTableCell>
                            </STableRow> */}

                              <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Employee Name</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    : {showSlip.user_details?.emp_name}
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Bank Name</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.bank_name}
                                  </Typography>
                                </SNCTableCell>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Days in Month</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    :{" "}
                                    {
                                      monthDays?.filter(
                                        (item) => item.label === month
                                      )[0].value
                                    }
                                  </Typography>
                                </SNCTableCell>
                              </STableRow>

                              <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Department</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    : {showSlip.user_details?.deptartment}
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>A/C</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.acc_no}
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Paid Days</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.paid_days}
                                  </Typography>
                                </SNCTableCell>
                              </STableRow>

                              <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>Designation</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    : {showSlip.user_details?.designation}
                                  </Typography>
                                </SNCTableCell>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>PAN</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.pan}
                                  </Typography>
                                </SNCTableCell>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>LOP Days</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.absent_days}
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2"></Typography>
                                </SNCTableCell>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2"></Typography>
                                </SNCTableCell>
                              </STableRow>

                              <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                <SNCTableCell
                                  sx={{
                                    width: `${100 / 6}%`,
                                    p: 0.75,
                                    m: 1,
                                    borderColor: "#FFFFFF",
                                  }}
                                >
                                  <Typography variant="body2">
                                    <b>Gender</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.gender}
                                  </Typography>
                                </SNCTableCell>
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    <b>UAN</b>
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                >
                                  <Typography variant="body2">
                                    {" "}
                                    : {showSlip.user_details?.uan}
                                  </Typography>
                                </SNCTableCell>

                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                />
                                <SNCTableCell
                                  sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                                />
                              </STableRow>
                            </Table>
                          </TableContainer>
                        </Box>
                        <br />

                        <Box sx={{ height: "100%", p: 1 }}>
                          <TableContainer>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "50%",
                                  // borderCollapse: "collapse",
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                }}
                              >
                                <STableRow>
                                  <SCTableCell>
                                    <Typography variant="body2">
                                      <b>Earnings</b>
                                    </Typography>
                                  </SCTableCell>

                                  <SCTableCell align="right">
                                    <Typography variant="body2">
                                      <b>
                                        Total
                                        {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                      </b>
                                    </Typography>
                                  </SCTableCell>
                                </STableRow>

                                {showSlip.earning
                                  ?.filter((ele) => ele.total !== 0)
                                  .map((ele) => (
                                    <STableRow>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {ele.component_name}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="right">
                                        <Typography variant="body2">
                                          {/* {ele.total?.toFixed(0)} */}
                                          {ele?.total?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 2,
                                          })}
                                        </Typography>
                                      </TableCell>
                                    </STableRow>
                                  ))}
                              </Table>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "50%",
                                  textTransform: "capitalize",
                                }}
                              >
                                <STableRow height="20px">
                                  <SCTableCell>
                                    <Typography variant="body2">
                                      <b>Deductions</b>
                                    </Typography>
                                  </SCTableCell>

                                  <SCTableCell align="right">
                                    <Typography variant="body2">
                                      <b>
                                        Total
                                        {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                      </b>
                                    </Typography>
                                  </SCTableCell>
                                </STableRow>

                                {showSlip.deduction?.map((ele) => (
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {ele.component_name === "otherdeduction"
                                          ? "Other Deduction"
                                          : ele.component_name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {/* {ele.total?.toFixed(0)} */}
                                        {ele?.total?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                ))}
                              </Table>
                            </Table>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "50%" }}
                              >
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>
                                        Gross Salary
                                        {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                      </b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* {totalEr?.toFixed(0)} */}
                                      {totalEr?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                              </Table>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "50%" }}
                              >
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>
                                        Total Deductions
                                        {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                      </b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* {totalDe?.toFixed(0)} */}
                                      {totalDe?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                              </Table>
                            </Table>

                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ width: "100%" }}
                            >
                              {/* <STableRow>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>Reimbursement</b>
                                </Typography>
                              </TableCell>

                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </STableRow> */}

                              {showSlip.reimbursment?.map((ele) => (
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      {ele.component_name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2"></Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2"></Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* {ele.total?.toFixed(0)} */}
                                      {ele?.total?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                              ))}

                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>Gross Earnings</b>
                                  </Typography>
                                </TableCell>

                                <TableCell />
                                <TableCell />

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalEr?.toFixed(0)} */}
                                    {totalEr?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>Total Deductions</b>
                                  </Typography>
                                </TableCell>

                                <TableCell />
                                <TableCell />

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalDe?.toFixed(0)} */}
                                    {totalDe?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>Total Reimbursements</b>
                                  </Typography>
                                </TableCell>

                                <TableCell />
                                <TableCell />

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalRe?.toFixed(0)} */}
                                    {totalRe?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 2,
                                    })}
                                  </Typography>
                                </TableCell>
                              </STableRow>

                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>Net Salary</b>
                                  </Typography>
                                </TableCell>

                                <STableCell />
                                <STableCell />

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  /> */}
                                    {showSlip?.total_net?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      }
                                    )}
                                    {/* {Math.round(showSlip.total_net)} */}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                              {/* <STableRow>
                              <STableCell>
                                <Typography variant="body2">
                                  <b>Salary in Words</b>
                                </Typography>
                              </STableCell>

                              <STableCell />
                              <STableCell />

                              <STableCell align="right">
                                <Typography variant="body2">
                                  {showSlip.user_details?.in_words} Only
                                </Typography>
                              </STableCell>
                            </STableRow> */}
                            </Table>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              <i>
                                <b style={{ textTransform: "capitalize" }}>
                                  Total Net Payable{" "}
                                  {showSlip?.total_net?.toLocaleString(
                                    "en-US",
                                    {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 2,
                                    }
                                  )}{" "}
                                  ({getInWords(showSlip.total_net)} Only)
                                </b>
                              </i>
                              <p>
                                **Total Net Payable = Gross Earnings - Total
                                Deductions + Total Reimbursements
                              </p>
                              <br />
                              <i>
                                <p>
                                  **This is a computer generated Payslip,
                                  doesn't require signature of the competent
                                  Authority**
                                </p>
                              </i>
                            </div>
                            <br />
                          </TableContainer>
                        </Box>
                      </Box>
                      {/* <Box sx={{ height: "7px", background: "#1d7e54" }}></Box>
                      <Box sx={{ height: "7px", background: "#3d3d3d" }}></Box> */}
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    color: "#000000",
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                  }}
                >
                  <Toolbar />
                  <a>
                    <Box
                      sx={{
                        width: "90%",
                        background: "#FFFFFF",
                        ml: "auto",
                        mr: "auto",
                        mt: 2,
                        mb: 2,
                        p: 2,
                        borderRadius: "4px",
                        boxShadow: 1,
                        "&:hover": {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <Typography variant="h6">Form 16</Typography>
                      <br />

                      <Typography variant="body2">
                        View/download your form 16.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid>
            </>
          )}
          {showPayslip && sessionStorage.getItem("coid") !== "Sapi141335" && (
            <>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Box sx={{ width: "95%", m: 1, ml: 1.5 }}>
                  <div
                    className="content container-fluid"
                    style={{
                      marginTop: "23px",
                      padding: "8px",
                      paddingLeft: "18px",
                    }}
                  >
                    <div className="page-header">
                      <h3 className="page-title">Payslip</h3>
                    </div>
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                    }}
                  >
                    <div className="col-md-3">
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "4px 4px",
                            fontSize: "15px",
                            background: "#2db8c50",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setShowPayslip(false)}
                        >
                          <ArrowBack />
                        </button>
                      </span>
                      {/* <input
                        type="month"
                        className="col-md-12"
                        style={{ height: "40px", borderRadius: "4px" }}
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      /> */}
                    </div>

                    <SButton onClick={printPage}>
                      <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
                        Download
                      </Typography>
                    </SButton>
                  </Box>

                  <Box sx={{ background: "#FFFFFF", borderRadius: "4px" }}>
                    <Box
                      ref={inputRef}
                      sx={{
                        textAlign: "left",
                        p: 1.5,
                        ml: "auto",
                        mr: "auto",
                      }}
                    >
                      <Grid container sx={{ mb: 1 }}>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                              textAlign: "center",
                              p: 1,
                            }}
                          >
                            <img
                              src={sessionStorage.getItem("company_logo")}
                              alt=""
                              style={{
                                height: "auto",
                                marginBottom: "8px",
                                height: "40px",
                                width: "auto",
                                // marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            />
                          </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                              textAlign: "center",
                              p: 1,
                            }}
                          >
                            <Typography variant="body2">
                              <b style={{ fontSize: "16px" }}>
                                {showSlip.user_details?.company_name}
                              </b>
                              <br />
                              <b>
                                {/* OZ HRMS, Oasis Complex, Ram Ratna House,
                                Pandurang Budhkar Marg, Worli, Mumbai,
                                Maharashtra 400013 */}
                                {showSlip.user_details?.company_address}
                              </b>
                              <p>CIN : {showSlip.user_details?.company_cin}</p>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item lg={3} md={3} sm={3} xs={3} />
                      </Grid>

                      <Box sx={{ textAlign: "center", p: 1 }}>
                        <Typography variant="body2">
                          <b>
                            <u>
                              PAYSLIP FOR THE MONTH OF {`${month}-${year1}`}
                            </u>
                          </b>
                        </Typography>
                      </Box>

                      <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                        <TableContainer>
                          <Table
                            size="small"
                            aria-label="a dense table"
                            sx={{ width: "100%", borderLeftColor: "red" }}
                          >
                            <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Employee ID</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.emp_id}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Bank Name:</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.bank_name}
                                </Typography>
                              </SNCTableCell>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>PAN</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.pan}
                                </Typography>
                              </SNCTableCell>
                            </STableRow>

                            <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Employee Name</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.emp_name}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>A/C</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.acc_no}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>LOP Days</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.absent_days}
                                </Typography>
                              </SNCTableCell>
                            </STableRow>

                            <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Department</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.deptartment}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>UAN</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.uan}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>ESI</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.esi}
                                </Typography>
                              </SNCTableCell>
                            </STableRow>

                            <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Designation</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.designation}
                                </Typography>
                              </SNCTableCell>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Days in Month</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {
                                    monthDays?.filter(
                                      (item) => item.label === month
                                    )[0].value
                                  }
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2"></Typography>
                              </SNCTableCell>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2"></Typography>
                              </SNCTableCell>
                            </STableRow>

                            <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{
                                  width: `${100 / 6}%`,
                                  p: 0.75,
                                  m: 1,
                                  borderColor: "#FFFFFF",
                                }}
                              >
                                <Typography variant="body2">
                                  <b>Gender</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.gender}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Paid Days</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.paid_days}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              />
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              />
                            </STableRow>
                          </Table>
                        </TableContainer>
                      </Box>
                      <br />

                      <Box sx={{ height: "100%", p: 1 }}>
                        <TableContainer>
                          <Table
                            size="small"
                            aria-label="a dense table"
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{
                                width: "50%",
                                // borderCollapse: "collapse",
                                border: "1px solid rgba(224, 224, 224, 1)",
                              }}
                            >
                              <STableRow>
                                <SCTableCell>
                                  <Typography variant="body2">
                                    <b>Particular</b>
                                  </Typography>
                                </SCTableCell>

                                <SCTableCell align="right">
                                  <Typography variant="body2">
                                    <b>
                                      Total (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      )
                                    </b>
                                  </Typography>
                                </SCTableCell>
                              </STableRow>

                              {showSlip.earning?.map((ele) => (
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      {ele.component_name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {ele.total?.toFixed(0)}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                              ))}
                            </Table>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ width: "50%" }}
                            >
                              <STableRow height="20px">
                                <SCTableCell>
                                  <Typography variant="body2">
                                    <b>Deduction</b>
                                  </Typography>
                                </SCTableCell>

                                <SCTableCell align="right">
                                  <Typography variant="body2">
                                    <b>
                                      Total (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      )
                                    </b>
                                  </Typography>
                                </SCTableCell>
                              </STableRow>

                              {showSlip.deduction?.map((ele) => (
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      {ele.component_name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {ele.total?.toFixed(0)}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                              ))}
                            </Table>
                          </Table>
                          <Table
                            size="small"
                            aria-label="a dense table"
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ width: "50%" }}
                            >
                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>
                                      Gross Salary (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      )
                                    </b>
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {totalEr?.toFixed(0)}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                            </Table>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              sx={{ width: "50%" }}
                            >
                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    <b>
                                      Total Deductions (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      )
                                    </b>
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {totalDe?.toFixed(0)}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                            </Table>
                          </Table>

                          <Table
                            size="small"
                            aria-label="a dense table"
                            sx={{ width: "100%" }}
                          >
                            <STableRow>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>Reimbursement</b>
                                </Typography>
                              </TableCell>

                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </STableRow>

                            {showSlip.reimbursment?.map((ele) => (
                              <STableRow>
                                <TableCell>
                                  <Typography variant="body2">
                                    {ele.component_name}
                                  </Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography variant="body2"></Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography variant="body2"></Typography>
                                </TableCell>

                                <TableCell align="right">
                                  <Typography variant="body2">
                                    {ele.total?.toFixed(0)}
                                  </Typography>
                                </TableCell>
                              </STableRow>
                            ))}

                            <STableRow>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>
                                    Total Reimbursement (
                                    <img
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                      }}
                                      src={Rupee}
                                    />
                                    )
                                  </b>
                                </Typography>
                              </TableCell>

                              <TableCell />
                              <TableCell />

                              <TableCell align="right">
                                <Typography variant="body2">
                                  {totalRe?.toFixed(0)}
                                </Typography>
                              </TableCell>
                            </STableRow>

                            <STableRow>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>
                                    Net Salary (
                                    <img
                                      style={{
                                        width: "10px",
                                        height: "10px",
                                      }}
                                      src={Rupee}
                                    />
                                    )
                                  </b>
                                </Typography>
                              </TableCell>

                              <STableCell />
                              <STableCell />

                              <TableCell align="right">
                                <Typography variant="body2">
                                  {Math.round(showSlip.total_net)}
                                </Typography>
                              </TableCell>
                            </STableRow>
                            {/* <STableRow>
                              <STableCell>
                                <Typography variant="body2">
                                  <b>Salary in Words</b>
                                </Typography>
                              </STableCell>

                              <STableCell />
                              <STableCell />

                              <STableCell align="right">
                                <Typography variant="body2">
                                  {showSlip.user_details?.in_words} Only
                                </Typography>
                              </STableCell>
                            </STableRow> */}
                          </Table>
                          <div style={{ textAlign: "center" }}>
                            <i>
                              <b>
                                Total Net Payable ₹
                                {Math.round(showSlip.total_net)} (
                                {showSlip.user_details?.in_words} Only)
                              </b>
                            </i>
                            <p>
                              **Total Net Payable = Gross Earnings - Total
                              Deductions + Total Reimbursements
                            </p>
                            <br />
                            <i>
                              <p>
                                **This is a computer generated Payslip, doesn't
                                require signature of the competent Authority**
                              </p>
                            </i>
                          </div>
                          <br />

                          <br />
                        </TableContainer>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    color: "#000000",
                    backgroundColor: "rgba(255, 255, 255, 0.20)",
                  }}
                >
                  <Toolbar />
                  <a>
                    <Box
                      sx={{
                        width: "90%",
                        background: "#FFFFFF",
                        ml: "auto",
                        mr: "auto",
                        mt: 2,
                        mb: 2,
                        p: 2,
                        borderRadius: "4px",
                        boxShadow: 1,
                        "&:hover": {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <Typography variant="h6">Form 16</Typography>
                      <br />

                      <Typography variant="body2">
                        View/download your form 16.
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default Payslip;
