import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import { Table } from "ant-table-extensions";
import { ConfigProvider } from "antd";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import moment from "moment";
import empty from "../../assets/img/profiles/browser.png";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No data to show</p>
  </div>
);

const Status = () => {
  const today = new Date();
  const year = today.getFullYear();
  const [menu, setMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState("Regularize");
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [data, setData] = useState([]);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const [isLoading, setIsLoading] = useState(true);
  //   const matchsmexact = useMediaQuery("(max-width:630px)");

  const handleRegularize = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/attendance/`,

        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            toggle: "n",
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            // ...ele,
            sno: index + 1,
            date: ele.date?.split("-")?.reverse()?.join("-"),
            status: ele.status,
            reason: ele.reason,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWorkFromHome = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mas_wfh/`,

        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            from: ele.from_date
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join("-"),
            to: ele.to_date?.substr(0, 10)?.split("-")?.reverse()?.join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleHalfDay = () => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mas_halfday/`,

        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,

            date: ele.on_date?.substr(0, 10)?.split("-")?.reverse()?.join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCompOff = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/mas_camp_off/`, {
        params: {
          year: monthValue.split("-")[0],
          month: monthValue.split("-")[1],
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            from: ele.from_date
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join("-"),
            to: ele.to_date?.substr(0, 10)?.split("-")?.reverse()?.join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShortLeave = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/get-short-leave-requests1/`, {
        params: {
          year: monthValue.split("-")[0],
          month: monthValue.split("-")[1],
          emp_id: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            on_date: ele.on_date
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join("-"),
            creation_date: ele.creation_date
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join("-"),
            approved_date: ele.approved_date
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              ?.join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentPage === "Work From Home") {
      handleWorkFromHome();
    } else if (currentPage === "Regularize") {
      handleRegularize();
    } else if (currentPage === "HalfDay") {
      handleHalfDay();
    } else if (currentPage === "ShortLeave") {
      handleShortLeave();
    } else {
      handleCompOff();
    }
  }, [monthValue, currentPage]);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "From",
      dataIndex: "from",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.from, "DD-MM-YYYY") - moment(b.from, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "To",
      dataIndex: "to",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.to, "DD-MM-YYYY") - moment(b.to, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      title: "Reason",
      dataIndex: "reason",
      // align: "center",
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
  ];

  const column1 = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      title: "Reason",
      dataIndex: "reason",
      // align: "center",
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
  ];

  const column2 = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Date",
      dataIndex: "on_date",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.on_date, "DD-MM-YYYY") - moment(b.on_date, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.approved_date, "DD-MM-YYYY") -
          moment(b.approved_date, "DD-MM-YYYY"),
      },
    },
    {
      title: "Application Date",
      dataIndex: "creation_date",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.creation_date, "DD-MM-YYYY") -
          moment(b.creation_date, "DD-MM-YYYY"),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ textTransform: "capitalize" }}
          >
            <i
              className={
                text === "Declined"
                  ? "fa fa-dot-circle-o text-danger"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-purple"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Attendance</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#973FCF",
                      border: "none",
                      color: "white",
                    }}
                    href="#"
                    classname="edit-icon"
                    onClick={() => setCurrentPage("Work From Home")}
                  >
                    Work from home
                  </button>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#ED9D44",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => {
                      setCurrentPage("Regularize");
                    }}
                  >
                    Regularise
                  </button>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298DB",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => setCurrentPage("Comp Off")}
                  >
                    Comp Off Leave
                  </button>
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => {
                      setCurrentPage("HalfDay");
                    }}
                  >
                    Half Day
                  </button> */}
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#973FCF",
                      border: "none",
                      color: "white",
                    }}
                    onClick={() => {
                      setCurrentPage("ShortLeave");
                    }}
                  >
                    Short Leave
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-0">
            <div
              style={{
                display: "flex",
                marginTop: "15px",
                marginLeft: "20px",
                marginRight: "20px",
                marginBottom: "-10px",
                justifyContent: "space-between",
              }}
            >
              <h4>{currentPage}</h4>
              <input
                style={{
                  width: "150px",
                  borderRadius: "5px",
                  height: "30px",
                  padding: "10px",
                }}
                type="month"
                value={monthValue}
                onChange={(e) => setMonthValue(e.target.value)}
              />
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <ConfigProvider
                  renderEmpty={
                    // customize ?
                    customizeRenderEmpty
                    // : undefined
                  }
                >
                  <Table
                    pagination={{
                      total: data.length,
                      showTotal: (total, range) =>
                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      showSizeChanger: true,
                      onShowSizeChange: onShowSizeChange,
                      itemRender: itemRender,
                    }}
                    style={{ overflowX: "auto" }}
                    columns={
                      currentPage === "Regularize"
                        ? column1
                        : currentPage === "HalfDay"
                        ? column1
                        : currentPage === "ShortLeave"
                        ? column2
                        : columns
                    }
                    bordered
                    dataSource={data}
                    rowKey={(record) => record.id}
                    // onChange={this.handleTableChange}
                    // exportable
                    // searchable
                    // exportableProps={{ showColumnPicker: true }}
                    // searchableProps={{ fuzzySearch: true }}
                    loading={isLoading}
                  />
                </ConfigProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Status;
