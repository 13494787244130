import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import pdflogo from "../../../assets/img/profiles/Adobe-PDF-File-Icon-01.svg";
import axios from "axios";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Upload from "../../../assets/img/profiles/upload.png";
import Swal from "sweetalert2";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Typography } from "@mui/material";

const AdminGuidelines = () => {
  const [pdfFile, setPdfFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [menu, setMenu] = useState(false);
  const [guides, setGuides] = useState([]);
  const [addDocu, setAddDocu] = useState({
    name: "",
    document: {},
  });
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const token = sessionStorage.getItem("access_token");
  const handleGetDocument = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/campany_guidelines/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setGuides(res.data);
        //  setLeaves(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetDocument();
  }, []);

  const handleAddDocs = (e) => {
    e.preventDefault();
    if (!addDocu.name || !addDocu.document.name) {
      Swal.fire({
        icon: "error",
        text: "Please fill all Mandatory fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/campany_guidelines/`,
          {
            empid: parseInt(sessionStorage.getItem("emp_id")),
            content_type: "application/pdf",
            file_name: addDocu.name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          var formData = new FormData();
          //console.log(value);
          formData.append("file", addDocu.document);
          axios
            .put(res.data.url, formData)
            .then((response) => {
              Swal.fire({
                icon: "success",
                // title: "Oops...",
                text: "Document uploaded successfully.",
                // footer: '<a href="">Why do I have this issue?</a>',
              });
              handleGetDocument();
            })
            .catch((err) => {
              if (err?.response?.status === 400) {
                Swal.fire({
                  icon: "error",
                  text: `${err?.response?.data?.error}!`,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong",
                });
              }
            });
          Swal.fire({
            icon: "success",
            text: "Document Uploaded Successfully",
          });
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        {viewPdf ? (
          <div className="content container-fluid">
            <div
              style={{
                background: "#FFFFFF",
                padding: "16px",
                textAlign: "right",
                borderRadius: "10px",
              }}
            >
              <CancelIcon
                sx={{ color: "#2E86C1", cursor: "pointer" }}
                onClick={() => setViewPdf(false)}
              />
              <br />

              <div style={{ height: "100%" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={
                      pdfFile ? pdfFile : "/assets/pdf-open-parameters.pdf"
                    }
                  />
                </Worker>
              </div>
            </div>
          </div>
        ) : (
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Guidelines</h3>
                </div>
                {rolePermissions["Guidelines"] === "Edit/Create/Approve" && (
                  <div className="col-auto float-end ml-auto">
                    <div className="view-icons">
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_document_board"
                        href="#"
                        classname="edit-icon"
                      >
                        <i className="fa fa-plus" />
                        &nbsp; Add Documents
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {guides.map((ele) => {
              return (
                <>
                  <div
                    className="col-sm-12 row"
                    style={{
                      height: "70px",
                      background: "white",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "16px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={pdflogo}
                          style={{ height: "35px", width: "35px" }}
                        />
                        &nbsp;
                        <Typography variant="subtitle1">
                          {ele.doc_name}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          sx={{ textTransform: "none" }}
                          onClick={() => {
                            setViewPdf(true);
                            setPdfFile(ele.link.links);
                          }}
                        >
                          <Typography variant="body1" sx={{ color: "blue" }}>
                            View
                          </Typography>
                        </Button>
                        &emsp;
                        <a
                          href={ele.link.links}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button sx={{ textTransform: "none" }}>
                            <Typography variant="body1" sx={{ color: "blue" }}>
                              Download
                            </Typography>
                          </Button>
                        </a>
                      </Box>
                    </Box>

                    {/** 
              <div className="col-sm-12" style={{ display: "flex" }}>
                <div className="col-sm-10" style={{ marginLeft: "20px" }}>
                  <img
                    src={pdflogo}
                    style={{ height: "35px", width: "35px" }}
                  />{" "}
                  &nbsp; &nbsp;
                  <span style={{ fontSize: "14px" }}>{ele.doc_name}</span>
                </div>
                <div className=" row col-sm-2" style={{ float: "end" }}>
                  {" "}
                  <a
                    href={ele.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "blue" }}
                    className="row col-sm-6"
                    // onClick={()=>{console.log(ele.link)}}
                  >
                    View
                  </a>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <a
                    style={{ color: "blue" }}
                    className=" row col-sm-6"
                    // target="_blank"
                    // href={ele.link}
                    download={ele.link}
                  >
                    Download
                  </a>
                </div>
              </div>
              */}
                  </div>
                  <br />
                </>
              );
            })}
          </div>
        )}

        {/* /Page Content */}
        <div
          id="add_document_board"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <br />

              <div
                className="modal-header"
                style={{ borderBottom: " 2px solid #cccccc" }}
              >
                <h4
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  {" "}
                  Add Document{" "}
                </h4>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Document Name <span className="text-danger">*</span>
                        </label>

                        <input
                          type="text"
                          className="form-control"
                          value={addDocu.name}
                          onChange={(e) =>
                            setAddDocu({ ...addDocu, name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label>
                          Upload Document <span className="text-danger">*</span>
                        </label>
                        <br />
                        <br />

                        <input
                          style={{
                            borderRadius: "5px",
                            height: "30px",
                            textAlign: "center",
                          }}
                          type="file"
                          className="form-control"
                          accept="application/pdf"
                          onChange={(e) =>
                            setAddDocu({
                              ...addDocu,
                              document: e.target.files[0],
                            })
                          }
                        ></input>
                      </div>
                    </div>
                    <div className="m-t-20 text-center">
                      <button
                        data-bs-dismiss={
                          !addDocu.name || !addDocu.document.name ? "" : "modal"
                        }
                        className="submit-btn1"
                        onClick={(e) => handleAddDocs(e)}
                        style={{
                          backgroundColor: "#3298db",
                          //height: "30px",
                          //padding:"none important",
                          minWidth: "30px",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminGuidelines;
