import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import {
//   avatar_02,
//   Avatar_05,
//   Avatar_09,
//   Avatar_10,
//   Avatar_03,
//   Avatar_08,
//   Avatar_15,
//   Avatar_20,
//   Avatar_25,
//   Avatar_24,
// } from "../../../src/assets/img/profiles/";

import Avatar_02 from "../../../src/assets/img/profiles/avatar-02.jpg";
import Avatar_05 from "../../assets/img/profiles/avatar-05.jpg";
import Avatar_09 from "../../assets/img/profiles/avatar-09.jpg";
import Avatar_10 from "../../assets/img/profiles/avatar-10.jpg";
import Avatar_03 from "../../assets/img/profiles/avatar-03.jpg";
import Avatar_08 from "../../assets/img/profiles/avatar-08.jpg";
import Avatar_15 from "../../assets/img/profiles/avatar-08.jpg";
import Avatar_20 from "../../assets/img/profiles/avatar-03.jpg";
import Avatar_25 from "../../assets/img/profiles/avatar-02.jpg";
import Avatar_24 from "../../assets/img/profiles/avatar-01.jpg";
import CircularStatic from "../../components/ProgressBar";
import { Table } from "antd";
import "antd/dist/antd.css";
import { Radio, TextField } from "@mui/material";

import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import Delete from "../../modelbox/Delete";
import axios from "axios";
import CustomizedTimeline from "../Timeline/Timeline";

const LeaveEmployee = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const [data, setData] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [leaveType, setLeaveType] = useState("Day");
  const [half, setHalf] = useState("");
  const [date, setDate] = useState("");
  const [managerList, setManagerList] = useState([]);
  const [duration, setDuration] = useState(0);
  const [getTime, setGetTmine] = useState([]);
  const [shortLeave, setShortLeave] = useState([]);
  const [applyLeave, setApplyLeave] = useState({
    empl_id: sessionStorage.getItem("emp_id"),
    type: { label: null, value: null, doc_req: 0 },
    noofleaves: null,
    from_date: "",
    to_date: "",
    reason: "",
    file: null,
  });
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const [leaveData, setLeaveData] = useState([]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const token = sessionStorage.getItem("access_token");
  const HandleGetTimeline = (text) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/approval_logs/`, {
        params: {
          approval_id: text,
        },
      })
      .then((res) => {
        console.log(res.data);
        setGetTmine(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getDaysDurationInclusive = () => {
    let start = moment(applyLeave.from_date);
    let end = moment(applyLeave.to_date);

    let duration1 = end.diff(start, "days") + 1;

    setDuration(duration1);
  };

  useEffect(() => {
    getDaysDurationInclusive();
  }, [applyLeave.from_date, applyLeave.to_date]);
  const handleApplyLeave = async (event) => {
    let formdata = new FormData();
    formdata.append("empl_id", sessionStorage.getItem("emp_id"));
    formdata.append("type", applyLeave.type.value);
    formdata.append("from_date", applyLeave.from_date);
    formdata.append("to_date", applyLeave.to_date);
    formdata.append("reason", applyLeave.reason);
    formdata.append("bill", applyLeave.file);
    event.preventDefault();
    if (
      !applyLeave.reason ||
      !applyLeave.from_date ||
      !applyLeave.to_date ||
      !applyLeave.type.value
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    }
    // else if (applyLeave.type.value === 63 && duration > 2) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "Please upload document",
    //   });
    //   // } else if (applyLeave.type.doc_req === 1 && applyLeave.file === null) {
    //   //   Swal.fire({
    //   //     icon: "error",
    //   //     text: "Please upload document",
    //   //   });
    //   // }
    // }
    else if (
      applyLeave.from_date.split("-")[1] !== applyLeave.to_date.split("-")[1]
    ) {
      Swal.fire({
        icon: "error",
        text: "Please apply leave within month",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_leave/`,
          formdata,
          // {
          //   empl_id: sessionStorage.getItem("emp_id"),
          //   type: applyLeave.type.value,
          //   from_date: applyLeave.from_date,
          //   to_date: applyLeave.to_date,
          //   reason: applyLeave.reason,
          // },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Leave Applied Successfully",
            });
            handleAllLeave();
            handleAllLeave2();
            setApplyLeave({
              type: { label: null, value: null, doc_req: 0 },
              manager: { label: null, value: null },
              from_date: "",
              to_date: "",
              reason: "",
              file: null,
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else if (err.response.status === 422) {
            console.log(err);
            Swal.fire({
              icon: "warning",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const handleApplyShortLeave = async (event) => {
    event.preventDefault();
    if (!date) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/apply-short-leave/`, {
          emp_id: parseInt(sessionStorage.getItem("emp_id")),
          on_date: date,
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            // title: "Oops...",
            text: "Short Leave applied successfully",
          });
          setDate("");
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err.response.data.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const handleApplyHalfLeave = async (event) => {
    event.preventDefault();
    if (!applyLeave.reason || !applyLeave.from_date || !applyLeave.type.value) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_halfleave/`,
          {
            empl_id: sessionStorage.getItem("emp_id"),
            type: applyLeave.type.value,
            number_of_leave: Number(applyLeave.noofleaves),
            on_date: applyLeave.from_date,
            reason: applyLeave.reason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Leave Applied Successfully",
            });
            handleAllLeave();
            handleAllLeave2();
            setApplyLeave({
              type: { label: null, value: null },
              noofleaves: null,
              from_date: "",
              to_date: "",
              reason: "",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const handleAllLeave = () => {
    if (!showLeave) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/mas_leave/`,
          // {
          //   email: sessionStorage.getItem("user"),
          // },
          {
            params: {
              empid: sessionStorage.getItem("emp_id"),
              toggle: "d",
              year: monthValue.split("-")[0],
              month: monthValue.split("-")[1],
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setData(
            res.data.map((ele) => ({
              ...ele,
              from: ele.from.split("-").reverse().join("-"),
              to: ele.to.split("-").reverse().join("-"),
              applied_date: ele.applied_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/get-short-leave-requests1/`,
          // {
          //   email: sessionStorage.getItem("user"),
          // },
          {
            params: { emp_id: sessionStorage.getItem("emp_id") },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          setShortLeave(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  let currentdate = new Date();
  let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 2));
  // console.log(currentdate);
  // console.log(`${last3months.getDate()}/${last3months.getMonth()+1}/${last3months.getFullYear()}T${last3months.getHours}:${last3months.getMinutes}`)

  const handleAllLeave2 = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mas_leave/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id"), toggle: "p" },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setLeaveData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleAllLeave2();
  }, []);
  useEffect(() => {
    handleAllLeave();
  }, [monthValue, showLeave]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mas_leavetypes/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLeaves(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // }
  const columns1 = [
    {
      title: "Application Date",
      dataIndex: "creation_date",
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Leaves Date",
      dataIndex: "on_date",
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Approved Date",
      dataIndex: "approved_date",
      render: (text, record) => (
        <>
          {text ? text?.slice(0, 10)?.split("-")?.reverse()?.join("-") : "--"}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            <BootstrapTooltip title={record.reason}>
              <Button>{text}</Button>
            </BootstrapTooltip>
          </a>
        </div>
      ),
    },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_leave"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_approve"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leavetype",
      sorter: (a, b) => a.leavetype.localeCompare(b.leavetype),
    },

    {
      title: "From",
      dataIndex: "from",
      sorter: {
        compare: (a, b) =>
          moment(a.from, "DD-MM-YYYY") - moment(b.from, "DD-MM-YYYY"),
      },
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: {
        compare: (a, b) =>
          moment(a.to, "DD-MM-YYYY") - moment(b.to, "DD-MM-YYYY"),
      },
    },

    {
      title: "No. of Days",
      dataIndex: "noofdays",
      sorter: (a, b) =>
        a.noofdays.match(/(\d+)/)[0] - b.noofdays.match(/(\d+)/)[0],
    },
    {
      title: "Application Date",
      dataIndex: "applied_date",
      sorter: {
        compare: (a, b) =>
          moment(a.applied_date, "DD-MM-YYYY") -
          moment(b.applied_date, "DD-MM-YYYY"),
      },
    },
    {
      title: "Approve Date",
      dataIndex: "approved_date",
      sorter: {
        compare: (a, b) =>
          moment(a.approved_date, "DD-MM-YYYY") -
          moment(b.approved_date, "DD-MM-YYYY"),
      },
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Reporting Manager",
      align: "center",
      render: (text, record) => <>{text.reporting_manager[0]}</>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            <BootstrapTooltip title={record.reason}>
              <Button>{text}</Button>
            </BootstrapTooltip>
          </a>
        </div>
      ),
    },
    {
      title: "View",
      dataIndex: "approval_id",
      // sorter: (a, b) => a.reason.length - b.reason.length,
      render: (text, record) => (
        <button
          data-bs-toggle="modal"
          data-bs-target="#show_timeline"
          onClick={() => HandleGetTimeline(text)}
          style={{
            border: "none",
            background: "#3298DB",
            padding: "2px 15px",
            color: "#FFFFFF",
            borderRadius: "15px",
          }}
        >
          View
        </button>
      ),
    },
    // {
    //   title: "Manager",
    //   dataIndex: "name",

    //   sorter: (a, b) => a.name.length - b.name.length,
    // },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_leave"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_approve"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin Template</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Leave Management</h3>
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Leaves</li>
              </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_leave"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Apply Leave
                  </button>{" "}
                  {sessionStorage.getItem("coid") === "Ram 067802" && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#short_leave"
                      href="#"
                      classname="edit-icon"
                    >
                      Apply Short Leave
                    </button>
                  )}
                </div>

                {/* <a
                  href="#"
                  className="btn add-btn"
                  // data-bs-toggle="modal"
                  // data-bs-target="#add_leave"
                  onClick={() => setShowLeave(!showLeave)}
                >
                  {!showLeave ? "Type of Leaves" : "LEAVE MANAGEMENT"}
                </a> */}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}
          <div className="row">
            {/* <div
              className="col-md-2"
              style={{ width: "175px", height: "200px" }}
            >
              <div className="stats-info">
                <h6>Total Leaves</h6>
                {/* <CircularStatic /> */}
            {/* <div style={{ width: 100, height: 100, marginLeft: "5px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#7446C3`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>  */}
            {/* <div
              className="col-md-2 kpi-div"
      
            >
              <div className="stats-info">
                <h6>Casual Leaves</h6>
      
                <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#7446C3`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-2 kpi-div"
              
            >
              <div className="stats-info">
                <h6>Sick Leaves</h6>
           
                <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#BD0000`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-2 kpi-div"
        
            >
              <div className="stats-info">
                <h6>Paternity Leaves</h6>
        
                <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#4483F7`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-2 kpi-div"
            >
              <div className="stats-info">
                <h6>Special Leaves</h6>
           
                <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#ED9D44`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div>
            <div
              className="col-md-2 "
             
            >
              <div className="stats-info">
                <h6 style={{ fontSize: "14px" }}>Comp Offs Leaves</h6>
               
                <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                  <CircularProgressbar
                    value={value}
                    maxValue={1}
                    text={`${value * 100}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      // Colors
                      pathColor: `#2DB8C5`,
                      textColor: "#f88",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
            </div> */}
            {leaveData.map((ele) => (
              <div className="col-md-2 " style={{ width: "210px" }}>
                <div className="stats-info">
                  <b
                    style={{
                      fontSize: "14px",
                      color: "#5f5f60",
                    }}
                  >
                    {ele.leavetype}
                  </b>
                  {/* <CircularStatic /> */}
                  <div style={{ width: 100, height: 100, marginLeft: "28px" }}>
                    <CircularProgressbar
                      value={
                        sessionStorage.getItem("coid") === "RAWJ225129"
                          ? ele.leaves_availed / 21
                          : ele.leaves_availed / ele.leaves_alloted
                      }
                      maxValue={1}
                      text={
                        sessionStorage.getItem("coid") === "RAWJ225129"
                          ? `${ele.leaves_availed} /21`
                          : `${ele.leaves_availed} / ${ele.leaves_alloted}`
                      }
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: "butt",
                        textSize: "22px",
                        pathTransitionDuration: 0.5,
                        // Colors
                        pathColor:
                          "#" +
                          Math.floor(Math.random() * 16777215)
                            .toString(16)
                            .padStart(6, "0")
                            .toUpperCase(),
                        textColor: "black",
                        trailColor: "#d6d6d6",
                        backgroundColor: "#3e98c7",
                      })}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  {sessionStorage.getItem("coid") === "Ram 067802" && (
                    <div className="duplicate-project">
                      <button
                        className={!showLeave ? "old-btn" : "old1-btn"}
                        onClick={() => {
                          setShowLeave(false);
                        }}
                      >
                        Regular Leaves
                      </button>
                      <button
                        className={showLeave ? "old-btn" : "old1-btn"}
                        onClick={() => {
                          setShowLeave(true);
                        }}
                      >
                        Short Leaves
                      </button>
                    </div>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <div className="view-icons">
                    <input
                      style={{
                        width: "150px",
                        borderRadius: "5px",
                        height: "37px",
                        padding: "10px",
                      }}
                      type="month"
                      value={monthValue}
                      onChange={(e) => setMonthValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    {!showLeave ? (
                      <Table
                        className="table-striped"
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        // onChange={console.log("change")}
                        loading={isLoading}
                      />
                    ) : (
                      <Table
                        className="table-striped"
                        pagination={{
                          total: shortLeave.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns1}
                        // bordered
                        dataSource={shortLeave}
                        rowKey={(record) => record.id}
                        // onChange={console.log("change")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        {showModal && (
          <div id="add_leave" className="modal custom-modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <br />
                  <br />
                  <br />
                  <h5 className="modal-title">
                    <b>Apply Leave</b>
                  </h5>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "6px 15px",
                        fontSize: "15px",
                        borderRadius: "8px 0px 0px 8px",
                        background: leaveType === "Day" ? "#3298DB" : "#FFFFFF",
                        border:
                          leaveType === "Day" ? "none" : "1px solid #344767",
                        color: leaveType === "Day" ? "#FFFFFF" : "#344767",
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#personal_info_modal"
                      onClick={() => {
                        setLeaveType("Day");
                      }}
                    >
                      {" "}
                      Full Day
                    </button>

                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "6px 15px",
                        fontSize: "15px",
                        borderRadius: "0px 8px 8px 0px",
                        background:
                          leaveType === "HalfDay" ? "#3298DB" : "#FFFFFF",
                        border:
                          leaveType === "HalfDay"
                            ? "none"
                            : "1px solid #344767",
                        color: leaveType === "HalfDay" ? "#FFFFFF" : "#344767",
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#personal_info_modal"
                      onClick={() => {
                        setLeaveType("HalfDay");
                      }}
                    >
                      Half Day
                    </button>
                  </div>
                </div>
                {leaveType === "Day" && (
                  <div className="modal-body">
                    <form>
                      <div className="form-group">
                        <label>
                          Type of Leave<span className="text-danger">*</span>
                        </label>
                        <Select
                          options={leaves}
                          value={applyLeave.type}
                          onChange={(e) =>
                            setApplyLeave({ ...applyLeave, type: e })
                          }
                        />
                      </div>
                      <div className="row col-md-12">
                        <div className="form-group col-md-6">
                          <div className="col-md-12">
                            {" "}
                            <label>
                              From <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                // min={new Date().toISOString().split("T")[0]}
                                // max={
                                //   applyLeave.to_date === ""
                                //     ? "9999-12-31"
                                //     : applyLeave.to_date
                                // }
                                min={`${last3months.getFullYear()}-${
                                  last3months.getMonth() + 1
                                }-${last3months.getDate()}`}
                                type="date"
                                value={applyLeave.from_date}
                                onChange={(e) =>
                                  setApplyLeave({
                                    ...applyLeave,
                                    from_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <div
                            className="col-md-12"
                            style={{ marginLeft: "25px" }}
                          >
                            {" "}
                            <label>
                              To <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                //min={new Date().toISOString().split("T")[0]}
                                disabled={
                                  applyLeave.from_date === "" ? true : false
                                }
                                min={applyLeave.from_date}
                                max={"9999-12-31"}
                                value={applyLeave.to_date}
                                className="form-control datetimepicker"
                                type="date"
                                onChange={(e) =>
                                  setApplyLeave({
                                    ...applyLeave,
                                    to_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className=" col-md-6">
                        <div className="form-group">
                          <label>
                            Number of days{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            type="text"
                            defaultValue={2}
                          />
                        </div>
                      </div> */}
                        {/* <div className=" col-md-6">
                        <div
                          className="form-group"
                          //style={{ marginLeft: "25px" }}
                        >
                          <label>
                            Remaining Leaves{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            defaultValue={12}
                            type="text"
                          />
                        </div>
                      </div> */}
                      </div>

                      <div className="form-group">
                        <label>
                          Leave Reason <span className="text-danger">*</span>
                        </label>
                        <textarea
                          rows={4}
                          value={applyLeave.reason}
                          className="form-control"
                          maxlength="100"
                          onChange={(e) =>
                            setApplyLeave({
                              ...applyLeave,
                              reason: e.target.value,
                            })
                          }
                        />
                      </div>
                      {/* {applyLeave.type.doc_req === 1 && (
                        <div className="form-group col-md-12">
                          <div
                            className="col-md-12"
                            // style={{ marginLeft: "25px" }}
                          >
                            {" "}
                            <label>
                              Upload Document{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                //min={new Date().toISOString().split("T")[0]}
                                // disabled={
                                //   applyLeave.from_date === "" ? true : false
                                // }
                                // min={applyLeave.from_date}
                                // max={"9999-12-31"}
                                // value={applyLeave.file}

                                className="form-control datetimepicker"
                                type="file"
                                onChange={(e) =>
                                  setApplyLeave({
                                    ...applyLeave,
                                    // to_date: e.target.value,
                                    file: e.target.files[0],
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )} */}

                      <div className="submit-section">
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            !applyLeave.reason ||
                            !applyLeave.from_date ||
                            !applyLeave.to_date ||
                            !applyLeave.type.value ||
                            applyLeave.from_date.split("-")[1] !==
                              applyLeave.to_date.split("-")[1]
                              ? ""
                              : "modal"
                          }
                          style={{
                            background: "#3298DB",
                            color: "white",
                            width: "94px",
                            height: "38px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleApplyLeave(event)}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                )}
                {leaveType === "HalfDay" && (
                  <div className="modal-body">
                    <form>
                      <div className="row col-md-12">
                        <div className="form-group col-md-6">
                          <label>
                            Type of Leave<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={leaves}
                            value={applyLeave.type}
                            onChange={(e) =>
                              setApplyLeave({ ...applyLeave, type: e })
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label>
                            Number of Leave
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            min="1"
                            defaultValue={0.5}
                            value={applyLeave.noofleaves}
                            onChange={(e) =>
                              setApplyLeave({
                                ...applyLeave,
                                noofleaves: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="row col-md-12">
                        <div className="form-group col-md-5">
                          <div className="col-md-12">
                            {" "}
                            <label>
                              Date <span className="text-danger">*</span>
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                // min={new Date().toISOString().split("T")[0]}
                                max={
                                  applyLeave.to_date === ""
                                    ? "9999-12-31"
                                    : applyLeave.to_date
                                }
                                type="date"
                                value={applyLeave.from_date}
                                onChange={(e) =>
                                  setApplyLeave({
                                    ...applyLeave,
                                    from_date: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group col-md-7">
                          <div className="col-md-12">
                            <div style={{ marginTop: "18px" }}>
                              <Radio
                                value="fs"
                                checked={half === "fs"}
                                onChange={(e) => setHalf(e.target.value)}
                                sx={{ m: 0, p: 0.5 }}
                              />
                              <label>First Half</label>

                              <Radio
                                value="sn"
                                checked={half === "sn"}
                                onChange={(e) => setHalf(e.target.value)}
                                sx={{ m: 0, p: 0.5 }}
                              />
                              <label>Second Half</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label>
                          Leave Reason <span className="text-danger">*</span>
                        </label>
                        <textarea
                          rows={4}
                          value={applyLeave.reason}
                          className="form-control"
                          maxlength="100"
                          onChange={(e) =>
                            setApplyLeave({
                              ...applyLeave,
                              reason: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="submit-section">
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            !applyLeave.reason ||
                            !applyLeave.from_date ||
                            !applyLeave.type.value
                              ? ""
                              : "modal"
                          }
                          style={{
                            background: "#3298DB",
                            color: "white",
                            width: "94px",
                            height: "38px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleApplyHalfLeave(event)}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* /Add Leave Modal */}
        {/* Edit Leave Modal */}
        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Type of Leave<span className="text-danger">*</span>
                    </label>
                    {/* <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select> */}
                    <Select />
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        // defaultValue="01-01-2019"
                        type="date"
                        min={`${last3months.getFullYear()}-${
                          last3months.getMonth() + 1
                        }-${last3months.getDate()}`}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Short leave */}

        <div id="short_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Short Leave</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Date <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        max={"9999-12-31"}
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss={!date ? "" : "modal"}
                      style={{
                        background: "#3298DB",
                        color: "white",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      onClick={(event) => handleApplyShortLeave(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/******************** */}
        <div
          id="show_timeline"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Leave Timeline</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <CustomizedTimeline
                  getTime={getTime}
                  timelineName="Leave Timeline"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveEmployee;
