import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Editproject from "../../modelbox/Editproject";
import Swal from "sweetalert2";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import AvatarGroup from "@atlaskit/avatar-group";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import "../../index.css";
import "./addProject.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import empty from "../../assets/img/profiles/browser.png";
import axios from "axios";
import { RANDOM_USERS } from "../../Constants/avtar";
import Select from "react-select";
import deleteicon from "../../assets/img/profiles/delete-bin-line.svg";
import userAdd from "../../assets/img/profiles/Group1216.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
// import Button from "@atlaskit/button/standard-button";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { RotatingLines } from "react-loader-spinner";
import "../../antdstyle.css";
import "antd/dist/antd.css";
import eye from "../../assets/img/profiles/Group813.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {fa-asterisk} from '@fortawesome/free-solid-svg-icons';
import { Table } from "ant-table-extensions";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import Drawer from "@atlaskit/drawer";
import ButtonGroup from "@atlaskit/button/button-group";
// import Button from "@atlaskit/button/standard-button";
import { Menu, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from "@atlaskit/modal-dialog";
import { css, jsx } from "@emotion/react";

import Tag, { SimpleTag } from "@atlaskit/tag";

import TagGroup from "@atlaskit/tag-group";
import { fontStyle } from "@mui/system";
import moment from "moment";

const SBox1 = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});

const Projects = () => {
  const [showCard, setShowCard] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const today = moment();
  const [menu, setMenu] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [userDetails, setUserDetails] = useState([
    { email: "", name: "", role: "" },
  ]);
  const [viewState, setViewState] = useState(false);
  const [open, setOpen] = useState(false);
  const [shouldScrollInViewport, setShouldScrollInViewport] = useState(false);
  const [projectDet, setProjectDet] = useState({});
  const [empList, setEmpList] = useState([]);
  const [search, setsearch] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectSearch, setProjectSearch] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const [projectDetails, setProjectDetails] = useState([]);
  const [more, setMore] = useState(null);
  const [enddate, setEnddate] = useState("");
  const [projectKey, setProjectKey] = useState(0);
  const [duplicateId, setDuplicateId] = useState({
    label: "Select project",
    value: "",
  });
  const [duplicateProject, setDuplicateProject] = useState({});
  const [newProject, setNewProject] = useState({
    name: "",
    description: "",
    start_date: today.format("YYYY-MM-DD"),
    deadlines: "",
    teamlead: [],
    color: "",
    members: [],
    // drive link
    driveLink: "",
  });
  const [newFields, setNewFields] = useState([]);

  const navigate = useNavigate();

  const addNewFields = (e) => {
    e.preventDefault();
    let newfield = {
      key: "",
      value: "",
    };
    setNewFields([...newFields, newfield]);
  };

  const handleNewFields = (index, name, value) => {
    let data = [...newFields];
    data[index][name] = value;
    setNewFields(data);
  };

  const removeNewField = (index) => {
    let data = [...newFields];
    data.splice(index, 1);
    setNewFields(data);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isopen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open1 = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeModal = useCallback(() => setOpen(false), [setOpen]);
  const setShouldScrollInViewportAndOpen = useCallback(
    (shouldScrollInViewport) => {
      setShouldScrollInViewport(shouldScrollInViewport);
      requestAnimationFrame(() => setOpen(true));
    },
    [setShouldScrollInViewport]
  );

  const handleGetProjects = () => {
    if (projectName === "") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/projects/`, {
          params: {
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        .then((res) => {
          setProjectDetails(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              starting: ele.creation_date?.split("T")[0],
              ending: ele.deadline?.split("T")[0],
            }))
          );
          //setProjectSearch(res.data);
          setProjectSearch(
            res.data.map((ele, index) => ({
              ...ele,
              sno: index + 1,
              starting: ele.creation_date?.split("T")[0],
              ending: ele.deadline?.split("T")[0],
            }))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            //title: "oops..",
            text: "Something Went Wrong",
            // timer: 1500,
          });
          setIsLoading(false);
        });
    }
  };

  const boldStyles = css({
    backbroundColor: "red",
  });

  useEffect(() => {
    handleGetProjects();
  }, [projectName]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
      });
  }, []);
  // console.log(viewState);
  const addFields = (e) => {
    e.preventDefault();
    let newfield = { email: "", name: "", role: "" };

    setUserDetails([...userDetails, newfield]);
  };

  // const data = projectDetails.members?.map((d, i) => ({
  //   email: d.email,
  //   key: d.email,
  //   name: d.name,
  //   href: "#",
  //   src: d.src,
  // }));

  const removeFields = (index) => {
    let data = [...userDetails];
    data.splice(index, 1);
    setUserDetails(data);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const closeProjectModal = () => {
    setShowCard(true);
    setNewProject({
      name: "",
      description: "",
      start_date: "",
      deadlines: "",
      teamlead: [],
      color: "",
      members: [],
      driveLink: "",
    });
    setNewFields([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      newProject.name !== "" &&
      newProject.description !== "" &&
      // newProject.deadlines !== "" &&
      newProject.teamlead.length !== 0 &&
      newProject.members.length !== 0
    ) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/projects/`,
          // if(newProject.name!=="") && (newProject.description!=="") && (newProject.deadlines!=="") && (newProject.teamlead.value!=="") && (newProject.members.length!==0)
          {
            empid: sessionStorage.getItem("emp_id"),
            name: newProject.name,
            description: newProject.description,
            start_date: newProject.start_date,
            deadline: newProject.deadlines,
            teamlead: [newProject.teamlead.value],
            color: newProject.color,
            status: "No Progress",
            members: Array.prototype.map.call(
              newProject.members,
              function (item) {
                return item.value;
              }
            ),
            // teamlead:Array.prototype.map
            // .call(newProject.teamlead, function (item) {
            //   return item.value;
            // })
            drive_link: newProject.driveLink,
            extra_fields: newFields,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        .then((res) => {
          setNewFields([]);
          setNewProject({
            name: "",
            description: "",
            start_date: "",
            deadlines: "",
            teamlead: [],
            color: "",
            members: [],
            driveLink: "",
          });
          // alert("update successfully");
          Swal.fire({
            icon: "success",
            text: "Project has been added Successfully.",
            // timer: 2500,
          });
          handleGetProjects();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fill all Mandatory fields.",
        // timer: 3000,
      });
    }

    // console.log(newProject);
  };

  const handleDuplicateSubmit = (e) => {
    e.preventDefault();
    if (
      duplicateProject.name !== "" &&
      duplicateProject.description !== "" &&
      duplicateProject.ending !== "" &&
      duplicateProject.teamleads.length !== 0 &&
      duplicateProject.members.length !== 0
    ) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/projects/`,
          // if(newProject.name!=="") && (newProject.description!=="") && (newProject.deadlines!=="") && (newProject.teamlead.value!=="") && (newProject.members.length!==0)
          {
            empid: sessionStorage.getItem("emp_id"),
            project_duplicate_id: duplicateProject.id,
            name: duplicateProject.name,
            description: duplicateProject.description,
            start_date: duplicateProject.starting,
            deadline: duplicateProject.ending,
            teamlead: duplicateProject.teamleads.map((ele) => ele.key),
            color: duplicateProject.color,
            status: "No Progress",
            members: duplicateProject.members.map((ele) => ele.key),
            // teamlead:Array.prototype.map
            // .call(newProject.teamlead, function (item) {
            //   return item.value;
            // })
            drive_link: duplicateProject.driveLink,
            extra_fields: newFields,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        .then((res) => {
          // console.log(res);
          setDuplicate(false);
          setDuplicateId({
            label: "Select Project",
            value: "",
          });
          setNewFields([]);
          setNewProject({
            name: "",
            description: "",
            start_date: "",
            deadlines: "",
            teamlead: [],
            color: "",
            members: [],
            driveLink: "",
          });
          // alert("update successfully");
          Swal.fire({
            icon: "success",
            text: "Project has been added Successfully.",
            // timer: 2500,
          });
          handleGetProjects();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    } else {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fill all Mandatory fields.",
        // timer: 3000,
      });
    }

    // console.log(newProject);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/projects/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          project_id: projectKey,
        },
      })
      .then((res) => {
        handleGetProjects();
        Swal.fire({
          icon: "success",
          text: "Project Deleted",
          // timer: 2000,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  // project search function
  // const handleSearch = () => {
  //   const newData = projectDetails.filter(
  //     (x) =>
  //       x.name.toLocaleLowerCase() ==
  //       (projectName == "" ? x.name.toLocaleLowerCase() : projectName)
  //   );
  //   setProjectSearch(newData);

  // };

  const handleClick1 = (event, value, index) => {
    setProjectDet(value);
    setProjectKey(value.id);
    setAnchorEl(event.currentTarget);
    console.log(value);
  };

  const handleViewPorject = (ele) => {
    //console.log(index);

    if (ele === "view") {
      setViewState(true);
    }
    if (ele === "edit") {
      setViewState(false);
    }

    setOpen(true);
    setMore(null);
    handleClose();
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Project Name",
      dataIndex: "name",
      //align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Start Date",
      align: "center",
      dataIndex: "starting",
      sorter: {
        compare: (a, b) =>
          moment(a.starting?.split("-")?.reverse().join("-"), "DD-MM-YYYY") -
          moment(b.starting?.split("-")?.reverse().join("-"), "DD-MM-YYYY"),
      },
      render: (text, record) => <>{text?.split("-")?.reverse().join("-")}</>,
    },
    {
      title: "Deadline",
      align: "center",
      dataIndex: "ending",
      sorter: {
        compare: (a, b) =>
          moment(a.ending?.split("-")?.reverse().join("-"), "DD-MM-YYYY") -
          moment(b.ending?.split("-")?.reverse().join("-"), "DD-MM-YYYY"),
      },
      render: (text, record) => <>{text?.split("-")?.reverse().join("-")}</>,
    },
    {
      title: "Teamlead",
      align: "center",
      render: (text, record) => (
        <>
          <ul className="team-members">
            <AvatarGroup
              appearance="stack"
              data={text.teamleads.map((ele, index) => ({
                ...ele,
                href: "#",
                email: ele.email,
                key: ele.email,

                src: ele.src.links,
              }))}
              // size="small"
            />
          </ul>
        </>
      ),
    },
    {
      title: "Members",
      render: (text, record) => (
        <>
          <ul className="team-members">
            <AvatarGroup
              appearance="stack"
              data={text.members.map((ele, index) => ({
                ...ele,
                href: "#",
                email: ele.email,
                key: ele.email,

                src: ele.src.links,
              }))}
              // size="small"
            />
          </ul>
        </>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          {projectDet?.edit_flag ? (
            <>
              <IconButton
                aria-label="more"
                onClick={(e) => handleClick1(e, text, text.id)}
                aria-haspopup="true"
                aria-controls="long-menu"
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={open1}
              >
                <MenuItem
                  onClick={() => handleViewPorject("view")}
                  data-bs-toggle="modal"
                  data-bs-target="#edit_project"
                >
                  <VisibilityOutlinedIcon className="view-icon" />
                  &nbsp;View Project
                </MenuItem>
                <MenuItem
                  onClick={() => handleViewPorject("edit")}
                  data-bs-toggle="modal"
                  data-bs-target="#edit_project"
                >
                  <EditOutlinedIcon className="view-icon" /> &nbsp;Edit Project
                </MenuItem>

                <MenuItem
                  onClick={() => handleViewPorject("delete")}
                  data-bs-toggle="modal"
                  data-bs-target="#delete_project"
                >
                  <DeleteOutlineOutlinedIcon className="view-icon" />{" "}
                  &nbsp;Delete Project
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <IconButton
                aria-label="more"
                onClick={(e) => handleClick1(e, text, text.id)}
                aria-haspopup="true"
                aria-controls="long-menu"
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                onClose={handleClose}
                open={open1}
              >
                <MenuItem
                  onClick={() => handleViewPorject("view")}
                  data-bs-toggle="modal"
                  data-bs-target="#edit_project"
                >
                  <VisibilityOutlinedIcon className="view-icon" />
                  &nbsp;View Project
                </MenuItem>
              </Menu>
            </>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const data = projectDetails?.find((ele) => ele.id === duplicateId.value);
    setDuplicateProject(data ? { ...data, name: `${data?.name}-Copy` } : {});
  }, [duplicateId]);

  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });
  // const onImageUpload = (fileList) => {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     ReactSummernote.insertImage(reader.result);
  //   };
  //   reader.readAsDataURL(fileList[0]);
  // };
  // console.log(projectDet);
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20%",
            }}
          >
            <RotatingLines
              strokeColor="#2e86c1"
              strokeWidth="5"
              animationDuration="0.50"
              width="50"
              marginRight="700"
              visible={true}
            />
          </div>
        ) : (
          <>
            {/* Page Content */}
            <div className="content container-fluid">
              {/* Page Header */}

              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col">
                    <h3 className="page-title">Projects</h3>
                  </div>
                  <div className="col-auto float-end ml-auto">
                    <div className="view-icons">
                      <Link
                        className={
                          showTable
                            ? "grid-view btn btn-link "
                            : "grid-view btn btn-link active"
                        }
                        onClick={() => setShowTable(false)}
                      >
                        <i className="fa fa-th" />
                      </Link>
                      <Link
                        className={
                          showTable
                            ? "list-view btn btn-link active"
                            : "grid-view btn btn-link "
                        }
                        onClick={() => setShowTable(true)}
                      >
                        <i className="fa fa-bars" />
                      </Link>
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298DB",
                          border: "none",
                          color: "white",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#create_project"
                        href="#"
                        classname="edit-icon"
                      >
                        <i className="fa fa-plus" /> Create Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              {/* Search Filter */}

              {/* Search Filter */}
              {showTable && (
                <>
                  <div className="row filter-row col-md-12">
                    <div className="col-md-12" style={{ display: "flex" }}>
                      {/* <div className="col-md-4">
                <div className=" col-md-12">
                  <input
                    onChange={(e) =>
                      //setProjectName(e.target.value.toLocaleLowerCase())
                      setsearch(e.target.value)
                    }
                    type="text"
                    placeholder="Project Name ...."
                    className="project-search col-md-12 "
                  />
                  //  <label className="focus-label">Project Name</label> 
                </div>
              </div> */}
                      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                        <div className="form-group form-focus select-focus">
                          <Select
                            isClearable
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            inputId="single-select-example"
                            className="single-select"
                            classNamePrefix="react-select"
                            //isClearable={()=>name1.clearValue()}
                            placeholder="Project Name"
                            options={[
                              ...new Set(
                                projectDetails?.map((item) => item.name)
                              ),
                            ].map((ele) => ({
                              label: ele,
                              value: ele,
                            }))}
                            value={
                              search !== ""
                                ? {
                                    label: search,
                                    value: search,
                                  }
                                : null
                            }
                            onChange={(e) => setsearch(e ? e.value : "")}
                            //className="select floating"
                            style={{
                              zindex: "5",
                              height: "50px",
                              width: "100%",
                              border: "1px solid lightgrey",
                              borderRadius: "3px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          pagination={{
                            //total: data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={projectSearch
                            ?.sort(
                              (a, b) =>
                                moment(
                                  b.creation_date,
                                  "YYYY-MM-DD, h:mm:ss a"
                                ) -
                                moment(a.creation_date, "YYYY-MM-DD, h:mm:ss a")
                            )
                            ?.filter((ele) => {
                              return search.toLocaleLowerCase() === ""
                                ? ele
                                : ele.name
                                    .toLocaleLowerCase()
                                    .includes(search.toLocaleLowerCase());
                            })}
                          rowKey={(record) => record.id}
                          // onChange={this.handleTableChange}
                          //exportable
                          // searchable

                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {!showTable && !isLoading && (
                <div className="row">
                  {projectDetails?.length > 0 ? (
                    <>
                      <div className="row filter-row col-md-12">
                        <div className="col-md-12" style={{ display: "flex" }}>
                          {/* <div className="col-md-4">
                <div className=" col-md-12">
                  <input
                    onChange={(e) =>
                      //setProjectName(e.target.value.toLocaleLowerCase())
                      setsearch(e.target.value)
                    }
                    type="text"
                    placeholder="Project Name ...."
                    className="project-search col-md-12 "
                  />
                  //  <label className="focus-label">Project Name</label> 
                </div>
              </div> */}
                          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                            <div className="form-group form-focus select-focus">
                              <Select
                                isClearable
                                styles={{
                                  // Fixes the overlapping problem of the component
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                  }),
                                }}
                                inputId="single-select-example"
                                className="single-select"
                                classNamePrefix="react-select"
                                //isClearable={()=>name1.clearValue()}
                                placeholder="Project Name"
                                options={[
                                  ...new Set(
                                    projectDetails?.map((item) => item.name)
                                  ),
                                ].map((ele) => ({
                                  label: ele,
                                  value: ele,
                                }))}
                                value={
                                  search !== ""
                                    ? {
                                        label: search,
                                        value: search,
                                      }
                                    : null
                                }
                                onChange={(e) => setsearch(e ? e.value : "")}
                                //className="select floating"
                                style={{
                                  zindex: "5",
                                  height: "50px",
                                  width: "100%",
                                  border: "1px solid lightgrey",
                                  borderRadius: "3px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {projectSearch
                        ?.filter((ele) => {
                          return search.toLocaleLowerCase() === ""
                            ? ele
                            : ele.name
                                .toLocaleLowerCase()
                                .includes(search.toLocaleLowerCase());
                        })
                        .sort(
                          (a, b) =>
                            moment(b.creation_date, "YYYY-MM-DD, h:mm:ss a") -
                            moment(a.creation_date, "YYYY-MM-DD, h:mm:ss a")
                        )
                        .map((ele, index) => (
                          <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                            <div
                              className="card card-no-border"
                              style={{ height: "380px" }}
                              key={ele.id}
                            >
                              <div
                                style={{ background: ele.color }}
                                className="project-head"
                              >
                                {" "}
                              </div>
                              <div className="card-body">
                                {/* <div className="dropdown dropdown-action profile-action">
                    <a
                      href="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_project"
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_project"
                      >
                        <i className="fa fa-trash-o m-r-5" /> Delete
                      </a>
                    </div>
                  </div> */}
                                <h4
                                  className="project-title"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <button
                                    style={{
                                      border: 0,
                                      background: "transparent",
                                      paddingLeft: "0px",
                                    }}
                                    onClick={() =>
                                      navigate(`/taskboard-Grid?id=${ele.name}`)
                                    }
                                    //data-bs-toggle="modal"
                                    //data-bs-target="#edit_project"
                                  >
                                    {ele.name}
                                  </button>{" "}
                                  <IconButton
                                    aria-label="more"
                                    onClick={(e) => handleClick1(e, ele, index)}
                                    aria-haspopup="true"
                                    aria-controls="long-menu"
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    onClose={handleClose}
                                    open={open1}
                                  >
                                    <MenuItem
                                      onClick={() => handleViewPorject("view")}
                                      data-bs-toggle="modal"
                                      data-bs-target="#edit_project"
                                    >
                                      <VisibilityOutlinedIcon className="view-icon" />
                                      &nbsp;View Project
                                    </MenuItem>
                                    {projectDet?.edit_flag && (
                                      <>
                                        <MenuItem
                                          onClick={() =>
                                            handleViewPorject("edit")
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#edit_project"
                                        >
                                          <EditOutlinedIcon className="view-icon" />{" "}
                                          &nbsp;Edit Project
                                        </MenuItem>
                                        <MenuItem
                                          // onClick={() => (
                                          //   setProjectKey(ele.key),
                                          //   setOpen(true),
                                          //   setMore(null),
                                          //   handleClose()
                                          // )}
                                          onClick={() =>
                                            handleViewPorject("delete")
                                          }
                                          data-bs-toggle="modal"
                                          data-bs-target="#delete_project"
                                        >
                                          <DeleteOutlineOutlinedIcon className="view-icon" />{" "}
                                          &nbsp;Delete Project
                                        </MenuItem>
                                      </>
                                    )}
                                  </Menu>
                                </h4>
                                {/* <small className="block text-ellipsis m-b-15">
                    <span className="text-xs">12</span>{" "}
                    <span className="text-muted">open tasks, </span>
                    <span className="text-xs">4</span>{" "}
                    <span className="text-muted">tasks completed</span>
                  </small> */}
                                {ele.description.length > 35 ? (
                                  <p className="text-muted">
                                    {ele.description?.substr(0, 25) +
                                      " " +
                                      "..."}
                                  </p>
                                ) : (
                                  <p className="text-muted">
                                    {ele.description}
                                  </p>
                                )}

                                <div
                                  className="pro-deadline m-b-15"
                                  style={{ height: "40px" }}
                                >
                                  <div className="sub-title">Deadline:</div>
                                  <div className="text-muted">
                                    {ele.deadline
                                      ?.substr(0, 10)
                                      ?.split("-")
                                      ?.reverse()
                                      ?.join("-")}
                                  </div>
                                </div>

                                <div
                                  className="project-members m-b-15"
                                  style={{ height: "55px" }}
                                >
                                  <div>Project Leader :</div>
                                  <ul className="team-members">
                                    {/* <li>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            title={ele.project_leader.name}
                          >
                            <img alt="" src={ele.project_leader.pic} />
                          </a>
                        </li> */}
                                    <AvatarGroup
                                      sx={{ zIndex: 0 }}
                                      appearance="stack"
                                      data={ele.teamleads.map((ele, index) => ({
                                        ...ele,
                                        href: "#",
                                        email: ele.email,
                                        key: ele.email,

                                        src: ele.src.links,
                                      }))}
                                      size="large"
                                    />
                                  </ul>
                                </div>
                                <div className="project-members m-b-15">
                                  <div>Team :</div>

                                  <ul className="team-members">
                                    <AvatarGroup
                                      appearance="stack"
                                      data={ele.members.map((ele, index) => ({
                                        ...ele,
                                        href: "#",
                                        email: ele.email,
                                        key: ele.email,

                                        src: ele.src.links,
                                      }))}
                                      size="large"
                                    />
                                  </ul>
                                </div>
                                <p className="m-b-5">
                                  Progress{" "}
                                  <span className="text-success float-end">
                                    {ele.progress}
                                  </span>
                                </p>
                                <div className="progress progress-xs mb-0">
                                  <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    data-bs-toggle="tooltip"
                                    title="40%"
                                    style={{
                                      width:
                                        ele.status === "No Progress"
                                          ? "5%"
                                          : ele.status === "In Progress"
                                          ? "57%"
                                          : ele.status === "Completed"
                                          ? "100%"
                                          : "15%",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {showCard ? (
                        <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                          <div
                            className="card card-no-border"
                            style={{ height: "380px" }}
                          >
                            <div className="card-body">
                              <SBox1>
                                <Box textAlign="center">
                                  <IconButton
                                    sx={{ color: "#3298DB" }}
                                    data-bs-toggle="modal"
                                    data-bs-target="#create_project"
                                    onClick={() =>
                                      setTimeout(() => {
                                        setShowCard(false);
                                      }, 250)
                                    }
                                  >
                                    <AddCircleOutlineIcon fontSize="large" />
                                  </IconButton>
                                  <br />

                                  <div className="text-muted">
                                    Click on the above button to create a new
                                    project.
                                  </div>
                                </Box>
                              </SBox1>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className="card "
                        style={{
                          height: "300px",
                          width: "350px",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          src={empty}
                        />
                        <br />
                        <h4>No Project to show</h4>
                        <h6>
                          Click on the below button to create a new project.
                        </h6>
                        <br />
                        <IconButton
                          sx={{ color: "#3298DB" }}
                          data-bs-toggle="modal"
                          data-bs-target="#create_project"
                        >
                          <AddCircleOutlineIcon size="medium" />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* /Page Content */}
            {/* Create Project Modal */}
            <div
              id="create_project"
              className="modal custom-modal fade"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
              >
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{
                      borderBottom: "2px solid #cccccc",
                      marginTop: "5px",
                    }}
                  >
                    <h4
                      className="modal-title"
                      style={{ marginTop: "5px", fontWeight: "600" }}
                    >
                      {" "}
                      New Project{" "}
                    </h4>
                    <button
                      type="button"
                      className="close-btn"
                      data-bs-dismiss="modal"
                      onClick={closeProjectModal}
                    >
                      <img src={closeIcon} />
                    </button>
                  </div>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: duplicate ? "space-around" : "center",
                    }}
                  >
                    <div className="duplicate-project">
                      <button
                        className={!duplicate ? "old-btn" : "old1-btn"}
                        onClick={() => {
                          setDuplicate(false);
                          setDuplicateId({
                            label: "Select Project",
                            value: "",
                          });
                          setNewFields([]);
                        }}
                      >
                        New Project
                      </button>
                      <button
                        className={duplicate ? "old-btn" : "old1-btn"}
                        onClick={() => {
                          setDuplicate(true);
                          setNewFields([]);
                        }}
                      >
                        Duplicate Project
                      </button>
                    </div>
                    <br />
                    {duplicate && (
                      <div style={{ width: "200px" }}>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                          inputId="single-select-example"
                          className="single-select"
                          classNamePrefix="react-select"
                          //isClearable={()=>name1.clearValue()}
                          placeholder="Project Name"
                          options={projectDetails?.map((ele) => ({
                            label: ele.name,
                            value: ele.id,
                          }))}
                          value={duplicateId}
                          onChange={(e) => setDuplicateId(e ? e : "")}
                          //className="select floating"
                          style={{
                            zindex: "5",
                            height: "50px",
                            width: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: "3px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {!duplicate && (
                    <div className="modal-body">
                      {/*<form className="myform">*/}

                      <div className="row col-sm-12">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Name</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              style={{ height: "40px" }}
                              value={newProject.name}
                              onChange={(e) => {
                                setNewProject({
                                  ...newProject,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Description</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea
                              className="form-control"
                              type="text"
                              rows={1}
                              style={{ height: "40px" }}
                              value={newProject.description}
                              onChange={(e) => {
                                setNewProject({
                                  ...newProject,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/** */}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Start Date</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                type="date"
                                value={newProject.start_date}
                                max="9999-12-31"
                                onChange={(e) => {
                                  setNewProject({
                                    ...newProject,
                                    start_date: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>End Date</b>{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                type="date"
                                disabled={!newProject.start_date}
                                min={moment(newProject.start_date).format(
                                  "YYYY-MM-DD"
                                )}
                                max="9999-12-31"
                                value={newProject.deadlines}
                                onChange={(e) => {
                                  setNewProject({
                                    ...newProject,
                                    deadlines: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <br />

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Lead</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              options={empList}
                              value={newProject.teamlead}
                              onChange={(e) => {
                                setNewProject({ ...newProject, teamlead: e });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Members</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isMulti
                              options={empList}
                              value={newProject.members}
                              onChange={(e) => {
                                if (e) {
                                  setNewProject({
                                    ...newProject,
                                    members: e.map((item) => item),
                                  });
                                } else {
                                  setNewProject([]);
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Upload Drive Link</b>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              //style={{ height: "20px" }}
                              value={newProject.driveLink}
                              onChange={(e) =>
                                setNewProject({
                                  ...newProject,
                                  driveLink: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              <b>Project Color</b>
                            </label>

                            <input
                              // placeholder="$50"
                              className="form-control color-input "
                              type="color"
                              style={{
                                borderRadius: "50%",
                                background:
                                  newProject.color === ""
                                    ? "black"
                                    : newProject.color,
                                border: "none",
                              }}
                              value={newProject.color}
                              onChange={(e) => {
                                setNewProject({
                                  ...newProject,
                                  color: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      {newFields.map((ele, i) => {
                        return (
                          <div className="row col-sm-12">
                            <div className="col-sm-1" />

                            <div className="col-sm-5">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  style={{ height: "40px" }}
                                  placeholder="Key"
                                  name="key"
                                  value={ele.key}
                                  onChange={(event) => {
                                    handleNewFields(
                                      i,
                                      event.target.name,
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-5">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  style={{ height: "40px" }}
                                  placeholder="Value"
                                  name="value"
                                  value={ele.value}
                                  onChange={(event) => {
                                    handleNewFields(
                                      i,
                                      event.target.name,
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-1">
                              <button
                                style={{
                                  marginTop: "8px",
                                  background: "#FFFFFF",
                                  border: "0px",
                                  width: "100%",
                                }}
                                onClick={() => removeNewField(i)}
                              >
                                <img src={deleteicon} />
                              </button>
                            </div>
                          </div>
                        );
                      })}

                      <div style={{ textAlign: "center" }}>
                        <button
                          style={{ background: "#3298db", color: "white" }}
                          className="btn"
                          onClick={(e) => addNewFields(e)}
                        >
                          Add more field(s)
                        </button>
                      </div>
                      {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Files</label>
                        <br />
                        <br />
                        <input
                          // placeholder="$50"
                          className="form-control"
                          type="file"
                        />
                      </div>
                    </div> */}

                      <br />
                      {/* <div className="row">
                    <h4>
                      <b> Invite User</b>
                    </h4>
                    <br />
                    <br />
                    {userDetails.map((input, index) => (
                      <div className=" row col-md-12">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>User Email</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>User Name</label>
                            <input
                              // placeholder="$50"
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4" style={{ display: "flex" }}>
                          <div className="form-group col-sm-11">
                            <label>Role</label>
                            <input
                              // placeholder="$50"
                              className="form-control"
                              type="text"
                            />
                          </div>
                          <div
                            className="form-group col-sm-1"
                            onClick={() => removeFields(index)}
                          >
                            <img src={deleteicon} />
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={(e) => addFields(e)}
                      className="add-btn-add"
                      style={{ width: "110px" }}
                    >
                      <p>
                        {" "}
                        <img src={userAdd} /> Add User
                      </p>
                    </button>
                  </div> */}
                      <div className="submit-section">
                        <button
                          style={{ background: "#3298db", color: "white" }}
                          className="btn"
                          data-bs-dismiss={
                            newProject.name !== "" &&
                            newProject.description !== "" &&
                            // newProject.deadlines !== "" &&
                            newProject.teamlead.length !== 0 &&
                            newProject.members.length !== 0
                              ? "modal"
                              : ""
                          }
                          onClick={(e) => handleSubmit(e)}
                        >
                          Submit
                        </button>
                      </div>
                      {/*</form>*/}
                    </div>
                  )}
                  {duplicate && (
                    <div className="modal-body">
                      {/*<form className="myform">*/}

                      <div className="row col-sm-12">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Name</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              disabled={
                                duplicateProject === undefined ? true : false
                              }
                              type="text"
                              style={{ height: "40px" }}
                              value={duplicateProject?.name}
                              onChange={(e) => {
                                setDuplicateProject({
                                  ...duplicateProject,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Description</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <textarea
                              className="form-control"
                              type="text"
                              disabled={
                                duplicateProject === undefined ? true : false
                              }
                              rows={1}
                              style={{ height: "40px" }}
                              value={duplicateProject?.description}
                              onChange={(e) => {
                                setDuplicateProject({
                                  ...duplicateProject,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Start Date</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                type="date"
                                disabled={
                                  duplicateProject === undefined ? true : false
                                }
                                value={duplicateProject?.starting}
                                max="9999-12-31"
                                onChange={(e) => {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    starting: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>End Date</b>{" "}
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <div>
                              <input
                                className="form-control datetimepicker"
                                type="date"
                                disabled={
                                  duplicateProject === undefined ? true : false
                                }
                                // disabled={!duplicateProject?.start_date}
                                // min={moment(
                                //   duplicateProject?.start_date
                                // ).format("YYYY-MM-DD")}
                                max="9999-12-31"
                                value={duplicateProject?.ending}
                                onChange={(e) => {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    ending: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Project Lead</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isDisabled={
                                duplicateProject === undefined ? true : false
                              }
                              options={empList.map((ele) => ({
                                label: ele.label,
                                value: ele.value,
                                name: ele.label,
                                key: ele.value,
                              }))}
                              value={
                                duplicateProject?.teamleads
                                  ? duplicateProject?.teamleads?.map((ele) => ({
                                      label: ele.name,
                                      value: ele.key,
                                      name: ele.name,
                                      key: ele.key,
                                    }))
                                  : []
                              }
                              onChange={(e) => {
                                if (e) {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    teamleads: [e],
                                  });
                                } else {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    teamleads: [],
                                  });
                                }
                              }}
                              // value={teamLeads}
                              // onChange={(e) => {
                              //   setTeamLeads(e ? e : teamLeads);
                              // }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Members</b>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <input type="text" className="form-control" /> */}
                            <Select
                              isMulti
                              isDisabled={
                                duplicateProject === undefined ? true : false
                              }
                              options={empList.map((ele) => ({
                                label: ele.label,
                                value: ele.value,
                                name: ele.label,
                                key: ele.value,
                              }))}
                              value={
                                duplicateProject?.members
                                  ? duplicateProject?.members?.map((ele) => ({
                                      label: ele.name,
                                      value: ele.key,
                                      name: ele.name,
                                      key: ele.key,
                                    }))
                                  : []
                              }
                              onChange={(e) => {
                                if (e) {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    members: e.map((item) => item),
                                  });
                                } else {
                                  setDuplicateProject({
                                    ...duplicateProject,
                                    members: [],
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="form-label required">
                              <b>Upload Drive Link</b>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              disabled={
                                duplicateProject === undefined ? true : false
                              }
                              //style={{ height: "20px" }}
                              value={duplicateProject?.drive_link}
                              onChange={(e) =>
                                setDuplicateProject({
                                  ...duplicateProject,
                                  drive_link: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label>
                              <b>Project Color</b>
                            </label>

                            <input
                              // placeholder="$50"
                              className="form-control color-input "
                              type="color"
                              disabled={
                                duplicateProject === undefined ? true : false
                              }
                              style={{
                                borderRadius: "50%",
                                background:
                                  duplicateProject?.color === ""
                                    ? "black"
                                    : duplicateProject?.color,
                                border: "none",
                              }}
                              value={duplicateProject?.color}
                              onChange={(e) => {
                                setDuplicateProject({
                                  ...duplicateProject,
                                  color: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <br />
                      {newFields.map((ele, i) => {
                        return (
                          <div className="row col-sm-12">
                            <div className="col-sm-1" />

                            <div className="col-sm-5">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  style={{ height: "40px" }}
                                  placeholder="Key"
                                  name="key"
                                  value={ele.key}
                                  onChange={(event) => {
                                    handleNewFields(
                                      i,
                                      event.target.name,
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-5">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  style={{ height: "40px" }}
                                  placeholder="Value"
                                  name="value"
                                  value={ele.value}
                                  onChange={(event) => {
                                    handleNewFields(
                                      i,
                                      event.target.name,
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            </div>

                            <div className="col-sm-1">
                              <button
                                style={{
                                  marginTop: "8px",
                                  background: "#FFFFFF",
                                  border: "0px",
                                  width: "100%",
                                }}
                                onClick={() => removeNewField(i)}
                              >
                                <img src={deleteicon} />
                              </button>
                            </div>
                          </div>
                        );
                      })}

                      <div style={{ textAlign: "center" }}>
                        <button
                          style={{ background: "#3298db", color: "white" }}
                          className="btn"
                          onClick={(e) => addNewFields(e)}
                          disabled={
                            duplicateProject === undefined ? true : false
                          }
                        >
                          Add more field(s)
                        </button>
                      </div>
                      {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>Files</label>
                        <br />
                        <br />
                        <input
                          // placeholder="$50"
                          className="form-control"
                          type="file"
                        />
                      </div>
                    </div> */}

                      <br />
                      {/* <div className="row">
                    <h4>
                      <b> Invite User</b>
                    </h4>
                    <br />
                    <br />
                    {userDetails.map((input, index) => (
                      <div className=" row col-md-12">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>User Email</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label>User Name</label>
                            <input
                              // placeholder="$50"
                              className="form-control"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4" style={{ display: "flex" }}>
                          <div className="form-group col-sm-11">
                            <label>Role</label>
                            <input
                              // placeholder="$50"
                              className="form-control"
                              type="text"
                            />
                          </div>
                          <div
                            className="form-group col-sm-1"
                            onClick={() => removeFields(index)}
                          >
                            <img src={deleteicon} />
                          </div>
                        </div>
                      </div>
                    ))}
                    <button
                      onClick={(e) => addFields(e)}
                      className="add-btn-add"
                      style={{ width: "110px" }}
                    >
                      <p>
                        {" "}
                        <img src={userAdd} /> Add User
                      </p>
                    </button>
                  </div> */}
                      <div className="submit-section">
                        <button
                          style={{ background: "#3298db", color: "white" }}
                          className="btn"
                          disabled={
                            duplicateProject === undefined ? true : false
                          }
                          data-bs-dismiss={
                            duplicateProject?.name !== "" &&
                            duplicateProject?.description !== "" &&
                            duplicateProject?.ending !== "" &&
                            duplicateProject?.teamleads?.length !== 0 &&
                            duplicateProject?.members?.length !== 0
                              ? "modal"
                              : ""
                          }
                          onClick={(e) => handleDuplicateSubmit(e)}
                        >
                          Submit
                        </button>
                      </div>
                      {/*</form>*/}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* /Create Project Modal */}
            {/* Edit Project Modal */}
            <Editproject
              projectDet={projectDet}
              setProjectDet={setProjectDet}
              enddate={enddate}
              setEnddate={setEnddate}
              empList={empList}
              setEmpList={setEmpList}
              more={more}
              setMore={setMore}
              handleGetProjects={handleGetProjects}
              viewState={viewState}
              setViewState={setViewState}
            />

            {/* /Delete Project Modal */}
            <div
              className="modal custom-modal fade"
              id="delete_project"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Project</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row">
                        <div className="col-6">
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            className="continue-btn"
                            onClick={(e) => handleDelete(e)}
                          >
                            Delete
                          </a>
                        </div>
                        <div className="col-6">
                          <a
                            href="#"
                            data-bs-dismiss="modal"
                            className="cancel-btn"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Project Modal */}

            {/* /Delete Project Modal */}

            {/* viewProject */}
            {/* <div
          id="view_project"
          className="modal custom-modal fade"
          role="dialog"
          // style={{ marginTop: "50px"}}
        >
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header" >
                    <h5 className="modal-title"  >
                      Project Details                      
                    </h5>
                    <button
                          type="button"
                          className="close-btn"
                          data-bs-dismiss="modal"
                    >
                      <img src={closeIcon} />
                    </button>                     
                </div>
                <div className="modal-body" style={{ display: "flex", justifyContent: "center" }}>
                  <div className="row col-sm-12" >
                    <div className="row col-sm-12">
                          <h4>Project Name</h4>
                          <p>{projectDet.name}</p>
                    </div>
                    <div className="row col-sm-12">
                          <h4>Project Description</h4>

                          <p>{projectDet.description}</p>
                    </div>
                    <div className="row col-sm-6">
                          <h4>Start Date</h4>
                          <p>{`${new Date(
                            projectDet.creation_date
                          ).getDate()}-${new Date(
                            projectDet.creation_date
                          ).getMonth()}-${new Date(
                            projectDet.creation_date
                          ).getFullYear()}`}</p>
                    </div>
                    <div className="row col-sm-6">
                          <h4>End Date</h4>

                          <p>{`${new Date(projectDet.deadline).getDate()}-${new Date(
                            projectDet.deadline
                          ).getMonth()}-${new Date(
                            projectDet.deadline
                          ).getFullYear()}`}</p>
                    </div>
                    <div className="row col-sm-12">
                          <h4>Drive Link</h4>

                          <p>
                            <Link>{projectDet.drive_link}</Link>
                          </p>
                    </div>
                    <div className="row col-sm-6">
                          <h4>Project Creator</h4>
                          <p>{projectDet.creator.name}</p>
                    </div>
                    <div className="row col-sm-6">
                          <h4>Team Lead</h4>

                          <p>{projectDet.teamleads[0].name}</p>
                    </div>
                    <div className="row col-sm-12">
                          <h4>Team Members</h4>
                          <TagGroup>
                            {projectDet.members.map((ele) => (
                              <SimpleTag text={ele.name} />
                            ))}
                          </TagGroup>
                    </div>
                  </div>  
                </div>                
            </div>
          </div>
        </div> */}
            {/* <div
          className="modal custom-modal fade  "
          id="view_project"
          role="dialog"
          style={{ marginTop: "50px" }}
        >
          <div
            className="modal-dialog  modal-lg"
            role="document"
            // onClose={closeModal}
            shouldScrollInViewport={shouldScrollInViewport}
            height={600}
            // width="medium"
            style={{ marginTop: "50px" }}
          >
            <div className="modal-content">
              <div
                className="modal-header"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderBottom: " 2px solid #cccccc",
                }}
              >
                <h5 className="modal-title">Project Details</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div
                className="modal-body"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="row col-sm-12">
                  <div className="row col-sm-12">
                    <h4>Project Name</h4>
                    <p>{projectDet?.name}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Project Description</h4>

                    <p>{projectDet?.description}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Start Date</h4>
                    <p>{`${new Date(
                      projectDet?.creation_date
                    ).getDate()}-${new Date(
                      projectDet?.creation_date
                    ).getMonth()}-${new Date(
                      projectDet?.creation_date
                    ).getFullYear()}`}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>End Date</h4>

                    <p>{`${new Date(projectDet?.deadline).getDate()}-${new Date(
                      projectDet?.deadline
                    ).getMonth()}-${new Date(
                      projectDet?.deadline
                    ).getFullYear()}`}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Drive Link</h4>

                    <p>
                      <Link>{projectDet?.drive_link}</Link>
                    </p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Project Creator</h4>

                    <p>{projectDet?.creator.name}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Team Lead</h4>

                    <p>{projectDet?.teamleads[0].name}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Team Members</h4>
                    <TagGroup>
                      {projectDet?.members.map((ele) => (
                        <SimpleTag text={ele.name} />
                      ))}
                    </TagGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            {/* viewProject */}

            {/* <ModalTransition style={{ marginTop: "50px !important" }}>
          {open && (
            <Modal
              onClose={closeModal}
              shouldScrollInViewport={shouldScrollInViewport}
              height={600}
              width="medium"
              style={{ marginTop: "50px",border: "1px solid" }}
              // css={boldStyles}
            > */}
            {/* <ModalHeader>
                <ModalTitle
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  
                  <span css={boldStyles}>Project Details</span>
                </ModalTitle>
              </ModalHeader> */}
            {/* <ModalBody style={{ display: "flex", justifyContent: "center" }}>
                <div className="row col-sm-12" >
                  <div className="row col-sm-12">
                    <h4>Project Name</h4>
                    <p>{projectDet.name}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Project Description</h4>

                    <p>{projectDet.description}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Start Date</h4>
                    <p>{`${new Date(
                      projectDet.creation_date
                    ).getDate()}-${new Date(
                      projectDet.creation_date
                    ).getMonth()}-${new Date(
                      projectDet.creation_date
                    ).getFullYear()}`}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>End Date</h4>

                    <p>{`${new Date(projectDet.deadline).getDate()}-${new Date(
                      projectDet.deadline
                    ).getMonth()}-${new Date(
                      projectDet.deadline
                    ).getFullYear()}`}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Drive Link</h4>

                    <p>
                      <Link>{projectDet.drive_link}</Link>
                    </p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Project Creator</h4>

                    <p>{projectDet.creator.name}</p>
                  </div>
                  <div className="row col-sm-6">
                    <h4>Team Lead</h4>

                    <p>{projectDet.teamleads[0].name}</p>
                  </div>
                  <div className="row col-sm-12">
                    <h4>Team Members</h4>
                    {/* <TagGroup>
                      {projectDet.members.map((ele) => (
                        <Tag text={ele.name} />
                      ))}
                    </TagGroup> 
                    <TagGroup>
                      {projectDet.members.map((ele)=>(
                        <SimpleTag text={ele.name}/>
                      ))}
                    </TagGroup>
                  </div>
                </div>
              </ModalBody> */}
            {/* <ModalFooter>
                <Button appearance="primary" onClick={closeModal}>
                  Close
                </Button>
              </ModalFooter> */}
            {/* </Modal>
          )}
        </ModalTransition>  */}
          </>
        )}
      </div>
    </div>
  );
};

export default Projects;
