import React from "react";
import "./ForgotPass.css";
import Form from "react-bootstrap/Form";
// import oz from "../../assets/img/profiles/OZHRMS.svg";
import oz1 from "../../assets/img/profiles/newOz.png";
// import verify from "../../assets/img/profiles/verify.png";
// import { Link, useNavigate } from "react-router-dom";
// import loginimg from "../../assets/img/profiles/login1.png";
// import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
// import axios from "axios";
import { useState, useRef, useEffect } from "react";
import IconButton from '@mui/material/IconButton';
// import Swal from "sweetalert2";
// import ClientCaptcha from "react-client-captcha";
// import "react-client-captcha/dist/index.css";
// import OutlinedInput from '@mui/material/OutlinedInput';
// import Input from '@mui/material/Input';
// import FormControl from '@mui/material/FormControl';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from "axios"
import Swal from "sweetalert2";
// import { resetWarningCache } from "prop-types";
import { useNavigate } from "react-router-dom";



const ForgotPass = ()=>{
    const [formData, setFormData] = useState({
        //company: null,
        empid: "",
        new_pass: "",
        conf_pass:""
    });

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const navigate=useNavigate()

    const ForgotFunc=()=>{

        if(formData.new_pass!==formData.conf_pass){
            Swal.fire({
                icon:"error",
                text:"New password and confirm password should match"
            })
        }else{
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/change_password/`,{
                "empid":formData.empid,
                "new_pass":formData.new_pass
                        }).then(()=>{
                            Swal.fire({
                                icon:"success",
                                // title: 'Auto close alert!',
                                text: 'Your password changed successfully and also mailed to registered email id',
                                // timer: 2000
                              })
                              navigate("/")
                              
                        })
        }
        
    }

    return (
        <div className="row main-container">
            <div className="row col-md-8 login-img"></div>
            <div className="row col-md-4 login-form">
                <div>
                    <div className="content1">
                        <img src={oz1} className="oz-logo" />
                        <br /><br/>
                        <div className="login-con">
                            <p className="login-text">Forgot Password</p>
                            <TextField
                                id="filled-textarea"
                                label="User ID"
                                sx={{
                                width: { sm: "95%", md: "95%" },
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                }}
                                value={formData.empid}
                                onChange={(e) =>
                                    setFormData({ ...formData, empid: e.target.value })
                                }
                                // placeholder="Placeholder"
                                // multiline
                                variant="standard"
                            />
                            <br/><br/>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                id="filled-textarea"
                                label="New Password"
                                marginRight="0px"
                                InputProps={{
                                endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    style={{background:"none",color:"#18838d", marginBottom:"23px",width:"30px", height:"50 !important", padding:"5px 0px", marginRight:"0px"}}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>,
                                }}
                            
                                // placeholder="Placeholder"
                                sx={{
                                width: { sm: "95%", md: "95%" },
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                }}
                                value={formData.new_pass}
                                onChange={(e) =>
                                    setFormData({ ...formData, new_pass: e.target.value })
                                }
                                // multiline
                                variant="standard"
                            />
                            <br/>
                            <br />  
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                id="filled-textarea"
                                label="Confirm Password"
                                marginRight="0px"
                                InputProps={{
                                endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    style={{background:"none",color:"#18838d", marginBottom:"23px",width:"30px", height:"50 !important", padding:"5px 0px", marginRight:"0px"}}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>,
                                }}
                            
                                // placeholder="Placeholder"
                                sx={{
                                width: { sm: "95%", md: "95%" },
                                "& .MuiInputBase-root": {
                                    height: 40,
                                },
                                }}
                                value={formData.conf_pass}
                                onChange={(e) =>
                                    setFormData({ ...formData, conf_pass: e.target.value })
                                }
                                // multiline
                                variant="standard"
                            /> 
                            <br/>                       
                        </div>
                        <button
                                style={{ fontSize: "15px" }}
                                type="submit"
                                onClick={() => ForgotFunc()}
                                >
                                {" "}
                                {/* <Link style={{ textDecoration: "none",color:"white" }} to="/dashboard"> */}
                                {/* {isLoading ? <LoadingSpinner /> : "Save"} */}
                                Submit
                                {/* </Link> */}
                        </button>                         
                    </div>
                    <a href="/" className="d-flex justify-content-center mt-3"> Go to Login</a>
                </div>
            </div>
        </div>

        // <div className=" row main-container ">
        //   <div className=" row col-md-8 login-img"></div>
        //   <div className=" row col-md-4 login-form">
        //     <div>
        //       <div className="content1">
        //         {/* <h1>Human Resource Management System</h1>
        //         <p>Access to dashboard</p> */}
        //         <img src={oz1} className="oz-logo" />
    
        //         <br />
        //         <br />
        //         <div className="login-con">
        //           <p className="login-text">Login</p>
    
        //           {/* <label>Company ID</label>
        //         <input
        //           type="text"
        //           required
        //           className="login-input"
        //           value={formData.company}
        //           onChange={(e) =>
        //             setFormData({ ...formData, company: parseInt(e.target.value) })
        //           }
        //         /> */}
        //           {/* <TextField
        //             id="filled-textarea"
        //             label="Company ID"
        //             // style={{ marginleft: "-20px" }}
        //             sx={{
        //               width: { sm: "95%", md: "95%" },
        //               "& .MuiInputBase-root": {
        //                 height: 40,
        //               },
        //             }}
        //             value={formData.company}
        //             onChange={(e) =>
        //               setFormData({
        //                 ...formData,
        //                 company: parseInt(e.target.value),
        //               })
        //             }
        //             // placeholder="Placeholder"
        //             // multiline
        //             variant="standard"
        //           /> */}
        //           <br />
    
        //           {/* <label>User ID</label>
        //         <input
        //           type="email"
        //           required
        //           className="login-input"
        //           value={formData.empid}
        //           onChange={(e) =>
        //             setFormData({ ...formData, empid: e.target.value })
        //           }
        //         /> */}
        //           <TextField
        //             id="filled-textarea"
        //             label="User ID"
        //             sx={{
        //               width: { sm: "95%", md: "95%" },
        //               "& .MuiInputBase-root": {
        //                 height: 40,
        //               },
        //             }}
        //             value={formData.empid}
        //             onChange={(e) =>
        //               setFormData({ ...formData, empid: e.target.value })
        //             }
        //             // placeholder="Placeholder"
        //             // multiline
        //             variant="standard"
        //           />
        //           <br />
    
        //           {/* <label>Password</label>
        //         <input
        //           type="password"
        //           required
        //           className="login-input"
        //           value={formData.password}
        //           onChange={(e) =>
        //             setFormData({ ...formData, password: e.target.value })
        //           }
        //         /> */}
        //           <TextField
        //             type={showPassword ? 'text' : 'password'}
        //             id="filled-textarea"
        //             label="Password"
        //             marginRight="0px"
        //             InputProps={{
        //               endAdornment: <InputAdornment position="end">
        //               <IconButton
        //                 aria-label="toggle password visibility"
        //                 onClick={handleClickShowPassword}
        //                 onMouseDown={handleMouseDownPassword}
        //                 edge="end"
        //                 style={{background:"none",color:"#18838d", marginBottom:"23px",width:"30px", height:"50 !important", padding:"5px 0px", marginRight:"0px"}}
        //               >
        //                 {showPassword ? <VisibilityOff /> : <Visibility />}
        //               </IconButton>
        //             </InputAdornment>,
        //             }}
                   
        //             // placeholder="Placeholder"
        //             sx={{
        //               width: { sm: "95%", md: "95%" },
        //               "& .MuiInputBase-root": {
        //                 height: 40,
        //               },
        //             }}
        //             value={formData.password}
        //             onChange={(e) =>
        //               setFormData({ ...formData, password: e.target.value })
        //             }
        //             // multiline
        //             variant="standard"
        //           />
        //           <br/>
    
        //           <br />
    
        //           <div style={{ display: "flex", marginLeft: "60px" }}>
        //             <p style={{ marginTop: "15px" }}>Verification Code</p>
        //             &nbsp;&nbsp;&nbsp;
        //             {/* <img src={verify} alt="verify" /> */}
        //             <ClientCaptcha
        //               //ref={captchaRef}
        //               captchaCode={setCode}
        //               charsCount={6}
        //               retry={true}
        //               width={150}
        //               retryIconSize={20}
        //             />
        //           </div>
        //           <br />
        //           {showError && <p style={{ color: "red" }}>Incorrect Captcha!</p>}
        //           {/* {showError && captchaCode.retry()} */}
        //           {/* <label>Enter Verification Code</label>
    
        //         <input
        //           type="text"
        //           className="login-input"
        //           value={captchaInput}
        //           onChange={(e) => setCaptchaInput(e.target.value)}
        //         /> */}
        //           <TextField
        //             id="filled-textarea"
        //             label="Verification Code"
        //             autoComplete="off"
        //             // placeholder="Placeholder"
        //             sx={{
        //               width: { sm: "95%", md: "95%" },
        //               "& .MuiInputBase-root": {
        //                 height: 40,
        //               },
        //             }}
        //             value={captchaInput}
        //             onChange={(e) => setCaptchaInput(e.target.value)}
        //             // multiline
        //             variant="standard"
        //           />
        //           <br />
        //         </div>
        //         <button
        //           style={{ fontSize: "15px" }}
        //           type="submit"
        //           onClick={() => Submit()}
        //         >
        //           {" "}
        //           {/* <Link style={{ textDecoration: "none",color:"white" }} to="/dashboard"> */}
        //           {isLoading ? <LoadingSpinner /> : "Login"}
        //           {/* </Link> */}
        //         </button>     
                       
        //       </div>
        //       <a href="#" className="d-flex justify-content-center mt-3">Forget Password?</a>
        //     </div>
        //   </div>
        // </div>
    );

}

export default ForgotPass;
