import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import pdflogo from "../../assets/img/profiles/Adobe-PDF-File-Icon-01.svg";
import axios from "axios";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import Upload from "../../assets/img/profiles/upload.png";
import Swal from "sweetalert2";
import empty from "../../assets/img/profiles/browser.png";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Typography } from "@mui/material";

const Guidelines = () => {
  const [pdfFile, setPdfFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [menu, setMenu] = useState(false);
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addDocu, setAddDocu] = useState({
    file_name: "",
    document: {},
  });
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/campany_guidelines/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
        setGuides(res.data);
        setIsLoading(false);
        //  setLeaves(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Guidelines</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons"></div>
              </div>
            </div>
          </div>
          {!isLoading && (
            <>
              {guides?.length > 0 ? (
                <>
                  {viewPdf ? (
                    <div
                      style={{
                        height: "100%",
                        background: "white",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        padding: "8px",
                        textAlign: "right",
                      }}
                    >
                      <CancelIcon
                        sx={{ color: "#2E86C1", cursor: "pointer" }}
                        onClick={() => setViewPdf(false)}
                      />
                      <br />

                      <div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                          <Viewer
                            fileUrl={
                              pdfFile
                                ? pdfFile
                                : "/assets/pdf-open-parameters.pdf"
                            }
                          />
                        </Worker>
                      </div>
                    </div>
                  ) : (
                    <>
                      {guides.map((ele) => {
                        return (
                          <>
                            <div
                              className="col-sm-12 row"
                              style={{
                                height: "70px",
                                background: "white",
                                display: "flex",
                                alignItems: "center",
                                //justifyContent: "space-between",
                                borderRadius: "8px",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  padding: "16px",
                                }}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <img
                                    src={pdflogo}
                                    style={{ height: "35px", width: "35px" }}
                                  />
                                  &nbsp;
                                  <Typography variant="subtitle1">
                                    {ele.doc_name}
                                  </Typography>
                                </Box>

                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Button
                                    sx={{ textTransform: "none" }}
                                    onClick={() => {
                                      setViewPdf(true);
                                      setPdfFile(ele.link.links);
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{ color: "blue" }}
                                    >
                                      View
                                    </Typography>
                                  </Button>
                                  &emsp;
                                  <a
                                    href={ele.link.links}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Button sx={{ textTransform: "none" }}>
                                      <Typography
                                        variant="body1"
                                        sx={{ color: "blue" }}
                                      >
                                        Download
                                      </Typography>
                                    </Button>
                                  </a>
                                </Box>
                              </Box>

                              {/*
                          <div
                            className="col-sm-12"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              className="col-sm-1"
                              style={{ marginLeft: "15px" }}
                            >
                              <img
                                src={pdflogo}
                                style={{ height: "35px", width: "35px" }}
                              />
                            </div>
                            <div
                              className="col-sm-8"
                              style={{
                                marginLeft: "10px",
                                marginRight: "20px",
                              }}
                            >
                              <span
                                style={{
                                  flexDirection: "row",
                                  fontSize: "20px",
                                }}
                              >
                                {ele.doc_name}
                              </span>
                            </div>
                            <div
                              className=" row col-sm-2"
                              style={{
                                float: "end",
                                marginTop: "4px",
                                fontSize: "18px",
                              }}
                            >
                              {" "}
                              <a
                                className="row col-sm-6"
                                style={{ color: "blue" }}
                                href={ele.link.links}
                                target="_blank"
                                rel="noopener noreferrer"
                              // onClick={()=>{console.log(ele.link)}}
                              >
                                View
                              </a>{" "}
                              &nbsp;&nbsp;&nbsp;
                              <div className=" row col-sm-2">
                                <a
                                  style={{ color: "blue" }}
                                  className=" row col-sm-6"
                                  // target="_blank"
                                  href={ele.link.links}
                                  download
                                >
                                  Download
                                </a>
                              </div>
                            </div>{" "}
                          </div>
                          */}
                            </div>
                            <br />
                          </>
                        );
                      })}
                    </>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="card "
                    style={{
                      height: "400px",
                      width: "450px",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "150px",
                        height: "150px",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      src={empty}
                    />
                    <br />
                    <h4>No Guidelines to show</h4>
                    {/* <h6>Click on Create Project to create a new project</h6> */}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};
export default Guidelines;
