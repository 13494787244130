import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import Select from "react-select";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";

import search from "../../../../assets/img/profiles/search-line.png";
import FilterOff from "../../../../assets/img/profiles/filter-off-fill1.svg";
// import Button from "@mui/material/Button";
import PayrollHistory from "./PayrollHistory";
import axios from "axios";
import { Table } from "ant-table-extensions";
import Swal from "sweetalert2";
import {
  itemRender,
  onShowSizeChange,
} from "../../../../components/paginationfunction";

const OnHoldPayroll = () => {
  const [menu, setMenu] = useState(false);
  const [empPay, setEmpPay] = useState([]);
  const [showDown, setShowDown] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dyCol, setDyCol] = useState([]);
  const [name1, setName1] = useState("");
  const [type1, setType1] = useState("");
  const [department1, setDepartment1] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const handleGetPayroll = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          toggle: "t5",
          comp_id: sessionStorage.getItem("coid"),
          month: month.split("-")[1],
          year: month.split("-")[0],
        },
      })
      .then((res) => {
        setDyCol(res.data.columns);
        setEmpPay(
          res.data.data.map((ele) => ({
            enabled: false,
            key: ele.employee_id,
            ...ele,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetPayroll();
  }, [month]);

  const handleReleasePayroll = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        // month: month.split("-")[1],
        // year: month.split("-")[0],
        empl_id: selectedRowKeys,
        comp_id: sessionStorage.getItem("coid"),
        toggle: "released",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "Payroll Released Successfully Selected Employees",
          // timer: 3000,
        });
        setSelectedRowKeys([]);
        handleGetPayroll();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    if (name1 === "" && type1 === "" && department1 === "") {
      setSearchData(empPay);
    }
  });

  const handleSearch1 = () => {
    const newData = empPay
      .filter((ele) => {
        return name1.toLocaleLowerCase() === ""
          ? ele
          : ele.employee_name
              .toLocaleLowerCase()
              .includes(name1.toLocaleLowerCase()) ||
            name1.toLocaleLowerCase() === ""
          ? ele
          : String(ele.employee_id)
              .toLocaleLowerCase()
              .includes(name1.toLocaleLowerCase());
      })
      .filter(
        (y) => y.department == (department1 == "" ? y.department : department1)
      )
      .filter(
        (z) => z.employement_type == (type1 == "" ? z.employement_type : type1)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch1();
  }, [name1, department1, type1]);

  const columns1 = dyCol.map((ele) => {
    if (ele.key === "employee_name") {
      return {
        title: ele.name,
        width: 200,
        dataIndex: ele.key,
        key: ele.key,
        align: "left",
        fixed: "left",
      };
    } else if (ele.key === "employee_id") {
      return {
        title: ele.name,
        width: 100,
        dataIndex: ele.key,
        key: ele.key,
        align: "left",
        fixed: "left",
      };
    } else {
      return {
        title: ele.name,
        width: 180,
        dataIndex: ele.key,
        key: ele.key,
        align: "right",
      };
    }
  });

  console.log(columns1);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="col-md-12 row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">&nbsp; Hold Payroll</h3>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div className="col-sm-6 col-md-5">
                <div className="form-group form-focus select-focus">
                  {/* <Select
            inputId="single-select-example"
            className="single-select"
            classNamePrefix="react-select"
            placeholder="Employee Name"
            options={[...new Set(empPay?.map((item) => item.emp_name))].map(
              (ele) => ({
                label: ele,
                value: ele,
              })
            )}
            value={
              name1 !== ""
                ? {
                    label: name1,
                    value: name1,
                  }
                : null
            }
            onChange={(e) => {
              setName1(e.value);
            }}
           
            style={{
              zindex: "5",
              height: "50px",
              width: "100%",
              border: "1px solid lightgrey",
              borderRadius: "3px",
            }}
          
          /> */}
                  <input
                    className="form-group col-md-10"
                    style={{
                      height: "38px",
                      paddingLeft: "20px",
                      marginTop: "1px",
                      borderRadius: "5px",
                    }}
                    placeholder="Search by name and employee id"
                    value={name1}
                    onChange={(e) => setName1(e.target.value)}
                  />
                  <button
                    className="col-md-2"
                    style={{
                      background: "#393f5c",
                      padding: "7px",
                      border: "none",
                      borderRadius: "2px",
                    }}
                  >
                    <img src={search} />
                  </button>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus select-focus">
                  <Select
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    placeholder="Employee Type"
                    isClearable
                    options={[
                      ...new Set(empPay?.map((ele) => ele.employement_type)),
                    ].map((ele) => {
                      return {
                        label: ele,
                        value: ele,
                      };
                    })}
                    value={
                      type1 !== ""
                        ? {
                            label: type1,
                            value: type1,
                          }
                        : null
                    }
                    onChange={(e) => {
                      setType1(e ? e.value : "");
                    }}
                    style={{
                      zindex: "5",
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="form-group form-focus select-focus">
                  <Select
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    placeholder="Department"
                    isClearable
                    options={[
                      ...new Set(empPay?.map((ele) => ele.department)),
                    ].map((ele) => {
                      return {
                        label: ele,
                        value: ele,
                      };
                    })}
                    value={
                      department1 !== ""
                        ? {
                            label: department1,
                            value: department1,
                          }
                        : null
                    }
                    onChange={(e) => {
                      setDepartment1(e ? e.value : "");
                    }}
                    // options={[
                    //   ...new Set(empPayrollDetails?.map((item) => item.emp_name)),
                    // ].map((ele) => ({
                    //   label: ele,
                    //   value: ele,
                    // }))}
                    // value={
                    //   name1 !== ""
                    //     ? {
                    //         label: name1,
                    //         value: name1,
                    //       }
                    //     : null
                    // }
                    // onChange={(e) => setName1(e.value)}
                    style={{
                      zindex: "5",
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-1">
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "30px",
                    // backgroundColor: "#3298DB",
                    padding: "1.5px 5px 2px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                  onClick={() => (
                    setType1(""), setDepartment1(""), setName1("")
                  )}
                />

                {/* <button
          style={{
            height: "38px",
            backgroundColor: "#0EAA00",
            padding: "1px 25px 2px 25px",
            borderRadius: "5px",
            marginLeft: "10px",
            border: "none",
            color: "white",
          }}
        >
          <b>RUN PAYROLL</b>
        </button> */}
                {/* <button
          style={{
            height: "38px",
            backgroundColor: "#3298DB",
            padding: "1px 15px 2px 15px",
            borderRadius: "5px",
            border: "none",
            color: "white",
            marginLeft: "20px",
          }}
        >
          <CiExport style={{ marginRight: "7px", fontSize: "22" }} />
          EXPORT
        </button> */}
              </div>
              <div className="col-md-12">
                <div className="card mb-0 ">
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      // marginBottom: "-48px",
                      marginRight: "10px",
                      justifyContent: "end",
                    }}
                  >
                    <input
                      style={{
                        width: "150px",
                        borderRadius: "5px",
                        height: "30px",
                        padding: "10px",
                      }}
                      type="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                  <div className="card-body">
                    {selectedRowKeys.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                        }}
                      >
                        <div className="regularize-part">
                          <button
                            style={{
                              border: "none",
                              outline: "none",
                              padding: "6px 30px",
                              background: "#2B8F6F",
                              color: "#fff",
                              borderRadius: "6px",
                            }}
                            onClick={(e) => handleReleasePayroll(e)}
                          >
                            Release Payroll
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="table-responsive">
                      <Table
                        // className="table-striped"
                        // searchable
                        rowClassName={(record, index) =>
                          index % 2 === 0 ? "table-row-light" : "table-row-dark"
                        }
                        rowSelection={rowSelection}
                        exportable
                        exportableProps={{ showColumnPicker: true }}
                        columns={columns1}
                        // columns={[
                        //   ...columns1,
                        //   {
                        //     title: "Action",
                        //     key: "action",
                        //     dataIndex: "employee_id",
                        //     hidden: true,
                        //     // fixed: "right",
                        //     render: (text, record) => (
                        //       <button className="p-btn">View Payslip</button>
                        //     ),
                        //   },
                        // ]}
                        dataSource={searchData}
                        pagination={{
                          total: searchData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        scroll={{ x: 1300 }}
                        loading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnHoldPayroll;
