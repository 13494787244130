import React, { useState, useEffect } from "react";
import Header from "../../pages/Sidebar/header";
import happylogo from "../../assets/img/profiles/happy.svg";
import Cards from "../../pages/cards/cards";
import { trending } from "../../Constants/happyZoneConstant";
import Sidebar from "../../pages/Sidebar/sidebar";
import laugh from "../../assets/img/profiles/laugh1.svg";
import calm from "../../assets/img/profiles/calm1.svg";
import stress from "../../assets/img/profiles/stress1.svg";
import busy from "../../assets/img/profiles/work1.svg";
import angry from "../../assets/img/profiles/angry1.svg";
import happy from "../../assets/img/profiles/happy1.svg";
import Pantry from "../../assets/img/profiles/pantry.svg";
import Emergency from "../../assets/img/profiles/emergency.svg";
import Tea from "../../assets/img/profiles/tea-cup.svg";
import GreenTea from "../../assets/img/profiles/green-tea.svg";
import Coffee from "../../assets/img/profiles/coffee-cup.svg";
import Nachos from "../../assets/img/profiles/nachos.svg";
import Biscuit from "../../assets/img/profiles/cookies.svg";
import SmileEmoji from "../../assets/img/profiles/smilie-emoji.gif";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import YouTube from "react-youtube";
import { FaMinus, FaPlus } from "react-icons/fa";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Swal from "sweetalert2";
import axios from "axios";
import ReactPlayer from "react-player";
import closeIcon from "../../assets/img/profiles/whiteclose.svg";

const HappyZone = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState({
    videos: [
      {
        id: 21,
        name: "Never Give Up",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/X_sI-qjUIY4",
        img_url: "",
        description:
          "A motivational video that talks about the importance of not giving up, being in the mindset of self-discipline and staying motivated/",
      },
      {
        id: 22,
        name: "One of the Greatest Speeches Ever by Steve Jobs",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/Tuw8hxrFBH8",
        img_url: "",
        description:
          "A TED talk by Steve Jobs, where he talks about the general sense of motivation and never giving up. If you are feeling low and stressed, try watching this video, and it might change the way you feel.",
      },
      {
        id: 23,
        name: "Sundar Pichai's Eye Opening Speech",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/8Aux7nqu6w8",
        img_url: "",
        description:
          "Hear Sundar Pichai talk about his experiences, and why changes are important.",
      },
      {
        id: 24,
        name: "Control Your Life",
        content_type: "VIDEO",
        emotion_type: "STRESS",
        video_url: "https://youtu.be/3gKYFWVIHus",
        img_url: "",
        description:
          "Why is taking control of your life so important? A speech that teaches the importance of self-control and how you can be the best version of yourself.",
      },
      {
        id: 29,
        name: "10 Things That Shows You are Smart, According to Experts",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/J4xQec5I_sw",
        img_url: "",
        description:
          "It talks about Topics Ranging From Health & welness To Human Relationships, Self Development, Motivation & Fun",
      },
      {
        id: 30,
        name: "Jack Ma - Learn From The Mistakes",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/K99sC9e_psY",
        img_url: "",
        description:
          "Learn the attitude you should have to be success in building your own business",
      },
      {
        id: 31,
        name: "Game of Life by Sandeep Maheshwari",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/3TfRSGrEE6I",
        img_url: "",
        description:
          "Teaching us how to play the game of life with both success & failures",
      },
      {
        id: 32,
        name: "4 Minutes To Start Your Day Right",
        content_type: "VIDEO",
        emotion_type: "CALM",
        video_url: "https://youtu.be/HgiiY9TLtX8",
        img_url: "",
        description: "Morning Motivation and Positivity",
      },
    ],
    itemsToShow: 4,
    expanded: false,
  });
  const [loading, setLoading] = useState(false);
  const [currVideo, setCurrVideo] = useState("");
  const [pageSwitch, setPageSwitch] = useState(true);
  const [mood, setMood] = useState("");
  const [tea, setTea] = useState(0);
  const [changeTheme, setChangeTheme] = useState({
    background: "black",
    color: "white",
  });
  const token = sessionStorage.getItem("access_token");
  const [source, setSource] = useState();
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  //console.log(source);

  const emotionContent = (e, moods) => {
    e.preventDefault();
    setData([]);
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_ADD_URL}/happyZone/hzContent/`, {
        params: {
          emotion_type: moods,
          content_limit: 6,
          content_type: "VIDEO",
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   title: "oops..",
        //   text: "Something Went Wrong",
        // });
        setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_ADD_URL}/happyZone/hzContent/`, {
        // params: {
        //   empid: sessionStorage.getItem("emp_id"),
        // },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        // Swal.fire({
        //   icon: "error",
        //   title: "oops..",
        //   text: "Something Went Wrong",
        // });
        setLoading(false);
      });
  }, []);
  console.log(data);

  const showMore = (e) => {
    e.preventDefault();
    // console.log("hello");
    data?.itemsToShow === 4
      ? setData({
          ...data,
          itemsToShow: data?.videos.length,
          expanded: true,
        })
      : setData({ ...data, itemsToShow: 4, expanded: false });
  };

  // useEffect(() => {
  //   emotionContent();
  // }, [mood]);

  const opts = {
    height: "500",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      style={{ background: "#262339" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <div
        className="happyzone-container"
        // style={{ backgroundColor: changeTheme.background }}
      >
        {/* <img src={happylogo} style={{ height: "20%", width: "100%" }} /> */}
        {pageSwitch ? (
          <div className="row">
            <div className="col-md-12 top-happy-div">
              <div style={{ display: "flex" }}>
                <img
                  style={{ width: "78px", height: "78px" }}
                  src={SmileEmoji}
                />
                <div style={{ marginLeft: "20px", marginTop: "30px" }}>
                  <h1 className="welcome-bold">Welcome to Happy Zone!</h1>
                  <h5
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "10px",
                      maxWidth: "500px",
                      fontWeight: "600",
                      fontFamily: "Kodchasan",
                    }}
                  >
                    Hey, User! How are you feeling today? I will try to bring in
                    some happiness to increase your productivity while working.
                  </h5>
                </div>
              </div>
              <br />
              {/* <div className="progress-div">
              {" "}
              <div
                className="progress_div_fill"
                style={{
                  width:
                    mood === "STRESS" ? "0%" : mood === "happy" ? "20%" : "0%",
                }}
              ></div>{" "}
            </div> */}
              <div className="smile">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={happy}
                    style={{
                      width: mood === "HAPPY" ? "85px" : "47px",
                      height: mood === "HAPPY" ? "85px" : "43px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setMood("HAPPY");
                      emotionContent(e, "HAPPY");
                    }}
                    className=""
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      fontWeight: mood === "HAPPY" ? 900 : 600,
                    }}
                  >
                    Happy
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: mood === "CALM" ? "85px" : "47px",
                      height: mood === "CALM" ? "85px" : "47px",
                      cursor: "pointer",
                    }}
                    src={calm}
                    onClick={(e) => {
                      setMood("CALM");
                      emotionContent(e, "CALM");
                    }}
                    className=""
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      fontWeight: mood === "CALM" ? 900 : 600,
                    }}
                  >
                    Calm
                  </p>
                </div>
                <div>
                  <img
                    src={busy}
                    style={{
                      width: mood === "BUSY" ? "95px" : "64px",
                      height: mood === "BUSY" ? "85px" : "49px",
                      marginTop:
                        mood === "" ? "0px" : mood !== "BUSY" ? "20px" : "0px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setMood("BUSY");
                      emotionContent(e, "BUSY");
                    }}
                    className=""
                  />
                  <p
                    style={{
                      marginTop: mood === "BUSY" ? "0px" : "10px",
                      marginLeft: mood === "BUSY" ? "20px" : "10px",
                      fontWeight: mood === "BUSY" ? 900 : 600,
                    }}
                  >
                    Busy
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={stress}
                    style={{
                      width: mood === "STRESS" ? "105px" : "57px",
                      height: mood === "STRESS" ? "85px" : "47px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setMood("STRESS");
                      emotionContent(e, "STRESS");
                    }}
                    className=""
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      fontWeight: mood === "STRESS" ? 900 : 600,
                    }}
                  >
                    Stressed
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={angry}
                    style={{
                      width: mood === "ANGRY" ? "85px" : "47px",
                      height: mood === "ANGRY" ? "85px" : "43px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setMood("ANGRY");
                      emotionContent(e, "ANGRY");
                    }}
                    className=""
                  />
                  <p
                    style={{
                      marginTop: "10px",
                      fontWeight: mood === "ANGRY" ? 900 : 600,
                    }}
                  >
                    Angry
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              id="video"
              className="card mb-0"
              style={{
                background: "rgba(255,255,255, 0.3)",
                width: "80%",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20px",
              }}
            >
              <div className="card-body">
                <button
                  style={{
                    position: "relative",
                    marginLeft: "97%",
                    marginBottom: "1%",
                  }}
                  type="button"
                  className="close-btn"
                  onClick={() => setPageSwitch(!pageSwitch)}
                >
                  <img src={closeIcon} />
                </button>
                <ReactPlayer
                  width="1190px"
                  height="660px"
                  url={source}
                  playing={false}
                  light={true}
                  controls
                />
              </div>
            </div>

            <br />
          </>
        )}
        <Box
          sx={{
            "& > :not(style)": { m: 1 },
          }}
          className="explore-more"
        >
          <Link to="/happyzone-two">
            <Fab
              variant="extended"
              sx={{ background: "#ffa500", color: "white", width: "220px" }}
            >
              Explore More
            </Fab>
          </Link>
        </Box>
        {/* <button
          className="btn btn-primary"
          style={{ position: "absolute", float: "right", marginTop: "-20%" }}
          onClick={() =>
            setChangeTheme({
              background:
                changeTheme.background === "black" ? "white" : "black",
              color: changeTheme.color === "black" ? "white" : "black",
            })
          }
        >
          Change Theme
        </button> */}

        <div
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "40px",
          }}
        >
          {loading && (
            <div style={{ marginLeft: "48%", marginTop: "7%" }}>
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.50"
                width="50"
                marginRight="700"
                visible={true}
              />
            </div>
          )}
          {!loading && (
            <>
              <div className="video-head-container">
                <h5
                  className="video-heading"
                  style={{ color: changeTheme.color }}
                >
                  Recommended Videos for you
                </h5>
                <button className="show-more" onClick={(e) => showMore(e)}>
                  {data.expanded ? (
                    <span>Show less</span>
                  ) : (
                    <span>Show more</span>
                  )}
                </button>
              </div>

              <section className="comp-section comp-cards" id="comp_cards">
                <div className="row">
                  {data.videos
                    // ?.slice(0, data.itemsToShow)
                    ?.map((ele, i) => (
                      <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
                        <div
                          className="card flex-fill"
                          style={{
                            border: "none",
                            borderRadius: "30px !important",
                            background: "none",
                          }}
                        >
                          {/* <img
                      alt=""
                      src={ele.video_url}
                      className="card-img-top"
                      style={{
                        height: "150px",
                        width: "100%",
                        borderRadius: "16px 16px 0px 0px ",
                      }}
                    /> */}
                          <ReactPlayer
                            url={
                              ele.content_type === "VIDEO"
                                ? ele.video_url
                                : ele.img_url
                            }
                            light={true}
                            className="card-img-top1"
                            playing={false}
                            style={{ pointerEvents: "none" }}
                          />
                          {/* <div className="card-header" style={{background:"#535353", color:"white"}}>
                  <h5 className="card-title mb-0" style={{color:"white"}}>{ele.title}</h5>
                </div> */}
                          <div
                            className="card-body"
                            style={{
                              background: "#535353",
                              color: "white",
                              borderRadius: "0px 0px 16px 16px ",
                            }}
                          >
                            <div style={{ height: "80px" }}>
                              <h5
                                className="card-title mb-0"
                                style={{ color: "white" }}
                              >
                                {ele.name}
                              </h5>
                              <p className="card-text">
                                {ele.description.slice(0, 30)}
                              </p>
                            </div>
                            <div
                              className="flex"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                height: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              <a
                                className="btn btn-primary"
                                href="#video"
                                onClick={() => (
                                  setSource(ele.video_url), setPageSwitch(false)
                                )}
                                style={{
                                  color: "white",
                                  border: "none",
                                  background: "#3EA6FF",
                                  padding: "0px 20px 0px 20px",
                                  borderRadius: "30px",
                                  fontSize: "14px",
                                  width: "100px",
                                  height: "30px",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                Play Now
                              </a>
                            </div>
                            {/* <a className="card-link" href="#">
                    Another link
                  </a> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </section>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HappyZone;
