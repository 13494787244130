/**
 * Data Table
 */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Table } from "antd";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { ResponsivePie } from "@nivo/pie";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { ServerStyleSheets } from "@material-ui/core";
// Datatable JS
// import "../../../assets/js/jquery.dataTables.min.js";
// import "../../../assets/js/dataTables.bootstrap4.min.js";

const Ctcbreakupgrid = () => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [ctcin, setCtcIn] = useState([]);
  const [ctcout, setCtcOut] = useState([]);
  const [vari, setVari] = useState([]);
  const [totalCTC, setTotalCTC] = useState(null);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [data, setData] = useState([]);
  const [deduct, setDeduct] = useState([
    {
      deduction: "PF - Employee",
      monthly: 83333.33,
      annualy: 954212.2,
    },
    {
      deduction: "Net Pay",
      monthly: 83333.33,
      annualy: 954212.2,
    },
  ]);

  const [data2, setData2] = useState([]);
  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ctc_brekup/`,

        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            table_toggle: "n",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const sum = res.data.ctc_list.reduce((accumulator, object) => {
          return accumulator + object.value;
        }, 0);
        setTotalCTC(sum);
        setData2(
          res.data.ctc_list
            .concat(res.data.variables_list)
            .map((ele) => ({ ...ele, id: ele.label }))
        );
        setCtcIn(res.data.ctc_list);
        setCtcOut(res.data.deduction_list);
        setVari(res.data.variables_list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ctc_brekup/`,

        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            table_toggle: "y",
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.ctc_breakup);
        setDeduct(res.data.deduction);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const COLORS = [
    "#0085E8",
    "#F34D80",
    "#FAB204",
    "#0812FF",
    "#6851BE",
    "#FCC648",
    "#57CFC5",
  ];
  const columns = [
    {
      title: "Details",
      dataIndex: "details",
      sorter: (a, b) => a.details.length - b.details.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      sorter: (a, b) => a.monthly.length - b.monthly.length,
    },

    {
      title: "Annualy",
      dataIndex: "annualy",
      sorter: (a, b) => a.annualy.length - b.annualy.length,
    },
  ];
  const columns1 = [
    {
      title: "Deductions",
      dataIndex: "deduction",
      sorter: (a, b) => a.deduction.length - b.deduction.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      sorter: (a, b) => a.monthly.length - b.monthly.length,
    },

    {
      title: "Annualy",
      dataIndex: "annualy",
      sorter: (a, b) => a.annualy.length - b.annualy.length,
    },
  ];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  let renderLabel = function (entry) {
    return entry.name;
  };

  const data3 = [
    {
      id: "erlang",
      label: "erlang",
      value: 24,
    },
    {
      id: "java",
      label: "java",
      value: 256,
      // color: "hsl(187, 70%, 50%)",
    },
    {
      id: "php",
      label: "php",
      value: 71,
      // color: "hsl(147, 70%, 50%)",
    },
    {
      id: "lisp",
      label: "lisp",
      value: 191,
      // color: "hsl(110, 70%, 50%)",
    },
    {
      id: "python",
      label: "python",
      value: 19,
      // color: "hsl(60, 70%, 50%)",
    },
    {
      id: "py",
      label: "py",
      value: 19,
      // color: "hsl(60, 70%, 50%)",
    },
  ];

  const totDed = ctcout?.map((ele) => ele.value);
  const TotEr = ctcin?.map((ele) => ele.value);
  const totCtc = ctcin?.concat(vari).map((ele) => ele.value);
  console.log(totDed);
  let sumDed = 0;
  let sumCtc = 0;
  let sumEr = 0;

  // Calculation the sum using forEach
  totDed?.forEach((x) => {
    sumDed += parseInt(x);
  });
  totCtc?.forEach((x) => {
    sumCtc += parseInt(x);
  });
  TotEr?.forEach((x) => {
    sumEr += parseInt(x);
  });

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">CTC Breakup</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                {/* <div className="view-icons">
                  <Link
                    // to="/attendance-grid"
                    className={
                      show
                        ? "grid-view btn btn-link  "
                        : "list-view btn btn-link active"
                    }
                    onClick={() => setShow(false)}
                  >
                    <i className="fa fa-th" />
                  </Link>
                  <Link
                    // to="/attendance-list"
                    className={
                      show
                        ? "grid-view btn btn-link active"
                        : "list-view btn btn-link"
                    }
                    onClick={() => setShow(true)}
                  >
                    <i className="fa fa-bars" />
                  </Link>
                 
                </div> */}
              </div>
            </div>
          </div>
          {/* /Page Header */}

          {show && (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={data}
                          rowKey={(record) => record.id}
                          pagination={false}
                          // onChange={this.handleTableChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </>
          )}
          {!show && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="row">
                      <div
                        className="col-sm-6 chrtsdiv"
                        // style={{ borderRight: "1px dashed black"}}
                      >
                        {" "}
                        <h3>Salary Components Distribution</h3>
                        <p>Per Annum</p>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ height: "500px", width: "500px" }}>
                            <ResponsivePie
                              data={data2}
                              margin={{
                                top: 40,
                                right: 80,
                                bottom: 80,
                                left: 80,
                              }}
                              innerRadius={0.6}
                              motionConfig="molasses"
                              transitionMode="middleAngle"
                              padAngle={1.5}
                              cornerRadius={5}
                              activeOuterRadiusOffset={8}
                              borderWidth={1}
                              borderColor={{
                                from: "color",
                                modifiers: [["darker", 0.2]],
                              }}
                              colors={{ scheme: "set1" }}
                              enableArcLinkLabels={true}
                              arcLinkLabelsSkipAngle={10}
                              arcLinkLabelsTextColor="#333333"
                              arcLinkLabelsThickness={2}
                              arcLinkLabelsColor={{ from: "color" }}
                              enableArcLabels={true}
                              arcLabelsSkipAngle={10}
                              arcLabelsTextColor={{
                                from: "color",
                                modifiers: [["darker", 2]],
                              }}
                              defs={[
                                {
                                  id: "dots",
                                  type: "patternDots",
                                  background: "inherit",
                                  color: "rgba(255, 255, 255, 0.3)",
                                  size: 4,
                                  padding: 1,
                                  stagger: true,
                                },
                                {
                                  id: "lines",
                                  type: "patternLines",
                                  background: "inherit",
                                  color: "rgba(255, 255, 255, 0.3)",
                                  rotation: -45,
                                  lineWidth: 6,
                                  spacing: 10,
                                },
                              ]}
                              fill={[
                                {
                                  match: {
                                    id: "ruby",
                                  },
                                  id: "dots",
                                },
                                {
                                  match: {
                                    id: "c",
                                  },
                                  id: "dots",
                                },
                                {
                                  match: {
                                    id: "go",
                                  },
                                  id: "dots",
                                },
                                {
                                  match: {
                                    id: "python",
                                  },
                                  id: "dots",
                                },
                                {
                                  match: {
                                    id: "scala",
                                  },
                                  id: "lines",
                                },
                                {
                                  match: {
                                    id: "lisp",
                                  },
                                  id: "lines",
                                },
                                {
                                  match: {
                                    id: "elixir",
                                  },
                                  id: "lines",
                                },
                                {
                                  match: {
                                    id: "javascript",
                                  },
                                  id: "lines",
                                },
                              ]}
                              legends={[]}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-6">
                        {" "}
                        <h3>CTC Breakup</h3>{" "}
                        <h5 style={{ display: "flex", float: "right" }}>
                          Total CTC : {totalCTC}{" "}
                        </h5>
                        <p style={{ marginBottom: "30px" }}>Per Annum</p>
                       
                        <div className="row">
                          <div className="col-sm-12 row" >
                            <div className="col-sm-6">
                            <h4>Earnings :</h4>
                            {ctcin.map((ele) => (
                              <div className="col-sm-12">
                                <p style={{ color: "#4cae4a" }}>
                                  <b>{ele.label}</b>
                                </p>
                                <div className="ctc-input">
                                  {" "}
                                  <span>{ele.value}</span>
                                  <span>Per Annum</span>
                                </div>
                                <br />
                              </div>
                            ))}
                            </div>
                            <div className="col-sm-6">
                            <h4>Deductions:</h4>
                            {ctcout.map((ele) => (
                              <div className="col-sm-12">
                                <p style={{ color: "red" }}>
                                  <b>{ele.label}</b>
                                </p>
                                <div className="ctc-input">
                                  {" "}
                                  <span>{ele.value}</span>
                                  <span>Per Annum</span>
                                </div>
                                <br />
                              </div>
                            ))}
       </div>
                            
                            <br />
                          
                            <br />
                          </div>
                        
                        </div>
                      </div> */}
                      <div className="col-sm-6">
                        <h3>CTC Breakup</h3>{" "}
                        <table
                          className="col-sm-12"
                          style={{ borderRadius: "5px" }}
                        >
                          <thead>
                            <tr style={{ fontWeight: "bold", padding: "5px" }}>
                              <th
                                className="col-sm-6"
                                style={{ border: "1px solid black" }}
                              >
                                Particulars
                              </th>
                              <th
                                className="col-sm-3"
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                Monthly
                              </th>
                              <th
                                className="col-sm-3"
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                Annually
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ctcin.map((ele) => (
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {ele.label}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {parseInt(ele.value) / 12}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {ele.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ border: "1px solid black" }}>
                                Gross Salary
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumEr / 12}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumEr}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            {ctcout.map((ele) => (
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {ele.label}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {parseInt(ele.value) / 12}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {ele.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ border: "1px solid black" }}>
                                Total Deduction
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumDed / 12}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumDed}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ border: "1px solid black" }}>
                                Net Salary
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {(sumEr - sumDed) / 12}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumEr - sumDed}
                              </td>
                            </tr>
                          </tbody>
                          <tbody>
                            {vari.map((ele) => (
                              <tr>
                                <td style={{ border: "1px solid black" }}>
                                  {ele.label}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {parseInt(ele.value) / 12}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {ele.value}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr style={{ fontWeight: "bold" }}>
                              <td style={{ border: "1px solid black" }}>
                                Total C.T.C.
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumCtc / 12}
                              </td>
                              <td
                                style={{
                                  textAlign: "right",
                                  border: "1px solid black",
                                }}
                              >
                                {sumCtc}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Ctcbreakupgrid;
