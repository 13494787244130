import React, { useState, useEffect } from "react";
import blueCandy from "./assets/images/blue-candy.png";
import greenCandy from "./assets/images/green-candy.png";
import orangeCandy from "./assets/images/orange-candy.png";
import purpleCandy from "./assets/images/purple-candy.png";
import redCandy from "./assets/images/red-candy.png";
import yellowCandy from "./assets/images/yellow-candy.png";
import blank from "./assets/images/blank.png";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import "./assets/css/styles.css";
import { Link } from "react-router-dom";

const SBox = styled(Box)({
  padding: "20px",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
});

const STypography = styled(Typography)({
  fontFamily: "Raleway",
  color: "#800080",
  paddingBottom: "8px",
});

const width = 8;

const candyColors = [
  blueCandy,
  orangeCandy,
  purpleCandy,
  redCandy,
  yellowCandy,
  greenCandy,
];

function CandyCrushh() {
  const [currentColorArrangement, setCurrentColorArrangement] = useState([]);
  const [squareBeingDragged, setSquareBeingDragged] = useState(null);
  const [squareBeingReplaced, setSquareBeingReplaced] = useState(null);
  const [points, setPoints] = useState(0);
  const [count, setCount] = useState(10);
  const [target, setTarget] = useState(30);
  const [level, setLevel] = useState(1);

  const Restart = () => {
    setPoints(0);
    setCount(10);
    setTarget(30);
    setLevel(1);
  };

  const ReachTarget = () => {
    if (points > target && count > 0) {
      Swal.fire({
        imageUrl: purpleCandy,
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#800080",
        title: `You won the game! Score: ${points * 5}`,
      });
      setPoints(0);
      setLevel(level + 1);
      setCount(count + 5);
      setTarget(target + 10);
    }
    if (points < target && count === 0) {
      Swal.fire({
        imageUrl: redCandy,
        imageWidth: 100,
        imageHeight: 100,
        confirmButtonColor: "#FF0000",
        title: "You lost the game!",
      });
      setPoints(0);
      setCount(10);
      setLevel(1);
      setTarget(30);
    }
  };

  useEffect(() => {
    ReachTarget();
  }, [points, count]);

  const checkForColumnOfFour = () => {
    for (let i = 0; i <= 39; i++) {
      const columnOfFour = [i, i + width, i + width * 2, i + width * 3];
      const decidedColor = currentColorArrangement[i];
      const isBlank = currentColorArrangement[i] === blank;

      if (
        columnOfFour.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank
        )
      ) {
        setPoints((score) => score + 4);
        columnOfFour.forEach(
          (square) => (currentColorArrangement[square] = blank)
        );
        return true;
      }
    }
  };

  const checkForRowOfFour = () => {
    for (let i = 0; i < 64; i++) {
      const rowOfFour = [i, i + 1, i + 2, i + 3];
      const decidedColor = currentColorArrangement[i];
      const notValid = [
        5, 6, 7, 13, 14, 15, 21, 22, 23, 29, 30, 31, 37, 38, 39, 45, 46, 47, 53,
        54, 55, 62, 63, 64,
      ];
      const isBlank = currentColorArrangement[i] === blank;

      if (notValid.includes(i)) continue;

      if (
        rowOfFour.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank
        )
      ) {
        setPoints((score) => score + 4);
        rowOfFour.forEach(
          (square) => (currentColorArrangement[square] = blank)
        );
        return true;
      }
    }
  };

  const checkForColumnOfThree = () => {
    for (let i = 0; i <= 47; i++) {
      const columnOfThree = [i, i + width, i + width * 2];
      const decidedColor = currentColorArrangement[i];
      const isBlank = currentColorArrangement[i] === blank;

      if (
        columnOfThree.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank
        )
      ) {
        setPoints((score) => score + 3);
        columnOfThree.forEach(
          (square) => (currentColorArrangement[square] = blank)
        );
        return true;
      }
    }
  };

  const checkForRowOfThree = () => {
    for (let i = 0; i < 64; i++) {
      const rowOfThree = [i, i + 1, i + 2];
      const decidedColor = currentColorArrangement[i];
      const notValid = [
        6, 7, 14, 15, 22, 23, 30, 31, 38, 39, 46, 47, 54, 55, 63, 64,
      ];
      const isBlank = currentColorArrangement[i] === blank;

      if (notValid.includes(i)) continue;

      if (
        rowOfThree.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank
        )
      ) {
        setPoints((score) => score + 3);
        rowOfThree.forEach(
          (square) => (currentColorArrangement[square] = blank)
        );
        return true;
      }
    }
  };

  const moveIntoSquareBelow = () => {
    for (let i = 0; i <= 55; i++) {
      const firstRow = [0, 1, 2, 3, 4, 5, 6, 7];
      const isFirstRow = firstRow.includes(i);

      if (isFirstRow && currentColorArrangement[i] === blank) {
        let randomNumber = Math.floor(Math.random() * candyColors.length);
        currentColorArrangement[i] = candyColors[randomNumber];
      }

      if (currentColorArrangement[i + width] === blank) {
        currentColorArrangement[i + width] = currentColorArrangement[i];
        currentColorArrangement[i] = blank;
      }
    }
  };

  const dragStart = (e) => {
    setSquareBeingDragged(e.target);
    setCount(count - 1);
  };

  const dragDrop = (e) => {
    setSquareBeingReplaced(e.target);
  };

  const dragEnd = () => {
    const squareBeingDraggedId = parseInt(
      squareBeingDragged.getAttribute("data-id")
    );
    const squareBeingReplacedId = parseInt(
      squareBeingReplaced.getAttribute("data-id")
    );

    currentColorArrangement[squareBeingReplacedId] =
      squareBeingDragged.getAttribute("src");
    currentColorArrangement[squareBeingDraggedId] =
      squareBeingReplaced.getAttribute("src");

    const validMoves = [
      squareBeingDraggedId - 1,
      squareBeingDraggedId - width,
      squareBeingDraggedId + 1,
      squareBeingDraggedId + width,
    ];

    const validMove = validMoves.includes(squareBeingReplacedId);

    const isAColumnOfFour = checkForColumnOfFour();
    const isARowOfFour = checkForRowOfFour();
    const isAColumnOfThree = checkForColumnOfThree();
    const isARowOfThree = checkForRowOfThree();

    if (
      squareBeingReplacedId &&
      validMove &&
      (isARowOfThree || isARowOfFour || isAColumnOfFour || isAColumnOfThree)
    ) {
      setSquareBeingDragged(null);
      setSquareBeingReplaced(null);
    } else {
      currentColorArrangement[squareBeingReplacedId] =
        squareBeingReplaced.getAttribute("src");
      currentColorArrangement[squareBeingDraggedId] =
        squareBeingDragged.getAttribute("src");
      setCurrentColorArrangement([...currentColorArrangement]);
    }
  };

  const createBoard = () => {
    const randomColorArrangement = [];
    for (let i = 0; i < width * width; i++) {
      const randomColor =
        candyColors[Math.floor(Math.random() * candyColors.length)];
      randomColorArrangement.push(randomColor);
    }
    setCurrentColorArrangement(randomColorArrangement);
  };

  useEffect(() => {
    createBoard();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      checkForColumnOfFour();
      checkForRowOfFour();
      checkForColumnOfThree();
      checkForRowOfThree();
      moveIntoSquareBelow();
      setCurrentColorArrangement([...currentColorArrangement]);
    }, 100);
    return () => clearInterval(timer);
  }, [
    checkForColumnOfFour,
    checkForRowOfFour,
    checkForColumnOfThree,
    checkForRowOfThree,
    moveIntoSquareBelow,
    currentColorArrangement,
  ]);

  return (
    <div
      style={{
        textAlign: "left",
        background:
          "radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(212, 148, 233, 1) 100%)",
      }}
    >
      <Link to="/happyzone-two">
        <Button
          variant="contained"
          sx={{
            mt: 2,
            ml: 2,
            background: "#800080",
            boxShadow: 0,
            textTransform: "none",
          }}
        >
          Back to Happy Zone
        </Button>
      </Link>

      <SBox>
        <Box textAlign="center">
          <STypography variant="h3">
            Candy Crushh &nbsp;
            <span style={{ color: "red", fontSize: "50%" }}>Level {level}</span>
          </STypography>

          <STypography variant="h6">
            Target {">"} {target}
          </STypography>

          <STypography variant="h6">
            Points:{" "}
            {points === 0 ? (
              <span style={{ color: "red" }}>{points}</span>
            ) : (
              points
            )}
          </STypography>

          <STypography variant="h6">Moves: {count}</STypography>

          <Button
            onClick={Restart}
            variant="outlined"
            color="error"
            sx={{ textTransform: "none", m: 1, mb: 0 }}
          >
            Restart
          </Button>
        </Box>

        <Box sx={{ height: "700px", p: 2 }}>
          <div
            className="game"
            style={{ marginLeft: "auto", marginRight: "auto" }}
          >
            {currentColorArrangement.map((candyColor, index) => (
              <img
                key={index}
                src={candyColor}
                alt={candyColor}
                data-id={index}
                draggable={true}
                onDragStart={dragStart}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={(e) => e.preventDefault()}
                onDragLeave={(e) => e.preventDefault()}
                onDrop={dragDrop}
                onDragEnd={dragEnd}
              />
            ))}
          </div>
        </Box>
      </SBox>
    </div>
  );
}

export default CandyCrushh;
