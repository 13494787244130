/**
 * Blank Page
 */
import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebar";
import Header from "../../Sidebar/header";
import axios from "axios";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  Cell,
} from "recharts";
import empty from "../../../assets/img/profiles/browser.png";

const AdminInterpretation = () => {
  const [menu, setMenu] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  // console.log(currentMonth);
  const token = sessionStorage.getItem("access_token");
  const [workingDays, setWorkingDays] = useState([]);

  const [workinghours, setWorkingHours] = useState([]);
  const [salaryData, setSalaryData] = useState([
    {
      year: "2020",
      total_salary: 1000000,
    },

    {
      year: "2021",
      total_salary: 1200000,
    },
    {
      year: "2022",
      total_salary: 1300000,
    },
    {
      year: "2023",
      total_salary: 1500000,
    },
  ]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [data, setData] = useState([]);

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;

    if (value === 0) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="red"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    if (value === 1) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="blue"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
    if (value === 2) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="yellow"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }
    if (value === 3) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="pink"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    if (value === 4) {
      return (
        <svg
          x={cx - 10}
          y={cy - 10}
          width={20}
          height={20}
          fill="gray"
          viewBox="0 0 1024 1024"
        >
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
        </svg>
      );
    }

    return (
      <svg
        x={cx - 10}
        y={cy - 10}
        width={20}
        height={20}
        fill="green"
        viewBox="0 0 1024 1024"
      >
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
      </svg>
    );
  };

  const barColors = [
    "#5932EA",
    "#FFD3A3",
    "#E0AFFF",
    "#B84200",
    "#9DCF00",
    "#FF7676",
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/graph/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data.graph1);
        setWorkingHours(
          res.data.graph2.map((ele) => ({
            name: ele.name,
            working_hours: Math.round((ele.score + Number.EPSILON) * 100) / 100,
          }))
        );
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/graph2/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          year: currentMonth.split("-")[0],
          month: currentMonth.split("-")[1],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.graph3);
        setWorkingDays(
          res.data.map((ele) => ({ name: ele.name, No_of_Days: ele.score }))
        );
      });
  }, [currentMonth]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Interpretation</h3>
              </div>
            </div>
          </div>

          <div className=" row col-sm-12">
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Happiness Index
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#8884d8"
                      dot={<CustomizedDot />}
                    />
                  </LineChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
            {/* <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Efficiency
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div> */}
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Working Location
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <div style={{ padding: "10px" }}>
                  <input
                    type="month"
                    style={{
                      width: "fit-content",
                      display: "flex",
                      marginLeft: "auto",
                      marginRight: "10px",
                      float: "inline-end",
                      position: "absolute",
                    }}
                    value={currentMonth}
                    onChange={(e) => setCurrentMonth(e.target.value)}
                  />
                </div>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={workingDays}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="No_of_Days" fill="#00a0fc">
                      {workingDays?.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={barColors[index % 20]}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
          </div>
          <br />
          <div className=" row col-sm-12">
            {/* <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Social Index
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div> */}
          </div>
          <br />
          <div className=" row col-sm-12">
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Working Hours
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={workinghours}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="working_hours" fill="#5932EA" />
                  </BarChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Salary Growth
              </p>
              <div className="card mb-0" style={{ height: "300px" }}>
                <br />
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={salaryData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis datakey="total_salary" />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="total_salary" fill="#5932EA" />
                  </BarChart>
                </ResponsiveContainer>
                <br />
              </div>
            </div>
          </div>
          <br />
          {/* <div className=" row col-sm-12">
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Title Vs Reporting Manager
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div>
            <div className="col-sm-6">
              <p className="page-title" style={{ fontSize: "15px" }}>
                Projects
              </p>
              <div
                className="card mb-0"
                style={{
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img style={{ width: "100px", height: "100px" }} src={empty} />
                <br />
                <p>No Statistic Available</p>
              </div>
            </div>
          </div> */}
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};

export default AdminInterpretation;
