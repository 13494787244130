import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import temp from "../assets/img/profiles/clipart817938.png";
const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className="main-wrapper">
      <button className="error-back-btn" onClick={() => navigate(-1)}>GO BACK</button>
      <div className="error-box">
        <img src={temp} style={{ height: "300px", width: "300px" }} />
        {/* <h3>
          <i className="fa fa-warning" /> Oops! Page not found!
        </h3> */}
        <p style={{ color: "white" }}>
          The page you requested is Under Construction.
        </p>
        {/* <Link
          onClick={() => localStorage.setItem("firstload", "true")}
          to="/dashboard"
          className="btn btn-custom"
        >
          Back to Home
        </Link> */}
      </div>
    </div>
  );
};

export default HomePage;
