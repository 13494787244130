import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { ConfigProvider, Table } from "antd";
import "antd/dist/antd.css";
import empty from "../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import Delete from "../../modelbox/Delete";
import axios from "axios";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No shift data to show</p>
  </div>
);

const Shifts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const [data, setData] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [times, setTimes] = useState({});
  const [dates, setDates] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const [applyShift, setApplyShift] = useState({
    emp_id: sessionStorage.getItem("emp_id"),
    type: { label: null, value: null },
    applicable_from: "",
    reason: "",
  });

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleAllShift = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift_apply/`, {
        params: { empid: sessionStorage.getItem("emp_id") },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDates(res.data.map((v) => v.applicable_from));
        setData(
          res.data.map((ele) => ({
            ...ele,
            applicable_from: ele.applicable_from
              ?.substr(0, 10)
              ?.split("-")
              ?.reverse()
              .join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApplyShift = async (event) => {
    event.preventDefault();
    if (
      !applyShift.type.value ||
      !applyShift.applicable_from ||
      !applyShift.reason
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields!",
        timer: 1500,
      });
    } else if (dates.includes(applyShift.applicable_from)) {
      Swal.fire({
        icon: "error",
        text: "You cannot apply for a shift change for the same date!",
        timer: 1500,
      });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/shift_apply/`, {
          empid: sessionStorage.getItem("emp_id"),
          shift_id: applyShift.type.value,
          applicable_from: applyShift.applicable_from,
          reason: applyShift.reason,
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Shift Change Applied Successfully",
              timer: 1500,
            });
            handleAllShift();
            setApplyShift({
              type: { label: null, value: null },
              applicable_from: "",
              reason: "",
            });
            setTimes({});
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  useEffect(() => {
    handleAllShift();
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift/`, {
        params: { coregid: sessionStorage.getItem("coid"), draft: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setShifts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (shifts.length > 0) {
      const list = shifts.find((ele) => ele.id === applyShift.type?.value); //=== projectName.value
      //console.log(list.map((ele) => ele.members.name));
      setTimes(list);
      //console.log(empList.find((ele) => ele.id === projectName.value));
      // const list1 = list?.members.concat(list.teamleads);
      // const list2 = list1?.concat([list?.creator]);
      // setEmpList(
      //   list2?.map((ele) => ({
      //     label: ele.name,
      //     value: ele.key,
      //   }))
      // );
    }
  }, [applyShift.type]);

  const columns = [
    {
      title: "Shift Type",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },

    {
      title: "Applicable From",
      dataIndex: "applicable_from",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.applicable_from, "DD-MM-YYYY") -
          moment(b.applicable_from, "DD-MM-YYYY"),
      },
    },

    {
      title: "Shift Start Time",
      dataIndex: "start_time",
      align: "center",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Shift End Time",
      dataIndex: "end_time",
      align: "center",
      sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Break",
      //dataIndex: "status",
      align: "center",
      render: (text, record) => <>{text.break_start + "-" + text.break_end}</>,
    },
    {
      title: "Effective Status",
      dataIndex: "effective",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text ? "Active" : "InActive"}
          </a>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            <BootstrapTooltip title={record.reason}>
              <Button>{text}</Button>
            </BootstrapTooltip>
          </a>
        </div>
      ),
    },

    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_leave"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_approve"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  const value = 0.66;
  const percentage = 66;
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Shift Management</h3>
              </div>
              {sessionStorage.getItem("coid") !== "Ram 067802" && (
                <div className="col-auto float-end ml-auto">
                  <div className="view-icons">
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_leave"
                      href="#"
                      classname="edit-icon"
                    >
                      <i className="fa fa-plus" /> &nbsp;Apply Shift Change
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <ConfigProvider
                      renderEmpty={
                        // customize ?
                        customizeRenderEmpty
                        // : undefined
                      }
                    >
                      <Table
                        className="table-striped"
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        loading={isLoading}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Apply for Shift Change</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} alt="" />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-6">
                      <label>
                        Shift<span className="text-danger">*</span>
                      </label>
                      <Select
                        options={[...new Set(shifts?.map((item) => item))].map(
                          (ele) => ({
                            label: ele.name,
                            value: ele.id,
                          })
                        )}
                        value={applyShift.type}
                        onChange={(e) =>
                          setApplyShift({ ...applyShift, type: e })
                        }
                      />
                    </div>
                    <div className="form-group col-md-6">
                      {" "}
                      <label>
                        Applicable from <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          min={new Date().toISOString().split("T")[0]}
                          type="date"
                          max="9999-12-31"
                          value={applyShift.applicable_from}
                          onChange={(e) =>
                            setApplyShift({
                              ...applyShift,
                              applicable_from: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        Min Start Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          disabled
                          value={times?.min_start_time}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        Start Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          value={times?.start_time}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        Max Start Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          value={times?.max_start_time}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        Min End Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          value={times?.min_end_time}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        End Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          value={times?.end_time}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      {" "}
                      <label>
                        Max End Time <span className="text-danger">*</span>
                      </label>
                      <div>
                        <input
                          className="form-control datetimepicker"
                          value={times?.max_end_time}
                          disabled
                        />
                      </div>
                    </div>
                    {/* <div className="form-group col-md-6">
                      <div className="col-md-12" style={{ marginLeft: "25px" }}>
                        {" "}
                        <label>
                          To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            //min={new Date().toISOString().split("T")[0]}
                            min={applyShift.from_date}
                            value={applyShift.to_date}
                            className="form-control datetimepicker"
                            type="date"
                            onChange={(e) =>
                              setApplyShift({
                                ...applyShift,
                                to_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className=" col-md-6">
                        <div className="form-group">
                          <label>
                            Number of days{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            type="text"
                            defaultValue={2}
                          />
                        </div>
                      </div> */}
                    {/* <div className=" col-md-6">
                        <div
                          className="form-group"
                          //style={{ marginLeft: "25px" }}
                        >
                          <label>
                            Remaining Leaves{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            defaultValue={12}
                            type="text"
                          />
                        </div>
                      </div> */}
                  </div>

                  <div className="form-group">
                    <label>
                      Reason for Shift change{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      value={applyShift.reason}
                      className="form-control"
                      maxlength="100"
                      onChange={(e) =>
                        setApplyShift({
                          ...applyShift,
                          reason: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss={
                        !applyShift.type.value ||
                        !applyShift.applicable_from ||
                        !applyShift.reason
                          ? ""
                          : "modal"
                      }
                      style={{
                        background: "#3298DB",
                        color: "white",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      onClick={(event) => handleApplyShift(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Leave Modal */}
        {/* Edit Leave Modal */}
        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Type of Leave<span className="text-danger">*</span>
                    </label>
                    {/* <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select> */}
                    <Select />
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Leave Modal */}
        {/* Delete Leave Modal */}
        <Delete />
        {/* /Delete Leave Modal */}
      </div>
    </div>
  );
};

export default Shifts;
