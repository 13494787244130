import React, { useState, useEffect } from "react";
import { Table } from "antd";
import Select from "react-select";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../../components/paginationfunction";
import "../../../../antdstyle.css";
import eye from "../../../../assets/img/profiles/Group813.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterOff from "../../../../assets/img/profiles/filter-off-fill1.svg";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import Back from "../../../../assets/img/profiles/arrow-left.svg";
import Button from "@mui/material/Button";
import { CiExport } from "react-icons/ci";
import { border } from "@mui/system";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
const PayrollHistory = (props) => {
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(0);
  const [name1, setName1]=useState("");
  const [department1, setDepartment1]=useState("");
  const [designation1, setDesignation1]=useState("")
  const data = [
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
    {
      employeeid: "1234566ased",
      name: "Vaibhav Maske",
      department: "IT",
      pay_days: "20",
      basic: "12345",
      deductions: "1234",
      taxes: "123",
      reimbursements: "456",
      variables: "1234",
      net_pay: "123456",
    },
  ];

  const [searchData, setSearchData]=useState(data)

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const columns = [
    {
      title: "Employee ID",
      //   width: 100,
      dataIndex: "employeeid",
      key: "employeeid",
      //   fixed: "left",
    },
    {
      title: "Employee Name",
      width: 150,
      dataIndex: "name",
      key: "name",
      //   fixed: "left",
      sorter: true,
    },
    { title: "Department", dataIndex: "department", key: "department" },
    { title: "Pay Days", dataIndex: "pay_days", key: "pay_days" },
    { title: "Basic Pay", dataIndex: "basic", key: "basic" },
    { title: "Deductions", dataIndex: "deductions", key: "deductions" },
    { title: "Taxes", dataIndex: "taxes", key: "taxes" },
    {
      title: "Reimbursements",
      dataIndex: "reimbursements",
      key: "reimbursements",
      width: 150,
    },
    { title: "Variables", dataIndex: "variables", key: "variables" },
    {
      title: "Net Pay",
      dataIndex: "net_pay",
      key: "net_pay",
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <a
            href="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <Button variant="contained">
              <MoreHorizIcon />
            </Button>
          </a>
          <div className="dropdown-menu dropdown-menu-right dropdown-insets">
            <a className="dropdown-item" href="#" onClick={() => setShow(2)}>
              <img
                src={eye}
                style={{
                  height: "10px",
                  width: "15px",
                }}
              />
              <b> View Salary Breakup</b>
            </a>
            <a className="dropdown-item" href="#" onClick={() => setShow(3)}>
              <i className="fa fa-pencil m-r-5" />
              <b> Revise Salary</b>
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
            >
              <i className="fa fa-trash-o m-r-5" />
              <b> Skip Payroll</b>
            </a>
          </div>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      hidden: true,
      fixed: "right",
    },
  ];


  const handleSearch1 = () => {
    const newData = data
      .filter((x) => x.name == (name1 == "" ? x.name : name1))
      .filter((y) => y.department == (department1 == "" ? y.department : department1))
      /*
      .filter(
        (z) => z.emp_type == (designation1 == "" ? z.emp_type : designation1)
      );
      */
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch1();
  }, [name1, department1]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div
            className="row"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="col-sm-6 col-md-2">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  placeholder="Employee Name"
                  options={[
                    ...new Set(
                      data?.map((item) => item.name)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    name1 !== ""
                      ? {
                        label: name1,
                        value: name1,
                      }
                      : null
                  }
                  onChange={(e) => {
                    setName1(e.value);
                  }}
                  // options={[
                  //   ...new Set(empPayrollDetails?.map((item) => item.emp_name)),
                  // ].map((ele) => ({
                  //   label: ele,
                  //   value: ele,
                  // }))}
                  // value={
                  //   name1 !== ""
                  //     ? {
                  //         label: name1,
                  //         value: name1,
                  //       }
                  //     : null
                  // }
                  // onChange={(e) => setName1(e.value)}
                  
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="form-group form-focus select-focus">
              <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  placeholder="Department"
                  options={[
                    ...new Set(
                      data?.map((item) => item.department)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department1 !== ""
                      ? {
                        label: department1,
                        value: department1,
                      }
                      : null
                  }
                  onChange={(e) => {
                    setDepartment1(e.value);
                  }}
                  // options={[
                  //   ...new Set(empPayrollDetails?.map((item) => item.emp_name)),
                  // ].map((ele) => ({
                  //   label: ele,
                  //   value: ele,
                  // }))}
                  // value={
                  //   name1 !== ""
                  //     ? {
                  //         label: name1,
                  //         value: name1,
                  //       }
                  //     : null
                  // }
                  // onChange={(e) => setName1(e.value)}
                  
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
                {/* <label className="focus-label">Designation</label> */}
              </div>
            </div>
            <div className="col-sm-6 col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control input-height"
                  placeholder="Department"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                  //   onChange={(e) =>
                  //     setAddress1(e.target.value.toLocaleLowerCase())
                  //   }
                />
                {/* <label className="focus-label">Location</label> */}
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <a
                className="btn-block "
                //   onClick={() => (setAddress1(""), setDesignation1(""), setName1(""))}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "1.5px 5px 2px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
              <button
                style={{
                  height: "38px",
                  backgroundColor: "#0EAA00",
                  padding: "1px 25px 2px 25px",
                  borderRadius: "5px",
                  marginLeft: "20px",
                  border: "none",
                  color: "white",
                }}
              >
                <b>RUN PAYROLL</b>
              </button>
              <button
                style={{
                  height: "38px",
                  backgroundColor: "#3298DB",
                  padding: "1px 15px 2px 15px",
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  marginLeft: "20px",
                }}
              >
                <CiExport style={{ marginRight: "7px", fontSize: "22" }} />
                EXPORT
              </button>
            </div>

            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      // className="table-striped"
                      columns={columns}
                      dataSource={searchData}
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      scroll={{ x: 1300 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollHistory;
