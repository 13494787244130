import React, { useState, useEffect, createContext } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import emoji from "./assets/img/profiles/smileemoji.png";
import closeIcon from "./assets/img/profiles/close-circle-fill.svg";
import Login from "./components/Login/login";
import Nav from "./containers/Navbar/navbar";
import Fab from "@mui/material/Fab";
import AllEmployees from "./components/Employees/allemployees";
import Employeeslist from "./components/Employees/employeeslist";
import EmployeeProfile from "./pages/Profile/employeeprofile";
import HappyZone from "./components/Happyzone/HappyZone";
import WelcomeZone from "./components/Happyzone/WelcomeZone";
import themes from "./Constants/theme";
import NewTheme from "./components/NewTheme";
import Dashboard from "./components/Dashboard/Dashboard";
import LeaveEmployee from "./pages/Leaves/leaveemployee";
import TaskBoard from "./pages/TaskBoard/taskboard";
import Document from "./pages/Documents/Documents";
import Attendancegrid from "./pages/Attendance/Attendancegrid";
import Ctcbreakupgrid from "./pages/CTC/Ctcbreakupgrid";
import Transfer from "./pages/Transfer/Transfer";
import Payslip from "./pages/Payslip/payslip";
import NewLogin from "./components/Newlogin/LoginPage";
import ForgotPass from "./components/ForgotPass/ForgotPass";
import Review from "./pages/Review/Review";
import Projects from "./pages/Projects/projects";
import Guidelines from "./pages/Guidelines/Guidelines";
import AdminGuidelines from "./pages/Admin/AdminGuidelines/AdminGuideline";
import Interpretation from "./pages/Interpretion/Interpretation";
import AdminInterpretation from "./pages/Admin/AdminInterpretation/Interpretation";
import AdminOverview from "./pages/Admin/AdminOverview/AdminOverview";
import Training from "./pages/Training/Training.jsx";
import HomePage from "./pages/HomePage";
import AdminAttendance from "./pages/Admin/AdminAttendance/AdminAttendance.jsx";
import LeavesAdmin from "./pages/Admin/AdminLeave/leave_admin";
import AdminReview from "./pages/Admin/AdminReview/AdminReview";
import AddEmployee from "./pages/Admin/Employee/AddEmployee";
import TransferAdmin from "./pages/Admin/AdminTransfer/transfer_admin";
import AddLeave from "./pages/Admin/AdminLeave/addleave_admin";
import LeaveReport from "./pages/Admin/AdminLeave/leave_report";
import AdminDocument from "./pages/Admin/AdminDocument/AdminDocument";
import ManageEmployee from "./pages/Admin/Employee/ManageEmployee";
import AddReimbursements from "./pages/Admin/AdminReimbursements/add_reimbursements";
import ReimbursementAdmin from "./pages/Admin/AdminReimbursements/reimbursement_admin";
import PrivateRoute from "./utils/PrivateRoute";
import AdminPayroll from "./pages/Admin/AdminPayroll/OverviewPayroll/AdminPayroll";
import Runpayroll from "./pages/Admin/AdminPayroll/Runpayroll/Runpayroll";
import PFReport from "./pages/Admin/AdminPayroll/PFReport/PFReport";
// import Index from "./pages/Settings/Index";
import Settings from "./pages/Settings/Settings";
import RolePermission from "./pages/Settings/rollspermission";
import CompanySettings from "./pages/Settings/companysettings";
import PayrollHistory from "./pages/Admin/AdminPayroll/Runpayroll/PayrollHistory";
import HappyZoneTwo from "./components/Happyzone/HappyZoneTwo";
//import Scratch from "./components/Happyzone/ScratchPad/scratch"
// import Header from "./pages/Sidebar/header";
import BoardView from "./components/Happyzone/Games/TwoZeroGame/components/Board";
import TicTac from "./components/Happyzone/Games/TicTacToeGame/TicTac";
import RingGame from "./components/Happyzone/Games/FindTheRing/containers/Ring";
import MineSweeper from "./components/Happyzone/Games/MineSweeperGame/MineSweeper";
import UnderMaintenance from "./modelbox/UnderMaintenance";
import HzContent from "./pages/Admin/HappyzoneContent/HzContent";
import TrainingContent from "./pages/Admin/HappyzoneContent/TrainingContent";
import CandyCrushh from "./components/Happyzone/Games/CandyCrushh/CandyCrushh";
import SketchBoard from "./components/Happyzone/Games/SketchBoard/SketchBoard";
import Salary from "./pages/Admin/Employee/Salary";
import Taxform from "./pages/Taxdecleration/Taxform";
import ShiftScheduling from "./pages/Admin/ShiftScheduling/AdminShift";
import Shifts from "./pages/Shift/Shifts";
import ShiftManage from "./pages/Admin/ShiftScheduling/ShiftManage";
import Reimbursement from "./pages/Reimbursements/Reimbursement";
import Reports from "./pages/Admin/Reports/Reports";
import axios from "axios";
import Dailyfeed from "./pages/Admin/Dailyfeed";
import TaskReport from "./pages/TaskBoard/taskreport";
import AdminTaskReport from "./pages/Admin/Reports/AdminTaskReport";
import Samarth from "./pages/Render/Samarth.js";
//import { Widget, addResponseMessage } from "react-chat-widget";

import "react-chat-widget/lib/styles.css";
import Survey from "./pages/Admin/Survey/Survey";
import TaskChart from "./pages/TaskChart/TaskChart";
import FAQ from "./pages/FAQ/FAQ";
import NewJoinersReport from "./pages/Admin/Reports/NewJoinesReport";
import BirthdayReport from "./pages/Admin/Reports/BirthdayReport";
import FaceRecognition from "./pages/Registration/FaceRegistration";
import FaceRecognitionAdmin from "./pages/Admin/Registration/FaceRecognition";
import PantryOrder from "./components/Happyzone/Pantry/PantryOrder";
import AdminPantry from "./pages/Admin/AdminPantry/AdminPantry";
import MoodReport from "./pages/Admin/Reports/MoodReport";
import Status from "./pages/Attendance/Status";
import ApprovalPermission from "./pages/Settings/approvalPermission.jsx";
import LoginReport from "./pages/Admin/Reports/LoginReport.jsx";
import Holidays from "./pages/Admin/Holidays/Holidays.jsx";
import Designation from "./pages/Settings/designation.jsx";
import Department from "./pages/Settings/department.jsx";
import GeoTracking from "./pages/Admin/GeoLocation/GeoTracking.jsx";
import IssuingLetter from "./pages/Admin/IssueLetter/IssuingLetter.jsx";
import GeoFencingReg from "./pages/Admin/GeoLocation/GeoFencingReg.jsx";
import DutyHours from "./pages/Admin/DutyHours/DutyHours.jsx";
import ContractAttendance from "./pages/Admin/Reports/ContractAttendance.jsx";
import ManpowerReport from "./pages/Admin/Reports/ManpowerReport.jsx";
import BankDetails from "./pages/Admin/Reports/BankDetails.jsx";
import ArrearReport from "./pages/Admin/Reports/ArrearReport.jsx";
import BonusReport from "./pages/Admin/Reports/BonusReport.jsx";
import GratuityReport from "./pages/Admin/Reports/GratuityReport.jsx";
import Contractor from "./pages/Admin/Contractor/Contractor.jsx";
import IncentiveReport from "./pages/Admin/Reports/IncentiveReport.jsx";
import InclusiveReport from "./pages/Admin/Reports/InclusiveReport.jsx";
import CompOffLeave from "./pages/Admin/AdminLeave/CompOffLeave.jsx";
import OnHoldPayroll from "./pages/Admin/AdminPayroll/Runpayroll/OnHoldPayroll.jsx";
import Arrears from "./pages/Admin/AdminPayroll/Runpayroll/Arrears.jsx";
import OvertimeReport from "./pages/Admin/Reports/OvertimeReport.jsx";
import ShortLeave from "./pages/Admin/AdminLeave/ShortLeave.jsx";
import RRDeduction from "./pages/Admin/AdminPayroll/RrwlDeduction.jsx";
import Outdoor from "./pages/Admin/AdminLeave/outdoor.jsx";
import PFDeduction from "./pages/Admin/AdminPayroll/PFDecuction.jsx";
import GeoTagging from "./pages/Admin/GeoLocation/GeoTagging.jsx";
export const UserContext = createContext();

function App() {
  const [notify, setNotify] = useState([]);
  const [backImg, setBackImg] = useState("");
  const [theme, setTheme] = useState("");
  const [IsAdmin, setIsAdmin] = useState(
    JSON.parse(sessionStorage.getItem("adminActive"))
  );
  const token = sessionStorage.getItem("access_token");
  const theme_link = sessionStorage.getItem("theme_link");

  // useEffect(() => {
  //   notify.map((ele) => addResponseMessage(ele.messsage));
  // }, [notify]);

  const happyBirthday = () => setIsAdmin(!IsAdmin);
  const handleChange = (selectedTheme) => {
    console.log(selectedTheme.value);
    setTheme(themes[selectedTheme.value]);
  };

  const handleNote = () => {
    {
      notify.map(({ id, msg_id, messsage }) => {
        return (
          <div
            key={id}
            className={`Message ${msg_id === 1 ? "sent" : "received"}`}
          >
            <h5>{messsage}</h5>
          </div>
        );
      });
    }
  };

  const admin = JSON.parse(sessionStorage.getItem("adminActive"));
  const refCallback = (node) => {
    if (node) {
      theme &&
        Object.keys(theme).forEach((element) => {
          node.style.setProperty(element, theme[element], "important");
          if (element === "background-image") {
            // apply the same background mentioned for theme to the body of the website
            console.log(theme[element]);
            document.body.style.background = theme[element];
          }
          // if (element === "color") {
          //     document.body.style.color =
          //       theme[element];
          // }
        });
    }
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    setIsAdmin(JSON.parse(sessionStorage.getItem("adminActive")));
  }, [sessionStorage.getItem("adminActive")]);
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <UserContext.Provider value={{ happyBirthday }}>
      <>
        <div
          ref={refCallback}
          className={IsAdmin ? "App" : "App1"}
          style={
            !IsAdmin
              ? {
                  backgroundImage:
                    backImg === "" ? `url(${theme_link})` : `url(${backImg})`,
                }
              : { backgroundImage: null }
          }
        >
          {/* <Widget handleNewUserMessage={handleNote} show /> */}
          <Routes>
            <Route
              exact
              path="/"
              element={<NewLogin backImg={backImg} setBackImg={setBackImg} />}
            />
            <Route exact path="/forget-pass" element={<ForgotPass />} />
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/employee-list" element={<Employeeslist />} />
            </Route>
            //{" "}
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/dashboard"
                element={
                  <Dashboard
                    handleChange={handleChange}
                    backImg={backImg}
                    setBackImg={setBackImg}
                  />
                }
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/under-maintenance"
                element={<UnderMaintenance />}
              />
            </Route>
            <Route path="*" element={<HomePage />} />
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/allEmployee" element={<AllEmployees />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/employee-profile"
                element={<EmployeeProfile />}
              />
            </Route>
            <Route exact path="/welcomezone" element={<WelcomeZone />} />
            <Route exact path="/happyzone" element={<HappyZone />} />
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/hz-content" element={<HzContent />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/training-content"
                element={<TrainingContent />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/geo-fencing-reg"
                element={<GeoFencingReg />}
              />
            </Route>
            <Route exact path="/happyzone-two" element={<HappyZoneTwo />} />
            <Route exact path="/twozerogame" element={<BoardView />} />
            <Route exact path="/pantry-order" element={<PantryOrder />} />
            <Route exact path="/tictac-game" element={<TicTac />} />
            <Route exact path="/ring-game" element={<RingGame />} />
            <Route exact path="/minesweeper-game" element={<MineSweeper />} />
            <Route exact path="/candycrushh-game" element={<CandyCrushh />} />
            <Route exact path="/sketchboard-game" element={<SketchBoard />} />
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/leaves" element={<LeaveEmployee />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/taskboard-Grid" element={<TaskBoard />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/task-report" element={<TaskReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/documents" element={<Document />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/attendance-status" element={<Status />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/admin-documents"
                element={<AdminDocument />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/admin-task" element={<AdminTaskReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/newjoiner-report"
                element={<NewJoinersReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/mood-report" element={<MoodReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/overtime-report"
                element={<OvertimeReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/face-recognition"
                element={<FaceRecognition />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/face-recog-admin"
                element={<FaceRecognitionAdmin />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/birthday-report"
                element={<BirthdayReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/arrear-report" element={<ArrearReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/bonus-report" element={<BonusReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/gratuity-report"
                element={<GratuityReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/manpower-report"
                element={<ManpowerReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/bank-details" element={<BankDetails />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/login-report" element={<LoginReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/comp-off-leave" element={<CompOffLeave />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/incentive-report"
                element={<IncentiveReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/inclusive-report"
                element={<InclusiveReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/contract-attendance"
                element={<ContractAttendance />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/admin-overview" element={<AdminOverview />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/attendance-grid"
                element={<Attendancegrid />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/DailyFeed" element={<Dailyfeed />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/admin-attendance"
                element={<AdminAttendance />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/transfer" element={<Transfer />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/payslip" element={<Payslip />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/geo-tracking" element={<GeoTracking />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/geo/tagging" element={<GeoTagging />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/issue-letter" element={<IssuingLetter />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/contractor" element={<Contractor />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/duty-hours" element={<DutyHours />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/review" element={<Review />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/review-admin" element={<AdminReview />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/projects" element={<Projects />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/guidelines" element={<Guidelines />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/holidays" element={<Holidays />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/admin-guidelines"
                element={<AdminGuidelines />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/interpretation"
                element={<Interpretation />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/admin-interpretation"
                element={<AdminInterpretation />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/training" element={<Training />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/leaves-admin" element={<LeavesAdmin />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/short-leave" element={<ShortLeave />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/leaves-admin" element={<LeavesAdmin />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/outdoor" element={<Outdoor />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/leave-admin-report"
                element={<LeaveReport />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/addleave-admin" element={<AddLeave />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/transfer-admin" element={<TransferAdmin />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/manageEmployee"
                element={<ManageEmployee />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/admin-pantry" element={<AdminPantry />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/add-employee" element={<AddEmployee />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/reimburse-ments"
                element={<Reimbursement />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/reports" element={<Reports />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/add-reimbursements"
                element={<AddReimbursements />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/reimbursement-admin"
                element={<ReimbursementAdmin />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/admin-shiftscheduling"
                element={<ShiftScheduling />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/shifts" element={<Shifts />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/faq" element={<FAQ />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/shift-manage" element={<ShiftManage />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/admin-payroll" element={<AdminPayroll />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/add-payroll" element={<Runpayroll />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/deductions" element={<RRDeduction />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/pf/deduction" element={<PFDeduction />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/arrears" element={<Arrears />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/hold-payroll" element={<OnHoldPayroll />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/pf-report" element={<PFReport />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/history-payroll"
                element={<PayrollHistory />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/theme"
                element={<NewTheme handleChange={handleChange} />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/Ctcbreakupgrid"
                element={<Ctcbreakupgrid />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/Settings" element={<Settings />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/roles-permissions"
                element={<RolePermission />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route
                exact
                path="/approval-setting"
                element={<ApprovalPermission />}
              />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route
                exact
                path="/companysettings"
                element={<CompanySettings />}
              />
              <Route exact path="/samarth" element={<Samarth />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/department" element={<Department />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              <Route exact path="/designation" element={<Designation />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/Taxform" element={<Taxform />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/survey-admin" element={<Survey />} />
            </Route>
            <Route exact element={<PrivateRoute />}>
              {" "}
              <Route exact path="/task-chart" element={<TaskChart />} />
            </Route>
          </Routes>
        </div>
      </>
    </UserContext.Provider>
  );
}

export default App;
