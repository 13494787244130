import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { ConfigProvider } from "antd";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import empty from "../../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No data to show</p>
  </div>
);

const BirthdayReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/birthday_report/`,

        {
          params: {
            // year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            // range_from: moment(state[0].startDate).format("YYYY-MM-DD"),
            // range_to: moment(state[0].endDate).format("YYYY-MM-DD"),
            admid: sessionStorage.getItem("admid"),
          },
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        }
      )
      .then((res) => {
        setData(
          res.data.map((ele, index) => {
            return { ...ele, sno: index + 1 };
          })
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [monthValue]);

  const columns = [
    {
      title: "SNo",
      dataIndex: "sno",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Department",
      dataIndex: "emp_department",
      // align: "center",
    },
    {
      title: "Designation",
      dataIndex: "emp_designation",
      // align: "center",
    },
    {
      title: "Date of Birth",
      dataIndex: "bday",
      align: "center",
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      // align: "center",
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/reports");
                    }}
                  >
                    Reports
                  </span>{" "}
                  <span style={{ color: "#6c757d", cursor: "not-allowed" }}>
                    {" "}
                    / Birthday Report
                  </span>
                </h3>
              </div>
              {/* <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_leave"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Apply Shift Change
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    // marginBottom: "-48px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <input
                    style={{
                      width: "150px",
                      borderRadius: "5px",
                      height: "30px",
                      padding: "10px",
                    }}
                    type="month"
                    value={monthValue}
                    onChange={(e) => setMonthValue(e.target.value)}
                  />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <ConfigProvider
                      renderEmpty={
                        // customize ?
                        customizeRenderEmpty
                        // : undefined
                      }
                    >
                      <Table
                        className="table-striped"
                        exportableProps={{ showColumnPicker: true }}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        loading={isLoading}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayReport;
