import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../paginationfunction";
// import {
//   Avatar_01,
//   Avatar_02,
//   Avatar_03,
//   Avatar_04,
//   Avatar_05,
//   Avatar_11,
//   Avatar_12,
//   Avatar_09,
//   Avatar_10,
//   Avatar_08,
//   Avatar_13,
//   Avatar_16,
// } from "../../../Entryfile/imagepath";
import Addemployee from "../../modelbox/Addemployee";
import Editemployee from "../../modelbox/Editemployee";
import Themes from "../../modelbox/themes";
import { RotatingLines } from "react-loader-spinner";
// import Header from '../../../initialpage/Sidebar/header'
import Nav from "../../containers/Navbar/navbar";
import Sidebar from "../../pages/Sidebar/sidebar";
import { dictData } from "../../Constants/empDict.js";
import Header from "../../pages/Sidebar/header";
import axios from "axios";
import moment from "moment";
import FilterOff from "../../assets/img/profiles/filter-off-fill1.svg";
// import logo from "../../assets/img/OZHRMS.svg"
const AllEmployees = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [serachData, setSerachData] = useState([]);
  const [view, setView] = useState("grid");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });

  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/directory/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmployeeDetails(res.data);
        setSerachData(res.data);
        setIsLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [name, address, designation]);

  const handleSearch = () => {
    const newData = employeeDetails
      .filter((x) => x.emp_name == (name == "" ? x.emp_name : name))
      .filter((y) => {
        return address.toLocaleLowerCase() === ""
          ? y
          : y.emp_adress?.toLocaleLowerCase().includes(address);
      })
      .filter(
        (z) => z.emp_role == (designation == "" ? z.emp_role : designation)
      );
    setSerachData(newData);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "emp_name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.emp_profile.links} className="image1" />
          </Link>
          <Link to="#">
            {text} <span>{record.role}</span>
          </Link>
        </h2>
      ),
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Employee ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id - b.emp_id,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    //   sorter: (a, b) => a.mobile - b.mobile,
    //   render: (text, record) => <>{text ? text : "--"}</>,
    // },

    {
      title: "Join Date",
      dataIndex: "emp_joined",
      sorter: {
        compare: (a, b) =>
          moment(a.emp_joined, "YYYY-MM-DD") -
          moment(b.emp_joined, "YYYY-MM-DD"),
      },
    },
    {
      title: "Role",
      dataIndex: "emp_role",
      sorter: (a, b) => a.emp_role.localeCompare(b.emp_role),
    },
    // {
    //   title: "Action",
    //   render: (text, record) => (
    //     <div className="dropdown dropdown-action text-end">
    //       <a
    //         href="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </a>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_employee"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </a>
    //         <a
    //           className="dropdown-item"
    //           href="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete_employee"
    //         >
    //           <i className="fa fa-trash-o m-r-5" /> Delete
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />

      <div className="page-wrapper">
        {/* <Helmet>
          <title>Employee - HRMS</title>
          <meta name="description" content="Login page" />
        </Helmet> */}
        {/* Page Content */}
        <div className="content container-fluid">
          {/* <NewTheme handleChange={props.handleChange} /> */}
          {/* <a
            className="dropdown-item"
            style={{ width: "fit-content" }}
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#change_theme"
          >
            <i className="fa fa-pencil m-r-5" /> Change Theme
          </a> */}
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee Directory</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  {/* <Link
                    to="/allEmployee"
                    className="grid-view btn btn-link active"
                  >
                    <i className="fa fa-th" />
                  </Link>
                  <Link to="/employee-list" className="list-view btn btn-link">
                    <i className="fa fa-bars" />
                  </Link> */}
                  <button
                    className={
                      view === "grid"
                        ? "grid-view btn btn-link active"
                        : "grid-view btn btn-link"
                    }
                    onClick={() => setView("grid")}
                  >
                    <i className="fa fa-th" />
                  </button>
                  <button
                    className={
                      view === "list"
                        ? "list-view btn btn-link active"
                        : "list-view btn btn-link"
                    }
                    onClick={() => setView("list")}
                  >
                    <i className="fa fa-bars" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div style={{ marginLeft: "40%", marginTop: "15%" }}>
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.50"
                width="50"
                marginRight="700"
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee Name"
                      options={[
                        ...new Set(
                          employeeDetails?.map((item) => item.emp_name)
                        ),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        name !== ""
                          ? {
                              label: name,
                              value: name,
                            }
                          : null
                      }
                      onChange={(e) => setName(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Select Designation..."
                      isClearable
                      options={[
                        ...new Set(
                          employeeDetails?.map((item) => item.emp_role)
                        ),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        designation !== ""
                          ? {
                              label: designation,
                              value: designation,
                            }
                          : null
                      }
                      onChange={(e) => setDesignation(e ? e.value : "")}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                    {/* <option>Select Designation</option>
                  <option>Web Developer</option>
                  <option>Web Designer</option>
                  <option>Android Developer</option>
                  <option>Ios Developer</option>
                  <option>Front-end Developer</option>
                  <option>Backend Developer</option>
                  <option>Data Analyst</option>
                  <option>Business Development Manager</option> */}
                  </div>
                </div>
                <div className="col-sm-6 col-md-3">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-height"
                      placeholder="Location"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value.toLocaleLowerCase());
                      }}
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                    {/* <label className="focus-label">Location</label> */}
                  </div>
                </div>

                <div className="col-sm-6 col-md-3">
                  <a
                    className="btn-block"
                    onClick={() => (
                      setAddress(""), setDesignation(""), setName("")
                    )}
                  >
                    <img
                      tabindex="0"
                      data-bs-toggle="tooltip"
                      title="clear all filter"
                      style={{
                        height: "38px",
                        backgroundColor: "#3298DB",
                        padding: "7px 7px",
                        borderRadius: "5px",
                      }}
                      src={FilterOff}
                    />
                  </a>
                </div>
              </div>
              {view === "grid" && (
                <div className="row staff-grid-row">
                  {serachData.map((ele) => (
                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                      <div
                        className="profile-widget"
                        style={{ height: "290px" }}
                      >
                        <div>
                          <h5 style={{ color: "#344767" }}>ID:{ele.emp_id}</h5>
                        </div>
                        <div className="profile-img">
                          <Link to="#" className="avatar">
                            <img
                              src={ele.emp_profile.links}
                              alt=""
                              className="image"
                            />
                          </Link>
                        </div>

                        <h4 className="user-name m-t-10 mb-2 text-ellipsis">
                          <Link to="#">{ele.emp_name}</Link>
                        </h4>

                        <div
                          className="small mb-2 "
                          style={{ color: "#344767" }}
                        >
                          {ele.emp_role}
                        </div>
                        <div
                          className="small mb-2 "
                          style={{ color: "#344767" }}
                        >
                          {ele.emp_department}
                        </div>
                        <div
                          className="small text-muted mb-2"
                          style={{ height: "35px", color: "#344767" }}
                        >
                          {ele.emp_adress}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "35px",
                            justifyContent: "center",
                          }}
                        >
                          {/* <i
                            tabindex="0"
                            data-bs-toggle="tooltip"
                            title={ele.email}
                            class="fa fa-envelope "
                            aria-hidden="true"
                            style={{ color: "#3298DB" }}
                          ></i> */}
                          <p>Email:</p><a href="mailto:someone@example.com">{ele.email}</a>

                          {/* <i
                            tabindex="0"
                            data-bs-toggle="tooltip"
                            title={ele.mobile}
                            class="fa fa-phone "
                            aria-hidden="true"
                            style={{ color: "#3298DB" }}
                          ></i> */}

                          {/* <i
                          class="fa fa-linkedin"
                          aria-hidden="true"
                          style={{ color: "#3298DB" }}
                        ></i> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {view === "list" && (
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        pagination={{
                          total: serachData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={serachData}
                        rowKey={(record) => record.id}
                        onChange={console.log("change")}
                        loading={isLoading}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {/* /Page Content */}
        {/* Add Employee Modal */}
        <Addemployee />
        {/* /Add Employee Modal */}
        {/* Edit Employee Modal */}
        {/* <Editemployee /> */}
        {/* /Edit Employee Modal */}
        {/* Delete Employee Modal */}
        {/* <Themes handleChange={props.handleChange} /> */}
        <div
          className="modal custom-modal fade"
          id="delete_employee"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Employee</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Employee Modal */}
      </div>
    </div>
  );
};

export default AllEmployees;
