import React, { useReducer, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Redirect, Route, Routes } from "react-router";
import ContextProvider from "../store/contextProvider";
import { createChests } from "../utils";
import { CHEST_COUNT, GameStatus } from "../consts";
import reducer from "../store/reducer";
import Game from "../components/Game/Game";
import Menu from "../components/Menu/Menu";

import "./Ring.css";

const RingGame = () => {
  const [toggle, setToggle] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    gameStatus: GameStatus.IN_PROGRESS,
    chests: createChests(CHEST_COUNT),
  });

  return (
    <ContextProvider state={state} dispatch={dispatch}>
      <div className="ring-game">
        <div className="App__container">
          <h1 className="App__heading">Find the ring</h1>
          {toggle && <Game toggle={toggle} setToggle={setToggle}/>}

          {!toggle && <Menu toggle={toggle} setToggle={setToggle}/>}
        </div>
      </div>
    </ContextProvider>
  );
};

export default RingGame;
