import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import { Avatar_09, Avatar_02,Avatar_03, Avatar_05, Avatar_08, Avatar_10,Avatar_15,Avatar_20, Avatar_24,Avatar_25  } from "../../../Entryfile/imagepath"
import Avatar_02 from "../../../assets/img/profiles/smile-icon.svg";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import { FiUpload, FiDownload } from "react-icons/fi";
import "../../../antdstyle.css";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import Swal from "sweetalert2";
import Select, { components } from "react-select";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { JsonToExcel } from "react-json-to-excel";

const ShiftManage = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewShiftDetails, setViewShiftDetails] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const hiddenFileInput = useRef(null);
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [empId, setEmpId] = useState("");
  const [empCodes, setEmpCodes] = useState([]);
  const [code, setCode] = useState({ label: null, value: null });
  const [name1, setName1] = useState("");
  const [location, setLocation] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [from1, setFrom1] = useState("");
  const [to1, setTo1] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [declineReason, setDeclineReason] = useState("");
  const [empList, setEmpList] = useState([]);
  const [times, setTimes] = useState({});
  const [shifts, setShifts] = useState([]);
  const [shiftFile, setShiftFile] = useState(null);
  const [month, setMonth] = useState("");
  const [getShiftnew, setGetShiftNew] = useState([]);
  const [month1, setMonth1] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
  );
  const [shiftChange, setShiftChange] = useState({
    admid: sessionStorage.getItem("adm_id"),
    shift: { label: null, value: null },
    applicable_from: "",
    empids: [],
  });
  console.log(month1);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickOne = () => {
    hiddenFileInput.current.click();
  };
  const getShift = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift_apply/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, i) => ({
            ...ele,
            applicable_from: ele.applicable_from
              .substr(0, 10)
              .split("-")
              .reverse()
              .join("-"),
          }))
        );
        //setSearchData(res.data)
        setSearchData(
          res.data.map((ele, i) => ({
            ...ele,
            applicable_from: ele.applicable_from
              .substr(0, 10)
              .split("-")
              .reverse()
              .join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getShift();
  // }, []);

  const handleApproveShift = (e, value) => {
    e.preventDefault();
    if (!declineReason && value) {
      Swal.fire({
        icon: "error",
        //title: "Oops...",
        text: "Enter the reason!",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/approve/`,
          {
            admid: JSON.parse(sessionStorage.getItem("admid")),
            approval_id: viewShiftDetails.approval_id,
            decline: value,
            reason: declineReason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: res.data.status === false ? "error" : "success",
            // text: res.data.status === true ? "Document Verified" : "",

            // icon:
            //   res.data.error === "Cannot approved by same person"
            //     ? "error"
            //     : "success",
            text:
              res.data.error === "Already Approved"
                ? "Shift Change is already approved"
                : res.data.error === "Cannot approved by same person"
                ? "Cannot approved or declined by same person"
                : res.data.error ===
                  "Cannot approved by someone of lower hierchy"
                ? "You have no authority to approve or decline request"
                : res.data.error ===
                  "Cannot approved by someone of different departmenr"
                ? "You have no authority to approve or decline request"
                : res.data.status === true
                ? "Shift Change approved successfully"
                : "Shift Change Rejected",
            // timer: 3000,
          });

          getShift();
          setDeclineReason("");
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [name1, location, from1, empId, leaveType]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift/`, {
        params: { coregid: sessionStorage.getItem("coid"), draft: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setShifts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (shifts.length > 0) {
      const list = shifts.find((ele) => ele.id === shiftChange.shift?.value);
      setTimes(list);
    }
  }, [shiftChange.shift]);

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/emp_data_empcode/`, {
        params: {
          comp_id: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpCodes(res.data);
      });
  }, []);

  const handleShiftChange = async (event) => {
    event.preventDefault();
    if (
      !shiftChange.shift.value ||
      !shiftChange.applicable_from ||
      shiftChange.empids.length === 0
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all Mandatory fields.",
        // timer: 1500,
      });
    } else {
      Swal.fire({
        // title: "Attendance Report Downloading !",
        html: "Your shift upload is in progress. This may take a few moments",
        // timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          // const timer = Swal.getPopup().querySelector("b");
          // timerInterval = setInterval(() => {
          //   timer.textContent = `${Swal.getTimerLeft()}`;
          // }, 100);
        },
        
      })
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/shift_apply/`, {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          shift_id: shiftChange.shift.value,
          reason: "",
          applicable_from: shiftChange.applicable_from,
          empids: Array.prototype.map.call(shiftChange.empids, function (item) {
            return item.value;
          }),
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Shift Assign Successfully",
            });
            setShiftChange({
              shift: { label: null, value: null },
              applicable_from: "",
              empids: [],
            });
            setTimes({});
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const handleShiftFile = () => {
    if (shiftFile === null || month === "") {
      Swal.fire({
        icon: "error",
        text: "All fields are mandatory",
      });
    } else {
      Swal.fire({
        // title: "Attendance Report Downloading !",
        html: "Your shift upload is in progress. This may take a few moments",
        // timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          // const timer = Swal.getPopup().querySelector("b");
          // timerInterval = setInterval(() => {
          //   timer.textContent = `${Swal.getTimerLeft()}`;
          // }, 100);
        },
        
      })
      let formData = new FormData();
      formData.append("file", shiftFile);
      formData.append("coregid", sessionStorage.getItem("coid"));
      formData.append("month", month.split("-")[1]);
      formData.append("year", month.split("-")[0]);
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/bulk_shift_rrwl/`, formData,
          {responseType: 'blob'}
        )
        .then((res) => {
          console.log(res.data.message)
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ShiftErrorReport.xlsx'); // Specify the file name and extension
          document.body.appendChild(link);
          link.click();
      
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          Swal.fire({
            icon: "success",
            text: "Monthly shift schedule uploaded successfully. Please check the downloaded file for any exceptions ",
  //           html: `
  //   You can use <b>bold text</b>,
  //   <a href=${url} download ShiftErrorReport.xlsx target="_blank">links</a>,
  //   and other HTML tags
  // `,
          });
          setMonth("");
          setShiftFile(null);
          handleNewShift();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };

  const handleSearch = () => {
    const newData = getShiftnew
      .filter((x) => x.name == (name1 == "" ? x.name : name1))
      .filter((x) => x.emp_code == (empId == "" ? x.emp_code : empId))
      // .filter(
      //   (a) => a.applicable_from == (from1 == "" ? a.applicable_from : from1)
      // )
      .filter(
        (y) =>
          y.office_location == (location == "" ? y.office_location : location)
      );
    // .filter((z) => z.name == (leaveType == "" ? z.name : leaveType));
    setSearchData(newData);
  };

  const handleNewShift = () => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/bulk_shift_rrwl/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
          month: month1.split("-")[1],
          year: month1.split("-")[0],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        // setShifts(res.data);
        setSearchData(res.data.map((ele,i)=>({id:ele.i+1, ...ele})));
        setGetShiftNew(res.data.map((ele,i)=>({id:ele.i+1, ...ele})));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleNewShift();
  }, [month1]);

  const handleChange = (e) => {
    setCode(e);
    const data = empList
      ?.map((v) => {
        return {
          label: v.label,
          value: v.value,
        };
      })
      .filter((item) => item.value === e.value);
    setShiftChange({
      ...shiftChange,
      empids: data,
    });
  };
  const handleChange1 = (e) => {
    setShiftChange({
      ...shiftChange,
      empids: [e],
    });
    const data = empCodes.filter((item) => item.value === e.value);
    setCode(data);
  };

  const columns1 = [
    {
      title: "Employee Code",
      dataIndex: "emp_code",
    },
    {
      title: "Name of Employee",
      dataIndex: "name",
    },
    {
      title: "1",
      dataIndex: "1",
    },
    {
      title: "2",
      dataIndex: "2",
    },
    {
      title: "3",
      dataIndex: "3",
    },
    {
      title: "4",
      dataIndex: "4",
    },
    {
      title: "5",
      dataIndex: "5",
    },
    {
      title: "6",
      dataIndex: "6",
    },
    {
      title: "7",
      dataIndex: "7",
    },
    {
      title: "8",
      dataIndex: "8",
    },
    {
      title: "9",
      dataIndex: "9",
    },
    {
      title: "10",
      dataIndex: "10",
    },
    {
      title: "11",
      dataIndex: "11",
    },
    {
      title: "12",
      dataIndex: "12",
    },
    {
      title: "13",
      dataIndex: "13",
    },
    {
      title: "14",
      dataIndex: "14",
    },
    {
      title: "15",
      dataIndex: "15",
    },
    {
      title: "16",
      dataIndex: "16",
    },
    {
      title: "17",
      dataIndex: "17",
    },
    {
      title: "18",
      dataIndex: "18",
    },
    {
      title: "19",
      dataIndex: "19",
    },
    {
      title: "20",
      dataIndex: "20",
    },
    {
      title: "21",
      dataIndex: "21",
    },
    {
      title: "22",
      dataIndex: "22",
    },
    {
      title: "23",
      dataIndex: "23",
    },
    {
      title: "24",
      dataIndex: "24",
    },
    {
      title: "25",
      dataIndex: "25",
    },
    {
      title: "26",
      dataIndex: "26",
    },
    {
      title: "27",
      dataIndex: "27",
    },
    {
      title: "28",
      dataIndex: "28",
    },
    {
      title: "29",
      dataIndex: "29",
    },
    {
      title: "30",
      dataIndex: "30",
    },
    {
      title: "31",
      dataIndex: "31",
    },
  ];

  const columns = [
    {
      title: "Name of Employee",
      dataIndex: "emp_name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.emp_name.localeCompare(b.emp_name),
    },
    {
      title: "Shift",
      dataIndex: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
    },

    {
      title: "Applicable From",
      dataIndex: "applicable_from",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.applicable_from, "DD-MM-YYYY") -
          moment(b.applicable_from, "DD-MM-YYYY"),
      },
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.start_time, "HH:MM:SS") - moment(b.start_time, "HH:MM:SS"),
      },
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      align: "center",
      sorter: {
        compare: (a, b) =>
          moment(a.to, "DD-MM-YYYY") - moment(b.to, "DD-MM_YYYY"),
      },
    },

    {
      title: "Effective Status",
      dataIndex: "effective",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text ? "Active" : "InActive"}
          </a>
        </div>
      ),
      // sorter: (a, b) => a.text.localeCompare(b.text),
      // sorter: (a, b) =>
      //   a.noofdays.match(/(\d+)/)[0] - b.noofdays.match(/(\d+)/)[0],
    },

    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   sorter: (a, b) => a.reason.length - b.reason.length,
    //   ellipsis: true,
    // },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Approved by Manager"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          disabled={
            rolePermissions["Employee Shift"] === "Edit/Create/Approve"
              ? false
              : true
          }
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#view_leave"
          className=" btn-primary submit-btn1"
          onClick={(e) => setViewShiftDetails(text)}
        >
          View
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Shifts</h3>
              </div>
              {rolePermissions["Employee Shift"] === "Edit/Create/Approve" && (
                <div className="col-auto float-end ml-auto">
                  <div className="view-icons">
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                        marginLeft: "10px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_schedule"
                    >
                      {" "}
                      Assign Shifts
                    </button>
                  </div>

                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#uploadShift"
                    // onClick={() => {
                    //   handleDownloadCsv();
                    // }}
                  >
                    <FiUpload /> Upload Shift
                  </button>

                  {/* <button
                    className=""
                    classname="edit-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "19px 23px",
                      height: "30px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <Box>
                      <Tooltip title="Add Bulk File">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          color="white"
                          // sx={{ ml: -3 }}
                          aria-controls={open ? "account-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          // className={classes.button}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              marginTop: "15px",
                              color: "white",
                              fontWeight: "500",
                            }}
                          >
                            <FiUpload />
                            &nbsp;&nbsp; Upload Bulk Shifts
                          </p>
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem>
                      <a
                        // onClick={() => handleExcelDownload()}
                        href={`${
                          process.env.REACT_APP_BACKEND_URL
                        }/bulk_leave/?coregid=${sessionStorage.getItem(
                          "coid"
                        )}`}
                        download
                        target="_blank"
                      >
                        Download Template
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        onClick={() => handleClickOne()}
                        // data-bs-toggle="modal"
                        // data-bs-target="#experience_info"
                        // href="#"
                        // appearance="primary"
                        // onClick={openModal}
                      >
                        Upload File
                      </a>
                    </MenuItem>
                  </Menu>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    // onChange={(e) => readUploadFile(e)}
                    style={{ display: "none" }}
                  /> */}
                </div>
              )}
            </div>
          </div>
          {/* /Page Header */}

          {/* /Leave Statistics */}
          {/* Search Filter */}
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              {/* <div className="form-group">
                <input
                  type="text"
                  className="form-control input-height"
                  placeholder="Employee Name"
                  onChange={(e) =>
                    setName1(e.target.value?.toLocaleLowerCase())
                  }
                />
              </div> */}
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee Name"
                  options={[
                    ...new Set(getShiftnew?.map((item) => item.name)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee code"
                  options={[
                    ...new Set(getShiftnew?.map((item) => item.emp_code)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    empId !== ""
                      ? {
                          label: empId,
                          value: empId,
                        }
                      : null
                  }
                  onChange={(e) => setEmpId(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Location"
                  isClearable
                  options={[
                    ...new Set(
                      getShiftnew?.map((item) => item.office_location)
                    ),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    location !== ""
                      ? {
                          label: location,
                          value: location,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setLocation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Shift"
                  isClearable
                  options={[...new Set(data?.map((item) => item.name))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    leaveType !== ""
                      ? {
                          label: leaveType,
                          value: leaveType,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setLeaveType(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div> */}
            {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group">
                <input
                  className="form-control input-height"
                  type="date"
                  placeholder="From"
                  value={from1}
                  onChange={(e) => setFrom1(e.target.value)}
                />
              </div>
            </div> */}
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group">
                <input
                  className="form-control input-height"
                  type="month"
                  placeholder="From"
                  value={month1}
                  defaultValue={month1}
                  onChange={(e) => setMonth1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (
                  setName1(""),
                  setEmpId(""),
                  setTo1(""),
                  setLocation(""),
                  setLeaveType("")
                )}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          {/* /Search Filter */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    {/* <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      bordered
                      dataSource={searchData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    /> */}
                    <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns1}
                      bordered
                      exportable
                      // exportableProps={{ showColumnPicker: true }}
                      dataSource={searchData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}
        <div id="view_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  View Shift Request
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="form-group">
                      <label>
                        Shift <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        className="form-control datetimepicker"
                        type="text"
                        value={viewShiftDetails.name}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Applicable From <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="text"
                            value={viewShiftDetails.applicable_from}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={viewShiftDetails.to
                              ?.split("-")
                              .reverse()
                              .join("-")}
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="form-group">
                      <label>
                        Number of days <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        readOnly
                        type="text"
                        disabled
                        value={viewShiftDetails.noofdays}
                      />
                    </div> */}
                    {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                    <div className="form-group">
                      <label>
                        Reason for shift change{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <textarea
                        disabled
                        rows={4}
                        className="form-control"
                        value={viewShiftDetails.reason}
                      />
                    </div>
                    <div
                      className="submit-section"
                      style={{ justifyContent: "space-between" }}
                    >
                      <button
                        type="button"
                        className="btn btn-success btn-block w-100 "
                        //data-bs-dismiss="modal"
                        style={{ background: "transparent", color: "#18838D" }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        //onClick={(e) => handleApproveShift(e, true)}
                      >
                        Decline
                      </button>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                      {viewShiftDetails.status === "Pending" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveShift(e, false)}
                        >
                          Accept
                        </button>
                      )}
                      {viewShiftDetails.status === "Declined" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveShift(e, false)}
                        >
                          Accept
                        </button>
                      )}
                      {viewShiftDetails.status === "Approved by Manager" &&
                        role === 2 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveShift(e, false)}
                          >
                            Accept
                          </button>
                        )}
                      {viewShiftDetails.status === "Approved by Manager" &&
                        role === 4 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveShift(e, false)}
                          >
                            Accept
                          </button>
                        )}
                      {viewShiftDetails.status === "Approved by Manager" &&
                        role === 3 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            // onClick={(e) => handleApproveShift(e, false)}
                            disabled
                          >
                            Approved
                          </button>
                        )}
                      {viewShiftDetails.status === "Approved" && role === 3 && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          // onClick={(e) => handleApproveShift(e, false)}
                          disabled
                        >
                          Approved
                        </button>
                      )}
                      {viewShiftDetails.status === "Approved" && role === 2 && (
                        <button
                          data-bs-dismiss="modal"
                          className="btn btn-success btn-block w-100"
                          // onClick={(e) => handleApproveShift(e, false)}
                          disabled
                        >
                          Approved
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Assign Shift Modal */}

        <div
          id="add_schedule"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Assign Shift</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        <Select className="select" />
                      </div>
                    </div> */}
                    {sessionStorage.getItem("coid") === "Ram 067802" ? (
                      <>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employees Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Select
                              className="select"
                              defaultValue={[]}
                              closeMenuOnSelect={false}
                              hideSelectedOptions={false}
                              options={empList?.map((v) => {
                                return {
                                  label: v.label,
                                  value: v.value,
                                };
                              })}
                              value={shiftChange.empids}
                              onChange={(e) => handleChange1(e)}
                              // onChange={(e) => {
                              //   setShiftChange({
                              //     ...shiftChange,
                              //     empids: [e],
                              //   });
                              // }}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employees Code{" "}
                            </label>
                            <Select
                              className="select"
                              isClearable
                              placeholder="Employee code"
                              options={empCodes}
                              value={code}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="col-form-label">
                            Employees Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            className="select"
                            defaultValue={[]}
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            options={empList?.map((v) => {
                              return {
                                label: v.label,
                                value: v.value,
                              };
                            })}
                            components={{
                              Option: InputOption,
                            }}
                            allowSelectAll={true}
                            value={shiftChange.empids}
                            onChange={(e) => {
                              if (e) {
                                setShiftChange({
                                  ...shiftChange,
                                  empids: e.map((item) => item),
                                });
                              } else {
                                setShiftChange([]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Applicable from <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            min={new Date().toISOString().split("T")[0]}
                            max="9999-12-31"
                            value={shiftChange.applicable_from}
                            onChange={(e) =>
                              setShiftChange({
                                ...shiftChange,
                                applicable_from: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Shifts <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="select"
                          options={[
                            ...new Set(shifts?.map((item) => item)),
                          ].map((ele) => ({
                            label: ele.name,
                            value: ele.id,
                          }))}
                          value={shiftChange.shift}
                          onChange={(e) =>
                            setShiftChange({ ...shiftChange, shift: e })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.min_start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.max_start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.min_end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.max_end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Break Start Time{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            type="text"
                            value={times?.break_start}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Break End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            type="text"
                            value={times?.break_end}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      className="modal-submit-btn"
                      data-bs-dismiss={
                        !shiftChange.shift.value ||
                        !shiftChange.applicable_from ||
                        shiftChange.empids.length === 0
                          ? ""
                          : "modal"
                      }
                      onClick={(event) => handleShiftChange(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Upload Shift File */}

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="uploadShift"
          // tabindex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  Upload Monthly Shift
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Select Month :</b>
                    </label>
                    <input
                      class="form-control"
                      id="message-text"
                      type="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Upload File :</b>
                    </label>
                    <input
                      class="form-control"
                      id="message-text"
                      type="file"
                      style={{
                        borderRadius: "5px",
                        height: "30px",
                        textAlign: "center",
                      }}
                      // value={month}
                      onChange={(e) => setShiftFile(e.target.files[0])}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={(e) => handleShiftFile()}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Leave Modal */}

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="exampleModal"
          // tabindex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Reason:</b>
                    </label>
                    <textarea
                      class="form-control"
                      id="message-text"
                      value={declineReason}
                      onChange={(e) => setDeclineReason(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="submit-btn2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => handleApproveShift(e, true)}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Leave Modal */}
        {/* Approve Leave Modal */}
        <div
          className="modal custom-modal fade"
          id="approve_leave"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Leave Approve</h3>
                  <p>Are you sure want to approve for this leave?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Approve
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Decline
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Approve Leave Modal */}
        {/* Delete Leave Modal */}
        <Delete />
        {/* /Delete Leave Modal */}
      </div>
    </div>
  );
};

export default ShiftManage;
