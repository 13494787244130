import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import deletimg from "../../../assets/img/profiles/delete.svg";
import { Table } from "antd";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import Delete from "../../../modelbox/Delete";
import axios from "axios";

const Holidays = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [inputList, setInputList] = useState([{ occation_name: "", date: "" }]);

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const token = sessionStorage.getItem("access_token");

  const getHolidays = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/holidays_list/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddHoliday = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/holidays_list/`,
        {
          coregid: sessionStorage.getItem("coid"),
          all_holiday: inputList,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          Swal.fire({
            icon: "success",
            // title: "Oops...",
            text: "Holiday added Successfully",
          });
          setInputList([{ occation_name: "", date: "" }]);
          getHolidays();
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${
              err.response.data.status[0].toUpperCase() +
              err.response.data.status.slice(1)
            }!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  useEffect(() => {
    getHolidays();
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/holidays_list/`, {
        data: {
          coregid: sessionStorage.getItem("coid"),
          dates: [{ date: id }],
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Holiday Deleted Successfully",
        });
        getHolidays();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const removeFields = (e, index) => {
    e.preventDefault();
    let data = [...inputList];
    data.splice(index, 1);
    setInputList(data);
  };
  const addFields = (e) => {
    e.preventDefault();
    let newfield = { occation_name: "", date: "" };

    setInputList([...inputList, newfield]);
  };
  const handleFormChange = (index, name, value) => {
    let data = [...inputList];
    // console.log(event.target.name);
    data[index][name] = value;
    setInputList(data);
    console.log(inputList);
  };

  const columns = [
    {
      title: "Holyday",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) =>
          moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
      },
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          {/* <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className="submit-btn1"
            data-bs-toggle="modal"
            data-bs-target="#edit_content"
            onClick={(e) => setHzContents(text)}
          >
            Edit
          </button>{" "} */}
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className="submit-btn1"
            onClick={(e) => setDeleteId(text.date)}
            data-bs-toggle="modal"
            data-bs-target="#delete_video"
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Holidays</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_holidays"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" /> &nbsp;Add Holiday
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={data}
                      rowKey={(record) => record.id}
                      // onChange={console.log("change")}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Holidays */}
        <div
          className="modal custom-modal fade"
          id="delete_video"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Holiday</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        className="continue-btn"
                        onClick={() => handleDelete(deleteId)}
                      >
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        className="cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Add Leave Modal */}
        <div
          id="add_holidays"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Holidays</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                {inputList.map((input, index) => {
                  return (
                    <div className="row col-md-12 ">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Holiday Name</label>
                          <input
                            className=" form-control"
                            name="name"
                            placeholder="name"
                            value={input.occation_name}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                "occation_name",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <label>Date</label>
                          <input
                            className=" form-control"
                            name="date"
                            type="date"
                            value={input.date}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                "date",
                                event.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div style={{ marginTop: "22px" }}>
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={(e) => removeFields(e, index)}
                          >
                            <img width="30px" src={deletimg} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="add-fields">
                <button className="add-btn-add" onClick={(e) => addFields(e)}>
                  Add More &nbsp;+
                </button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  margin: "auto",
                  marginBottom: "15px",
                }}
              >
                <button
                  className="modal-submit-btn"
                  data-bs-dismiss="modal"
                  onClick={(e) => handleAddHoliday(e)}
                >
                  submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Holidays;
