export const eightyC = [
  {
    name: "Life Insurance Premium Paid for yourself, spouse, or children",
    field_name: "life_insure",
    value: 0,
  },
  {
    name: "Repayment of Housing Loan (Principal Component)",
    field_name: "house_repo",
    value: 0,
  },
  {
    name: "Deposit in PF/Superannuation fund",
    field_name: "pf",
    value: 0,
  },
  {
    name: "Investment in 5 yr fixed deposit/bonds",
    field_name: "fd",
    value: 0,
  },
  {
    name: "Investment in NSC",
    field_name: "nsc",
    value: 0,
  },
  {
    name: "Tuition Fee of 2 children",
    field_name: "tution",
    value: 0,
  },
  {
    name: "Stamp duty/fee for purchase of residential house",
    field_name: "stamp_duty",
    value: 0,
  },
  {
    name: "Mutual Fund/UTI and other investments eligible for 80C",
    field_name: "mutual_fund",
    value: 0,
  },
  {
    name: "Your Individual Contribution to NPS (maximum possible 10% of salary- basic+DA)",
    field_name: "nps",
    value: 0,
  },
];

export const eightyD = [
  {
    name: "Payment for Medical Insurance Premium/CGHS (for non-senior citizens) *mode other than cash (Self, Dependent Children and Spouse)",
    field_name: "cghs_family",
    value: 0,
  },
  {
    name: "Payment for Medical Insurance Premium/CGHS (for non-senior citizens) *mode other than cash (Parents)",
    field_name: "cghs_parents",
    value: 0,
  },
  {
    name: "Payment for Medical Insurance Premium (for senior citizens) (Self, Dependent Children and Spouse)",
    field_name: "medical_insurance_family",
    value: 0,
  },
  {
    name: "Payment for Medical Insurance Premium (for senior citizens) (Parents)",
    field_name: "medical_insurance_parents",
    value: 0,
  },
  {
    name: "Payments for preventive health check-ups (Self, Dependent Children and Spouse)",
    field_name: "preventive_health_family",
    value: 0,
  },
  {
    name: "Payments for preventive health check-ups (Parents)",
    field_name: "preventive_health_parents",
    value: 0,
  },
  {
    name: "Medical expenditure on the health of resident senior citizen and very senior citizen for whom no amount is paid to effect / keep in force an insurance on the health (mode of payment other than cash) (Self, Dependent Children and Spouse)",
    field_name: "medical_expenditure_family",
    value: 0,
  },
  {
    name: "Medical expenditure on the health of resident senior citizen and very senior citizen for whom no amount is paid to effect / keep in force an insurance on the health (mode of payment other than cash) (Parents)",
    field_name: "medical_expenditure_parents",
    value: 0,
  },
];

export const recome = [
  {
    key: 1,
    component: "Public Provident Fund",
  },
  {
    key: 2,
    component: "National Pension Scheme",
  },
  {
    key: 3,
    component: "Premium Paid for Life Insurance policy",
  },
  {
    key: 4,
    component: "National Savings Certificate",
  },
  {
    key: 5,
    component: "Equity Linked Savings Scheme",
  },
  {
    key: 6,
    component: "Home loan's principal amount",
  },
];

export const recomConst = {
  a: "",
  b: "You seem to focus on liquidity in your savings. ELSS provides the highest liquidity amongst all options under 80C. We recommend you to explore the same and enhance your 80C deductions to Rs 150000.",
  c: "Are you aware that medical bills for dependent parents, whose insurance has not been taken, can also be used for exemptions? Read more on the same, and try to maximise the value of your 80D exemptions.",
  d: "Mediclaim insurance for self, spouse, children and dependent parents can be taken by you, to increase the limit of exemption under 80D. Also, check out all preventive healthcare tests.",
  e: "Gratuity and voluntary retirement exemptions are there. Gifts up to Rs 5000 are exempted for you. You can plan your financial year properly with this knowledge.",
  f: "Please check with a financial advisor if you can reduce your tax liability by getting gifts and increasing allowances against activities performed for the company.",
  g: "Increasing your savings and opting for the old tax regime, with a focus on safe instruments such as NPS, PPF, 5 year fixed deposit, should be considered.",
  h: "With some donations, you can increase your exemption limits. You can choose to donate to organisations that provide 100% exemption, if they suit your ideologies",
  i: "Great to see your plans of donations to various NGOs. Kindly make sure that you check all institutions and their eligibility for 80G exemptions before making the donations.",
  j: "If you are increasing your savings in safe instruments, keep in mind that the instruments are compliant with 80C deductions.",
};
