import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Select, { components } from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
import { Table } from "antd";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
//import Addschedule from "../../../_components/modelbox/Addschedule";

const ShiftScheduling = () => {
  const [name1, setName1] = useState("");
  const [shift1, setShift1] = useState("");
  const [menu, setMenu] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [times, setTimes] = useState({});
  const [shifts, setShifts] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [shiftChange, setShiftChange] = useState({
    admid: sessionStorage.getItem("adm_id"),
    shift: { label: null, value: null },
    applicable_from: "",
    empids: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const token = sessionStorage.getItem("access_token");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/mas_leave/`, {
  //       params: {
  //         admid: sessionStorage.getItem("admid"),
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       const list = res.data || [];
  //       const firstObjs = list[0] || {};
  //       const cols = [];
  //       for (const key in firstObjs) {
  //         const col = {
  //           title: key.toLocaleUpperCase().split("_"),
  //           dataIndex: key,
  //         };
  //         cols.push(col);
  //       }
  //       setColumns(cols);
  //       setDataSource(res.data);

  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/shift/`, {
        params: { coregid: sessionStorage.getItem("coid"), draft: 1 },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setShifts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (shifts.length > 0) {
      const list = shifts.find((ele) => ele.id === shiftChange.shift?.value);
      setTimes(list);
    }
  }, [shiftChange.shift]);

  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
      });
  }, []);

  console.log(shiftChange.empids);

  const handleShiftChange = async (event) => {
    event.preventDefault();
    if (
      !shiftChange.shift.value ||
      !shiftChange.applicable_from ||
      shiftChange.empids.length === 0
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all Mandatory fields.",
        // timer: 1500,
      });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/shift_apply/`, {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          shift_id: shiftChange.shift.value,
          reason: "",
          applicable_from: shiftChange.applicable_from,
          empids: Array.prototype.map.call(shiftChange.empids, function (item) {
            return item.value;
          }),
        })
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Shift Assign Successfully",
            });
            setShiftChange({
              shift: { label: null, value: null },
              applicable_from: "",
              empids: [],
            });
            setTimes({});
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  //const [columns, setColumns] = useState([]);

  const columns = [
    {
      title: "Employee Name",
    },
    {
      title: "Exiting Shift",
    },
    {
      title: "Previous Shift",
    },
  ];

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={(value) => toggleMobileMenu()} />
        <Sidebar />
        {/* Page Wrapper */}
        <div className="page-wrapper">
          {/* <Helmet>
            <title>Shift &amp; Schedule - HRMS Admin Template</title>
            <meta name="description" content="Login page" />
          </Helmet> */}
          {/* Page Content */}
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Shift Scheduling</h3>
                </div>
                {rolePermissions["Employee Shift"] ===
                  "Edit/Create/Approve" && (
                  <div className="col-auto float-end ml-auto">
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#add_schedule"
                    >
                      {" "}
                      Assign Shifts
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* /Page Header */}
            {/* Content Starts */}
            {/* Search Filter */}
            <div className="row filter-row">
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
                <div className="form-group form-focus select-focus">
                  <Select
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    isClearable
                    //isClearable={()=>name1.clearValue()}
                    options={empList?.map((v) => {
                      return {
                        label: v.label,
                        value: v.label,
                      };
                    })}
                    value={
                      name1 !== ""
                        ? {
                            label: name1,
                            value: name1,
                          }
                        : null
                    }
                    placeholder="Employee Name"
                    onChange={(e) => setName1(e ? e.value : "")}
                    //className="select floating"
                    style={{
                      zindex: "5",
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </div>

              {/*
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                <div className="form-group form-focus select-focus">
                  <Select
                    isClearable
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    placeholder="Department"
                    // classname="select floating"
                    style={{
                      zindex: "5",
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </div>
              */}

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
                <div className="form-group form-focus select-focus">
                  <Select
                    inputId="single-select-example"
                    className="single-select"
                    classNamePrefix="react-select"
                    placeholder="Shift"
                    options={shifts?.map((v) => {
                      return {
                        label: v.name,
                        value: v.name,
                      };
                    })}
                    value={
                      shift1 !== ""
                        ? {
                            label: shift1,
                            value: shift1,
                          }
                        : null
                    }
                    onChange={(e) => setShift1(e ? e.value : "")}
                    // classname="select floating"
                    style={{
                      zindex: "5",
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </div>

              {/*
              <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                <div className="form-group  ">
                  <input
                    className="form-control input-height"
                    type="date"
                    placeholder="To"
                  />
                </div>
              </div>
              */}

              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-12">
                <a
                  href="#"
                  className="btn-block w-100"
                  style={{
                    //fontSize: "14px",
                    height: "38px",
                    //background: "#2DB8C5",
                    //color:"white"
                  }}
                  onClick={() => {
                    setName1("");
                    setShift1("");
                  }}
                >
                  <img
                    tabindex="0"
                    data-bs-toggle="tooltip"
                    title="clear all filter"
                    style={{
                      height: "38px",
                      backgroundColor: "#3298DB",
                      padding: "9px 5px 9px 5px",
                      borderRadius: "5px",
                    }}
                    src={FilterOff}
                  />
                </a>
              </div>
            </div>

            {/* Search Filter */}
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        pagination={{
                          total: dataSource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        rowSelection={{
                          type: "checkbox",
                          onSelect: (record) => {
                            console.log(record);
                          },
                        }}
                        // bordered
                        dataSource={dataSource
                          .filter(
                            (x) => x.name == (name1 == "" ? x.name : name1)
                          )
                          .filter(
                            (a) => a.shift == (shift1 == "" ? a.shift : shift1)
                          )}
                        //rowKey={(record) => record.id}
                        // onChange={console.log("change")}
                        loading={isLoading}
                      ></Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Content End */}
          </div>
          {/* /Page Content */}
        </div>
        {/* /Page Wrapper */}
        {/* Add Schedule Modal */}
        <div
          id="add_schedule"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Assign Shift</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        <Select className="select" />
                      </div>
                    </div> */}
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">
                          Employees Name <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="select"
                          defaultValue={[]}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          options={empList?.map((v) => {
                            return {
                              label: v.label,
                              value: v.value,
                            };
                          })}
                          components={{
                            Option: InputOption,
                          }}
                          allowSelectAll={true}
                          value={shiftChange.empids}
                          onChange={(e) => {
                            if (e) {
                              setShiftChange({
                                ...shiftChange,
                                empids: e.map((item) => item),
                              });
                            } else {
                              setShiftChange([]);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Applicable from <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                            min={new Date().toISOString().split("T")[0]}
                            max="9999-12-31"
                            value={shiftChange.applicable_from}
                            onChange={(e) =>
                              setShiftChange({
                                ...shiftChange,
                                applicable_from: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Shifts <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="select"
                          options={[
                            ...new Set(shifts?.map((item) => item)),
                          ].map((ele) => ({
                            label: ele.name,
                            value: ele.id,
                          }))}
                          value={shiftChange.shift}
                          onChange={(e) =>
                            setShiftChange({ ...shiftChange, shift: e })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.min_start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max Start Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.max_start_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.min_end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            value={times?.max_end_time}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Break Start Time{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            type="text"
                            value={times?.break_start}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Break End Time <span className="text-danger">*</span>
                        </label>
                        <div className="input-group time timepicker">
                          <input
                            className="form-control"
                            type="text"
                            value={times?.break_end}
                            disabled
                          />
                          <span className="input-group-append input-group-addon">
                            <span className="input-group-text">
                              <i className="fa fa-clock-o" />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      className="modal-submit-btn"
                      data-bs-dismiss={
                        !shiftChange.shift.value ||
                        !shiftChange.applicable_from ||
                        shiftChange.empids.length === 0
                          ? ""
                          : "modal"
                      }
                      onClick={(event) => handleShiftChange(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <Addschedule /> */}
        {/* /Add Schedule Modal */}
        {/* Edit Schedule Modal */}
        <div
          id="edit_schedule"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Edit Schedule</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Department <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option value>Select</option>
                          <option>Development</option>
                          <option value={1}>Finance</option>
                          <option value={2}>Finance and Management</option>
                          <option value={3}>Hr &amp; Finance</option>
                          <option value={4}>ITech</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Employee Name <span className="text-danger">*</span>
                        </label>
                        <Select className="select" options={empList} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Date <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          Shifts <span className="text-danger">*</span>
                        </label>
                        <select className="select">
                          <option value>Select </option>
                          <option value={1}>10'o clock Shift</option>
                          <option value={2}>10:30 shift</option>
                          <option value={3}>Daily Shift </option>
                          <option value={4}>New Shift</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min Start Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="06:11 am"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Start Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="06:30 am"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max Start Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="08:12 am"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Min End Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="09:12 pm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          End Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="09:30 pm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Max End Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="09:45 pm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label className="col-form-label">
                          Break Time <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={45}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          Recurring Shift
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Repeat Every</label>
                        <select className="select">
                          <option value>1 </option>
                          <option value={1}>2</option>
                          <option value={2}>3</option>
                          <option value={3}>4</option>
                          <option value={4}>5</option>
                          <option value={3}>6</option>
                        </select>
                        <label className="col-form-label">Week(s)</label>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group wday-box">
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="monday"
                            className="days recurring"
                            defaultChecked
                          />
                          <span className="checkmark">M</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="tuesday"
                            className="days recurring"
                            defaultChecked
                          />
                          <span className="checkmark">T</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="wednesday"
                            className="days recurring"
                            defaultChecked
                          />
                          <span className="checkmark">W</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="thursday"
                            className="days recurring"
                            defaultChecked
                          />
                          <span className="checkmark">T</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="friday"
                            className="days recurring"
                            defaultChecked
                          />
                          <span className="checkmark">F</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="saturday"
                            className="days recurring"
                          />
                          <span className="checkmark">S</span>
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="checkbox"
                            name="week_days[]"
                            defaultValue="sunday"
                            className="days recurring"
                          />
                          <span className="checkmark">S</span>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="col-form-label">
                          End On <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck2"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck2"
                        >
                          Indefinite
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">
                          Accept Extra Hours{" "}
                        </label>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch3"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch3"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label className="col-form-label">Publish </label>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch4"
                            defaultChecked
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch4"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className=" modal-submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Schedule Modal */}
    </>
  );
};

export default ShiftScheduling;
