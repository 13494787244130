import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap";
import "react-circular-progressbar/dist/styles.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "font-awesome/css/font-awesome.min.css";

// import "../assets/css/font-awesome.min.css";
import "../src/assets/css/font-awesome.min.css";
import "../src/assets/css/line-awesome.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/bootstrap.min.css";

// Custom Style File
// import "../src/assets/js/bootstrap.bundle.js";
import "../src/assets/css/select2.min.css";

// import "../src/assets/js/popper.min.js";
// import "../src/assets/js/app.js";
// import "../src/assets/js/select2.min.js";

// import "../src/assets/js/bootstrap-datetimepicker.min.js";

// import "../src/assets/js/multiselect.min.js";
import "../src/assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css";
import "../src/assets/css/bootstrap-datetimepicker.min.css";
import "../src/assets/css/style.css";
window.Popper = require("popper.js").default;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {" "}
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
