import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Calendar from "../../components/Calender/calendar";
import Attendancetable from "./Attendancetable";
import Select from "react-select";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import { appendErrors } from "react-hook-form";
import moment from "moment";
import { Radio, TextField } from "@mui/material";
import absent from "../../assets/img/profiles/absent.png";
import half1 from "../../assets/img/profiles/half.png";
import late from "../../assets/img/profiles/late.png";
import tw from "../../assets/img/profiles/total.png";
import wd from "../../assets/img/profiles/worked.png";
import aw from "../../assets/img/profiles/average.png";
import on_leaves from "../../assets/img/profiles/leave.png";
import early from "../../assets/img/profiles/early.png";

const Attendancegrid = () => {
  const today = new Date();
  const year = today.getFullYear();
  const [Year, setYear] = useState(year);
  const [Month, setMonth] = useState("");
  const [half, setHalf] = useState("");
  const [OptionDates, setOptionDates] = useState([]);
  const [date, setDate] = useState("");
  const [menu, setMenu] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [managerList, setManagerList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [kpi, setKpi] = useState({});
  // absent: {
  //   total: 2,
  //   percent: "50%",
  //   improved: false,
  // },
  // leave: {
  //   total: 2,
  //   percent: "50%",
  //   improved: true,
  // },
  // half: {
  //   total: 2,
  //   percent: "50%",
  //   improved: false,
  // },
  // late: {
  //   total: 2,
  //   percent: "50%",
  //   improved: false,
  // },
  // early: {
  //   total: 2,
  //   percent: "50%",
  //   improved: false,
  // },
  // total_wh: {
  //   total: "35:00:00",
  //   percent: "10%",
  //   improved: true,
  // },
  // worked: {
  //   total: 2,
  //   percent: "50%",
  //   improved: false,
  // },
  // average_wh: {
  //   total: "07:00:00",
  //   percent: "20%",
  //   improved: true,
  // },

  const [empLeaveData, setEmpLeaveData] = useState({
    empid: sessionStorage.getItem("emp_id"),
    manager: { label: null, value: null },
    form: [],
    from_date: "",
    to_date: "",
    geo_loc: "",
    reason: "",
    half: "",
    clockin_tm:"",
    clockout_tm:"",
    location: { label: null, value: null },
    mood: { label: "Happy", value: "Happy" },
  });

  const months = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Feb" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Apr" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "Aug" },
    { value: 9, label: "Sept" },
    { value: 10, label: "Oct" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dec" },
  ];

  const cleanLeaveData = () => {
    setEmpLeaveData({
      empid: sessionStorage.getItem("emp_id"),
      manager: { label: null, value: null },
      from: [],
      from_date: "",
      to_date: "",
      geo_loc: "",
      reason: "",
      location: { label: null, value: null },
      mood: { label: "Happy", value: "Happy" },
    });
  };
  const [applyLeave, setApplyLeave] = useState("wfh");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const work_location = [
    { label: "Office", value: "Office" },
    { label: "Outdoor", value: "Outdoor" },
    { label: "Work From Home", value: "Work From Home" },
  ];

  const work_mood = [
    { label: "Sad", value: "Sad" },
    { label: "Down", value: "Down" },
    { label: "Indifferent", value: "Indifferent" },
    { label: "Curious", value: "Curious" },
    { label: "Happy", value: "Happy" },
    { label: "Cheerful", value: "Cheerful" },
  ];

  const inputRef = useRef(null);

  const getAttendance = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/calendar_list_v1/`,
        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            // table_toggle: "y",
            year: Year,
            month: Month,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setOptionDates(
        res.data
          .filter((v) => v.name === "Absent")
          .map((ele) => ({
            label: ele.date,
            value: ele.date,
          }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttendance();
  }, [Month, Year]);

  const handleWorkFromHome = (e) => {
    e.preventDefault();
    if (
      !empLeaveData.reason ||
      !empLeaveData.manager.value ||
      !empLeaveData.from_date ||
      !empLeaveData.to_date
    ) {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fiil all mandatory fields.",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_wfh/`,
          {
            empid: empLeaveData.empid,
            manager: empLeaveData.manager.value,
            from_date: empLeaveData.from_date,
            to_date: empLeaveData.to_date,
            reason: empLeaveData.reason,
          },
          {
            // params: {
            //   empid: sessionStorage.getItem("emp_id"),
            // },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Work From Home applied successfully",
              // timer: 1500,
            });
          }
          cleanLeaveData();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            //title: "oops..",
            text: err.response?.data?.error
              ? `${
                  err.response.data.error[0].toUpperCase() +
                  err.response.data.error.slice(1)
                }!`
              : "Something Went Wrong",
            // timer: 1500,
          });
        });
    }
  };

  const handleHalfDay = (e) => {
    e.preventDefault();
    if (
      !empLeaveData.reason ||
      !half ||
      !empLeaveData.manager.value ||
      !empLeaveData.from_date
    ) {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fiil all mandatory fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_halfday/`,
          {
            empid: empLeaveData.empid,
            manager: empLeaveData.manager.value,
            on_date: empLeaveData.from_date,
            reason: empLeaveData.reason,
            half: half,
          },
          {
            // params: {
            //   empid: sessionStorage.getItem("emp_id"),
            // },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Half Day applied successfully",
              // timer: 1500,
            });
          }
          cleanLeaveData();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: err.message,
              // text: "You can't apply two leaves for the same day(s)!",
            });
          }
        });
    }
  };

  const handleCompOff = (e) => {
    e.preventDefault();
    if (
      !empLeaveData.reason ||
      !empLeaveData.manager.value ||
      !empLeaveData.from_date ||
      !empLeaveData.to_date
    ) {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fiil all mandatory fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_camp_off/`,
          {
            empid: empLeaveData.empid,
            manager: empLeaveData.manager.value,
            from_date: empLeaveData.from_date,
            to_date: empLeaveData.to_date,
            reason: empLeaveData.reason,
          },
          {
            // params: {
            //   empid: sessionStorage.getItem("emp_id"),
            // },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Comp Off Leave applied successfully",
              // timer: 1500,
            });
          }
          cleanLeaveData();
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            //title: "oops..",
            text: err.response?.data?.error
              ? `${
                  err.response.data.error[0].toUpperCase() +
                  err.response.data.error.slice(1)
                }!`
              : "Something Went Wrong",
            // timer: 1500,
          });
        });
    }
  };

  const handleRegular = (e) => {
    console.log(empLeaveData);
    e.preventDefault();
    if (
      !empLeaveData.reason ||
      !empLeaveData.mood.value ||
      !empLeaveData.manager.value
    ) {
      Swal.fire({
        icon: "error",
        //title: "oops..",
        text: "Please fill all mandatory fields.",
        // timer: 1500,
      });
    } else if (
      empLeaveData?.date?.length === 0
      // !OptionDates.map((v) => v.value).includes(empLeaveData.from_date)
    ) {
      Swal.fire({
        icon: "error",
        title: "oops..",
        text: "Please fill all mandatory fields. one",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/attendance/`,
          {
            apply_toggle: "y",
            // shift_from: sessionStorage.getItem("shift_from"),
            empid: empLeaveData.empid,
            manager: empLeaveData.manager.value,
            // date: empLeaveData.from_date,
            date: empLeaveData.from.map((ele) => ele.value),
            location: empLeaveData.location.value,
            mood: empLeaveData.mood.value,
            reason: empLeaveData.reason,
            key:`R`,
            clockout_tm:`${empLeaveData.from[0].value} ${empLeaveData.clockout_tm}`,
            clockin_tm:`${empLeaveData.from[0].value} ${empLeaveData. clockin_tm}`
          },
          {
            // params: {
            //   empid: sessionStorage.getItem("emp_id"),
            // },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Regularise applied successfully",
              // timer: 1500,
            });
          }
          cleanLeaveData();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            //title: "oops..",
            text: err.response?.data?.error
              ? `${
                  err.response.data.error[0].toUpperCase() +
                  err.response.data.error.slice(1)
                }!`
              : "Something Went Wrong",
            // timer: 1500,
          });
        });
    }
  };

  // const dummyData = [
  //   {
  //     name: "Absent Days",
  //     total: 2,
  //     percent: "50",
  //     improved: true,
  //   },
  //   {
  //     name: "On Leave",
  //     total: 1,
  //     percent: "50",
  //     improved: false,
  //   },
  //   {
  //     name: "Half Days",
  //     total: 3,
  //     percent: "50",
  //     improved: true,
  //   },
  //   {
  //     name: "Late In",
  //     total: 2,
  //     percent: "50",
  //     improved: false,
  //   },
  //   {
  //     name: "Early Out",
  //     total: 2,
  //     percent: "50",
  //     improved: true,
  //   },
  //   {
  //     name: "Total WH",
  //     total: "35:00:00",
  //     percent: "50",
  //     improved: false,
  //   },
  //   {
  //     name: "Day(s) Worked",
  //     total: 24,
  //     percent: "50",
  //     improved: true,
  //   },
  //   {
  //     name: "Average WH",
  //     total: "07:00:00",
  //     percent: "50",
  //     improved: true,
  //   },
  // ];

  const handleGetkpi = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/attendance/`, {
        params: {
          year: monthValue.split("-")[0],
          month: monthValue.split("-")[1],
          toggle: "ii",
          // range_from: moment(state[0].startDate).format("YYYY-MM-DD"),
          // range_to: moment(state[0].endDate).format("YYYY-MM-DD"),
          empid: sessionStorage.getItem("emp_id"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setKpi(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetkpi();
  }, [monthValue]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Attendance</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <Link
                    // to="/attendance-grid"
                    className={
                      showTable
                        ? "grid-view btn btn-link "
                        : "grid-view btn btn-link active"
                    }
                    onClick={() => setShowTable(false)}
                  >
                    <i className="fa fa-th" />
                  </Link>
                  <Link
                    // to="/attendance-list"
                    className={
                      showTable
                        ? "list-view btn btn-link active"
                        : "grid-view btn btn-link "
                    }
                    onClick={() => setShowTable(true)}
                  >
                    <i className="fa fa-bars" />
                  </Link>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #973FCF",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    href="#"
                    classname="edit-icon"
                    onClick={() => setApplyLeave("wfh")}
                  >
                    Work from home
                  </button>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #ED9D44",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    onClick={() => {
                      setApplyLeave("regular");
                    }}
                  >
                    Regularise
                  </button>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #3298DB",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    onClick={() => setApplyLeave("compoff")}
                  >
                    Comp Off Leave
                  </button>
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #2b8f6f",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    onClick={() => {
                      setApplyLeave("halfday");
                    }}
                  >
                    Half Day
                  </button> */}
                  {/* <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#FF7676",
                      border: "none",
                      color: "white",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#Leave_info_modal"
                    onClick={() => setApplyLeave("shiftchange")}
                  >
                    Shift Change
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row col-md-12">
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info  "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Absent Days </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.absent?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.absent?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.absent?.improved
                        ? `+${kpi.absent?.percent}`
                        : `-${kpi.absent?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={absent} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">On Leave </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.leave?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.leave?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.leave?.improved
                        ? `+${kpi.leave?.percent}`
                        : `-${kpi.leave?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={on_leaves} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Half Days </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.half?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.half?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.half?.improved
                        ? `+${kpi.half?.percent}`
                        : `-${kpi.half?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={half1} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Late In </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.late?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.late?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.late?.improved
                        ? `+${kpi.late?.percent}`
                        : `-${kpi.late?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={late} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Early Out </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.early?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={kpi.early?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.early?.improved
                        ? `+${kpi.early?.percent}`
                        : `-${kpi.early?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className="kpi-img" src={early} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Total Working Hours </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.total_wh?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.total_wh?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.total_wh?.improved
                        ? `+${kpi.total_wh?.percent}`
                        : `-${kpi.total_wh?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className="kpi-img" src={tw} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Day(s) Worked </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">{kpi.worked?.total}</h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.worked?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.worked?.improved
                        ? `+${kpi.worked?.percent}`
                        : `-${kpi.worked?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={wd} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
            <div
              className="col-md-3 "
              // style={{ width: "210px" }}
            >
              <div
                className="stats-info col-md-12 "
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-md-9 ">
                  <h4 className="kpi-head">Average Working Hours </h4>
                  <div style={{ display: "flex" }}>
                    <h4 className="kpi-value">
                      {kpi.average_wh ? Math.round(kpi.average_wh.total) : 0}
                    </h4>
                    &nbsp;&nbsp;
                    {/* <h5
                      className={
                        kpi.average_wh?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.average_wh?.improved
                        ? `+${kpi.average_wh?.percent}`
                        : `-${kpi.average_wh?.percent}`}
                    </h5> */}
                  </div>
                </div>
                <div className="col-md-3 kpi-img-box">
                  <img className=" kpi-img" src={aw} alt="" />
                </div>

                {/* <CircularStatic /> */}
                <div
                // style={{ width: 100, height: 100, marginLeft: "28px" }}
                ></div>
              </div>
            </div>
          </div>

          {!showTable && <Calendar />}
          {showTable && (
            <Attendancetable
              monthValue={monthValue}
              setMonthValue={setMonthValue}
            />
          )}
        </div>
      </div>
      {/* /Page Content */}

      <div
        id="Leave_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                {" "}
                {applyLeave === "wfh"
                  ? "Apply for Work from Home"
                  : applyLeave === "regular"
                  ? "Apply to Regularise Attendance"
                  : applyLeave === "compoff"
                  ? "Apply for Comp off Leave"
                  : "Apply for Half Day"}{" "}
              </h5>
              {/* <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button> */}
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                onClick={() => cleanLeaveData()}
              >
                <img src={closeIcon} alt="" />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Select Manager <span className="text-danger">*</span>
                      </label>
                      {/* <select className="select form-control">
                          <option>Aman</option>
                          <option>Anuj</option>
                          <option>Jagdesh</option>
                        </select> */}
                      <Select
                        options={managerList}
                        value={empLeaveData.manager}
                        onChange={(e) =>
                          setEmpLeaveData({ ...empLeaveData, manager: e })
                        }
                      />
                    </div>
                  </div>
                  {applyLeave === "regular" && (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year</label>{" "}
                          <span className="text-danger">*</span>
                          <TextField
                            type="number"
                            maxLength="4"
                            pattern="[1-9]\d*"
                            size="small"
                            defaultValue={year}
                            onChange={(e) => setYear(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Month <span className="text-danger">*</span>
                          </label>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            options={months}
                            onChange={(e) => setMonth(e.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            isMulti
                            isDisabled={!Month}
                            ref={inputRef}
                            options={OptionDates}
                            onChange={(e) => {
                              if (e) {
                                setEmpLeaveData({
                                  ...empLeaveData,
                                  from: e.map((item) => item),
                                });
                              } else {
                                setEmpLeaveData([]);
                              }
                            }}
                            // onChange={(e) =>
                            //   setEmpLeaveData({
                            //     ...empLeaveData,
                            //     from_date: e.value,
                            //   })
                            // }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {applyLeave === "regular" ? (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Location</label>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            options={work_location}
                            value={empLeaveData.location}
                            onChange={(e) =>
                              setEmpLeaveData({ ...empLeaveData, location: e })
                            }
                          />
                        </div>
                      </div>
                      {sessionStorage.getItem("coid")!=="RAWJ225129" &&
                      <>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Mood</label>{" "}
                          <span className="text-danger">*</span>
                          {/* <input className="form-control" type="date" /> */}
                          <Select
                            options={work_mood}
                            value={empLeaveData.mood}
                            onChange={(e) =>
                              setEmpLeaveData({ ...empLeaveData, mood: e })
                            }
                          />
                        </div>
                      </div>
                      </>
}
                    </>
                  ) : (
                    <>
                      <div className="col-md-6">
                        <div className="form-group">
                          {applyLeave !== "halfday" ? (
                            <label>
                              From<span className="text-danger">*</span>
                            </label>
                          ) : (
                            <label>
                              Date<span className="text-danger">*</span>
                            </label>
                          )}
                          <div>
                            <input
                              className="form-control date"
                              type="date"
                              min={new Date().toISOString().split("T")[0]}
                              max={"9999-12-31"}
                              value={empLeaveData.from_date}
                              onChange={(e) =>
                                setEmpLeaveData({
                                  ...empLeaveData,
                                  from_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {applyLeave === "halfday" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <div style={{ marginTop: "18px" }}>
                              <Radio
                                value="fs"
                                checked={half === "fs"}
                                onChange={(e) => setHalf(e.target.value)}
                                sx={{ m: 0, p: 0.5 }}
                              />
                              <label>First Half</label>

                              <Radio
                                value="sn"
                                checked={half === "sn"}
                                onChange={(e) => setHalf(e.target.value)}
                                sx={{ m: 0, p: 0.5 }}
                              />
                              <label>Second Half</label>
                            </div>
                          </div>
                        </div>
                      )}

                      {applyLeave !== "halfday" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              To<span className="text-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              max={"9999-12-31"}
                              disabled={!empLeaveData.from_date}
                              //min={empLeaveData.from_date}
                              value={empLeaveData.to_date}
                              onChange={(e) =>
                                setEmpLeaveData({
                                  ...empLeaveData,
                                  to_date: e.target.value,
                                })
                              }
                              min={moment(empLeaveData.from_date).format(
                                "YYYY-MM-DD"
                              )}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Reason<span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        className="form-control"
                        type="long-text"
                        maxlength="100"
                        style={{ height: "40px" }}
                        value={empLeaveData.reason}
                        onChange={(e) =>
                          setEmpLeaveData({
                            ...empLeaveData,
                            reason: e.target.value,
                          })
                        }
                      />
                    </div>
                    
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Clock In Time
                      </label>
                      <input
                       step="2"
                        className="form-control"
                        type="time"
                        maxlength="100"
                        style={{ height: "40px" }}
                        value={empLeaveData.clockin_tm}
                        onChange={(e) =>
                          setEmpLeaveData({
                            ...empLeaveData,
                            clockin_tm: e.target.value,
                          })
                        }
                      />
                    </div>
                    
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Clock Out Time
                      </label>
                      <input
                       step="2"
                        className="form-control"
                        type="time"
                        maxlength="100"
                        style={{ height: "40px" }}
                        value={empLeaveData.clockout_tm}
                        onChange={(e) =>
                          setEmpLeaveData({
                            ...empLeaveData,
                            clockout_tm: e.target.value,
                          })
                        }
                      />
                    </div>
                    
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className=" btn"
                    style={{
                      background: "#3298DB",
                      color: "white",
                      width: "94px",
                      height: "38px",
                      borderRadius: "5px",
                      fontSize: "16px",
                    }}
                    data-bs-dismiss={
                      applyLeave === "wfh"
                        ? applyLeave === "wfh" &&
                          (empLeaveData.reason === "" ||
                            !empLeaveData.manager.value ||
                            empLeaveData.from_date === "" ||
                            empLeaveData.to_date === "")
                          ? ""
                          : "modal"
                        : applyLeave === "halfday"
                        ? applyLeave === "halfday" &&
                          (empLeaveData.reason === "" ||
                            !empLeaveData.manager.value ||
                            empLeaveData.from_date === "" ||
                            !half)
                          ? ""
                          : "modal"
                        : applyLeave === "compoff"
                        ? applyLeave === "compoff" &&
                          (empLeaveData.reason === "" ||
                            !empLeaveData.manager.value ||
                            empLeaveData.from_date === "" ||
                            empLeaveData.to_date === "")
                          ? ""
                          : "modal"
                        : applyLeave === "regular"
                        ? applyLeave === "regular" &&
                          (empLeaveData.reason === "" ||
                            !empLeaveData.manager.value ||
                            empLeaveData.from?.length === 0 ||
                            !empLeaveData.mood.value)
                          ? ""
                          : "modal"
                        : "modal"
                    }
                    //data-bs-dismiss="modal"
                    onClick={(e) =>
                      applyLeave === "wfh"
                        ? handleWorkFromHome(e)
                        : applyLeave === "compoff"
                        ? handleCompOff(e)
                        : applyLeave === "halfday"
                        ? handleHalfDay(e)
                        : handleRegular(e)
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendancegrid;
