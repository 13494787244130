import "gantt-task-react/dist/index.css";

import React from "react";
import { ViewMode } from "gantt-task-react";

export const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  view,
  setView,
}) => {
  return (
    <div className="ViewContainer">
      <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        Show Task List
      </div>
      <button
        className={view === ViewMode.Hour ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.Hour)}
      >
        Hour
      </button>
      <button
        className={view === ViewMode.QuarterDay ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.QuarterDay)}
      >
        Quarter of Day
      </button>
      <button
        className={view === ViewMode.HalfDay ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.HalfDay)}
      >
        Half of Day
      </button>
      <button
        className={view === ViewMode.Day ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        Day
      </button>
      <button
        className={view === ViewMode.Week ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        Week
      </button>
      <button
        className={view === ViewMode.Month ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        Month
      </button>
      <button
        className={view === ViewMode.Year ? "Button2" : "Button1"}
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        Year
      </button>
    </div>
  );
};
