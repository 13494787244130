import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { ConfigProvider, Table } from "antd";
import "antd/dist/antd.css";
import empty from "../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import Delete from "../../modelbox/Delete";
import axios from "axios";

const FaceRecognition = () => {
  const [empData, setEmpData] = useState({});
  const [menu, setMenu] = useState(false);
  const [disable, setDisable] = useState(true);
  const [file, setFile] = useState("");
  const token = sessionStorage.getItem("access_token");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpData(res.data);
        setDisable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const handleFaceRegistration = (e) => {
  //   e.preventdefault();
  // };

  const handleFaceRegistration = async (e) => {
    // e.preventdefault();
    if (file == "") {
      Swal.fire({
        icon: "info",
        text: "Please upload your photo",
      });
    } else {
      let formData = new FormData();
      formData.append("emp_pic", file);
      formData.append("name", empData.name);
      formData.append("empid", empData.empl_id);
      formData.append("emp_email", empData.email);
      formData.append("desg", empData?.designation?.label);
      formData.append("dept", empData?.department?.label);

      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/mas_leavesdsd/`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          Swal.fire({
            icon: "success",
            // title: "Oops...",
            text: "Registration Successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: "Something went wrong",
          });
        });
    }
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Face Recognition</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  className="card-body"
                  style={{
                    width: "90%",
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="table-responsive">
                    <h4 style={{ textAlign: "center", marginBottom: "45px" }}>
                      Registration Form
                    </h4>

                    <div className="row col-md-12">
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Employee ID</label>
                        <div>
                          <input
                            readOnly={true}
                            className="form-control "
                            type="text"
                            value={empData?.empl_id}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Employee Name </label>
                        <div>
                          <input
                            readOnly={true}
                            className="form-control "
                            type="text"
                            value={empData?.name}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Employee Email</label>
                        <div>
                          <input
                            readOnly={true}
                            className="form-control"
                            type="text"
                            value={empData?.email}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Employee Designation </label>
                        <div>
                          <input
                            readOnly={true}
                            className="form-control "
                            type="text"
                            value={empData?.designation?.label}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Employee Department</label>
                        <div>
                          <input
                            readOnly={true}
                            className="form-control "
                            type="text"
                            value={empData?.department?.label}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        {" "}
                        <label>Upload Employee photo</label>
                        <div>
                          <input
                            className="form-control upload-photo"
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group">
                        <label>
                          Reason for Shift change{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <textarea
                          rows={4}
                          //   value={applyShift.reason}
                          className="form-control"
                          maxlength="100"
                          //   onChange={(e) =>
                          //     setApplyShift({
                          //       ...applyShift,
                          //       reason: e.target.value,
                          //     })
                          //   }
                        />
                      </div> */}
                    <div className="submit-section">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          gap: "50px",
                          marginTop: "30px",
                          marginBottom: "50px",
                        }}
                      >
                        <button
                          className=" btn"
                          disabled
                          style={{
                            // background: "#3298DB",
                            border: "1px solid #3298DB",
                            color: "#3298DB",
                            display: "flex",
                            textAlign: "center",
                            fontWeight: "600",
                            padding:
                              "var(--dimensions-corner-radius-2-xl, 3px) 80px",
                            height: "32px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          //   onClick={(event) => handleApplyShift(event)}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={disable}
                          className=" btn"
                          style={{
                            background: "#3298DB",
                            textAlign: "center",
                            padding:
                              "var(--dimensions-corner-radius-2-xl, 3px) 80px",
                            fontWeight: "600",
                            color: "white",
                            // width: "120px",
                            height: "32px",
                            // borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleFaceRegistration(event)}
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaceRecognition;
