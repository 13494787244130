export const trending = {
  video: [
    {
      img: "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      title: "Sample Video1",
      desc: "This is Sample Video. for test",
    },
    {
      img: "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      title: "Sample Video1",
      desc: "This is Sample Video. for test",
    },
    {
      img: "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      title: "Sample Video1",
      desc: "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      img: "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      title: "Sample Video1",
      desc: "This is Sample Video. for test",
    },
    {
      img: "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      title: "Sample Video1",
      desc: "This is Sample Video. for test",
    },
  ],
  itemsToShow: 4,
  expanded: false,
};

export const data = {
  HAPPY: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  STRESS: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  SAD: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  CALM: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  ANGRY: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  BUSY: [
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description:
        "This is Sample Video.   for test This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
    {
      video_url:
        "https://img.freepik.com/free-vector/joystick-game-sport-technology_138676-2045.jpg?w=2000",
      name: "Sample Video1",
      description: "This is Sample Video. for test",
    },
  ],
  itemsToShow: 4,
  expanded: false,
};
