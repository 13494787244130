import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import { Avatar_09, Avatar_02,Avatar_03, Avatar_05, Avatar_08, Avatar_10,Avatar_15,Avatar_20, Avatar_24,Avatar_25  } from "../../../Entryfile/imagepath"
import Avatar_02 from "../../../assets/img/profiles/smile-icon.svg";
import { Table } from "antd";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
import moment from "moment";

const TransferAdmin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [declineReason, setDeclineReason] = useState("");
  const [menu, setMenu] = useState(false);
  const [viewTransferDetails, setViewTransferDetails] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [name1, setName1] = useState("");
  const [status1, setStatus1] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [from1, setFrom1] = useState("");
  const [to1, setTo1] = useState("");
  const [searchData, setSearchData] = useState([]);

  const getTransfer = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/transfer/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele) => ({
            ...ele,
            transfer_type: ele.permanent === true ? "Permanent" : "Temporary",
          }))
        );
        setSearchData(
          res.data.map((ele) => ({
            ...ele,
            transfer_type: ele.permanent === true ? "Permanent" : "Temporary",
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTransfer();
  }, []);

  const handleApproveTransfer = (e, value) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/approve/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          approval_id: viewTransferDetails.approval_id,
          decline: value,
          reason: declineReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text:
            res.data.error === "Already Approved"
              ? "Transfer Approved Successfully"
              : "Transfer Declined Successfully",
        });
        getTransfer();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  useEffect(() => {
    handleSearch();
  }, [name1, from1, to1, status1, leaveType]);

  const handleSearch = () => {
    const newData = data
      .filter((x) => x.name == (name1 == "" ? x.name : name1))
      .filter((a) => a.date_from == (from1 == "" ? a.date_from : from1))
      .filter((a) => a.date_to == (to1 == "" ? a.date_to : to1))
      .filter((y) => y.status == (status1 == "" ? y.status : status1))
      .filter(
        (z) =>
          z.transfer_type == (leaveType == "" ? z.transfer_type : leaveType)
      );
    setSearchData(newData);
  };

  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });
  const columns = [
    {
      title: "Emp ID",
      dataIndex: "key",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Name of Employee",
      dataIndex: "name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Current Location",
      dataIndex: "curr_location",
      sorter: (a, b) => a.curr_location.localeCompare(b.curr_location),
    },

    {
      title: "Transfer Location",
      dataIndex: "office_to",
      sorter: (a, b) => a.office_to.localeCompare(b.office_to),
    },
    {
      title: "Transfer Type",
      dataIndex: "transfer_type",
      sorter: (a, b) => a.transfer_type.localeCompare(b.transfer_type),
    },

    {
      title: "From",
      dataIndex: "date_from",
      sorter: {
        compare: (a, b) =>
          moment(a.date_from, "YYYY-MM-DD") - moment(b.date_from, "YYYY-MM-DD"),
      },
    },
    {
      title: "To",
      dataIndex: "date_to",
      sorter: {
        compare: (a, b) =>
          moment(a.date_to, "YYYY-MM-DD") - moment(b.date_to, "YYYY-MM-DD"),
      },
    },

    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   sorter: (a, b) => a.reason.length - b.reason.length,
    //   ellipsis: true,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#view_transfer"
          className=" btn-primary submit-btn1"
          onClick={(e) => setViewTransferDetails(text)}
        >
          View
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Transfer</h3>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}
          {/* <div className="row">
            <div className="col-md-3">
              <div className="stats-info">
                <h6>Today Presents</h6>
                <h4>12 / 60</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="stats-info">
                <h6>Planned Leaves</h6>
                <h4>
                  8 <span>Today</span>
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="stats-info">
                <h6>Unplanned Leaves</h6>
                <h4>
                  0 <span>Today</span>
                </h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="stats-info">
                <h6>Pending Requests</h6>
                <h4>12</h4>
              </div>
            </div>
          </div> */}
          {/* /Leave Statistics */}
          {/* Search Filter */}
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  isClearable
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  //isClearable={()=>name1.clearValue()}
                  placeholder="Employee Name"
                  options={[...new Set(data?.map((item) => item.name))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  isClearable
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  placeholder="Transfer Status"
                  options={[...new Set(data?.map((item) => item.status))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    status1 !== ""
                      ? {
                          label: status1,
                          value: status1,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setStatus1(e ? e.value : "");
                  }}
                  // classname="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Transfer Type"
                  options={[
                    ...new Set(data?.map((item) => item.transfer_type)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    leaveType !== ""
                      ? {
                          label: leaveType,
                          value: leaveType,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setLeaveType(e.value);
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group">
                <input
                  className="form-control input-height"
                  type="date"
                  placeholder="From"
                  value={from1}
                  onChange={(e) => setFrom1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group  ">
                <input
                  className="form-control input-height"
                  type="date"
                  placeholder="To"
                  value={to1}
                  onChange={(e) => setTo1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block w-100"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  //background: "#2DB8C5",
                  //color:"white"
                }}
                onClick={() => (
                  setName1(""),
                  setFrom1(""),
                  setTo1(""),
                  setStatus1(""),
                  setLeaveType("")
                )}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          {/* /Search Filter */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={searchData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Add Leave Modal */}

        <div
          id="view_transfer"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  View Transfer
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="form-group">
                      <label>
                        Transfer Type <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        className="form-control datetimepicker"
                        type="text"
                        value={
                          viewTransferDetails.permanent
                            ? "Permanent"
                            : "Temporary"
                        }
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          From <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={viewTransferDetails.date_from}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={viewTransferDetails.date_to}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Number of days <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        readOnly
                        type="text"
                        disabled
                        value={viewTransferDetails.noofdays}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                    <div className="form-group">
                      <label>
                        Transfer Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        disabled
                        rows={4}
                        className="form-control"
                        value={viewTransferDetails.reason}
                      />
                    </div>
                    <div
                      className="submit-section"
                      style={{ justifyContent: "space-between" }}
                    >
                      <button
                        type="button"
                        className="btn btn-success btn-block w-100 "
                        //data-bs-dismiss="modal"
                        style={{ background: "transparent", color: "#18838D" }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={(e) => handleApproveTransfer(e, true)}
                      >
                        Decline
                      </button>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                      {viewTransferDetails.status === "Pending" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveTransfer(e, false)}
                        >
                          Accept
                        </button>
                      )}
                      {viewTransferDetails.status === "Declined" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveTransfer(e, false)}
                        >
                          Accept
                        </button>
                      )}
                      {viewTransferDetails.status === "Approved by Manager" &&
                        role === 2 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveTransfer(e, false)}
                          >
                            Accept
                          </button>
                        )}
                      {viewTransferDetails.status === "Approved by Manager" &&
                        role === 3 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            disabled
                            // onClick={(e) => handleApproveTransfer(e, false)}
                          >
                            Approved
                          </button>
                        )}
                      {viewTransferDetails.status === "Approved" &&
                        role === 3 && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            disabled
                            // onClick={(e) => handleApproveTransfer(e, false)}
                          >
                            Approved
                          </button>
                        )}
                      {viewTransferDetails.status === "Approved" &&
                        role === 2 && (
                          <button
                            data-bs-dismiss="modal"
                            className="btn btn-success btn-block w-100"
                            disabled
                            // onClick={(e) => handleApproveTransfer(e, false)}
                          >
                            Approved
                          </button>
                        )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Decline Modal */}

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="exampleModal"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Reason:</b>
                    </label>
                    <textarea
                      class="form-control"
                      id="message-text"
                      value={declineReason}
                      onChange={(e) => setDeclineReason(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="submit-btn2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => handleApproveTransfer(e, true)}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* /Add Leave Modal */}
        {/* Edit Leave Modal */}
        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Leave Modal */}
        {/* Approve Leave Modal */}
        <div
          className="modal custom-modal fade"
          id="approve_leave"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Leave Approve</h3>
                  <p>Are you sure want to approve for this leave?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Approve
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Decline
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Approve Leave Modal */}
        {/* Delete Leave Modal */}
        <Delete />
        {/* /Delete Leave Modal */}
      </div>
    </div>
  );
};

export default TransferAdmin;
