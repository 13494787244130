import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
//import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import moment from "moment";
import { Menu, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStateManager } from "react-select";

const DutyHours = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [dutyDetail, setDutyDetail] = useState({
    desg_id: "",
    dept_id: "",
    from_date: "",
    to_date: "",
    max_hours: "",
  });
  const [data, setData] = useState([]);
  const [editTracking, setEditTracking] = useState({});

  const getDutyHoursData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/duty_hour/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        // setData(
        //   res.data.map((ele, index) => ({
        //     ...ele,
        //     joining_date: ele.joining_date
        //       .substr(0, 10)
        //       .split("-")
        //       .reverse()
        //       .join("-"),
        //   }))
        // );
        setSearchData(res.data);
        // setSearchData(
        //   res.data.map((ele, index) => ({
        //     ...ele,
        //     joining_date: ele.joining_date
        //       .substr(0, 10)
        //       .split("-")
        //       .reverse()
        //       .join("-"),
        //   }))
        // );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDutyHoursData();
  }, []);

  const handleSearch = () => {
    const newData = data
      .filter(
        (y) => y.department == (department == "" ? y.department : department)
      )
      .filter(
        (z) =>
          z.designation == (designation == "" ? z.designation : designation)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [department, designation]);

  const handleDutyHours = async (event) => {
    event.preventDefault();
    if (
      dutyDetail.from_date === "" ||
      !dutyDetail.dept_id ||
      !dutyDetail.desg_id ||
      !dutyDetail.max_hours ||
      (!toggle && dutyDetail.to_date === "")
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/duty_hour/`,
          {
            coregid: sessionStorage.getItem("coid"),
            from_date: dutyDetail.from_date,
            to_date: toggle ? "" : dutyDetail.to_date,
            dept_id: dutyDetail.dept_id.value,
            desg_id: dutyDetail.desg_id.value,
            max_hours: dutyDetail.max_hours,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Duty Hours added Successfully",
            });
            setDutyDetail({
              desg_id: "",
              dept_id: "",
              from_date: "",
              to_date: "",
              max_hours: "",
            });
            getDutyHoursData();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went Wrong",
            });
          }
        });
    }
  };

  const handleDeleteDuty = (id) => {
    handleClose();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/duty_hour/`, {
        data: { id: id },
      })
      .then((res) => {
        getDutyHoursData();
        setDeleteId(null);
        Swal.fire({
          icon: "success",
          text: "Geo Tracking Disabled Successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_department/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setGetDept(res.data);
        setDepartmentList(
          res.data.map((ele) => ({
            label: ele.dept.name,
            value: ele.dept.dept_id,
          }))
        );
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_designation/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDesignationList(
          res.data
            .filter((item) => item.desg !== null)
            .map((ele) => ({
              label: ele.desg.name,
              value: ele.desg.desg_id,
            }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id.localeCompare(b.id),
    },
    {
      title: "Max Hours",
      dataIndex: "max_hours",
      sorter: (a, b) => a.max_hours.localeCompare(b.max_hours),
    },

    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
    },
    {
      title: "From Date",
      dataIndex: "from_date",
      sorter: {
        compare: (a, b) =>
          moment(a.from_date, "DD-MM-YYYY") - moment(b.from_date, "DD-MM_YYYY"),
      },
    },
    {
      title: "To Date",
      dataIndex: "to_date",
      sorter: {
        compare: (a, b) =>
          moment(a.to_date, "DD-MM-YYYY") - moment(b.to_date, "DD-MM_YYYY"),
      },
    },

    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className="submit-btn1"
            onClick={(e) => setDeleteId(text.id)}
            data-bs-toggle="modal"
            data-bs-target="#delete_duty"
          >
            Delete
          </button>
        </>
      ),
    },
  ];
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Duty Hours</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                      marginLeft: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#duty_hours"
                    href="#"
                  >
                    <i className="fa fa-plus" /> &nbsp;Add Duty Hours
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Search Filter */}
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Department"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.department)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department !== ""
                      ? {
                          label: department,
                          value: department,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setDepartment(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Designation"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.designation)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation !== ""
                      ? {
                          label: designation,
                          value: designation,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDesignation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (setDepartment(""), setDesignation(""))}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          {/* /Search Filter */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                ></div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: searchData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      bordered
                      dataSource={searchData}
                      // rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* /Page Content */}
        <div
          id="duty_hours"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Set Duty Hours Details</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className=" col-sm-12">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="form-group col-sm-4">
                          <label>Department</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            options={departmentList}
                            value={dutyDetail.dept_id}
                            onChange={(e) =>
                              setDutyDetail({
                                ...dutyDetail,
                                dept_id: e,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>Designation</label>
                          <Select
                            // styles={colourStyles}
                            className="add-emp-field"
                            options={designationList}
                            value={dutyDetail.desg_id}
                            onChange={(e) =>
                              setDutyDetail({
                                ...dutyDetail,
                                desg_id: e,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>
                            Applicable Type
                            <span className="text-danger">*</span>
                          </label>
                          <div style={{ display: "flex", marginTop: "10px" }}>
                            <label>Temporary</label> &nbsp;&nbsp;
                            <input
                              type="checkbox"
                              className="check"
                              id="toggleOne"
                              name={toggle}
                              checked={toggle}
                              onChange={() => setToggle(!toggle)}
                            />
                            <label className="checktoggle" htmlFor="toggleOne">
                              checkbox
                            </label>
                            &nbsp;&nbsp;
                            <label>Permanent</label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "flex-start",
                          marginTop: "20px",
                        }}
                      >
                        <div className="form-group col-sm-4">
                          <label>Max Hours</label>
                          <input
                            type="text"
                            className="form-control"
                            value={dutyDetail.max_hours}
                            onChange={(e) =>
                              setDutyDetail({
                                ...dutyDetail,
                                max_hours: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="form-group col-sm-4">
                          <label>From Date</label>
                          <input
                            type="date"
                            className="form-control"
                            value={dutyDetail.from_date}
                            onChange={(e) =>
                              setDutyDetail({
                                ...dutyDetail,
                                from_date: e.target.value,
                              })
                            }
                          />
                        </div>
                        {!toggle && (
                          <div className="form-group col-sm-4">
                            <label>To Date</label>

                            <input
                              type="date"
                              disabled={
                                dutyDetail.from_date === "" ? true : false
                              }
                              min={dutyDetail.from_date}
                              max={"9999-12-31"}
                              className="form-control"
                              value={dutyDetail.to_date}
                              onChange={(e) =>
                                setDutyDetail({
                                  ...dutyDetail,
                                  to_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        )}
                      </div>
                      <div className="submit-section">
                        <button
                          className=" btn"
                          data-bs-dismiss={
                            !dutyDetail.from_date ||
                            !dutyDetail.dept_id ||
                            !dutyDetail.desg_id ||
                            !dutyDetail.max_hours ||
                            (!toggle && !dutyDetail.to_date)
                              ? ""
                              : "modal"
                          }
                          style={{
                            background: "#3298DB",
                            color: "white",
                            width: "94px",
                            height: "38px",
                            borderRadius: "5px",
                            fontSize: "16px",
                          }}
                          onClick={(event) => handleDutyHours(event)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Duty Hours */}

        <div className="modal custom-modal fade" id="delete_duty" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Duty Hours</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        className="continue-btn"
                        onClick={() => handleDeleteDuty(deleteId)}
                      >
                        Delete
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        className="cancel-btn"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DutyHours;
