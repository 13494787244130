import { Box, Button, Typography } from "@mui/material"
import styled from '@emotion/styled'

export const SBox1 = styled(Box)({
    color: '#000000',
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    //backdropFilter: 'blur(16px) saturate(180%)',
    //WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
})

export const SBox2 = styled(Box)({
    color: '#000000',
    backgroundColor: 'rgba(255, 255, 255, 0.90)',
    //backdropFilter: 'blur(16px) saturate(180%)',
    //WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    height: '245px',
    borderRadius: '8px',
    padding: '16px',
})

export const SBox3 = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '330px',
    marginBottom: '8px'
})

export const SButton = styled(Button)({
    background: '#2E86C1',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    marginBottom: '16px',
    ":hover": {
        background: '#2E86C1',
        boxShadow: 'none'
    }
})

export const STypography = styled(Typography)({
    fontFamily: 'Roboto',
})

export const SButton2 = styled(Button)({
    background: '#2E86C1',
    borderRadius: '8px',
    textTransform: 'none',
    boxShadow: 'none',
    height:'30px',
    ":hover": {
        background: '#2E86C1',
        boxShadow: 'none'
    }
})