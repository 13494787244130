/* global google */
import React, { Fragment } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
} from "react-google-maps";

const GeoMap = (props) => {
  return (
    <>
      <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={{ lat: 19.11578, lng: 72.847511 }}
        center={props.center}
        onLoad={(map) => {
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(props.center);
          map.fitBounds(bounds);
        }}
      >
        {props.places.map((place) => {
          return (
            <Fragment key={place.id}>
              <Marker
                position={{
                  lat: parseFloat(place.latitude),
                  lng: parseFloat(place.longitude),
                }}
              />
              {place.circle && (
                <Circle
                  defaultCenter={{
                    lat: parseFloat(place.latitude),
                    lng: parseFloat(place.longitude),
                  }}
                  radius={place.circle.radius}
                  options={place.circle.options}
                />
              )}
            </Fragment>
          );
        })}
      </GoogleMap>
    </>
  );
};

export default withScriptjs(withGoogleMap(GeoMap));
