//import './App.css';
import Board from "./Board";
import Square from "./Square";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Back from "../../../../assets/img/profiles/arrowleft.svg";

const defaultSquares = () => new Array(9).fill(null);

const lines = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6],
];

function App() {
  const [squares, setSquares] = useState(defaultSquares());
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    const isComputerTurn =
      squares.filter((square) => square !== null).length % 2 === 1;
    const linesThatAre = (a, b, c) => {
      return lines.filter((squareIndexes) => {
        const squareValues = squareIndexes.map((index) => squares[index]);
        return (
          JSON.stringify([a, b, c].sort()) ===
          JSON.stringify(squareValues.sort())
        );
      });
    };
    const emptyIndexes = squares
      .map((square, index) => (square === null ? index : null))
      .filter((val) => val !== null);
    const playerWon = linesThatAre("x", "x", "x").length > 0;
    const computerWon = linesThatAre("o", "o", "o").length > 0;
    if (playerWon) {
      setWinner("x");
    }
    if (computerWon) {
      setWinner("o");
    }
    const putComputerAt = (index) => {
      let newSquares = squares;
      newSquares[index] = "o";
      setSquares([...newSquares]);
    };
    if (isComputerTurn) {
      const winingLines = linesThatAre("o", "o", null);
      if (winingLines.length > 0) {
        const winIndex = winingLines[0].filter(
          (index) => squares[index] === null
        )[0];
        putComputerAt(winIndex);
        return;
      }

      const linesToBlock = linesThatAre("x", "x", null);
      if (linesToBlock.length > 0) {
        const blockIndex = linesToBlock[0].filter(
          (index) => squares[index] === null
        )[0];
        putComputerAt(blockIndex);
        return;
      }

      const linesToContinue = linesThatAre("o", null, null);
      if (linesToContinue.length > 0) {
        putComputerAt(
          linesToContinue[0].filter((index) => squares[index] === null)[0]
        );
        return;
      }

      const randomIndex =
        emptyIndexes[Math.ceil(Math.random() * emptyIndexes.length)];
      putComputerAt(randomIndex);
    }
  }, [squares]);

  function handleSquareClick(index) {
    const isPlayerTurn =
      squares.filter((square) => square !== null).length % 2 === 0;
    if (isPlayerTurn) {
      let newSquares = squares;
      newSquares[index] = "x";
      setSquares([...newSquares]);
    }
  }
  const restart = (e) => {
    e.preventDefault();
    setSquares(defaultSquares());
    setWinner(null);
  };

  return (
    <Wrapper>
      <div className=" twozerogame">
        <Helmet>
          <title>Tic Tac Toe game</title>
          <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        {/* Page Content */}

        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h1
                className="page-title"
                style={{ fontSize: "45px", fontWeight: "900" }}
              >
                Tic Tac Toe
              </h1>
            </div>
            <div
              className="col-auto ml-auto"
              style={{ display: "flex", float: "inline-start" }}
            >
              <Link to="/happyzone-two" style={{ color: "white" }}>
                <button className="back-happyzone">
                  <span>
                    <img src={Back} />
                  </span>{" "}
                  Back To HappyZone
                </button>
              </Link>
            </div>
          </div>
        </div>
        <main>
          <button
            style={{
              border: "none",
              background: "#07145b",
              width: "100px",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
            onClick={(e) => restart(e)}
          >
            Restart
          </button>
          <Board>
            {squares.map((square, index) => (
              <Square
                x={square === "x" ? 1 : 0}
                o={square === "o" ? 1 : 0}
                onClick={() => handleSquareClick(index)}
              />
            ))}
          </Board>
          {!!winner && winner === "x" && (
            <div className="result green">You WON!</div>
          )}
          {!!winner && winner === "o" && (
            <div className="result red">You LOST!</div>
          )}
        </main>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
body{
    background-color: #123;
    color:#fff;
  }
  main{
    width: 300px;
    margin: 50px auto 0;
  }
  div.board{
    display: grid;
    border: 2px solid #fff;
    border-radius:10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 335px;
    height;300px;
    padding: 13px;
    background: #07145b;
  }
  div.square{
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    box-sizing: border-box;
    font-size: 4rem;
    border-radius:10px;
    text-align: center;
  }
  div.result{
    font-size: 2rem;
    padding: 10px;
  }
  div.result.green{
    background-color: #5a5;
    border-radius: 25px;
    text-align: center;
    margin-top:30px;
  }
  div.result.red{
    background-color: #a55;
    border-radius: 25px;
    text-align: center;
    margin-top:30px;
  }
`;

export default App;
