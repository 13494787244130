import React from "react";
import Select from "react-select";
import { useEffect, useState } from "react";
import themes from "../Constants/theme";
import axios from "axios";
import { Button } from "@mui/material";
import styled from "@emotion/styled";

const SButton = styled(Button)({
  background: "#3298DB",
  borderRadius: "8px",
  textTransform: "none",
  color: "#FFFFFF",
  boxShadow: "none",
  margin: "8px",
  ":hover": {
    background: "#3298DB",
    boxShadow: "none",
  },
});

const NewTheme = ({ handleChange, backImg, setBackImg }) => {
  const options = [];
  const [wallpaper, setWallpaper] = useState("");
  const [preview, setPreview] = useState("");

  Object.keys(themes).forEach((theme) => {
    options.push({
      value: theme,
      label: theme.charAt(0).toUpperCase() + theme.slice(1),
    });
  });

  const token = sessionStorage.getItem("access_token");

  const getTheme = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/change_theme/`,
        {
          params: {
            empid: JSON.parse(sessionStorage.getItem("emp_id")),
            coregid: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.data;
      if (res.status === 200) {
        sessionStorage.setItem("theme_link", data.link);
      }
      if (sessionStorage.getItem("theme_link")) {
        const theme_link = sessionStorage.getItem("theme_link");
        setBackImg(theme_link);
      }
      //setBackImg(data.link)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTheme();
  }, []);

  const updateTheme = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/change_theme/`,
        {
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
          link: wallpaper,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        getTheme();
      }
    } catch (err) {
      console.log(err);
    }
  };

  /*
  useEffect(() => {
    employeeDetails()
  }, [])
  */

  const Wallpaper = [
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/hd-wallpaper-g4bbf6af09_1920.jpg",
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/nature-ga127fccad_1920.jpg",
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/wallpaperbetter.com_1920x1080.jpg",
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/wallpaperbetter.com_1920x10801.jpg",
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/ws_Abstract_waves_1920x1080.jpg",
    "https://sapio-oz-hrms.s3.ap-south-1.amazonaws.com/theme/wallpaperbetter.jpg",
  ];
  // console.log(Wallpaper);
  // console.log(backImg);
  return (
    <div className="theme-selection" style={{ textAlign: "center" }}>
      {/*
      <div style={{ textAlign: 'center' }}>
        <h3>
          Upload Wallpaper
        </h3>

        <input
          type="file"
          accept="image/*"
          onChange={(e) => { setWallpaper(e.target.files[0]); setPreview(URL.createObjectURL(e.target.files[0])) }}
        />
        <br />

        {preview ?
          <img src={preview} width='300px' /> :
          null
        }
        <br />
        <br/>

        <Button variant="contained" onClick={updateDetails}>
          Set Wallpaper
        </Button>
      </div>
      */}
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
          <div
            className="card flex-fill"
            style={{
              border: "none",
              borderRadius: "30px !important",
              background: "none",
            }}
          >
            <div
              // className="card-img-top"
              style={
                backImg === ""
                  ? {
                      height: "150px",
                      width: "100%",
                      borderRadius: "10px",
                      background: "#1e2442",
                      border: "4px solid #3298db",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }
                  : {
                      height: "150px",
                      width: "100%",
                      background: "#1e2442",
                      borderRadius: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }
              }
              onClick={() => {
                setBackImg("");
                setWallpaper("");
              }}
            >
              <h4 style={{ color: "white" }}>Default</h4>
            </div>
          </div>
        </div>
        {Wallpaper.map((ele) => (
          <div className="col-sm-6 col-md-3 col-lg-3 d-flex">
            <div
              className="card flex-fill"
              style={{
                border: "none",
                borderRadius: "30px !important",
                background: "none",
              }}
            >
              <img
                alt=""
                src={ele}
                className="card-img-top"
                style={
                  ele === backImg
                    ? {
                        height: "150px",
                        width: "100%",
                        borderRadius: "10px",
                        border: "4px solid #3298db",
                      }
                    : {
                        height: "150px",
                        width: "100%",
                        borderRadius: "10px",
                      }
                }
                onClick={(e) => {
                  setBackImg(e.target.src);
                  setWallpaper(e.target.src);
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <SButton onClick={updateTheme} data-bs-dismiss="modal">
        Save
      </SButton>

      {/*
      <Select
        className="select-filter"
        onChange={handleChange}
        options={options}
      />
      */}
    </div>
  );
};

export default NewTheme;
