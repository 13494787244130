import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
// import eye from "../../../../assets/img/profiles/Group813.png";
import eye from "../../../assets/img/profiles/Group813.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "@mui/material/Button";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
//import Select from "react-select";
import { read, utils } from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import moment from "moment";
import { FiUpload } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import CustomizedTimeline from "../../Timeline/Timeline";
import { Radio, TextField } from "@mui/material";

const LeavesAdmin = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewLeaveDetails, setViewLeaveDetails] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [half, setHalf] = useState("");
  const hiddenFileInput = useRef(null);
  const hiddenFileInputTwo = useRef(null);
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [name1, setName1] = useState("");
  const [status1, setStatus1] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [location, setLocation] = useState("");
  const [to1, setTo1] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [declineReason, setDeclineReason] = useState("");
  const [leaver, setLeaver] = useState([]);
  const [empId, setEmpId] = useState("");
  const [leavec, setLeavec] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [exData, setExData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [duration, setDuration] = useState(0);
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [leaves, setLeaves] = useState([]);
  const [empList, setEmpList] = useState([]);
  const [getTime, setGetTmine] = useState([]);
  const [leaveT, setLeaveT] = useState("Day");
  const [difference, setDifference] = useState(null);
  const [applyLeave, setApplyLeave] = useState({
    empl_id: { label: "", value: "" },
    type: { label: null, value: null, doc_req: 0 },
    from_date: "",
    noofleaves: null,
    to_date: "",
    reason: "",
    file: null,
  });
  const [month, setMonth] = useState("");
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const HandleGetTimeline = (text) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/approval_logs/`, {
        params: {
          approval_id: text,
        },
      })
      .then((res) => {
        console.log(res.data);
        setGetTmine(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLeave = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/mas_leave/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
          year: monthValue.split("-")[0],
          month: monthValue.split("-")[1],
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //setData(res.data);
        console.log(`running1`)
        const per= JSON.parse(sessionStorage.getItem(`permissions`))
        if(per[`Data Restriction`]=== "All employees ( active and inactive)" ){
          setData(
            res.data.map((ele, index) => ({
              ...ele,
              from: ele.from.split("-").reverse().join("-"),
              to: ele.to.split("-").reverse().join("-"),
              applied_date: ele.applied_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
          setSearchData(
            res.data.map((ele, index) => ({
              ...ele,
              from: ele.from.split("-").reverse().join("-"),
              to: ele.to.split("-").reverse().join("-"),
              applied_date: ele.applied_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
        }else{
          console.log(`running2`)
          setData(
            res.data.filter((item)=>(item.reporting_manager===sessionStorage.getItem(`name`))).map((ele, index) => ({
              ...ele,
              from: ele.from.split("-").reverse().join("-"),
              to: ele.to.split("-").reverse().join("-"),
              applied_date: ele.applied_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
          setSearchData(
            res.data.filter((item)=>(item.reporting_manager===sessionStorage.getItem(`name`))).map((ele, index) => ({
              ...ele,
              from: ele.from.split("-").reverse().join("-"),
              to: ele.to.split("-").reverse().join("-"),
              applied_date: ele.applied_date
                .substr(0, 10)
                .split("-")
                .reverse()
                .join("-"),
            }))
          );
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLeave();
  }, [monthValue]);

  const handleLeaveReport = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/leave_report/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setLeavec(res.data.columns);
        setLeaver(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleLeaveReport();
  }, []);

  const handleApproveLeave = (e, value) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/approve/`,
        {
          admid: JSON.parse(sessionStorage.getItem("admid")),
          approval_id: viewLeaveDetails.approval_id,
          decline: value,
          reason: declineReason,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: res.data.status === false ? "error" : "success",
          // text: res.data.status === true ? "Document Verified" : "",

          // icon:
          //   res.data.error === "Cannot approved by same person"
          //     ? "error"
          //     : "success",
          text:
            res.data.error === "Already Approved"
              ? "Leave is already approved"
              : res.data.error === "Cannot approved by same person"
              ? "Cannot approved or declined by same person"
              : res.data.error === "Cannot approved by someone of lower hierchy"
              ? "You have no authority to approve or decline request"
              : res.data.error ===
                "Cannot approved by someone of different departmenr"
              ? "You have no authority to approve or decline request"
              : res.data.status === true
              ? "Leave approved successfully"
              : "Leave Rejected",
          // timer: 3000,
        });
        getLeave();
        setDeclineReason("");
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleAdjustLeave = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/adjusted_leave/`,
        {
          coregid: sessionStorage.getItem("coid"),
          year: month.split("-")[0],
          month: month.split("-")[1],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: res.data.status === false ? "error" : "success",
          // text: res.data.status === true ? "Document Verified" : "",
          // icon:
          //   res.data.error === "Cannot approved by same person"
          //     ? "error"
          //     : "success",
          text: "Leaves adjusted successfully",
          timer: 3000,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const readUploadFile = (e) => {
    // e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // console.log(json);
        setExcelData(json);
        setShowTable(true);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
      setExData(e.target.files[0]);
    }
  };

  const HandleDelete = () => {
    setShowTable(false);
    setExcelData([]);
  };

  const handleSearch = () => {
    const newData = data
      .filter((x) => x.name == (name1 == "" ? x.name : name1))
      .filter((x) => x.emp_code == (empId == "" ? x.emp_code : empId))
      .filter(
        (a) =>
          a.office_location == (location == "" ? a.office_location : location)
      )
      .filter(
        (a) =>
          a.to == (to1 == "" ? a.to : to1?.split("-")?.reverse()?.join("-"))
      )
      .filter((y) => y.status == (status1 == "" ? y.status : status1))
      .filter(
        (z) => z.leavetype == (leaveType == "" ? z.leavetype : leaveType)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [name1, status1, location, to1, leaveType, empId]);

  const getDaysDurationInclusive = () => {
    let start = moment(applyLeave.from_date);
    let end = moment(applyLeave.to_date);

    let duration1 = end.diff(start, "days") + 1;

    setDuration(duration1);
  };

  useEffect(() => {
    getDaysDurationInclusive();
  }, [applyLeave.from_date, applyLeave.to_date]);

  const handleApplyLeave = async (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("empl_id", applyLeave.empl_id.value);
    formdata.append("type", applyLeave.type.value);
    formdata.append("from_date", applyLeave.from_date);
    formdata.append("to_date", applyLeave.to_date);
    formdata.append("reason", applyLeave.reason);
    formdata.append("bill", applyLeave.file);
    if (
      !applyLeave.reason ||
      !applyLeave.from_date ||
      !applyLeave.to_date ||
      !applyLeave.type.value ||
      !applyLeave.empl_id.value
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else if (
      applyLeave.from_date.split("-")[1] !== applyLeave.to_date.split("-")[1]
    ) {
      Swal.fire({
        icon: "error",
        text: "Please apply leave within month",
      });
    } else {
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/mas_leave/`, formdata, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            getLeave();
            console.log(res);
            Swal.fire({
              icon: "success",
              text: "Leave Applied Successfully",
            });
            setApplyLeave({
              empl_id: { label: "", value: "" },
              type: { label: null, value: null, doc_req: 0 },
              from_date: "",
              to_date: "",
              reason: "",
              file: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "You can't apply two leaves for the same day(s)!",
            });
          }
        });
    }
  };

  const handleApplyHalfLeave = async (event) => {
    event.preventDefault();
    if (
      !applyLeave.reason ||
      !applyLeave.from_date ||
      !applyLeave.type.value ||
      !applyLeave.empl_id?.value
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/mas_halfleave/`,
          {
            empl_id: applyLeave.empl_id.value,
            type: applyLeave.type.value,
            number_of_leave: Number(applyLeave.noofleaves),
            on_date: applyLeave.from_date,
            reason: applyLeave.reason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            Swal.fire({
              icon: "success",
              text: "Leave Applied Successfully",
            });
            setApplyLeave({
              type: { label: null, value: null },
              noofleaves: null,
              from_date: "",
              to_date: "",
              reason: "",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mas_leavetypes/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLeaves(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
      });
  }, []);

  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });
  const columns = [
    {
      title: "Name of Employee",
      dataIndex: "name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Leave Type",
      dataIndex: "leavetype",
      sorter: (a, b) => a.leavetype.localeCompare(b.leavetype),
    },

    {
      title: "From",
      dataIndex: "from",
      sorter: {
        compare: (a, b) =>
          moment(a.from, "DD-MM-YYYY") - moment(b.from, "DD-MM_YYYY"),
      },
    },
    {
      title: "To",
      dataIndex: "to",
      sorter: {
        compare: (a, b) =>
          moment(a.to, "DD-MM-YYYY") - moment(b.to, "DD-MM_YYYY"),
      },
    },
    {
      title: "Application date",
      dataIndex: "applied_date",
      sorter: {
        compare: (a, b) =>
          moment(a.applied_date, "DD-MM-YYYY") -
          moment(b.applied_date, "DD-MM_YYYY"),
      },
    },

    {
      title: "No Of Days",
      dataIndex: "noofdays",
      sorter: (a, b) =>
        a.noofdays.match(/(\d+)/)[0] - b.noofdays.match(/(\d+)/)[0],
    },

    // {
    //   title: "Approve Date",
    //   dataIndex: "approved_date",
    //   sorter: {
    //     compare: (a, b) =>
    //       moment(a.approved_date, "DD-MM-YYYY") -
    //       moment(b.approved_date, "DD-MM-YYYY"),
    //   },
    //   render: (text, record) => (
    //     <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
    //   ),
    // },
    {
      title: "Reporting Manager",
      align: "center",
      render: (text, record) => <>{text.reporting_manager}</>,
    },

    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   sorter: (a, b) => a.reason.length - b.reason.length,
    //   ellipsis: true,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Approved by Manager"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    // {
    //   title: "Action",
    //   align: "center",
    //   render: (text, record) => (
    //     <button
    //       style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
    //       data-bs-toggle="modal"
    //       data-bs-target="#view_leave"
    //       className=" btn-primary submit-btn1"
    //       onClick={(e) => setViewLeaveDetails(text)}
    //       disabled={
    //         rolePermissions["Employee Leave"] === "Edit/Create/Approve"
    //           ? false
    //           : true
    //       }
    //     >
    //       View
    //     </button>
    //   ),
    // },
    // {
    //   title: "View",
    //   dataIndex: "approval_id",

    //   render: (text, record) => (<button    data-bs-toggle="modal"
    //   data-bs-target="#show_timeline" onClick={()=>HandleGetTimeline(text)}>View</button>)
    // },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      align: "right",
      render: (text, record) => (
        <div className="dropdown dropdown-action ">
          <a
            href="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={
             rolePermissions["Employee Leave"] === "Edit/Create/Approve"
                ? false
                : true
            }
          >
            <Button variant="contained">
              <MoreHorizIcon />
            </Button>
          </a>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{ inset: "-100px auto auto 0px" }}
          >
            <a
              className="dropdown-item"
              href="#"
              // style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
              data-bs-toggle="modal"
              data-bs-target="#view_leave"
              // className=" btn-primary submit-btn1"
              onClick={(e) => setViewLeaveDetails(text)}
              disabled={
                rolePermissions["Employee Leave"] === "Edit/Create/Approve"
                  ? false
                  : true
              }
            >
              <img
                src={eye}
                style={{
                  height: "10px",
                  width: "15px",
                }}
              />
              <b> View </b>
            </a>
            <a
              className="dropdown-item"
              data-bs-toggle="modal"
              data-bs-target="#show_timeline"
              href="#"
              onClick={() => HandleGetTimeline(text.approval_id)}
            >
              <i className="fa fa-pencil m-r-5" />
              <b> Timeline</b>
            </a>
            {/* <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_employee"
            >
              <i className="fa fa-trash-o m-r-5" />
              <b> Skip Payroll</b>
            </a> */}
          </div>
        </div>
      ),
    },
  ];

  const handleUploadBulk = () => {
    let formdata = new FormData();
    formdata.append("file", exData);
    formdata.append("coregid", sessionStorage.getItem("coid"));
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/bulk_leave/`, formdata)
      .then((res) => {
        Swal.fire({
          icon: "success",
          // text: "uploaded successfully",
          text: res.data[0]?.slice(7),
        });
        HandleDelete();
        getLeave();
      })

      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const calculateDifference = () => {
    const firstDate = new Date(applyLeave.from_date);
    const secondDate = new Date(applyLeave.to_date);

    // Calculate the difference in time (milliseconds)
    const differenceInTime = secondDate - firstDate;

    // Convert the difference to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
    setDifference(differenceInDays);
  };

  useEffect(()=>{
calculateDifference()
  },[applyLeave.from_date, applyLeave.to_date])

  const handleClickOne = () => {
    hiddenFileInput.current.click();
  };

  const handleClickTwo = () => {
    hiddenFileInputTwo.current.click();
  };

  const handleChangeTwo = (event) => {
    // const fileUploaded = event.target.files[0];
    // setBioExcel(fileUploaded);
    // uploadAttendance();
    let formData = new FormData();
    formData.append("coregid", sessionStorage.getItem("coid"));
    formData.append("file", event.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/bulk_leave_rrwl/`, formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Leave Balance uploaded successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Leaves</h3>
              </div>
              {!showTable &&
                rolePermissions["Upload leave history"] ===
                  "Edit/Create/Approve" && (
                  <div className="col-auto float-end ml-auto">
                    <div className="view-icons">
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                          marginLeft: "10px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#add_leave"
                        href="#"
                      >
                        <i className="fa fa-plus" /> &nbsp;Apply Leave
                      </button>
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: " #2b8f6f",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                          marginLeft: "10px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#adjust_leave"
                        href="#"
                      >
                        Adjust Leave
                      </button>
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                          marginLeft: "10px",
                        }}
                        onClick={() => handleClickTwo()}
                      >
                        <i className="fa fa-plus" /> &nbsp;Upload Leave Balance
                      </button>
                      <input
                        type="file"
                        ref={hiddenFileInputTwo}
                        onChange={(e) => handleChangeTwo(e)}
                        style={{ display: "none" }}
                      />
                    </div>
                    <button
                      className=""
                      classname="edit-icon"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "19px 23px",
                        height: "30px",
                        fontSize: "15px",
                        background: "#2b8f6f",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <Box>
                        <Tooltip title="Add Bulk File">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            color="white"
                            // sx={{ ml: -3 }}
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            // className={classes.button}
                          >
                            <p
                              style={{
                                fontSize: "14px",
                                marginTop: "15px",
                                color: "white",
                                fontWeight: "500",
                              }}
                            >
                              <FiUpload />
                              &nbsp;&nbsp; Upload Leave History
                            </p>
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </button>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem>
                        <a
                          // onClick={() => handleExcelDownload()}
                          href={`${
                            process.env.REACT_APP_BACKEND_URL
                          }/bulk_leave/?coregid=${sessionStorage.getItem(
                            "coid"
                          )}`}
                          download
                          target="_blank"
                        >
                          Download Template
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <a
                          onClick={() => handleClickOne()}
                          // data-bs-toggle="modal"
                          // data-bs-target="#experience_info"
                          // href="#"
                          // appearance="primary"
                          // onClick={openModal}
                        >
                          Upload File
                        </a>
                      </MenuItem>
                    </Menu>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={(e) => readUploadFile(e)}
                      style={{ display: "none" }}
                    />
                  </div>
                )}
            </div>
          </div>
          {!showTable && (
            <>
              {/* Search Filter */}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee Name"
                      options={[...new Set(data?.map((item) => item.name))].map(
                        (ele) => ({
                          label: ele,
                          value: ele,
                        })
                      )}
                      value={
                        name1 !== ""
                          ? {
                              label: name1,
                              value: name1,
                            }
                          : null
                      }
                      onChange={(e) => setName1(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee code"
                      options={[
                        ...new Set(data?.map((item) => item.emp_code)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        empId !== ""
                          ? {
                              label: empId,
                              value: empId,
                            }
                          : null
                      }
                      onChange={(e) => setEmpId(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Leave Status"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.status)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        status1 !== ""
                          ? {
                              label: status1,
                              value: status1,
                            }
                          : null
                      }
                      //isClearable={true}
                      isSearchable
                      onChange={(e) => {
                        setStatus1(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Leave Type"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.leavetype)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        leaveType !== ""
                          ? {
                              label: leaveType,
                              value: leaveType,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setLeaveType(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Work Location"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.office_location)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        location !== ""
                          ? {
                              label: location,
                              value: location,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setLocation(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group  ">
                    <input
                      className="form-control input-height"
                      type="date"
                      placeholder="To"
                      value={to1}
                      onChange={(e) => setTo1(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a
                    href="#"
                    className="btn-block"
                    style={{
                      //fontSize: "14px",
                      height: "38px",
                      background: "#3298DB",
                      //color:"white"
                    }}
                    onClick={() => (
                      setName1(""),
                      setLocation(""),
                      setEmpId(""),
                      setStatus1(""),
                      setLeaveType("")
                    )}
                  >
                    <img
                      tabindex="0"
                      data-bs-toggle="tooltip"
                      title="clear all filter"
                      style={{
                        height: "38px",
                        backgroundColor: "#3298DB",
                        padding: "7px 5px",
                        borderRadius: "5px",
                      }}
                      src={FilterOff}
                    />
                  </a>
                </div>
              </div>
              {/* /Search Filter */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginRight: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <div className="view-icons">
                        <input
                          type="month"
                          value={monthValue}
                          onChange={(e) => setMonthValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          exportableProps={{ showColumnPicker: true }}
                          pagination={{
                            total: searchData.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={searchData}
                          rowKey={(record) => record.id}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {showTable && (
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        scroll={{ x: 1300 }}
                        dataSource={excelData}
                        // pagination={{
                        //   total: excelData.length,
                        //   showTotal: (total, range) =>
                        //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        //   showSizeChanger: true,
                        //   onShowSizeChange: onShowSizeChange,
                        //   itemRender: itemRender,
                        // }}
                        pagination={false}
                        columns={
                          excelData.length !== 0
                            ? Object.keys(excelData[0]).map((ele) => ({
                                title:
                                  ele
                                    .split("_")
                                    .join(" ")[0]
                                    .toLocaleUpperCase() +
                                  ele
                                    .split("_")
                                    .join(" ")
                                    .substring(1)
                                    .toLocaleUpperCase(),
                                dataIndex: ele,
                                key: ele,
                              }))
                            : []
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <button
                      className="btn-sub"
                      style={{ background: "white", color: "#3298DB" }}
                      onClick={() => HandleDelete()}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-sub"
                      style={{ background: "#3298DB", color: "white" }}
                      onClick={(e) => handleUploadBulk(e)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* /Page Content */}
        <div id="add_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Apply Leave</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "6px 15px",
                      fontSize: "15px",
                      borderRadius: "8px 0px 0px 8px",
                      background: leaveT === "Day" ? "#3298DB" : "#FFFFFF",
                      border: leaveT === "Day" ? "none" : "1px solid #344767",
                      color: leaveT === "Day" ? "#FFFFFF" : "#344767",
                    }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#personal_info_modal"
                    onClick={() => {
                      setLeaveT("Day");
                    }}
                  >
                    {" "}
                    Full Day
                  </button>

                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "6px 15px",
                      fontSize: "15px",
                      borderRadius: "0px 8px 8px 0px",
                      background: leaveT === "HalfDay" ? "#3298DB" : "#FFFFFF",
                      border:
                        leaveT === "HalfDay" ? "none" : "1px solid #344767",
                      color: leaveT === "HalfDay" ? "#FFFFFF" : "#344767",
                    }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#personal_info_modal"
                    onClick={() => {
                      setLeaveT("HalfDay");
                    }}
                  >
                    Half Day
                  </button>
                </div>
              </div>
              {leaveT === "Day" && (
                <div className="modal-body">
                  <form>
                    <div className="row col-md-12">
                      <div className="form-group col-md-6">
                        <div className="col-md-12">
                          <label>
                            Name of Employee
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={empList}
                            value={applyLeave.empl_id}
                            onChange={(e) =>
                              setApplyLeave({ ...applyLeave, empl_id: e })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "25px" }}
                        >
                          <label>
                            Type of Leave<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={leaves}
                            value={applyLeave.type}
                            onChange={(e) =>
                              setApplyLeave({ ...applyLeave, type: e })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12">
                      <div className="form-group col-md-6">
                        <div className="col-md-12">
                          {" "}
                          <label>
                            From <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              className="form-control datetimepicker"
                              // min={new Date().toISOString().split("T")[0]}
                              max={
                                applyLeave.to_date === ""
                                  ? "9999-12-31"
                                  : applyLeave.to_date
                              }
                              type="date"
                              value={applyLeave.from_date}
                              onChange={(e) =>
                                setApplyLeave({
                                  ...applyLeave,
                                  from_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "25px" }}
                        >
                          {" "}
                          <label>
                            To <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              //min={new Date().toISOString().split("T")[0]}
                              disabled={
                                applyLeave.from_date === "" ? true : false
                              }
                              min={applyLeave.from_date}
                              max={"9999-12-31"}
                              value={applyLeave.to_date}
                              className="form-control datetimepicker"
                              type="date"
                              onChange={(e) =>
                                setApplyLeave({
                                  ...applyLeave,
                                  to_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className=" col-md-6">
                        <div className="form-group">
                          <label>
                            Number of days{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            type="text"
                            defaultValue={2}
                          />
                        </div>
                      </div> */}
                      {/* <div className=" col-md-6">
                        <div
                          className="form-group"
                          //style={{ marginLeft: "25px" }}
                        >
                          <label>
                            Remaining Leaves{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            readOnly
                            defaultValue={12}
                            type="text"
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="form-group">
                      <label>
                        Leave Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={4}
                        value={applyLeave.reason}
                        className="form-control"
                        maxlength="100"
                        onChange={(e) =>
                          setApplyLeave({
                            ...applyLeave,
                            reason: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* {applyLeave.type.doc_req === 1 && (
                    <div className="form-group col-md-12">
                      <div
                        className="col-md-12"
                        // style={{ marginLeft: "25px" }}
                      >
                        {" "}
                        <label>
                          Upload Document <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            //min={new Date().toISOString().split("T")[0]}
                            // disabled={
                            //   applyLeave.from_date === "" ? true : false
                            // }
                            // min={applyLeave.from_date}
                            // max={"9999-12-31"}
                            // value={applyLeave.file}

                            className="form-control datetimepicker"
                            type="file"
                            onChange={(e) =>
                              setApplyLeave({
                                ...applyLeave,
                                // to_date: e.target.value,
                                file: e.target.files[0],
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )} */}
                  <div>{`Total Leave days:${difference + 1}`} </div>
                    <div className="submit-section">
                      <button
                        className=" btn"
                        data-bs-dismiss={
                          !applyLeave.reason ||
                          !applyLeave.from_date ||
                          !applyLeave.to_date ||
                          !applyLeave.type.value ||
                          !applyLeave.empl_id.value ||
                          applyLeave.from_date.split("-")[1] !==
                            applyLeave.to_date.split("-")[1]
                            ? ""
                            : "modal"
                        }
                        style={{
                          background: "#3298DB",
                          color: "white",
                          width: "94px",
                          height: "38px",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}
                        onClick={(event) => handleApplyLeave(event)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {leaveT === "HalfDay" && (
                <div className="modal-body">
                  <form>
                    <div className="row col-md-12">
                      <div className="form-group col-md-12">
                        <div className="col-md-12">
                          <label>
                            Name of Employee
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={empList}
                            value={applyLeave.empl_id}
                            onChange={(e) =>
                              setApplyLeave({ ...applyLeave, empl_id: e })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row col-md-12">
                      <div className="form-group col-md-6">
                        <label>
                          Type of Leave<span className="text-danger">*</span>
                        </label>
                        <Select
                          options={leaves}
                          value={applyLeave.type}
                          onChange={(e) =>
                            setApplyLeave({ ...applyLeave, type: e })
                          }
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label>
                          Number of Leave
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          // min="1"
                          defaultValue={0.5}
                          value={applyLeave.noofleaves}
                          onChange={(e) =>
                            setApplyLeave({
                              ...applyLeave,
                              noofleaves: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="row col-md-12">
                      <div className="form-group col-md-5">
                        <div className="col-md-12">
                          {" "}
                          <label>
                            Date <span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              className="form-control datetimepicker"
                              // min={new Date().toISOString().split("T")[0]}
                              max={
                                applyLeave.to_date === ""
                                  ? "9999-12-31"
                                  : applyLeave.to_date
                              }
                              type="date"
                              value={applyLeave.from_date}
                              onChange={(e) =>
                                setApplyLeave({
                                  ...applyLeave,
                                  from_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-7">
                        <div className="col-md-12">
                          <div style={{ marginTop: "18px" }}>
                            <Radio
                              value="fs"
                              checked={half === "fs"}
                              onChange={(e) => setHalf(e.target.value)}
                              sx={{ m: 0, p: 0.5 }}
                            />
                            <label>First Half</label>

                            <Radio
                              value="sn"
                              checked={half === "sn"}
                              onChange={(e) => setHalf(e.target.value)}
                              sx={{ m: 0, p: 0.5 }}
                            />
                            <label>Second Half</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label>
                        Leave Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        rows={4}
                        value={applyLeave.reason}
                        className="form-control"
                        maxlength="100"
                        onChange={(e) =>
                          setApplyLeave({
                            ...applyLeave,
                            reason: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="submit-section">
                      <button
                        className=" btn"
                        data-bs-dismiss={
                          !applyLeave.reason ||
                          !applyLeave.from_date ||
                          !applyLeave.type.value
                            ? ""
                            : "modal"
                        }
                        style={{
                          background: "#3298DB",
                          color: "white",
                          width: "94px",
                          height: "38px",
                          borderRadius: "5px",
                          fontSize: "16px",
                        }}
                        onClick={(event) => handleApplyHalfLeave(event)}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* View Leave Modal */}
        <div id="view_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  View Leave
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="form-group">
                      <label>
                        Leave Type <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        className="form-control datetimepicker"
                        type="text"
                        value={viewLeaveDetails.leavetype}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          From <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={viewLeaveDetails.from
                              ?.split("-")
                              .reverse()
                              .join("-")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          To <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={viewLeaveDetails.to
                              ?.split("-")
                              .reverse()
                              .join("-")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>
                        Number of days <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        readOnly
                        type="text"
                        disabled
                        value={viewLeaveDetails.noofdays}
                      />
                    </div>
                    {/* <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div> */}
                    <div className="form-group">
                      <label>
                        Leave Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        disabled
                        rows={4}
                        className="form-control"
                        value={viewLeaveDetails.reason}
                      />
                    </div>
                    {[
                      "OASI370734",
                      "OASI019787",
                      "S3 F251778",
                      "OM N160326",
                    ].includes(sessionStorage.getItem("coid")) && (
                      <div
                        className="submit-section"
                        style={{
                          display:
                            Number(sessionStorage.getItem("emp_id")) !== 5428 &&
                            viewLeaveDetails?.greater_leave === 1
                              ? "none"
                              : "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          type="button"
                          className="btn btn-success btn-block w-100 "
                          //data-bs-dismiss="modal"
                          style={{
                            background: "transparent",
                            color: "#18838D",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          //onClick={(e) => handleApproveLeave(e, true)}
                        >
                          Reject
                        </button>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                        {viewLeaveDetails.status === "Pending" && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveLeave(e, false)}
                          >
                            Accept
                          </button>
                        )}
                        {viewLeaveDetails.status === "Rejected" && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveLeave(e, false)}
                          >
                            Accept
                          </button>
                        )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 2 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Accept
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 4 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Accept
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 3 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved" &&
                          role === 3 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved" &&
                          role === 2 && (
                            <button
                              data-bs-dismiss="modal"
                              className="btn btn-success btn-block w-100"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                      </div>
                    )}
                    {![
                      "OASI370734",
                      "OASI019787",
                      "S3 F251778",
                      "OM N160326",
                    ].includes(sessionStorage.getItem("coid")) && (
                      <div
                        className="submit-section"
                        style={{ justifyContent: "space-between" }}
                      >
                        <button
                          type="button"
                          className="btn btn-success btn-block w-100 "
                          //data-bs-dismiss="modal"
                          style={{
                            background: "transparent",
                            color: "#18838D",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          //onClick={(e) => handleApproveLeave(e, true)}
                        >
                          Reject
                        </button>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                        {viewLeaveDetails.status === "Pending" && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveLeave(e, false)}
                          >
                            Accept
                          </button>
                        )}
                        {viewLeaveDetails.status === "Rejected" && (
                          <button
                            className="btn btn-success btn-block w-100"
                            data-bs-dismiss="modal"
                            onClick={(e) => handleApproveLeave(e, false)}
                          >
                            Accept
                          </button>
                        )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 2 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Accept
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 4 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Accept
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved by Manager" &&
                          role === 3 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved" &&
                          role === 3 && (
                            <button
                              className="btn btn-success btn-block w-100"
                              data-bs-dismiss="modal"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                        {viewLeaveDetails.status === "Approved" &&
                          role === 2 && (
                            <button
                              data-bs-dismiss="modal"
                              className="btn btn-success btn-block w-100"
                              disabled
                              // onClick={(e) => handleApproveLeave(e, false)}
                            >
                              Approved
                            </button>
                          )}
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* /Adjust Leave Modal */}

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="adjust_leave"
          // tabindex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  Adjust Leaves
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Select Month :</b>
                    </label>
                    <input
                      class="form-control"
                      id="message-text"
                      min="2024-05"
                      type="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={(e) => handleAdjustLeave(e)}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Adjust
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Leave Modal */}

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="exampleModal"
          // tabindex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Reason:</b>
                    </label>
                    <textarea
                      class="form-control"
                      id="message-text"
                      value={declineReason}
                      onChange={(e) => setDeclineReason(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="submit-btn2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  onClick={(e) => handleApproveLeave(e, true)}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="edit_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Leave</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Select Leave Type</option>
                      <option>Casual Leave 12 Days</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      To <span className="text-danger">*</span>
                    </label>
                    <div>
                      <input
                        className="form-control datetimepicker"
                        defaultValue="01-01-2019"
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Number of days <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      defaultValue={2}
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Remaining Leaves <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      readOnly
                      defaultValue={12}
                      type="text"
                    />
                  </div>
                  <div className="form-group">
                    <label>
                      Leave Reason <span className="text-danger">*</span>
                    </label>
                    <textarea
                      rows={4}
                      className="form-control"
                      defaultValue={"Going to hospital"}
                    />
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Edit Leave Modal */}
        {/* Approve Leave Modal */}
        <div
          className="modal custom-modal fade"
          id="approve_leave"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Leave Approve</h3>
                  <p>Are you sure want to approve for this leave?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <a href="" className="btn btn-primary continue-btn">
                        Approve
                      </a>
                    </div>
                    <div className="col-6">
                      <a
                        href=""
                        data-bs-dismiss="modal"
                        className="btn btn-primary cancel-btn"
                      >
                        Reject
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**************************** */}
        <div
          id="show_timeline"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <h5 className="modal-title">Leave Timeline</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <CustomizedTimeline
                  getTime={getTime}
                  timelineName="Leave Timeline"
                />
              </div>
            </div>
          </div>
        </div>
        {/***************************** */}
        {/* /Approve Leave Modal */}
        {/* Delete Leave Modal */}
        <Delete />
        {/* /Delete Leave Modal */}
      </div>
    </div>
  );
};

export default LeavesAdmin;
