import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Table, Tooltip } from "antd";
// import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../antdstyle.css";
// import Attendancegrid from "./Attendancegrid";
import axios from "axios";
import moment from "moment";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
// Datatable JS
// import "../../../assets/js/jquery.dataTables.min.js";
// import "../../../assets/js/dataTables.bootstrap4.min.js";
import Sidebar from "../Sidebar/sidebarAdmin";
import Header from "../Sidebar/header";
const Dailyfeed = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const monthval = new Date().getMonth() + 1;
  const [menu, setMenu] = useState(false);
  const [feed, setFeed] = useState("");
  const [getFeed, setGetFeed] = useState([]);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const token = sessionStorage.getItem("access_token");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  // console.log(monthValue);
  const handleGetFeed = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/daily_feeds/`,

        {
          params: {
            // year: monthValue.split("-")[0],
            // month: monthValue.split("-")[1],
            coregid: sessionStorage.getItem("coid"),
          },
          //   headers: {
          //     Authorization: `Bearer ${token}`,
          //   },
        }
      )
      .then((res) => {
        setGetFeed(
          res.data.map((ele, i) => ({
            ...ele,
            key: i + 1,
            date: ele.date.substr(0, 10).split("-").reverse().join("-"),
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetFeed();
  }, []);

  const handlePostFeed = (e) => {
    e.preventDefault();
    if (!feed) {
      Swal.fire({
        icon: "error",
        text: "Enter the feed.",
        // timer: 1500,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/daily_feeds/`, {
          admid: sessionStorage.getItem("admid"),
          feed: feed,
        })
        .then((res) => {
          handleGetFeed();
        });
    }
  };
  const handleDeleteFeed = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/daily_feeds/`, {
        data: {
          id: id,
          // admid: sessionStorage.getItem("admid"),
        },
      })
      .then((res) => {
        handleGetFeed();
      });
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      align: "left",
      key: "key",
      width: 100,
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Date",
      dataIndex: "date",
      align: "left",
      key: "key",
      sorter: {
        compare: (a, b) =>
          moment(a.date, "DD-MM-YYYY") - moment(b.date, "DD-MM-YYYY"),
      },
      //   sorter: (a, b) => a.date.length - b.date.length,
      // render: (text, record) => <>{text?.split("T")[0]}</>,
    },

    {
      title: "Feed",
      dataIndex: "feed",
      align: "left",
      key: "sno",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      align: "left",
      key: "sno",
    },
    {
      title: "Action",
      // dataIndex: "created_by",
      align: "center",
      key: "sno",
      render: (text, record) => (
        <button
          className="btn"
          style={{
            background: "#a020f0",
            // height: "27px",
            width: "55px",
            border: "none",
            padding: "3px 2px",
            color: "white",
            borderRadius: "5px",
            fontSize: "12px",
          }}
          onClick={() => handleDeleteFeed(text.id)}
        >
          Delete
        </button>
      ),
    },
  ];
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <h3 className="page-title">Daily Feed</h3>
                <div className="col-auto float-end ml-auto">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #973FCF",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#daily_feed"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" />
                    &nbsp; ADD FEED
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-0">
            <div className="card-body">
              <div className="table-responsive">
                <Table
                  ellipsis={true}
                  pagination={{
                    total: getFeed.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  //   style={{ overflowX: "auto" }}
                  columns={columns}
                  //   bordered
                  dataSource={getFeed}
                  //   rowKey={(record) => record.id}
                  // onChange={this.handleTableChange}
                  // exportable
                  // searchable
                  // exportableProps={{ showColumnPicker: true }}
                  // searchableProps={{ fuzzySearch: true }}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="daily_feed" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" style={{ marginTop: "16px" }}>
                Add Feed
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Feed <span className="text-danger">*</span>
                  </label>
                  <div>
                    <input
                      className="form-control "
                      type="text"
                      value={feed}
                      onChange={(e) => setFeed(e.target.value)}
                    />
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    data-bs-dismiss={!feed ? "" : "modal"}
                    className="submit-btn1"
                    onClick={(e) => handlePostFeed(e)}
                    style={{
                      backgroundColor: "#3298db",
                      minWidth: "30px",
                    }}
                  >
                    Save
                  </button>
                </div>

                {/*
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    onClick={(e) => handlePostFeed(e)}
                  >
                    Save
                  </button>
                </div>
                */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dailyfeed;
