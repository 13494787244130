import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Form,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  Input,
  Select,
} from "antd";
import Settings from "../../Settings/Settings";
import SalaryTable from "./SalaryTable";
import Swal from "sweetalert2";

// import async from "react-select/dist/declarations/src/async/index";
const Salary = (props) => {
  const {
    totalCtc,
    setTotalCtc,
    fixed,
    setFixed,
    otheV,
    othea,
    setOtheV,
    setOthea,
    totalSum,
    setTotalSum,
    otherAllow,
    otherDeduct,
    enrg,
    setEnrg,
    dummy,
    setDummy,
    variables,
    setVariables,
    deduct,
    setDeduct,
    fixedPer,
    setFixedPer,
    setOtherAllow,
    salData,
    setSalData,
    allData,
    setAllData,
    showPre,
    setShowPre,
    earn,
    setEarn,
    Ded,
    setDed,
    setVaria,
    varia,
  } = props;

  const [form] = Form.useForm();
  const [data, setData] = useState(enrg);
  const [showPreCTC, setShowPreCTC] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      value: "",

      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...enrg];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };

  const save1 = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...variables];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setVariables(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setEnrg(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };

  const save2 = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...deduct];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDeduct(newData);
        setEditingKey("");
        setDummy(!dummy);
      } else {
        newData.push(row);
        setDeduct(newData);
        setEditingKey("");
        setDummy(!dummy);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
    // finally {
    //   handleSalary(handleOther);
    // }
  };
  /*------------GET API for prefilled earnings,deductions, and variables---------------*/
  const token = sessionStorage.getItem("access_token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          comp_id: sessionStorage.getItem("coid"),
          toggle: "t3",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        setEnrg(
          res.data.earning.map((ele, i) => ({
            key: `E+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            amount: 0,
            annually: 0,
            enabled: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "A",
          }))
        );
        setVariables(
          res.data.variables.map((ele, i) => ({
            key: `V+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "V",
          }))
        );
        setDeduct(
          res.data.deduction.map((ele, i) => ({
            key: `D+${i}`,
            id: ele.id,
            name: ele.name,
            type: ele.type === "val" ? "Value" : "Percent",
            value: ele.value,
            monthly: 0,
            annually: 0,
            amount: 0,
            enabled: true,
            visible_payslip: ele.visible_payslip,
            taxable: ele.taxable,
            comp_type: "D",
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /*-----------------------*/

  /*------------------*/
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? (
        <InputNumber />
      ) : inputType === "select" ? (
        <Input />
      ) : dataIndex === "type" ? (
        <Select options={option} />
      ) : dataIndex === "visible_payslip" ? (
        <Select options={option1} />
      ) : (
        <Select options={option1} />
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  /*--------------------------*/
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  console.log(selectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  /*-------------------------*/

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          // <div className="table-action">
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
          //  <Popconfirm
          //         title="Sure to delete?"
          //         onConfirm={() => handleDelete(record.key)}
          //       >
          //         <a>Delete</a>
          //       </Popconfirm>
          //     </div>
        );
      },
    },
  ];

  const columns1 = [
    {
      title: "Name",
      dataIndex: "name",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save1(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          record.name !== "Other Variables" &&
            record.name !== "Total Variables" && (
              <Typography.Link
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
              >
                Edit
              </Typography.Link>
            )
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "Name",
      dataIndex: "name",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Type",
      dataIndex: "type",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Monthly",
      dataIndex: "monthly",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Annually",
      dataIndex: "annually",
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      editable: true,
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      editable: true,
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
      // align: "center",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save2(record.key)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const columns4 = [
    { title: "Name of Component", dataIndex: "name" },
    { title: "Monthly", dataIndex: "monthly", align: "right" },
    { title: "Annually", dataIndex: "annually", align: "right" },
    {
      title: "Visible in Payslip",
      dataIndex: "visible_payslip",
      align: "center",
      render: (text, record) => <>{text === 1 ? "Yes" : "No"}</>,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedColumns1 = columns1.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const mergedColumns2 = columns2.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "value"
            ? "number"
            : col.dataIndex === "name"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSalaryFix = (fix) => {
    setFixed(fix);
    setFixedPer((fix * 100) / totalCtc);
    // const fix = (totalCtc * per) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
  };

  const handleSalaryPer = (per) => {
    setFixed((totalCtc * per) / 100);
    setFixedPer(per);
    const fix = (totalCtc * per) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
  };

  const handleSalary = (handleOther) => {
    setFixed((totalCtc * fixedPer) / 100);
    // setFixedPer((fixed * 100) / totalCtc);
    const fix = (totalCtc * fixedPer) / 100;
    const vari = totalCtc - fix;

    setEnrg(
      enrg.map((ele, i) => {
        return {
          id: ele.id,
          enabled: true,
          key: i + ele.name,
          name: ele.name,
          type: ele.type,
          value: ele.value,
          visible_payslip: ele.visible_payslip,
          taxable: ele.taxable,
          comp_type: "A",
          monthly:
            ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
          annually:
            ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
        };
      })
    );

    setDeduct(
      deduct.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "D",
        monthly: ele.type === "Percent" ? (fix * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent" ? (fix * ele.value) / 100 : ele.value * 12,
      }))
    );
    setVariables(
      variables.map((ele, i) => ({
        id: ele.id,
        enabled: true,
        key: i + ele.name,
        name: ele.name,
        type: ele.type,
        value: ele.value,
        visible_payslip: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: "V",
        monthly:
          ele.type === "Percent" ? (totalCtc * ele.value) / 1200 : ele.value,
        annually:
          ele.type === "Percent"
            ? (totalCtc * ele.value) / 100
            : ele.value * 12,
      }))
    );
    // handleOther();
  };

  const handleOther = () => {
    // const rem = p.filter((ele) => ele.name !== "Other Allowance");
    const sum = enrg.reduce((accumulator, object) => {
      console.log(accumulator);
      return accumulator + object.annually;
    }, 0);
    const oth = fixed - sum;
    console.log(sum);
    console.log(oth);
    setEnrg(
      enrg.map((ele) => {
        return {
          name: ele.name,
          type: ele.type,
          value: ele.value,
          monthly: ele.name === "Other Allowance" ? oth / 12 : ele.monthly,
          annually: ele.name === "Other Allowance" ? oth : ele.annually,
        };
      })
    );
  };
  useEffect(() => {
    handleSalary(handleOther);
  }, [totalCtc, dummy]);

  const option = [
    {
      label: "Percent",
      value: "Percent",
    },
    {
      label: "Value",
      value: "Value",
    },
  ];

  const option1 = [
    {
      label: "Yes",
      value: 1,
    },
    {
      label: "No",
      value: 0,
    },
  ];

  const handleAdd = (e) => {
    e.preventDefault();
    const newData = {
      key: `E+${enrg.length + 1}`,
      name: "",
      type: "",
      value: 0,
      monthly: 0,
      annually: 0,
      enabled: true,
    };
    setEnrg([...enrg, newData]);
    // setCount(count + 1);
  };

  const handleDelete = (key) => {
    const newData = enrg.filter((item) => item.key !== key);
    setEnrg(newData);
  };

  useEffect(() => {
    const sum = enrg.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);
    const sum1 = variables.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);

    setOtherAllow(fixed - sum);
    const oth = fixed - sum;
    const tot = oth + sum;
    setOthea([
      {
        name: "Other Allowance",
        type: "-",
        value: "-",
        monthly: oth / 12,
        annually: oth,
        enabled: false,
      },
      {
        name: "Total Earnings",
        type: "-",
        value: "-",
        monthly: tot / 12,
        annually: tot,
        enabled: false,
      },
    ]);

    // setEnrg(
    //   enrg.concat[
    //     {
    //       name: "Other Allowance",
    //       type: "-",
    //       value: "-",
    //       monthly: oth / 12,
    //       annually: oth,
    //     }
    //   ]
    // );
    // setTotalSum( othea[0].annually + otheV[0].annually + sum1);
    // setTotalSum(tot + otheV[1]?.annually);
  }, [enrg]);
  console.log(totalSum);
  useEffect(() => {
    setTotalSum(othea[1]?.annually + otheV[1]?.annually);
  }, [othea, otheV]);

  useEffect(() => {
    const sum = variables.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);
    const sum1 = enrg.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);
    const fix = (totalCtc * fixedPer) / 100;

    const vari = totalCtc - fix;
    const mVari = vari - sum;
    const tot = mVari + sum;
    console.log(fix);
    console.log(vari);
    console.log(mVari);
    setOtheV([
      {
        name: "Other Variables",
        type: "-",
        value: "-",
        monthly: mVari / 12,
        annually: mVari,
        enabled: false,
      },
      {
        name: "Total Variables",
        type: "-",
        value: "-",
        monthly: tot / 12,
        annually: tot,
        enabled: false,
      },
    ]);

    // setTotalSum(tot + othea[1]?.annually);
  }, [variables]);

  const handleValue = () => {
    const totalErn = enrg.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);
    const totalErnM = enrg.reduce((accumulator, object) => {
      return accumulator + object.monthly;
    }, 0);

    const totalGross = enrg
      .filter((ele) => ele.payslip_visible !== "No")
      .reduce((accumulator, object) => {
        return accumulator + object.annually;
      }, 0);
    const totalGrossM = enrg
      .filter((ele) => ele.payslip_visible !== "No")
      .reduce((accumulator, object) => {
        return accumulator + object.monthly;
      }, 0);
    const totalDeductM = deduct.reduce((accumulator, object) => {
      return accumulator + object.monthly;
    }, 0);

    const totalDeduct = deduct.reduce((accumulator, object) => {
      return accumulator + object.annually;
    }, 0);

    setSalData({
      monthly_ctc: totalErnM.toFixed(0),

      monthly_gross: totalGrossM.toFixed(0),
      annual_gross: totalGross.toFixed(0),
      monthly_net: (totalGrossM - totalDeductM).toFixed(0),
      annual_net: (totalGross - totalDeduct).toFixed(0),
    });
  };

  useEffect(() => {
    handleValue();
  }, [enrg, deduct, variables]);

  const calculateCTCSalary = async (e) => {
    e.preventDefault();
    const payload = enrg
      .concat(deduct)
      .concat(variables)
      .map((ele) => ({
        id: ele.id,
        name: ele.name,
        amt: ele.value == null ? 0 : ele.value,
        payslip_visible: ele.visible_payslip,
        taxable: ele.taxable,
        comp_type: ele.comp_type,
        type: ele.type === "Value" ? "val" : "per",
        comp_id: [0],
      }));
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/calculate_salary_sad/`, {
        monthly_ctc: parseInt(salData.monthly_ctc),
        annual_ctc: parseInt(totalCtc),
        monthly_gross: "",
        annual_gross: "",
        fixed_ctc: parseInt(fixed) / 12,
        monthly_net: "",
        annual_net: "",
        component: payload,
      })
      .then((res) => {
        console.log(res.data);
        setAllData(res.data);
        setEarn(
          res.data.component
            .filter((item) => item.comp_type === "A")
            .map((ele, i) => ({
              key: i + 1,
              id: ele.id,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setDed(
          res.data.component
            .filter((item) => item.comp_type === "D")
            .map((ele, i) => ({
              key: i + 1,
              id: ele.id,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setVaria(
          res.data.component
            .filter((item) => item.comp_type === "V")
            .map((ele, i) => ({
              key: i + 1,
              id: ele.id,
              name: ele.name,
              annually: ele.amt * 12,
              monthly: ele.amt,
              comp_type: ele.comp_type,
              type: ele.type,
              visible_payslip: ele.payslip_visible,
              taxable: ele.taxable,
            }))
        );
        setShowPreCTC(true);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  // useEffect(() => {
  //   const sum = variables.reduce((accumulator, object) => {
  //     return accumulator + object.annually;
  //   }, 0);

  //   const totleDeduct=

  // }, [enrg]);

  /* Edit Table */

  /*---------------  */

  return (
    <div className="col-md-12 row">
      <div className="col-md-2"></div>
      <div
        style={{ background: "white", padding: "20px" }}
        className="col-md-8"
      >
        {/* <div className="">
        <input
          type="radio"
          id="html"
          name="fav_language"
          value="HTML"
          checked
        />
        <label for="html">Total CTC</label>
        &nbsp; &nbsp;
        <input type="radio" id="css" name="fav_language" value="CSS" />
        <label for="css">Basic Salary</label>
      </div> */}
        {!showPreCTC ? (
          <>
            <div className="col-md-6">
              <h4 style={{ fontWeight: "600" }}>Salary Details</h4>
            </div>

            <br />
            <div className="row col-md-12">
              <div className=" col-md-4">
                <label>Total CTC :</label> &nbsp;
                <input
                  placeholder="Total CTC"
                  className="salary_input"
                  value={totalCtc}
                  onChange={(e) => setTotalCtc(e.target.value)}
                />
              </div>

              <div className=" col-md-4">
                <label>
                  Fixed CTC % <sup className="sup">*</sup>:
                </label>
                &nbsp;
                <input
                  placeholder="fixed CTC %"
                  className="salary_input"
                  value={fixedPer}
                  onChange={(e) => handleSalaryPer(e.target.value)}
                  // onChange={(e) => setFixedPer(e.target.value)}
                />
              </div>
              <div className=" col-md-4">
                <label>Fixed CTC :</label> &nbsp;
                <input
                  placeholder="fixed CTC %"
                  className="salary_input"
                  value={fixed}
                  onChange={(e) => handleSalaryFix(e.target.value)}
                  // onChange={(e) => setFixed(e.target.value)}
                />
              </div>
            </div>
            <br />
            {/* <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>{`Monthly CTC`}:</label> &nbsp;&nbsp;&nbsp;
                      {salData.monthly_ctc}
                    </div>
                    <div>
                      {" "}
                      <label>{`Monthly Gross`}:</label> &nbsp;&nbsp;&nbsp;
                      {salData.monthly_gross}
                    </div>
                    <div>
                      {" "}
                      <label>Monthly Gross before TDS :</label>
                      &nbsp;&nbsp;&nbsp;
                      {salData.monthly_net}
                    </div>
                  </div>
                </div>
                <br />
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>Annual Gross:</label>&nbsp;&nbsp;&nbsp;
                      {salData.annual_gross}
                    </div>
                    <div>
                      <label>Annual Gross before TDS:</label>&nbsp;&nbsp;&nbsp;
                      {salData.annual_net}
                    </div>
                  </div>
                </div> */}

            <br />

            <div>
              <p className="salary_head">Earnings:</p>
              {/* <button
          onClick={(e) => handleAdd(e)}
          type="primary"
          style={{
            marginBottom: 16,
          }}
        >
          Add a row
        </button> */}
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={enrg}
                  columns={mergedColumns}
                  // rowClassName="editable-row"
                  pagination={false}
                  // rowClassName={(record) =>
                  //   !record.enabled ? "disabled-row" : "editable-row"
                  // }
                  // rowSelection={rowSelection}
                />
              </Form>
              {/* <SalaryTable data={enrg} /> */}
            </div>
            {/* <div className="other">
        <p>Other Allowances</p>
        <p>{`-`}</p>
        <p>{`-`}</p>
        <p className="other-r">{othea[0].monthly}</p>
        <p className="other-r">{othea[0].annually}</p>
      </div> */}
            <br />
            <div>
              <p className="salary_head"> Standard Deductions:</p>
              <Form form={form} component={false}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={deduct}
                  pagination={false}
                  columns={mergedColumns2}
                  // rowClassName="editable-row"
                  // pagination={false}
                  rowClassName={(record) =>
                    !record.enabled ? "disabled-row" : "editable-row"
                  }
                />
              </Form>
            </div>
            <br />
            <div>
              <p className="salary_head">Variables:</p>
              <Form form={form} component={false}>
                <Table
                  // columns={columns1}
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={variables.concat(otheV)}
                  pagination={false}
                  columns={mergedColumns1}
                  // rowClassName="editable-row"
                  // pagination={false}
                  rowClassName={(record) =>
                    !record.enabled ? "disabled-row" : "editable-row"
                  }
                />
              </Form>

              {/* <div className="other">
          <p>Other Allowances</p>
          <p>{`-`}</p>
          <p>{`-`}</p>
          <p className="other-r">{otherAllow / 12}</p>
          <p className="other-r">{otherAllow}</p>
        </div> */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="btn-sub-ctcbased"
                style={{ background: "#3298DB", color: "white" }}
                onClick={(e) => calculateCTCSalary(e)}
              >
                Calculate
              </button>
            </div>
          </>
        ) : (
          <>
            <>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <button
                  onClick={() => setShowPreCTC(false)}
                  style={{
                    width: "fit-content",
                    border: "none",
                    background: "#004693",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  Recalculate
                </button>
              </div>

              <br />
              {earn.length !== 0 && (
                <>
                  <label>Earnings</label>
                  <br />
                  <Table
                    columns={columns4}
                    dataSource={earn}
                    pagination={false}
                  />
                  <br />
                </>
              )}
              <br />
              {Ded.length !== 0 && (
                <>
                  <label>Deductions</label>
                  <br />
                  <Table
                    columns={columns4}
                    dataSource={Ded}
                    pagination={false}
                  />
                  <br />
                </>
              )}
              <br />
              {varia.length !== 0 && (
                <>
                  <label>Variables</label>
                  <br />
                  <Table
                    columns={columns4}
                    dataSource={varia}
                    pagination={false}
                  />
                </>
              )}

              <br />
              <br />

              <div style={{ display: "flex", marginTop: "20px" }}>
                <p className="col-md-4">
                  <b>Monthly CTC :</b>&nbsp;
                  {allData.monthly_ctc}
                </p>
                <p className="col-md-4">
                  <b>Monthly Gross :</b>&nbsp;
                  {allData.monthly_gross}
                </p>
                <p className="col-md-4">
                  <b>Monthly Net :</b>&nbsp;
                  {allData.monthly_net}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <p className="col-md-4">
                  <b>Annual CTC :</b>&nbsp;
                  {allData.annual_ctc}
                </p>
                <p className="col-md-4">
                  <b>Annual Gross :</b>&nbsp;
                  {allData.annual_gross}
                </p>
                <p className="col-md-4">
                  <b>Annual Net :</b>&nbsp;
                  {allData.annual_net}
                </p>
              </div>
              {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className={
                    ctcType === 1 ? "btn-sub-ctcbased" : "btn-sub-basicbased"
                  }
                  style={{ background: "#3298DB", color: "white" }}
                  onClick={(e) => handleUpdateSalary(e)}
                >
                  Save
                </button>
              </div> */}
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default Salary;
