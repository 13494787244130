import React, { useState, useEffect } from "react";
import rupee from "../../assets/img/profiles/Rupee-Symbol-Black.svg";
import { Badge, Dropdown, Space, Table } from "antd";
import axios from "axios";
// import rupee from "../../assets/img/profiles/Rupee-Symbol-Black.svg";
import { recome } from "./taxConst";
import { recomConst } from "./taxConst";
import pointer from "../../assets/img/profiles/Vaibhav_icon.png";
import Swal from "sweetalert2";
const TaxPreview = (props) => {
  const {
    eightyc,
    setEightc,
    eightyd,
    setEightd,
    eightyG,
    eightyE,
    eightyEe,
    eightyDd,
    eightyCCG,
    eightyDDB,
    showp,
    show,
    setShowp,
    section,
    setSection,
    rent,
  } = props;
  // const {section, setSection}=props
  const [tds, setTds] = useState({
    eightyC_val: 0,
    eightyD_val: 0,
    eightyG_val: 0,
    eightyE_val: 0,
    eightyEE_val: 0,
    eightyU_DD_val: 0,
    eightyCCG_val: 0,
    eightyDDB_val: 0,
    finalDeduction: 0,
    annual_tds: 0,
    total_taxable_income: 0,
  });
  const [taxable, setTaxable] = useState([]);
  const [rentComp, setRentComp] = useState([
    {
      key: 1,
      component: rent
        ? "Monthly rental value"
        : "Interest on housing loan on self-occupied property",
      amount: section?.rentORpropoerty,
    },
  ]);

  const data = [
    {
      key: 1,
      component: "Basic Pay",
      amount: 20000,
    },
    { key: 2, component: "HRA", amount: 5000 },
    { key: 3, component: "Special Allowances", amount: 2000 },
  ];
  const values = Object.values(eightyDd);
  const other = [
    {
      component: "80G- Donations",
      amount: eightyG,
    },
    {
      component: "  80E- Interest on Loans for Higher Education",
      amount: eightyE,
    },
    {
      component:
        " 80EE- Interest on residential property less than Rs 50 lakhs in value",
      amount: eightyE,
    },
    {
      component: "80U or 80 DD- Disability of Self or Dependent",
      amount: values.reduce((a, b) => parseInt(a) + parseInt(b)),
    },
    {
      component:
        "80CCG- First time Equity Investor with gross salary less than Rs 12 lacks p.a. (50% of amount invested in selected schemes)",
      amount: eightyCCG,
    },
    {
      component: "80DDB- Expenses on specified diseases for self/dependent",
      amount: eightyDDB,
    },
  ];

  const HandleSubmitTds = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/tds_calc/`, {
        empid: sessionStorage.getItem("emp_id"),
        tds: tds.annual_tds,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",

          text: "Your IDF Submitted Successfully",
        }).catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
      });
  };

  const handleGetPayroll = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          toggle: "i2",
          comp_id: sessionStorage.getItem("coid"),
        },
      })
      .then((res) => {
        console.log(res.data);
        setTaxable(
          res.data.earning
            .filter((ie) => ie.component_name !== " Wifi Allowance")
            .concat(res.data.variables)
            .map((ele) => ({
              key: 1,
              component: ele.component_name,
              amount: ele.component_amount_annual,
            }))
            .filter((item) => item.component !== "Conveyance Allowance")
        );
        // setEmpPayrollDetails(res.data);
        // searchData(res.data);
        // setViewSalary(res.data);
        // const sum = res.data.earning.reduce((accumulator, object) => {
        //   return accumulator + object.component_amount_monthly;
        // }, 0);
        // setErSum(sum);
        // const sum1 = res.data.deduction.reduce((accumulator, object) => {
        //   return accumulator + object.component_amount_monthly;
        // }, 0);
        // setDeSum(sum1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetPayroll();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  const columns1 = [
    {
      title: "Recommendations",
      dataIndex: "component",
      // align: "left",
      key: "component",
      width: "100%",
    },
  ];
  const token = sessionStorage.getItem("access_token");
  const empid = sessionStorage.getItem("emp_id");
  const EightYC = eightyc.map((v) => v.value);
  const EightYD = eightyd.map((v) => v.value);

  const CalculateTDS = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/tds/`,
        {
          empid: empid,
          type: show ? "new" : "old",
          financial_year: "2023-2024",
          component: taxable.map((ele) => ({
            name: ele.component.toLowerCase(),
            value: ele.amount,
          })),
          eightyC: {
            insurancePremium_children: EightYC[0],
            housingLoan_principalComponent: EightYC[1],
            NSC_tuitionFee_stampDuty_mutualFund_UTI_otherInvestments:
              EightYC[2] +
              EightYC[3] +
              EightYC[4] +
              EightYC[5] +
              EightYC[6] +
              EightYC[7],
            individualContribution_NPS: EightYC[8],
          },
          eightyD: {
            medicalInsurancePremium_NONSeniorCitizen: EightYD[0],
            medicalInsurancePremium_NONSeniorCitizen_Parents: EightYD[1],
            medicalInsurancePremium_SeniorCitizen: EightYD[2],
            medicalInsurancePremium_SeniorCitizen_Parents: EightYD[3],
            preventiveHealthCheckups: EightYD[4],
            preventiveHealthCheckups_Parents: EightYD[5],
            medicalExpenditure: EightYD[6],
            medicalExpenditure_Parents: EightYD[7],
          },
          eightyG: {
            donation: parseInt(eightyG),
          },
          eightyE: {
            interestON_educationLoan: parseInt(eightyE),
          },
          eightyEE: {
            intrestONLoan: parseInt(eightyEe),
          },
          eightyU_DD: eightyDd,
          eighyCCG: {
            amountInvested: parseInt(eightyCCG),
          },
          eightyDDB: {
            expensesOnSpecifiedDiseases: parseInt(eightyDDB),
            isSeniorCitizen: 0,
          },
          section: {
            age: parseInt(section.age),
            isMetroCity: section.isMetroCity,
            rentORpropoerty: parseInt(section.rentORpropoerty),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const data = await res.data;
      // const value = data.filter((v) => v !== "Final Deduction: ");
      setTds(res.data);
    } catch (err) {
      if (err?.response?.status === 400) {
        Swal.fire({
          icon: "error",
          text: `${err?.response?.data?.error}!`,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
      }
    }
  };

  const columns = [
    {
      title: "Components",
      dataIndex: "component",
      align: "center",
      key: "component",
      width: "40%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
  ];
  return (
    <div
      className="col-md-12 main-page"
      // style={{ background: "white" }}
    >
      <div className="contain">
        <div className="col-md-12  ">
          <h4>
            <b>
              <u>
                Taxable Income
                {/* <img src={show ? up : down} /> */}
              </u>
            </b>
          </h4>
        </div>
        <br />
        {/* <div className="col-md-12 row  ">
          <div className="col-md-1"></div>
          <label className="col-md-6">Basic Salary</label>
          <div className=" col-md-3  tax-input1">
            <img src={rupee} className="rupee col-md-4" />
            <input
              className="tax-i col-md-8"
              // placeholder="Enter Amount"
            />
          </div>
        </div> */}
        {/* <br />
        <br />
        <div className="col-md-12 row  ">
          <div className="col-md-1"></div>
          <label className="col-md-6">House Rent Allowance</label>
          <div className=" col-md-3  tax-input1">
            <img src={rupee} className="rupee col-md-4" />
            <input
              className="tax-i col-md-8"
          
            />
          </div>
        </div>
        <br />
        <br />
        <div className="col-md-12 row ">
          <div className="col-md-1"></div>
          <label className="col-md-6">Other Allowances</label>
          <div className=" col-md-3  tax-input1">
            <img src={rupee} className="rupee col-md-4" />
            <input
              className="tax-i col-md-8"
          
            />
          </div>
        </div>
        <br />
        <br /> */}

        <div className="table-responsive">
          <Table
            pagination={false}
            // pagination={{
            //   total: showTasks.length,
            //   showTotal: (total, range) =>
            //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            //   showSizeChanger: true,
            //   onShowSizeChange: onShowSizeChange,
            //   itemRender: itemRender,
            // }}
            columns={columns}
            dataSource={taxable}
            // expandable={{
            //   expandedRowRender,
            //   defaultExpandedRowKeys: ["0"],
            // }}
            // dataSource={showTasks}
            // loading={isLoading}
          />
        </div>
        <br />

        {!show && (
          <>
            <div className="col-md-12 row ">
              <h4>
                <b>
                  <u>
                    Exemption
                    {/* <img src={show ? up : down} /> */}
                  </u>
                </b>
              </h4>
            </div>
            <br />
            <div
              className=" col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5>80C Deduction</h5>
              <h4>
                <b>
                  {" "}
                  <img src={rupee} className="rupee " />
                  {tds.eightyC_val}
                </b>
              </h4>
            </div>
            <div>
              {" "}
              {/*className="table-responsive"*/}
              <Table
                pagination={false}
                // pagination={{
                //   total: showTasks.length,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                //   showSizeChanger: true,
                //   onShowSizeChange: onShowSizeChange,
                //   itemRender: itemRender,
                // }}
                columns={columns}
                dataSource={eightyc
                  .map((ele, i) => {
                    return {
                      key: i + 1,
                      component: ele.name,
                      amount: ele.value ? ele.value : 0,
                    };
                  })
                  .filter((item, index) => item.amount > 0)}
                //.filter((item) => item.amount)}
                //.filter((item) => item.amount > 0)}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ["0"],
                // }}
                // dataSource={showTasks}
                // loading={isLoading}
              />
            </div>
            <br />
            <br />

            <div
              className=" col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5>80D Deduction</h5>
              <h4>
                <b>
                  {" "}
                  <img src={rupee} className="rupee " />
                  {tds.eightyD_val}
                </b>
              </h4>
            </div>
            <div>
              <Table
                pagination={false}
                // pagination={{
                //   total: showTasks.length,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                //   showSizeChanger: true,
                //   onShowSizeChange: onShowSizeChange,
                //   itemRender: itemRender,
                // }}
                columns={columns}
                dataSource={eightyd
                  .map((ele, i) => {
                    return {
                      key: i + 1,
                      component: ele.name,
                      amount: ele.value ? ele.value : 0,
                    };
                  })
                  .filter((item, index) => item.amount > 0)}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ["0"],
                // }}
                // dataSource={showTasks}
                // loading={isLoading}
              />
            </div>
            <br />
            <br />
            <div
              className=" col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5>Other Deduction</h5>
              <h4>
                <b>
                  {" "}
                  <img src={rupee} className="rupee " />{" "}
                  {tds.eightyG_val +
                    tds.eightyCCG_val +
                    tds.eightyE_val +
                    tds.eightyDDB_val +
                    tds.eightyEE_val +
                    tds.eightyU_DD_val}
                </b>
              </h4>
            </div>

            <div className="table-responsive">
              <Table
                pagination={false}
                // pagination={{
                //   total: showTasks.length,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                //   showSizeChanger: true,
                //   onShowSizeChange: onShowSizeChange,
                //   itemRender: itemRender,
                // }}
                columns={columns}
                dataSource={other.filter((ele) => ele.amount > 0)}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ["0"],
                // }}
                // dataSource={showTasks}
                // loading={isLoading}
              />
            </div>
            <br />
            <br />
            <div
              className=" col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h5>House rent or Housing Loan</h5>
              <h4>
                <b>
                  {" "}
                  <img src={rupee} className="rupee " />{" "}
                  {section.rentORpropoerty}
                </b>
              </h4>
            </div>

            <div className="table-responsive">
              <Table
                pagination={false}
                // pagination={{
                //   total: showTasks.length,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                //   showSizeChanger: true,
                //   onShowSizeChange: onShowSizeChange,
                //   itemRender: itemRender,
                // }}
                columns={columns}
                dataSource={rentComp.filter((ele) => ele.amount > 0)}
                // expandable={{
                //   expandedRowRender,
                //   defaultExpandedRowKeys: ["0"],
                // }}
                // dataSource={showTasks}
                // loading={isLoading}
              />
            </div>
          </>
        )}
        <br />
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="tax-btn" onClick={() => setShowp(false)}>
            Go Back
          </button>{" "}
          &nbsp; &nbsp;&nbsp;
          <button className="tax-btn" onClick={CalculateTDS}>
            Calculate TDS
          </button>
        </div>
        <br />
        <br />
        <div className="col-md-12  row ">
          <div className="col-md-1"></div>
          <label className="col-md-6">
            <b>Annual TDS</b>
          </label>
          <div
            className=" col-md-3  tax-input1"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={rupee} className="rupee col-md-4" />
            <input
              className="tax-i col-md-8"
              value={tds.annual_tds}
              // placeholder="Enter Amount"
            />
          </div>
        </div>
        <br />
        <br />
        <div className="col-md-12 row ">
          <div className="col-md-1"></div>
          <label className="col-md-6">
            <b>Monthly TDS</b>
          </label>
          <div
            className=" col-md-3  tax-input1"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={rupee} className="rupee col-md-4" />
            <input
              className="tax-i col-md-8"
              value={
                tds.annual_tds === 0 ? 0 : (tds.annual_tds / 12).toFixed(2)
              }
              // placeholder="Enter Amount"
            />
          </div>
        </div>

        <br />
        <h4>
          <b>Recommendations</b>
        </h4>
        {tds.eightyC_val < 150000 && !show && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.b}</p>
          </div>
        )}
        {tds.eightyD_val < 50000 && !show && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.c}</p>
          </div>
        )}
        {tds.eightyD_val < 25000 && !show && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.d}</p>
          </div>
        )}
        {show && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.e}</p>
          </div>
        )}
        {tds.total_taxable_income < 510000 && tds.finalDeduction > 0 && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.f}</p>
          </div>
        )}
        {tds.total_taxable_income > 100000 && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "15px", height: "15px", marginTop: "4px" }}
              src={pointer}
            />
            &nbsp; &nbsp;
            <p>{recomConst.g}</p>
          </div>
        )}
        {tds.total_taxable_income > 100000 &&
          tds.eightyG_val === 0 &&
          !show && (
            <div style={{ display: "flex" }}>
              <img
                style={{ width: "15px", height: "15px", marginTop: "4px" }}
                src={pointer}
              />
              &nbsp; &nbsp;
              <p>{recomConst.h}</p>
            </div>
          )}
        {tds.total_taxable_income < 100000 &&
          tds.eightyG_val > 50000 &&
          !show && (
            <div style={{ display: "flex" }}>
              <img
                style={{ width: "15px", height: "15px", marginTop: "4px" }}
                src={pointer}
              />
              &nbsp; &nbsp;
              <p>{recomConst.i}</p>
            </div>
          )}

        <div style={{ display: "flex" }}>
          <img
            style={{ width: "15px", height: "15px", marginTop: "4px" }}
            src={pointer}
          />
          &nbsp; &nbsp;
          <p>{recomConst.j}</p>
        </div>
        {/* <p>{recomConst.k}</p>
        <p>{recomConst.l}</p> */}

        {/* <div className="table-responsive">
          <Table
            pagination={false}
           
            columns={columns1}
            dataSource={recome}
            rowSelection={rowSelection}
          
          />
        </div> */}
        <br />
        <i>
          *Disclaimer: Recommendations are advisory in nature and any financial
          advice should be implemented after consultation with certified
          financial advisors only.{" "}
        </i>
        <br />
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <button className="tax-btn" onClick={() => HandleSubmitTds()}>
            Final Submit to Company
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaxPreview;
