import React from "react";
import NewTheme from "../components/NewTheme";
import closeIcon from "../assets/img/profiles/close-circle-fill.svg";

const Themes = ({ handleChange, backImg, setBackImg }) => {

  return (
    <div
      id="change_theme"
      className="modal custom-modal fade" role="dialog"
    // style={{ opacity: "1" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg"
        role="document">
        <div className="modal-content">
          <br />
          <div className="modal-header">
            <h4 className="modal-title" style={{ marginTop: "10px" }}><b>Choose Theme</b></h4>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setBackImg("")}
            >
              <img src={closeIcon} />
            </button>
          </div>
          <div className="modal-body">
            <NewTheme handleChange={handleChange} backImg={backImg} setBackImg={setBackImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Themes;
