import { Box, TextField, Button } from "@mui/material";
import img1 from "../../assets/img/group-people-working-out-business-plan-office.jpg";
import styled from "@emotion/styled";

export const styles = {
  paperContainer: {
    height: "100vh",
    backgroundImage: `url(${img1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

export const SBox1 = styled(Box)({
  "@media (max-width: 960px)": {
    width: "50%",
  },
  "@media (max-width: 600px)": {
    display: "none",
  },
});

export const SBox2 = styled(Box)({
  textAlign: "center",
  margin: "24px",
  "@media (max-width: 960px)": {
    width: "50%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

export const SBox3 = styled(Box)({
  marginTop: "16px",
  padding: "16px",
  textAlign: "left",
});

export const SBox4 = styled(Box)({
  marginTop: "8px",
  "@media (max-width: 1309px)": {
    marginTop: "4px",
    width: "60%",
  },
});

export const STextField = styled(TextField)({
  width: "100%",
  marginBottom: "24px",
});

export const SButton = styled(Button)({
  textTransform: "none",
  background: "#18838d",
  borderRadius: "8px",
  boxShadow: "none",
  padding: "8px",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginTop: "8px",
  marginBottom: "8px",
  width: "120px",
  height: "45px",
  ":hover": {
    background: "#18838d",
    boxShadow: "none",
  },
});
