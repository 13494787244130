import { Box, Button, Fab } from "@mui/material"
import styled from '@emotion/styled'

export const SButton = styled(Button)({
    background: '#F39C12',
    borderRadius: '16px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '8px',
    marginTop: '8px',
    ":hover": {
        background: '#F39C12',
        boxShadow: 'none'
    }
})

export const SButton1 = styled(Button)({
    background: '#797D7F',
    borderRadius: '16px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '8px',
    marginTop: '8px',
    width: '120px',
    ":hover": {
        background: '#797D7F',
        boxShadow: 'none'
    }
})

export const SButton2 = styled(Button)({
    background: '#E74C3C',
    borderRadius: '16px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '8px',
    marginTop: '8px',
    width: '120px',
    ":hover": {
        background: '#E74C3C',
        boxShadow: 'none'
    }
})

export const SButton3 = styled(Button)({
    background: '#2ECC71',
    borderRadius: '16px',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '8px',
    marginTop: '8px',
    width: '120px',
    ":hover": {
        background: '#2ECC71',
        boxShadow: 'none'
    }
})

export const SClockButton = styled(Button)({
    background: 'transparent',
    borderRadius: '16px',
    border: '2px solid #FFFFFF',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '16px',
    marginTop: '8px',
    ":hover": {
        background: 'transparent',
        boxShadow: 'none'
    }
})

export const SBox = styled(Box)({
    position: 'fixed',
    height: '355px',
    minWidth: '300px',
    width: '353px',
    padding: '12px',
    background: '#FFFFFF',
    borderRadius: '8px',
    bottom: '70px',
    right: '14px'
})

export const SFab = styled(Fab)({
    position: "fixed",
    bottom: "0px",
    right: "0px",
    margin: "8px"
})
