import React from 'react'
import { useState } from 'react';
import Sidebar from '../Sidebar/sidebarAdmin';
import Header from '../Sidebar/header';

const Samarth = () => {
    const [menu, setMenu] = useState(false);
    const toggleMobileMenu = () => {
        setMenu(!menu);
      };
      const user= sessionStorage.getItem("user")
      const token= sessionStorage.getItem("token")
  return (
    
          <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <iframe
//   width="80%"
marginTop="60px"
  height="800px"
//   style="border:0"
  loading="lazy"
//   allowfullscreen
  referrerpolicy="no-referrer-when-downgrade"
  src={`http://samarth-uat-job-provider.s3-website.ap-south-1.amazonaws.com/login/?user=${user}&token=${token}`}>
</iframe>
</div>
    </div>
  )
}

export default Samarth