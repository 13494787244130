import React from 'react';
import ControlPanel from '../ControlPanel/ControlPanel';
import ChestContainer from '../ChestContainer/ChestContainer';

const Game = (props) => {
  return (
    <>
      <ChestContainer />
      <ControlPanel toggle={props.toggle} setToggle={props.setToggle}/>
    </>
  );
};

export default Game;
