import React from "react";
import { useState } from "react";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import faq from "../../assets/img/profiles/OZHRMSFAQ.pdf";
import down from "../../assets/img/profiles/download-2-line.png";
const FAQ = () => {
  const [guides, setGuides] = useState([]);
  const [menu, setMenu] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">FAQ</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <a
                  style={{
                    color: "white",
                    backgroundColor: "#009ce7",
                    padding: "10px 10px",
                    fontSize: "16px",
                    borderRadius: "5px",
                  }}
                  href={faq}
                  download
                >
                  {" "}
                  Download <img src={down} style={{ height: "17px" }} />
                </a>
              </div>
            </div>
          </div>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={faq} width="100%" />
          </Worker>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
