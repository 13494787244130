import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import setting from "../../assets/img/profiles/usersettings.svg";
import shield from "../../assets/img/profiles/shield.svg";
import SettingsSidebar from "../Sidebar/settingsidebar";
import Header from "../Sidebar/header";
import { Radio, TextField } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";

const RolePermission = () => {
  const [menu, setMenu] = useState(false);
  const [dataAccess, setDataAccess] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [addRol, setAddRol] = useState({ name: "", desc: "" });
  const [updateRole, setUpdateRole] = useState({});
  const [permissions, setPermissions] = useState([]);
  const [roleID, setRoleID] = useState("");
  const [permissionView, setPermissionView] = useState(false);
  const [managerList, setManagerList] = useState([]);
  const [member, setMember] = useState([]);
  const [edit, setEdit] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const token = sessionStorage.getItem("access_token");

  const handlePermission = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/group_role/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { grp_id: sessionStorage.getItem("role") },
        }
      )
      .then((res) => {
        let permission = {};

        res.data.forEach((element) => {
          permission[`${element.name}`] = element.optn?.filter(
            (item) => item.value === element.selected
          )[0].label;
        });

        sessionStorage.setItem("permissions", JSON.stringify(permission));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getRole = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/group_role/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { admid: sessionStorage.getItem("admid") },
        }
      )
      .then((res) => {
        setRoleList(res.data);
        setRoleID(res.data[0].id);
        setEdit(false);
        setPermissionView(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addRole = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/group_role/`, {
        admid: sessionStorage.getItem("admid"),
        name: addRol.name,
        desc: addRol.desc,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Role added Successfully",
          // timer: 2000,
        });
        getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateRol = () => {
    axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/group_role/`, {
        grp_id: updateRole.id,
        name: updateRole.name,
        desc: updateRole.desc,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Role updated Successfully",
          // timer: 2000,
        });
        getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRolePermissions = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/group_role/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { grp_id: roleID },
        }
      )
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePermission = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/group_role/`, {
        grp_id: roleID,
        permission: permissions.map((ele) => ele.selected),
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Permissions Updated Successfully",
          // timer: 2000,
        });
        setEdit(!edit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddMember = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/group_role/`, {
        grp_id: roleID,
        admids: roleList
          ?.filter((ele) => roleID == ele.id)[0]
          ?.members_details.map((item) => item.admid)
          .concat(member.map((ele) => ele.value)),
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Member added Successfully",
          // timer: 2000,
        });
        setMember([]);
        getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveMember = (e, id) => {
    e.preventDefault();
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_URL}/group_role/`,
        {
          data: {
            grp_id: roleID,
            admids: [id],
          },
        }
        // {
        //   params: { grp_id: roleID, admids: [id] },
        // }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Member deleted",
          // timer: 2000,
        });
        getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRemoveRole = (e) => {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/group_role/`, {
        data: {
          grp_id: updateRole.id,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Role deleted",
          // timer: 2000,
        });
        getRole();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormChange12 = (id, e, index) => {
    // console.log(id);
    // const newLevelData = permissions.map((ele) => {
    //   if (ele.id === id) {
    //     return { ...ele, selected: e.value };
    //   }
    //   return ele;
    // });

    // setPermissions(newLevelData);
    let data = [...permissions];
    console.log(index);
    // console.log(event);
    data[index]["selected"] = e.value;
    // data[index][event] = event.value;
    setPermissions(data);
  };
  useEffect(() => {
    getRole();
  }, []);
  useEffect(() => {
    getRolePermissions();
  }, [roleID]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <SettingsSidebar />
      <div className="page-wrapper" style={{ paddingTop: "25px" }}>
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                {/* <h3 className="page-title">Roles &amp; Permissions</h3> */}
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div
              className="col-sm-4 col-md-4 col-lg-4 col-xl-3"
              style={{ marginTop: "-15px" }}
            >
              {/* <a href="#" className="permissions-back">
                <Link
                  //onClick={() => localStorage.setItem("firstload", "true")}
                  to="/dashboard"
                >
                   <i className="la la-home" size="md" /> &nbsp;
                  <ArrowBack />
                  &nbsp; Back to HRMS
                </Link>
              </a> */}
              <div className="roles-header">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "70px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Roles
                  </div>
                  {rolePermissions?.Admin === "Edit/Create/Approve" && (
                    <div>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#add_role"
                        style={{
                          background: "#007BFF",
                          color: "#ffffff",
                          padding: "4px 13px",
                          borderRadius: "8px",
                          border: "none",
                        }}
                      >
                        <i className="fa fa-plus" /> Add
                      </button>{" "}
                    </div>
                  )}
                </div>
                <div className="roles-menu">
                  <ul>
                    {roleList.map((ele, index) => {
                      return (
                        <li
                          className={roleID === ele.id ? "active" : ""}
                          key={index}
                          onClick={() => (
                            setRoleID(ele.id),
                            setEdit(false),
                            setPermissionView(false)
                          )}
                        >
                          <a href="#">
                            {ele.name}
                            <span className="role-action">
                              {rolePermissions?.Admin ===
                                "Edit/Create/Approve" &&
                                ele.name !== "Superadmin" && (
                                  <>
                                    <span
                                      className="action-circle large"
                                      data-bs-toggle="modal"
                                      data-bs-target="#edit_role"
                                      onClick={() => setUpdateRole(ele)}
                                    >
                                      <i className="material-icons">edit</i>
                                    </span>
                                    <span
                                      className="action-circle large delete-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#delete_role"
                                      onClick={() => setUpdateRole(ele)}
                                    >
                                      <i className="material-icons">delete</i>
                                    </span>
                                  </>
                                )}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-sm-8 col-md-8 col-lg-8 col-xl-9"
              style={{
                background: "#f6f8fc",
                borderRadius: "8px",
                // marginTop: "37px",
                padding: "20px",
                height: "600px",
                overflowY: "scroll",
              }}
            >
              <h6 className="card-title m-b-10">
                {" "}
                <img height={"30px"} src={setting} />
                &nbsp;&nbsp;
                {roleList?.filter((ele) => roleID == ele.id)[0]?.name}
              </h6>
              <p>{roleList?.filter((ele) => roleID == ele.id)[0]?.desc}</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <div className="duplicate-project1">
                  <button
                    className={!permissionView ? "old-btn" : "old1-btn"}
                    onClick={() => {
                      setPermissionView(false);
                    }}
                  >
                    Permission
                  </button>
                  <button
                    className={permissionView ? "old-btn" : "old1-btn"}
                    onClick={() => {
                      setPermissionView(true);
                    }}
                  >
                    Members
                  </button>
                </div>
              </div>
              <br />
              {!permissionView ? (
                <>
                  {/* <div
                  className="m-b-20"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                    border: "1px solid #C4C4C4",
                    borderRadius: "10px",
                    gap: "50px",
                  }}
                >
                  <div>
                    <img src={shield} />
                  </div>
                  <div>
                    <h5>Users with this role can access the information of</h5>
                    <div className="form-group">
                      <div style={{ marginTop: "14px" }}>
                        <div>
                          <Radio
                            value="fs"
                            checked={dataAccess === "fs"}
                            onChange={(e) => setDataAccess(e.target.value)}
                            sx={{ m: 0, p: 0.5 }}
                          />
                          <label>All Employees (Active/Inactive)</label>
                          &nbsp;&nbsp;
                          <Radio
                            value="sn"
                            checked={dataAccess === "sn"}
                            onChange={(e) => setDataAccess(e.target.value)}
                            sx={{ m: 0, p: 0.5 }}
                          />
                          <label>All Employees (Active)</label>
                        </div>
                        <div>
                          <Radio
                            value="fs"
                            checked={dataAccess === "fs"}
                            onChange={(e) => setDataAccess(e.target.value)}
                            sx={{ m: 0, p: 0.5 }}
                          />
                          <label>Direct & Indirect Reports (Departments)</label>
                          &nbsp;&nbsp;
                          <Radio
                            value="sn"
                            checked={dataAccess === "sn"}
                            onChange={(e) => setDataAccess(e.target.value)}
                            sx={{ m: 0, p: 0.5 }}
                          />
                          <label>Direct Reports</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div
                    className="m-b-30"
                    style={{
                      padding: "20px",
                      border: "1px solid #C4C4C4",
                      borderRadius: "10px",
                    }}
                  >
                    <h4>Permissions</h4>
                    {rolePermissions?.Admin === "Edit/Create/Approve" &&
                      roleList?.filter((ele) => roleID == ele.id)[0]?.name !==
                        "Superadmin" && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            style={{
                              background: "#007bff",
                              color: "white",
                              padding: "2px 18px",
                              borderRadius: "5px",
                              border: "none",
                              textAlign: "end",
                            }}
                            onClick={() => setEdit(!edit)}
                          >
                            Edit
                          </button>
                        </div>
                      )}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "10px 12px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "10px 10px 0px 0px",
                        background: "#ffffff",
                        color: "#6C6C6C",
                      }}
                    >
                      <div>Section</div>
                      <div className="status-toggle">Permission</div>
                    </div>
                    <br />
                    <div className="list-group notification-list">
                      {permissions.map((ele, index) => {
                        return (
                          <p
                            className="list-group-item"
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {ele.name}

                            {/* <Switch
                             
                              onChange={() => handleFormChange12(ele.id)}
                              checked={ele.is_active}
                              color="primary"
                            /> */}
                            <div style={{ width: "20%" }}>
                              {!edit && (
                                <p
                                  style={{
                                    border: "1px solid #393f5c",
                                    padding: "3px 10px",
                                    textAlign: "center",
                                    float: "right",
                                    borderRadius: "5px",
                                    width: "fit-content",
                                    background: "#f6f8fc",
                                  }}
                                >
                                  {
                                    ele?.optn?.filter(
                                      (item) => item.value === ele.selected
                                    )[0]?.label
                                  }
                                </p>
                              )}
                              {edit && (
                                <Select
                                  options={ele.optn}
                                  value={ele.optn.filter(
                                    (item) => item.value === ele.selected
                                  )}
                                  onChange={(e) =>
                                    handleFormChange12(ele.id, e, index)
                                  }
                                />
                              )}
                            </div>

                            {/* <input
                          type="checkbox"
                          // id="staff_module"
                          className="check"
                          onChange={() => handleFormChange12(ele.id)}
                          value={ele.is_active}
                        /> */}
                            {/* <label htmlFor="staff_module" className="checktoggle">
                          checkbox
                        </label> */}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  {edit && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: "30px",
                      }}
                    >
                      <button
                        style={{
                          background: "#007bff",
                          color: "white",
                          padding: "5px 15px",
                          borderRadius: "8px",
                          border: "none",
                        }}
                        onClick={() => updatePermission()}
                      >
                        save
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {rolePermissions?.Admin === "Edit/Create/Approve" && (
                    <div className="row col-sm-12">
                      <div className="col-sm-8">
                        <div className="form-group">
                          {/* <input type="text" className="form-control" /> */}
                          <Select
                            isMulti
                            options={managerList?.filter(
                              (item) =>
                                !roleList
                                  ?.filter((ele) => roleID == ele.id)[0]
                                  ?.members_details?.map((ele) => ele.admid)
                                  ?.includes(item.value)
                            )}
                            value={member}
                            onChange={(e) => {
                              if (e) {
                                setMember(e.map((item) => item));
                              } else {
                                setMember([]);
                              }
                            }}
                          />
                        </div>
                      </div>
                      {member?.length > 0 && (
                        <div className="col-sm-4">
                          <button
                            style={{
                              background: "#009ce7",
                              color: "#ffffff",
                              padding: "5px 15px",
                              border: "none",
                              borderRadius: "8px",
                            }}
                            onClick={() => handleAddMember()}
                          >
                            Add
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <label className="form-label required">
                    <b>Members</b>
                  </label>
                  {roleList
                    ?.filter((ele) => roleID == ele.id)[0]
                    ?.members_details?.map((ele, index) => {
                      return (
                        <>
                          <p>
                            <span>
                              {index + 1}&nbsp;&nbsp;{ele.name}
                            </span>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {ele.email}&nbsp;&nbsp;&nbsp;&nbsp;
                            {rolePermissions?.Admin ===
                              "Edit/Create/Approve" && (
                              <span
                                style={{
                                  border: "1px solid #007bff",
                                  fontSize: "10px",
                                  borderRadius: "8px",
                                  padding: "2px 5px",
                                  color: "#ffffff",
                                  background: "#007bff",
                                  cursor: "pointer",
                                }}
                                onClick={(e) =>
                                  handleRemoveMember(e, ele.admid)
                                }
                              >
                                Remove
                              </span>
                            )}
                          </p>
                        </>
                      );
                    })}
                </>
              )}

              {/* <div className="table-responsive">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <th className="text-center">Read</th>
                    <th className="text-center">Write</th>
                    <th className="text-center">Create</th>
                    <th className="text-center">Delete</th>
                    <th className="text-center">Import</th>
                    <th className="text-center">Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Employee</td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                  </tr>
                  <tr>
                    <td>Holidays</td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                  </tr>
                  <tr>
                    <td>Leaves</td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                  </tr>
                  <tr>
                    <td>Events</td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                    <td className="text-center">
                      <input type="checkbox" defaultChecked />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
      {/* Add Role Modal */}
      <div id="add_role" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Role</h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Role Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={addRol.name}
                    onChange={(e) =>
                      setAddRol({ ...addRol, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    Role description
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={addRol.desc}
                    onChange={(e) =>
                      setAddRol({ ...addRol, desc: e.target.value })
                    }
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    onClick={(e) => addRole(e)}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role Modal */}
      {/* Edit Role Modal */}
      <div id="edit_role" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-md">
            <div className="modal-header">
              <h5 className="modal-title">Edit Role</h5>

              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Role Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    defaultValue="Team Leader"
                    type="text"
                    value={updateRole.name}
                    onChange={(e) =>
                      setUpdateRole({ ...updateRole, name: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label>
                    Role description
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={updateRole.desc}
                    onChange={(e) =>
                      setUpdateRole({ ...updateRole, desc: e.target.value })
                    }
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    onClick={() => updateRol()}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Role Modal */}
      {/* Delete Role Modal */}
      <div className="modal custom-modal fade" id="delete_role" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-header">
                <h3>Delete Role</h3>
                <p>Are you sure want to delete?</p>
              </div>
              <div className="modal-btn delete-action">
                <div className="row">
                  <div className="col-6">
                    <a
                      href=""
                      data-bs-dismiss="modal"
                      className="btn-primary continue-btn"
                      onClick={(e) => handleRemoveRole(e)}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      href=""
                      data-bs-dismiss="modal"
                      className="btn-primary cancel-btn"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};
export default RolePermission;
