/******************************************************** 
Project Name:- OZ HRMS
Module Name:- Face Recognition 
Objective of Code:- Registration for face recognition
Name of File: - FaceRecognition.jsx
Head files if any:- Admin/Registration
Programmer Name: - Vaibhav V. Maske
Date of creation: - 10/01/2024
Last Push on PROD:- Not pushed to prod yet.

changes made:-
**Name of Techies: Vaibhav V. Maske
**Date of Change: 12/01/2024
**Purpose of code change:- Api correction for upload file due to change in toggle of the api's

/******************************************************* */

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Avatar_02 from "../../../assets/img/profiles/smile-icon.svg";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Webcam from "react-webcam";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Delete from "../../../modelbox/Delete";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
import CameraIcon from "../../../assets/img/profiles/cameraIcon.svg";
import captureImage from "../../../assets/img/profiles/capture.svg";
//import Select from "react-select";
import { read, utils } from "xlsx";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import moment from "moment";
import { FiUpload } from "react-icons/fi";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import {
  Typography,
  Grid,
  Stack,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  useMediaQuery,
  Toolbar,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "500px",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  width: "50%",
  minWidth: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};
const styleOne = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "600px",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  width: "47%",
  minWidth: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "13px",
  p: 2,
  // overflow: "auto",
};

const FaceRecognitionAdmin = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewLeaveDetails, setViewLeaveDetails] = useState({});
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const hiddenFileInput = useRef(null);
  const token = sessionStorage.getItem("access_token");
  const role = JSON.parse(sessionStorage.getItem("role"));
  const [data, setData] = useState([]);
  const [name1, setName1] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [from1, setFrom1] = useState("");
  const [to1, setTo1] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [declineReason, setDeclineReason] = useState("");
  const [leaver, setLeaver] = useState([]);
  const [leavec, setLeavec] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openmodal, setOpenmodal] = useState(false);
  const [openmodal1, setOpenmodal1] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [id, setId] = useState(null);
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*****************************************************/
  const uploadFile = () => {
    // if(isSuccess){
    let formData = new FormData();
    formData.append("toggle", "b");
    formData.append("pic", selectedFile);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, formData)
      .then((res) => {
        setIsSuccess(true);
        setOpenmodal(false);
        Swal.fire({
          title: "Your file uploaded successfullty",
          text: "You clicked the button!",
          icon: "success",
        });
      })
      .catch((err) => console.log(err));
    // }
  };
  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  console.log(selectedFile);

  const validateSelectedFile = () => {
    const MIN_FILE_SIZE = 1024; // 1MB
    const MAX_FILE_SIZE = 5120; // 5MB
    const fileSizeKiloBytes = selectedFile.size / 1024;

    if (!selectedFile) {
      setErrorMsg("Please choose a file");
      setIsSuccess(false);
      return;
    }

    if (selectedFile.type !== "application/x-zip-compressed") {
      setErrorMsg("Please upload zip file.");
      setIsSuccess(false);
      return;
    }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit");
      setIsSuccess(false);
      return;
    }

    uploadFile();

    setErrorMsg("");
    setIsSuccess(true);
  };

  /**********************************************************/
  const handleModalClose = () => {
    setOpenmodal(false);

    setErrorMsg("");
    setIsSuccess(false);
    setSelectedFile({});
  };
  const handleModalOpen = () => {
    setOpenmodal(true);
    setErrorMsg("");
    setIsSuccess(false);
  };
  const handleModalClose1 = () => {
    setOpenmodal1(false);
  };

  const handleModalOpen1 = () => {
    setOpenmodal1(true);
  };
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    const urlObj = dataURLtoFile(imageSrc, "captured_image.png");
    setImgUrl(urlObj);
  }, [webcamRef]);

  const dataURLtoFile = (dataURL, fileName) => {
    const base64String = dataURL.split(",")[1];
    const binaryString = window.atob(base64String);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: "image/png" });
    return new File([blob], fileName, { type: "image/png" });
  };

  const retake = () => {
    setImgSrc(null);
  };
  const getEmployeePic = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedRowKeys([]);
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            key: ele.empid,
          }))
        );
        setSearchData(
          res.data.map((ele, index) => ({
            ...ele,
            key: ele.empid,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEmployeePic();
  }, []);

  const handleSearch = () => {
    const newData = data
      .filter((x) => x.name == (name1 == "" ? x.name : name1))
      .filter(
        (y) =>
          y.designation == (designation == "" ? y.designation : designation)
      )
      .filter(
        (z) => z.department == (department == "" ? z.department : department)
      );
    setSearchData(newData);
  };

  useEffect(() => {
    handleSearch();
  }, [name1, designation, department]);

  const handleInputChange = (e, id) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("pic", e.target.files[0]);
    formData.append("toggle", "a");
    formData.append("empid", id);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Photo uploaded Successfully",
          // timer: 2000,
        });

        getEmployeePic();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else if (err?.response?.status === 413) {
          Swal.fire({
            icon: "error",
            text: `Please upload file below 5 MB.`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleImageCapture = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("pic", imgUrl);
    formData.append("toggle", "a");
    formData.append("empid", id);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Photo uploaded Successfully",
          // timer: 2000,
        });
        setImgSrc(null);
        setId(null);
        setImgUrl(null);
        handleModalClose1();
        getEmployeePic();
      })
      .catch((err) => {
        handleModalClose1();
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else if (err?.response?.status === 413) {
          Swal.fire({
            icon: "error",
            text: `Please upload file below 5 MB.`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const HandleBulkPicUpload = () => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/emp_pics/`,
        {
          toggle: "s",
          empid: selectedRowKeys,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Photos uploaded Successfully",
          // timer: 2000,
        });
        getEmployeePic();
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "empid",
      key: "key",
    },
    {
      title: "Name of Employee",
      dataIndex: "name",
      key: "key",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "key",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
    },

    {
      title: "Department",
      dataIndex: "department",
      key: "key",
      sorter: (a, b) => a.department.localeCompare(b.department),
    },

    {
      title: "Upload from System",
      align: "center",
      render: (text, record) => (
        <div className="file-input-container">
          <input
            type="file"
            accept="image/*"
            capture="environment"
            id="fileInput"
            className="custom-file-input"
            onChange={(e) => handleInputChange(e, text.empid)}
          />
        </div>
      ),
    },
    {
      title: "Capture with Camera",
      align: "center",
      render: (text, record) => (
        <div className="file-input-container">
          <button
            onClick={() => (setId(text.empid), handleModalOpen1())}
            style={{
              backgroundColor: "transparent",

              border: "none",
            }}
          >
            <img src={CameraIcon} style={{ cursor: "pointer" }} />
          </button>
        </div>
      ),
    },
    {
      title: "Preview",
      align: "center",
      render: (text, record) => (
        <>
          {text.temp_pic === "" ? (
            "NA"
          ) : (
            <div className="avatar">
              <img alt="" src={record.temp_pic} />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Uploaded Photo",
      align: "center",
      render: (text, record) => (
        <>
          {text.pic === "" ? (
            "NA"
          ) : (
            <div className="avatar">
              <img alt="" src={record.pic} />
            </div>
          )}
        </>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleClickOne = () => {
    hiddenFileInput.current.click();
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div
                className="col"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="page-title">Face Recognition</h3>
                <button
                  onClick={() => handleModalOpen()}
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#3298DB",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  Bulk Upload
                </button>
              </div>
            </div>
          </div>
          {!showTable && (
            <>
              {/* Search Filter */}
              <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      inputId="single-select-example"
                      className="single-select"
                      classNamePrefix="react-select"
                      isClearable
                      placeholder="Employee Name"
                      options={[...new Set(data?.map((item) => item.name))].map(
                        (ele) => ({
                          label: ele,
                          value: ele,
                        })
                      )}
                      value={
                        name1 !== ""
                          ? {
                              label: name1,
                              value: name1,
                            }
                          : null
                      }
                      onChange={(e) => setName1(e ? e.value : "")}
                      //className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Designation"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.designation)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        designation !== ""
                          ? {
                              label: designation,
                              value: designation,
                            }
                          : null
                      }
                      //isClearable={true}
                      isSearchable
                      onChange={(e) => {
                        setDesignation(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        zindex: "5",
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group form-focus select-focus">
                    <Select
                      placeholder="Department"
                      isClearable
                      options={[
                        ...new Set(data?.map((item) => item.department)),
                      ].map((ele) => ({
                        label: ele,
                        value: ele,
                      }))}
                      value={
                        department !== ""
                          ? {
                              label: department,
                              value: department,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setDepartment(e ? e.value : "");
                      }}
                      className="select floating"
                      style={{
                        height: "50px",
                        width: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: "3px",
                      }}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <a
                    href="#"
                    className="btn-block"
                    style={{
                      //fontSize: "14px",
                      height: "38px",
                      background: "#3298DB",
                      //color:"white"
                    }}
                    onClick={() => (
                      setName1(""), setDesignation(""), setDepartment("")
                    )}
                  >
                    <img
                      tabindex="0"
                      data-bs-toggle="tooltip"
                      title="clear all filter"
                      style={{
                        height: "38px",
                        backgroundColor: "#3298DB",
                        padding: "7px 5px",
                        borderRadius: "5px",
                      }}
                      src={FilterOff}
                    />
                  </a>
                </div>
              </div>
              {/* /Search Filter */}
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          pagination={{
                            total: searchData.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          rowSelection={rowSelection}
                          columns={columns}
                          bordered
                          dataSource={searchData}
                          // rowKey={(record) => record.id}
                          loading={isLoading}
                        />
                      </div>
                    </div>
                    {selectedRowKeys.length > 0 && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          style={{
                            border: "none",
                            background: "#3298DB",
                            color: "#ffffff",
                            padding: "10px 20px",
                            borderRadius: "7px",
                            margin: "25px",
                          }}
                          onClick={(e) => HandleBulkPicUpload(e)}
                        >
                          Photos Upload
                        </button>{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div
          className="modal custom-modal fade"
          role="dialog"
          id="exampleModal"
          // tabindex="-1"
          // aria-labelledby="exampleModalLabel"
          // aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label for="message-text" class="col-form-label">
                      <b>Reason:</b>
                    </label>
                    <textarea
                      class="form-control"
                      id="message-text"
                      value={declineReason}
                      // onChange={(e) => setDeclineReason(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="submit-btn2"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  // onClick={(e) => handleApproveLeave(e, true)}
                  data-bs-dismiss="modal"
                  className="submit-btn2"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openmodal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button>

          <div className="App-container" style={{}}>
            <div className="card">
              <div className="card-header">
                <h4 className="title">
                  Bulk Attendance Recognition Picture Upload
                </h4>
              </div>

              <div className="card-body">
                {/* <p className="label">Choose File</p> */}
                <input
                  type="file"
                  name="file"
                  accept=".zip,.rar,.7zip"
                  onChange={handleFileChange}
                />
                <br />
                <br />
                {/* <br/> */}
                <div className="space-between">
                  {/* <p className="info-message">Min size: 1MB</p> */}
                  <p className="info-message">
                    {" "}
                    Note: Please upload file less than 5 MB and it should be in
                    zip format
                  </p>
                </div>
                <br />
                {isSuccess ? (
                  <p className="success-message" style={{ color: "green" }}>
                    Validation successful
                  </p>
                ) : null}
                <p className="error-message" style={{ color: "red" }}>
                  {errorMsg}
                </p>
                <br />
                <br />
                <button
                  disabled={selectedFile ? false : true}
                  style={{
                    padding: "5px 15px",
                    border: "none",
                    backgroundColor: "#3298DB",
                    color: "white",
                    borderRadius: "5px",
                  }}
                  onClick={validateSelectedFile}
                >
                  Upload file
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openmodal1}
        onClose={handleModalClose1}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box sx={styleOne}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose1}
          >
            <img src={closeIcon} />
          </button>
          <div style={{ borderRadius: "12px" }}>
            <div>
              <div>
                <h4 className="title">Image Capture</h4>
              </div>
              <div className="card-body">
                {imgSrc ? (
                  <img src={imgSrc} alt="webcam" />
                ) : (
                  <Webcam
                    audio={false}
                    height={400}
                    width={600}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    // screenshotQuality={0.96}
                  />
                )}
                <div className="btn-container">
                  {imgSrc ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        style={{
                          background: "#3298DB",
                          border: "none",
                          color: "#ffffff",
                          margin: "30px 50px",
                          padding: "5px 15px",
                          borderRadius: "8px",
                        }}
                        onClick={() => retake()}
                      >
                        Retake
                      </button>
                      <button
                        style={{
                          background: "#3298DB",
                          border: "none",
                          color: "#ffffff",
                          margin: "30px 50px",
                          padding: "5px 15px",
                          borderRadius: "8px",
                        }}
                        onClick={(e) => handleImageCapture(e)}
                      >
                        upload
                      </button>
                    </div>
                  ) : (
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        marginTop: "20px",
                      }}
                      onClick={() => capture()}
                    >
                      <img src={captureImage} />
                    </button>
                  )}
                </div>

                {/* <button
                  disabled={selectedFile ? false : true}
                  style={{
                    padding: "5px 15px",
                    border: "none",
                    backgroundColor: "#3b5092",
                    color: "white",
                  }}
                  onClick={validateSelectedFile}
                >
                  Upload file
                </button> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FaceRecognitionAdmin;
