import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calendar.css";
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Modalbox from "../../modelbox/modalbox";
import axios from "axios";
import Popup from "reactjs-popup";
import Tooltip from "@atlaskit/tooltip";

const Calendar = (props) => {
  const [menu, setMenu] = useState(false);
  const [modalOne, setModalOne] = useState(false);
  // console.log($("#calendar").fullCalendar("getDate"));
  // console.log(document.getElementsByClassName("fc-toolbar-title")[0]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  function renderEventContent(info) {
    info.el.setAttribute("title", info.event.title);
  }

  const [startDate, setDate] = useState(new Date()),
    [showCategory, setshowCategory] = useState(false),
    [showmodel, setshowmodel] = useState(true),
    [showEvents, setshowEvents] = useState(false),
    [show, setshow] = useState(false),
    [iseditdelete, setiseditdelete] = useState(false),
    [addneweventobj, setaddneweventobj] = useState(null),
    [isnewevent, setisnewevent] = useState(false),
    [event_title, setevent_title] = useState(""),
    [category_color, setcategory_color] = useState(""),
    [calenderevent, setcalenderevent] = useState(""),
    [weekendsVisible, setweekendsVisible] = useState(true),
    [currentEvents, setscurrentEvents] = useState([]),
    [attendanceData, setAttendanceData] = useState([]);
  const token = sessionStorage.getItem("access_token");
  const defaultEvents = [
    {
      title: " 4",
      start: Date.now() + 148000000,
      className: "bg-purple",
    },
    {
      title: "Event Name 5",
      start: Date.now() + 148000000,
      className: "bg-success",
    },
    {
      title: "Test Event 1",
      start: Date.now(),
      end: Date.now(),
      className: "bg-success",
    },
    {
      title: "Test Event 2",
      start: Date.now() + 168000000,
      className: "bg-info",
    },
    {
      title: "Test Event 3",
      start: Date.now() + 338000000,
      className: "bg-primary",
    },
  ];

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://oodr5t6r3c.execute-api.ap-south-1.amazonaws.com/dev/calendar_list/",
  //       {
  //         params: {
  //           // empid: sessionStorage.getItem("emp_id"),
  //           // table_toggle: "y",
  //           year: 2022,
  //           month: 12,
  //         },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res.data);
  //       setAttendanceData(
  //         res.data.map((ele) => ({
  //           ...ele,
  //           title: ele.name === "Holiday" ? "Holdiday" : "",
  //           color:
  //             ele.name === "Holiday"
  //               ? "#5EAD56"
  //               : ele.name === "Leave"
  //               ? "#ED9D44"
  //               : ele.name === "Clockout"
  //               ? "#0C41FF"
  //               : ele.name === "Clockin"
  //               ? "#29CD00"
  //               : ele.name === "Home"
  //               ? "#FFD3A3"
  //               : ele.name === "Sunday"
  //               ? "#FF7676"
  //               : ele.name === "Outdoor"
  //               ? "#E0AFFF"
  //               : ele.name === "Absent"
  //               ? "#BD0000"
  //               : "white",
  //           display:
  //             ele.name === "Holiday"
  //               ? "background"
  //               : ele.name === "Leave"
  //               ? "listItem"
  //               : ele.name === "Clockout"
  //               ? "listItem"
  //               : ele.name === "Clockin"
  //               ? "listItem"
  //               : ele.name === "Absent"
  //               ? "listItem"
  //               : ele.name === "Home"
  //               ? "background"
  //               : ele.name === "Outdoor"
  //               ? "background"
  //               : "background",
  //           className:
  //             ele.name === "Holiday"
  //               ? "block"
  //               : ele.name === "Leave"
  //               ? "dot"
  //               : ele.name === "Clockout"
  //               ? "dot"
  //               : ele.name === "Clockin"
  //               ? "dot"
  //               : ele.name === "Absent"
  //               ? "dot"
  //               : ele.name === "Home"
  //               ? "block"
  //               : ele.name === "Outdoor"
  //               ? "block"
  //               : "block",
  //         }))
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // console.log(attendanceData);
  const handleChange = (date) => {
    setDate(date);
  };
  const addEvent = () => {
    setshowEvents(true);
  };
  const categoryHandler = () => {
    setshowCategory(true);
  };

  const handleClose = () => {
    setisnewevent(false);
    setiseditdelete(false);
    setshow(false);
    setshowCategory(false);
    setshowEvents(false);
    setshowmodel(false);
  };
  const handleEventClick = (clickInfo) => {
    // console.log(clickInfo);
    setiseditdelete(false);
    setevent_title(clickInfo.event.title);
    setcalenderevent(clickInfo.event);
  };

  const handleDateSelect = (selectInfo) => {
    setisnewevent(true);
    setaddneweventobj(selectInfo);
  };
  const addnewevent = () => {
    let calendarApi = addneweventobj.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (event_title) {
      calendarApi.addEvent({
        id: 10,
        title: event_title,
        className: category_color,
        start: addneweventobj.startStr,
        end: addneweventobj.endStr,
        allDay: addneweventobj.allDay,
      });
    }
    setisnewevent(false);
  };

  const onupdateModalClose = () => {
    setiseditdelete(false);
    setevent_title("");
  };
  const oncreateeventModalClose = () => {
    setevent_title("");
    setisnewevent(false);
  };
  const removeevent = () => {
    calenderevent.remove();
    setiseditdelete(false);
  };
  const clickupdateevent = () => {
    const newArray = defaultEvents;
    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].id === parseInt(calenderevent.id)) {
        newArray[i].title = event_title;
      }
    }
    defaultEvents = newArray;
    setiseditdelete(false);
  };

  const handleClick = () => {
    setshow(true);
  };

  const callbackFunction = (info) => {
    // console.log(info);
  };
  const handleDateClick = (arg) => {
    // bind with an arrow function
    console.log(arg);
    //openPopupForm(arg);
    //setModalOne(true);
  };

  async function getCalendarData(fetchInfo, successCallback) {
    try {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
       console.log(month)
      if (fetchInfo) {
        year = new Date(fetchInfo.start).getFullYear();
        
        month = new Date(fetchInfo.endStr).getMonth() + 1;
      }
      console.log(month)
      // console.log(year, month);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/calendar_list/`,
        {
          params: {
            // empid: sessionStorage.getItem("emp_id"),
            // table_toggle: "y",
            year: month === 13 ? year + 1 : year,
            month: month - 1 ,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      successCallback(
        response.data.map((ele) => {
          return {
            ...ele,
            // title: ele.name === "Holiday" ? "Holiday" : "",
            title:
              sessionStorage.getItem("coid") === "Ram 067802"
                ? ele.title
                : ele.name === "Holiday"
                ? "Holiday"
                : "",
            color:
              ele.name === "Holiday"
                ? "#5EAD56"
                : ele.name === "Leave"
                ? "#ED9D44"
                : ele.name === "Clockout"
                ? "#0C41FF"
                : ele.name === "Clockin"
                ? "#29CD00"
                : ele.name === "Home"
                ? "#FFD3A3"
                : ele.name === "Sunday"
                ? "#FF7676"
                : ele.name === "Off"
                ? "#FF7676"
                : ele.name === "Outdoor"
                ? "#E0AFFF"
                : ele.name === "Absent"
                ? "#BD0000"
                : "white",
            display:
              ele.name === "Holiday"
                ? "background"
                : ele.name === "Leave"
                ? "listItem"
                : ele.name === "Clockout"
                ? "listItem"
                : ele.name === "Clockin"
                ? "listItem"
                : ele.name === "Absent"
                ? "listItem"
                : ele.name === "Home"
                ? "background"
                : ele.name === "Off"
                ? "background"
                : ele.name === "Outdoor"
                ? "background"
                : "background",
            className:
              ele.name === "Holiday"
                ? "block"
                : ele.name === "Leave"
                ? "dot"
                : ele.name === "Clockout"
                ? "dot"
                : ele.name === "Clockin"
                ? "dot"
                : ele.name === "Absent"
                ? "dot"
                : ele.name === "Off"
                ? "block"
                : ele.name === "Home"
                ? "block"
                : ele.name === "Outdoor"
                ? "block"
                : "block",
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  }
  var calendarEl = document.getElementById("calendar");

  return (
    <div style={{ fontFamily: "Roboto" }}>
      {/* <Header onMenuClick={(value) => toggleMobileMenu()} />
            <Sidebar />  */}

      {/* <div className="page-wrapper"> */}
      {/* <div className="content container-fluid"> */}
      {/* Page Header */}
      {/* <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Calendar</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/index">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Calendar</li>
                </ul>
              </div>
              <div className="col-auto text-end float-end ml-auto">
                <a
                  href="#"
                  className="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_event"
                >
                  <i className="fa fa-plus" /> Add Event
                </a>
              </div>
            </div>
          </div> */}
      {/*  /Page Header */}

      <div
        className="card-body"
        style={{ borderRadius: "8px", background: "white" }}
      >
        {/* <div style={{ position: "absolute", marginTop: "15px" }}>
                <div> work from home</div>
                <div> work from home</div>
              </div> */}

        <FullCalendar
          // allDaySlot={false}
          // viewSkeletonRender={(info) => {
          //   callbackFunction(info);
          // }}
          eventDidMount={renderEventContent}
          // dateClick={(e) => handleDateClick(e)}
          popup
          selectable
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev",
            center: "title",
            right: "next",
          }}
          events={(fetchInfo, successCallback, failureCallback) =>
            getCalendarData(fetchInfo, successCallback, failureCallback)
          }
          // dateClick={(info) => {
          //   // Optional: Handle date click if needed
          // }}

          // eventClick={(e) => openEvent(e)}
          forceEventDuration={true}
          eventTimeFormat={{
            hour: "numeric",
            minute: "numeric",
          }}
          titleFormat={{
            // day: "2-digit",
            month: "long",
            year: "numeric",
          }}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
          }}
          columnHeaderFormat={{
            weekday: "short",
            day: "numeric",
            omitCommas: true,
          }}
          allDaySlot={false}
          //eventClick={(e) => openEvent(e)}
          // forceEventDuration={true}
          // eventTimeFormat={{
          //   hour: "numeric",
          //   minute: "numeric",
          // }}
          // titleFormat={{
          //   day: "2-digit",
          //   month: "long",
          // }}
          // slotLabelFormat={{
          //   hour: "2-digit",
          //   minute: "2-digit",
          // }}
          // columnHeaderFormat={{
          //   weekday: "short",
          //   day: "numeric",
          //   omitCommas: true,
          // }}
          // datesSet={(dateInfo) => {
          //   console.log(dateInfo.start); //start of the range the calendar date
          //   console.log(dateInfo.end); //end of the range the calendar date
          // }}

          initialView="dayGridMonth"
          // eventLimit= {false}
          // editable={true}
          // selectable={true}
          // selectMirror={true}
          // dayMaxEvents={true}
          weekends={weekendsVisible}
          // initialEvents={defaultEvents} // alternatively, use the `events` setting to fetch from a feed
          // events={[
          //   {
          //     title: "event 1",
          //     date: "2022-11-11",
          //     display: "list-item",
          //     color: "black",
          //     start: "2022-11-11",
          //     end: "2022-11-11",
          //     display: "listItem",
          //     color: "#5EAD56",
          //     className: "dot",
          //     height: "20px !impo",
          //     width: "20px",
          //   },
          //   {
          //     title: " 4",
          //     start: Date.now() + 148000000,
          //     color: "green",
          //     display: "listItem",
          //     className: "dot",
          //   },
          //   {
          //     title: "Event Name 5",
          //     start: Date.now() + 148000000,
          //     className: "dot",
          //     display: "listItem",
          //     color: "blue",
          //   },
          //   {
          //     title: "Test Event 1",
          //     date: "2022-11-15",
          //     display: "background",
          //     color: "#5EAD56",
          //     className: "block",
          //     zIndex: "100",
          //     className: "dot",
          //   },

          //   {
          //     title: "event 5",
          //     date: "2022-11-11",
          //     display: "listItem  ",
          //     className: "dot",
          //     color: "#ED9D44",
          //   },
          //   {
          //     title: "event 2",
          //     date: "2022-11-14",
          //     display: "listItem",
          //     color: "green",
          //     className: "dot",
          //   },
          // ]}
          // events={attendanceData}
          // events={(fetchInfo, successCallback, failureCallback) =>
          //   getCalendarData(fetchInfo, successCallback, failureCallback)
          // }
          dateClick={(e) => console.log(e)}
          select={handleDateSelect}
          // eventContent={renderEventContent} // custom render function
          // eventClick={(clickInfo) => handleEventClick(clickInfo)}
          // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
          /* you can update a remote database when these fire:
                                    eventAdd={function(){}}
                                    eventChange={function(){}}
                                    eventRemove={function(){}}
                                    */
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "15px",
            fontSize: "12px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="dot-show"
                style={{ background: "#29CD00" }}
              ></div>{" "}
              &nbsp;
              <div>Clock In</div>
            </div>
            &emsp;&emsp;&emsp;
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="dot-show"
                style={{ background: "#0C41FF" }}
              ></div>{" "}
              &nbsp;
              <div>Clock Out</div>
            </div>
            &emsp;&emsp;&emsp;
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="dot-show"
                style={{ background: "#ED9D44" }}
              ></div>{" "}
              &nbsp;
              <div>On Leave</div>
            </div>
            &emsp;&emsp;&emsp;
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="dot-show"
                style={{ background: "#BD0000" }}
              ></div>{" "}
              &nbsp;
              <div>Absent</div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="rectangle"
                style={{ background: "#FFD3A3" }}
              ></div>{" "}
              &nbsp;
              <div>Work from home</div>
            </div>
            &emsp;&emsp;&emsp;
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="rectangle"
                style={{ background: "#E0AFFF" }}
              ></div>{" "}
              &nbsp;
              <div>Outdoor</div>
            </div>
            &emsp;&emsp;&emsp;
            <div style={{ display: "flex" }}>
              {" "}
              <div
                className="rectangle"
                style={{ background: "#5EAD56" }}
              ></div>{" "}
              &nbsp;
              <div>Holiday</div>
            </div>
          </div>
        </div>
        {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "15px",
                  fontSize: "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  {" "}
                  <div
                    className="rectangle"
                    style={{ background: "#FFD3A3" }}
                  ></div>{" "}
                  &nbsp;
                  <div>Work from home</div>
                </div>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <div style={{ display: "flex" }}>
                  {" "}
                  <div
                    className="rectangle"
                    style={{ background: "#E0AFFF" }}
                  ></div>{" "}
                  &nbsp;
                  <div>Outdoor</div>
                </div>
              </div> */}
      </div>

      {/*  Add Event modal */}
      <div id="add_event" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Event</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>
                    Event Name <span className="text-danger">*</span>
                  </label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label>
                    Event Date <span className="text-danger">*</span>
                  </label>
                  <div>
                    <input className="form-control" type="date" />
                  </div>
                </div>
                <div className="form-group mb-0">
                  <label>Choose Category Color</label>
                  <select
                    className="form-control form-white"
                    data-placeholder="Choose a color..."
                    name="category-color"
                  >
                    <option value="success">Success</option>
                    <option value="danger">Danger</option>
                    <option value="info">Info</option>
                    <option value="primary">Primary</option>
                    <option value="warning">Warning</option>
                    <option value="inverse">Inverse</option>
                  </select>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*  /Add Event modal */}

      {/*  Create Event modal */}
      <div className="modal custom-modal fade none-border" id="my_event">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Event</h4>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body"></div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-success save-event submit-btn"
              >
                Create event
              </button>
              <button
                type="button"
                className="btn btn-danger delete-event submit-btn"
                data-bs-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  /Create Event modal */}

      <Modalbox show={showmodel} handleClose={handleClose} />
    </div>
    // </div>
    // </div>
  );
};
export default Calendar;
export function createEvent(values) {
  let events = JSON.parse(localStorage.getItem("events"));
  events.push(values); //Push New Item
  localStorage.setItem("events", JSON.stringify(events)); //Update Storage
}
