import React, { Component, useState } from "react";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import "./Documents.css";
import { RotatingLines } from "react-loader-spinner";
import eye from "../../assets/img/profiles/Group813.png";
import delet from "../../assets/img/profiles/Group973.svg";
import pdflogo from "../../assets/img/profiles/Adobe-PDF-File-Icon-01.svg";
import smiley from "../../assets/img/profiles/OZHRMS.svg";
import smily from "../../assets/img/smiley-icon.svg";
import { ReactDialogBox } from "react-js-dialog-box";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import aadhar from "../../assets/img/profiles/Aadhaar_Logo.svg.png";
import Pan from "../../assets/img/profiles/pan-card.jpg";
import Photo from "../../assets/img/profiles/photo.svg";
import cv from "../../assets/img/profiles/cva.png";
import offerl from "../../assets/img/profiles/offerl.png";
import EduEx from "../../assets/img/profiles/eduEx.png";
import sign from "../../assets/img/profiles/sign.png";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Table } from "antd";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import "antd/dist/antd.css";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../antdstyle.css";
import { useEffect } from "react";
import axios from "axios";
import Documentsview from "../../modelbox/Documentsview";
import Swal from "sweetalert2";
import CancelIcon from "@mui/icons-material/Cancel";

const Document = () => {
  const [menu, setMenu] = useState(false);
  const [empDetails, setEmpDetails] = useState({});
  const [file, setFile] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const handleGetDocument = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/documents/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        setData(res.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetDocument();
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setEmpDetails(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleUploadDocument = (value, id, type) => {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/documents/`,
        {
          empid: sessionStorage.getItem("emp_id"),
          doc_id: id,
          content_type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        var formData = new FormData();
        formData.append("files", value);
        axios
          .put(res.data.url, formData)
          .then((response) => {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Document uploaded successfully",
              // footer: '<a href="">Why do I have this issue?</a>',
            });

            handleGetDocument();
          })
          .catch((err) => {
            if (err?.response?.status === 400) {
              Swal.fire({
                icon: "error",
                text: `${err?.response?.data?.error}!`,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong",
              });
            }
          });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const columns = [
    {
      title: "Document Name",
      dataIndex: "doc_name",
      sorter: (a, b) => a.doc_name.length - b.doc_name.length,
    },
    {
      title: "Select File (only pdf format)",
      dataIndex: "select_file",
      render: (text, record) => (
        <>
          {/* {" "}
          <button
            className="choose-file-btn"
            style={{ background: "#20A3AF" }}
            onClick={() => console.log(text)}
          >
            Choose File
          </button> */}
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) =>
              handleUploadDocument(
                e.target.files[0],
                text.doc_id,
                text.content_type
              )
            }
          />
        </>
      ),
    },

    {
      title: "Upload file",
      dataIndex: "upload_file",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          {text === "uploaded" ? (
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-success" /> Uploaded
            </a>
          ) : (
            <a className="dropdown-item">
              <i className="fa fa-dot-circle-o text-danger" /> Not Uploaded
            </a>
          )}
        </div>
      ),
      sorter: (a, b) => a.upload_file.localeCompare(b.upload_file),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) =>
        //   text === "verified" ? (
        //     <button
        //       className="choose-file-btn "
        //       style={{ background: "#5FAE57" }}
        //     >
        //       Verified
        //     </button>
        //   ) : (
        //     <button
        //       className="choose-file-btn "
        //       style={{ background: "#FFA500" }}
        //     >
        //       Under Review
        //     </button>
        //   ),

        text === "Approved" ? (
          <a className="dropdown-item" href="#">
            <i className="fa fa-dot-circle-o text-success" /> Verified
          </a>
        ) : text === "Pending" ? (
          <a className="dropdown-item" href="#">
            <i className="fa fa-dot-circle-o text-primary" /> Pending
          </a>
        ) : (
          <a className="dropdown-item">
            <i className="fa fa-dot-circle-o text-danger" /> Rejected
          </a>
        ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },

    {
      title: "Action",
      dataIndex: "select_file",
      render: (text, record) => (
        <img
          src={eye}
          style={{
            height: "12px",
            width: "18px",
            marginLeft: "40%",
            cursor: "pointer",
          }}
          onClick={() => {
            setViewPdf(true);
            setPdfFile(text.link[0]?.links);
          }}
          // onClick={() =>
          //   handleshowDocument(
          //     "https://www.africau.edu/images/default/sample.pdf"
          //   )
          // }
        />
      ),
    },
  ];

  const handleshowDocument = (value) => {
    setFile(value);
    // setIsOpen(!isOpen);
    // console.log(value);
  };
  // console.log(file);
  const closeBox = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Documents</h3>
              </div>
            </div>
          </div>

          <div className="card mb-0" style={{ borderRadius: "10px" }}>
            <div className="card-body-document" style={{ marginTop: "14px" }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <a href="#">
                          <img
                            alt=""
                            src={
                              empDetails.profile_pic
                                ? empDetails.profile_pic.links
                                : smily
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <div className="text-one">
                              {`ID : ${
                                empDetails.empl_id ? empDetails.empl_id : "-"
                              }`}
                            </div>

                            <h3
                              className="user-name m-t-0 mb-0"
                              style={{ textTransform: "capitalize" }}
                            >
                              {empDetails.name ? empDetails.name : "-"}
                            </h3>

                            <h6 style={{ marginTop: "10px" }}>
                              <b>
                                {empDetails.designation
                                  ? empDetails.designation.label
                                  : "-"}
                              </b>
                            </h6>

                            <h6 style={{ marginTop: "10px" }}>
                              <b>
                                {`Date of Joining :${
                                  empDetails.date_joined
                                    ? empDetails.date_joined
                                    : "-"
                                }`}
                              </b>
                            </h6>

                            <h6 style={{ marginTop: "10px" }}>
                              <b>
                                Office Name:{" "}
                                {empDetails.office_name
                                  ? empDetails.office_name
                                  : "-"}{" "}
                              </b>
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Phone:
                              </div>
                              <div className="">
                                {empDetails.phone
                                  ? "+91" + empDetails.phone
                                  : "-"}{" "}
                              </div>
                            </li>
                            <li className="document-list">
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Email:
                              </div>
                              <div className="">
                                {empDetails.email ? empDetails.email : "-"}
                              </div>
                            </li>
                            <li className="document-list">
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                DOB:
                              </div>
                              <div className="">
                                {empDetails.bday
                                  ? empDetails.bday
                                      ?.split("-")
                                      ?.reverse()
                                      ?.join("-")
                                  : "-"}
                              </div>
                            </li>
                            <li className="document-list">
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                {" "}
                                Address:
                              </div>
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                {empDetails.address ? empDetails.address : "-"}
                              </div>
                            </li>
                            <li className="document-list">
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Gender:
                              </div>
                              <div
                                className=""
                                style={{ textTransform: "capitalize" }}
                              >
                                {empDetails.gender ? empDetails.gender : "-"}
                              </div>
                            </li>
                            <li className="document-list">
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Reports to:
                              </div>
                              <div className="">
                                {empDetails.report_to
                                  ? empDetails.report_to.label
                                  : "-"}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />

          {viewPdf ? (
            <div
              style={{
                background: "#FFFFFF",
                padding: "16px",
                textAlign: "right",
                borderRadius: "10px",
              }}
            >
              <CancelIcon
                sx={{ color: "#2E86C1", cursor: "pointer" }}
                onClick={() => setViewPdf(false)}
              />
              <br />

              <div style={{ height: "100%" }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={
                      pdfFile ? pdfFile : "/assets/pdf-open-parameters.pdf"
                    }
                  />
                </Worker>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card mb-0" style={{ borderRadius: "10px" }}>
                    <div className="card-body">
                      <div className="row col-sm-12">
                        {!isLoading &&
                          data?.map((ele, index) => {
                            return (
                              <div
                                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  //src={Pan}
                                  src={
                                    ele.doc_name === "Aadhaar Card"
                                      ? aadhar
                                      : ele.doc_name === "PAN Card"
                                      ? Pan
                                      : ele.doc_name === "Offer Letter"
                                      ? offerl
                                      : ele.doc_name === "Education Certificate"
                                      ? EduEx
                                      : ele.doc_name ===
                                        ("Passport Size Photograph" ||
                                          "Profile pic")
                                      ? Photo
                                      : ele.doc_name ===
                                        "Experience Certificate"
                                      ? EduEx
                                      : ele.doc_name === "Resume"
                                      ? cv
                                      : ele.doc_name === "Signature"
                                      ? sign
                                      : pdflogo
                                  }
                                  style={{
                                    height: "60px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if (ele.upload_file === "uploaded") {
                                      setViewPdf(true);
                                      setPdfFile(
                                        ele.select_file.link[0]?.links
                                      );
                                    }
                                  }}
                                />
                                <p
                                  style={{
                                    marginBottom: "-2px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <b>{ele.doc_name}</b>
                                </p>
                                <div className="document-status">
                                  <div className="dropdown action-label text-center">
                                    {ele.upload_file === "uploaded" ? (
                                      <a className="dropdown-item" href="#">
                                        <i className="fa fa-dot-circle-o text-success" />{" "}
                                        Uploaded
                                      </a>
                                    ) : (
                                      <a className="dropdown-item">
                                        <i className="fa fa-dot-circle-o text-danger" />{" "}
                                        Not Uploaded
                                      </a>
                                    )}
                                  </div>
                                  <div className="dropdown action-label text-center">
                                    {ele.status === "Approved" ? (
                                      <a className="dropdown-item" href="#">
                                        <i className="fa fa-dot-circle-o text-success" />{" "}
                                        Verified
                                      </a>
                                    ) : ele.status === "Pending" ? (
                                      <a className="dropdown-item" href="#">
                                        <i className="fa fa-dot-circle-o text-primary" />{" "}
                                        Pending
                                      </a>
                                    ) : (
                                      <a className="dropdown-item">
                                        <i className="fa fa-dot-circle-o text-danger" />{" "}
                                        Rejected
                                      </a>
                                    )}
                                  </div>
                                </div>
                                {![
                                  "OASI370734",
                                  "OASI019787",
                                  "S3 F251778",
                                  "OM N160326",
                                ].includes(sessionStorage.getItem("coid")) && (
                                  <div
                                    className=""
                                    style={{ marginLeft: "-5px" }}
                                  >
                                    <input
                                      type="file"
                                      accept="application/pdf"
                                      onChange={(e) =>
                                        handleUploadDocument(
                                          e.target.files[0],
                                          ele.doc_id,
                                          ele.select_file.content_type
                                        )
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        {isLoading && (
                          <div
                            style={{
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <RotatingLines
                              strokeColor="#2e86c1"
                              strokeWidth="5"
                              animationDuration="0.50"
                              width="50"
                              marginRight="700"
                              visible={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <Documentsview file={file} />
      </div>
    </div>
  );
};
export default Document;
