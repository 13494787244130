import React, { useMemo, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import { Table } from "antd";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";

const Outdoor = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [status1, setStatus1] = useState("");
  const [name1, setName1] = useState("");
  const [location, setLocation] = useState("");
  const [empId, setEmpId] = useState("");
  const [outdoor, setOutdoor] = useState({});
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");

  const handleOutdoor = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/outdoor_system/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: {
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
            coregid: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleOutdoor();
  }, [monthValue]);

  const handleApproveLeave = (e, value) => {
    e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/outdoor_system/`,
        {
          empid: outdoor.empid,
          date: outdoor.date,
          status: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        Swal.fire({
          icon: "success",
          text: `${res.data.message}!`,
        });
        // Swal.fire({
        //   icon: res.data.status === false ? "error" : "success",
        //   text:
        // });
        handleOutdoor();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const filteredData = useMemo(() => {
    return data
      .filter((x) => x.empname.toLowerCase().includes(name1.toLowerCase()))
      .filter((x) => x.empcode.toLowerCase().includes(empId.toLowerCase()))
      .filter((a) =>
        a.work_location.toLowerCase().includes(location.toLowerCase())
      )
      .filter((y) => y.status.toLowerCase().includes(status1.toLowerCase()));
  }, [name1, status1, location, empId, data]);

  console.log(data);
  console.log(filteredData);
  const columns = [
    {
      title: "Employee Code",
      dataIndex: "empcode",
    },
    {
      title: "Employee Name",
      dataIndex: "empname",
    },
    {
      title: "Outdoor Location",
      dataIndex: "location",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => (
        <>{text.slice(0, 10).split("-").reverse().join("-")}</>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Approved by Manager"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Pending"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "Approved"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#short_leave"
          className="btn-primary submit-btn1"
          onClick={(e) => {
            setOutdoor(text);
          }}
        >
          view
        </button>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Outdoor</h3>
              </div>
            </div>
          </div>
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee Name"
                  options={[
                    ...new Set(filteredData?.map((item) => item.empname)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee code"
                  options={[
                    ...new Set(filteredData?.map((item) => item.empcode)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    empId !== ""
                      ? {
                          label: empId,
                          value: empId,
                        }
                      : null
                  }
                  onChange={(e) => setEmpId(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Leave Status"
                  isClearable
                  options={[
                    ...new Set(filteredData?.map((item) => item.status)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    status1 !== ""
                      ? {
                          label: status1,
                          value: status1,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setStatus1(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Work Location"
                  isClearable
                  options={[
                    ...new Set(filteredData?.map((item) => item.work_location)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    location !== ""
                      ? {
                          label: location,
                          value: location,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setLocation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            {/* <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                  <div className="form-group  ">
                    <input
                      className="form-control input-height"
                      type="date"
                      placeholder="To"
                      value={to1}
                      onChange={(e) => setTo1(e.target.value)}
                    />
                  </div>
                </div> */}
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (
                  setName1(""), setLocation(""), setEmpId(""), setStatus1("")
                )}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <div className="view-icons">
                    <input
                      style={{
                        width: "150px",
                        borderRadius: "5px",
                        height: "37px",
                        padding: "10px",
                      }}
                      type="month"
                      value={monthValue}
                      onChange={(e) => setMonthValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: filteredData?.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={filteredData}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}

        <div id="short_leave" className="modal custom-modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  View Short Leave
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-sm-12">
                    <div className="form-group">
                      <label>
                        Employee Name <span className="text-danger">*</span>
                      </label>
                      <input
                        disabled
                        className="form-control datetimepicker"
                        type="text"
                        value={outdoor.empname}
                      />
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Date <span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            disabled
                            className="form-control datetimepicker"
                            type="date"
                            value={outdoor.date?.slice(0, 10)}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="submit-section"
                      style={{ justifyContent: "space-between" }}
                    >
                      {outdoor.status !== "Declined" && (
                        <button
                          type="button"
                          className="btn btn-success btn-block w-100 "
                          data-bs-dismiss="modal"
                          style={{
                            background: "transparent",
                            color: "#18838D",
                          }}
                          onClick={(e) => handleApproveLeave(e, "Declined")}
                        >
                          Reject
                        </button>
                      )}
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {/* <button className="btn btn-success btn-block w-100">
                        Approved
                      </button> */}
                      {outdoor.status === "Pending" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveLeave(e, "Approved")}
                        >
                          Accept
                        </button>
                      )}
                      {outdoor.status === "Declined" && (
                        <button
                          className="btn btn-success btn-block w-100"
                          data-bs-dismiss="modal"
                          onClick={(e) => handleApproveLeave(e, "Approved")}
                        >
                          Accept
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Outdoor;
