import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClientCaptcha from "react-client-captcha";
import {
  styles,
  SBox1,
  SBox2,
  SBox3,
  SBox4,
  STextField,
  SButton,
} from "./LoginCSS";
import img2 from "../../assets/img/profiles/newOz.png";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function LoginPage({ backImg, setBackImg }) {
  const [formData, setFormData] = useState({ empid: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
  };

  const setCode = (captchaCode) => {
    setCaptcha(captchaCode);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const GetAllCompany=()=>{
    axios.get(`https://oz.middleware.prod.api.ozhrms.com/company__details`
  ).then((res)=>{
    console.log(res.data)
  }).catch((err)=>{
    console.log(err)
  })
  }

  useEffect(()=>{
GetAllCompany()
  },[])

  const handlePermission = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/group_role/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: { grp_id: id },
        }
      )
      .then((res) => {
        let permission = {};

        res.data.forEach((element) => {
          permission[`${element.name}`] = element.optn?.filter(
            (item) => item.value === element.selected
          )[0].label;
        });

        console.log(permission);
        sessionStorage.setItem("permissions", JSON.stringify(permission));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async () => {
    if (formData.empid === "" && formData.password === "") {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#18838d",
        // timer: 1500,
        //title: "Oops...",
        text: "Enter User ID & Password",
        fontSize: "1.6rem",
      });
      document.getElementById("retryButton").click();
    } else if (formData.empid === "") {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#18838d",
        // timer: 1500,
        //title: "Oops...",
        text: "Enter User ID",
      });
      setFormData({ ...formData, password: "" });
      document.getElementById("retryButton").click();
    } else if (formData.password === "") {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#18838d",
        // timer: 1500,
        //itle: "Oops...",
        text: "Enter Password",
      });
      document.getElementById("retryButton").click();
    } else if (
      captcha === captchaInput &&
      !(formData.empid === "") &&
      !(formData.password === "")
    ) {
      setIsLoading(!isLoading);
      setShowError(false);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login_employee/`,
          formData
        );
        if (res.status === 200) {
          sessionStorage.setItem("theme_link", res.data.theme_link);
          sessionStorage.setItem("access_token", res.data.token.access);
          sessionStorage.setItem("refresh_token", res.data.token.refresh);
          sessionStorage.setItem("user", res.data.email);
          sessionStorage.setItem("emp_id", res.data.empid);
          sessionStorage.setItem("clockin_status", res.data.clockin_status);
          sessionStorage.setItem("company_logo", res.data.company_logo.links);
          sessionStorage.setItem("clockin_time", res.data.clockin_time);
          sessionStorage.setItem("name", res.data.name);
          sessionStorage.setItem("admid", res.data.admid);
          sessionStorage.setItem("role", res.data.role);
          sessionStorage.setItem("adminActive", false);
          sessionStorage.setItem("disable_clockin", res.data.disable_clockin);
          sessionStorage.setItem("coid", res.data.company_id);
          sessionStorage.setItem("happyZone", false);
          sessionStorage.setItem("wfh", res.data.wfh);
          sessionStorage.setItem("shift_from", res.data.shift_from);
          sessionStorage.setItem("token", res.data.sam_token)
          sessionStorage.setItem(
            "designations",
            JSON.stringify(res.data.designations)
          );
          sessionStorage.setItem("desigToggle", true);
          sessionStorage.setItem("isopen", true);
          handlePermission(res.data.role);
          setIsLoading(false);
          navigate("/dashboard");
        }
        if (sessionStorage.getItem("theme_link")) {
          const theme_link = sessionStorage.getItem("theme_link");
          setBackImg(theme_link);
        }
      } catch (err) {
        if (err.response.status === 400) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#18838d",
            // timer: 1500,
            title: "Oops...",
            text: err.response.data.message,
            // text: "The username or password you entered is incorrect. Please review your credentials and try again",
            // text: "Please Enter Valid Credentials",
          });
          setIsLoading(false);
          document.getElementById("retryButton").click();
          setFormData({ ...formData, password: "" });
        } else if (err.response.status === 500) {
          Swal.fire({
            icon: "error",
            confirmButtonColor: "#18838d",
            // timer: 1500,
            title: "Oops...",
            // text: "Incorrect User Details",
            text: err.response.data.message,
          });
          document.getElementById("retryButton").click();
          setFormData({ ...formData, password: "" });
        }
      }
      setIsLoading(false);
    } else {
      setShowError(true);
      document.getElementById("retryButton").click();
      setFormData({ ...formData, password: "" });
    }
  };

  return (
    <Box sx={{ display: "flex", background: "#FFFFFF", height: "100vh" }}>
      <SBox1 style={styles.paperContainer} sx={{ width: "68%" }} />
      <SBox2 sx={{ width: "32%" }}>
        <img
          src={img2}
          style={{ width: "180px", height: "auto", marginTop: "30px" }}
        />
        <SBox3>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Login
          </Typography>

          <STextField
            label="User ID"
            id="standard-size-small"
            size="small"
            variant="standard"
            // onCut={handleChange}
            // onCopy={handleChange}
            // onPaste={handleChange}
            value={formData.empid}
            onChange={(e) =>
              setFormData({ ...formData, empid: e.target.value.trim() })
            }
            onKeyPress={(e) => handleKeyPress(e)}
          />

          <STextField
            label="Password"
            id="standard-size-small"
            size="small"
            variant="standard"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={handleChange}
                    edge="end"
                    sx={{ color: "#18838d" }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            // onCut={handleChange}
            // onCopy={handleChange}
            // onPaste={handleChange}
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value.trim() })
            }
            onKeyPress={(e) => handleKeyPress(e)}
          />

          <Box sx={{ textAlign: "center", mt: 3 }}>
            <Grid container>
              <Grid item xs={4}>
                <SBox4>
                  <Typography variant="caption">Verification Code</Typography>
                </SBox4>
              </Grid>

              <Grid item xs={4}>
                <Box sx={{ mt: 1 }}>
                  <ClientCaptcha
                    captchaCode={setCode}
                    charsCount={6}
                    retry={true}
                    width={150}
                    height={25}
                    retryIconSize={29.5}
                  />
                </Box>
              </Grid>
              <Grid item md={4} xs={12} />
            </Grid>
          </Box>
          <br />

          <STextField
            label="Verification Code"
            id="standard-size-small"
            size="small"
            variant="standard"
            value={captchaInput}
            onChange={(e) => setCaptchaInput(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e)}
            name="password"
            autoComplete="off"
            type="text"
          />

          {showError && (
            <Box textAlign="center">
              <Typography variant="body2" sx={{ color: "red" }}>
                Incorrect Captcha!
              </Typography>
            </Box>
          )}
        </SBox3>
        <div style={{ display: "column" }}>
          <SButton variant="contained" onClick={handleSubmit}>
            {isLoading ? (
              <ThreeDots
                height="30"
                width="30"
                radius="9"
                color="#edf9fa"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            ) : (
              <Typography variant="body1">Login</Typography>
            )}
          </SButton>
          <div>
            <a
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => navigate("/forget-pass")}
            >
              Forgot Password ?
            </a>
          </div>
        </div>
      </SBox2>
    </Box>
  );
}

export default LoginPage;
