import React from "react";
import Sidebar from "../../pages/Sidebar/sidebar";
import Header from "../../pages/Sidebar/header";
import { useState, useEffect } from "react";
import Welcome from "../../assets/img/profiles/happyzone1.svg";
import { Link } from "react-router-dom";

function WelcomeZone() {
  const [menu, setMenu] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    window.history.pushState(null, null, window.location.pathname);
  };

  window.addEventListener("popstate", onBackButtonEvent);

  return (
    <div
      className={`main-wrapper ${menu ? "slide-nav" : ""}`}
      style={{ background: "#262339" }}
    >
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Content */}
          <div>
            <div className="row" style={{ marginLeft: "2%" }}>
              <div className="col-sm-8">
                <div>
                  <p className="welcome-header">Welcome to Happy Zone</p>
                  <h5 className="welcome-font">
                    Nothing great is ever achieved without being happy!
                  </h5>
                  {/* <h5 className="welcome-font"></h5>   */}
                </div>
              </div>
              <div className="col-sm-4" style={{ marginLeft: "-80px" }}>
                <img src={Welcome} />
              </div>
            </div>
            <Link to="/happyzone-two" style={{ color: "white" }}>
              <button className="welcome-btn">GET STARTED</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WelcomeZone;
