import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Select from "react-select";
import moment from "moment";
import Swal from "sweetalert2";
import { ConfigProvider, Table } from "antd";
import "antd/dist/antd.css";
import Tooltip from "@mui/material/Tooltip";
import taskvis from "../../assets/img/profiles/task-visualization.svg";
import report from "../../assets/img/profiles/task-board.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import "../../../src/antdstyle.css";
import axios from "axios";
import absent from "../../assets/img/profiles/absent.png";
import half1 from "../../assets/img/profiles/half.png";
import late from "../../assets/img/profiles/late.png";
import tw from "../../assets/img/profiles/total.png";
import wd from "../../assets/img/profiles/worked.png";
import aw from "../../assets/img/profiles/average.png";
import on_leaves from "../../assets/img/profiles/leave.png";
import early from "../../assets/img/profiles/early.png";
import empty from "../../assets/img/profiles/browser.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "80px", marginTop: "15px" }} />
    <p>No data in task report</p>
  </div>
);

const TaskReport = () => {
  const [data, setData] = useState([]);
  const [KpiData, setKpiData] = useState({});
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const token = sessionStorage.getItem("access_token");

  const handleGetProject = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/projects/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setProjects(
          res.data.map((ele) => ({ label: ele.name, value: ele.name }))
        );
      });
  };

  const handleApply = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/task_view2/`,

        {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
            project_id: "",
            comp_id: sessionStorage.getItem("coid"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetKpi = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/task_report/`,

        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setKpiData(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetProject();
    handleApply();
    handleGetKpi();
  }, []);

  const columns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Task Name",
      dataIndex: "task_name",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      sorter: (a, b) => a.project_name.localeCompare(b.project_name),
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "Low"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Medium"
                  ? "fa fa-dot-circle-o text-info"
                  : text === ""
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },

    {
      title: "Assigned Date",
      dataIndex: "assigned_date",
      //sorter: (a, b) => a.assigned_date.length - b.assigned_date.length,
      sorter: {
        compare: (a, b) =>
          moment(a.assigned_date, "YYYY-MM-DD") -
          moment(b.assigned_date, "YYYY-MM-DD"),
      },
    },
    {
      title: "Deadline",
      dataIndex: "due_date",
      render: (text, record) => (text === null ? "Permanent" : text),
      //sorter: (a, b) => a.due_date.length - b.due_date.length,
      sorter: {
        compare: (a, b) =>
          moment(a.due_date, "YYYY-MM-DD") - moment(b.due_date, "YYYY-MM-DD"),
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a, b) => a.description.length - b.description.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (text, record) => (
        <div className="action-label text-center">
          <a className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Partialy Completed"
                  ? "fa fa-dot-circle-o text-info"
                  : text === "In Progress"
                  ? "fa fa-dot-circle-o text-primary"
                  : text === "Completed"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin Template</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Task Report</h3>
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Leaves</li>
              </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <Tooltip title="Task Board">
                    <div
                      // to="/attendance-grid"
                      className={"grid-view btn btn-link "}
                      // onClick={() => setShowTable(false)}
                    >
                      {/* <i className="fa fa-th" /> */}
                      <img
                        src={report}
                        onClick={() => navigate("/taskboard-Grid")}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Tooltip>
                  &nbsp;&nbsp;
                  <Tooltip title="Task Visualization">
                    <div
                      // to="/attendance-grid"
                      className={"grid-view btn btn-link "}
                      // onClick={() => setShowTable(false)}
                    >
                      {/* <i className="fa fa-th" /> */}
                      <img
                        src={taskvis}
                        onClick={() => navigate("/task-chart")}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Tooltip>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Leave Statistics */}

          {/* /Leave Statistics */}
          <div className="row">
            <div className="col-md-12">
              {data.length == 0 && !isLoading ? (
                <div
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className="card "
                    style={{
                      height: "300px",
                      width: "350px",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        width: "100px",
                        height: "100px",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                      src={empty}
                    />
                    <br />
                    <h4>There is no task for you</h4>
                    <h6>Go and create task </h6>
                    <br />
                    <IconButton
                      sx={{ color: "#3298DB" }}
                      data-bs-toggle="modal"
                      data-bs-target="#create_project"
                    >
                      <AddCircleOutlineIcon size="medium" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row filter-row col-md-12">
                    <div className="col-md-12" style={{ display: "flex" }}>
                      <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-12">
                        <div className="form-group form-focus select-focus">
                          <Select
                            isClearable
                            styles={{
                              // Fixes the overlapping problem of the component
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                            inputId="single-select-example"
                            className="single-select"
                            classNamePrefix="react-select"
                            //isClearable={()=>name1.clearValue()}
                            placeholder="Project Name"
                            options={[
                              ...new Set(
                                data?.map((item) => item.project_name)
                              ),
                            ].map((ele) => ({
                              label: ele,
                              value: ele,
                            }))}
                            // options={projects}
                            value={
                              search !== ""
                                ? {
                                    label: search,
                                    value: search,
                                  }
                                : null
                            }
                            onChange={(e) => setSearch(e ? e.value : "")}
                            //className="select floating"
                            style={{
                              zindex: "5",
                              height: "50px",
                              width: "100%",
                              border: "1px solid lightgrey",
                              borderRadius: "3px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <div
                      className="col-md-3 "
                      // style={{ width: "210px" }}
                    >
                      <div
                        className="stats-info  "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="col-md-9 ">
                          <h4 className="kpi-head">No Progress</h4>
                          <div style={{ display: "flex" }}>
                            <h4 className="kpi-value">
                              {KpiData?.no_progress ? KpiData?.no_progress : 0}
                            </h4>
                            &nbsp;&nbsp;
                            {/* <h5
                      className={
                        kpi.absent?.improved ? "com_text" : "com_text1"
                      }
                    >
                      {kpi.absent?.improved
                        ? `+${kpi.absent?.percent}`
                        : `-${kpi.absent?.percent}`}
                    </h5> */}
                          </div>
                        </div>
                        <div className="col-md-3 kpi-img-box">
                          <img className=" kpi-img" src={absent} alt="" />
                        </div>

                        {/* <CircularStatic /> */}
                        <div
                        // style={{ width: 100, height: 100, marginLeft: "28px" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 "
                      // style={{ width: "210px" }}
                    >
                      <div
                        className="stats-info col-md-12 "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="col-md-9 ">
                          <h4 className="kpi-head">In Progress </h4>
                          <div style={{ display: "flex" }}>
                            <h4 className="kpi-value">
                              {KpiData?.in_progress ? KpiData?.in_progress : 0}
                            </h4>
                            &nbsp;&nbsp;
                            {/* <h5
                      className={kpi.leave?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.leave?.improved
                        ? `+${kpi.leave?.percent}`
                        : `-${kpi.leave?.percent}`}
                    </h5> */}
                          </div>
                        </div>
                        <div className="col-md-3 kpi-img-box">
                          <img className=" kpi-img" src={on_leaves} alt="" />
                        </div>

                        {/* <CircularStatic /> */}
                        <div
                        // style={{ width: 100, height: 100, marginLeft: "28px" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 "
                      // style={{ width: "210px" }}
                    >
                      <div
                        className="stats-info col-md-12 "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="col-md-9 ">
                          <h4 className="kpi-head">Task Completed </h4>
                          <div style={{ display: "flex" }}>
                            <h4 className="kpi-value">
                              {KpiData?.task_completed
                                ? KpiData?.task_completed
                                : 0}
                            </h4>
                            &nbsp;&nbsp;
                            {/* <h5
                      className={kpi.half?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.half?.improved
                        ? `+${kpi.half?.percent}`
                        : `-${kpi.half?.percent}`}
                    </h5> */}
                          </div>
                        </div>
                        <div className="col-md-3 kpi-img-box">
                          <img className=" kpi-img" src={wd} alt="" />
                        </div>

                        {/* <CircularStatic /> */}
                        <div
                        // style={{ width: 100, height: 100, marginLeft: "28px" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="col-md-3 "
                      // style={{ width: "210px" }}
                    >
                      <div
                        className="stats-info col-md-12 "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="col-md-9 ">
                          <h4 className="kpi-head">Late Completed </h4>
                          <div style={{ display: "flex" }}>
                            <h4 className="kpi-value">
                              {KpiData?.late_complete
                                ? KpiData?.late_complete
                                : 0}
                            </h4>
                            &nbsp;&nbsp;
                            {/* <h5
                      className={kpi.late?.improved ? "com_text" : "com_text1"}
                    >
                      {kpi.late?.improved
                        ? `+${kpi.late?.percent}`
                        : `-${kpi.late?.percent}`}
                    </h5> */}
                          </div>
                        </div>
                        <div className="col-md-3 kpi-img-box">
                          <img className=" kpi-img" src={late} alt="" />
                        </div>

                        {/* <CircularStatic /> */}
                        <div
                        // style={{ width: 100, height: 100, marginLeft: "28px" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="table-responsive">
                        <ConfigProvider
                          renderEmpty={
                            // customize ?
                            customizeRenderEmpty
                            // : undefined
                          }
                        >
                          <Table
                            className="table-striped"
                            pagination={{
                              total: data.length,
                              showTotal: (total, range) =>
                                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                              showSizeChanger: true,
                              onShowSizeChange: onShowSizeChange,
                              itemRender: itemRender,
                            }}
                            style={{ overflowX: "auto" }}
                            columns={columns}
                            // bordered
                            dataSource={data?.filter((ele) => {
                              return search?.toLocaleLowerCase() === ""
                                ? ele
                                : ele.project_name
                                    .toLocaleLowerCase()
                                    .includes(search?.toLocaleLowerCase());
                            })}
                            rowKey={(record) => record.id}
                            onChange={console.log("change")}
                            loading={isLoading}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskReport;
