import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../Sidebar/header";
import Sidebar from "../../Sidebar/sidebarAdmin";
import { Table, Typography } from "antd";
import "antd/dist/antd.css";
import Select from "@atlaskit/select";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import "../../../antdstyle.css";
import empty from "../../../assets/img/profiles/emptystate.svg";
import smily from "../../../assets/img/smiley-icon.svg";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import { ArrowBack } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { FiDownload } from "react-icons/fi";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import { empDetails } from "./empConst";
import moment from "moment";
import {
  Button,
  IconButton,
  TextField,
  Box,
  TableContainer,
  Table as Tablemui,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const styles = (theme) => ({
  textField: {
    boxSizing: "inherit",
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
});

const SButton = styled(Button)({
  background: "#3298DB",
  borderRadius: "8px",
  textTransform: "none",
  color: "#FFFFFF",
  boxShadow: "none",
  margin: "8px",
  ":hover": {
    background: "#3298DB",
    boxShadow: "none",
  },
});
const SButton1 = styled(Button)({
  background: "transparent",
  borderRadius: "4px",
  border: "1px solid #3298DB",
  textTransform: "none",
  color: "#3298DB",
  boxShadow: "none",
  padding: "0px",
  paddingLeft: "4px",
  paddingRight: "4px",
  margin: "8px",
  ":hover": {
    background: "transparent",
    boxShadow: "none",
  },
});

const SButton2 = styled(Button)({
  background: "transparent",
  borderRadius: "4px",
  border: "1px solid #FF0000",
  textTransform: "none",
  color: "#FF0000",
  boxShadow: "none",
  padding: "0px",
  paddingLeft: "4px",
  paddingRight: "4px",
  margin: "8px",
  ":hover": {
    background: "transparent",
    boxShadow: "none",
  },
});

const SBox = styled(Box)({
  textAlign: "center",
  width: "100%",
});

const ManageEmployee = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  const [empDetails, setEmpDetails] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [fullempDetails, setFullEmpDetails] = useState({});
  const [bankInfo, setBankInfo] = useState(false);
  const [columnData, setColumnData] = useState([
    { label: "Employee code", key: "empcode", isExportable: true },
    { label: "Employee ID", key: "empid", isExportable: true },
    { label: "Name", key: "name", isExportable: true },
    { label: "Email", key: "email", isExportable: true },
    { label: "Contact Number", key: "empphone", isExportable: true },

    { label: "Status", key: "status", isExportable: true },
    { label: "Employment type", key: "emp_type", isExportable: true },
    { label: "Designation", key: "designation", isExportable: true },
    { label: "Grade", key: "grade", isExportable: true },
    { label: "Department", key: "department", isExportable: true },
    { label: "DOJ", key: "date_joined", isExportable: true },
    { label: "Job Location", key: "job_location", isExportable: true },
    {
      label: "Reporting Manager Name",
      key: "reporting_manager_name",
      isExportable: true,
    },
    {
      label: "Reporting Manager Emp Code",
      key: "reporting_manager_empcode",
      isExportable: true,
    },
    { label: "DOB", key: "bday", isExportable: true },
    { label: "Age", key: "age", isExportable: true },
    { label: "Blood Group", key: "blood_group", isExportable: true },
    { label: "Marital Status", key: "martial_status", isExportable: true },
    { label: "PAN", key: "pan", isExportable: true },
    { label: "Aadhar", key: "aadhaar_no", isExportable: true },
    { label: "UAN", key: "uan", isExportable: true },
    { label: "Bank Name", key: "bank_name", isExportable: true },
    { label: "Account No.", key: "acc_no", isExportable: true },
    { label: "IFSC Code", key: "ifsc", isExportable: true },
    { label: "Education", key: "education", isExportable: true },
    { label: "Qualification", key: "qualification", isExportable: true },
    { label: "Current Address", key: "cur_address", isExportable: true },
    { label: "Permanent Address", key: "per_address", isExportable: true },
    { label: "Years in Company", key: "year_in_company", isExportable: true },
    {
      label: "Emergency Contact Name",
      key: "emergency_contact_primary_name",
      isExportable: true,
    },
    {
      label: "Emergency Contact Relation",
      key: "emergency_contact_primary_relation",
      isExportable: true,
    },
    {
      label: "Emergency Contact Phone",
      key: "emergency_contact_primary_phone",
      isExportable: true,
    },
    {
      label: "Emergency Contact Email",
      key: "emergency_contact_primary_email",
      isExportable: true,
    },
    {
      label: "Emergency Contact Blood Group",
      key: "emergency_contact_primary_blood_group",
      isExportable: true,
    },
  ]);

  const [rowData, setRowData] = useState([]);
  const [offices, setOffices] = useState([]);
  const [empPer, setEmpPer] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [deptList, setDeptList] = useState([]);
  const [hierarchies, setHierarchies] = useState([]);
  const [perList, setPerList] = useState([]);
  const [addressList, setAddressList] = useState({});
  const [addressList1, setAddressList1] = useState({});
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [bankDetails, setBankDetails] = useState({
    uan: "",
    bank_name: "",
    acc_no: "",
    confirm_acc_no: "",
    ifsc: "",
    pan: "",
    esi: "",
  });

  const [emergencyInfo, setEmergencyInfo] = useState(false);
  const [emergencyDetails, setEmergencyDetails] = useState({
    primary_name: "",
    primary_relation: "",
    primary_phone: "",
    primary_email: "",
    primary_blood_group: "",
    secondary_name: "",
    secondary_relation: "",
    secondary_phone: "",
    secondary_email: "",
    secondary_blood_group: "",
  });

  const [perInfo, setPerInfo] = useState(false);
  const [perDetails, setPerDetails] = useState({
    passport_no: "",
    passport_exp_dt: "",
    phone: "",
    aadhaar_no: "",
    office_name: "",
    office: "",
    father_name: "",
    mother_name: "",
    spouse_name: "",
    per_address: "",
    district: "",
    state: "",
    pincode: "",
    linkedin: null,
    facebook: null,
    twitter: null,
    country: "",
    nationality: "",
    blood_group: "",
    martial_status: "",
    marriage_date: "",
    empl_of_spouce: "",
    offspring_no: "",
    alternate_phone: "",
  });

  const [empInfo, setEmpInfo] = useState(false);
  const [empBasic, setEmpBasic] = useState({
    empphone: "",
    bday: "",
    address: "",
    empcode: "",
    gender: "",
    report_to: "",
    desg_id: "",
    dept_id: "",
    hie_id: "",
    date_joined: "",
    contractor_id: "",
  });

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [search, setSearch] = useState("");
  const [toggel, setToggel] = useState(true);
  const token = sessionStorage.getItem("access_token");

  const Gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const maritalStatus = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Separated", value: "Separated" },
    { label: "Widowed", value: "Widowed" },
  ];
  const bloodGroup = [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];
  const [inputList, setInputList] = useState([
    { education: "", college: "", from: "", to: "" },
  ]);

  const [certificationList, setCertificationLis] = useState([
    { course: "", institute: "", from: "", to: "" },
  ]);

  const [educationInfo, setEducationInfo] = useState(false);
  const [certificatesInfo, setCertificatesInfo] = useState(false);
  const [experienceInfo, setExperienceInfo] = useState(false);
  const [addEducation, setAddEducation] = useState([]);
  const [addCertificates, setAddCertificates] = useState([]);
  const [addExperience, setAddExperience] = useState([]);

  const AddFields = (e) => {
    e.preventDefault();
    let newfield = {
      education: "",
      college: "",
      from: "",
      to: "",
    };
    setAddEducation([...addEducation, newfield]);
  };

  const HandleForm = (index, name, value) => {
    let data = [...addEducation];
    data[index][name] = value;
    setAddEducation(data);
  };

  const RemoveField = (index) => {
    let data = [...addEducation];
    data.splice(index, 1);
    setAddEducation(data);
  };

  const AddFields1 = (e) => {
    e.preventDefault();
    let newfield = {
      course: "",
      institute: "",
      from: "",
      to: "",
    };
    setAddCertificates([...addCertificates, newfield]);
  };

  const HandleForm1 = (index, name, value) => {
    let data = [...addCertificates];
    data[index][name] = value;
    setAddCertificates(data);
  };

  const RemoveField1 = (index) => {
    let data = [...addCertificates];
    data.splice(index, 1);
    setAddCertificates(data);
  };

  const AddFields2 = (e) => {
    e.preventDefault();
    let newfield = {
      designation: "",
      company_name: "",
      industry: "",
      city: "",
      state: "",
      country: "",
      ctc: "",
      from: "",
      to: "",
    };
    setAddExperience([...addExperience, newfield]);
  };
  const emptype = [
    {
      label: "Full-Time",
      value: "Full-Time",
    },
    {
      label: "Permanent",
      value: "Permanent",
    },
    {
      label: "Probationer",
      value: "Probationer",
    },
    {
      label: "Part-Time",
      value: "Part-Time",
    },
    {
      label: "Contractual",
      value: "Contractual",
    },
    {
      label: "Trainee",
      value: "Trainee",
    },
    {
      label: "Intern",
      value: "Intern",
    },
    {
      label: "Temporary",
      value: "Temporary",
    },
  ];

  const HandleForm2 = (index, name, value) => {
    let data = [...addExperience];
    data[index][name] = value;
    setAddExperience(data);
    if (name === "city") {
      setCity(value);
    } else if (name === "state") {
      setState(value);
    }
  };

  const RemoveField2 = (index) => {
    let data = [...addExperience];
    data.splice(index, 1);
    setAddExperience(data);
  };

  const handleFormChange = (index, event) => {
    let data = [...inputList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setInputList(data);
  };
  const handleFormChange1 = (index, event) => {
    let data = [...certificationList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setCertificationLis(data);
  };

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { education: "", college: "", from: "", to: "" };
    setInputList([...inputList, newfield]);
  };

  const addCertificateFields = (e) => {
    e.preventDefault();
    let newfield = { course: "", institute: "", from: "", to: "" };

    setCertificationLis([...certificationList, newfield]);
  };

  const Input = () => {
    return (
      <>
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
      </>
    );
  };
  // console.log(inputList);

  const removeFields = (index) => {
    let data = [...inputList];
    data.splice(index, 1);
    setInputList(data);
  };

  const removeFields1 = (index) => {
    let data = [...certificationList];
    data.splice(index, 1);
    setCertificationLis(data);
  };

  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));

  const handleFormChange12 = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isActive"] = !data[index]["isActive"];
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChange111 = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["education"] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChange112 = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["industry"] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_admins/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setManagerList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_hierarchy/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setHeir(res.data);
        // setHierarchies(
        //   res.data.hie.filter(
        //     (obj, index) =>
        //       res.data.hie.findIndex((item) => item.hie_id === obj.hie_id) ===
        //       index
        //   )
        // );
        // setHierarchies(
        //   res.data.map((ele) => ({
        //     label: ele.hie.name,
        //     value: ele.hie.hie_id,
        //   }))
        // );
        // setIndustrytype(res.data);
        setHierarchies(
          res.data.map((ele) => ({
            label: ele.hie.name,
            value: ele.hie.hie_id,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_department/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setGetDept(res.data);
        setDeptList(
          res.data.map((ele) => ({
            label: ele.dept.name,
            value: ele.dept.dept_id,
          }))
        );
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gs_mas_industry/`)
      .then((res) => {
        // setDeptList(
        //   res.data.map((ele) => ({
        //     label: ele.dept.name,
        //     value: ele.dept.dept_id,
        //   }))
        // );
        setIndustryList(
          res.data.map((ele) => ({
            label: ele.label,
            value: ele.label,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gs_mas_education/`)
      .then((res) => {
        // setDeptList(
        //   res.data.map((ele) => ({
        //     label: ele.dept.name,
        //     value: ele.dept.dept_id,
        //   }))
        // );
        setQualificationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/contractors/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setContractors(
          res.data.map((ele, index) => ({
            value: ele.id,
            label: ele.name,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_co_office/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // setOfficeDetails(res.data);
        setOffices(
          res.data.map((ele) => ({
            label: `${ele.name}-${ele.city}`,
            value: ele.off_id,
          }))
        );
        //     });
        // setOfficeTable(res.data);
        // setIndustrytype(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_designation/`, {
        params: {
          draft: 1,
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDesignationList(
          res.data
            .filter((item) => item.desg !== null)
            .map((ele) => ({
              label: ele.desg.name,
              value: ele.desg.desg_id,
            }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleStatus = (e, id, status) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/change_status/`,
        {
          empid: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "Employee Status Updated",
          // timer: 3000,
        });
        handlemanage();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const handlemanage = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/directory/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmployeeDetails(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            emp_id: ele.emp_username,
            empl_id: ele.emp_id,
            name: ele.emp_name,
            email: ele.email,
            joined_date: ele.emp_joined,
            empstatus: ele.emp_status,
            role: ele.emp_role,
            department: ele.emp_department,
          }))
        );
        setIsLoading(false);
        // setSerachData(res.data);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handlemanage();
  }, []);

  const GetEmployeeDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
        {
          params: {
            empid: empDetails.empl_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.data;
      setFullEmpDetails(data);
      setEmpPer(res.data.permission);
      setInputList(data.emp_education);
      //
      setAddEducation(data.emp_education);
      setAddCertificates(data.certification);
      setAddExperience(data.experience);
      //
      setCertificationLis(data.certification);

      setBankDetails({
        bank_name: data.bank_name,
        acc_no: data.acc_no,
        confirm_acc_no: data.acc_no,
        ifsc: data.ifsc,
        pan: data.pan,
        uan: data.uan,
        esi: data.esi,
      });

      setEmergencyDetails({
        primary_name: data.primary_name,
        primary_relation: data.primary_relation,
        primary_phone: data.primary_phone,
        primary_email: data.primary_email,
        primary_blood_group: data.primary_blood_group,
        secondary_name: data.secondary_name,
        secondary_relation: data.secondary_relation,
        secondary_phone: data.secondary_phone,
        secondary_email: data.secondary_email,
        secondary_blood_group: data.secondary_blood_group,
      });

      setPerDetails({
        passport_no: data.passport_no,
        passport_exp_dt: data.passport_exp_dt,
        phone: data.phone,
        aadhaar_no: data.aadhaar_no,
        office_name: data.office_name,
        father_name: data.father_name,
        mother_name: data.mother_name,
        spouse_name: data.spouse_name,
        per_address: data.per_address,
        district: data.district,
        state: data.state,
        pincode: data.pincode,
        country: data.country,
        office: data.offices?data.offices:[],
        nationality: data.nationality,
        blood_group: data.blood_group,
        martial_status: data.martial_status,
        marriage_date: data.marriage_date,
        empl_of_spouce: data.empl_of_spouce,
        offspring_no: data.offspring_no,
        alternate_phone: data.alternate_phone,
        linkedin: data.linkedin,
        facebook: data.facebook,
        twitter: data.twitter,
      });

      setEmpBasic({
        mobile: data.mobile,
        bday: data.bday,
        alternate_phone: data.alternate_phone,
        emp_adress: data.address,
        address: data.address,
        gender: data.gender,
        report_to: data.report_to,
        empphone: data.empphone,
        empcode: data.empcode,
        date_joined: data.date_joined !== "" ? new Date(data.date_joined) : "",
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetEmployeeDetails();
  }, [empDetails]);

  const allEmployeeReport = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/master_employees/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
      })
      .then((res) => {
        //   setColumnData( res.data.map((v) => v)[0].map((item) =>
        //   Object.keys(item)
        // )[0].map((ele)=>({
        //     name: ele,
        //     key: ele,
        // })))
        // setColumnData(
        //   Object.keys(res.data[0]).map((ele) => ({ name: ele, key: ele }))
        // );
        setRowData(
          res.data.map((ele, index) => ({
            ...ele,
            date_joined: ele.date_joined?.slice(0, 10),
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    allEmployeeReport();
  }, []);

  const handleAddress = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/city_state_country/`, {
        params: {
          city: city.length > 2 ? city : "",
          state: state.length > 2 ? state : "",
          country: "",
        },
      })
      .then((res) => {
        //   setColumnData( res.data.map((v) => v)[0].map((item) =>
        //   Object.keys(item)
        // )[0].map((ele)=>({
        //     name: ele,
        //     key: ele,
        // })))
        setAddressList(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddress1 = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/city_state_country/`, {
        params: {
          city: perDetails?.district?.length > 2 ? perDetails?.district : "",
          state: "",
          country: "",
        },
      })
      .then((res) => {
        //   setColumnData( res.data.map((v) => v)[0].map((item) =>
        //   Object.keys(item)
        // )[0].map((ele)=>({
        //     name: ele,
        //     key: ele,
        // })))
        setAddressList1(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleAddress();
  }, [city, state]);
  useEffect(() => {
    handleAddress1();
  }, [perDetails.district]);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const EditEmployeeDetails = async () => {
    if (
      (Boolean(emergencyDetails?.primary_email?.match(isValidEmail)) ||
        emergencyDetails?.primary_email === null ||
        emergencyDetails?.primary_email === "" ||
        emergencyDetails?.primary_email === undefined) &&
      (Boolean(emergencyDetails?.secondary_email?.match(isValidEmail)) ||
        emergencyDetails?.secondary_email === null ||
        emergencyDetails?.secondary_email === "" ||
        emergencyDetails?.secondary_email === undefined) &&
      bankDetails.acc_no === bankDetails.confirm_acc_no
    ) {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
          {
            empid: fullempDetails.empl_id,
            name: fullempDetails.name,
            dob: moment(empBasic.bday).format("YYYY-MM-DD"),
            gender: fullempDetails.gender,
            off_mobile_number: empBasic.empphone,
            address: empBasic.address,
            date_joined: empBasic.date_joined,
            empcode: empBasic.empcode,
            report_to: fullempDetails.report_to.value
              ? fullempDetails.report_to.value
              : null,
            desg_id: fullempDetails.designation.value,
            dept_id: fullempDetails.department.value,
            hie_id: fullempDetails.hierchy.value,
            emp_type: fullempDetails.emp_type,
            contractor_id: fullempDetails.contractor_id?.value,
            passport_number: perDetails.passport_no,
            overtime_applicable: fullempDetails.overtime_applicable,
            passport_exp_date:
              fullempDetails.passport_exp_dt !== null
                ? moment(fullempDetails.passport_exp_dt).format("YYYY-MM-DD")
                : null,
            offices: perDetails.office?.value,
            nationality: perDetails.nationality,
            father_name: perDetails.father_name,
            mother_name: perDetails.mother_name,
            spouse_name: perDetails.spouse_name,
            blood_grp: perDetails.blood_group,
            linkedin: perDetails.linkedin,
            facebook: perDetails.facebook,
            twitter: perDetails.twitter,
            marital_status: perDetails.martial_status,
            marriage_date: perDetails.marriage_date,
            per_ph_number: empBasic.empphone,
            emp_of_spouse: perDetails.empl_of_spouce,
            number_of_children: Number(perDetails.offspring_no),
            alt_ph_number: perDetails.alternate_phone,
            aux_desg: fullempDetails.aux_desg.map((ele) => ele.value),
            aadhar_number: perDetails.aadhaar_no
              ? perDetails.aadhaar_no.split(" ").join("")
              : "",
            permanant_address: perDetails.per_address,
            state: perDetails.state,
            district: perDetails.district,
            country: perDetails.country,
            pincode: perDetails.pincode,
            bank_name: bankDetails.bank_name,
            acc_number: bankDetails.confirm_acc_no,
            IFSC_code: bankDetails.ifsc,
            uan: bankDetails.uan,
            esi: bankDetails.esi,
            permission: perList
              .filter((items) => items.isActive === true)
              .map((ele) => ele.id),
            // role: fullempDetails.roles.id,
            //role: 4,
            emergency_cont: [
              {
                relation: emergencyDetails.primary_relation
                  ? emergencyDetails.primary_relation
                  : "",
                name: emergencyDetails.primary_name
                  ? emergencyDetails.primary_name
                  : "",
                ph_number: emergencyDetails.primary_phone
                  ? emergencyDetails.primary_phone
                  : "",
                email: emergencyDetails.primary_email
                  ? emergencyDetails.primary_email
                  : "",
                blood_grp: emergencyDetails.primary_blood_group
                  ? emergencyDetails.primary_blood_group
                  : "",
              },
              {
                relation: emergencyDetails.secondary_relation
                  ? emergencyDetails.secondary_relation
                  : "",
                name: emergencyDetails.secondary_name
                  ? emergencyDetails.secondary_name
                  : "",
                ph_number: emergencyDetails.secondary_phone
                  ? emergencyDetails.secondary_phone
                  : "",
                email: emergencyDetails.secondary_email
                  ? emergencyDetails.secondary_email
                  : "",
                blood_grp: emergencyDetails.secondary_blood_group
                  ? emergencyDetails.secondary_blood_group
                  : "",
              },
            ],
            education: addEducation.map((v) => {
              return {
                degree_name: v.education,
                institution: v.college,
                specialization: "",
                from: v.from,
                to: v.to,
              };
            }),
            experience: addExperience,
            certification: addCertificates.map((ele) => ({
              name: ele.course,
              institute: ele.institute,
              from: ele.from,
              to: ele.to,
            })),
            /*
            empid: fullempDetails.empl_id, //JSON.parse(sessionStorage.getItem("emp_id")),
            toggle_pp: "n",
            //aadhaar_no: perDetails.aadhaar_no,
            acc_no: bankDetails.acc_no,
            address: empBasic.emp_adress,
            alternate_phone: fullempDetails.alternate_phone,
            bank_name: bankDetails.bank_name,
            bday: fullempDetails.bday,
            blood_group: perDetails.blood_group,
            candidateid: fullempDetails.candidateid,
            certification: fullempDetails.certification,
            date_joined: fullempDetails.date_joined,
            department: fullempDetails.department,
            designation: fullempDetails.designation,
            district: fullempDetails.district,
            email: fullempDetails.email,
            emp_education: fullempDetails.emp_education,
            emp_profile: fullempDetails.emp_profile,
            emp_type: fullempDetails.emp_type,
            //empl_id: fullempDetails.empl_id,
            empl_of_spouce: perDetails.empl_of_spouce,
            empphone: fullempDetails.empphone,
            //experience: fullempDetails.experience,
            gender: empBasic.gender,
            ids: fullempDetails.ids,
            ifsc: bankDetails.ifsc,
            martial_status: perDetails.martial_status,
            name: fullempDetails.name,
            nationality: perDetails.nationality,
            //office_logo: fullempDetails.office_logo,
            office_name: perDetails.office_name,
            offspring_no: perDetails.offspring_no,
            pan: bankDetails.pan,
            passport_exp_dt: perDetails.passport_exp_dt,
            passport_no: perDetails.passport_no,
            per_address: perDetails.per_address,
            per_email: fullempDetails.per_email,
            phone: perDetails.phone,
            pincode: fullempDetails.pincode,
            primary_blood_group: emergencyDetails.primary_blood_group,
            primary_email: emergencyDetails.primary_email,
            primary_name: emergencyDetails.primary_name,
            primary_phone: emergencyDetails.primary_phone,
            primary_relation: emergencyDetails.primary_relation,
            //profile_pic: fullempDetails.profile_pic,
            religion: fullempDetails.religion,
            report_to: empBasic.report_to,
            secondary_blood_group: fullempDetails.secondary_blood_group,
            secondary_email: emergencyDetails.secondary_email,
            secondary_name: emergencyDetails.secondary_name,
            secondary_phone: emergencyDetails.secondary_phone,
            secondary_relation: emergencyDetails.secondary_relation,
            state: fullempDetails.state,
            status: fullempDetails.status,
            */
          },
          {
            /*
            params: {
              empid: empDetails.empl_id,
            },
            */
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);
        if (res.status === 200) {
          GetEmployeeDetails();
          Swal.fire({
            icon: "success",
            text: "Profile Information updated successfully.",
            // timer: 1500,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      if (bankDetails.acc_no !== bankDetails.confirm_acc_no) {
        Swal.fire({
          icon: "error",
          text: "Please confirm bank account number",
          // timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Enter valid email.",
          // timer: 1500,
        });
      }
    }
  };

  const handleFormChange13 = (event, index, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["isExportable"] = !data[index]["isExportable"];
    // data[index][event] = event.value;
    setState(data);
  };

  const handlePerms = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/permissions/`
        // {
        // params: {
        //   // empid: sessionStorage.getItem("emp_id"),
        //   coregid: sessionStorage.getItem("coid"),
        // },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
        // }
      )
      .then((res) => {
        setPerList(
          res.data
            .map((ele) => {
              return {
                id: ele.id,
                name: ele.name,
                description: ele.description,
              };
            })
            .map((items) => {
              if (empPer.map((ele) => ele.id).includes(items.id)) {
                return {
                  ...items,
                  isActive: true,
                };
              } else {
                return {
                  ...items,
                  isActive: false,
                };
              }
            })
        );
      });
  };
  const sortOrder = [
    "Active",
    "Serving Notice Period",
    "Withdrawn",
    "InActive",
    "Inactive",
  ];

  // useEffect(() => {
  //   if (empRole !== "") {
  //     const data = perList.find(
  //       (ele) => ele.id === employeeAdd.user_detail.role
  //     );
  //     setUserPer(data.perm_ids);
  //   }
  // }, [employeeAdd.user_detail.role]);

  const columns = [
    {
      title: "SNo.",
      dataIndex: "sno",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "ID",
      dataIndex: "emp_id",
      sorter: (a, b) => a.emp_id.localeCompare(b.emp_id),
    },
    {
      title: "Employee Code",
      dataIndex: "empcode",
      render: (text, record) => <>{text ? text : "-"}</>,
      // sorter: (a, b) => a.empcode.localeCompare(b.empcode),
    },
    {
      title: "Name of Employee",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },

    {
      title: "Joining Date",
      dataIndex: "joined_date",
      sorter: {
        compare: (a, b) =>
          moment(a.joined_date, "YYYY-MM-DD") -
          moment(b.joined_date, "YYYY-MM-DD"),
      },
      render: (text, record) => (
        <>{text?.slice(0, 10)?.split("-")?.reverse()?.join("-")}</>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role.localeCompare(b.role),
    },

    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Status",
      dataIndex: "empstatus",
      align: "center",
      key: "empstatus",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            // disabled={text === "Completed" ? true : false}
          >
            <i
              className={
                text === "Serving Notice Period"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "Withdrawn"
                  ? "fa fa-dot-circle-o text-primary"
                  : text === "Active"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
            {/* === "No Progress"
                ? "No Progress"
                : text === "In Progress"
                ? "In Progress"
                : text === "Partialy Completed"
                ? "Completed"
                : text === "Completed"
                ? "Completed"
                : "No Progress"} */}
          </a>
          <div className="dropdown-menu dropdown-menu">
            {text !== "Inactive" && (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.empl_id, "InActive")}
              >
                <i className="fa fa-dot-circle-o text-danger" /> InActive
              </a>
            )}
            {text !== "Withdrawn" && (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.empl_id, "Withdrawn")}
              >
                <i className="fa fa-dot-circle-o text-primary" />
                Withdrawn
              </a>
            )}
            {text !== "Serving Notice Period" && (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) =>
                  handleStatus(e, record.empl_id, "Serving Notice Period")
                }
              >
                <i className="fa fa-dot-circle-o text-purple" />
                Serving Notice Period
              </a>
            )}
            {text !== "Active" && (
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.empl_id, "Active")}
              >
                <i className="fa fa-dot-circle-o text-success" />
                Active
              </a>
            )}
          </div>
        </div>
      ),
    },

    // {
    //   title: "Reason",
    //   dataIndex: "reason",
    //   sorter: (a, b) => a.reason.length - b.reason.length,
    //   ellipsis: true,
    // },

    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            data-bs-toggle="modal"
            data-bs-target="#view_leave"
            className=" btn-primary submit-btn1"
            onClick={(e) => (
              setToggel(false),
              setEmpDetails(record),
              setSearch(""),
              setEmpInfo(false),
              setEmergencyInfo(false),
              setPerInfo(false),
              setBankInfo(false)
            )}
          >
            View
          </button>
          {/* <button
            data-bs-toggle="modal"
            data-bs-target="#admin_permission"
            onClick={() => handlePerms()}
          >
            Admin
          </button> */}
        </>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  {/* {!toggel && (
                    <>
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "4px 4px",
                            fontSize: "15px",
                            background: " #3298DB",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setToggel(true)}
                        >
                          <ArrowBack />
                        </button>
                      </span>
                      &nbsp;{" "}
                    </>
                  )} */}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setToggel(true)}
                  >
                    Manage Employees
                  </span>{" "}
                  {!toggel && (
                    <span style={{ color: "#6c757d" }}> / EmpProfile</span>
                  )}
                </h3>
              </div>
              {toggel && (
                <div className="col-auto float-end ml-auto">
                  <button
                    className="btn btn-success btn-block"
                    // onClick={allEmployeeReport}
                    data-bs-toggle="modal"
                    data-bs-target="#admin_permission"
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: " #973FCF",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    Employee Report
                  </button>
                </div>
              )}
            </div>
          </div>

          {toggel === true ? (
            <>
              <div className="row filter-row col-md-12">
                <div
                  className="col-md-8"
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <div className="col-md-6">
                    <div className=" col-md-12">
                      <input
                        type="search"
                        onChange={(e) =>
                          //setProName(e.target.value.toLocaleLowerCase())
                          setSearch(e.target.value)
                        }
                        placeholder="Employee Name and Code"
                        className="project-search col-md-12 "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: employeeDetails
                          ?.sort(function (a, b) {
                            return (
                              sortOrder.indexOf(a.emp_status) -
                              sortOrder.indexOf(b.emp_status)
                            );
                          })
                          .filter((ele) => {
                            return search.toLocaleLowerCase() === ""
                              ? ele
                              : ele.name
                                  .toLocaleLowerCase()
                                  .includes(search.toLocaleLowerCase()) ||
                                search === ""
                              ? ele
                              : ele.empcode?.includes(search);
                          }).length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={employeeDetails
                        ?.sort(function (a, b) {
                          return (
                            sortOrder.indexOf(a.emp_status) -
                            sortOrder.indexOf(b.emp_status)
                          );
                        })
                        ?.filter((ele) => {
                          return search.toLocaleLowerCase() === ""
                            ? ele
                            : ele.name
                                .toLocaleLowerCase()
                                .includes(search.toLocaleLowerCase()) ||
                              search === ""
                            ? ele
                            : ele.empcode?.includes(search);
                        })}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-view">
                        <div className="profile-img-wrap">
                          <div
                            className="profile-img"
                            style={{ display: "flex" }}
                          >
                            <a href="#">
                              <img
                                // style={{ border: "1px solid #344767" }}
                                alt=""
                                src={
                                  fullempDetails.profile_pic
                                    ? fullempDetails.profile_pic?.links
                                    : smily
                                }
                              />
                            </a>
                          </div>
                        </div>
                        <div className="profile-basic">
                          <div className="row">
                            <div className="col-md-5">
                              <div
                                className="profile-info-left"
                                style={{
                                  lineHeight: "300%",
                                  marginTop: "20px",
                                }}
                              >
                                {/* <div
                                  className="text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  <b>ID : </b>
                                  {fullempDetails.empl_id
                                    ? fullempDetails.empl_id
                                    : "-"}
                                </div> */}

                                {/* <div
                                  // className="text"
                                  style={{
                                    textTransform: "capitalize",
                                    marginTop: "-10px",
                                  }}
                                >
                                  <b>Emp Code : </b>
                                  {fullempDetails.empcode
                                    ? fullempDetails.empcode
                                    : "-"}
                                </div> */}

                                {/* <h3
                                  className="user-name m-t-0 mb-2"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {fullempDetails.name
                                    ? fullempDetails.name
                                    : "-"}
                                </h3> */}
                                <h3>
                                  {" "}
                                  {empInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="input"
                                      value={fullempDetails?.name}
                                      // onInput={(e) => {
                                      //   e.target.value = Math.max(
                                      //     0,
                                      //     parseInt(e.target.value)
                                      //   )
                                      //     .toString()
                                      //     .slice(0, 10);
                                      // }}
                                      onChange={(e) =>
                                        setFullEmpDetails({
                                          ...fullempDetails,
                                          name: e.target.value,
                                        })
                                      }
                                    />
                                  ) : fullempDetails.name ? (
                                    fullempDetails.name
                                  ) : (
                                    "-"
                                  )}
                                </h3>
                                <div
                                  className="small doj "
                                  style={{
                                    marginTop: "",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <h6>
                                    {" "}
                                    Emp Code:
                                    {fullempDetails.empcode
                                      ? fullempDetails.empcode
                                      : "-"}
                                  </h6>
                                </div>
                                <small
                                  className=""
                                  style={{ marginTop: "10px" }}
                                >
                                  <h6>
                                    {fullempDetails.designation
                                      ? fullempDetails.designation.label
                                      : "-"}
                                  </h6>
                                </small>

                                <div
                                  className="small doj "
                                  style={{
                                    marginTop: "",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <h6>
                                    {" "}
                                    Date of Joining:{" "}
                                    {empInfo ? (
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        value={empBasic.date_joined}
                                        // onInput={(e) => {
                                        //   e.target.value = Math.max(
                                        //     0,
                                        //     parseInt(e.target.value)
                                        //   )
                                        //     .toString()
                                        //     .slice(0, 10);
                                        // }}
                                        onChange={(e) =>
                                          setEmpBasic({
                                            ...empBasic,
                                            date_joined: e.target.value,
                                          })
                                        }
                                      />
                                    ) : fullempDetails.date_joined ? (
                                      fullempDetails.date_joined
                                    ) : (
                                      "-"
                                    )}
                                  </h6>
                                </div>

                                <h6
                                  className=""
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {fullempDetails.address
                                    ? fullempDetails.address
                                    : "-"}
                                </h6>
                                {/* <div className="staff-msg">
                              <Link
                                onClick={() =>
                                  localStorage.setItem("minheight", "true")
                                }
                                className="btn btn-custom"
                                to="/conversation/chat"
                              >
                                Send Message
                              </Link>
                            </div> */}
                              </div>
                            </div>
                            <div className="col-md-7">
                              <ul className="personal-info">
                                {rolePermissions["Employee Office Details"] ===
                                  "Edit/Create/Approve" && (
                                  <Box
                                    sx={{ textAlign: "right", width: "100%" }}
                                  >
                                    {!empInfo ? (
                                      <IconButton
                                        sx={{ color: "#000000", mb: 1 }}
                                        onClick={() => setEmpInfo(true)}
                                      >
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    ) : null}{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <button
                                      className="permission-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#manage_permission"
                                      onClick={() => handlePerms()}
                                    >
                                      Manage Premission
                                    </button> */}
                                  </Box>
                                )}

                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Email:
                                  </div>
                                  <div className="">
                                    {fullempDetails.email
                                      ? fullempDetails.email
                                      : "-"}
                                  </div>
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Phone:
                                  </div>
                                  {empInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="number"
                                      defaultValue={fullempDetails?.empphone}
                                      onInput={(e) => {
                                        e.target.value = Math.max(
                                          0,
                                          parseInt(e.target.value)
                                        )
                                          .toString()
                                          .slice(0, 10);
                                      }}
                                      onChange={(e) =>
                                        setEmpBasic({
                                          ...empBasic,
                                          empphone: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.empphone
                                        ? fullempDetails.empphone
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    DOB:
                                  </div>
                                  {empInfo ? (
                                    <TextField
                                      sx={{ minWidth: "210px" }}
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      defaultValue={fullempDetails?.bday}
                                      onChange={(e) =>
                                        setEmpBasic({
                                          ...empBasic,
                                          bday: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.bday
                                        ? fullempDetails.bday
                                            ?.split("-")
                                            ?.reverse()
                                            ?.join("-")
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  {empInfo && (
                                    <>
                                      <div
                                        className="title"
                                        style={{ fontWeight: "600" }}
                                      >
                                        {" "}
                                        Employee Code:
                                      </div>
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <TextField
                                          id="outlined-size-small"
                                          size="small"
                                          defaultValue={fullempDetails?.empcode}
                                          onChange={(e) =>
                                            setEmpBasic({
                                              ...empBasic,
                                              empcode: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {" "}
                                    Current Address:
                                  </div>
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {empInfo ? (
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="text"
                                        defaultValue={fullempDetails?.address}
                                        onChange={(e) =>
                                          setEmpBasic({
                                            ...empBasic,
                                            address: e.target.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <div className="">
                                        {fullempDetails.address
                                          ? fullempDetails.address
                                          : "-"}
                                      </div>
                                    )}
                                  </div>
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Gender:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Gender"
                                        options={Gender}
                                        value={
                                          fullempDetails.gender
                                            ? {
                                                label: fullempDetails.gender,
                                                value: fullempDetails.gender,
                                              }
                                            : null
                                        }
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            gender: e.value,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.gender
                                        ? fullempDetails.gender
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Reports to:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Manager"
                                        options={managerList}
                                        // defaultValue={fullempDetails.report_to}
                                        value={fullempDetails.report_to}
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            report_to: e,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails?.report_to?.label
                                        ? fullempDetails?.report_to?.label
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Designation:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Designation"
                                        options={designationList}
                                        defaultValue={
                                          fullempDetails.designation
                                        }
                                        value={fullempDetails.designation}
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            designation: e,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.designation
                                        ? fullempDetails.designation.label
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                {/* <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                   Position:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                       isMulti
                                       placeholder="Designation"
                                       options={designationList}
                                       defaultValue={
                                         fullempDetails.aux_desg
                                       }
                                       value={fullempDetails.aux_desg}
                                       onChange={(e) =>
                                         setFullEmpDetails({
                                           ...fullempDetails,
                                           aux_desg: e,
                                         })
                                          // console.log(`e`, e)
                                       }
                                      //  type="text"
                                       id="outlined-size-small"
                                       size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.aux_desg
                                        ? fullempDetails.aux_desg.map((ele)=>(ele.label)).join(" , ")
                                        : "-"}
                                    </div>
                                  )}
                                </li> */}
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Department:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Department"
                                        options={deptList}
                                        value={fullempDetails.department}
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            department: e,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.department?.label
                                        ? fullempDetails.department?.label
                                        : "-"}
                                    </div>
                                  )}
                                </li>

                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Grade:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Hierachy"
                                        options={hierarchies}
                                        value={fullempDetails.hierchy}
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            hierchy: e,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.hierchy?.label
                                        ? fullempDetails.hierchy?.label
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Employment Type:
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <Select
                                        placeholder="Employment Type"
                                        options={emptype}
                                        // value={fullempDetails.emp_type}
                                        value={emptype.filter(
                                          (ele) =>
                                            fullempDetails.emp_type ===
                                            ele.value
                                        )}
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            emp_type: e.value,
                                          })
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.emp_type
                                        ? fullempDetails.emp_type
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                {fullempDetails?.emp_type === "Contractual" && (
                                  <li>
                                    <div
                                      className="title"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Contractor:
                                    </div>

                                    {empInfo ? (
                                      <FormControl
                                        sx={{
                                          minWidth: 210,
                                          backgroundColor: "#e9eaec",
                                        }}
                                      >
                                        <Select
                                          placeholder="Employment Type"
                                          options={contractors}
                                          // value={fullempDetails.emp_type}
                                          value={fullempDetails?.contractor_id}
                                          onChange={(e) =>
                                            setFullEmpDetails({
                                              ...fullempDetails,
                                              contractor_id: e,
                                            })
                                          }
                                          type="text"
                                          id="outlined-size-small"
                                          size="small"
                                        />
                                      </FormControl>
                                    ) : (
                                      <div className="">
                                        {fullempDetails.contractor_id?.label
                                          ? fullempDetails.contractor_id?.label
                                          : "-"}
                                      </div>
                                    )}
                                  </li>
                                )}
                                <li style={{ marginTop: "20px" }}>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Overtime Applicable :
                                  </div>

                                  {empInfo ? (
                                    <FormControl
                                      sx={{
                                        minWidth: 210,
                                        backgroundColor: "#e9eaec",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        style={{
                                          display: "flex",
                                          textAlign: "left",
                                          width: "50px",
                                        }}
                                        value={
                                          fullempDetails?.overtime_applicable
                                        }
                                        onChange={(e) =>
                                          setFullEmpDetails({
                                            ...fullempDetails,
                                            overtime_applicable:
                                              !fullempDetails.overtime_applicable,
                                          })
                                        }
                                      />
                                    </FormControl>
                                  ) : (
                                    <div className="">
                                      {fullempDetails.overtime_applicable
                                        ? "Yes"
                                        : "No"}
                                    </div>
                                  )}
                                </li>

                                {/* {empInfo && (
                                  <li>
                                    <div
                                      className="title"
                                      style={{ fontWeight: "600" }}
                                    >
                                      Date of Joining:
                                    </div>

                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      defaultValue={fullempDetails?.date_joined}
                                      onChange={(e) =>
                                        setEmpBasic({
                                          ...empBasic,
                                          bday: e.target.value,
                                        })
                                      }
                                    />
                                  </li>
                                )} */}
                              </ul>

                              {empInfo ? (
                                <SBox>
                                  <SButton
                                    onClick={() => {
                                      setEmpInfo(false);
                                      EditEmployeeDetails();
                                    }}
                                  >
                                    Save
                                  </SButton>
                                </SBox>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {/* <div className="pro-edit">
                        <a
                          data-bs-target="#profile_info"
                          data-bs-toggle="modal"
                          className="edit-icon"
                          href="#"
                        >
                          <i className="fa fa-pencil" />
                        </a>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                {/* Profile Info Tab */}
                <div
                  id="emp_profile"
                  className="pro-overview tab-pane fade show active"
                >
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "600px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Personal Information
                            {/* <a
                            href="#"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#personal_info_modal"
                          >
                            <i className="fa fa-pencil" />
                          </a> */}
                            {rolePermissions["Employee Personal Details"] ===
                              "Edit/Create/Approve" && (
                              <>
                                {!perInfo ? (
                                  <IconButton
                                    sx={{ color: "#000000", mb: 1 }}
                                    onClick={() => setPerInfo(true)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                ) : null}
                              </>
                            )}
                          </h3>
                          <ul className="personal-info">
                          <li >
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Passport No.
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  defaultValue={fullempDetails?.passport_no}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      passport_no: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.passport_no
                                    ? fullempDetails.passport_no
                                    : "-"}
                                </div>
                              )}
                            </li> 
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Passport Exp Date.
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  sx={{ minWidth: "210px" }}
                                  size="small"
                                  type="date"
                                  InputProps={{
                                    inputProps: {
                                      min: "1000-05-01",
                                      max: "9999-12-31",
                                    },
                                  }}
                                  defaultValue={fullempDetails?.passport_exp_dt}
                                  onChange={(e) =>
                                    setFullEmpDetails({
                                      ...fullempDetails,
                                      passport_exp_dt: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.passport_exp_dt
                                    ? fullempDetails.passport_exp_dt
                                        ?.split("-")
                                        ?.reverse()
                                        ?.join("-")
                                    : "-"}
                                </div>
                              )}
                            </li>

                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Alternate Contact Number
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  inputProps={{ maxLength: 10 }}
                                  defaultValue={fullempDetails?.alternate_phone}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      alternate_phone: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.alternate_phone
                                    ? fullempDetails.alternate_phone
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Aadhar Card Number
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  inputProps={{ maxLength: 12 }}
                                  defaultValue={fullempDetails?.aadhaar_no}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      aadhaar_no: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.aadhaar_no
                                    ? fullempDetails.aadhaar_no
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Office
                              </div>
                              {perInfo ? (
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    placeholder="Office"
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    options={offices}
                                    value={perDetails.office}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        office: e,
                                      })
                                    }
                                  />
                                </FormControl>
                              ) : (
                                <div className="">
                                  {fullempDetails.offices?.label
                                    ? fullempDetails.offices?.label
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Permanent Address
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.per_address}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      per_address: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.per_address
                                    ? fullempDetails.per_address
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Pincode
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="number"
                                  maxLength="6"
                                  defaultValue={perDetails?.pincode}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      pincode: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.pincode
                                    ? fullempDetails.pincode
                                    : "-"}
                                </div>
                              )}
                            </li>
                            {/* <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                City
                              </div>
                              {perInfo ? (
                                <div class="form-group">
                                  <input
                                    type="text"
                                    style={{
                                      height: "40px",
                                      border: "1px solid #b4b3b1",
                                      backgroundColor: "#ebebeb",
                                      maxWidth: "210px",
                                    }}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    list="EmpCity"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter city"
                                    value={perDetails?.district}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        district: e.target.value,
                                      })
                                    }
                                  />
                                  <datalist id="EmpCity">
                                    {addressList1?.city
                                      ?.filter(
                                        (value, index, self) =>
                                          self.indexOf(value) === index
                                      )
                                      ?.map((ele, index) => (
                                        <option
                                          key={index}
                                          value={ele}
                                        ></option>
                                      ))}
                                  </datalist>
                                </div>
                              ) : (
                                <div className="">
                                  {fullempDetails.district
                                    ? fullempDetails.district
                                    : "-"}
                                </div>
                              )}
                            </li> */}
                            {/* <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                State
                              </div>
                              {perInfo ? (
                                <div class="form-group">
                                  <input
                                    type="text"
                                    style={{
                                      height: "40px",
                                      border: "1px solid #b4b3b1",
                                      backgroundColor: "#ebebeb",
                                      maxWidth: "210px",
                                    }}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    list="EmpState"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter state"
                                    value={perDetails?.state}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        state: e.target.value,
                                      })
                                    }
                                  />
                                  <datalist id="EmpState">
                                    {addressList1?.state
                                      ?.filter(
                                        (value, index, self) =>
                                          self.indexOf(value) === index
                                      )
                                      ?.map((ele, index) => (
                                        <option
                                          key={index}
                                          value={ele}
                                        ></option>
                                      ))}
                                  </datalist>
                                </div>
                              ) : (
                                <div className="">
                                  {fullempDetails.state
                                    ? fullempDetails.state
                                    : "-"}
                                </div>
                              )}
                            </li> */}
                            {/* <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Country
                              </div>
                              {perInfo ? (
                                <div class="form-group">
                                  <input
                                    type="text"
                                    style={{
                                      height: "40px",
                                      border: "1px solid #b4b3b1",
                                      backgroundColor: "#ebebeb",
                                      maxWidth: "210px",
                                    }}
                                    class="form-control"
                                    id="exampleInputEmail1"
                                    list="EmpCountry"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter country"
                                    value={perDetails?.country}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        country: e.target.value,
                                      })
                                    }
                                  />
                                  <datalist id="EmpCountry">
                                    {addressList1?.country
                                      ?.filter(
                                        (value, index, self) =>
                                          self.indexOf(value) === index
                                      )
                                      ?.map((ele, index) => (
                                        <option
                                          key={index}
                                          value={ele}
                                        ></option>
                                      ))}
                                  </datalist>
                                </div>
                              ) : (
                                <div className="">
                                  {fullempDetails.country
                                    ? fullempDetails.country
                                    : "-"}
                                </div>
                              )}
                            </li> */}
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Nationality
                              </div>
                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.nationality}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      nationality: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.nationality
                                    ? fullempDetails.nationality
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Blood Group
                              </div>
                              {perInfo ? (
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="Blood Group"
                                    options={bloodGroup}
                                    value={
                                      perDetails.blood_group
                                        ? {
                                            label: perDetails.blood_group,
                                            value: perDetails.blood_group,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        blood_group: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                              ) : (
                                
                                <div className="">
                                  {fullempDetails.blood_group
                                    ? fullempDetails.blood_group
                                    : "-"}
                                </div>
                              )}
                            </li>

                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Father Name/Husband Name
                              </div>

                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  value={perDetails.father_name}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      father_name: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.father_name
                                    ? fullempDetails.father_name
                                    : "-"}
                                </div>
                              )}
                            </li>

                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Mother Name
                              </div>

                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  value={perDetails.mother_name}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      mother_name: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.mother_name
                                    ? fullempDetails.mother_name
                                    : "-"}
                                </div>
                              )}
                            </li>

                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Marital Status
                              </div>
                              {perInfo ? (
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="marital status"
                                    options={maritalStatus}
                                    value={
                                      perDetails.martial_status
                                        ? {
                                            label: perDetails.martial_status,
                                            value: perDetails.martial_status,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        martial_status: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                              ) : (
                                // <TextField
                                //   id="outlined-size-small"
                                //   size="small"
                                //   type="text"
                                //   defaultValue={fullempDetails?.martial_status}
                                //   onChange={(e) =>
                                //     setPerDetails({
                                //       ...perDetails,
                                //       martial_status: e.target.value,
                                //     })
                                //   }
                                // />
                                <div className="">
                                  {fullempDetails.martial_status
                                    ? fullempDetails.martial_status
                                    : "-"}
                                </div>
                              )}
                            </li>
                            {perDetails.martial_status === "Married" && (
                              <>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Spouse Name
                                  </div>

                                  {perInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      value={perDetails.spouse_name}
                                      onChange={(e) =>
                                        setPerDetails({
                                          ...perDetails,
                                          spouse_name: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.spouse_name
                                        ? fullempDetails.spouse_name
                                        : "-"}
                                    </div>
                                  )}
                                </li>

                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Date of Marriage
                                  </div>

                                  {perInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      sx={{ width: "210px" }}
                                      InputProps={{
                                        inputProps: { max: "9999-12-31" },
                                      }}
                                      value={perDetails.marriage_date}
                                      onChange={(e) =>
                                        setPerDetails({
                                          ...perDetails,
                                          marriage_date: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.marriage_date
                                        ? fullempDetails?.marriage_date
                                            ?.slice(0, 10)
                                            ?.split("-")
                                            ?.reverse()
                                            ?.join("-")
                                        : "-"}
                                    </div>
                                  )}
                                </li>

                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Spouse Employment
                                  </div>
                                  {perInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="text"
                                      defaultValue={
                                        fullempDetails?.empl_of_spouce
                                      }
                                      onChange={(e) =>
                                        setPerDetails({
                                          ...perDetails,
                                          empl_of_spouce: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.empl_of_spouce
                                        ? fullempDetails.empl_of_spouce
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                                <li>
                                  <div
                                    className="title"
                                    style={{ fontWeight: "600" }}
                                  >
                                    Children
                                  </div>
                                  {perInfo ? (
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="number"
                                      defaultValue={
                                        fullempDetails?.offspring_no
                                      }
                                      onChange={(e) =>
                                        setPerDetails({
                                          ...perDetails,
                                          offspring_no: e.target.value,
                                        })
                                      }
                                    />
                                  ) : (
                                    <div className="">
                                      {fullempDetails.offspring_no
                                        ? fullempDetails.offspring_no
                                        : "-"}
                                    </div>
                                  )}
                                </li>
                              </>
                            )}
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Facebook
                              </div>

                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  value={perDetails.facebook}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      facebook: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.facebook
                                    ? fullempDetails.facebook
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Linkedin
                              </div>

                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  value={perDetails.linkedin}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      linkedin: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.linkedin
                                    ? fullempDetails.linkedin
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Twitter
                              </div>

                              {perInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  value={perDetails.twitter}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      twitter: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.twitter
                                    ? fullempDetails.twitter
                                    : "-"}
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>

                        {perInfo ? (
                          <SBox>
                            <SButton
                              onClick={() => {
                                setPerInfo(false);
                                EditEmployeeDetails();
                              }}
                            >
                              Save
                            </SButton>
                          </SBox>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "600px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Emergency Contact{" "}
                            {/* <a
                            href="#"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#emergency_contact_modal"
                          >
                            <i className="fa fa-pencil" />
                          </a> */}
                            {rolePermissions["Employee Personal Details"] ===
                              "Edit/Create/Approve" && (
                              <>
                                {!emergencyInfo ? (
                                  <IconButton
                                    sx={{ color: "#000000", mb: 1 }}
                                    onClick={() => setEmergencyInfo(true)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                ) : null}
                              </>
                            )}
                          </h3>

                          <h5 className="section-title">Primary </h5>
                          <ul className="personal-info">
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Name
                              </div>

                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.primary_name}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      primary_name: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.primary_name
                                    ? fullempDetails.primary_name
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Relationship
                              </div>
                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={
                                    fullempDetails?.primary_relation
                                  }
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      primary_relation: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.primary_relation
                                    ? fullempDetails.primary_relation
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Phone{" "}
                              </div>

                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="number"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  defaultValue={fullempDetails?.primary_phone}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      primary_phone: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.primary_phone
                                    ? fullempDetails.primary_phone
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Email ID
                              </div>

                              {emergencyInfo ? (
                                <TextField
                                  type="email"
                                  id="outlined-size-small"
                                  size="small"
                                  defaultValue={fullempDetails?.primary_email}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      primary_email: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.primary_email
                                    ? fullempDetails.primary_email
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Blood Group
                              </div>

                              {emergencyInfo ? (
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="Blood Group"
                                    options={bloodGroup}
                                    value={
                                      emergencyDetails.primary_blood_group
                                        ? {
                                            label:
                                              emergencyDetails.primary_blood_group,
                                            value:
                                              emergencyDetails.primary_blood_group,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setEmergencyDetails({
                                        ...emergencyDetails,
                                        primary_blood_group: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                              ) : (
                                // <TextField
                                //   id="outlined-size-small"
                                //   size="small"
                                //   type="text"
                                //   defaultValue={
                                //     fullempDetails?.primary_blood_group
                                //   }
                                //   onChange={(e) =>
                                //     setEmergencyDetails({
                                //       ...emergencyDetails,
                                //       primary_blood_group: e.target.value,
                                //     })
                                //   }
                                // />
                                <div className="">
                                  {fullempDetails.primary_blood_group
                                    ? fullempDetails.primary_blood_group
                                    : "-"}
                                </div>
                              )}
                            </li>
                          </ul>
                          <hr />
                          <h5 className="section-title">Secondary</h5>
                          <ul className="personal-info">
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Name
                              </div>
                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.secondary_name}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      secondary_name: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.secondary_name
                                    ? fullempDetails.secondary_name
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Relationship
                              </div>

                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={
                                    fullempDetails?.secondary_relation
                                  }
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      secondary_relation: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.secondary_relation
                                    ? fullempDetails.secondary_relation
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Phone{" "}
                              </div>
                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="number"
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  defaultValue={fullempDetails?.secondary_phone}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      secondary_phone: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.secondary_phone
                                    ? fullempDetails.secondary_phone
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Email ID
                              </div>

                              {emergencyInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="email"
                                  value={emergencyDetails?.secondary_email}
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      secondary_email: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.secondary_email
                                    ? fullempDetails.secondary_email
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Blood Group
                              </div>

                              {emergencyInfo ? (
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="Blood Group"
                                    options={bloodGroup}
                                    value={
                                      emergencyDetails.secondary_blood_group
                                        ? {
                                            label:
                                              emergencyDetails.secondary_blood_group,
                                            value:
                                              emergencyDetails.secondary_blood_group,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setEmergencyDetails({
                                        ...emergencyDetails,
                                        secondary_blood_group: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                              ) : (
                                // <TextField
                                //   id="outlined-size-small"
                                //   size="small"
                                //   type="text"
                                //   defaultValue={
                                //     fullempDetails?.secondary_blood_group
                                //   }
                                //   onChange={(e) =>
                                //     setEmergencyDetails({
                                //       ...emergencyDetails,
                                //       secondary_blood_group: e.target.value,
                                //     })
                                //   }
                                // />
                                <div className="">
                                  {fullempDetails.secondary_blood_group
                                    ? fullempDetails.secondary_blood_group
                                    : "-"}
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>

                        {emergencyInfo ? (
                          <SBox>
                            <SButton
                              onClick={() => {
                                setEmergencyInfo(false);
                                EditEmployeeDetails();
                              }}
                            >
                              Save
                            </SButton>
                          </SBox>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Bank Information
                            {rolePermissions["Employee Banking Details"] ===
                              "Edit/Create/Approve" && (
                              <>
                                {!bankInfo ? (
                                  <IconButton
                                    sx={{ color: "#000000", mb: 1 }}
                                    onClick={() => setBankInfo(true)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                ) : null}
                              </>
                            )}
                          </h3>
                          <ul className="personal-info">
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Bank Name
                              </div>
                              {bankInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.bank_name}
                                  onChange={(e) =>
                                    setBankDetails({
                                      ...bankDetails,
                                      bank_name: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.bank_name
                                    ? fullempDetails.bank_name
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Bank Account No.
                              </div>
                              {bankInfo ? (
                                <>
                                  <TextField
                                    sx={{ boxSizing: "inherit" }}
                                    id="standard-basic"
                                    size="small"
                                    type="password"
                                    // className={classes.textField}
                                    defaultValue={bankDetails?.acc_no}
                                    onChange={(e) =>
                                      setBankDetails({
                                        ...bankDetails,
                                        acc_no: e.target.value,
                                      })
                                    }
                                    name="password"
                                    autoComplete="off"
                                  />
                                </>
                              ) : (
                                <div className="">
                                  {fullempDetails.acc_no
                                    ? fullempDetails.acc_no
                                    : "-"}
                                </div>
                              )}
                            </li>
                            {bankInfo && (
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Confirm Bank Account No.
                                </div>
                                {bankInfo ? (
                                  <>
                                    <TextField
                                      error={
                                        bankDetails.acc_no ===
                                        bankDetails.confirm_acc_no
                                          ? false
                                          : true
                                      }
                                      sx={{ boxSizing: "inherit" }}
                                      id="standard-basic"
                                      size="small"
                                      type="text"
                                      helperText={
                                        bankDetails.acc_no ===
                                        bankDetails.confirm_acc_no
                                          ? ""
                                          : "account number not matched"
                                      }
                                      // className={classes.textField}
                                      defaultValue={bankDetails?.acc_no}
                                      onChange={(e) =>
                                        setBankDetails({
                                          ...bankDetails,
                                          confirm_acc_no: e.target.value,
                                        })
                                      }
                                      name="password"
                                      autoComplete="off"
                                    />
                                  </>
                                ) : (
                                  <div className="">
                                    {fullempDetails.acc_no
                                      ? fullempDetails.acc_no
                                      : "-"}
                                  </div>
                                )}
                              </li>
                            )}

                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                IFSC Code
                              </div>
                              {bankInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.ifsc?.toUpperCase()}
                                  onChange={(e) =>
                                    setBankDetails({
                                      ...bankDetails,
                                      ifsc: e.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.ifsc
                                    ? fullempDetails.ifsc
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                PAN No
                              </div>
                              {bankInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  disabled
                                  defaultValue={fullempDetails?.pan}
                                  onChange={(e) =>
                                    setBankDetails({
                                      ...bankDetails,
                                      pan: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.pan
                                    ? fullempDetails.pan
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                UAN
                              </div>
                              {bankInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.uan}
                                  onChange={(e) =>
                                    setBankDetails({
                                      ...bankDetails,
                                      uan: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.uan
                                    ? fullempDetails.uan
                                    : "-"}
                                </div>
                              )}
                            </li>
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                ESI
                              </div>
                              {bankInfo ? (
                                <TextField
                                  id="outlined-size-small"
                                  size="small"
                                  type="text"
                                  defaultValue={fullempDetails?.esi}
                                  onChange={(e) =>
                                    setBankDetails({
                                      ...bankDetails,
                                      esi: e.target.value,
                                    })
                                  }
                                />
                              ) : (
                                <div className="">
                                  {fullempDetails.esi
                                    ? fullempDetails.esi
                                    : "-"}
                                </div>
                              )}
                            </li>
                          </ul>

                          {bankInfo ? (
                            <SBox>
                              <SButton
                                onClick={() => {
                                  setBankInfo(false);
                                  EditEmployeeDetails();
                                }}
                              >
                                Save
                              </SButton>
                            </SBox>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Experience{" "}
                            {addExperience?.length === 0 ? (
                              !experienceInfo ? null : (
                                <>
                                  <SButton2
                                    onClick={() => setExperienceInfo(false)}
                                  >
                                    X
                                  </SButton2>
                                  <SButton1
                                    onClick={() => {
                                      EditEmployeeDetails();
                                      setExperienceInfo(false);
                                    }}
                                  >
                                    Save
                                  </SButton1>
                                </>
                              )
                            ) : !experienceInfo ? (
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setExperienceInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <>
                                <SButton2
                                  onClick={async () => {
                                    await GetEmployeeDetails();
                                    setExperienceInfo(false);
                                  }}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={async () => {
                                    await EditEmployeeDetails();
                                    await GetEmployeeDetails();
                                    setExperienceInfo(false);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )}
                          </h3>

                          {addExperience?.length === 0 ? (
                            !experienceInfo ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "180px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton
                                  sx={{ color: "#3298DB" }}
                                  onClick={() => setExperienceInfo(true)}
                                >
                                  <AddCircleOutlineIcon fontSize="large" />
                                </IconButton>
                              </Box>
                            ) : null
                          ) : null}

                          <div className="experience-box">
                            {experienceInfo ? (
                              <>
                                {addExperience.map((v, i) => {
                                  return (
                                    <>
                                      <TextField
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="Designation"
                                        value={v.designation}
                                     
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm2(
                                            i,
                                            "designation",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <br />

                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="Company Name"
                                        value={v.company_name}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm2(
                                            i,
                                            "company_name",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="CTC"
                                        type="number"
                                        value={v.ctc}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm2(i, "ctc", e.target.value)
                                        }
                                      />
                                      <br />
                                      <Select
                                        placeholder="Industry"
                                        options={industryList}
                                        value={
                                          v.industry
                                            ? {
                                                label: v.industry,
                                                value: v.industry,
                                              }
                                            : null
                                        }
                                        onChange={(event) =>
                                          handleFormChange112(
                                            i,
                                            event,
                                            addExperience,
                                            setAddExperience
                                          )
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />

                                      <br />

                                      {/* <TextField
                                        // id="outlined-size-small"
                                        id="EmpAddress"
                                        size="small"
                                        placeholder="city"
                                        // value={v.city}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        // onChange={(e) =>
                                        //   HandleForm2(i, "ctc", e.target.value)
                                        // }
                                      /> */}
                                      {/* <div class="form-group">
                                       
                                        <input
                                          type="text"
                                          style={{
                                            height: "40px",
                                            border: "1px solid #b4b3b1",
                                            backgroundColor: "#ebebeb",
                                          }}
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          list="EmpAddressCity"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter city"
                                          value={v.city}
                                          onChange={(e) =>
                                            HandleForm2(
                                              i,
                                              "city",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <datalist id="EmpAddressCity">
                                          {addressList?.city
                                            ?.filter(
                                              (value, index, self) =>
                                                self.indexOf(value) === index
                                            )
                                            ?.map((ele, index) => (
                                              <option
                                                key={index}
                                                value={ele}
                                              ></option>
                                            ))}
                                        </datalist>
                                      </div> */}
                                      {/* <div class="form-group">
                                    
                                        <input
                                          type="text"
                                          style={{
                                            height: "40px",
                                            border: "1px solid #b4b3b1",
                                            backgroundColor: "#ebebeb",
                                          }}
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          list="EmpAddressState"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter state"
                                          onChange={(e) =>
                                            HandleForm2(
                                              i,
                                              "state",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <datalist id="EmpAddressState">
                                          {addressList?.state
                                            ?.filter(
                                              (value, index, self) =>
                                                self.indexOf(value) === index
                                            )
                                            ?.map((ele, index) => (
                                              <option
                                                key={index}
                                                value={ele}
                                              ></option>
                                            ))}
                                        </datalist>
                                      </div> */}
                                      {/* <div class="form-group">
                                       
                                        <input
                                          type="text"
                                          style={{
                                            height: "40px",
                                            border: "1px solid #b4b3b1",
                                            backgroundColor: "#ebebeb",
                                          }}
                                          class="form-control"
                                          id="exampleInputEmail1"
                                          list="EmpAddressCon"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter country"
                                          onChange={(e) =>
                                            HandleForm2(
                                              i,
                                              "country",
                                              e.target.value
                                            )
                                          }
                                        />
                                        <datalist id="EmpAddressCon">
                                          {addressList?.country
                                            ?.filter(
                                              (value, index, self) =>
                                                self.indexOf(value) === index
                                            )
                                            ?.map((ele, index) => (
                                              <option
                                                key={index}
                                                value={ele}
                                              ></option>
                                            ))}
                                        </datalist>
                                      </div> */}

                                      <Typography variant="caption">
                                        From
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.from}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm2(i, "from", e.target.value)
                                        }
                                      />
                                      <br />

                                      <Typography variant="caption">
                                        To
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.to}
                                        sx={{ width: "100%", mb: 1 }}
                                        onChange={(e) =>
                                          HandleForm2(i, "to", e.target.value)
                                        }
                                      />

                                      <SButton2
                                        sx={{ mb: 2 }}
                                        onClick={() => RemoveField2(i)}
                                      >
                                        Delete
                                      </SButton2>
                                    </>
                                  );
                                })}

                                <Box
                                  sx={{ textAlign: "center", width: "100%" }}
                                >
                                  <SButton1 onClick={(e) => AddFields2(e)}>
                                    Add
                                  </SButton1>
                                </Box>
                              </>
                            ) : (
                              <>
                                {addExperience.length !== 0 ? (
                                  <TableContainer component={Paper}>
                                    <Tablemui
                                      sx={{ minWidth: 250 }}
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Company Name</TableCell>

                                          <TableCell>Designation</TableCell>
                                          <TableCell>CTC</TableCell>
                                          <TableCell>Industry</TableCell>
                                          <TableCell>Location</TableCell>
                                          <TableCell>From</TableCell>

                                          <TableCell>To</TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {addExperience?.map((ele) => {
                                          return (
                                            <TableRow>
                                              <TableCell>
                                                {ele.company_name}
                                              </TableCell>

                                              <TableCell>
                                                {ele.designation}
                                              </TableCell>
                                              <TableCell>{ele.ctc}</TableCell>
                                              <TableCell>
                                                {ele.industry}
                                              </TableCell>
                                              <TableCell>
                                                {ele.city +
                                                  ", " +
                                                  ele.state +
                                                  ", " +
                                                  ele.country}
                                              </TableCell>
                                              <TableCell>
                                                {ele.from
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>

                                              <TableCell>
                                                {ele.to
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Tablemui>
                                  </TableContainer>
                                ) : null}
                                {/*
                              {addExperience?.map((v) => {
                                return (
                                  <ul className="experience-list">
                                    <li>
                                      <div className="experience-user">
                                        <div className="before-circle" />
                                      </div>
                                      <div className="experience-content">
                                        <div className="timeline-content">
                                          <a href="/" className="name">
                                            {`${v.designation} at ${v.company_name}`}
                                          </a>
                                          <span className="time">
                                            {`${v.from} to ${v.to}`}
                                          </span>
                                          <br />
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                              */}
                              </>
                            )}

                            {/*
                          <ul className="experience-list">
                            {
                              empDetails.experience?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <SButton1>
                                    +
                                  </SButton1>
                                </div>
                              ) : (
                                empDetails.experience?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.designation} at ${ele.company_name}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )

                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>
                          */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Education{" "}
                            {addEducation?.length === 0 ? (
                              !educationInfo ? null : (
                                <>
                                  <SButton2
                                    onClick={() => setEducationInfo(false)}
                                  >
                                    X
                                  </SButton2>
                                  <SButton1
                                    onClick={() => {
                                      EditEmployeeDetails();
                                      setEducationInfo(false);
                                    }}
                                  >
                                    Save
                                  </SButton1>
                                </>
                              )
                            ) : !educationInfo ? (
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setEducationInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <>
                                <SButton2
                                  onClick={async () => {
                                    await GetEmployeeDetails();
                                    setEducationInfo(false);
                                  }}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={async () => {
                                    await EditEmployeeDetails();
                                    await GetEmployeeDetails();
                                    setEducationInfo(false);
                                    // setAddEducation([]);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )}
                          </h3>

                          {addEducation?.length === 0 ? (
                            !educationInfo ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "180px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton
                                  sx={{ color: "#3298DB" }}
                                  onClick={() => setEducationInfo(true)}
                                >
                                  <AddCircleOutlineIcon fontSize="large" />
                                </IconButton>
                              </Box>
                            ) : null
                          ) : null}

                          <div className="experience-box">
                            {educationInfo ? (
                              <>
                                {addEducation.map((v, i) => {
                                  return (
                                    <>
                                      {/* <TextField
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="Education"
                                        value={v.education}
                                        //value={v.education}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm(
                                            i,
                                            "education",
                                            e.target.value
                                          )
                                        }
                                      /> */}
                                      <Select
                                        placeholder="Education"
                                        options={qualificationList}
                                        value={
                                          v.education
                                            ? {
                                                label: v.education,
                                                value: v.education,
                                              }
                                            : null
                                        }
                                        onChange={(event) =>
                                          handleFormChange111(
                                            i,
                                            event,
                                            addEducation,
                                            setAddEducation
                                          )
                                        }
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                      />
                                      <br />

                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="College"
                                        defaultValue={v.college}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm(
                                            i,
                                            "college",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <br />

                                      <Typography variant="caption">
                                        From
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.from}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm(i, "from", e.target.value)
                                        }
                                      />
                                      <br />

                                      <Typography variant="caption">
                                        To
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.to}
                                        sx={{ width: "100%", mb: 1 }}
                                        onChange={(e) =>
                                          HandleForm(i, "to", e.target.value)
                                        }
                                      />

                                      <SButton2
                                        sx={{ mb: 2 }}
                                        onClick={() => RemoveField(i)}
                                      >
                                        Delete
                                      </SButton2>
                                    </>
                                  );
                                })}

                                <Box
                                  sx={{ textAlign: "center", width: "100%" }}
                                >
                                  <SButton1 onClick={(e) => AddFields(e)}>
                                    Add
                                  </SButton1>
                                </Box>
                              </>
                            ) : (
                              <>
                                {addEducation.length !== 0 ? (
                                  <TableContainer component={Paper}>
                                    <Tablemui
                                      sx={{ minWidth: 250 }}
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>
                                            College/Institute
                                          </TableCell>

                                          <TableCell>Degree</TableCell>

                                          <TableCell>From</TableCell>

                                          <TableCell>To</TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {addEducation?.map((ele) => {
                                          return (
                                            <TableRow>
                                              <TableCell>
                                                {ele.college}
                                              </TableCell>

                                              <TableCell>
                                                {ele.education}
                                              </TableCell>

                                              <TableCell>
                                                {ele.from
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>

                                              <TableCell>
                                                {ele.to
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Tablemui>
                                  </TableContainer>
                                ) : null}
                                {/** 
                              {addEducation?.map((v) => {
                                return (
                                  <>
                                    <ul className="experience-list">
                                      <li>
                                        <div className="experience-user">
                                          <div className="before-circle" />
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="/" className="name">
                                              {`${v.education} at ${v.college}`}
                                            </a>
                                            <span className="time">
                                              {`${v.from} to ${v.to}`}
                                            </span>
                                            <br />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </>
                                );
                              })}
                              */}
                              </>
                            )}

                            {/*
                          <ul className="experience-list">
                            {
                              empDetails.emp_education?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{ height: "100px" }}
                                    src={empty}
                                  />
                                  <p>
                                    <b>No Data Found</b>
                                  </p>
                                </div>
                              ) : (
                                empDetails.emp_education?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.education} at ${ele.college}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )
                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div
                          className="card-body"
                          style={{ maxHeight: "400px", overflowY: "auto" }}
                        >
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Certification{" "}
                            {addCertificates.length === 0 ? (
                              !certificatesInfo ? null : (
                                <>
                                  <SButton2
                                    onClick={() => setCertificatesInfo(false)}
                                  >
                                    X
                                  </SButton2>
                                  <SButton1
                                    onClick={() => {
                                      EditEmployeeDetails();
                                      setCertificatesInfo(false);
                                    }}
                                  >
                                    Save
                                  </SButton1>
                                </>
                              )
                            ) : !certificatesInfo ? (
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setCertificatesInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <>
                                <SButton2
                                  onClick={async () => {
                                    await GetEmployeeDetails();
                                    setCertificatesInfo(false);
                                  }}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={async () => {
                                    await EditEmployeeDetails();
                                    await GetEmployeeDetails();
                                    setCertificatesInfo(false);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )}
                          </h3>

                          {addCertificates?.length === 0 ? (
                            !certificatesInfo ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "180px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton
                                  sx={{ color: "#3298DB" }}
                                  onClick={() => setCertificatesInfo(true)}
                                >
                                  <AddCircleOutlineIcon fontSize="large" />
                                </IconButton>
                              </Box>
                            ) : null
                          ) : null}

                          <div className="experience-box">
                            {certificatesInfo ? (
                              <>
                                {addCertificates.map((v, i) => {
                                  return (
                                    <>
                                      <TextField
                                        type="text"
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="Course"
                                        value={v.course}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm1(
                                            i,
                                            "course",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <br />

                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        placeholder="Institute"
                                        value={v.institute}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm1(
                                            i,
                                            "institute",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <br />

                                      <Typography variant="caption">
                                        From
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.from}
                                        sx={{ width: "100%", mb: 0.5 }}
                                        onChange={(e) =>
                                          HandleForm1(i, "from", e.target.value)
                                        }
                                      />
                                      <br />

                                      <Typography variant="caption">
                                        To
                                      </Typography>
                                      <br />
                                      <TextField
                                        id="outlined-size-small"
                                        size="small"
                                        type="date"
                                        InputProps={{
                                          inputProps: {
                                            min: "1000-05-01",
                                            max: "9999-12-31",
                                          },
                                        }}
                                        value={v.to}
                                        sx={{ width: "100%", mb: 1 }}
                                        onChange={(e) =>
                                          HandleForm1(i, "to", e.target.value)
                                        }
                                      />

                                      <SButton2
                                        sx={{ mb: 2 }}
                                        onClick={() => RemoveField1(i)}
                                      >
                                        Delete
                                      </SButton2>
                                    </>
                                  );
                                })}

                                <Box
                                  sx={{ textAlign: "center", width: "100%" }}
                                >
                                  <SButton1 onClick={(e) => AddFields1(e)}>
                                    Add
                                  </SButton1>
                                </Box>
                              </>
                            ) : (
                              <>
                                {addCertificates.length !== 0 ? (
                                  <TableContainer component={Paper}>
                                    <Tablemui
                                      sx={{ minWidth: 250 }}
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Institute</TableCell>

                                          <TableCell>Course</TableCell>

                                          <TableCell>From</TableCell>

                                          <TableCell>To</TableCell>
                                        </TableRow>
                                      </TableHead>

                                      <TableBody>
                                        {addCertificates?.map((ele) => {
                                          return (
                                            <TableRow>
                                              <TableCell>
                                                {ele.institute}
                                              </TableCell>

                                              <TableCell>
                                                {ele.course}
                                              </TableCell>

                                              <TableCell>
                                                {ele.from
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>

                                              <TableCell>
                                                {ele.to
                                                  ?.split("-")
                                                  ?.reverse()
                                                  ?.join("-")}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Tablemui>
                                  </TableContainer>
                                ) : null}
                                {/*
                              {addCertificates?.map((v) => {
                                return (
                                  <>
                                    <ul className="experience-list">
                                      <li>
                                        <div className="experience-user">
                                          <div className="before-circle" />
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="/" className="name">
                                              {`${v.course} at ${v.institute}`}
                                            </a>
                                            <span className="time">
                                              {`${v.from} to ${v.to}`}
                                            </span>
                                            <br />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </>
                                );
                              })}
                              */}
                              </>
                            )}
                            {/*
                          <ul className="experience-list">
                            {
                              empDetails.certification?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{ height: "100px" }}
                                    src={empty}
                                  />
                                  <p>
                                    <b>No Data Found</b>
                                  </p>
                                </div>
                              ) : (
                                empDetails.certification?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.course} at ${ele.institute}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )
                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>
                          */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Experience{" "}
                           
                          </h3>

                          {
                            fullempDetails.experience?.length === 0 ? (
                              <div className="experience-box">
                                <ul className="experience-list">
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      style={{ height: "100px" }}
                                      src={empty}
                                    />
                                    <p>
                                      <b>No Data Found</b>
                                    </p>
                                  </div>
                                </ul>
                              </div>
                            ) : (
                              <>
                                <TableContainer component={Paper}>
                                  <Tablemui
                                    sx={{ minWidth: 250 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Company Name</TableCell>

                                        <TableCell>Designation</TableCell>

                                        <TableCell>From</TableCell>

                                        <TableCell>To</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {fullempDetails.experience?.map((ele) => {
                                        return (
                                          <TableRow>
                                            <TableCell>
                                              {ele.company_name}
                                            </TableCell>

                                            <TableCell>
                                              {ele.designation}
                                            </TableCell>

                                            <TableCell>{ele.from}</TableCell>

                                            <TableCell>{ele.to}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Tablemui>
                                </TableContainer>
                                
                              </>
                            )
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Education{" "}
                           
                          </h3>
                          {
                            fullempDetails.emp_education?.length === 0 ? (
                              <div className="experience-box">
                                <ul className="experience-list">
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      style={{ height: "100px" }}
                                      src={empty}
                                    />
                                    <p>
                                      <b>No Data Found</b>
                                    </p>
                                  </div>
                                </ul>
                              </div>
                            ) : (
                              <>
                                <TableContainer component={Paper}>
                                  <Tablemui
                                    sx={{ minWidth: 250 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>College/Institute</TableCell>

                                        <TableCell>Degree</TableCell>

                                        <TableCell>From</TableCell>

                                        <TableCell>To</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {fullempDetails.emp_education?.map(
                                        (ele) => {
                                          return (
                                            <TableRow>
                                              <TableCell>
                                                {ele.college}
                                              </TableCell>

                                              <TableCell>
                                                {ele.education}
                                              </TableCell>

                                              <TableCell>{ele.from}</TableCell>

                                              <TableCell>{ele.to}</TableCell>
                                            </TableRow>
                                          );
                                        }
                                      )}
                                    </TableBody>
                                  </Tablemui>
                                </TableContainer>
                                
                              </>
                            )
                            
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex">
                      <div className="card profile-box flex-fill">
                        <div className="card-body">
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Certification{" "}
                           
                          </h3>

                          {fullempDetails.certification?.length === 0 ? (
                            <div className="experience-box">
                              <ul className="experience-list">
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{ height: "100px" }}
                                    src={empty}
                                  />
                                  <p>
                                    <b>No Data Found</b>
                                  </p>
                                </div>
                              </ul>
                            </div>
                          ) : (
                            <>
                              <TableContainer component={Paper}>
                                <Tablemui
                                  sx={{ minWidth: 250 }}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Institute</TableCell>

                                      <TableCell>Course</TableCell>

                                      <TableCell>From</TableCell>

                                      <TableCell>To</TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {fullempDetails.certification?.map(
                                      (ele) => {
                                        return (
                                          <TableRow>
                                            <TableCell>
                                              {ele.institute}
                                            </TableCell>

                                            <TableCell>{ele.course}</TableCell>

                                            <TableCell>{ele.from}</TableCell>

                                            <TableCell>{ele.to}</TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Tablemui>
                              </TableContainer>
                              

                              
                            </>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </>
          )}

          <div
            id="manage_permission"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <br />

                <div
                  className="modal-header"
                  style={{ borderBottom: " 2px solid #cccccc" }}
                >
                  <h4
                    className="modal-title"
                    style={{ marginTop: "10px", fontWeight: "600" }}
                  >
                    {" "}
                    Manage Permissions{" "}
                  </h4>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group col-sm-12 row">
                    {perList.map((ele, index) => (
                      <div className="form-group col-sm-6">
                        <li className="list-group-item col-sm-12 ">
                          <div
                            className="status-toggle"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {ele.name}
                            <input
                              type="checkbox"
                              id={ele.id}
                              className="check"
                              name={ele.name}
                              checked={ele.isActive}
                              onChange={(event) =>
                                handleFormChange12(
                                  index,
                                  event,
                                  perList,
                                  setPerList
                                )
                              }
                            />
                            <label htmlFor={ele.id} className="checktoggle">
                              checkbox
                            </label>
                          </div>
                        </li>
                      </div>
                    ))}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      onClick={() => EditEmployeeDetails()}
                      className="permission-update-btn"
                      data-bs-dismiss="modal"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="admin_permission"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              <div className="modal-content">
                <br />

                <div
                  className="modal-header"
                  style={{ borderBottom: " 2px solid #cccccc" }}
                >
                  <h4
                    className="modal-title"
                    style={{ marginTop: "10px", fontWeight: "600" }}
                  >
                    {" "}
                    Select Columns To Export
                  </h4>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group col-sm-12 row">
                    {columnData.map((ele, index) => (
                      <>
                        <ul className="UlCSV" key={index}>
                          <input
                            type="checkbox"
                            checked={ele.isExportable}
                            onChange={(event) =>
                              handleFormChange13(
                                event,
                                index,
                                columnData,
                                setColumnData
                              )
                            }
                          />
                          <span className="ListCSV"> {ele.label}</span>
                        </ul>
                      </>
                    ))}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <button
                      className="btn btn-success btn-block"
                      // onClick={allEmployeeReport}
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: " #973FCF",
                        border: "none",
                        color: "white",
                        borderRadius: "10px",
                      }}
                    >
                      <CSVLink
                        data={rowData}
                        // headers={columnData}
                        filename="Employee Data Report"
                        headers={columnData.filter(
                          (ele) => ele.isExportable === true
                        )}
                        //   .map((ele) => ({
                        //   label: ele.name,
                        //   key: ele.key,
                        // }))}
                      >
                        <span style={{ color: "white" }}>
                          {" "}
                          {/*<FiDownload />*/}
                          {/* &nbsp;&nbsp; Employees Report */}
                          <span className="login-btn3-Text">Export</span>
                        </span>
                      </CSVLink>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageEmployee;
