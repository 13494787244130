/**
 * Blank Page
 */
import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import axios from "axios";
import "gantt-task-react/dist/index.css";

import { Gantt, Task, ViewMode } from "gantt-task-react";
// import React, { useState } from "react";
import { getStartEndDateForProject, initTasks } from "./Helper.jsx";

import { ViewSwitcher } from "./View.jsx";
import { useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import taskvis from "../../assets/img/profiles/file-chart-2-line.png";
import report from "../../assets/img/profiles/task-board.svg";
import empty from "../../assets/img/profiles/browser.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";

const TaskChart = () => {
  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const navigate = useNavigate();
  let columnWidth = 65;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
  console.log(tasks);
  console.log(new Date("2023-01-02"));

  const handleTaskChange = (task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleSelect = (task, isSelected) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };
  const currentDate = new Date();

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/ganttchart/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
      })
      .then((res) => {
        console.log(res.data);
        // if (res.data.length === 0) {
        //   setTasks([
        //     {
        //       start: new Date(
        //         currentDate.getFullYear(),
        //         currentDate.getMonth(),
        //         1
        //       ),
        //       end: new Date(
        //         currentDate.getFullYear(),
        //         currentDate.getMonth(),
        //         15
        //       ),
        //       name: "Some Project",
        //       id: "ProjectSample",
        //       progress: 25,
        //       type: "project",
        //       hideChildren: false,
        //       displayOrder: 1,
        //     },
        //   ]);
        // } else {
        setTasks(
          res.data.map((ele) => ({
            // start: new Date(
            //   parseInt(ele.start_date.split("-")[0]),
            //   parseInt(ele.start_date.split("-")[1]),
            //   parseInt( parseInt(ele.start_date.split("-")[2])===01?ele.start_date.split("")[2][1])
            //   // 15
            // ),
            // end: new Date(
            //   parseInt(ele.end_date.split("-")[0]),
            //   parseInt(ele.end_date.split("-")[1]),
            //   parseInt(ele.end_date.split("-")[2])
            //   30
            // ),
            // start: new Date(
            //   currentDate.getFullYear(),
            //   currentDate.getMonth(),
            //   1
            // ),
            // end: new Date(
            //   currentDate.getFullYear(),
            //   currentDate.getMonth(),
            //   15
            // ),
            start: new Date(
              parseInt(ele.start_date.split("-")[0]),
              parseInt(ele.start_date.split("-")[1]),
              ele.start_date.split("-")[2] === "01"
                ? 1
                : ele.start_date.split("-")[2] === "02"
                ? 2
                : ele.start_date.split("-")[2] === "03"
                ? 3
                : ele.start_date.split("-")[2] === "04"
                ? 4
                : ele.start_date.split("-")[2] === "05"
                ? 5
                : ele.start_date.split("-")[2] === "06"
                ? 6
                : ele.start_date.split("-")[2] === "07"
                ? 7
                : ele.start_date.split("-")[2] === "08"
                ? 8
                : ele.start_date.split("-")[2] === "09"
                ? 9
                : parseInt(ele.start_date.split("-")[2])
            ),
            end: new Date(
              parseInt(ele.end_date.split("-")[0]),
              parseInt(ele.end_date.split("-")[1]),
              ele.end_date.split("-")[2] === "01"
                ? 1
                : ele.end_date.split("-")[2] === "02"
                ? 2
                : ele.end_date.split("-")[2] === "03"
                ? 3
                : ele.end_date.split("-")[2] === "04"
                ? 4
                : ele.end_date.split("-")[2] === "05"
                ? 5
                : ele.end_date.split("-")[2] === "06"
                ? 6
                : ele.end_date.split("-")[2] === "07"
                ? 7
                : ele.end_date.split("-")[2] === "08"
                ? 8
                : ele.end_date.split("-")[2] === "09"
                ? 9
                : parseInt(ele.end_date.split("-")[2])
            ),
            start_day:
              ele.start_date.split("-")[0] === "01"
                ? 1
                : ele.start_date.split("-")[0] === "02"
                ? 2
                : ele.start_date.split("-")[0] === "03"
                ? 3
                : ele.start_date.split("-")[0] === "04"
                ? 4
                : ele.start_date.split("-")[0] === "05"
                ? 5
                : ele.start_date.split("-")[0] === "06"
                ? 6
                : ele.start_date.split("-")[0] === "07"
                ? 7
                : ele.start_date.split("-")[0] === "08"
                ? 8
                : ele.start_date.split("-")[0] === "09"
                ? 9
                : parseInt(ele.start_date.split("-")[0]),
            start_m: parseInt(ele.start_date.split("-")[1]),
            start_y: parseInt(ele.start_date.split("-")[2]),
            end_day:
              ele.end_date.split("-")[0] === "01"
                ? 1
                : ele.end_date.split("-")[0] === "02"
                ? 2
                : ele.end_date.split("-")[0] === "03"
                ? 3
                : ele.end_date.split("-")[0] === "04"
                ? 4
                : ele.end_date.split("-")[0] === "05"
                ? 5
                : ele.end_date.split("-")[0] === "06"
                ? 6
                : ele.end_date.split("-")[0] === "07"
                ? 7
                : ele.end_date.split("-")[0] === "08"
                ? 8
                : ele.end_date.split("-")[0] === "09"
                ? 9
                : parseInt(ele.end_date.split("-")[0]),
            end_m: parseInt(ele.end_date.split("-")[1]),
            end_y: parseInt(ele.end_date.split("-")[2]),
            ...ele,
          }))

          // .map((item) => ({
          //   ...item,
          //   start: new Date(item.start_y, item.start_m, item.start_day),
          //   end: new Date(item.end_y, item.end_m, item.end_date),
          // }))
        );
        setIsLoading(false);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Task Visualization</h3>
                {/* <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/app/main/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Leaves</li>
              </ul> */}
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <Tooltip title="Task Board">
                    <div
                      // to="/attendance-grid"
                      className={"grid-view btn btn-link "}
                      // onClick={() => setShowTable(false)}
                    >
                      {/* <i className="fa fa-th" /> */}
                      <img
                        src={report}
                        onClick={() => navigate("/taskboard-Grid")}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Tooltip>
                  &nbsp;&nbsp;
                  <Tooltip title="Task Report">
                    <div
                      // to="/attendance-grid"
                      className={"grid-view btn btn-link "}
                      // onClick={() => setShowTable(false)}
                    >
                      {/* <i className="fa fa-th" /> */}
                      <img
                        src={taskvis}
                        onClick={() => navigate("/task-report")}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Tooltip>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Content Starts */}
          {tasks.length === 0 ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                className="card "
                style={{
                  height: "300px",
                  width: "350px",
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  src={empty}
                />
                <br />
                <h4>There is no task for you</h4>
                <h6>Go and create task </h6>
                <br />
                <IconButton
                  sx={{ color: "#3298DB" }}
                  data-bs-toggle="modal"
                  data-bs-target="#create_project"
                >
                  <AddCircleOutlineIcon size="medium" />
                </IconButton>
              </div>
            </div>
          ) : (
            <>
              <ViewSwitcher
                onViewModeChange={(viewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
                view={view}
                setView={setView}
              />
              <Gantt
                tasks={tasks}
                viewMode={view}
                onDateChange={handleTaskChange}
                onDelete={handleTaskDelete}
                onProgressChange={handleProgressChange}
                onDoubleClick={handleDblClick}
                onSelect={handleSelect}
                onExpanderClick={handleExpanderClick}
                listCellWidth={isChecked ? "155px" : ""}
                // ganttHeight={}
                columnWidth={columnWidth}
              />
            </>
          )}

          {/* /Content End */}
        </div>
        {/* /Page Content */}
      </div>
    </div>
  );
};
export default TaskChart;
