import React from "react";
import NewTheme from "../components/NewTheme";

const Theme = (props) => {
  return (
    <div
      id="change_themes"
      className="modal custom-modal fade  "
      role="dialog"
      style={{ opacity: "1" }}
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Change Wallpaper</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <NewTheme handleChange={props.handleChange} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme;
