import React, { useState, useEffect, useMemo } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import Header from "../../../pages/Sidebar/header";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import FilterOff from "../../../assets/img/profiles/filter-off-fill1.svg";
//import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "@atlaskit/select";
import moment from "moment";
import { Menu, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useStateManager } from "react-select";

const GeoTagging = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menu, setMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const token = sessionStorage.getItem("access_token");
  const [data, setData] = useState([]);
  const [name1, setName1] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [toggle, setToggle] = useState(false);
  const [empList, setEmpList] = useState([]);
  const [dept, setDept] = useState({});
  const [taggingDetail, setTaggingDetail] = useState({
    emp_id: [],
    from_date: "",
    to_date: "",
  });
  const [editTagging, setEditTagging] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getTaggingData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/geo_tagging_perm/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
          toggle: "p",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        //setData(res.data);
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            joining_date: ele.joining_date
              .substr(0, 10)
              .split("-")
              .reverse()
              .join("-"),
          }))
        );

        setEmpList(
          res.data.map((ele, index) => ({
            label: ele.name,
            value: ele.id,
            department: ele.department,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTaggingData();
  }, []);

  const filteredData = useMemo(() => {
    return data
      .filter((x) => x.name.toLowerCase().includes(name1.toLowerCase()))
      .filter((x) =>
        x.department.toLowerCase().includes(department.toLowerCase())
      )
      .filter((a) =>
        a.designation.toLowerCase().includes(designation.toLowerCase())
      );
  }, [name1, department, designation, data]);

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
  //       params: {
  //         empid: sessionStorage.getItem("emp_id"),
  //       },
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setEmpList(res.data);
  //     });
  // }, []);

  const EmployeeData = useMemo(() => {
    if (dept?.value === undefined) return empList;
    return empList.filter((x) =>
      x.department?.toLowerCase().includes(dept?.value?.toLowerCase())
    );
  }, [empList, dept]);

  const handleTaggingDetail = async (event) => {
    event.preventDefault();
    if (
      taggingDetail.emp_id?.length === 0 ||
      taggingDetail.from_date === "" ||
      (!toggle && taggingDetail.to_date === "")
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/geo_tagging_perm/`,
          {
            coregid: sessionStorage.getItem("coid"),
            empid: taggingDetail.emp_id?.map((ele) => ele.value),
            from_date: taggingDetail.from_date,
            to_date: toggle ? "" : taggingDetail.to_date,
            temp: !toggle,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              // title: "Oops...",
              text: "Geo Tagging added Successfully",
            });
            setTaggingDetail({
              emp_id: [],
              from_date: "",
              to_date: "",
            });
            getTaggingData();
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${
                err.response.data.status[0].toUpperCase() +
                err.response.data.status.slice(1)
              }!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: "Something went Wrong",
            });
          }
        });
    }
  };

  const handleEditTagging = (event) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/geo_tagging_perm/`,
        {
          coregid: sessionStorage.getItem("coid"),
          empid: [editTagging.id],
          from_date: editTagging.from_date,
          // to_date: editTagging.to_date,
          to_date: editTagging.temp ? editTagging.to_date : "",
          temp: editTagging.temp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setEditTagging({});
          getTaggingData();
          Swal.fire({
            icon: "success",
            // title: "Oops...",
            text: "Geo Tagging edited Successfully",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${
              err.response.data.status[0].toUpperCase() +
              err.response.data.status.slice(1)
            }!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Something went Wrong",
          });
        }
      });
  };
  const handleDeleteTagging = () => {
    handleClose();
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/geo_tagging_perm/`,
        {
          // coregid: sessionStorage.getItem("coid"),
          empid: deleteId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        getTaggingData();
        setDeleteId(null);
        Swal.fire({
          icon: "success",
          text: "Geo Tagging Disabled Successfully",
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const handleClick1 = (event, value, index) => {
    setEditTagging(value);
    setDeleteId(index);
    setAnchorEl(event.currentTarget);
  };

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "id",
      sorter: (a, b) => a.emp_id.localeCompare(b.emp_id),
    },
    {
      title: "Name",
      dataIndex: "name",
      // render: (text, record) => (
      //   <h2 className="table-avatar">
      //     <Link to="/app/profile/employee-profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <Link to="/app/profile/employee-profile">
      //       {text} <span>{record.role}</span>
      //     </Link>
      //   </h2>
      // ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.localeCompare(b.designation),
    },
    {
      title: "Joining Date",
      dataIndex: "joining_date",
      sorter: {
        compare: (a, b) =>
          moment(a.joining_date, "DD-MM-YYYY") -
          moment(b.joining_date, "DD-MM_YYYY"),
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === 1
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text === 1 ? "Active" : "InActive"}
          </a>
          {/* <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-purple" /> New
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-info" /> Pending
            </a>
            <a
              className="dropdown-item"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#approve_leave"
            >
              <i className="fa fa-dot-circle-o text-success" /> Approved
            </a>
            <a className="dropdown-item" href="#">
              <i className="fa fa-dot-circle-o text-danger" /> Declined
            </a>
          </div> */}
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <>
          <IconButton
            aria-label="more"
            onClick={(e) => handleClick1(e, text, text.id)}
            aria-haspopup="true"
            aria-controls="long-menu"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            onClose={handleClose}
            open={open}
          >
            {/* <MenuItem
              //   onClick={() => handleViewPorject("view")}
              data-bs-toggle="modal"
              data-bs-target="#edit_project"
            >
              View Tracking
            </MenuItem> */}
            <MenuItem
              onClick={() => handleClose()}
              data-bs-toggle="modal"
              data-bs-target="#edit_tracking"
            >
              {editTagging.status === 1 ? "Edit" : "Add"}
            </MenuItem>
            <MenuItem
              onClick={() => handleDeleteTagging()}
              // data-bs-toggle="modal"
              // data-bs-target="#edit_project"
            >
              Disable Geo Tagging
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Geo Tagging</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                      marginLeft: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_geo_tracking"
                    href="#"
                  >
                    <i className="fa fa-plus" /> &nbsp;Add Geo Tagging
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Search Filter */}
          <div className="row filter-row">
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  inputId="single-select-example"
                  className="single-select"
                  classNamePrefix="react-select"
                  isClearable
                  placeholder="Employee Name"
                  options={[...new Set(data?.map((item) => item.name))].map(
                    (ele) => ({
                      label: ele,
                      value: ele,
                    })
                  )}
                  value={
                    name1 !== ""
                      ? {
                          label: name1,
                          value: name1,
                        }
                      : null
                  }
                  onChange={(e) => setName1(e ? e.value : "")}
                  //className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Department"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.department)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    department !== ""
                      ? {
                          label: department,
                          value: department,
                        }
                      : null
                  }
                  //isClearable={true}
                  isSearchable
                  onChange={(e) => {
                    setDepartment(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    zindex: "5",
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <div className="form-group form-focus select-focus">
                <Select
                  placeholder="Designation"
                  isClearable
                  options={[
                    ...new Set(data?.map((item) => item.designation)),
                  ].map((ele) => ({
                    label: ele,
                    value: ele,
                  }))}
                  value={
                    designation !== ""
                      ? {
                          label: designation,
                          value: designation,
                        }
                      : null
                  }
                  onChange={(e) => {
                    setDesignation(e ? e.value : "");
                  }}
                  className="select floating"
                  style={{
                    height: "50px",
                    width: "100%",
                    border: "1px solid lightgrey",
                    borderRadius: "3px",
                  }}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
              <a
                href="#"
                className="btn-block"
                style={{
                  //fontSize: "14px",
                  height: "38px",
                  background: "#3298DB",
                  //color:"white"
                }}
                onClick={() => (
                  setName1(""), setDepartment(""), setDesignation("")
                )}
              >
                <img
                  tabindex="0"
                  data-bs-toggle="tooltip"
                  title="clear all filter"
                  style={{
                    height: "38px",
                    backgroundColor: "#3298DB",
                    padding: "7px 5px",
                    borderRadius: "5px",
                  }}
                  src={FilterOff}
                />
              </a>
            </div>
          </div>
          {/* /Search Filter */}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                ></div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={{
                        total: filteredData.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      bordered
                      dataSource={filteredData}
                      // rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <div
          id="add_geo_tracking"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Set Geo Tagging Details</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-3">
                      <div className="col-md-12">
                        <label>Department</label>
                        <Select
                          isClearable
                          options={[
                            ...new Set(data?.map((item) => item.department)),
                          ].map((ele) => ({
                            label: ele,
                            value: ele,
                          }))}
                          value={dept}
                          onChange={(e) => setDept(e)}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-5">
                      <div className="col-md-12">
                        <label>
                          Name of Employee<span className="text-danger">*</span>
                        </label>
                        <Select
                          isMulti
                          options={EmployeeData}
                          value={taggingDetail.emp_id}
                          onChange={(e) => {
                            if (e) {
                              setTaggingDetail({
                                ...taggingDetail,
                                emp_id: e.map((item) => item),
                              });
                            } else {
                              setTaggingDetail([]);
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-group col-md-4">
                      <div className="col-md-12">
                        <label>
                          Applicable Type<span className="text-danger">*</span>
                        </label>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <label>Temporary</label> &nbsp;&nbsp;
                          <input
                            type="checkbox"
                            className="check"
                            id="toggleOne"
                            name={toggle}
                            checked={toggle}
                            onChange={() => setToggle(!toggle)}
                          />
                          <label className="checktoggle" htmlFor="toggleOne">
                            checkbox
                          </label>
                          &nbsp;&nbsp;
                          <label>Permanent</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row col-md-12">
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>
                          From Date<span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            // min={new Date().toISOString().split("T")[0]}
                            max={
                              taggingDetail.to_date === ""
                                ? "9999-12-31"
                                : taggingDetail.to_date
                            }
                            type="date"
                            value={taggingDetail.from_date}
                            onChange={(e) =>
                              setTaggingDetail({
                                ...taggingDetail,
                                from_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {!toggle && (
                      <div className="form-group col-md-6">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "25px" }}
                        >
                          {" "}
                          <label>
                            To Date<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              disabled={
                                taggingDetail.from_date === "" ? true : false
                              }
                              min={taggingDetail.from_date}
                              max={"9999-12-31"}
                              value={taggingDetail.to_date}
                              className="form-control datetimepicker"
                              type="date"
                              onChange={(e) =>
                                setTaggingDetail({
                                  ...taggingDetail,
                                  to_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss={
                        !taggingDetail.from_date ||
                        taggingDetail.emp_id.length === 0
                          ? ""
                          : "modal"
                      }
                      style={{
                        background: "#3298DB",
                        color: "white",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      onClick={(event) => handleTaggingDetail(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* edit geo tagging */}
        <div
          id="edit_tracking"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>
                    {editTagging.status === 1
                      ? "Edit Geo Location Details"
                      : "Add Geo Location Details"}
                  </b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        <label>
                          Name of Employee<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          value={editTagging.name}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <div className="col-md-12" style={{ marginLeft: "25px" }}>
                        <label>
                          Applicable Type<span className="text-danger">*</span>
                        </label>
                        <div style={{ display: "flex", marginTop: "10px" }}>
                          <label>Temporary</label> &nbsp;&nbsp;
                          <input
                            type="checkbox"
                            className="check"
                            id="toggleTne"
                            name={editTagging.temp}
                            checked={!editTagging.temp}
                            onChange={() =>
                              setEditTagging({
                                ...editTagging,
                                temp: !editTagging.temp,
                              })
                            }
                          />
                          <label className="checktoggle" htmlFor="toggleTne">
                            checkbox
                          </label>
                          &nbsp;&nbsp;
                          <label>Permanent</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row col-md-12">
                    <div className="form-group col-md-6">
                      <div className="col-md-12">
                        {" "}
                        <label>
                          From Date<span className="text-danger">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            // min={new Date().toISOString().split("T")[0]}
                            // max={
                            //   editTagging.to_date === ""
                            //     ? "9999-12-31"
                            //     : editTagging.to_date
                            // }
                            type="date"
                            value={editTagging.from_date}
                            onChange={(e) =>
                              setEditTagging({
                                ...editTagging,
                                from_date: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {editTagging.temp && (
                      <div className="form-group col-md-6">
                        <div
                          className="col-md-12"
                          style={{ marginLeft: "25px" }}
                        >
                          {" "}
                          <label>
                            To Date<span className="text-danger">*</span>
                          </label>
                          <div>
                            <input
                              // disabled={
                              //   editTagging.from_date === "" ? true : false
                              // }
                              // min={editTagging.from_date}
                              // max={"9999-12-31"}
                              value={editTagging.to_date}
                              className="form-control datetimepicker"
                              type="date"
                              onChange={(e) =>
                                setEditTagging({
                                  ...editTagging,
                                  to_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss="modal"
                      style={{
                        background: "#3298DB",
                        color: "white",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                      onClick={(event) => handleEditTagging(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoTagging;
