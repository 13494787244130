import React from 'react'
import { CanvasSketchTool } from 'react-arts';
import { Link } from 'react-router-dom';

function SketchBoard() {
    return (
        <div style={{
            textAlign: 'center',
            fontFamily: 'Roboto',
            background: 'linear-gradient(0deg, rgba(46, 204, 113, 1) 0%, rgba(255, 255, 255, 1) 100%)',
            height: '100vh'
        }}
        >
            <div style={{ textAlign: 'left', padding: '16px' }}>
                <Link to='/happyzone-two'>
                <button type="button" className="btn btn-warning">
                    Back to Happy Zone
                </button>
                </Link>
            </div>

            <h2 style={{ color: '#186A3B' }}>Sketch Board</h2>

            <CanvasSketchTool
                height={450}
                width={750}
                minWidth={10}
            />
        </div>
    )
}

export default SketchBoard
