import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import moment from "moment";
import Rating from "@mui/material/Rating";
import Swal from "sweetalert2";

const ReviewForm = (props) => {
  const { setDisplayPage } = props;
  const [belowEmp, setBelowEmp] = useState([]);
  const [reviewForm, setReviewForm] = useState({});
  const [reviewField, setReviewField] = useState([]);
  const [value, setValue] = useState(2);
  const today = moment();
  const [review, setReview] = useState({
    name: { label: null, value: null },
    comment: "",
  });
  const rating = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
  ];

  const handleFormChange = (index, event, state, setState) => {
    let data = [...state];
    data[index]["answer"] = event.target.value;
    setState(data);
  };

  const handleFormChange1 = (index, event, state, setState, value) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["comment"] = event.target.value;
    // data[index][event] = event.value;
    setState(data);
  };
  console.log(reviewField);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/below_emp/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
        },
      })
      .then((res) => {
        setBelowEmp(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          toggle: "a",
        },
      })
      .then((res) => {
        setReviewForm(res.data);
        setReviewField(
          res.data?.questions.map((ele) => ({
            id: ele.id,
            question: ele.question,
            answer: "",
            comment: "",
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlesubmit = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        empid: JSON.parse(sessionStorage.getItem("emp_id")),
        to_empid: review.name.value,
        toggle: "q",
        review_id: reviewForm.questions[0].review_id,
        comment: review.comment,
        review: reviewField.map((ele) => ({
          id: ele.id,
          answer: ele.answer,
          comment: ele.comment,
        })),
      })
      .then((res) => {
        setDisplayPage("submitted");
        Swal.fire({
          icon: "success",
          text: "Review Submitted successfully",
        });
        //setReview("");
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${
              err.response.data.message[0].toUpperCase() +
              err.response.data.message.slice(1)
            }!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  return (
    <div>
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col"></div>
          </div>
        </div>
        {/* <div className="form-group row">
          <div className="col-lg-9">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="general"
                value={SelectReview}
                              onChange={() => setSelectReview("general")}
                              defaultChecked
              />
              <label className="form-check-label" htmlFor="general">
                General Review
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="gender"
                id="kra"
                value={SelectReview}
                onChange={() => setSelectReview("kra")}
              />
              <label className="form-check-label" htmlFor="kra">
                KRA Based Review
              </label>
            </div>
          </div>
          <div className="col-auto float-end ml-auto">
            <div className="view-icons">
              <input
                className="form-check-input"
                type="radio"
                name="anoymous"
                id="anonymous"
              />
              &nbsp;&nbsp;
              <label className="form-check-label" htmlFor="anonymous">
                Be Anonymous
              </label>
            </div>
          </div>
        </div> */}
        {/* /Page Header */}
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ padding: "20px" }}>
              <div className="row">
                <h4 style={{ textAlign: "center" }}>Review Form</h4>
                <hr
                  style={{
                    boxSizing: "border-box",
                    height: "4px",
                  }}
                />
                <p style={{ fontSize: "12px" }}>
                  <span className="text-danger">*</span>
                  IMPORTANT: Please note that this review uses a 1-5 scale where
                  1 is the lowest and 5 is the highest. When answering the
                  questions, please consider this scale and select the number
                  that best reflects your opinion or experience
                </p>
                <div className="form-group col-md-3">
                  {/* <label>Employee Name</label>
                  <input type="text" className="form-control" /> */}
                </div>
                <div className="form-group col-md-3">
                  <label>Employee Name</label>
                  <Select
                    //className="form-control"
                    options={belowEmp}
                    value={review.name}
                    onChange={(e) =>
                      setReview({
                        ...review,
                        name: e,
                      })
                    }
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={today.format("YYYY-MM-DD")}
                  />
                </div>
                <div className="form-group col-md-3">
                  {/* <label>Date</label>
                  <input type="text" className="form-control" /> */}
                </div>
              </div>
            </div>

            <h5 className="page-title" style={{ fontSize: "16px" }}>
              {reviewForm?.form_name}
            </h5>
            <br />
            <div className="card" style={{ padding: "20px" }}>
              <div className="col-xl-12">
                {reviewForm?.questions?.map((ele, index) => {
                  return (
                    <div className="row" key={index}>
                      <label className="col-lg-4 col-form-label">
                        {ele.question}
                      </label>
                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="form-group">
                              {/* <input
                              type="text"
                              placeholder="First Name"
                              className="form-control"
                            /> */}

                              {/* <Select
                                placeholder="Select Rating"
                                options={rating}
                                value={reviewField.answer}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    reviewField,
                                    setReviewField
                                  )
                                }
                              /> */}
                              <Rating
                                name="simple-controlled"
                                value={reviewField.answer}
                                // value={value}
                                // onChange={(event, newValue) => {
                                //   setValue(newValue);
                                // }}
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    reviewField,
                                    setReviewField
                                  )
                                }
                              />
                            </div>
                          </div>
                          {sessionStorage.getItem("coid") !== "RAWJ225129" && (
                            <div className="col-md-9">
                              <div className="form-group">
                                <input
                                  name="comment"
                                  type="text"
                                  placeholder="Comment"
                                  className="form-control"
                                  value={reviewField.comment}
                                  onChange={(event) =>
                                    handleFormChange1(
                                      index,
                                      event,
                                      reviewField,
                                      setReviewField
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {sessionStorage.getItem("coid") !== "RAWJ225129" && (
              <>
                <h5 className="page-title" style={{ fontSize: "16px" }}>
                  Additional Comments
                </h5>
                <br />
                <div className="card" style={{ padding: "20px" }}>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <textarea
                        type="text"
                        rows={30}
                        className="form-control"
                        value={review.comment}
                        onChange={(e) =>
                          setReview({
                            ...review,
                            comment: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div style={{ textAlign: "center", margin: "auto" }}>
              <button
                className="modal-submit-btn"
                onClick={() => handlesubmit()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewForm;
