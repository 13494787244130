import React, { useEffect, useState, useCallback } from "react";
import Header from "../../pages/Sidebar/header";
import Sidebar from "../../pages/Sidebar/sidebar";
import Calendar from "../Calender/calendar";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import row from "../../assets/img/profiles/Vaibhav_icon.png";
import back from "../../assets/img/profiles/animation_500_lfw79k6c.gif";
import nob from "../../assets/img/profiles/no-birthday.svg";
import noa from "../../assets/img/profiles/no-work-anniversary.svg";
import noe from "../../assets/img/profiles/no-new-employee.svg";
import info from "../../assets/img/profiles/info.svg";
import { Helmet } from "react-helmet";
import {
  Box,
  Typography,
  Grid,
  Stack,
  Avatar,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  useMediaQuery,
  Toolbar,
} from "@mui/material";
import {
  SBox1,
  SBox2,
  SBox3,
  SButton,
  SButton2,
  STypography,
} from "./DashboardCSS";
import axios from "axios";
import Swal from "sweetalert2";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";

//import { daily, newEmployee } from "../../Constants/overviewConst.js";
//import sample from "../../../src/assets/img/profiles/sample.jpg";
//import Select from "react-select";
//import { css, jsx } from "@emotion/react";
import welcomeImage from "../../assets/img/profiles/Banner 1.png";
import { height } from "@mui/system";
//import Button, { ButtonGroup } from "@atlaskit/button";
//import Modal, { ModalTransition } from "@atlaskit/modal-dialog";
//import { Table } from "antd";
//import "antd/dist/antd.css";
//import "../../antdstyle.css";
//import "./Dashboard.css";

/*
const containerStyles = css({
  padding: "40px 44px 36px",
  textAlign: "center",
});

const headerStyles = css({
  marginBottom: 8,
  color: "inherit",
  fontSize: 20,
  fontStyle: "inherit",
  fontWeight: 500,
  letterSpacing: "-0.008em",
  lineHeight: 1.2,
});
*/

//const marginBottomStyles = css({ marginBottom: 40 });

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "500px",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  maxWidth: 500,
  minWidth: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  // maxWidth: 500,
  // minWidth: 250,
  width: "500px",
  padding: "none",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  // backgroundColor: "rgba(0, 0, 0, 0.5)",
  // p: 4,
  overflow: "auto",
};

const Dashboard = ({ handleChange, backImg, setBackImg }) => {
  const [menu, setMenu] = useState(false);
  const [showHoliday, setShowHoliday] = useState(false);
  const [overviewinfo, setOverviewinfo] = useState({});
  const [data, setData] = useState([]);
  const [dailyfeed, setDailyfeed] = useState([]);
  const [healthtips, setHealthtips] = useState([]);
  const [newmember, setNewmember] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [work, setWork] = useState([]);
  const [quote, setQuote] = useState("");
  const [wishe, setWishe] = useState({
    type: "birthday" / "work_anniverary",
    emp_id_wish: "",
    emp_id_wisher: "",
    message: "",
    wish_name: "",
  });
  const [isDialogOpen, setDialogOpen] = useState(
    JSON.parse(sessionStorage.getItem("desigToggle"))
  );

  const closeDialog = (desig) => {
    sessionStorage.setItem("designation", desig);
    sessionStorage.setItem("desigToggle", false);
    setDialogOpen(false);
  };
  //const openModal = useCallback(() => setIsOpen(true), [])
  //const closeModal = useCallback(() => setIsOpen(false), [])
  const [isOpen, setIsOpen] = useState(
    sessionStorage.getItem("coid") === "RAWJ225129"
      ? JSON.parse(sessionStorage.getItem("isopen"))
      : false
  );
  const token = sessionStorage.getItem("access_token");
  const [openmodal, setOpenmodal] = useState(false);
  const handleModalOpen = () => setOpenmodal(true);
  const handleModalClose = () => setOpenmodal(false);
  const handleModalClose1 = () => {
    sessionStorage.setItem("isopen", false);
    setIsOpen(false);
  };
  const [designation, setDesignation] = useState(
    JSON.parse(sessionStorage.getItem("designations"))
  );

  const matcheslg = useMediaQuery("(max-width:899px)");
  const matchesxlexact = useMediaQuery("(max-width:991.9px)");

  // useEffect(() => {
  //   axios.get(`https://quotes.rest/qod?language=en`).then((res) => {
  //     setQuote(res.data.contents.quotes[0].quote);
  //   });
  // }, []);
  // console.log(quote);

  const GetHolidays = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/holidays_list/`,
        {
          params: { empid: sessionStorage.getItem("emp_id") },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const wishThem = async (type, id) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/wishes/`,
        {
          type: wishe.type,
          wish_to: wishe.emp_id_wish,
          wish_by: parseInt(sessionStorage.getItem("emp_id")),
          message: wishe.message,
        },
        {
          // params: {
          //   empid: sessionStorage.getItem("emp_id"),
          // },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setWishe({
          type: "birthday" / "work_anniverary",
          emp_id_wish: "",
          emp_id_wisher: "",
          message: "",
          wish_name: "",
        });
        Swal.fire({
          icon: "success",
          text: "Awesome! Your good wishes have been conveyed!",
          timer: 4000,
          showConfirmButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: `${
          err.response.data.status[0].toUpperCase() +
          err.response.data.status.slice(1)
        }!`,
      });
    }
  };

  const GetData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/overview/`,
        {
          params: {
            empid: sessionStorage.getItem("emp_id"),
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res);
      setOverviewinfo(res.data);
      setQuote(res.data.quote);
      setDailyfeed(res.data.daily_feed);
      setHealthtips(res.data.heath_tips);
      setNewmember(res.data.new_members);
      setBirthdays(res.data.birthdays);
      setWork(res.data.work_anniversary);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetHolidays();
    GetData();
  }, []);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  /*
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      //   sorter: (a, b) => a.sno.length - b.sno.length,
    },
    {
      title: "Holiday",
      dataIndex: "name",
      //   sorter: (a, b) => a.date.length - b.date.length,
    },
  ];

  // useEffect(() => {
  //   openModal();
  // }, []);
  */

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header
        onMenuClick={(value) => toggleMobileMenu()}
        handleChange={handleChange}
        backImg={backImg}
        setBackImg={setBackImg}
      />

      <Sidebar />
      <Helmet>
        <title>OZ-HRMS</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <div className="page-wrapper">
        {/*
        <div>
          {showHoliday && (
            <div className="holiday-list">
              <div className="modal-content">
                <Box sx={{width:'500px', minWidth:'200px'}}>
                  A
                </Box>
                {/*
                <div
                  className="modal-header"
                  style={{ backgroundColor: "#2DB8C5", color: "white" }}
                >
                  <h5
                    className="modal-title"
                    style={{ backgroundColor: "#2DB8C5", color: "white" }}
                  >
                    List Of Holidays
                  </h5>
                </div>
                <div className="modal-body-list">
                  <Table
                    style={{ overflowX: "auto" }}
                    showHeader={false}
                    columns={columns}
                    // bordered
                    dataSource={data}
                    pagination={false}
                  // onChange={this.handleTableChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        */}

        {/** 
       
            <Modal onClose={closeModal}>
              <img
                alt="Graphic showing users working on a project"
                src={welcomeImage}
              />
              <div className="containerStyles">
                <h4 className="headerStyles">
                  <b>{`Happy New Year  ${sessionStorage.getItem("name")} !`}</b>
                </h4>
                <p>
                  OZ HRMS wishes you and your family, a very happy new year. May
                  this new year be filled with new adventures and good fortune.
                </p>
                <p className="marginBottomStyles">
                  Best wishes for peace and prosperity !
                </p>
                  <Button onClick={closeModal} appearance="primary" autoFocus>
                    Thank You
                  </Button>
              </div>
            </Modal>
          )}
        </ModalTransition>
      */}
        <Toolbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            m: 1,
            mb: 3,
            mt: -4,
          }}
        >
          <Typography variant="h5" sx={{ p: 0.5, color: "#FFFFFF" }}>
            Overview
          </Typography>

          {/* <SButton data-bs-toggle="modal" data-bs-target="#change_theme">
            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              Change Theme
            </Typography>
          </SButton> */}
        </Box>

        <Grid container spacing={2} sx={{ p: 1 }}>
          {matcheslg ? (
            <Grid item xs={12}>
              <SBox1 sx={{ boxShadow: 3, height: "150px" }}>
                <Box textAlign="left" sx={{ maxHeight: 150, overflow: "auto" }}>
                  <Typography variant="h6">
                    Welcome, &nbsp;
                    <i>
                      {sessionStorage.getItem("name")
                        ? sessionStorage.getItem("name").split(" ")[0]
                        : "User"}
                    </i>
                    &nbsp; !
                  </Typography>

                  <Typography variant="body1">
                    {quote !== ""
                      ? `${quote.quote}`
                      : `There are no secrets to success. It is the result of
                      preparation, hardwork and learning from failure!`}
                  </Typography>
                </Box>
              </SBox1>
            </Grid>
          ) : null}

          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box
              sx={{
                minHeight: "645px",
                background: "#FFFFFF",
                borderRadius: "8px",
              }}
            >
              <Calendar />
            </Box>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Grid container spacing={2} direction="column">
              {!matcheslg ? (
                <Grid item xs={12}>
                  <SBox1 sx={{ boxShadow: 3, height: "150px" }}>
                    <Box
                      textAlign="left"
                      sx={{ maxHeight: 150, overflow: "auto" }}
                    >
                      <STypography
                        variant="h6"
                        sx={{ fontSize: "20px", mb: 1 }}
                      >
                        <div style={{ display: "flex" }}>
                          Welcome,&nbsp;
                          <i>
                            {sessionStorage.getItem("name")
                              ? sessionStorage.getItem("name").split(" ")[0]
                              : "User"}
                          </i>{" "}
                          &nbsp; !
                        </div>
                      </STypography>
                      {/*{overviewinfo.name ? `${overviewinfo.name}!` : "User!"}*/}

                      <STypography variant="body2">
                        <p className="quote">
                          {" "}
                          {quote !== ""
                            ? `${quote.quote}`
                            : `There are no secrets to success. It is the result of
                        preparation, hardwork and learning from failure!`}
                        </p>
                      </STypography>
                    </Box>
                  </SBox1>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <SBox1
                  sx={{
                    boxShadow: 3,
                    height: "227px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <STypography variant="h6" sx={{ fontSize: "20px", mb: 1 }}>
                    <b>OZ Assist</b>
                  </STypography>

                  <Box
                    textAlign="left"
                    sx={{ maxHeight: 200, overflow: "auto" }}
                  >
                    <STypography
                      variant="body2"
                      sx={{ mb: 1 }}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "3px",
                        }}
                        src={row}
                      />
                      &nbsp;&nbsp;
                      <p>
                        <b>Shift :</b>{" "}
                        {`${overviewinfo.shift_name} (
                          ${overviewinfo.shift_from} - ${overviewinfo.shift_to} )`}
                      </p>
                    </STypography>
                    {dailyfeed.map((ele) => {
                      return (
                        <>
                          <STypography
                            variant="body2"
                            sx={{ mb: 1 }}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            {/* <ArrowRightIcon fontSize="small" /> */}
                            {/* <div className="round"></div> &nbsp; &nbsp; */}
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "3px",
                              }}
                              src={row}
                            />
                            &nbsp;&nbsp;
                            <p>{ele.feed}</p>
                          </STypography>
                        </>
                      );
                    })}
                  </Box>
                </SBox1>
              </Grid>

              <Grid item xs={12}>
                <SBox1
                  sx={{
                    boxShadow: 3,
                    height: "235px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <STypography variant="h6" sx={{ fontSize: "20px", mb: 1 }}>
                    <b> New Member(s)</b>
                  </STypography>
                  <Box
                    textAlign="left"
                    sx={{ maxHeight: 230, overflow: "auto", width: "100%" }}
                  >
                    {newmember?.length !== 0 ? (
                      <>
                        {newmember?.map((ele) => {
                          return (
                            <Stack
                              direction="row "
                              style={{
                                justifyContent: "space-between",
                                paddingRight: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Avatar
                                  src={ele.profile_img.links}
                                  sx={{ width: 30, height: 30 }}
                                />
                                &nbsp;
                                <STypography
                                  variant="subtitle1"
                                  sx={{ ml: 0.5, mb: 0.2 }}
                                >
                                  {ele.name?.split(" ")[0][0]?.toUpperCase() +
                                    ele.name?.split(" ")[0]?.slice(1)}{" "}
                                  {ele.name?.split(" ")?.length > 2
                                    ? ele.name?.split(" ")[2]
                                    : null}
                                  <br />
                                  <STypography
                                    variant="caption"
                                    sx={{
                                      color: "#000000",
                                      background: "#FFFFFF",
                                      pl: 0.2,
                                      pr: 0.2,
                                      borderRadius: 1,
                                    }}
                                  >
                                    {ele.role}
                                  </STypography>
                                </STypography>
                              </div>
                              <SButton2
                                data-bs-target="#wish"
                                data-bs-toggle="modal"
                              >
                                <STypography
                                  variant="caption"
                                  sx={{
                                    color: "#FFFFFF",
                                    minWidth: "50px",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    setWishe({
                                      type: "work_annivesary",

                                      name: ele.name,
                                      emp_id_wish: ele.id,
                                      emp_id_wisher:
                                        sessionStorage.getItem("emp_id"),
                                      message: "",
                                      wish_name: `Let's give a warm welcome to our newest member, ${ele.name}!`,
                                    })
                                  }
                                >
                                  Wish
                                </STypography>
                              </SButton2>
                            </Stack>
                          );
                        })}
                      </>
                    ) : (
                      <div
                        style={{
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img src={noe} style={{ height: "80px" }} />
                      </div>
                    )}
                  </Box>
                </SBox1>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            lg={4}
            md={6}
            sm={12}
            xs={12}
            // style={{ backgroundImage: { back } }}
          >
            <SBox2 sx={{ boxShadow: 3 }}>
              <STypography variant="h6" sx={{ fontSize: "20px", mb: 1 }}>
                <b>Birthday(s)</b>
              </STypography>

              <Box
                textAlign="left"
                sx={{
                  padding: "10px",
                  maxHeight: 180,
                  minHeight: 180,
                  overflow: "auto",
                  backgroundImage: { back },
                }}
                className="birt-div"
              >
                {birthdays?.length !== 0 ? (
                  <>
                    {birthdays.map((ele) => {
                      return (
                        <Stack direction="row" sx={{ marginBottom: "5px" }}>
                          <Avatar
                            src={ele.profile_img.links}
                            sx={{ width: 30, height: 30 }}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <SBox3>
                            <STypography variant="subtitle1" sx={{ ml: 0.5 }}>
                              {ele.name.split(" ")[0]}{" "}
                              {ele.name.split(" ").length > 2
                                ? ele.name.split(" ")[2]
                                : null}
                              &nbsp;
                              <br />
                              <STypography
                                variant="caption"
                                sx={{
                                  color: "#000000",
                                  background: "#c4c4c4",
                                  // pl: 0.2,
                                  // pr: 0.2,
                                  borderRadius: 1,
                                  padding: "2px",
                                  marginBottom: "5px",
                                }}
                              >
                                {ele.role}
                              </STypography>
                            </STypography>

                            <SButton2
                              data-bs-target="#wish"
                              data-bs-toggle="modal"
                            >
                              <STypography
                                variant="caption"
                                sx={{
                                  color: "#FFFFFF",
                                  minWidth: "50px",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() =>
                                  setWishe({
                                    type: "bithday",

                                    name: ele.name,
                                    emp_id_wish: ele.id,
                                    emp_id_wisher:
                                      sessionStorage.getItem("emp_id"),
                                    message: "",
                                    wish_name: `Wish ${ele.name} Happy Birthday`,
                                  })
                                }
                              >
                                Wish
                              </STypography>
                            </SButton2>
                          </SBox3>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={nob} style={{ height: "80px" }} />
                    <br />
                    <p>No birthdays today!</p>
                  </div>
                )}
              </Box>
            </SBox2>
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SBox2 sx={{ boxShadow: 3 }}>
              <STypography variant="h6" sx={{ fontSize: "20px", mb: 1 }}>
                <b> Work Anniversaries</b>
              </STypography>
              <Box
                sx={{
                  maxHeight: 180,
                  overflow: "auto",
                  textAlign: "left",
                  paddingTop: "10px",
                }}
              >
                {work?.length !== 0 ? (
                  <>
                    {work.map((ele) => {
                      return (
                        <Stack direction="row">
                          <Avatar
                            src={ele.profile_img.links}
                            sx={{ width: 30, height: 30 }}
                          />
                          <SBox3>
                            <STypography variant="subtitle1" sx={{ ml: 0.5 }}>
                              {ele.name.split(" ")[0]}{" "}
                              {ele.name.split(" ").length > 2
                                ? ele.name.split(" ")[2]
                                : null}
                              &nbsp;
                              <br />
                              <STypography
                                variant="caption"
                                sx={{
                                  color: "#000000",
                                  background: "#FFFFFF",
                                  pl: 0.2,
                                  pr: 0.2,
                                  borderRadius: 1,
                                }}
                              >
                                {ele.role}
                              </STypography>
                            </STypography>

                            <SButton2
                              data-bs-target="#wish"
                              data-bs-toggle="modal"
                            >
                              <STypography
                                variant="caption"
                                sx={{
                                  color: "#FFFFFF",
                                  minWidth: "50px",
                                  whiteSpace: "nowrap",
                                }}
                                onClick={() =>
                                  setWishe({
                                    type: "work_annivesary",

                                    name: ele.name,
                                    emp_id_wish: ele.id,
                                    emp_id_wisher:
                                      sessionStorage.getItem("emp_id"),
                                    message: "",
                                    wish_name: `Wish ${ele.name} on Work Anniversary`,
                                  })
                                }
                              >
                                Wish
                              </STypography>
                            </SButton2>
                          </SBox3>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img src={noa} style={{ height: "80px" }} />
                    <br />
                    <p>No work anniversaries today!</p>
                  </div>
                )}
              </Box>
            </SBox2>
          </Grid>

          <Grid item lg={4} md={6} sm={12} xs={12}>
            <SBox2 sx={{ boxShadow: 3 }}>
              <Typography variant="h6" sx={{ fontSize: "20px", mb: 1 }}>
                <b>Health Tips</b>
              </Typography>
              <Box sx={{ maxHeight: 180, overflow: "auto", textAlign: "left" }}>
                {healthtips.map((ele) => {
                  return (
                    <>
                      <STypography
                        variant="body2"
                        sx={{ mb: 1 }}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        {/* <ArrowRightIcon fontSize="small" /> */}
                        {/* <div className="round"></div> &nbsp; &nbsp; */}
                        <img
                          style={{
                            width: "15px",
                            height: "15px",
                            marginTop: "3px",
                          }}
                          src={row}
                        />
                        &nbsp;&nbsp;
                        <p>{ele.tips}</p>
                      </STypography>
                    </>
                  );
                })}
              </Box>
            </SBox2>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={openmodal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              float: "right",
              marginTop: "-15px",
              cursor: "pointer",
              border: "none",
              background: "none",
              marginRight: "-20px",
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button>
          <br />

          <STypography variant="h5">List of holidays</STypography>
          <br />

          <TableContainer>
            <Table
              sx={{ minWidth: 200 }}
              size="small"
              aria-label="a dense table"
            >
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ background: "#FFFFFF" }}
                    >
                      <STypography variant="body1">{row.name}</STypography>
                    </TableCell>

                    <TableCell
                      align="right"
                      sx={{ background: "#FFFFFF", textAlign: "left" }}
                    >
                      <STypography variant="body1">{row.date}</STypography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
      <Modal
        open={isOpen}
        onClose={handleModalClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          {/* <button
            style={{
              float: "right",
          
              cursor: "pointer",
              border: "none",
              background: "none",
             
            }}
            type="button"
            onClick={handleModalClose}
          >
            <img src={closeIcon} />
          </button> */}
          <img src={welcomeImage} style={{ width: "500px", height: "auto" }} />
        </Box>
      </Modal>

      <div id="wish" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              {/* <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                Edit Leave
              </h5> */}
              {/* <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button> */}
            </div>
            <div className="modal-body wish-div">
              <br />
              <br />
              <h3 style={{ maxWidth: "450px" }}>{wishe.wish_name}</h3>
              <br />
              <textarea
                style={{ width: "50%", minHeight: "50px", borderRadius: "8px" }}
                placeholder="Type your wishes"
                onChange={(e) =>
                  setWishe({ ...wishe, message: e.target.value })
                }
              />
              <br />
              <br />
              <button
                className="wish-btn"
                data-bs-dismiss="modal"
                onClick={() => wishThem()}
              >
                Send wishes
              </button>
            </div>
          </div>
        </div>
      </div>

      {isDialogOpen && designation?.length > 1 && (
        <div className="dialog-overlay">
          <div className="dialog-content">
            {/* <span onClick={closeDialog} className="close-button">
              close
            </span> */}
            <div className="dialog-header">
              <p
                style={{
                  color: "#fff",
                  paddingTop: "15px",
                  fontWeight: "50",
                  fontSize: "20px",
                }}
              >
                Select Job Profile*
              </p>
            </div>
            <div className="dialog-disclaimer">
              <img src={info} />
              <p
                style={{
                  paddingTop: "15px",
                  color: "#666666",
                  fontSize: "14px",
                }}
              >
                Please select your job profile to access OZ HRMS
              </p>
            </div>
            <div className="dialog-buttons">
              {designation?.map((ele, index) => {
                return (
                  <button onClick={() => closeDialog(ele.label)}>
                    {ele.label}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
