import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";
import Swal from "sweetalert2";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import moment from "moment";
import { ConfigProvider } from "antd";
import { Table } from "ant-table-extensions";
import "antd/dist/antd.css";
import empty from "../../../assets/img/profiles/browser.png";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { Box, Modal, useMediaQuery } from "@mui/material";
import { addDays } from "date-fns";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "150px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const customizeRenderEmpty = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
    <img src={empty} style={{ width: "50px", marginTop: "15px" }} />
    <p>No data to show</p>
  </div>
);

const OvertimeReport = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [menu, setMenu] = useState(false);
  const [open, setOpen] = useState(false);
  const [overtimeData, setOvertimeData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [empId, setEmpId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const matchsmexact = useMediaQuery("(max-width:630px)");
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/overtime_report/`, {
        params: {
          // year: monthValue.split("-")[0],
          // month: monthValue.split("-")[1],
          from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
          to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
          coregid: sessionStorage.getItem("coid"),
        },
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  const getOvertime = (id) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_overtime_report/`,
        // {
        //   email: sessionStorage.getItem("user"),
        // },
        {
          params: {
            emp_id: empId ? empId : id,
            year: monthValue.split("-")[0],
            month: monthValue.split("-")[1],
          },
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        }
      )
      .then((res) => {
        setOvertimeData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
            date: ele.date.split("-").reverse().join("-"),
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOvertime();
  }, [monthValue]);

  const columns = [
    // {
    //   title: "SNo",
    //   dataIndex: "sno",
    // },
    {
      title: "Employee Code",
      dataIndex: "emp_code",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
    },
    {
      title: "Location",
      dataIndex: "work_location",
    },
    {
      title: "Overtime",
      dataIndex: "overtime_hours",
      // align: "center",
    },
    {
      title: "Action",
      align: "center",
      fixed: "right",
      render: (text, record) => (
        <button
          style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
          data-bs-toggle="modal"
          data-bs-target="#view_overtime"
          className=" btn-primary submit-btn1"
          onClick={(e) => {
            setEmpId(text.emp_id);
            getOvertime(text.emp_id);
          }}
        >
          View
        </button>
      ),
    },
  ];

  const column1 = [
    {
      title: "SNo",
      dataIndex: "sno",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Overtime",
      dataIndex: "overtime_hours",
      // align: "center",
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        {/* <Helmet>
        <title>Leaves - HRMS Admin</title>
        <meta name="description" content="Login page" />
      </Helmet> */}

        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/reports");
                    }}
                  >
                    Reports
                  </span>{" "}
                  <span style={{ color: "#6c757d", cursor: "not-allowed" }}>
                    {" "}
                    / Overtime Report
                  </span>
                </h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Box
                      sx={
                        matchsmexact
                          ? { width: "250px", overflow: "auto" }
                          : { minWidth: "200px", overflow: "auto" }
                      }
                    >
                      <DateRangePicker
                        onChange={(item) => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                      />
                    </Box>
                    <Box
                      sx={{
                        position: "relative",
                        bottom: "-15px",
                        left: "28%",
                        // width: "100px",
                        // padding: "3px 5px",
                        // background: "#3d91ff",
                        // color: "white",
                        // borderRadius: "8px",
                        // cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        gap: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",

                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          width: "100px",
                          padding: "3px 5px",
                          background: "#3d91ff",
                          color: "white",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          width: "100px",
                          padding: "3px 5px",
                          background: "#3d91ff",
                          color: "white",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClose()}
                      >
                        Apply
                      </Box>
                    </Box>
                  </Box>
                </Modal>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginRight: "10px",
                    justifyContent: "end",
                  }}
                >
                  <h4 style={{ paddingTop: "7px" }}>
                    <b>{`Overtime Report From
                    ${moment(state[0].startDate).format(
                      "DD-MM-YYYY"
                    )} To ${moment(state[0].endDate).format("DD-MM-YYYY")}`}</b>
                  </h4>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <div className="view-icons">
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "5px 15px",
                        fontSize: "15px",
                        background: "#3298DB",
                        border: "none",
                        color: "white",
                      }}
                      onClick={handleOpen}
                    >
                      Select Date(s)
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <ConfigProvider
                      renderEmpty={
                        // customize ?
                        customizeRenderEmpty
                        // : undefined
                      }
                    >
                      <Table
                        className="table-striped"
                        exportableProps={{ showColumnPicker: true }}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        loading={isLoading}
                      />
                    </ConfigProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="view_overtime" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5
                className="modal-title"
                style={{ fontWeight: "600" }}
              >
                Regularize Request
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div> */}
            <div className="modal-body">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "-10px",
                }}
              >
                <div>
                  <h4>Overtime Report</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        marginRight: "10px",
                        justifyContent: "end",
                      }}
                    >
                      <div className="view-icons">
                        <input
                          style={{
                            width: "150px",
                            borderRadius: "5px",
                            height: "29px",
                            padding: "10px",
                          }}
                          type="month"
                          value={monthValue}
                          onChange={(e) => setMonthValue(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          pagination={{
                            total: overtimeData.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={column1}
                          // bordered
                          dataSource={overtimeData}
                          rowKey={(record) => record.id}
                          // onChange={console.log("change")}
                          loading={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OvertimeReport;
