import React from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Applogo from "../../../../assets/img/profiles/image23.png";
import Printer from "../../../../assets/img/profiles/printer-line.svg";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
import { useState, useEffect } from "react";
import Select from "react-select";
import Rupee from "../../../../assets/img/profiles/Rupee-Symbol-Black.svg";
const ViewPayslip = (props) => {
  // const { empID } = props;
  const [menu, setMenu] = useState(false);
  const [showLeave, setShowLeave] = useState(false);
  const [viewSalary, setViewSalary] = useState({});
  const [erSum, setErSum] = useState(0);
  const [deSum, setDeSum] = useState(0);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  // console.log(props.empID);
  const handleGetPayroll = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          empl_id: props.empID,
          toggle: "i",
          comp_id: sessionStorage.getItem("coid"),
        },
      })
      .then((res) => {
        console.log(res.data);
        // setEmpPayrollDetails(res.data);
        // searchData(res.data);
        setViewSalary(res.data);
        const sum = res.data.earning.reduce((accumulator, object) => {
          return accumulator + parseInt(object.component_amount_monthly);
        }, 0);
        setErSum(sum);
        const sum1 = res.data.deduction.reduce((accumulator, object) => {
          return accumulator + parseInt(object.component_amount_monthly);
        }, 0);
        setDeSum(sum1);
        console.log(sum, sum1);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetPayroll();
  }, []);

  const totDed = viewSalary.deduction?.map(
    (ele) => ele.component_amount_monthly
  );
  const totCtc = viewSalary.earning
    ?.concat(viewSalary.variables)
    .map((ele) => ele.component_amount_monthly);
  console.log(totDed);
  let sumDed = 0;
  let sumCtc = 0;

  // Calculation the sum using forEach
  totDed?.forEach((x) => {
    sumDed += parseInt(x);
  });
  totCtc?.forEach((x) => {
    sumCtc += parseInt(x);
  });
  console.log(sumDed);
  return (
    <>
      <div className="col-md-12 mb-3" style={{ display: "flex" }}>
        {/* <div className="col-md-1">
          <button
            className="btn "
            style={{ background: "#3298DB", color: "white" }}
          >
            Revise
          </button>
        </div> */}
        {/* <div className="col-md-1">
          <button
            className="btn "
            style={{
              background: "#3298DB",
              color: "white",
              float: "left",
              marginLeft: "8px",
            }}
          >
            <img src={Printer} />
          </button>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div
            className="card"
            style={{
              background: " #FFFFFF",
              borderRadius: "10px",
            }}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-sm-6 m-b-0 m-t-10">
                  <ul className="list-unstyled mb-0">
                    <li>
                      {" "}
                      <h4>
                        <b>{props.empName}</b>
                      </h4>
                    </li>
                    <li>
                      <h5>
                        <b>Emp ID: {props.empID}</b>
                      </h5>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 m-b-0 m-t-10">
                  <div className="invoice-details">
                    <h5>
                      Monthly Gross Salary(
                      <img
                        style={{ width: "10px", height: "10px" }}
                        src={Rupee}
                      />
                      ): <b>{Math.round(viewSalary?.monthly_gross)}</b>
                    </h5>
                    <ul className="list-unstyled">
                      <h5>
                        Annual Gross Salary (
                        <img
                          style={{ width: "10px", height: "10px" }}
                          src={Rupee}
                        />
                        ): <b>{Math.round(viewSalary?.annual_gross)} </b>
                      </h5>
                    </ul>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    {/* <h4 className="m-b-10">
                      <strong style={{ color: "green" }}>
                        Earnings (
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={Rupee}
                        />
                        )
                      </strong>
                      <strong style={{ marginLeft: "60%" }}>Amount</strong>
                    </h4> */}
                    <table
                      className=" table table-borderless "
                      style={{ backgroundColor: "#f2dbdb" }}
                    >
                      <thead style={{ border: "1px solid black" }}>
                        <th>
                          <strong
                            className="float-start"
                            style={{ marginLeft: "30px" }}
                          >
                            Particulars
                          </strong>{" "}
                        </th>
                        <th style={{ textAlign: "end" }}>
                          <strong>Monthly</strong>{" "}
                        </th>
                        <th>
                          <strong
                            className="float-end"
                            style={{ marginRight: "30px" }}
                          >
                            Annually
                          </strong>{" "}
                        </th>
                      </thead>
                      <tbody>
                        {viewSalary.earning?.map((ele) => (
                          <tr style={{ backgroundColor: "#f2dbdb" }}>
                            <td>
                              <span
                                className="float-start"
                                style={{ marginLeft: "30px" }}
                              >
                                {ele.component_name}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              <span>
                                {Math.round(ele.component_amount_monthly)}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className="float-end"
                                style={{ marginRight: "30px" }}
                              >
                                {Math.round(ele.component_amount_annual)}
                              </span>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tbody>
                        <tr style={{ backgroundColor: "#f2dbdb" }}>
                          <td>
                            {" "}
                            <span
                              style={{ marginLeft: "30px", fontWeight: "bold" }}
                            >
                              Gross Salary
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              {viewSalary?.monthly_gross}
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "30px",
                              }}
                            >
                              {viewSalary?.annual_gross}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        {viewSalary.deduction?.map((ele) => (
                          <tr style={{ backgroundColor: "#f2dbdb" }}>
                            <td>
                              <span
                                className="float-start"
                                style={{ marginLeft: "30px" }}
                              >
                                {ele.component_name}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              <span>
                                {Math.round(ele.component_amount_monthly)}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className="float-end"
                                style={{ marginRight: "30px" }}
                              >
                                {Math.round(ele.component_amount_annual)}
                              </span>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tbody>
                        <tr style={{ backgroundColor: "#f2dbdb" }}>
                          <td>
                            {" "}
                            <span
                              style={{ marginLeft: "30px", fontWeight: "bold" }}
                            >
                              Total Deduction
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>{sumDed}</span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "30px",
                              }}
                            >
                              {sumDed * 12}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr style={{ backgroundColor: "#f2dbdb" }}>
                          <td>
                            {" "}
                            <span
                              style={{ marginLeft: "30px", fontWeight: "bold" }}
                            >
                              Net Salary
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              {viewSalary?.monthly_net}
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "30px",
                              }}
                            >
                              {viewSalary?.annual_net}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        {viewSalary.variables?.map((ele) => (
                          <tr style={{ backgroundColor: "#f2dbdb" }}>
                            <td>
                              <span
                                className="float-start"
                                style={{ marginLeft: "30px" }}
                              >
                                {ele.component_name}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              <span>
                                {Math.round(ele.component_amount_monthly)}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className="float-end"
                                style={{ marginRight: "30px" }}
                              >
                                {Math.round(ele.component_amount_annual)}
                              </span>{" "}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tbody>
                        <tr style={{ backgroundColor: "#f2dbdb" }}>
                          <td>
                            {" "}
                            <span
                              style={{ marginLeft: "30px", fontWeight: "bold" }}
                            >
                              Total C.T.C.
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              {viewSalary?.monthly_ctc}
                            </span>
                          </td>
                          <td style={{ textAlign: "end" }}>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "30px",
                              }}
                            >
                              {viewSalary?.annual_ctc}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* <div>
                    <h4 className="m-b-10">
                      <strong style={{ color: "green" }}>
                        Deduction (
                        <img
                          style={{ width: "12px", height: "12px" }}
                          src={Rupee}
                        />
                        )
                      </strong>
                      <strong style={{ marginLeft: "60%" }}>Amount</strong>
                    </h4>
                    <table className=" table table-borderless ">
                      <thead className="">
                        <th>
                          <strong
                            className="float-start"
                            style={{ marginLeft: "30px" }}
                          >
                            Components
                          </strong>{" "}
                        </th>
                        <th style={{ textAlign: "end" }}>
                          <strong>Monthly</strong>{" "}
                        </th>
                        <th>
                          <strong
                            className="float-end"
                            style={{ marginRight: "30px" }}
                          >
                            Annually
                          </strong>{" "}
                        </th>
                      </thead>
                      <tbody>
                        {viewSalary.deduction?.map((ele) => (
                          <tr>
                            <td>
                              <span
                                className="float-start"
                                style={{ marginLeft: "30px" }}
                              >
                                {ele.component_name}
                              </span>{" "}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              <span>
                                {Math.round(ele.component_amount_monthly)}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className="float-end"
                                style={{ marginRight: "30px" }}
                              >
                                {Math.round(ele.component_amount_annual)}
                              </span>{" "}
                            </td>
                          </tr>
                        ))}

                        <br />
                      </tbody>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div
            className="card"
            style={{
              background: " #FFFFFF",
              borderRadius: "10px",
              paddingTop: "8px",
            }}
          >
            <table className=" table table-borderless ">
              <tbody className="">
                <tr>
                  <th>
                    <strong
                      className="float-start"
                      style={{ marginLeft: "20px" }}
                    >
                      Total Salary to be paid (
                      <img
                        style={{ width: "12px", height: "12px" }}
                        src={Rupee}
                      />
                      )
                    </strong>{" "}
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span>{Math.round(viewSalary?.monthly_net)} </span>{" "}
                  </th>
                  <th className="float-end" style={{ marginRight: "30px" }}>
                    <span>{Math.round(viewSalary?.annual_net)} </span>{" "}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ViewPayslip;
