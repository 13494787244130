import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Table, Tooltip } from "antd";
// import { Table } from "ant-table-extensions";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../antdstyle.css";
import axios from "axios";
import moment from "moment";
import mail from "../../../assets/img/profiles/mailicon.svg";
import Sidebar from "../../Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import copy from "../../../assets/img/profiles/file-copy.svg";
import down from "../../../assets/img/profiles/download-2-line.png";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";

const IssuingLetter = () => {
  const [data, setData] = useState([]);
  const [letters, setLetters] = useState([]);
  const [empList, setEmpList] = useState([]);
  const monthval = new Date().getMonth() + 1;
  const [menu, setMenu] = useState(false);
  const [link, setLink] = useState(null);
  const [issueLetter, setIssueLetter] = useState({
    empid: null,
    letter_id: null,
    issue_date: "",
    warn_date: "",
    language: "",
  });
  const [letterList, setLetterList] = useState([]);
  const [monthValue, setMonthValue] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const token = sessionStorage.getItem("access_token");
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const languages = [
    { label: "Hindi", value: "Hindi" },
    { label: "English", value: "English" },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/emp_pics/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(
          res.data.map((ele, index) => ({
            value: ele.empid,
            label: ele.name,
          }))
        );
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            key: ele.empid,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/issue_letter/`, {
        params: {
          admid: sessionStorage.getItem("admid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLetters(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_letters/`, {
        params: {
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLetterList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const refresh = (e) => {
    e.preventDefault();
    setIssueLetter({
      empid: null,
      letter_id: null,
      issue_date: "",
      warn_date: "",
      language: "",
    });
  };

  const copyToClipboard = () => {
    // Select the text
    const textToCopy = document.getElementById("text-to-copy");
    textToCopy.select();

    // Copy to clipboard
    document.execCommand("copy");

    // Deselect the text
    window.getSelection().removeAllRanges();
  };

  const handleIssueLetter = (e) => {
    e.preventDefault();
    if (
      !issueLetter.empid ||
      !issueLetter.letter_id ||
      !issueLetter.warn_date ||
      !issueLetter.issue_date ||
      !issueLetter.language
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields",
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/issue_letter/`,
          {
            empid: issueLetter.empid.value,
            letter_id: issueLetter.letter_id.value,
            issue_date: issueLetter.issue_date.split("-").reverse().join("/"),
            language: issueLetter.language.value,
            warning_date: issueLetter.warn_date.split("-").reverse().join("/"),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLink(res.data.url);
          Swal.fire({
            icon: "success",
            text: "Letter issued successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: "Something went wrong!",
          });
        });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Letter Name",
      dataIndex: "letter_name",
      //   sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      // align: "center",
    },
    {
      title: "Warning Date",
      dataIndex: "warning_date",
      // align: "center",
    },
    {
      title: "Action",
      dataIndex: "url",
      // sorter: (a, b) => a.reason.length - b.reason.length,
      render: (text, record) => (
        <a
          style={{
            color: "white",
            backgroundColor: "#18838D",
            padding: "3px 10px",
            fontSize: "13px",
            borderRadius: "5px",
          }}
          href={text}
          download
        >
          {" "}
          Download
          {/* <img src={down} style={{ height: "17px" }} /> */}
        </a>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="card mb-0">
            <div className="card-body">
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    style={{
                      padding: "3px 13px",
                      fontSize: "15px",
                      background: "#18838D",
                      border: "none",
                      color: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#issued_letters"
                  >
                    Issued Letters
                  </button>
                </div>

                {/* <a
                  href="#"
                  className="btn add-btn"
                  // data-bs-toggle="modal"
                  // data-bs-target="#add_leave"
                  onClick={() => setShowLeave(!showLeave)}
                >
                  {!showLeave ? "Type of Leaves" : "LEAVE MANAGEMENT"}
                </a> */}
              </div>
              <form>
                <div className="row col-sm-12">
                  <div className=" col-sm-8">
                    <h4 className="heading-add" style={{ textAlign: "start" }}>
                      <b style={{ fontSize: "20px" }}>
                        <img src={mail} />
                        &nbsp; Issuing Letter
                      </b>
                      <span style={{ fontSize: "10px" }}>
                        {" "}
                        {/* (All field are mandatory) */}
                      </span>
                    </h4>
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="form-group col-sm-4">
                        <label>Letter Name</label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          options={letterList}
                          value={issueLetter.letter_id}
                          onChange={(e) =>
                            setIssueLetter({
                              ...issueLetter,
                              letter_id: e,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label>Issue Date</label>

                        <input
                          type="date"
                          className="form-control"
                          value={issueLetter.issue_date}
                          onChange={(e) =>
                            setIssueLetter({
                              ...issueLetter,
                              issue_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label>Warning Date</label>

                        <input
                          type="date"
                          className="form-control"
                          value={issueLetter.warn_date}
                          onChange={(e) =>
                            setIssueLetter({
                              ...issueLetter,
                              warn_date: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="row"
                      style={{
                        justifyContent: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <div className="form-group col-sm-4">
                        <label>Name of Employee</label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          options={empList}
                          value={issueLetter.empid}
                          onChange={(e) =>
                            setIssueLetter({
                              ...issueLetter,
                              empid: e,
                            })
                          }
                        />
                      </div>
                      {/* <div className="form-group col-sm-4">
                        <label>Employee Code</label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          // options={genderData}
                          // value={
                          //   employeeAdd.official_info.gender !== ""
                          //     ? {
                          //         label: employeeAdd.official_info.gender,
                          //         value: employeeAdd.official_info.gender,
                          //       }
                          //     : null
                          // }
                          // onChange={(e) =>
                          //   setEmployeeAdd({
                          //     ...employeeAdd,
                          //     official_info: {
                          //       ...employeeAdd.official_info,
                          //       gender: e.value,
                          //     },
                          //   })
                          // }
                        />
                      </div> */}
                      <div className="form-group col-sm-4">
                        <label>Department </label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          // options={genderData}
                          isDisabled
                          value={data
                            .filter(
                              (ele) => issueLetter?.empid?.value === ele.empid
                            )
                            .map((ele) => ({
                              label: ele.department,
                              value: ele.department,
                            }))}
                        />
                      </div>
                      <div className="form-group col-sm-4">
                        <label>Language of Letter</label>
                        <Select
                          // styles={colourStyles}
                          className="add-emp-field"
                          options={languages}
                          value={issueLetter.language}
                          onChange={(e) =>
                            setIssueLetter({
                              ...issueLetter,
                              language: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                  {" "}
                  <button
                    className="btn-sub"
                    style={{
                      background: "none",
                      color: "#18838D",
                      border: "1px solid #18838D",
                      cursor: "pointer",
                    }}
                    onClick={(e) => refresh(e)}
                  >
                    Refresh
                  </button>
                  {!link ? (
                    <button
                      type="submit"
                      className="btn-sub"
                      style={{
                        background: "#18838D",
                        color: "white",
                        border: "1px solid #18838D",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleIssueLetter(e)}
                    >
                      Generate Letter
                    </button>
                  ) : (
                    <a
                      style={{
                        color: "white",
                        backgroundColor: "#18838D",
                        padding: "9px 20px",
                        fontSize: "13px",
                        borderRadius: "5px",
                        marginLeft: "20px",
                        cursor: "pointer",
                      }}
                      href={link}
                      download
                    >
                      {" "}
                      Download <img src={down} style={{ height: "17px" }} />
                    </a>
                  )}
                </div>
              </form>
              {/* <hr />
              <div
                style={{
                  margin: "30px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div>
                  <b>Link:</b>
                  <input
                    type="text"
                    id="text-to-copy"
                    defaultValue="Text to be copied from here to somewhere else"
                    style={{
                      width: "200px",
                      border: "none",
                      background: "none",
                      color: "blue",
                      fontWeight: "600",
                      textDecorationLine: "underline",
                    }}
                  />
                  <button
                    onClick={copyToClipboard}
                    style={{ border: "none", background: "none" }}
                  >
                    <img src={copy} />
                  </button>
                </div>
                <div>
                  {" "}
                  <button
                    className="btn-sub"
                    style={{
                      background: "#18838D",
                      color: "white",
                      border: "1px solid #18838D",
                    }}
                  >
                    Save and Preview
                  </button>
                  <button
                    className="btn-sub"
                    style={{
                      background: "#18838D",
                      color: "white",
                      border: "1px solid #18838D",
                    }}
                    // onClick={(e) => handleIssueLetter(e)}
                  >
                    Print
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div
          id="issued_letters"
          className="modal custom-modal fade xl"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Issued Letters</h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <Table
                  className="table-striped"
                  exportableProps={{ showColumnPicker: true }}
                  pagination={{
                    total: letters.length,
                    showTotal: (total, range) =>
                      `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                    showSizeChanger: true,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                  }}
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  // bordered
                  dataSource={letters}
                  // rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssuingLetter;
