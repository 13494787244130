import React, { Component, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../Sidebar/header";
import axios from "axios";
import Select from "react-select";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import Swal from "sweetalert2";
import { Table } from "antd";

const AddReimbLimits = () => {
  const [menu, setMenu] = useState(false);
  const [reimbursements, setReimbursements] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [updateReimbursment, setUpdateReimbursment] = useState({});
  const token = sessionStorage.getItem("access_token");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [addReimbLimit, setAddReimbLimit] = useState({
    name: null,
    max_amt: 0,
    applicable_to: [],
  });
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reim_limit/`, {
        params: {
          // coregid: sessionStorage.getItem("coid")
          empid: sessionStorage.getItem("emp_id"),
          toggle: "i",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReimbursements(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tran_hierarchy/`, {
        params: {
          // empid: sessionStorage.getItem("emp_id"),
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoleList(res.data);
        // setRoleList(
        //   res.data.map((ele) => ({
        //     label: ele.name,
        //     value: ele.id,
        //   }))
        // );
      });
  }, []);
  const handleReimbursement = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/reimbursement/`, {
        params: {
          // empid: sessionStorage.getItem("emp_id"),
          coregid: sessionStorage.getItem("coid"),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setIsLoading(false);
        // setData(
        //   res.data.map((ele) => ({
        //     ...ele,
        //     applicable_to: roleList.filter((item) =>
        //       ele.hei_id.includes(item.value)
        //     ),
        //   }))
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(data);

  useEffect(() => {
    handleReimbursement();
  }, []);

  const handleAddReimbLimit = (e) => {
    e.preventDefault();
    if (
      !addReimbLimit.name ||
      addReimbLimit.applicable_to.length === 0 ||
      // !addReimbLimit.status ||
      // !addReimbLimit.description ||
      addReimbLimit.max_amt === 0
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields!",
        // timer: 1500,
      });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/reim_limit/`,
          {
            // company_id: 343000,
            id: addReimbLimit.name.value,
            max_amt: parseInt(addReimbLimit.max_amt),
            hie_id: addReimbLimit.applicable_to.map((ele) => ele.value),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            text: "Reimbursement limit added successfully",
            // timer: 1500,
          });
          setAddReimbLimit({
            name: null,
            max_amt: 0,
            applicable_to: [],
          });
          handleReimbursement();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    }
  };
  const handleUpdtRembursement = (e) => {
    e.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/reim_limit/`,
        {
          id: updateReimbursment.id,
          max_amt: parseInt(updateReimbursment.max_amount),
          hie_id: updateReimbursment.applicable_to.map((ele) => ele.value),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Reimbursement updated successfully",
        });
        handleReimbursement();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleDeleteReimburs = (e, id) => {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/reim_limit/`, {
        data: {
          id: id,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Reimbursement limit deleted",
        });
        handleReimbursement();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const columns = [
    {
      title: "Reimbursement Type",
      dataIndex: "name",
      //   sorter: (a, b) => a.leaveleavetype.length - b.type.length,
    },

    {
      title: "Max Amount",
      dataIndex: "max_amount",
      //   sorter: (a, b) => a.noofdays.length - b.noofdays.length,
    },
    {
      title: "Applicable To",
      render: (text, record) => (
        <>
          {roleList
            ?.filter((item) => text.hei_id?.includes(item.value))
            ?.map((ele) => (
              <span>{ele.label + ", "}</span>
            ))}
        </>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            data-bs-toggle="modal"
            data-bs-target="#edit_reimbursement"
            className=" btn-primary submit-btn1"
            onClick={(e) =>
              setUpdateReimbursment({
                ...text,
                applicable_to: roleList?.filter((item) =>
                  text.hei_id?.includes(item.value)
                ),
              })
            }
          >
            Edit
          </button>{" "}
          {/* <button
            style={{ height: "25px", fontSize: "14px", paddingTop: "2px" }}
            className=" btn-primary submit-btn1"
            onClick={(e) => handleDeleteReimburs(e, text.id)}
          >
            Delete
          </button> */}
        </>
      ),
    },
  ];

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Reimbursements</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  <button
                    className="btn btn-success btn-block "
                    style={{
                      padding: "8px 23px",
                      fontSize: "15px",
                      background: "#3298db",
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#add_reimbursement"
                    href="#"
                    classname="edit-icon"
                  >
                    <i className="fa fa-plus" />
                    &nbsp; Add Reimbursement
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table-striped"
                      pagination={false}
                      columns={columns}
                      // bordered
                      dataSource={data}
                      rowKey={(record) => record.id}
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <div
          id="add_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content" style={{ width: "615px" }}>
              <div className="modal-header">
                <br />
                <br />
                <br />
                <h5 className="modal-title">
                  <b>Add Reimbursement Limit</b>
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-8">
                      <label>
                        Reimbursement <span className="text-danger">*</span>
                      </label>
                      <Select
                        // options={[
                        //   ...new Set(reimbursements?.map((item) => item)),
                        // ].map((ele) => ({
                        //   label: ele.name,
                        //   value: ele.id,
                        // }))}
                        options={reimbursements}
                        value={addReimbLimit.name}
                        onChange={(e) =>
                          setAddReimbLimit({ ...addReimbLimit, name: e })
                        }
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label>
                        Max Amount<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control datetimepicker"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={addReimbLimit.max_amt}
                        onChange={(e) =>
                          setAddReimbLimit({
                            ...addReimbLimit,
                            max_amt: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* <div className=" col-md-6">
                      <div className="form-group">
                        <label>
                          Status <span className="text-danger">*</span>
                        </label>
                        <div>
                          <Select
                            options={Status}
                            value={
                              addReimbLimit.status !== ""
                                ? {
                                    label: addReimbLimit.status,
                                    value: addReimbLimit.status,
                                  }
                                : null
                            }
                            onChange={(e) =>
                              setAddReimbLimit({
                                ...addReimbLimit,
                                status: e.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Code <span className="text-danger">*</span>
                        </label>

                        <input
                          className="form-control"
                          type="text"
                          value={addReimbLimit.code}
                          onChange={(e) =>
                            setAddReimbLimit({
                              ...addReimbLimit,
                              code: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>*/}
                  </div>

                  <div className="form-group">
                    <label>
                      Applicable To <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={roleList}
                      isMulti
                      value={addReimbLimit.applicable_to}
                      onChange={(e) => {
                        if (e) {
                          setAddReimbLimit({
                            ...addReimbLimit,
                            applicable_to: e.map((item) => item),
                          });
                        } else {
                          setAddReimbLimit([]);
                        }
                      }}
                    />
                  </div>
                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss="modal"
                      style={{
                        background: "#329fd1",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                        color: "white",
                      }}
                      onClick={(event) => handleAddReimbLimit(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          id="edit_reimbursement"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  style={{ marginTop: "10px", fontWeight: "600" }}
                >
                  Edit Reimbursement Limit
                </h5>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="modal"
                >
                  <img src={closeIcon} />
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row col-md-12">
                    <div className="form-group col-md-8">
                      <label>
                        Reimbursement <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled
                        options={[
                          ...new Set(reimbursements?.map((item) => item)),
                        ].map((ele) => ({
                          label: ele.name,
                          value: ele.id,
                        }))}
                        value={
                          updateReimbursment.name
                            ? {
                                label: updateReimbursment.name,
                                value: updateReimbursment.name,
                              }
                            : null
                        }
                        // onChange={(e) =>
                        //   setUpdateReimbursment({
                        //     ...updateReimbursment,
                        //     name: e,
                        //   })
                        // }
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <label>
                        Max Amount<span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control datetimepicker"
                        type="number"
                        min="0"
                        onInput={(e) => {
                          e.target.value =
                            Math.abs(e.target.value) >= 0
                              ? Math.abs(e.target.value)
                              : null;
                        }}
                        value={updateReimbursment.max_amount}
                        onChange={(e) =>
                          setUpdateReimbursment({
                            ...updateReimbursment,
                            max_amount: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Applicable To <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={roleList}
                      isMulti
                      value={
                        updateReimbursment.applicable_to
                          ? updateReimbursment.applicable_to.map((ele) => ({
                              label: ele.label,
                              value: ele.value,
                            }))
                          : []
                      }
                      onChange={(e) => {
                        if (e) {
                          setUpdateReimbursment({
                            ...updateReimbursment,
                            applicable_to: e.map((item) => item),
                          });
                        } else {
                          setUpdateReimbursment({
                            ...updateReimbursment,
                            applicable_to: [],
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="submit-section">
                    <button
                      className=" btn"
                      data-bs-dismiss="modal"
                      style={{
                        background: "#329fd1",
                        width: "94px",
                        height: "38px",
                        borderRadius: "5px",
                        fontSize: "16px",
                        color: "white",
                      }}
                      onClick={(event) => handleUpdtRembursement(event)}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddReimbLimits;
