import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Select from "react-select";
import axios from "axios";
import "antd/dist/antd.css";
import closeIcon from "../../../assets/img/profiles/close-circle-fill.svg";
import {
  itemRender,
  onShowSizeChange,
} from "../../../components/paginationfunction";
import "../../../../src/antdstyle.css";
import Sidebar from "../../../pages/Sidebar/sidebarAdmin";
import Header from "../../../pages/Sidebar/header";
import deletimg from "../../../assets/img/profiles/delete.svg";
import Swal from "sweetalert2";
import { Percent } from "@mui/icons-material";
//import ReviewForm from "./ReviewForm";

// Datatable JS
// import "../../../assets/js/jquery.dataTables.min.js";
// import "../../../assets/js/dataTables.bootstrap4.min.js";

const Survey = () => {
  const [menu, setMenu] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [reviewName, setReviewName] = useState("");
  const [selectSurvey, setSelectSurvey] = useState({});
  const [reviewData, setReviewData] = useState();
  const [loading, setLoading] = useState(true);
  const [emplist, setEmpList] = useState([]);
  const [selectEmplist, setSelectEmplist] = useState("all");
  const [selectEmp, setSelectEmp] = useState([]);
  const [valid, setValid] = useState(null);
  const [duplicate, setDuplicate] = useState(false);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const [dept, setDept] = useState({});
  const [surveyQ, setSurveyQ] = useState("");
  const [surveyData, setSurveyData] = useState([]);
  const [inputList, setInputList] = useState([{ question: "" }]);
  const token = sessionStorage.getItem("access_token");
  const removeFields = (e, index) => {
    e.preventDefault();
    let data = [...inputList];
    data.splice(index, 1);
    setInputList(data);
  };
  const addFields = (e) => {
    e.preventDefault();
    let newfield = { question: "" };

    setInputList([...inputList, newfield]);
  };
  const handleFormChange = (index, event) => {
    let data = [...inputList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setInputList(data);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          toggle: "d",
        },
      })
      .then((res) => {
        setData(
          res.data.map((ele, index) => ({
            ...ele,
            sno: index + 1,
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/list_empl/`, {
        params: {
          empid: sessionStorage.getItem("emp_id"),
          //  toggle: "d",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setEmpList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleEmpReview = (e, reviewid, by_empid, to_empid) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/review/`, {
        params: {
          review_id: reviewid,
          by_empid: by_empid,
          to_empid: to_empid,
          toggle: "e",
        },
      })
      .then((res) => {
        console.log(res.data, "Hello");
        setReviewData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSurveyDelete = (value) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/survey/`, {
        data: {
          survey_id: value,
        },
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Survey form has been deleted",
          // timer: 1500,
        });
        handleGetSurvey();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          text: "We have an internal server error and hence we are not able to serve you now. Please wait, we will come back",
          // timer: 1500,
        });
      });
  };

  const handleCreateReview = (e) => {
    e.preventDefault();
    if (
      reviewName === "" ||
      inputList
        .map((v) => String(Object.values(v).includes("")))
        .some((v) => v === "true")
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all fields.",
        // timer: 1500,
      });
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_URL}/survey/`, {
          created_by: JSON.parse(sessionStorage.getItem("emp_id")),
          survey_name: reviewName,
          valid_till: valid,
          targets:
            selectEmplist === "all"
              ? emplist.map((ele) => ele.value)
              : selectEmp.map((ele) => ele.value),
          questions: [
            {
              question: surveyQ,
              options: inputList.map((ele) => {
                return ele.question;
              }),
            },
          ],
        })
        .then((res) => {
          setShowForm(false);
          setReviewName("");
          setSurveyQ("");
          setInputList([{ question: "" }]);
          Swal.fire({
            icon: "success",
            text: "Survey form created successfully.",
            // timer: 1500,
          });
          handleGetSurvey();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something Went Wrong",
            });
          }
        });
    }
  };

  const handleSurveyStatus = (e, id) => {
    e.preventDefault();
    axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/survey_status/`, {
        coregid: sessionStorage.getItem("coid"),
        id: id,
      })
      .then((res) => {
        handleGetSurvey();
        Swal.fire({
          icon: "success",
          text: "Status changed successfully.",
          // timer: 1500,
        });
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something Went Wrong",
          });
        }
      });
  };

  const columns = [
    {
      title: "Survey Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Created By",
      dataIndex: "creator_name",
    },
    {
      title: "Expiry Date",
      dataIndex: "valid_till",
      sorter: (a, b) => a.valid_till.localeCompare(b.valid_till),
    },

    {
      title: "Question",
      dataIndex: "question",
      sorter: (a, b) => a.question.localeCompare(b.question),
      ellipsis: {
        showTitle: false,
      },
      render: (comment) => (
        <Tooltip placement="topLeft" title={comment}>
          {comment}
        </Tooltip>
      ),
    },
    {
      title: "Options",
      dataIndex: "option",
      ellipsis: {
        showTitle: false,
      },
      render: (comment) => (
        <Tooltip placement="topLeft" title={comment}>
          {comment}
        </Tooltip>
      ),
    },
    {
      title: "Answer",
      dataIndex: "answer",
      ellipsis: {
        showTitle: false,
      },
      render: (comment) => (
        <Tooltip placement="topLeft" title={comment}>
          {comment}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (text) => <>{text ? "Active" : "InActive"}</>,
    },
    {
      title: "Action",
      align: "center",
      render: (text, record) => (
        <>
          <button
            // className="choose-file-btn"
            style={{
              background: "#a020f0",
              width: "50px",
              border: "none",
              paddingRight: "10px",
              paddingLeft: "10px",
              color: "white",
              borderRadius: "5px",
              fontSize: "12px",
            }}
            disabled={
              rolePermissions["Survey"] === "Edit/Create/Approve" ? false : true
            }
            onClick={(e) => handleSurveyDelete(text.survey_id)}
          >
            Delete
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            style={{
              background: "#a020f0",
              width: "70px",
              border: "none",
              paddingRight: "10px",
              paddingLeft: "10px",
              color: "white",
              borderRadius: "5px",
              fontSize: "12px",
            }}
            disabled={
              rolePermissions["Survey"] === "Edit/Create/Approve" ? false : true
            }
            onClick={(e) => handleSurveyStatus(e, text.survey_id)}
          >
            {text.active ? "InActivate" : "Activate"}
          </button>
        </>
      ),
    },
    {
      title: "Report",
      align: "center",
      render: (text, record) => (
        <>
          <button
            style={{
              background: "#a020f0",
              width: "70px",
              border: "none",
              paddingRight: "10px",
              paddingLeft: "10px",
              color: "white",
              borderRadius: "5px",
              fontSize: "12px",
            }}
            disabled={
              rolePermissions["Survey"] === "Edit/Create/Approve" ? false : true
            }
          >
            <a
              href={`${
                process.env.REACT_APP_BACKEND_URL
              }/SurveyReport/?coregid=${sessionStorage.getItem(
                "coid"
              )}&survey_id=${text.survey_id}`}
              download
              target="_blank"
              style={{ color: "white" }}
            >
              Report
            </a>
          </button>
        </>
      ),
    },
  ];
  const handleGetSurvey = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/survey/`, {
        params: {
          toggle: "a",
          empid: JSON.parse(sessionStorage.getItem("emp_id")),
        },
      })
      .then((res) => {
        console.log(res.data);
        setSurveyData(
          res.data?.map((ele) => ({
            ...ele,
            name: ele.name,
            // creation_date: ele.creation_date.split("-").reverse().join("-"),
            question: ele.question?.[0]?.question || "No question available", // Safely access the first question
            option:
              ele.question?.[0]?.option
                ?.map((item) => item.option)
                ?.toString(" ") || "No options available", // Replace .toString(" ") with .join(" ")
            answer:
              ele.question?.[0]?.option
                ?.map(
                  (e) => `${e.option} (${(e.percentage * 100).toFixed(1)}%)`
                )
                ?.toString(" ") || "No answers available", // Same here, using .join(" ") and providing a default value
            survey_id: ele.id,
            valid_till: ele?.valid_till?.slice(0, 10),
          }))
        );

        // setSurveyData(
        //   res.data?.map((ele) => ({
        //     ...ele,
        //     name: ele.name,
        //     // creation_date: ele.creation_date.split("-").reverse().join("-"),
        //     question: ele.question[0].question,
        //     option: ele.question[0].option
        //       ?.map((item) => item.option)
        //       ?.toString(" "),
        //     answer: ele.question[0].option
        //       ?.map((e) => ${e.option}(${(e.percentage * 100).toFixed(1)}))
        //       ?.toString(" "),
        //     survey_id: ele.id,
        //   }))
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetSurvey();
  }, []);

  useEffect(() => {
    const data = surveyData?.filter(
      (ele) => ele.survey_id === selectSurvey?.value
    );
    setReviewName(data.length > 0 ? `${data[0]?.name}-copy` : "");
    setSurveyQ(data[0]?.question);

    data.length > 0 &&
      setInputList(
        data[0]?.option.split(",")?.map((ele) => ({ question: ele }))
      );
  }, [selectSurvey]);

  const EmployeeData = useMemo(() => {
    if (dept?.value === undefined) return emplist;
    return emplist.filter((x) =>
      x.dept?.toLowerCase().includes(dept?.value?.toLowerCase())
    );
  }, [emplist, dept]);

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Survey</h3>
              </div>
              <div className="col-auto float-end ml-auto">
                <div className="view-icons">
                  {rolePermissions["Survey"] === "Edit/Create/Approve" && (
                    <>
                      {!showForm && (
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "8px 23px",
                            fontSize: "15px",
                            background: "#973fcf",
                            border: "none",
                            color: "white",
                            borderRadius: "10px",
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#personal_info_modal"
                          // onClick={() => setApplyLeave("regular")}
                          onClick={() => {
                            setShowForm(true);
                          }}
                        >
                          <i className="fa fa-plus" />
                          &nbsp; Create Survey Form
                        </button>
                      )}
                    </>
                  )}
                  {showForm && (
                    <button
                      className="btn btn-success btn-block "
                      style={{
                        padding: "8px 23px",
                        fontSize: "15px",
                        background: "#3298db",
                        border: "none",
                        color: "white",
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#personal_info_modal"
                      // onClick={() => setApplyLeave("regular")}
                      onClick={() => {
                        setShowForm(false);
                      }}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {!showForm && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        ellipsis={true}
                        pagination={{
                          total: surveyData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        bordered
                        dataSource={surveyData}
                        rowKey={(record) => record.id}
                        loading={loading}
                        scroll={{ x: 1300 }}
                        // onChange={this.handleTableChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showForm && (
            <div className="card" style={{ padding: "20px" }}>
              <h3>Survey form</h3>
              {/* <p style={{ fontSize: "12px" }}>
                <span className="text-danger">*</span>
                Please ensure that your questions are based on a 1-5 scale where
                1 is the lowest and 5 is the highest. This will provide
                consistent and meaningful data analysis. Contact our support
                team with any questions. Thank you.
              </p> */}
              <div className="col-xl-12">
                <div
                  style={{
                    display: "flex",
                    marginBottom: "20px",
                    justifyContent: duplicate ? "space-around" : "center",
                  }}
                >
                  <div className="duplicate-project">
                    <button
                      className={!duplicate ? "old-btn" : "old1-btn"}
                      onClick={() => {
                        setDuplicate(false);
                        setSurveyQ("");
                        setReviewName("");
                        setInputList([{ question: "" }]);
                      }}
                    >
                      New Survey
                    </button>
                    <button
                      className={duplicate ? "old-btn" : "old1-btn"}
                      onClick={() => {
                        setDuplicate(true);
                      }}
                    >
                      Duplicate Survey
                    </button>
                  </div>
                  <br />
                  {duplicate && (
                    <div style={{ width: "200px" }}>
                      <Select
                        styles={{
                          // Fixes the overlapping problem of the component
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        inputId="single-select-example"
                        className="single-select"
                        classNamePrefix="react-select"
                        options={surveyData?.map((ele) => ({
                          label: ele.name,
                          value: ele.survey_id,
                        }))}
                        value={selectSurvey}
                        onChange={(e) => setSelectSurvey(e)}
                        //isClearable={()=>name1.clearValue()}
                        placeholder="Survey Name"
                        style={{
                          zindex: "5",
                          height: "50px",
                          width: "100%",
                          border: "1px solid lightgrey",
                          borderRadius: "3px",
                        }}
                      />
                    </div>
                  )}
                </div>
                <form>
                  <div className="card col-md-12">
                    <div className="card-body">
                      <div className="row">
                        <label className="col-lg-2 col-form-label">
                          Survey Form Name:
                        </label>
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              value={reviewName}
                              onChange={(e) => setReviewName(e.target.value)}
                              placeholder="Survey Name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <label className="col-lg-2 col-form-label">
                          Expiry Date:
                        </label>
                        <div className="col-md-2">
                          <div className="form-group">
                            <input
                              type="date"
                              value={valid}
                              onChange={(e) => setValid(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-lg-2 col-form-label">
                          Applicable To:
                        </label>

                        <div className="col-md-4">
                          <input
                            type="radio"
                            id="all"
                            name="fav_language"
                            value="All"
                            checked={selectEmplist === "all" ? true : false}
                            onChange={() => setSelectEmplist("all")}
                          />
                          &nbsp;&nbsp;
                          <label htmlFor="all">All Employees</label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            id="selected"
                            name="fav_language"
                            value="Selected"
                            checked={
                              selectEmplist === "selected" ? true : false
                            }
                            onChange={() => setSelectEmplist("selected")}
                          />
                          &nbsp;&nbsp;
                          <label htmlFor="selected">Selected Employees</label>
                        </div>

                        {selectEmplist === "selected" && (
                          <>
                            <div className="col-md-3 form-group">
                              <Select
                                isClearable
                                placeholder="Department name"
                                options={[
                                  ...new Set(emplist?.map((item) => item.dept)),
                                ].map((ele) => ({
                                  label: ele,
                                  value: ele,
                                }))}
                                value={dept}
                                onChange={(e) => setDept(e)}
                              />
                            </div>
                            <div className="col-md-3 form-group">
                              <Select
                                isMulti
                                placeholder="Employees"
                                options={EmployeeData}
                                value={selectEmp}
                                onChange={(e) => setSelectEmp(e ? e : [])}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Question</label>
                          <input
                            className=" form-control"
                            name="question"
                            placeholder="Survey Question"
                            value={surveyQ}
                            onChange={(event) => setSurveyQ(event.target.value)}
                          />
                        </div>
                      </div>
                      {inputList?.map((input, index) => {
                        return (
                          <div className="row col-md-12" key={index}>
                            <div className="remove-div">
                              <button
                                className="add-btn-remove"
                                onClick={(e) => removeFields(e, index)}
                              >
                                <img src={deletimg} />
                              </button>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <label>{`Option ${index + 1}`}</label>
                                <input
                                  className=" form-control"
                                  name="question"
                                  placeholder="Survey Option"
                                  value={input.question}
                                  onChange={(event) =>
                                    handleFormChange(index, event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="add-div">
                      <button
                        className="add-btn-add"
                        onClick={(e) => addFields(e)}
                      >
                        Add More &nbsp;+
                      </button>
                    </div>
                  </div>
                </form>
                <div style={{ textAlign: "center", margin: "auto" }}>
                  <button
                    className="modal-submit-btn"
                    onClick={(e) => handleCreateReview(e)}
                  >
                    submit
                  </button>
                </div>
              </div>
            </div>
          )}
          <div
            id="show_review"
            className="modal custom-modal fade"
            role="dialog"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <br />
                  <br />
                  <br />
                  <h5 className="modal-title">
                    <b>Review</b>
                  </h5>
                  <button
                    type="button"
                    className="close-btn"
                    data-bs-dismiss="modal"
                  >
                    <img src={closeIcon} />
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          {reviewData?.map((ele) => {
                            return (
                              <>
                                <p>
                                  {"Question: "}
                                  {ele.question}
                                </p>
                                <p>
                                  <span>
                                    {"rating :"} {ele.rating}
                                  </span>{" "}
                                  <span>
                                    {"comment :"} {ele.comment}
                                  </span>
                                </p>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Survey;
