import React from "react";
import { Button, Box } from "@mui/material"
import styled from '@emotion/styled'

export const taskData = {
  self_assigned: [{
    task_name: "OZ Hrms",
    task_desc: "webstie Creation with employee dashboard",
    progress: 70,
    assigned_date: " 26 Oct 2022",
    deadline_days: 5,
    key_point: [
      { name: "React. Js", color: "#FF9F38", bg: "#FFECD7" },
      { name: "Django", color: "#2EB67D", bg: "#BFF1DF" },
    ],
  },
  {
    task_name: "Samarth",
    task_desc: "webstie Creation with Job portal dashboard",
    progress: 50,
    assigned_date: " 26 Nov 2022",
    deadline_days: 5,
    key_point: [
      { name: "React. Js", color: "#FF9F38", bg: "#FFECD7" },
      { name: "Django", color: "#2EB67D", bg: "#BFF1DF" },
    ],
  },
  ]
};

export const SButton = styled(Button)({
  background: '#2E86C1',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  marginBottom: '16px',
  ":hover": {
    background: '#2E86C1',
    boxShadow: 'none'
  }
})

export const SBox2 = styled(Box)({
  color: '#000000',
  backgroundColor: 'rgba(255, 255, 255, 0.90)',
  backdropFilter: 'blur(16px) saturate(180%)',
  WebkitBackdropFilter: 'blur(16px) saturate(180%)',
  height: '100%',
  width: '100%',
  borderRadius: '8px',
})