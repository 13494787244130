/**
 * TermsCondition Page
 */
import React, { useEffect, useState, useRef } from "react";
//import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import {Avatar_02,Avatar_05,Avatar_09,Avatar_10,Avatar_16 } from '../../../Entryfile/imagepath'
//import avatar_02 from "../../assets/img/profiles/avatar-02.jpg";
import smily from "../../assets/img/smiley-icon.svg";
import Sidebar from "../Sidebar/sidebar";
import Header from "../Sidebar/header";
import Select from "@atlaskit/select";
import FormControl from "@mui/material/FormControl";
import InlineMessage from "@atlaskit/inline-message";
import axios from "axios";
import empty from "../../assets/img/profiles/emptystate.svg";
import deletimg from "../../assets/img/profiles/delete.svg";
import Swal from "sweetalert2";
import CameraIcon from "../../assets/img/profiles/cameraIcon.svg";
//import CameraIcon1 from "../../assets/img/profiles/cameraIcon1.svg";
//import { color } from "@mui/system";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Button,
  TableContainer,
  Table as Tablemui,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
//import { SBox1 } from './employeeprofilecss'
//import ReactRoundedImage from "react-rounded-image"
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SelfImprovementRounded } from "@mui/icons-material";

const SButton1 = styled(Button)({
  background: "transparent",
  borderRadius: "4px",
  border: "1px solid #3298DB",
  textTransform: "none",
  color: "#3298DB",
  boxShadow: "none",
  padding: "0px",
  paddingLeft: "4px",
  paddingRight: "4px",
  margin: "8px",
  ":hover": {
    background: "transparent",
    boxShadow: "none",
  },
});

const SButton2 = styled(Button)({
  background: "transparent",
  borderRadius: "4px",
  border: "1px solid #FF0000",
  textTransform: "none",
  color: "#FF0000",
  boxShadow: "none",
  padding: "0px",
  paddingLeft: "4px",
  paddingRight: "4px",
  margin: "8px",
  ":hover": {
    background: "transparent",
    boxShadow: "none",
  },
});

const SBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const EmployeeProfile = () => {
  // useEffect( ()=>{
  //   if($('.select').length > 0) {
  //     $('.select').select2({
  //       minimumResultsForSearch: -1,
  //       width: '100%'
  //     });
  //   }
  // });
  const [menu, setMenu] = useState(false);
  const [empDetails, setEmpDetails] = useState({});
  const [editProfile, setEditProfile] = useState(false);
  const first_name = sessionStorage.getItem("name");
  const [addressList, setAddressList] = useState({});
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const [inputList, setInputList] = useState([
    { education: "", college: "", from: "", to: "" },
  ]);

  const [certificationList, setCertificationLis] = useState([
    { course: "", institute: "", from: "", to: "" },
  ]);
  const [bankInfo, setBankInfo] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    bank_name: "",
    acc_no: "",
    confirm_acc_no: "",
    ifsc: "",
    pan: "",
    uan: "",
  });

  const [emergencyInfo, setEmergencyInfo] = useState(false);
  const [emergencyDetails, setEmergencyDetails] = useState({
    primary_name: "",
    primary_relation: "",
    primary_phone: "",
    primary_email: "",
    primary_blood_group: "",
    secondary_name: "",
    secondary_relation: "",
    secondary_phone: "",
    secondary_email: "",
    secondary_blood_group: "",
  });

  const [perInfo, setPerInfo] = useState(false);
  const [perDetails, setPerDetails] = useState({
    passport_no: "",
    passport_exp_dt: "",
    phone: "",
    aadhaar_no: "",
    office_name: "",
    father_name: "",
    mother_name: "",
    spouse_name: "",
    per_address: "",
    district: "",
    state: "",
    pincode: "",
    country: "",
    nationality: "",
    blood_group: "",
    martial_status: "",
    marriage_date: "",
    empl_of_spouce: "",
    offspring_no: "",
    linkedin: null,
    facebook: null,
    twitter: null,
  });

  const [empInfo, setEmpInfo] = useState(false);
  const [empBasic, setEmpBasic] = useState({
    mobile: "",
    bday: "",
    emp_adress: "",
    gender: "",
    report_to: "",
  });

  const maritalStatus = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Separated", value: "Separated" },
    { label: "Widowed", value: "Widowed" },
  ];
  const bloodGroup = [
    { label: "A+", value: "A+" },
    { label: "A-", value: "A-" },
    { label: "B+", value: "B+" },
    { label: "B-", value: "B-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
  ];

  const Gender = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const [educationInfo, setEducationInfo] = useState(false);
  const [certificatesInfo, setCertificatesInfo] = useState(false);
  const [experienceInfo, setExperienceInfo] = useState(false);
  const [addEducation, setAddEducation] = useState([
    /*
    {
      education: "",
      college: "",
      from: "",
      to: ""
    }
    */
  ]);

  const [addCertificates, setAddCertificates] = useState([
    /*
    {
      course: "",
      institute: "",
      from: "",
      to: ""
    }
    */
  ]);

  const [addExperience, setAddExperience] = useState([
    /*
    {
      course: "",
      institute: "",
      from: "",
      to: ""
    }
    */
  ]);

  const AddFields = (e) => {
    e.preventDefault();
    let newfield = {
      education: "",
      college: "",
      from: "",
      to: "",
    };
    setAddEducation([...addEducation, newfield]);
  };

  const HandleForm = (index, name, value) => {
    let data = [...addEducation];
    data[index][name] = value;
    setAddEducation(data);
  };

  const RemoveField = (index) => {
    let data = [...addEducation];
    data.splice(index, 1);
    setAddEducation(data);
  };

  const AddFields1 = (e) => {
    e.preventDefault();
    let newfield = {
      course: "",
      institute: "",
      from: "",
      to: "",
    };
    setAddCertificates([...addCertificates, newfield]);
  };

  const HandleForm1 = (index, name, value) => {
    let data = [...addCertificates];
    data[index][name] = value;
    setAddCertificates(data);
  };

  const RemoveField1 = (index) => {
    let data = [...addCertificates];
    data.splice(index, 1);
    setAddCertificates(data);
  };

  const AddFields2 = (e) => {
    e.preventDefault();
    let newfield = {
      designation: "",
      company_name: "",
      industry: "",
      city: "",
      state: "",
      country: "",
      ctc: "",
      from: "",
      to: "",
    };
    setAddExperience([...addExperience, newfield]);
  };

  const HandleForm2 = (index, name, value) => {
    let data = [...addExperience];
    data[index][name] = value;
    setAddExperience(data);
    if (name === "city") {
      setCity(value);
    } else if (name === "state") {
      setState(value);
    }
  };

  const RemoveField2 = (index) => {
    let data = [...addExperience];
    data.splice(index, 1);
    setAddExperience(data);
  };

  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    //const fileUploaded = event.target.files[0];
    //props.handleFile(fileUploaded);
    let formData = new FormData();
    formData.append("emp_pic", event.target.files[0]);
    formData.append("toggle_pp", "y");
    formData.append("empid", JSON.parse(sessionStorage.getItem("emp_id")));
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "profile Changes Successfully",
          // timer: 2000,
        });
        employeeDetails();
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.error}!`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong",
          });
        }
      });
  };

  const handleFormChange = (index, event) => {
    let data = [...inputList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setInputList(data);
  };
  const handleFormChange1 = (index, event) => {
    let data = [...certificationList];
    // console.log(event.target.name);
    data[index][event.target.name] = event.target.value;
    setCertificationLis(data);
  };

  const addFields = (e) => {
    e.preventDefault();
    let newfield = { education: "", college: "", from: "", to: "" };
    setInputList([...inputList, newfield]);
  };

  const addCertificateFields = (e) => {
    e.preventDefault();
    let newfield = { course: "", institute: "", from: "", to: "" };

    setCertificationLis([...certificationList, newfield]);
  };
  const onAddBtnClick = (event) => {
    setInputList(inputList.concat(<Input key={inputList.length} />));
  };

  const Input = () => {
    return (
      <>
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
        <input placeholder="Your input here" />
      </>
    );
  };
  // console.log(inputList);

  const removeFields = (index) => {
    let data = [...inputList];
    data.splice(index, 1);
    setInputList(data);
  };

  const removeFields1 = (index) => {
    let data = [...certificationList];
    data.splice(index, 1);
    setCertificationLis(data);
  };

  const handleFormChange111 = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["education"] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const handleFormChange112 = (index, event, state, setState) => {
    let data = [...state];
    console.log(index);
    console.log(event);
    data[index]["industry"] = event.value;
    // data[index][event] = event.value;
    setState(data);
  };

  const emptyArr = [];
  const token = sessionStorage.getItem("access_token");
  /*
  const employeeDetails = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data)
        setEmpDetails(res.data);
        setInputList(res.data.emp_education);
        setCertificationLis(res.data.certification);
        setBankDetails({
          bank_name: data.bank_name,
          acc_no: data.acc_no,
          ifsc: data.ifsc,
          pan: data.pan,
        });
        
      })
      .catch((err) => {
        console.log(err);
      });
  };
  */

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gs_mas_industry/`)
      .then((res) => {
        // setDeptList(
        //   res.data.map((ele) => ({
        //     label: ele.dept.name,
        //     value: ele.dept.dept_id,
        //   }))
        // );
        setIndustryList(
          res.data.map((ele) => ({
            label: ele.label,
            value: ele.label,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/gs_mas_education/`)
      .then((res) => {
        // setDeptList(
        //   res.data.map((ele) => ({
        //     label: ele.dept.name,
        //     value: ele.dept.dept_id,
        //   }))
        // );
        setQualificationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const employeeDetails = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.data;
      console.log(data);
      setEmpDetails(data);
      setInputList(data.emp_education);
      //
      setAddEducation(data.emp_education);
      setAddCertificates(data.certification);
      setAddExperience(data.experience);
      //
      setCertificationLis(data.certification);
      setBankDetails({
        bank_name: data.bank_name,
        acc_no: data.acc_no,
        confirm_acc_no: data.acc_no,
        ifsc: data.ifsc,
        pan: data.pan,
        uan: data.uan,
      });
      setEmergencyDetails({
        primary_name: data.primary_name,
        primary_relation: data.primary_relation,
        primary_phone: data.primary_phone,
        primary_email: data.primary_email,
        primary_blood_group: data.primary_blood_group,
        secondary_name: data.secondary_name,
        secondary_relation: data.secondary_relation,
        secondary_phone: data.secondary_phone,
        secondary_email: data.secondary_email,
        secondary_blood_group: data.secondary_blood_group,
      });
      setPerDetails({
        passport_no: data.passport_no,
        passport_exp_dt: data.passport_exp_dt,
        phone: data.alternate_phone,
        aadhaar_no: data.aadhaar_no,
        office_name: data.office_name,
        father_name: data.father_name,
        mother_name: data.mother_name,
        spouse_name: data.spouse_name,
        per_address: data.per_address,
        district: data.district,
        state: data.state,
        pincode: data.pincode,
        country: data.country,
        nationality: data.nationality,
        blood_group: data.blood_group,
        martial_status: data.martial_status,
        marriage_date: data.marriage_date,
        empl_of_spouce: data.empl_of_spouce,
        offspring_no: data.offspring_no,
        linkedin: data.linkedin,
        facebook: data.facebook,
        twitter: data.twitter,
      });
      setEmpBasic({
        mobile: data.phone,
        bday: data.bday,
        emp_adress: data.address,
        gender: data.gender,
        report_to: data.report_to,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddress = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/city_state_country/`, {
        params: {
          city: city.length > 2 ? city : "",
          state: state.length > 2 ? state : "",
          country: "",
        },
      })
      .then((res) => {
        //   setColumnData( res.data.map((v) => v)[0].map((item) =>
        //   Object.keys(item)
        // )[0].map((ele)=>({
        //     name: ele,
        //     key: ele,
        // })))
        setAddressList(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddress1 = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/city_state_country/`, {
        params: {
          city: perDetails?.district?.length > 2 ? perDetails.district : "",
          state: perDetails?.state?.length > 2 ? perDetails.state : "",
          country: "",
        },
      })
      .then((res) => {
        //   setColumnData( res.data.map((v) => v)[0].map((item) =>
        //   Object.keys(item)
        // )[0].map((ele)=>({
        //     name: ele,
        //     key: ele,
        // })))
        setAddressList(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleAddress();
  }, [city, state]);

  useEffect(() => {
    handleAddress1();
  }, [perDetails.district, perDetails.state]);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const employeeDetailsUpdate = () => {
    if (
      (Boolean(emergencyDetails?.primary_email?.match(isValidEmail)) ||
        emergencyDetails?.primary_email === null ||
        emergencyDetails?.primary_email === "" ||
        emergencyDetails?.primary_email === undefined) &&
      (Boolean(emergencyDetails?.secondary_email?.match(isValidEmail)) ||
        emergencyDetails?.secondary_email === null ||
        emergencyDetails?.secondary_email === "" ||
        emergencyDetails?.secondary_email === undefined) &&
      bankDetails.acc_no === bankDetails.confirm_acc_no
    ) {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/employee_full_details/`,
          {
            empid: empDetails.empl_id,
            name: empDetails.name
              ? empDetails.name
              : sessionStorage.getItem("name"),
            dob: empBasic.bday,
            gender: empBasic.gender,
            off_mobile_number: "",
            address: empBasic.emp_adress,
            passport_number: perDetails.passport_no,
            passport_exp_date: perDetails.passport_exp_dt,
            nationality: perDetails.nationality,
            blood_grp: perDetails.blood_group,
            father_name: perDetails.father_name,
            mother_name: perDetails.mother_name,
            spouse_name: perDetails.spouse_name,
            marital_status: perDetails.martial_status,
            marriage_date: perDetails.marriage_date,
            per_ph_number: empBasic.mobile,
            aux_desg: empDetails.aux_desg?.map((ele) => ele.value),
            emp_of_spouse: perDetails.empl_of_spouce,
            number_of_children: perDetails.offspring_no,
            alt_ph_number: perDetails.phone,
            aadhar_number:
              perDetails.aadhaar_no == null
                ? ""
                : perDetails.aadhaar_no?.split(" ").join(""),
            report_to: empDetails.report_to.value
              ? empDetails.report_to.value
              : null,
            desg_id: empDetails.designation
              ? empDetails.designation.value
              : null,
            dept_id: empDetails.department ? empDetails.department.value : null,
            permanant_address: perDetails.per_address,
            state: perDetails.state,
            district: perDetails.district,
            country: perDetails.country,
            pincode: perDetails.pincode,
            linkedin: perDetails.linkedin,
            facebook: perDetails.facebook,
            twitter: perDetails.twitter,
            empcode: empDetails.empcode,
            // role: 4,
            // permission: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24],
            // role: empDetails.roles.id ? empDetails.roles.id : {},
            permission: empDetails.permission
              ? empDetails.permission.map((ele) => ele.id)
              : [],
            bank_name: bankDetails.bank_name,
            acc_number: bankDetails.confirm_acc_no,
            IFSC_code: bankDetails.ifsc,
            uan: bankDetails.uan,
            emergency_cont: [
              {
                relation: emergencyDetails.primary_relation
                  ? emergencyDetails.primary_relation
                  : "",
                name: emergencyDetails.primary_name
                  ? emergencyDetails.primary_name
                  : "",
                ph_number: emergencyDetails.primary_phone
                  ? emergencyDetails.primary_phone
                  : "",
                email: emergencyDetails.primary_email
                  ? emergencyDetails.primary_email
                  : "",
                blood_grp: emergencyDetails.primary_blood_group
                  ? emergencyDetails.primary_blood_group
                  : "",
              },
              {
                relation: emergencyDetails.secondary_relation
                  ? emergencyDetails.secondary_relation
                  : "",
                name: emergencyDetails.secondary_name
                  ? emergencyDetails.secondary_name
                  : "",
                ph_number: emergencyDetails.secondary_phone
                  ? emergencyDetails.secondary_phone
                  : "",
                email: emergencyDetails.secondary_email
                  ? emergencyDetails.secondary_email
                  : "",
                blood_grp: emergencyDetails.secondary_blood_group
                  ? emergencyDetails.secondary_blood_group
                  : "",
              },
            ],
            education: addEducation.map((v) => {
              return {
                degree_name: v.education,
                institution: v.college,
                specialization: "",
                from: v.from,
                to: v.to,
              };
            }),
            experience: addExperience,
            certification: addCertificates.map((ele) => ({
              name: ele.course,
              institute: ele.institute,
              from: ele.from,
              to: ele.to,
            })),
            /*
            empid: empDetails.empl_id, //JSON.parse(sessionStorage.getItem("emp_id")),
            toggle_pp: "n",
            //aadhaar_no: perDetails.aadhaar_no,
            acc_no: bankDetails.acc_no,
            address: empBasic.emp_adress,
            alternate_phone: empDetails.alternate_phone,
            bank_name: bankDetails.bank_name,
            bday: empDetails.bday,
            blood_group: perDetails.blood_group,
            candidateid: empDetails.candidateid,
            certification: addCertificates,
            date_joined: empDetails.date_joined,
            department: empDetails.department,
            designation: empDetails.designation,
            district: empDetails.district,
            email: empDetails.email,
            emp_education: addEducation,
            emp_profile: empDetails.emp_profile,
            emp_type: empDetails.emp_type,
            //empl_id: fullempDetails.empl_id,
            empl_of_spouce: perDetails.empl_of_spouce,
            empphone: empDetails.empphone,
            experience: addExperience,
            gender: empBasic.gender,
            ids: empDetails.ids,
            ifsc: bankDetails.ifsc,
            martial_status: perDetails.martial_status,
            name: empDetails.name,
            nationality: perDetails.nationality,
            //office_logo: fullempDetails.office_logo,
            office_name: perDetails.office_name,
            offspring_no: perDetails.offspring_no,
            pan: bankDetails.pan,
            passport_exp_dt: perDetails.passport_exp_dt,
            passport_no: perDetails.passport_no,
            per_address: perDetails.per_address,
            per_email: empDetails.per_email,
            phone: perDetails.phone,
            pincode: empDetails.pincode,
            primary_blood_group: emergencyDetails.primary_blood_group,
            primary_email: emergencyDetails.primary_email,
            primary_name: emergencyDetails.primary_name,
            primary_phone: emergencyDetails.primary_phone,
            primary_relation: emergencyDetails.primary_relation,
            //profile_pic: fullempDetails.profile_pic,
            religion: empDetails.religion,
            report_to: empBasic.report_to,
            secondary_blood_group: empDetails.secondary_blood_group,
            secondary_email: emergencyDetails.secondary_email,
            secondary_name: emergencyDetails.secondary_name,
            secondary_phone: emergencyDetails.secondary_phone,
            secondary_relation: emergencyDetails.secondary_relation,
            state: empDetails.state,
            status: empDetails.status,
            */
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res);
          // alert("update successfully");
          Swal.fire({
            icon: "success",
            text: "Profile information updated successfully",
            // timer: 3000,
          });
          employeeDetails();
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            Swal.fire({
              icon: "error",
              text: `${err?.response?.data?.error}!`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong",
            });
          }
        });
    } else {
      if (bankDetails.acc_no !== bankDetails.confirm_acc_no) {
        Swal.fire({
          icon: "error",
          text: "Please confirm bank account number",
          // timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Enter valid email.",
          // timer: 1500,
        });
      }
    }
  };

  useEffect(() => {
    employeeDetails();
  }, []);

  const handleProfileEdit = () => {
    employeeDetails();
    setEditProfile(!editProfile);
  };

  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />

      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Content */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12" style={{ display: "flex" }}>
                <h3 className="page-title">Profile</h3>&nbsp;&nbsp;&nbsp;
                {/*
                <div className="pro-edit" style={{ marginTop: "17px" }}>
                  <a
                    className="edit-icon"
                    href="#"
                    onClick={() => handleProfileEdit()}
                  >
                    <i className="fa fa-pencil" />
                  </a>
                </div>
                */}
                {/* <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Profile</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
        {!editProfile ? (
          <div
            className="content container-fluid"
            style={{ marginTop: "-50px" }}
          >
            {/* Page Header */}

            {/* /Page Header */}
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div
                        className="profile-img-wrap"
                        style={{ marginTop: "40px" }}
                      >
                        <div
                          className="profile-img"
                          style={{ display: "flex" }}
                        >
                          <a href="#">
                            <img
                              alt=""
                              src={
                                empDetails.profile_pic
                                  ? empDetails.profile_pic.links
                                  : smily
                              }
                            />
                          </a>
                        </div>
                        <button
                          style={{ border: "none" }}
                          onClick={(e) => handleClick(e)}
                        >
                          <div
                            style={{
                              background: "#C9CCD5",
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                              textAlign: "center",
                              border: "3px solid white",
                            }}
                          >
                            <img
                              src={CameraIcon}
                              style={{
                                width: "25px",
                                height: "25px",
                                marginTop: "5px",
                              }}
                            />
                          </div>
                        </button>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={(e) => handleChange(e)}
                          style={{ display: "none" }}
                        />
                      </div>

                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div
                              className="profile-info-left"
                              style={{ lineHeight: "300%" }}
                            >
                              <h5 style={{ marginTop: "45px" }}>
                                Employee Code :
                                {empDetails.empcode ? empDetails.empcode : "-"}
                              </h5>

                              <h3>{empDetails.name ? empDetails.name : "-"}</h3>

                              <h5>
                                {empDetails.designation
                                  ? empDetails.designation.label
                                  : "-"}
                              </h5>
                              <h5>
                                {empDetails.aux_desg
                                  ? empDetails.aux_desg
                                      .map((ele) => ele.label)
                                      .join(" , ")
                                  : "-"}
                              </h5>

                              <h5>
                                Date of Joining:
                                {empDetails.date_joined
                                  ? empDetails.date_joined
                                  : "-"}
                              </h5>
                              {/* <div className="staff-msg">
                              <Link
                                onClick={() =>
                                  localStorage.setItem("minheight", "true")
                                }
                                className="btn btn-custom"
                                to="/conversation/chat"
                              >
                                Send Message
                              </Link>
                            </div> */}
                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <Box sx={{ textAlign: "right", width: "100%" }}>
                                {!empInfo ? (
                                  <IconButton
                                    sx={{ color: "#000000", mb: 1 }}
                                    onClick={() => setEmpInfo(true)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                ) : (
                                  <>
                                    <SButton2
                                      onClick={() => {
                                        setEmpInfo(false);
                                        //EditEmployeeDetails();
                                      }}
                                    >
                                      Cancel
                                    </SButton2>

                                    <SButton1
                                      onClick={() => {
                                        setEmpInfo(false);
                                        employeeDetailsUpdate();
                                      }}
                                    >
                                      Save
                                    </SButton1>
                                  </>
                                )}
                              </Box>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Email:
                                </div>
                                <div className="">
                                  {empDetails.email ? empDetails.email : "-"}
                                </div>
                              </li>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Reports to:
                                </div>
                                <div
                                  className=""
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {empDetails.report_to
                                    ? empDetails.report_to.label
                                    : "-"}
                                </div>
                              </li>

                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Phone:
                                </div>

                                {empInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    type="number"
                                    //value={empBasic.mobile}
                                    defaultValue={empBasic.mobile}
                                    onInput={(e) => {
                                      e.target.value = Math.max(
                                        0,
                                        parseInt(e.target.value)
                                      )
                                        .toString()
                                        .slice(0, 10);
                                    }}
                                    onChange={(e) =>
                                      setEmpBasic({
                                        ...empBasic,
                                        mobile: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {"+91"}{" "}
                                    {empDetails.phone ? empDetails.phone : "-"}
                                  </div>
                                )}
                              </li>

                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  D.O.B:
                                </div>

                                {empInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    type="date"
                                    sx={{ width: "210px" }}
                                    InputProps={{
                                      inputProps: {
                                        min: "1000-05-01",
                                        max: "9999-12-31",
                                      },
                                    }}
                                    value={empBasic.bday}
                                    onChange={(e) =>
                                      setEmpBasic({
                                        ...empBasic,
                                        bday: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {empDetails.bday
                                      ? empDetails.bday
                                          ?.split("-")
                                          ?.reverse()
                                          ?.join("-")
                                      : "-"}
                                  </div>
                                )}
                              </li>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  {" "}
                                  Current Address:
                                </div>
                                {empInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    value={empBasic.emp_adress}
                                    onChange={(e) =>
                                      setEmpBasic({
                                        ...empBasic,
                                        emp_adress: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div
                                    className=""
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {empDetails.address
                                      ? empDetails.address
                                      : "-"}
                                  </div>
                                )}
                              </li>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Gender:
                                </div>

                                {empInfo ? (
                                  <FormControl
                                    sx={{
                                      minWidth: 210,
                                      backgroundColor: "#e9eaec",
                                    }}
                                  >
                                    <Select
                                      inputId="single-select-example"
                                      className="single-select"
                                      classNamePrefix="react-select"
                                      placeholder="Gender"
                                      options={Gender}
                                      value={
                                        empBasic.gender
                                          ? {
                                              label: empBasic.gender,
                                              value: empBasic.gender,
                                            }
                                          : null
                                      }
                                      onChange={(e) =>
                                        setEmpBasic({
                                          ...empBasic,
                                          gender: e.value,
                                        })
                                      }
                                    />
                                  </FormControl>
                                ) : (
                                  <div className="">
                                    {empDetails.gender
                                      ? empDetails.gender
                                      : "-"}
                                  </div>
                                )}
                              </li>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Grade:
                                </div>

                                {empInfo ? (
                                  <FormControl
                                    sx={{
                                      minWidth: 210,
                                      backgroundColor: "#e9eaec",
                                    }}
                                  >
                                    <Select
                                      isDisabled
                                      inputId="single-select-example"
                                      className="single-select"
                                      classNamePrefix="react-select"
                                      value={empDetails.hierchy}
                                    />
                                  </FormControl>
                                ) : (
                                  <div className="">
                                    {empDetails.hierchy?.label
                                      ? empDetails.hierchy?.label
                                      : "-"}
                                  </div>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <div className="pro-edit">
                        <a
                          data-bs-target="#profile_info"
                          data-bs-toggle="modal"
                          className="edit-icon"
                          href="#"
                        >
                          <i className="fa fa-pencil" />
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tab-content">
              {/* Profile Info Tab */}
              <div
                id="emp_profile"
                className="pro-overview tab-pane fade show active"
              >
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        // style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <SBox>
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Personal Information
                            {!perInfo ? (
                              <IconButton
                                sx={{ color: "#000000", mb: 1 }}
                                onClick={() => setPerInfo(true)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </h3>

                          <Box>
                            {perInfo ? (
                              <>
                                <SButton2
                                  onClick={() => {
                                    setPerInfo(false);
                                    //EditEmployeeDetails();
                                  }}
                                >
                                  Cancel
                                </SButton2>

                                <SButton1
                                  onClick={() => {
                                    setPerInfo(false);
                                    employeeDetailsUpdate();
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            ) : null}
                          </Box>
                        </SBox>

                        <ul className="personal-info">
                          <li style={{}}>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Passport No.
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                inputProps={{
                                  pattern: "[A-PR-WY][1-9]ds?d{4}[1-9]",
                                }}
                                value={perDetails.passport_no}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    passport_no: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.passport_no
                                  ? empDetails.passport_no
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Passport Exp Date.
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="date"
                                sx={{ width: "210px" }}
                                InputProps={{
                                  inputProps: { max: "9999-12-31" },
                                }}
                                value={perDetails.passport_exp_dt}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    passport_exp_dt: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.passport_exp_dt
                                  ? empDetails.passport_exp_dt
                                      ?.split("-")
                                      .reverse()
                                      ?.join("-")
                                  : "-"}
                              </div>
                            )}
                          </li>

                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Alternate Contact Number
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="number"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                //value={perDetails.phone}
                                defaultValue={perDetails.phone}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    phone: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.alternate_phone
                                  ? empDetails.alternate_phone
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Aadhar Card Number
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                inputProps={{ maxLength: 12 }}
                                // onInput={(e) => {
                                //   e.target.value = Math.max(
                                //     0,
                                //     parseInt(e.target.value)
                                //   )
                                //     .toString()
                                //     .slice(0, 12);
                                // }}
                                defaultValue={perDetails.aadhaar_no}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    aadhaar_no: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.aadhaar_no
                                  ? empDetails.aadhaar_no
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Office
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.office_name}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    office_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.office_name
                                  ? empDetails.office_name
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Permanent Address
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.per_address}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    per_address: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.per_address
                                  ? empDetails.per_address
                                  : "-"}
                              </div>
                            )}
                          </li>

                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Pincode
                            </div>
                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="text"
                                defaultValue={perDetails?.pincode}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    pincode: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.pincode ? empDetails.pincode : "-"}
                              </div>
                            )}
                          </li>
                          {/* <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              City
                            </div>
                            {perInfo ? (
                              <div class="form-group">
                                <input
                                  type="text"
                                  style={{
                                    height: "40px",
                                    border: "1px solid #b4b3b1",
                                    backgroundColor: "#ebebeb",
                                    maxWidth: "210px",
                                  }}
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  list="EmpAddressic"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter city"
                                  value={perDetails?.district}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      district: e.target.value,
                                    })
                                  }
                                />
                                <datalist id="EmpAddressic">
                                  {addressList?.city
                                    ?.filter(
                                      (value, index, self) =>
                                        self.indexOf(value) === index
                                    )
                                    ?.map((ele, index) => (
                                      <option key={index} value={ele}></option>
                                    ))}
                                </datalist>
                              </div>
                            ) : (
                              <div className="">
                                {empDetails.district
                                  ? empDetails.district
                                  : "-"}
                              </div>
                            )}
                          </li> */}
                          {/* <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              State
                            </div>
                            {perInfo ? (
                              <div class="form-group">
                                <input
                                  type="text"
                                  style={{
                                    height: "40px",
                                    border: "1px solid #b4b3b1",
                                    backgroundColor: "#ebebeb",
                                    maxWidth: "210px",
                                  }}
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  list="EmpAddresss"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter state"
                                  value={perDetails?.state}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      state: e.target.value,
                                    })
                                  }
                                />
                                <datalist id="EmpAddresss">
                                  {addressList?.state
                                    ?.filter(
                                      (value, index, self) =>
                                        self.indexOf(value) === index
                                    )
                                    ?.map((ele, index) => (
                                      <option key={index} value={ele}></option>
                                    ))}
                                </datalist>
                              </div>
                            ) : (
                              <div className="">
                                {empDetails.state ? empDetails.state : "-"}
                              </div>
                            )}
                          </li> */}
                          {/* <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Country
                            </div>
                            {perInfo ? (
                              <div class="form-group">
                                <input
                                  type="text"
                                  style={{
                                    height: "40px",
                                    border: "1px solid #b4b3b1",
                                    backgroundColor: "#ebebeb",
                                    maxWidth: "210px",
                                  }}
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  list="EmpAddressCont"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter country"
                                  value={perDetails?.country}
                                  onChange={(e) =>
                                    setPerDetails({
                                      ...perDetails,
                                      country: e.target.value,
                                    })
                                  }
                                />
                                <datalist id="EmpAddressCont">
                                  {addressList?.country
                                    ?.filter(
                                      (value, index, self) =>
                                        self.indexOf(value) === index
                                    )
                                    ?.map((ele, index) => (
                                      <option key={index} value={ele}></option>
                                    ))}
                                </datalist>
                              </div>
                            ) : (
                              <div className="">
                                {empDetails.country ? empDetails.country : "-"}
                              </div>
                            )}
                          </li> */}
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Nationality
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.nationality}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    nationality: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.nationality
                                  ? empDetails.nationality
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Blood Group
                            </div>

                            {perInfo ? (
                              <>
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    styles={{
                                      // Fixes the overlapping problem of the component
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="Blood Group"
                                    options={bloodGroup}
                                    value={
                                      perDetails.blood_group
                                        ? {
                                            label: perDetails.blood_group,
                                            value: perDetails.blood_group,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        blood_group: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                                {/* <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.blood_group}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    blood_group: e.target.value,
                                  })
                                }
                                /> */}
                              </>
                            ) : (
                              <div className="">
                                {empDetails.blood_group
                                  ? empDetails.blood_group
                                  : "-"}
                              </div>
                            )}
                          </li>

                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Father Name/Husband Name
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.father_name}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    father_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.father_name
                                  ? empDetails.father_name
                                  : "-"}
                              </div>
                            )}
                          </li>

                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Mother Name
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.mother_name}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    mother_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.mother_name
                                  ? empDetails.mother_name
                                  : "-"}
                              </div>
                            )}
                          </li>

                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Marital Status
                            </div>

                            {perInfo ? (
                              <>
                                <FormControl
                                  sx={{
                                    minWidth: 210,
                                    backgroundColor: "#e9eaec",
                                  }}
                                >
                                  <Select
                                    inputId="single-select-example"
                                    className="single-select"
                                    classNamePrefix="react-select"
                                    placeholder="marital status"
                                    options={maritalStatus}
                                    value={
                                      perDetails.martial_status
                                        ? {
                                            label: perDetails.martial_status,
                                            value: perDetails.martial_status,
                                          }
                                        : null
                                    }
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        martial_status: e.value,
                                      })
                                    }
                                  />
                                </FormControl>
                                {/* <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.martial_status}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    martial_status: e.target.value,
                                  })
                                }
                                /> */}
                              </>
                            ) : (
                              <div className="">
                                {empDetails.martial_status
                                  ? empDetails.martial_status
                                  : "-"}
                              </div>
                            )}
                          </li>
                          {perDetails.martial_status === "Married" && (
                            <>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Spouse Name
                                </div>

                                {perInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    value={perDetails.spouse_name}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        spouse_name: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {empDetails.spouse_name
                                      ? empDetails.spouse_name
                                      : "-"}
                                  </div>
                                )}
                              </li>

                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Date of Marriage
                                </div>

                                {perInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    type="date"
                                    sx={{ width: "210px" }}
                                    InputProps={{
                                      inputProps: { max: "9999-12-31" },
                                    }}
                                    value={perDetails.marriage_date}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        marriage_date: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {empDetails.marriage_date
                                      ? empDetails.marriage_date
                                          ?.slice(0, 10)
                                          ?.split("-")
                                          ?.reverse()
                                          ?.join("-")
                                      : "-"}
                                  </div>
                                )}
                              </li>

                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Spouse Employment
                                </div>

                                {perInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    value={perDetails.empl_of_spouce}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        empl_of_spouce: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {empDetails.empl_of_spouce
                                      ? empDetails.empl_of_spouce
                                      : "-"}
                                  </div>
                                )}
                              </li>
                              <li>
                                <div
                                  className="title"
                                  style={{ fontWeight: "600" }}
                                >
                                  Children
                                </div>

                                {perInfo ? (
                                  <TextField
                                    id="outlined-size-small"
                                    size="small"
                                    type="number"
                                    value={perDetails.offspring_no}
                                    onChange={(e) =>
                                      setPerDetails({
                                        ...perDetails,
                                        offspring_no: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="">
                                    {empDetails.offspring_no
                                      ? empDetails.offspring_no
                                      : "-"}
                                  </div>
                                )}
                              </li>
                            </>
                          )}
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Facebook
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.facebook}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    facebook: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.facebook
                                  ? empDetails.facebook
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              LinkedIn
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.linkedin}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    linkedin: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.linkedin
                                  ? empDetails.linkedin
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Twitter
                            </div>

                            {perInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={perDetails.twitter}
                                onChange={(e) =>
                                  setPerDetails({
                                    ...perDetails,
                                    twitter: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.twitter ? empDetails.twitter : "-"}
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <SBox>
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Emergency Contact
                            {!emergencyInfo ? (
                              <IconButton
                                sx={{ color: "#000000", mb: 1 }}
                                onClick={() => setEmergencyInfo(true)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </h3>

                          <Box>
                            {emergencyInfo ? (
                              <>
                                <SButton2
                                  onClick={() => {
                                    setEmergencyInfo(false);
                                    //EditEmployeeDetails();
                                  }}
                                >
                                  Cancel
                                </SButton2>

                                <SButton1
                                  onClick={() => {
                                    setEmergencyInfo(false);
                                    employeeDetailsUpdate();
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            ) : null}
                          </Box>
                        </SBox>

                        <h5 className="section-title">Primary </h5>
                        <ul className="personal-info">
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Name
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={emergencyDetails.primary_name}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    primary_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.primary_name
                                  ? empDetails.primary_name
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Relationship
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={emergencyDetails.primary_relation}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    primary_relation: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.primary_relation
                                  ? empDetails.primary_relation
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Phone{" "}
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="number"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                defaultValue={emergencyDetails.primary_phone}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    primary_phone: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.primary_phone
                                  ? empDetails.primary_phone
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Email ID
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="email"
                                value={emergencyDetails.primary_email}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    primary_email: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.primary_email
                                  ? empDetails.primary_email
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Blood Group
                            </div>

                            {emergencyInfo ? (
                              <FormControl
                                sx={{
                                  minWidth: 210,
                                  backgroundColor: "#e9eaec",
                                }}
                              >
                                <Select
                                  inputId="single-select-example"
                                  className="single-select"
                                  classNamePrefix="react-select"
                                  placeholder="Blood Group"
                                  options={bloodGroup}
                                  value={
                                    emergencyDetails.primary_blood_group
                                      ? {
                                          label:
                                            emergencyDetails.primary_blood_group,
                                          value:
                                            emergencyDetails.primary_blood_group,
                                        }
                                      : null
                                  }
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      primary_blood_group: e.value,
                                    })
                                  }
                                />
                              </FormControl>
                            ) : (
                              // <TextField
                              //   id="outlined-size-small"
                              //   size="small"
                              //   value={emergencyDetails.primary_blood_group}
                              //   onChange={(e) =>
                              //     setEmergencyDetails({
                              //       ...emergencyDetails,
                              //       primary_blood_group: e.target.value,
                              //     })
                              //   }
                              // />
                              <div className="">
                                {empDetails.primary_blood_group
                                  ? empDetails.primary_blood_group
                                  : "-"}
                              </div>
                            )}
                          </li>
                        </ul>
                        <hr />
                        <h5 className="section-title">Secondary</h5>
                        <ul className="personal-info">
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Name
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={emergencyDetails.secondary_name}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    secondary_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.secondary_name
                                  ? empDetails.secondary_name
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Relationship
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={emergencyDetails.secondary_relation}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    secondary_relation: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.secondary_relation
                                  ? empDetails.secondary_relation
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Phone{" "}
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="number"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 10);
                                }}
                                defaultValue={empDetails.secondary_phone}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    secondary_phone: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.secondary_phone
                                  ? empDetails.secondary_phone
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Email ID
                            </div>

                            {emergencyInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                type="email"
                                value={emergencyDetails.secondary_email}
                                onChange={(e) =>
                                  setEmergencyDetails({
                                    ...emergencyDetails,
                                    secondary_email: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.secondary_email
                                  ? empDetails.secondary_email
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Blood Group
                            </div>

                            {emergencyInfo ? (
                              <FormControl
                                sx={{
                                  minWidth: 210,
                                  backgroundColor: "#e9eaec",
                                }}
                              >
                                <Select
                                  inputId="single-select-example"
                                  className="single-select"
                                  classNamePrefix="react-select"
                                  placeholder="Blood Group"
                                  options={bloodGroup}
                                  value={
                                    emergencyDetails.secondary_blood_group
                                      ? {
                                          label:
                                            emergencyDetails.secondary_blood_group,
                                          value:
                                            emergencyDetails.secondary_blood_group,
                                        }
                                      : null
                                  }
                                  onChange={(e) =>
                                    setEmergencyDetails({
                                      ...emergencyDetails,
                                      secondary_blood_group: e.value,
                                    })
                                  }
                                />
                              </FormControl>
                            ) : (
                              // <TextField
                              //   id="outlined-size-small"
                              //   size="small"
                              //   value={emergencyDetails.secondary_blood_group}
                              //   onChange={(e) =>
                              //     setEmergencyDetails({
                              //       ...emergencyDetails,
                              //       secondary_blood_group: e.target.value,
                              //     })
                              //   }
                              // />
                              <div className="">
                                {empDetails.secondary_blood_group
                                  ? empDetails.secondary_blood_group
                                  : "-"}
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <SBox>
                          <h3
                            className="card-title"
                            style={{ fontWeight: "700" }}
                          >
                            Bank Information
                            {!bankInfo ? (
                              <IconButton
                                sx={{ color: "#000000", mb: 1 }}
                                onClick={() => setBankInfo(true)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </h3>

                          <Box>
                            {bankInfo ? (
                              <>
                                <SButton2
                                  onClick={() => {
                                    setBankInfo(false);
                                    //EditEmployeeDetails();
                                  }}
                                >
                                  Cancel
                                </SButton2>

                                <SButton1
                                  onClick={() => {
                                    setBankInfo(false);
                                    employeeDetailsUpdate();
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            ) : null}
                          </Box>
                        </SBox>

                        <ul className="personal-info">
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Bank Name
                            </div>

                            {bankInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={bankDetails.bank_name}
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    bank_name: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.bank_name
                                  ? empDetails.bank_name
                                  : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              Bank Account No.
                            </div>

                            {bankInfo ? (
                              <TextField
                                sx={{ boxSizing: "inherit" }}
                                id="outlined-size-small"
                                size="small"
                                type="password"
                                value={bankDetails.acc_no}
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    acc_no: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.acc_no ? empDetails.acc_no : "-"}
                              </div>
                            )}
                          </li>
                          {bankInfo && (
                            <li>
                              <div
                                className="title"
                                style={{ fontWeight: "600" }}
                              >
                                Confirm Bank Account No.
                              </div>
                              {bankInfo ? (
                                <>
                                  <TextField
                                    error={
                                      bankDetails.acc_no ===
                                      bankDetails.confirm_acc_no
                                        ? false
                                        : true
                                    }
                                    sx={{ boxSizing: "inherit" }}
                                    id="standard-basic"
                                    size="small"
                                    type="text"
                                    helperText={
                                      bankDetails.acc_no ==
                                      bankDetails.confirm_acc_no
                                        ? ""
                                        : "account number not matched"
                                    }
                                    // className={classes.textField}
                                    defaultValue={bankDetails?.acc_no}
                                    onChange={(e) =>
                                      setBankDetails({
                                        ...bankDetails,
                                        confirm_acc_no: e.target.value,
                                      })
                                    }
                                  />
                                </>
                              ) : (
                                <div className="">
                                  {empDetails.acc_no ? empDetails.acc_no : "-"}
                                </div>
                              )}
                            </li>
                          )}
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              IFSC Code
                            </div>

                            {bankInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={bankDetails.ifsc}
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    ifsc: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.ifsc ? empDetails.ifsc : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              PAN No
                            </div>

                            {bankInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={bankDetails.pan}
                                disabled
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    pan: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.pan ? empDetails.pan : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              UAN
                            </div>

                            {bankInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={bankDetails.uan}
                                disabled
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    uan: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.uan ? empDetails.uan : "-"}
                              </div>
                            )}
                          </li>
                          <li>
                            <div
                              className="title"
                              style={{ fontWeight: "600" }}
                            >
                              ESI
                            </div>

                            {bankInfo ? (
                              <TextField
                                id="outlined-size-small"
                                size="small"
                                value={bankDetails.esi}
                                disabled
                                onChange={(e) =>
                                  setBankDetails({
                                    ...bankDetails,
                                    esi: e.target.value,
                                  })
                                }
                              />
                            ) : (
                              <div className="">
                                {empDetails.esi ? empDetails.esi : "-"}
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <h3
                          className="card-title"
                          style={{ fontWeight: "700" }}
                        >
                          Experience{" "}
                          {addExperience?.length === 0 ? (
                            !experienceInfo ? null : (
                              <>
                                <SButton2
                                  onClick={() => setExperienceInfo(false)}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={() => {
                                    employeeDetailsUpdate();
                                    setExperienceInfo(false);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )
                          ) : !experienceInfo ? (
                            <IconButton
                              sx={{ color: "#3298DB" }}
                              onClick={() => setExperienceInfo(true)}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <>
                              <SButton2
                                onClick={async () => {
                                  await employeeDetails();
                                  setExperienceInfo(false);
                                }}
                              >
                                X
                              </SButton2>
                              <SButton1
                                onClick={async () => {
                                  await employeeDetailsUpdate();
                                  await employeeDetails();
                                  setExperienceInfo(false);
                                }}
                              >
                                Save
                              </SButton1>
                            </>
                          )}
                        </h3>

                        {addExperience?.length === 0 ? (
                          !experienceInfo ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: "180px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setExperienceInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          ) : null
                        ) : null}

                        <div className="experience-box">
                          {experienceInfo ? (
                            <>
                              {addExperience.map((v, i) => {
                                return (
                                  <>
                                    <TextField
                                      type="text"
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="Designation"
                                      value={v.designation}
                                      //value={v.education}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm2(
                                          i,
                                          "designation",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <br />

                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="Company Name"
                                      value={v.company_name}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm2(
                                          i,
                                          "company_name",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="CTC"
                                      type="number"
                                      value={v.ctc}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm2(i, "ctc", e.target.value)
                                      }
                                    />
                                    <br />
                                    <Select
                                      placeholder="Industry"
                                      options={industryList}
                                      value={
                                        v.industry
                                          ? {
                                              label: v.industry,
                                              value: v.industry,
                                            }
                                          : null
                                      }
                                      onChange={(event) =>
                                        handleFormChange112(
                                          i,
                                          event,
                                          addExperience,
                                          setAddExperience
                                        )
                                      }
                                      type="text"
                                      id="outlined-size-small"
                                      size="small"
                                    />

                                    <br />
                                    {/* <div class="form-group">
                                   
                                      <input
                                        type="text"
                                        style={{
                                          height: "40px",
                                          border: "1px solid #b4b3b1",
                                          backgroundColor: "#ebebeb",
                                        }}
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        list="EmpAddressCity"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter city"
                                        value={v.city}
                                        onChange={(e) =>
                                          HandleForm2(i, "city", e.target.value)
                                        }
                                      />
                                      <datalist id="EmpAddressCity">
                                        {addressList?.city
                                          ?.filter(
                                            (item, index, self) =>
                                              self.indexOf(item) === index
                                          )
                                          ?.map((ele, index) => (
                                            <option
                                              key={index}
                                              value={ele}
                                            ></option>
                                          ))}
                                      </datalist>
                                    </div> */}
                                    {/* <div class="form-group">
                                      
                                      <input
                                        type="text"
                                        style={{
                                          height: "40px",
                                          border: "1px solid #b4b3b1",
                                          backgroundColor: "#ebebeb",
                                        }}
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        list="EmpAddressState"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter state"
                                        onChange={(e) =>
                                          HandleForm2(
                                            i,
                                            "state",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <datalist id="EmpAddressState">
                                        {addressList?.state
                                          ?.filter(
                                            (item, index, self) =>
                                              self.indexOf(item) === index
                                          )
                                          ?.map((ele, index) => (
                                            <option
                                              key={index}
                                              value={ele}
                                            ></option>
                                          ))}
                                      </datalist>
                                    </div> */}
                                    {/* <div class="form-group">
                                     
                                      <input
                                        type="text"
                                        style={{
                                          height: "40px",
                                          border: "1px solid #b4b3b1",
                                          backgroundColor: "#ebebeb",
                                        }}
                                        class="form-control"
                                        id="exampleInputEmail1"
                                        list="EmpAddressCon"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter country"
                                        onChange={(e) =>
                                          HandleForm2(
                                            i,
                                            "country",
                                            e.target.value
                                          )
                                        }
                                      />
                                      <datalist id="EmpAddressCon">
                                        {addressList?.country
                                          ?.filter(
                                            (item, index, self) =>
                                              self.indexOf(item) === index
                                          )
                                          ?.map((ele, index) => (
                                            <option
                                              key={index}
                                              value={ele}
                                            ></option>
                                          ))}
                                      </datalist>
                                    </div> */}

                                    <Typography variant="caption">
                                      From
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.from}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm2(i, "from", e.target.value)
                                      }
                                    />
                                    <br />

                                    <Typography variant="caption">
                                      To
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.to}
                                      sx={{ width: "100%", mb: 1 }}
                                      onChange={(e) =>
                                        HandleForm2(i, "to", e.target.value)
                                      }
                                    />

                                    <SButton2
                                      sx={{ mb: 2 }}
                                      onClick={() => RemoveField2(i)}
                                    >
                                      Delete
                                    </SButton2>
                                  </>
                                );
                              })}

                              <Box sx={{ textAlign: "center", width: "100%" }}>
                                <SButton1 onClick={(e) => AddFields2(e)}>
                                  Add
                                </SButton1>
                              </Box>
                            </>
                          ) : (
                            <>
                              {addExperience.length !== 0 ? (
                                <TableContainer component={Paper}>
                                  <Tablemui
                                    sx={{ minWidth: 250 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Company Name</TableCell>

                                        <TableCell>Designation</TableCell>
                                        <TableCell>CTC</TableCell>
                                        <TableCell>Industry</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>From</TableCell>

                                        <TableCell>To</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {addExperience?.map((ele) => {
                                        return (
                                          <TableRow>
                                            <TableCell>
                                              {ele.company_name}
                                            </TableCell>

                                            <TableCell>
                                              {ele.designation}
                                            </TableCell>
                                            <TableCell>{ele.ctc}</TableCell>
                                            <TableCell>
                                              {ele.industry}
                                            </TableCell>
                                            <TableCell>
                                              {ele.city +
                                                ", " +
                                                ele.state +
                                                ", " +
                                                ele.country}
                                            </TableCell>
                                            <TableCell>
                                              {ele.from
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>

                                            <TableCell>
                                              {ele.to
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Tablemui>
                                </TableContainer>
                              ) : null}
                              {/*
                              {addExperience?.map((v) => {
                                return (
                                  <ul className="experience-list">
                                    <li>
                                      <div className="experience-user">
                                        <div className="before-circle" />
                                      </div>
                                      <div className="experience-content">
                                        <div className="timeline-content">
                                          <a href="/" className="name">
                                            {`${v.designation} at ${v.company_name}`}
                                          </a>
                                          <span className="time">
                                            {`${v.from} to ${v.to}`}
                                          </span>
                                          <br />
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                );
                              })}
                              */}
                            </>
                          )}

                          {/*
                          <ul className="experience-list">
                            {
                              empDetails.experience?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <SButton1>
                                    +
                                  </SButton1>
                                </div>
                              ) : (
                                empDetails.experience?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.designation} at ${ele.company_name}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )

                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>
                          */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <h3
                          className="card-title"
                          style={{ fontWeight: "700" }}
                        >
                          Education{" "}
                          {addEducation?.length === 0 ? (
                            !educationInfo ? null : (
                              <>
                                <SButton2
                                  onClick={() => setEducationInfo(false)}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={() => {
                                    employeeDetailsUpdate();
                                    setEducationInfo(false);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )
                          ) : !educationInfo ? (
                            <IconButton
                              sx={{ color: "#3298DB" }}
                              onClick={() => setEducationInfo(true)}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <>
                              <SButton2
                                onClick={async () => {
                                  await employeeDetails();
                                  setEducationInfo(false);
                                }}
                              >
                                X
                              </SButton2>
                              <SButton1
                                onClick={async () => {
                                  await employeeDetailsUpdate();
                                  await employeeDetails();
                                  setEducationInfo(false);
                                  setAddEducation([]);
                                }}
                              >
                                Save
                              </SButton1>
                            </>
                          )}
                        </h3>

                        {addEducation?.length === 0 ? (
                          !educationInfo ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: "180px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setEducationInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          ) : null
                        ) : null}

                        <div className="experience-box">
                          {educationInfo ? (
                            <>
                              {addEducation.map((v, i) => {
                                return (
                                  <>
                                    {/* <TextField
                                      type="text"
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="Education"
                                      value={v.education}
                                      //value={v.education}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm(
                                          i,
                                          "education",
                                          e.target.value
                                        )
                                      }
                                    /> */}
                                    <Select
                                      placeholder="Education"
                                      options={qualificationList}
                                      value={
                                        v.education
                                          ? {
                                              label: v.education,
                                              value: v.education,
                                            }
                                          : null
                                      }
                                      onChange={(event) =>
                                        handleFormChange111(
                                          i,
                                          event,
                                          addEducation,
                                          setAddEducation
                                        )
                                      }
                                      type="text"
                                      id="outlined-size-small"
                                      size="small"
                                    />
                                    <br />

                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="College"
                                      defaultValue={v.college}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm(i, "college", e.target.value)
                                      }
                                    />
                                    <br />

                                    <Typography variant="caption">
                                      From
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.from}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm(i, "from", e.target.value)
                                      }
                                    />
                                    <br />

                                    <Typography variant="caption">
                                      To
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.to}
                                      sx={{ width: "100%", mb: 1 }}
                                      onChange={(e) =>
                                        HandleForm(i, "to", e.target.value)
                                      }
                                    />

                                    <SButton2
                                      sx={{ mb: 2 }}
                                      onClick={() => RemoveField(i)}
                                    >
                                      Delete
                                    </SButton2>
                                  </>
                                );
                              })}

                              <Box sx={{ textAlign: "center", width: "100%" }}>
                                <SButton1 onClick={(e) => AddFields(e)}>
                                  Add
                                </SButton1>
                              </Box>
                            </>
                          ) : (
                            <>
                              {addEducation.length !== 0 ? (
                                <TableContainer component={Paper}>
                                  <Tablemui
                                    sx={{ minWidth: 250 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>College/Institute</TableCell>

                                        <TableCell>Degree</TableCell>

                                        <TableCell>From</TableCell>

                                        <TableCell>To</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {addEducation?.map((ele) => {
                                        return (
                                          <TableRow>
                                            <TableCell>{ele.college}</TableCell>

                                            <TableCell>
                                              {ele.education}
                                            </TableCell>

                                            <TableCell>
                                              {ele.from
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>

                                            <TableCell>
                                              {ele.to
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Tablemui>
                                </TableContainer>
                              ) : null}
                              {/** 
                              {addEducation?.map((v) => {
                                return (
                                  <>
                                    <ul className="experience-list">
                                      <li>
                                        <div className="experience-user">
                                          <div className="before-circle" />
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="/" className="name">
                                              {`${v.education} at ${v.college}`}
                                            </a>
                                            <span className="time">
                                              {`${v.from} to ${v.to}`}
                                            </span>
                                            <br />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </>
                                );
                              })}
                              */}
                            </>
                          )}

                          {/*
                          <ul className="experience-list">
                            {
                              empDetails.emp_education?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{ height: "100px" }}
                                    src={empty}
                                  />
                                  <p>
                                    <b>No Data Found</b>
                                  </p>
                                </div>
                              ) : (
                                empDetails.emp_education?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.education} at ${ele.college}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )
                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", overflowY: "auto" }}
                      >
                        <h3
                          className="card-title"
                          style={{ fontWeight: "700" }}
                        >
                          Certification{" "}
                          {addCertificates.length === 0 ? (
                            !certificatesInfo ? null : (
                              <>
                                <SButton2
                                  onClick={() => setCertificatesInfo(false)}
                                >
                                  X
                                </SButton2>
                                <SButton1
                                  onClick={() => {
                                    employeeDetailsUpdate();
                                    setCertificatesInfo(false);
                                  }}
                                >
                                  Save
                                </SButton1>
                              </>
                            )
                          ) : !certificatesInfo ? (
                            <IconButton
                              sx={{ color: "#3298DB" }}
                              onClick={() => setCertificatesInfo(true)}
                            >
                              <AddCircleOutlineIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <>
                              <SButton2
                                onClick={async () => {
                                  await employeeDetails();
                                  setCertificatesInfo(false);
                                }}
                              >
                                X
                              </SButton2>
                              <SButton1
                                onClick={async () => {
                                  await employeeDetailsUpdate();
                                  await employeeDetails();
                                  setCertificatesInfo(false);
                                }}
                              >
                                Save
                              </SButton1>
                            </>
                          )}
                        </h3>

                        {addCertificates?.length === 0 ? (
                          !certificatesInfo ? (
                            <Box
                              sx={{
                                width: "100%",
                                height: "180px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                sx={{ color: "#3298DB" }}
                                onClick={() => setCertificatesInfo(true)}
                              >
                                <AddCircleOutlineIcon fontSize="large" />
                              </IconButton>
                            </Box>
                          ) : null
                        ) : null}

                        <div className="experience-box">
                          {certificatesInfo ? (
                            <>
                              {addCertificates.map((v, i) => {
                                return (
                                  <>
                                    <TextField
                                      type="text"
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="Course"
                                      value={v.course}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm1(i, "course", e.target.value)
                                      }
                                    />
                                    <br />

                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      placeholder="Institute"
                                      value={v.institute}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm1(
                                          i,
                                          "institute",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <br />

                                    <Typography variant="caption">
                                      From
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.from}
                                      sx={{ width: "100%", mb: 0.5 }}
                                      onChange={(e) =>
                                        HandleForm1(i, "from", e.target.value)
                                      }
                                    />
                                    <br />

                                    <Typography variant="caption">
                                      To
                                    </Typography>
                                    <br />
                                    <TextField
                                      id="outlined-size-small"
                                      size="small"
                                      type="date"
                                      InputProps={{
                                        inputProps: {
                                          min: "1000-05-01",
                                          max: "9999-12-31",
                                        },
                                      }}
                                      value={v.to}
                                      sx={{ width: "100%", mb: 1 }}
                                      onChange={(e) =>
                                        HandleForm1(i, "to", e.target.value)
                                      }
                                    />

                                    <SButton2
                                      sx={{ mb: 2 }}
                                      onClick={() => RemoveField1(i)}
                                    >
                                      Delete
                                    </SButton2>
                                  </>
                                );
                              })}

                              <Box sx={{ textAlign: "center", width: "100%" }}>
                                <SButton1 onClick={(e) => AddFields1(e)}>
                                  Add
                                </SButton1>
                              </Box>
                            </>
                          ) : (
                            <>
                              {addCertificates.length !== 0 ? (
                                <TableContainer component={Paper}>
                                  <Tablemui
                                    sx={{ minWidth: 250 }}
                                    size="small"
                                    aria-label="a dense table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Institute</TableCell>

                                        <TableCell>Course</TableCell>

                                        <TableCell>From</TableCell>

                                        <TableCell>To</TableCell>
                                      </TableRow>
                                    </TableHead>

                                    <TableBody>
                                      {addCertificates?.map((ele) => {
                                        return (
                                          <TableRow>
                                            <TableCell>
                                              {ele.institute}
                                            </TableCell>

                                            <TableCell>{ele.course}</TableCell>

                                            <TableCell>
                                              {ele.from
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>

                                            <TableCell>
                                              {ele.to
                                                ?.split("-")
                                                ?.reverse()
                                                ?.join("-")}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Tablemui>
                                </TableContainer>
                              ) : null}
                              {/*
                              {addCertificates?.map((v) => {
                                return (
                                  <>
                                    <ul className="experience-list">
                                      <li>
                                        <div className="experience-user">
                                          <div className="before-circle" />
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="/" className="name">
                                              {`${v.course} at ${v.institute}`}
                                            </a>
                                            <span className="time">
                                              {`${v.from} to ${v.to}`}
                                            </span>
                                            <br />
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </>
                                );
                              })}
                              */}
                            </>
                          )}
                          {/*
                          <ul className="experience-list">
                            {
                              empDetails.certification?.length === 0 ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{ height: "100px" }}
                                    src={empty}
                                  />
                                  <p>
                                    <b>No Data Found</b>
                                  </p>
                                </div>
                              ) : (
                                empDetails.certification?.map((ele) => (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle" />
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a href="/" className="name">
                                          {`${ele.course} at ${ele.institute}`}
                                        </a>
                                        <span className="time">
                                          {`${ele.from} to ${ele.to}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )
                              // : emptyArr.map((ele) => (
                              //     <li>
                              //       <div className="experience-user">
                              //         <div className="before-circle" />
                              //       </div>
                              //       <div className="experience-content">
                              //         <div className="timeline-content">
                              //           <a href="/" className="name">
                              //             Web Designer at Zen Corporation
                              //           </a>
                              //           <span className="time">
                              //             Jan 2013 - Present (5 years 2 months)
                              //           </span>
                              //         </div>
                              //       </div>
                              //     </li>
                              //   ))
                            }
                          </ul>
                          */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Profile Info Tab */}
              {/* Projects Tab */}
              <div className="tab-pane fade" id="emp_projects">
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="dropdown profile-action">
                          <a
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            className="action-icon dropdown-toggle"
                            href="#"
                          >
                            <i className="material-icons">more_vert</i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              data-bs-target="#edit_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-pencil m-r-5" /> Edit
                            </a>
                            <a
                              data-bs-target="#delete_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-trash-o m-r-5" /> Delete
                            </a>
                          </div>
                        </div>
                        <h4 className="project-title">
                          <Link to="/app/projects/projects-view">
                            Office Management
                          </Link>
                        </h4>
                        <small className="block text-ellipsis m-b-15">
                          <span className="text-xs">1</span>{" "}
                          <span className="text-muted">open tasks, </span>
                          <span className="text-xs">9</span>{" "}
                          <span className="text-muted">tasks completed</span>
                        </small>
                        <p className="text-muted">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. When an unknown printer took a
                          galley of type and scrambled it...
                        </p>
                        <div className="pro-deadline m-b-15">
                          <div className="sub-title">Deadline:</div>
                          <div className="text-muted">17 Apr 2019</div>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Project Leader :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a> */}
                            </li>
                          </ul>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Team :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a> */}
                            </li>
                            <li>
                              <a href="#" className="all-users">
                                +15
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="m-b-5">
                          Progress{" "}
                          <span className="text-success float-end">40%</span>
                        </p>
                        <div className="progress progress-xs mb-0">
                          <div
                            style={{ width: "40%" }}
                            data-bs-toggle="tooltip"
                            role="progressbar"
                            className="progress-bar bg-success"
                            data-original-title="40%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="dropdown profile-action">
                          <a
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            className="action-icon dropdown-toggle"
                            href="#"
                          >
                            <i className="material-icons">more_vert</i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              data-bs-target="#edit_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-pencil m-r-5" /> Edit
                            </a>
                            <a
                              data-bs-target="#delete_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-trash-o m-r-5" /> Delete
                            </a>
                          </div>
                        </div>
                        <h4 className="project-title">
                          <Link to="/app/projects/projects-view">
                            Project Management
                          </Link>
                        </h4>
                        <small className="block text-ellipsis m-b-15">
                          <span className="text-xs">2</span>{" "}
                          <span className="text-muted">open tasks, </span>
                          <span className="text-xs">5</span>{" "}
                          <span className="text-muted">tasks completed</span>
                        </small>
                        <p className="text-muted">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. When an unknown printer took a
                          galley of type and scrambled it...
                        </p>
                        <div className="pro-deadline m-b-15">
                          <div className="sub-title">Deadline:</div>
                          <div className="text-muted">17 Apr 2019</div>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Project Leader :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a> */}
                            </li>
                          </ul>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Team :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a> */}
                            </li>
                            <li>
                              <a href="#" className="all-users">
                                +15
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="m-b-5">
                          Progress{" "}
                          <span className="text-success float-end">40%</span>
                        </p>
                        <div className="progress progress-xs mb-0">
                          <div
                            style={{ width: "40%" }}
                            data-bs-toggle="tooltip"
                            role="progressbar"
                            className="progress-bar bg-success"
                            data-original-title="40%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="dropdown profile-action">
                          <a
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            className="action-icon dropdown-toggle"
                            href="#"
                          >
                            <i className="material-icons">more_vert</i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              data-bs-target="#edit_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-pencil m-r-5" /> Edit
                            </a>
                            <a
                              data-bs-target="#delete_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-trash-o m-r-5" /> Delete
                            </a>
                          </div>
                        </div>
                        <h4 className="project-title">
                          <Link to="/app/projects/projects-view">
                            Video Calling App
                          </Link>
                        </h4>
                        <small className="block text-ellipsis m-b-15">
                          <span className="text-xs">3</span>{" "}
                          <span className="text-muted">open tasks, </span>
                          <span className="text-xs">3</span>{" "}
                          <span className="text-muted">tasks completed</span>
                        </small>
                        <p className="text-muted">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. When an unknown printer took a
                          galley of type and scrambled it...
                        </p>
                        <div className="pro-deadline m-b-15">
                          <div className="sub-title">Deadline:</div>
                          <div className="text-muted">17 Apr 2019</div>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Project Leader :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a> */}
                            </li>
                          </ul>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Team :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a> */}
                            </li>
                            <li>
                              <a href="#" className="all-users">
                                +15
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="m-b-5">
                          Progress{" "}
                          <span className="text-success float-end">40%</span>
                        </p>
                        <div className="progress progress-xs mb-0">
                          <div
                            style={{ width: "40%" }}
                            data-bs-toggle="tooltip"
                            role="progressbar"
                            className="progress-bar bg-success"
                            data-original-title="40%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="dropdown profile-action">
                          <a
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            className="action-icon dropdown-toggle"
                            href="#"
                          >
                            <i className="material-icons">more_vert</i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              data-bs-target="#edit_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-pencil m-r-5" /> Edit
                            </a>
                            <a
                              data-bs-target="#delete_project"
                              data-bs-toggle="modal"
                              href="#"
                              className="dropdown-item"
                            >
                              <i className="fa fa-trash-o m-r-5" /> Delete
                            </a>
                          </div>
                        </div>
                        <h4 className="project-title">
                          <Link to="/app/projects/projects-view">
                            Hospital Administration
                          </Link>
                        </h4>
                        <small className="block text-ellipsis m-b-15">
                          <span className="text-xs">12</span>{" "}
                          <span className="text-muted">open tasks, </span>
                          <span className="text-xs">4</span>{" "}
                          <span className="text-muted">tasks completed</span>
                        </small>
                        <p className="text-muted">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. When an unknown printer took a
                          galley of type and scrambled it...
                        </p>
                        <div className="pro-deadline m-b-15">
                          <div className="sub-title">Deadline:</div>
                          <div className="text-muted">17 Apr 2019</div>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Project Leader :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Jeffery Lalor"><img alt="" src={Avatar_16} /></a> */}
                            </li>
                          </ul>
                        </div>
                        <div className="project-members m-b-15">
                          <div>Team :</div>
                          <ul className="team-members">
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Doe"><img alt="" src={Avatar_02} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Richard Miles"><img alt="" src={Avatar_09} /></a>*/}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="John Smith"><img alt="" src={Avatar_10} /></a> */}
                            </li>
                            <li>
                              {/* <a href="#" data-bs-toggle="tooltip" title="Mike Litorus"><img alt="" src={Avatar_05} /></a> */}
                            </li>
                            <li>
                              <a href="#" className="all-users">
                                +15
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="m-b-5">
                          Progress{" "}
                          <span className="text-success float-end">40%</span>
                        </p>
                        <div className="progress progress-xs mb-0">
                          <div
                            style={{ width: "40%" }}
                            data-bs-toggle="tooltip"
                            role="progressbar"
                            className="progress-bar bg-success"
                            data-original-title="40%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Projects Tab */}
              {/* Bank Statutory Tab */}
              <div className="tab-pane fade" id="bank_statutory">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title"> Basic Salary Information</h3>
                    <form>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Salary basis{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select salary basis type</option>
                              <option>Hourly</option>
                              <option>Daily</option>
                              <option>Weekly</option>
                              <option>Monthly</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Salary amount{" "}
                              <small className="text-muted">per month</small>
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Type your salary amount"
                                defaultValue={0.0}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Payment type
                            </label>
                            <select className="select">
                              <option>Select payment type</option>
                              <option>Bank transfer</option>
                              <option>Check</option>
                              <option>Cash</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3 className="card-title"> PF Information</h3>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              PF contribution
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              PF No. <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee PF rate
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              defaultValue="11%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee PF rate
                            </label>
                            <select className="select">
                              <option>Select PF contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              defaultValue="11%"
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h3 className="card-title"> ESI Information</h3>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              ESI contribution
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              ESI No. <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Employee ESI rate
                            </label>
                            <select className="select">
                              <option>Select ESI contribution</option>
                              <option>Yes</option>
                              <option>No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">
                              Additional rate{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select className="select">
                              <option>Select additional rate</option>
                              <option>0%</option>
                              <option>1%</option>
                              <option>2%</option>
                              <option>3%</option>
                              <option>4%</option>
                              <option>5%</option>
                              <option>6%</option>
                              <option>7%</option>
                              <option>8%</option>
                              <option>9%</option>
                              <option>10%</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label className="col-form-label">Total rate</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="N/A"
                              defaultValue="11%"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="submit-section1">
                        <button
                          className="btn btn-primary submit-btn"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* /Bank Statutory Tab */}
            </div>
          </div>
        ) : (
          <div
            // id="profile_info"
            // className="modal custom-modal fade" // role="dialog"
            style={{ background: "white" }}
          >
            <div
              // className="modal-dialog modal-dialog-centered modal-lg"
              // role="document"
              className="content container-fluid"
              style={{ background: "white" }}
            >
              <div className="card-mb-0">
                <div
                  style={{ marginTop: "-40px", display: "flex" }}
                  className="card-body"
                >
                  <h3>
                    <b>Profile Information</b>
                  </h3>
                  &nbsp; &nbsp;
                  <div style={{ marginTop: "3px" }}>
                    <InlineMessage
                      appearance="connectivity"
                      iconLabel="Log in to see more information"
                    >
                      <p>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        Please! Contact to Admin for changes in the fields which
                        are not editable in this form
                      </p>
                    </InlineMessage>
                  </div>
                </div>

                <div className="card-mb-0">
                  <div
                    style={{
                      height: "61vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      zIndex: "-1",
                      marginTop: "-5px",
                    }}
                  >
                    <form>
                      <div className="card">
                        <div className="card-body">
                          <h4>
                            {" "}
                            <b>Personal Information</b>
                          </h4>
                          <div className="row">
                            <div className="col-md-12">
                              {/* <div className="profile-img-wrap edit-img"> */}
                              {/* <img className="inline-block" src={Avatar_02} alt="user" /> */}
                              {/* <div className="fileupload btn">
                          <span className="btn-text">edit</span>
                          <input className="upload" type="file" />
                        </div>
                      </div> */}
                              <div className="row">
                                <div
                                  className="col-md-6"
                                  style={{ display: "flex" }}
                                >
                                  <div className="form-group col-md-10">
                                    <label> Name</label>
                                    <input
                                      disabled
                                      type="text"
                                      className="form-control"
                                      value={empDetails.name}
                                      onChange={(e) =>
                                        setEmpDetails({
                                          ...empDetails,
                                          name: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                  <div
                                    className="col-md-2"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <InlineMessage
                                      appearance="connectivity"
                                      iconLabel="Log in to see more information"
                                    >
                                      <p>
                                        Please contact Admin for changes in this
                                        field
                                      </p>
                                    </InlineMessage>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Alternate Contact Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={empDetails.alternate_phone}
                                      onChange={(e) =>
                                        setEmpDetails({
                                          ...empDetails,
                                          alternate_phone: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Passport Number</label>
                                    <div>
                                      <input
                                        className="form-control datetimepicker"
                                        type="text"
                                        value={empDetails.passport_no}
                                        onChange={(e) =>
                                          setEmpDetails({
                                            ...empDetails,
                                            passport_no: e.target.value,
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Passport Expiry Date</label>
                                    {/* <Select/> */}
                                    <input
                                      className="form-control datetimepicker"
                                      type="date"
                                      value={empDetails.passport_exp_dt}
                                      onChange={(e) =>
                                        setEmpDetails({
                                          ...empDetails,
                                          passport_exp_dt: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.address}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      address: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Permanent Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.per_address}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      per_address: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Marital Status</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.martial_status}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      martial_status: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Employeement of Spouse</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.empl_of_spouce}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      empl_of_spouce: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>No. of Children</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.offspring_no}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      offspring_no: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                    <form>
                      <div className="card">
                        <div className="card-body">
                          <h4>
                            {" "}
                            <b>Primary Contact</b>
                          </h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.primary_name}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      primary_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Relationship{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.primary_relation}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      primary_relation: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Phone <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.primary_phone}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      primary_phone: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Blood Group</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.primary_blood_group}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      primary_blood_group: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <h4>
                            {" "}
                            <b>Secondary Contact</b>
                          </h4>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Name <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={empDetails.secondary_name}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      secondary_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Relationship{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.secondary_relation}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      secondary_relation: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>
                                  Phone <span className="text-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.secondary_phone}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      secondary_phone: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Blood Group</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={empDetails.secondary_blood_group}
                                  onChange={(e) =>
                                    setEmpDetails({
                                      ...empDetails,
                                      secondary_blood_group: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <form>
                      <div className="card col-md-12">
                        <div className="card-body ">
                          <h4>
                            {" "}
                            <b>Education</b>
                          </h4>
                          {inputList.map((input, index) => {
                            return (
                              <div className="row col-md-12 ">
                                <div className="remove-div">
                                  <button
                                    className="add-btn-remove"
                                    onClick={() => removeFields(index)}
                                  >
                                    <img src={deletimg} />
                                  </button>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Education</label>
                                    <input
                                      className=" form-control"
                                      name="education"
                                      placeholder="Education"
                                      value={input.education}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>College</label>
                                    <input
                                      className="form-control"
                                      name="college"
                                      placeholder="College"
                                      value={input.college}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>From</label>
                                    <input
                                      name="from"
                                      className=" form-control"
                                      placeholder="from"
                                      type="date"
                                      value={input.from}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>To</label>
                                    <input
                                      name="to"
                                      className="form-control"
                                      placeholder="to"
                                      type="date"
                                      value={input.to}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="add-div">
                          <button
                            className="add-btn-add"
                            onClick={(e) => addFields(e)}
                          >
                            Add More &nbsp;+
                          </button>
                        </div>
                      </div>
                    </form>
                    <form>
                      <div className="card col-md-12">
                        <div className="card-body">
                          <h4>
                            {" "}
                            <b>Certification</b>
                          </h4>
                          {certificationList.map((input, index) => {
                            return (
                              <div className="row col-md-12">
                                <div className="remove-div">
                                  <button
                                    className="add-btn-remove"
                                    onClick={() => removeFields1(index)}
                                  >
                                    <img src={deletimg} />
                                  </button>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Course</label>
                                    <input
                                      className=" form-control"
                                      name="course"
                                      placeholder="Course"
                                      value={input.course}
                                      onChange={(event) =>
                                        handleFormChange1(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Institute</label>
                                    <input
                                      className="form-control"
                                      name="institute"
                                      placeholder="Institute"
                                      value={input.institute}
                                      onChange={(event) =>
                                        handleFormChange1(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>From</label>
                                    <input
                                      name="from"
                                      className=" form-control"
                                      placeholder="from"
                                      type="date"
                                      value={input.from}
                                      onChange={(event) =>
                                        handleFormChange1(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>To</label>
                                    <input
                                      name="to"
                                      className="form-control"
                                      placeholder="to"
                                      type="date"
                                      value={input.to}
                                      onChange={(event) =>
                                        handleFormChange1(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="add-div">
                          <button
                            className="add-btn-add"
                            onClick={(e) => addCertificateFields(e)}
                          >
                            Add More &nbsp;+
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  {/* <div>
      <button onClick={onAddBtnClick}>Add input</button>
      {inputList}
    </div> */}
                </div>
              </div>
            </div>
            <div className="submit-section1">
              <button
                className="btn-sub"
                style={{ background: "#3298DB", color: "white" }}
                onClick={() => employeeDetailsUpdate()}
              >
                Save
              </button>
              <button
                className="btn-sub"
                style={{ background: "white", color: "#3298DB" }}
                onClick={() => handleProfileEdit()}
              >
                Discard
              </button>
            </div>
          </div>
        )}
        {/* /Profile Modal */}
        {/* Personal Info Modal */}
        <div
          id="personal_info_modal"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Personal Information</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Passport No</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Passport Expiry Date</label>
                        <div>
                          <input
                            className="form-control datetimepicker"
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tel</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Nationality <span className="text-danger">*</span>
                        </label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Religion</label>
                        <div>
                          <input className="form-control" type="date" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Marital status <span className="text-danger">*</span>
                        </label>
                        <select className="select form-control">
                          <option>-</option>
                          <option>Single</option>
                          <option>Married</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Employment of spouse</label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>No. of children </label>
                        <input className="form-control" type="text" />
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Personal Info Modal */}
        {/* Family Info Modal */}
        <div
          id="family_info_modal"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"> Family Informations</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-scroll">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Family Member{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Relationship{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Education Informations{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Name <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Relationship{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Date of birth{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Phone <span className="text-danger">*</span>
                              </label>
                              <input className="form-control" type="text" />
                            </div>
                          </div>
                        </div>
                        <div className="add-more">
                          <a href="">
                            <i className="fa fa-plus-circle" /> Add More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Family Info Modal */}
        {/* Emergency Contact Modal */}
        <div
          id="emergency_contact_modal"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Personal Information</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Primary Contact</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Name <span className="text-danger">*</span>
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Relationship{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Phone <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone 2</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Primary Contact</h3>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Name <span className="text-danger">*</span>
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Relationship{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Phone <span className="text-danger">*</span>
                            </label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone 2</label>
                            <input className="form-control" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Emergency Contact Modal */}
        {/* Education Modal */}
        <div
          id="education_info"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title"> Education Informations</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-scroll">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Education Informations{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Oxford University"
                                className="form-control floating"
                              />
                              <label className="focus-label">Institution</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Computer Science"
                                className="form-control floating"
                              />
                              <label className="focus-label">Subject</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <div>
                                <input
                                  type="date"
                                  defaultValue="01/06/2002"
                                  className="form-control floating datetimepicker"
                                />
                              </div>
                              <label className="focus-label">
                                Starting Date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <div>
                                <input
                                  type="date"
                                  defaultValue="31/05/2006"
                                  className="form-control floating datetimepicker"
                                />
                              </div>
                              <label className="focus-label">
                                Complete Date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="BE Computer Science"
                                className="form-control floating"
                              />
                              <label className="focus-label">Degree</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Grade A"
                                className="form-control floating"
                              />
                              <label className="focus-label">Grade</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Education Informations{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Oxford University"
                                className="form-control floating"
                              />
                              <label className="focus-label">Institution</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Computer Science"
                                className="form-control floating"
                              />
                              <label className="focus-label">Subject</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <div>
                                <input
                                  type="date"
                                  defaultValue="01/06/2002"
                                  className="form-control floating datetimepicker"
                                />
                              </div>
                              <label className="focus-label">
                                Starting Date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <div>
                                <input
                                  type="date"
                                  defaultValue="31/05/2006"
                                  className="form-control floating datetimepicker"
                                />
                              </div>
                              <label className="focus-label">
                                Complete Date
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="BE Computer Science"
                                className="form-control floating"
                              />
                              <label className="focus-label">Degree</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus focused">
                              <input
                                type="text"
                                defaultValue="Grade A"
                                className="form-control floating"
                              />
                              <label className="focus-label">Grade</label>
                            </div>
                          </div>
                        </div>
                        <div className="add-more">
                          <a href="">
                            <i className="fa fa-plus-circle" /> Add More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Education Modal */}
        {/* Experience Modal */}
        <div
          id="experience_info"
          className="modal custom-modal fade"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Experience Informations</h5>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-scroll">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Experience Informations{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Digital Devlopment Inc"
                              />
                              <label className="focus-label">
                                Company Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="United States"
                              />
                              <label className="focus-label">Location</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Web Developer"
                              />
                              <label className="focus-label">
                                Job Position
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div>
                                <input
                                  type="date"
                                  className="form-control floating datetimepicker"
                                  defaultValue="01/07/2007"
                                />
                              </div>
                              <label className="focus-label">Period From</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div>
                                <input
                                  type="date"
                                  className="form-control floating datetimepicker"
                                  defaultValue="08/06/2018"
                                />
                              </div>
                              <label className="focus-label">Period To</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title">
                          Experience Informations{" "}
                          <a href="" className="delete-icon">
                            <i className="fa fa-trash-o" />
                          </a>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Digital Devlopment Inc"
                              />
                              <label className="focus-label">
                                Company Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="United States"
                              />
                              <label className="focus-label">Location</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <input
                                type="text"
                                className="form-control floating"
                                defaultValue="Web Developer"
                              />
                              <label className="focus-label">
                                Job Position
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div>
                                <input
                                  type="date"
                                  className="form-control floating datetimepicker"
                                  defaultValue="01/07/2007"
                                />
                              </div>
                              <label className="focus-label">Period From</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-focus">
                              <div>
                                <input
                                  type="date"
                                  className="form-control floating datetimepicker"
                                  defaultValue="08/06/2018"
                                />
                              </div>
                              <label className="focus-label">Period To</label>
                            </div>
                          </div>
                        </div>
                        <div className="add-more">
                          <a href="">
                            <i className="fa fa-plus-circle" /> Add More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-section">
                    <button className="btn btn-primary submit-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* /Experience Modal */}
      </div>
    </div>
  );
};
export default EmployeeProfile;
