import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import AvatarGroup from "@atlaskit/avatar-group";
import { IconButton, Button as ButtonMui } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Table, Typography, ConfigProvider, Tooltip as Tooltips } from "antd";
import Tag, { SimpleTag } from "@atlaskit/tag";
import TagGroup from "@atlaskit/tag-group";
import { data } from "./taskConst";
import { Spin } from "antd";
import empty from "../../assets/img/profiles/browser.png";
import { FcExpand } from "react-icons/fc";
import { IoIosArrowUp } from "react-icons/io";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import Button from "@atlaskit/button/standard-button";
import MoreIcon from "@atlaskit/icon/glyph/more";
import EditIcon from "@atlaskit/icon/glyph/edit";
import AddIcon from "@atlaskit/icon/glyph/add";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import Tooltip from "@atlaskit/tooltip";
import { RANDOM_USERS } from "../../Constants/avtar";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import styled from "@emotion/styled";
import closeIcon from "../../assets/img/profiles/close-circle-fill.svg";
import {
  SBox4,
  SBox5,
  SBox6,
  STypography,
} from "../../pages/Projects/projectcss";
import { Box } from "@mui/material";

const SButton = styled(ButtonMui)({
  background: "transparent",
  borderRadius: "4px",
  textTransform: "none",
  color: "#3298DB",
  boxShadow: "none",
  padding: "4px",
  margin: "8px",
  ":hover": {
    background: "transparent",
    boxShadow: "none",
  },
});

const Tasklist = (props) => {
  const [toggelOpen, setToggelOpen] = useState(false);
  const [statusdrop, setStatusDrop] = useState(false);
  const [viewTask, setViewTask] = useState({});
  const {
    showTasks,
    editTask,
    setEditTask,
    taskKey,
    subTaskKey,
    setTaskKey,
    setSubTaskKey,
    setDateLimit,
    handleStatus,
    isLoading,
    addTask,
    setAddTask,
    projectName,
    setProjectName,
    handleGetComments,
  } = props;
  let emptyArry = [];
  console.log(showTasks);
  console.log(projectName);
  const items = [
    {
      key: "1",
      label: "Action 1",
    },
    {
      key: "2",
      label: "Action 2",
    },
  ];

  //   const data = [];
  //   for (let i = 0; i < 3; ++i) {
  //     data.push({
  //       key: i.toString(),
  //       name: "Screem",
  //       platform: "iOS",
  //       version: "10.3.4.5654",
  //       upgradeNum: 500,
  //       creator: "Jack",
  //       createdAt: "2014-12-24 23:12:00",
  //     });
  //   }
  const customizeRenderEmpty = () => (
    <div
      style={{
        textAlign: "center",
      }}
    >
      {/* <SmileOutlined
      style={{
        fontSize: 20,
      }}
    /> */}
      <img src={empty} style={{ width: "60px", marginTop: "15px" }} />
      {showTasks?.length > 0 ? (
        <p>No Sub Tasks to Show</p>
      ) : (
        <p>No Tasks to Show</p>
      )}
    </div>
  );

  console.log(viewTask);

  const expandedRowRender = (row) => {
    // console.log(row);
    const columns = [
      // {
      //   title: "",
      //   dataIndex: "",
      //   key: "",
      //   width: "170px",
      //   fixed: "left",
      // },
      {
        // title: "Sub Task Name",
        dataIndex: "task_name",
        key: "task_name",
        width: "280px",
        fixed: "left",
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltips placement="topLeft" title={text}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setViewTask(record);
              }}
              data-bs-toggle="modal"
              data-bs-target="#view_task"
            >
              {text}
            </span>
          </Tooltips>
        ),
        // render: (text, record) => (
        //   <span
        //     style={{ cursor: "pointer" }}
        //     onClick={() => {
        //       setViewTask(record);
        //     }}
        //     data-bs-toggle="modal"
        //     data-bs-target="#view_task"
        //   >
        //     {text}
        //   </span>
        // ),
      },

      {
        // title: "Priority",
        dataIndex: "priority",
        align: "center",
        key: "priority",
        render: (text, record) => (
          <div className="action-label text-center">
            <a className="btn btn-white btn-sm btn-rounded">
              <i
                className={
                  text === "New"
                    ? "fa fa-dot-circle-o text-success"
                    : text === "medium" || text === "Medium"
                    ? "fa fa-dot-circle-o text-primary"
                    : text === "low" || text === "Low"
                    ? "fa fa-dot-circle-o text-purple"
                    : "fa fa-dot-circle-o text-danger"
                }
              />{" "}
              {text}
            </a>
          </div>
        ),
      },
      {
        // title: "Assigned By",
        // dataIndex: "assign_by",
        key: "assign_by",
        render: (text) => (
          <AvatarGroup
            appearance="stack"
            data={emptyArry.concat([text.assign_by]).map((ele) => ({
              // email: "nadan@gmail.com",
              key: ele.key,
              name: ele.name,
              href: "#",
              src: ele.pic?.links,
            }))}
          />
        ),
      },
      {
        // title: "Assigned To",
        // dataIndex: "assign_to",
        key: "assign_to",
        render: (text) => (
          <AvatarGroup
            appearance="stack"
            data={text.assign_to.map((ele) => ({
              // email: "nadan@gmail.com",
              key: ele.key,
              name: ele.name,
              href: "#",
              src: ele.pic?.links,
            }))}
          />
        ),
      },
      {
        // title: "Status",
        dataIndex: "status",
        align: "center",
        key: "status",
        render: (text, record) => (
          <div className="dropdown action-label text-center">
            <a
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              disabled={text === "Completed" ? true : false}
            >
              <i
                className={
                  text === "null"
                    ? "fa fa-dot-circle-o text-purple"
                    : text === "In Progress" || text === "in progress"
                    ? "fa fa-dot-circle-o text-primary"
                    : text === "Completed" || text === "Partialy Completed"
                    ? "fa fa-dot-circle-o text-success"
                    : "fa fa-dot-circle-o text-danger"
                }
              />{" "}
              {text === "No Progress"
                ? "No Progress"
                : text === "In Progress"
                ? "In Progress"
                : text === "Partialy Completed"
                ? "Completed"
                : text === "Completed"
                ? "Completed"
                : "No Progress"}
            </a>
            <div className="dropdown-menu dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.key, "No Progress")}
              >
                <i className="fa fa-dot-circle-o text-danger" /> No Progress
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.key, "In Progress")}
              >
                <i className="fa fa-dot-circle-o text-primary" />
                In Progress
              </a>

              <a
                className="dropdown-item"
                href="#"
                // disabled={text === "No Progress" ? true : false}
                onClick={(e) =>
                  handleStatus(e, record.key, "Partialy Completed")
                }
              >
                <i className="fa fa-dot-circle-o text-success" />
                Completed
              </a>
            </div>
          </div>
        ),
      },
      {
        // title: "Manager's Status",
        align: "center",
        render: (text, record) => (
          <div className="dropdown action-label text-center">
            <button
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              disabled={
                text.assign_by.key ===
                JSON.parse(sessionStorage.getItem("emp_id"))
                  ? false
                  : true
              }
            >
              <i
                className={
                  text.status === null
                    ? "fa fa-dot-circle-o text-purple"
                    : text.status === "In Progress" ||
                      text.status === "in progress"
                    ? "fa fa-dot-circle-o text-danger"
                    : text.status === "No Progress"
                    ? "fa fa-dot-circle-o text-danger"
                    : text.status === "Partialy Completed" ||
                      text.status === "Done"
                    ? "fa fa-dot-circle-o text-danger"
                    : "fa fa-dot-circle-o text-success"
                }
              />{" "}
              {text.status === "No Progress"
                ? "Not Approved"
                : text.status === "In Progress"
                ? "Not Approved"
                : text.status === "Partialy Completed"
                ? "Not Approved"
                : "Approved"}
            </button>
            <div className="dropdown-menu dropdown-menu">
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.key, "In Progress")}
              >
                <i className="fa fa-dot-circle-o text-danger" /> Not Approved
              </a>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleStatus(e, record.key, "Completed")}
              >
                <i className="fa fa-dot-circle-o text-success" />
                Approved
              </a>
            </div>
          </div>
        ),
      },
      {
        // title: "Assigned Date",
        dataIndex: "assigned_date",
        key: "assigned_date",
        align: "center",
        render: (text, record) => <>{text.split("-")?.reverse()?.join("-")}</>,
      },
      {
        // title: " Due Date",
        dataIndex: "due_date",
        key: "due_date",
        align: "center",
        render: (text, record) => <>{text.split("-")?.reverse()?.join("-")}</>,
      },
      {
        // title: " Due Time",
        dataIndex: "due_time",
        key: "due_time",
        align: "center",
      },
      {
        // title: " Extra Time",
        dataIndex: "extra_time",
        key: "extra_time",
        align: "center",
      },
      {
        // title: "Action",
        key: "operation",
        fixed: "right",
        width: "54px",
        render: (text) => (
          <DropdownMenu
            trigger={({ triggerRef, ...props }) => (
              <Button
                {...props}
                iconBefore={<MoreIcon label="more" />}
                ref={triggerRef}
              />
            )}
          >
            <DropdownItemGroup style={{ display: "flex" }}>
              {text.edit_flag && (
                <DropdownItem
                  data-bs-toggle="modal"
                  data-bs-target="#edit_task_board"
                  onClick={() => (
                    setEditTask(text),
                    setDateLimit({
                      max: text.max_due_datetime?.substr(0, 10),
                      min: text.min_due_datetime?.substr(0, 10),
                      tag: "sub",
                    })
                  )}
                >
                  {/* <i class="ri-edit-line"></i> */}
                  <Tooltip content="Edit Subtask">
                    <EditIcon
                      label="edit"
                      data-bs-toggle="tooltip"
                      title="Edit Task"
                    />
                  </Tooltip>
                </DropdownItem>
              )}

              {/* <DropdownItem>Move</DropdownItem>
            <DropdownItem>Clone</DropdownItem>
            <DropdownItem>Delete</DropdownItem>
            <DropdownItem>Report</DropdownItem> */}
            </DropdownItemGroup>
          </DropdownMenu>
        ),
      },
    ];

    // for (let i = 0; i < 3; ++i) {
    //   data.push({
    //     key: i.toString(),
    //     date: "2014-12-24 23:12:00",
    //     name: "This is production name",
    //     upgradeNum: "Upgraded: 56",
    //   });
    // }
    return (
      <div className="card card-board">
        {" "}
        <Table
          ellipsis={true}
          columns={columns}
          dataSource={row.subtask}
          pagination={false}
          scroll={{ x: 1800 }}
        />{" "}
      </div>
    );
  };

  const columns = [
    {
      title: " Task Name",
      dataIndex: "task_name",
      fixed: "left",
      width: "250px",
      // align: "left",
      key: "task_name",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record) => (
        <Tooltips placement="topLeft" title={text}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setViewTask(record);
            }}
            data-bs-toggle="modal"
            data-bs-target="#view_task"
          >
            {record?.subtask?.length > 0 ? (
              <span>
                {text} &nbsp;
                <span
                  style={{
                    background: "green",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  {record.subtask.length}
                </span>
              </span>
            ) : (
              text
            )}
          </span>
        </Tooltips>
      ),
    },
    // {
    //   title: " Project Name",
    //   dataIndex: "project_name",
    //   key: "project_name",

    //   fixed: "left",
    // },
    {
      title: "Priority",
      dataIndex: "priority",
      align: "center",
      key: "priority",
      sorter: (a, b) => a.priority.localeCompare(b.priority),

      render: (text, record) => (
        <div className=" dropdown action-label text-center ">
          <a
            className="btn btn-white btn-sm btn-rounded "
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="true"
            //onClick={() => setStatusDrop()}
          >
            <i
              className={
                text === "New"
                  ? "fa fa-dot-circle-o text-success"
                  : text === "medium" || text === "Medium"
                  ? "fa fa-dot-circle-o text-primary"
                  : text === "low" || text === "Low"
                  ? "fa fa-dot-circle-o text-purple"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text}
          </a>
        </div>
      ),
    },
    {
      title: "Assigned By",
      // dataIndex: "assign_by",
      key: "assign_by",
      render: (text) => (
        <AvatarGroup
          appearance="stack"
          data={emptyArry?.concat([text?.assign_by]).map((ele) => ({
            email: "nadan@gmail.com",
            key: ele.name,
            name: ele.name,
            href: "#",
            src: ele.pic?.links,
          }))}
        />
      ),
    },
    {
      title: "Assigned To",
      // dataIndex: "assign_to",

      key: "assign_to",
      render: (text) => (
        <AvatarGroup
          appearance="stack"
          data={text?.assign_to?.map((ele) => ({
            // email: "nadan@gmail.com",
            key: ele.name,
            name: ele.name,
            href: "#",
            src: ele.pic?.links,
          }))}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <a
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={text === "Completed" ? true : false}
          >
            <i
              className={
                text === "null"
                  ? "fa fa-dot-circle-o text-purple"
                  : text === "In Progress" || text === "in progress"
                  ? "fa fa-dot-circle-o text-primary"
                  : text === "Completed" || text === "Partialy Completed"
                  ? "fa fa-dot-circle-o text-success"
                  : "fa fa-dot-circle-o text-danger"
              }
            />{" "}
            {text === "No Progress"
              ? "No Progress"
              : text === "In Progress"
              ? "In Progress"
              : text === "Partialy Completed"
              ? "Completed"
              : "Completed"}
          </a>
          <div
            className="dropdown-menu dropdown-menu"
            style={{ inset: "-100px auto auto 0px" }}
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleStatus(e, record.key, "No Progress")}
            >
              <i className="fa fa-dot-circle-o text-danger" /> No Progress
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleStatus(e, record.key, "In Progress")}
            >
              <i className="fa fa-dot-circle-o text-primary" />
              In Progress
            </a>

            <a
              className="dropdown-item"
              href="#"
              // disabled={text === "No Progress" ? true : false}
              onClick={(e) => handleStatus(e, record.key, "Partialy Completed")}
            >
              <i className="fa fa-dot-circle-o text-success" />
              Completed
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Manager's Status",
      align: "center",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <button
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            href="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            disabled={
              text?.assign_by?.key ===
              JSON.parse(sessionStorage.getItem("emp_id"))
                ? false
                : true
            }
          >
            <i
              className={
                text.status === "null"
                  ? "fa fa-dot-circle-o text-purple"
                  : text.status === "In Progress" ||
                    text.status === "in progress"
                  ? "fa fa-dot-circle-o text-danger"
                  : text.status === "No Progress"
                  ? "fa fa-dot-circle-o text-danger"
                  : text.status === "Partialy Completed" ||
                    text.status === "Done"
                  ? "fa fa-dot-circle-o text-danger"
                  : "fa fa-dot-circle-o text-success"
              }
            />{" "}
            {text.status === "No Progress"
              ? "Not Approved"
              : text.status === "In Progress"
              ? "Not Approved"
              : text.status === "Partialy Completed"
              ? "Not Approved"
              : "Approved"}
          </button>
          <div
            className="dropdown-menu dropdown-menu"
            style={{ inset: "-100px auto auto 0px" }}
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleStatus(e, record.key, "In Progress")}
            >
              <i className="fa fa-dot-circle-o text-danger" /> Not Approved
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleStatus(e, record.key, "Completed")}
            >
              <i className="fa fa-dot-circle-o text-success" />
              Approved
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "Assigned Date",
      align: "center",
      dataIndex: "assigned_date",
      key: "assigned_date",
      render: (text, record) => <>{text.split("-")?.reverse()?.join("-")}</>,
    },
    {
      title: " Due Date",
      dataIndex: "due_date",
      key: "due_date",
      align: "center",
      render: (text, record) => <>{text.split("-")?.reverse()?.join("-")}</>,
    },
    {
      title: " Due Time",
      dataIndex: "due_time",
      key: "due_time",
      align: "center",
    },
    {
      title: " Extra Time",
      dataIndex: "extra_time",
      key: "extra_time",
      align: "center",
    },
    {
      title: "Action",
      key: "operation",
      // align: "center",
      fixed: "right",
      width: "70px",
      render: (text) => (
        <DropdownMenu
          trigger={({ triggerRef, ...props }) => (
            <Button
              {...props}
              iconBefore={<MoreIcon label="more" />}
              ref={triggerRef}
            />
          )}
        >
          <DropdownItemGroup style={{ display: "flex" }}>
            {text.edit_flag && (
              <DropdownItem
                data-bs-toggle="modal"
                data-bs-target="#edit_task_board"
                onClick={() => (
                  setEditTask(text), setDateLimit({ max: "", min: "", tag: "" })
                )}
              >
                {/* <i class="ri-edit-line"></i> */}
                <Tooltip content="Edit Task">
                  <EditIcon
                    label="edit"
                    data-bs-toggle="tooltip"
                    title="Edit Task"
                  />
                </Tooltip>
              </DropdownItem>
            )}

            <DropdownItem
              data-bs-toggle="modal"
              data-bs-target="#add_task_board"
              onClick={() => (
                setTaskKey(text.key),
                console.log(text),
                setDateLimit({
                  max: text.due_date,
                  min: text.assigned_date,
                  tag: "sub",
                  projectName1: text.project_name,
                })
              )}
            >
              {" "}
              <Tooltip content="Add Subtask">
                <AddIcon label="add" />
              </Tooltip>
            </DropdownItem>
            {sessionStorage.getItem("emp_id") == text.assign_by?.key && (
              <DropdownItem
                data-bs-toggle="modal"
                data-bs-target="#delete_task_board"
                onClick={() => setTaskKey(text.key)}
              >
                <Tooltip content="Delete task">
                  <DeleteOutlineOutlinedIcon label="delete" />
                </Tooltip>
              </DropdownItem>
            )}
            <DropdownItem
              data-bs-toggle="modal"
              data-bs-target="#comment_task_board"
              onClick={() => (
                handleGetComments(text.key), setTaskKey(text.key)
              )}
            >
              <Tooltip content="Comment">
                <ChatBubbleOutlineOutlinedIcon label="Comments" />
              </Tooltip>
            </DropdownItem>
            {/*<DropdownItem>Delete</DropdownItem>
            <DropdownItem>Report</DropdownItem> */}
          </DropdownItemGroup>
        </DropdownMenu>
      ),
    },
  ];

  return (
    <div>
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        {/* <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <h3 className="page-title">Task Dashboard</h3>
            </div>
          </div>
        </div> */}
        {/* /Page Header */}
        {/* Content Starts */}

        {showTasks.map((ele) => (
          <>
            <div className="card mb-0">
              <div className="card-body">
                <div className="table-responsive">
                  <h3>{ele?.project_name}</h3>
                  <ConfigProvider
                    renderEmpty={
                      // customize ?
                      customizeRenderEmpty
                      // : undefined
                    }
                  >
                    <Table
                      ellipsis={true}
                      columns={columns}
                      expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ["0"],
                        expandIcon: ({ expanded, onExpand, record }) =>
                          expanded ? (
                            <IoIosArrowUp
                              style={{ cursor: "pointer" }}
                              onClick={(e) => onExpand(record, e)}
                            />
                          ) : (
                            <FcExpand
                              style={{ cursor: "pointer" }}
                              onClick={(e) => onExpand(record, e)}
                            />
                          ),
                      }}
                      dataSource={ele?.tasks}
                      loading={isLoading}
                      pagination={{
                        total: ele?.tasks.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      scroll={{ x: 1800 }}
                    />
                  </ConfigProvider>
                </div>

                <Box sx={{ textAlign: "center" }}>
                  <SButton
                    data-bs-toggle="modal"
                    data-bs-target="#add_task_board"
                    sx={{ color: "red" }}
                    onClick={() => {
                      setProjectName({
                        label: ele.project_name,
                        value: ele.project_id,
                      });
                      setDateLimit({
                        max: "",
                        min: new Date().toISOString().split("T")[0],
                        tag: "",
                        projectName1: ele.project_name,
                      });
                    }}
                  >
                    <div className="text-muted">Add Task</div>

                    <AddCircleOutlineIcon sx={{ color: "#3298db", ml: 0.5 }} />
                  </SButton>
                </Box>
              </div>
            </div>
            <br />
          </>
        ))}

        <br />
        {/* /Content End */}
      </div>
      {/* /Page Content */}
      <div id="view_task" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <br />
            <div className="modal-header">
              <STypography variant="h5">
                {/* <b>{viewState ? "View Project" : "Edit Project"}</b> */}
                <b>View Task</b>
              </STypography>

              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <hr />

            <div className="modal-body" style={{ marginTop: "-20px" }}>
              <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Task Name</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.task_name}
                  </STypography>
                </SBox5>

                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Project Name</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.project_name}
                  </STypography>
                </SBox5>
              </SBox4>

              <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Start Date</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.assigned_date?.split("-")?.reverse()?.join("-")}
                    {/* ?.substr(0, 10)
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")} */}
                  </STypography>
                </SBox5>

                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Start Time</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.assigned_time}
                    {/* ?.substr(0, 10)
                       ?.split("-")
                       ?.reverse()
                       ?.join("-")}  */}
                  </STypography>
                </SBox5>
              </SBox4>
              <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>End Date</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.due_date?.split("-")?.reverse()?.join("-")}
                    {/* ?.substr(0, 10)
                       ?.split("-")
                       ?.reverse()
                       ?.join("-")}  */}
                  </STypography>
                </SBox5>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>End Time</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.due_time}
                    {/* ?.substr(0, 10)
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")} */}
                  </STypography>
                </SBox5>
              </SBox4>
              <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Status</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.status}
                  </STypography>
                </SBox5>

                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Priority</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.priority}
                  </STypography>
                </SBox5>
              </SBox4>
              <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Extra Time</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {viewTask?.extra_time}
                  </STypography>
                </SBox5>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Assigned By</b>
                  </STypography>

                  <STypography paragraph>
                    <TagGroup>
                      <SimpleTag text={viewTask?.assign_by?.name} />
                    </TagGroup>
                  </STypography>
                </SBox5>
              </SBox4>
              <STypography variant="subtitle1">
                <b>Assigned To</b>
              </STypography>

              <STypography paragraph>
                <TagGroup>
                  {viewTask?.assign_to?.map((ele) => (
                    <SimpleTag text={ele.name} />
                  ))}
                </TagGroup>
              </STypography>
              <STypography variant="subtitle1">
                <b>Description</b>
              </STypography>

              <STypography variant="subtitle1">
                {viewTask?.description}
              </STypography>

              {/* <SBox4>
                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Assigned By</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {projectDet?.creator.name}
                  </STypography>
                </SBox5>

                <SBox5>
                  <STypography variant="subtitle1">
                    <b>Project Lead</b>
                  </STypography>

                  <STypography variant="subtitle1">
                    {projectDet?.teamleads[0]?.name}
                  </STypography>
                </SBox5>
              </SBox4> */}

              {/* <STypography variant="subtitle1">
                <b>Drive Link</b>
              </STypography>

              <STypography paragraph>
                <a
                  href={`//${projectDet?.drive_link}`}
                  target="_blank"
                  rel="noopener"
                >
                  {projectDet?.drive_link}
                </a>
              </STypography> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Tasklist;
